import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./components/App/App";
import store from "./store";
import { env } from "./config/client";

if (env === "prod") {
  const sentryKey = "527089a2c16c440eb17c15a5069083b8";
  const sentryApp = "5181024";
  Sentry.init({ dsn: `https://${sentryKey}@sentry.io/${sentryApp}` });
}

ReactDOM.render(<App store={store} />, document.getElementById("root"));
