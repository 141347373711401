import {
  GET_MAX_BET_CHANCE_BONUS_ERROR,
  GET_MAX_BET_CHANCE_BONUS_REQUEST,
  GET_MAX_BET_CHANCE_BONUS_SUCCESS,
  GET_WIN_PROBABILITY_SUCCESS,
  GET_HOUSE_EDGE_GLOBAL_REQUEST,
  GET_HOUSE_EDGE_GLOBAL_SUCCESS,
  GET_HOUSE_EDGE_GLOBAL_ERROR,
  GET_GWP_REQUEST,
  GET_GWP_SUCCESS,
  GET_GWP_ERROR,
  GET_GBS_REQUEST,
  GET_GBS_SUCCESS,
  GET_GBS_ERROR,
  GET_GCL_REQUEST,
  GET_GCL_SUCCESS,
  GET_GCL_ERROR,
  GET_MIN_BET_SIZE_REQUEST,
  GET_MIN_BET_SIZE_SUCCESS,
  GET_MIN_BET_SIZE_ERROR,
} from "../../actionTypes/contractConstants/games";
import {
  getGBSFromContract,
  getGCLFromContract,
  getGWPFromContract,
  getHouseEdgeGlobalFromContract,
  getMaxBetChanceForBonusBettingFromContract, getMinBetFromContract,
  getWinProbabilityFromContract,
} from '../../services/ethereum/games';

/**
 * Sets max bet chance for bonus betting
 *
 * @return {Function}
 */
export const getMaxBetChanceForBonusBetting = () => async (dispatch) => {
  dispatch({ type: GET_MAX_BET_CHANCE_BONUS_REQUEST });
  try {
    const payload = await getMaxBetChanceForBonusBettingFromContract();
    dispatch({ type: GET_MAX_BET_CHANCE_BONUS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_BET_CHANCE_BONUS_ERROR, payload: err.message });
  }
};

export const getHouseEdgeGlobal = () => async ( dispatch, getState ) => {
  dispatch({ type: GET_HOUSE_EDGE_GLOBAL_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await getHouseEdgeGlobalFromContract( account );
    dispatch({ type: GET_HOUSE_EDGE_GLOBAL_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_HOUSE_EDGE_GLOBAL_ERROR, payload: e.message });
  }
};

export const getMinBetSize = () => async (dispatch) => {
  dispatch({ type: GET_MIN_BET_SIZE_REQUEST });
  try {
    const payload = await getMinBetFromContract();
    dispatch({ type: GET_MIN_BET_SIZE_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_MIN_BET_SIZE_ERROR, payload: error.message });
  }
};

export const getGWP = () => async (dispatch) => {
  dispatch({ type: GET_GWP_REQUEST });
  try {
    const payload = await getGWPFromContract();
    dispatch({ type: GET_GWP_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_GWP_ERROR, payload: error.message });
  }
};

export const getGBS = () => async (dispatch) => {
  dispatch({ type: GET_GBS_REQUEST });
  try {
    const payload = await getGBSFromContract();
    dispatch({ type: GET_GBS_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_GBS_ERROR, payload: error.message });
  }
};

export const getGCL = () => async (dispatch) => {
  dispatch({ type: GET_GCL_REQUEST });
  try {
    const payload = await getGCLFromContract();
    dispatch({ type: GET_GCL_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_GCL_ERROR, payload: error.message });
  }
};






export const getWinProbability = () => async ( dispatch, getState ) => {
  const { account } = getState().account;

  const { minWinProb, maxWinProb } = await getWinProbabilityFromContract(account);
  dispatch({
    type: GET_WIN_PROBABILITY_SUCCESS,
    payload: { minWinProb, maxWinProb }
  });
};
