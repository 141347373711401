import {
  GET_ROLL_GAME_STATE_REQUEST,
  GET_ROLL_GAME_STATE_SUCCESS,
  GET_ROLL_GAME_STATE_ERROR,
  GET_ROLL_ROUND_STATE_REQUEST,
  GET_ROLL_ROUND_STATE_SUCCESS,
  GET_ROLL_ROUND_STATE_ERROR,
  GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST,
  GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS,
  GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR,
  GET_ACC_ROLL_TOTAL_BETS_ERROR,
  GET_ACC_ROLL_TOTAL_BETS_REQUEST,
  GET_ACC_ROLL_TOTAL_BETS_SUCCESS,
  SET_ROLL_GAME_TYPE,
  SET_ROLL_GAME_SUBTYPE,
  SET_ROLL_BET_AMOUNT,
  SET_ROLL_BET_VALUE,
  SET_ROLL_WIN_CHANCE,
  SET_ROLL_WIN_PRIZE,
  SET_ROLL_LAST_SELECTED_NUMBER,
  SET_ROLL_LAST_USER_SELECTED_NUMBER,
  SET_ROLL_LAST_ROUND_PLAYED,
  SET_ROLL_LAST_USER_SELECTED_GAME_TYPE,
  SET_ROLL_RANGE_LIMITS,
  SET_ROLL_LAST_WIN_PRIZE,
  SET_ROLL_SHOW_CONTROLS,
  SET_ROLL_SHOW_DICE,
  PLACE_ROLL_BET_REQUEST,
  PLACE_ROLL_BET_SUCCESS,
  PLACE_ROLL_BET_ERROR,
  CLOSE_ROLL_ROUND,
  SET_ROLL_PAUSED,
  SET_ROLL_LAST_ROUND_BLOCK,
  ROLL_LISTENERS_INITIATED,
  GET_ROLL_INIT_DATA_ERROR,
  GET_ROLL_INIT_DATA_REQUEST,
  GET_ROLL_INIT_DATA_SUCCESS,
  START_ROLL_AUTO_BET_REQUEST,
  START_ROLL_AUTO_BET_SUCCESS,
  START_ROLL_AUTO_BET_ERROR,
  STOP_ROLL_AUTO_BET_REQUEST,
  STOP_ROLL_AUTO_BET_SUCCESS,
  STOP_ROLL_AUTO_BET_ERROR,
  UPDATE_ROLL_AUTO_BET_REQUEST,
  UPDATE_ROLL_AUTO_BET_SUCCESS,
  UPDATE_ROLL_AUTO_BET_ERROR,
  ROLL_AUTO_BET_STARTED,
  ROLL_AUTO_BET_STOPPED,
  SET_ROLL_AUTO_BET_DATA,
  DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION,
} from "../../actionTypes/games/roll";

const INITIAL_STATE = {
  betAmount: "0",
  rollPaused: false,

  gettingGameState: false,
  gettingGameStateErr: "",

  rollLastRoundBlock: 0,
  roundState: "",
  gettingRoundState: false,
  gettingRoundStateErr: "",

  inputRangeMin: 4,
  inputRangeMax: 94,

  placingBet: false,
  placingBetError: "",

  showControls: true,
  showDice: false,

  bettingRound: 0,
  lastRoundPlayed: 0,
  lastSelectedNumber: 0,
  lastUserSelectedNumber: 0,
  lastUserSelectedGameType: 0,
  lastWinPrize: 0,
  gameType: 0,
  gameSubType: 0,
  betValue: 4,
  winChance: 95,
  winPrize: 0,

  lastRoundTimestamp: 0,
  gettingLastRoundTimestamp: false,
  gettingLastRoundTimestampError: "",

  rollTotalBets: 0,
  gettingRollTotalBets: false,
  gettingRollTotalBetsErr: "",

  rollListenersInitiated: false,

  gettingRollInitData: false,
  gettingRollInitDataErr: "",

  isAutoBetting: false,
  autoBettingRounds: 0,
  autoBettingCurrentRound: 0,
  autoBetBalanceNotificationDismissed: false,

  updatingAutoBetting: false,
  updatingAutoBettingErr: "",

  startingAutoBetting: false,
  startingAutoBettingErr: "",
  stoppingAutoBetting: false,
  stoppingAutoBettingErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ROLL_GAME_STATE_REQUEST:
      return { ...state, gettingGameState: true, gettingGameStateErr: "" };

    case GET_ROLL_GAME_STATE_SUCCESS:
      return { ...state, gettingGameState: false, gettingGameStateErr: "" };

    case GET_ROLL_GAME_STATE_ERROR:
      return {
        ...state,
        gettingGameState: false,
        gettingGameStateErr: payload
      };

    case GET_ROLL_ROUND_STATE_REQUEST:
      return { ...state, gettingRoundState: true, gettingRoundStateErr: "" };

    case GET_ROLL_ROUND_STATE_SUCCESS:
      return {
        ...state,
        gettingRoundState: false,
        roundState: payload.status,
        bettingRound: payload.round,
        gettingRoundStateErr: ""
      };

    case GET_ROLL_ROUND_STATE_ERROR:
      return {
        ...state,
        gettingRoundState: false,
        gettingRoundStateErr: payload
      };

    case SET_ROLL_RANGE_LIMITS:
      return {
        ...state,
        inputRangeMin: payload.min,
        inputRangeMax: payload.max
      };

    case SET_ROLL_LAST_WIN_PRIZE:
      return { ...state, lastWinPrize: payload };

    case SET_ROLL_GAME_TYPE:
      return { ...state, gameType: payload };

    case SET_ROLL_GAME_SUBTYPE:
      return { ...state, gameSubType: payload };

    case SET_ROLL_BET_AMOUNT:
      return { ...state, betAmount: payload };

    case SET_ROLL_BET_VALUE:
      return { ...state, betValue: payload };

    case SET_ROLL_WIN_CHANCE:
      return { ...state, winChance: payload };

    case SET_ROLL_WIN_PRIZE:
      return { ...state, winPrize: payload };

    case SET_ROLL_LAST_SELECTED_NUMBER:
      return { ...state, lastSelectedNumber: payload };

    case SET_ROLL_LAST_USER_SELECTED_NUMBER:
      return { ...state, lastUserSelectedNumber: payload };

    case SET_ROLL_LAST_USER_SELECTED_GAME_TYPE:
      return { ...state, lastUserSelectedGameType: payload };

    case SET_ROLL_LAST_ROUND_PLAYED:
      return { ...state, lastRoundPlayed: payload };

    case SET_ROLL_SHOW_CONTROLS:
      return { ...state, showControls: payload };

    case SET_ROLL_SHOW_DICE:
      return { ...state, showDice: payload };

    case PLACE_ROLL_BET_REQUEST:
      return { ...state, placingBet: true, placingBetError: "" };

    case PLACE_ROLL_BET_SUCCESS:
      return { ...state, placingBet: false, placingBetError: "" };

    case PLACE_ROLL_BET_ERROR:
      return { ...state, placingBet: false, placingBetError: payload || "" };

    case CLOSE_ROLL_ROUND:
      return { ...state, roundState: "closed" };

    case GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST:
      return {
        ...state,
        gettingLastRoundTimestamp: true,
        gettingLastRoundTimestampError: ""
      };

    case GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        lastRoundTimestamp: payload,
        gettingLastRoundTimestampError: ""
      };

    case GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        gettingLastRoundTimestampError: payload
      };

    case GET_ACC_ROLL_TOTAL_BETS_REQUEST:
      return {
        ...state,
        gettingRollTotalBets: true,
        gettingRollTotalBetsErr: ""
      };

    case GET_ACC_ROLL_TOTAL_BETS_SUCCESS:
      return {
        ...state,
        gettingRollTotalBets: false,
        gettingRollTotalBetsErr: "",
        rollTotalBets: payload
      };

    case GET_ACC_ROLL_TOTAL_BETS_ERROR:
      return {
        ...state,
        gettingRollTotalBets: false,
        gettingRollTotalBetsErr: payload
      };

    case SET_ROLL_PAUSED:
      return {
        ...state,
        rollPaused: payload
      };

    case SET_ROLL_LAST_ROUND_BLOCK: return {
      ...state,
      rollLastRoundBlock: payload,
    };

    case ROLL_LISTENERS_INITIATED: return {
      ...state,
      rollListenersInitiated: true,
    };

    case GET_ROLL_INIT_DATA_REQUEST: return {
      ...state,
      gettingRollInitData: true,
      gettingRollInitDataErr: "",
    };

    case GET_ROLL_INIT_DATA_SUCCESS: return {
      ...state,
      gettingRollInitData: false,
      gettingRollInitDataErr: "",
    };

    case GET_ROLL_INIT_DATA_ERROR: return {
      ...state,
      gettingRollInitData: false,
      gettingRollInitDataErr: payload,
    };


    case START_ROLL_AUTO_BET_REQUEST: return {
      ...state,
      startingAutoBetting: true,
      startingAutoBettingErr: "",
    };

    case START_ROLL_AUTO_BET_SUCCESS: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: "",
    };

    case START_ROLL_AUTO_BET_ERROR: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: payload,
    };

    case ROLL_AUTO_BET_STARTED: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: +payload
    };

    case UPDATE_ROLL_AUTO_BET_REQUEST: return {
      ...state,
      updatingAutoBetting: true,
      updatingAutoBettingErr: "",
    };

    case UPDATE_ROLL_AUTO_BET_SUCCESS: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: "",
      autoBettingCurrentRound: payload
    };

    case UPDATE_ROLL_AUTO_BET_ERROR: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: payload,
    };

    case STOP_ROLL_AUTO_BET_REQUEST: return {
      ...state,
      stoppingAutoBetting: true,
      stoppingAutoBettingErr: "",
    };

    case STOP_ROLL_AUTO_BET_SUCCESS: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: "",
    };

    case ROLL_AUTO_BET_STOPPED: return {
      ...state,
      isAutoBetting: false,
      autoBettingRounds: 0,
      autoBettingCurrentRound: 0,
      autoBetBalanceNotificationDismissed: false,
    };

    case STOP_ROLL_AUTO_BET_ERROR: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: payload,
    };

    case SET_ROLL_AUTO_BET_DATA: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: payload.autoBettingRounds,
      autoBettingCurrentRound: payload.autoBettingCurrentRound,
    };

    case DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION: return {
      ...state,
      autoBetBalanceNotificationDismissed: true,
    };

    default:
      return state;
  }
};
