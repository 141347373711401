import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect, Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { setInitData } from "../../actions/games/games";
import { silentLogin } from "../../actions/account";
import { setTimeOffset } from "../../actions/roundTimer";
import { clearSession } from "../../services/api";

// Components
import Loader from "../Loader/Loader";
import DiceGame from "../Games/DiceGame/DiceGame";
import SpinGame from "../Games/SpinGame/SpinGame";
import RouletteGame from "../Games/RouletteGame/RouletteGame";
import ScratchGame from "../Games/ScratchGame/ScratchGame";
import Notification from "../Notification/Notification";
import NoProvider from "../NoProvider/NoProvider";
import Modal from "../Modal/Modal";
import HeaderMain from "../HeaderMain/HeaderMain";
import Header from "../Header/Header";
import TxNotifications from "../TxNotifications/TxNotification";
import DrawerRight from "../Drawer/DrawerRight";
import DrawerLeft from "../Drawer/DrawerLeft";
import BadToken from "../BadToken/BadToken";
import AffiliateToken from "../AffiliateToken/AffiliateToken";
import Footer from "../Footer/Footer";

import "react-tippy/dist/tippy.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "../../styles/index.scss";
import "./banner.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.clearSession = this.clearSession.bind(this);
  }

  clearSession = async () => {
    const { rememberUser } = this.props;
    if (!rememberUser) {
      console.log("Clear session.");
      await clearSession();
    }
  };

  async componentWillMount() {
    const { silentLogin, setTimeOffset, setInitData } = this.props;
    await silentLogin();
    await setInitData();
    setTimeOffset();

    window.addEventListener('beforeunload', this.clearSession);
  }

  async componentWillUnmount() {
    this.clearSession();
    window.removeEventListener('beforeunload', this.clearSession);
  }

  render() {
    const { store, connectingProvider, accountError, isRegistered } = this.props;
    if (!!accountError) {
      return (
        <Provider store={store}>
          <BrowserRouter>
            <Fragment>
              <Switch>
                <Route path="/" component={NoProvider} />
              </Switch>
              <Notification />
            </Fragment>
          </BrowserRouter>
        </Provider>
      );
    }
    if (connectingProvider || !isRegistered) {
      return (
        <Provider store={store}>
          <Fragment>
            <Modal />
            <Loader size={85} isFullScreen={true} text={"Loading..."} />
          </Fragment>
        </Provider>
      );
    }
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Fragment>
            <HeaderMain />
            <Header />
            <TxNotifications />
            <Switch>
              <Route exact path="/roll" component={DiceGame} />
              <Route exact path="/spin" component={SpinGame} />
              <Route exact path="/scratch" component={ScratchGame} />
              <Route exact path="/roulette" component={RouletteGame} />
              <Route exact path="/bad-token" component={BadToken} />
              <Route exact path="/affiliates" component={AffiliateToken} />
              <Route path="/no-provider" component={NoProvider} />
            </Switch>
            <Footer />
            <DrawerLeft />
            <DrawerRight />
            <Notification />
            <Modal />
          </Fragment>
        </BrowserRouter>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  silentLogin: PropTypes.func.isRequired,
  setTimeOffset: PropTypes.func.isRequired,
  connectingProvider: PropTypes.bool.isRequired,
  accountError: PropTypes.string.isRequired,
  setInitData: PropTypes.func.isRequired
};

const mapStateToProps = ({ account, games }) => ({
  connectingProvider: account.connectingProvider,
  accountError: account.accountError,
  activeGame: games.activeGame,
  rememberUser: account.rememberUser,
  isRegistered: account.isRegistered,
});

const mapDispatchToProps = {
  silentLogin,
  setTimeOffset,
  setInitData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
