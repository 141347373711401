import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { itemRevealed } from "../../../../../actions/games/scratch";

import Ethereum from "../../../../Decorative/ScratchCardItems/Ethereum";
import MakerDAO from "../../../../Decorative/ScratchCardItems/MakerDAO";
import BAT from "../../../../Decorative/ScratchCardItems/BAT";
import ChainLink from "../../../../Decorative/ScratchCardItems/ChainLink";
import Matic from "../../../../Decorative/ScratchCardItems/Matic";
import Augur from "../../../../Decorative/ScratchCardItems/Augur";
import "./ScratchItem.scss";

const brushURI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC+SURBVHgBrZXBEYIwEEW/ePGYEuzAFuzAlJAS7AA6gE60BSuADqJHT5Sgu2NwIEOGheybeTlkhjchhw0wjyGv5I305CfYhj2HFViyH0VSekm4FoRiy1Ss3BAbrOOYy4gN2nHQKwT53s0+nM4hnwP5Lmi5QI8zB4/Q47TD7//VKKAMB5/Qo+NgBz1eHHxAjzsvPFkkw0AyLP5YhaCLj9tkxCokqDbEGizgIBsWPaIJIwnzuG8xvXje4+fBzH30BaO3phWx/8YMAAAAAElFTkSuQmCC";

const distanceBetween = (point1, point2) => {
  return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
};

const angleBetween = (point1, point2) => {
  return Math.atan2( point2.x - point1.x, point2.y - point1.y );
};

function getFilledInPixels(stride, context, canvasSize) {
  if (!stride || stride < 1) { stride = 1; }

  const pixels   = context.getImageData(0, 0, canvasSize, canvasSize);
  const pixelData = pixels.data;
  const l = pixelData.length;
  const total = (l / stride);
  let count = 0;

  // Iterate over all pixels
  for(let i = count; i < l; i += stride) {
    if (parseInt(pixelData[i]) === 0) {
      count++;
    }
  }

  return Math.round((count / total) * 100);
}


const getMouse = (e, canvas) => {
  let offsetX = 0;
  let offsetY = 0;
  let x;
  let y;

  if (canvas.offsetParent !== undefined) {
    do {
      offsetX += canvas.offsetLeft;
      offsetY += canvas.offsetTop;
    } while ((canvas = canvas.offsetParent));
  }

  x = (e.pageX || e.touches[0].clientX) - offsetX;
  y = (e.pageY || e.touches[0].clientY) - offsetY;

  return { x, y };
};

const MATCH_ICONS = [
  <Fragment />,
  <Ethereum />,
  <MakerDAO />,
  <BAT />,
  <ChainLink />,
  <Matic />,
  <Augur />,
]

class ScratchItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawing: false,
      lastPoint: 0,
      canvasSize: 100,
      canvasContext: null,
      brush: null,
      isRevealed: false,
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handlePercentage = this.handlePercentage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cardRevealed !== this.props.cardRevealed) {
      this.handleClear();
      this.setState({ isRevealed: true });
    }
  }

  handleEnter = (event) => {
    this.setState({ isDrawing: true,  lastPoint: getMouse(event, this.canvasItem) });
  };

  handleLeave = () => {
    this.setState({ isDrawing: false });
  };

  handleClear = () => {
    const { canvasContext, canvasSize } = this.state;
    canvasContext.clearRect(0, 0, canvasSize, canvasSize)
  }

  handlePercentage = (filledInPixels, iconType) => {
    filledInPixels = filledInPixels || 0;
    const revealed = filledInPixels > 25;
    if (revealed && !this.state.isRevealed) this.props.itemRevealed(iconType);
    return revealed;
  };

  handleMove = (event) => {
    const { isDrawing, lastPoint, canvasContext, canvasSize, brush } = this.state;
    if (!isDrawing) return;
    event.preventDefault();

    const currentPoint = getMouse(event, this.canvasItem);
    const dist = distanceBetween(lastPoint, currentPoint);
    const angle = angleBetween(lastPoint, currentPoint);
    let x;
    let y;

    for (let i = 0; i < dist; i++) {
      x = lastPoint.x + (Math.sin(angle) * i) - 10;
      y = lastPoint.y + (Math.cos(angle) * i) - 10;
      canvasContext.globalCompositeOperation = 'destination-out';
      canvasContext.drawImage(brush, x, y);
    }
    const { iconType } = this.props;
    const isRevealed = this.handlePercentage(getFilledInPixels(32, canvasContext, canvasSize), iconType);

    this.setState({ lastPoint: currentPoint, isRevealed });
  };

  componentDidMount() {
    const { canvasSize } = this.state;

    this.canvasItem.width = canvasSize;
    this.canvasItem.height = canvasSize;
    const canvasContext = this.canvasItem.getContext('2d');

    const canvasGradient = canvasContext.createLinearGradient(0, 0, 0, canvasSize);
    canvasGradient.addColorStop(0, "#C5C5C5");
    canvasGradient.addColorStop(1, "#626262");
    canvasContext.fillStyle = canvasGradient;
    canvasContext.fillRect(0,0, canvasSize, canvasSize);

    const brush = new Image();
    brush.src = brushURI;

    this.setState({ canvasContext, brush }, () => {
      if (this.props.cardRevealed) {
        this.handleClear();
        this.setState({ isRevealed: true });
      }
    });
  }

  render() {
    const { isRevealed } = this.state;
    const { iconType, winType, cardWin, cardRevealed } = this.props;
    return (
      <div className={`scratch-card__item
           ${isRevealed ? 'revealed' : ''}
           ${winType === iconType && cardWin && cardRevealed ? 'winning' : ''}`}>
        <canvas
          ref={canvasItem => this.canvasItem = canvasItem}
          className="scratch-card__item__canvas"
          onMouseDown={this.handleEnter}
          onMouseMove={this.handleMove}
          onMouseUp={this.handleLeave}
          onTouchStart={this.handleEnter}
          onTouchMove={this.handleMove}
          onTouchEnd={this.handleLeave}
        />
        <div className="scratch-card__item__content">
          {MATCH_ICONS[iconType]}
        </div>
      </div>
    );
  }
}

ScratchItem.propTypes = {
  iconType: PropTypes.number.isRequired,
  cardRevealed: PropTypes.bool,
  itemRevealed: PropTypes.func.isRequired,
  winType: PropTypes.number,
  cardWin: PropTypes.bool,
};

const mapDispatchToProps = { itemRevealed }

export default connect(null, mapDispatchToProps)(ScratchItem);
