import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { withRouter } from "react-router-dom";
import { toggleModal } from "../../actions/modal";
import { toggleDrawerRight } from "../../actions/drawer";
import { formatNumber } from "../../services/utils";
import { DP } from "../../config/constants";
import { weiToEth } from "../../services/ethereum/main";

import RollLogo from "../Decorative/GameLogos/RollLogo";
import SpinLogo from "../Decorative/GameLogos/SpinLogo";
import ScratchLogo from "../Decorative/GameLogos/ScratchLogo";
import RouletteLogo from "../Decorative/GameLogos/RouletteLogo";
import EthLogo from "../Decorative/EthLogo";
import MaticLogo from "../Decorative/MaticLogo";
import BiconomyLogo from "../Decorative/BiconomyLogo";
import InfoIcon from "../Decorative/Icons/InfoIcon";
import ArrowBack from "../Decorative/Icons/ArrowBack";
import ChatIcon from "../Decorative/Icons/ChatIcon";

import "./Header.scss";

const Header = ({
  walletBalance,
  userContractBalance,
  toggleModal,
  drawerRightOpen,
  toggleDrawerRight,
  drawerRightComponent,
  unreadMessages,
  adminOnline,
  activeGame,
  history
}) => {
  return (
    <header className="header header--roll">
      <div className="container">
        <section className="header__section header__section--title">
          {activeGame === "roll" && <RollLogo />}
          {activeGame === "spin" && <SpinLogo />}
          {activeGame === "scratch" && <ScratchLogo />}
          {activeGame === "roulette" && <RouletteLogo />}
          {history.location.pathname === "/bad-token" && (
            <span className="page-title">BAD Token</span>
          )}
          {history.location.pathname === "/affiliates" && (
            <span className="page-title">Affiliates</span>
          )}
          <EthLogo />
          <MaticLogo />
          <BiconomyLogo />
        </section>
        <section className="header__section header__section--balance">
          <div className="header__section__balance__single">
            <Tooltip title={weiToEth(userContractBalance)} theme="roll">
              <p className="header__section__balance__label">
                Contract ETH:
                <span
                  className="header__section__balance__value"
                  data-currency="eth"
                >
                  {formatNumber(weiToEth(userContractBalance), DP)}
                </span>
              </p>
            </Tooltip>
          </div>
          <div className="header__section__balance__single">
            <Tooltip title={weiToEth(walletBalance)} theme="roll">
              <p className="header__section__balance__label">
                Matic ETH:
                <span
                  className="header__section__balance__value"
                  data-currency="eth"
                >
                  {formatNumber(weiToEth(walletBalance), DP)}
                </span>
              </p>
            </Tooltip>
          </div>
          {userContractBalance !== "0" && (
            <div className="header__section__balance__single">
              <button
                className="btn btn--primary"
                onClick={() => toggleModal("Withdraw")}
              >
                Withdraw From Contract
              </button>
            </div>
          )}
          <div className="header__section__balance__single">
            <button
              className="btn btn--primary"
              onClick={() => toggleModal("MoveWETH", { isMedium: true, activeTab: "deposit" })}
            >
              Deposit ETH
            </button>
          </div>
          <div className="header__section__balance__single">
            <button
              className="btn btn--primary"
              onClick={() => toggleModal("MoveWETH", { isMedium: true, activeTab: "withdraw" })}
            >
              Withdraw ETH
            </button>
          </div>
          <div className="header__section__balance__single">
            {drawerRightOpen && drawerRightComponent === "AccountInfo" && (
              <ArrowBack clicked={() => toggleDrawerRight()} />
            )}
            {drawerRightComponent !== "AccountInfo" && (
              <InfoIcon
                clicked={() => toggleDrawerRight(true, "AccountInfo")}
                type={2}
              />
            )}
          </div>
        </section>

        <section className="header__section--mobile-controls">
          {drawerRightComponent !== "AccountInfo" && (
            <div className="mobile-controls__single">
              <InfoIcon
                clicked={() => toggleDrawerRight(true, "AccountInfo")}
                type={2}
              />
            </div>
          )}
          {drawerRightComponent !== "Chat" && (
            <div className="mobile-controls__single">
              <ChatIcon
                numberOfUnreadMsg={unreadMessages}
                isAdminOnline={adminOnline}
                clicked={() => toggleDrawerRight(true, "Chat")}
              />
            </div>
          )}
          {drawerRightOpen && (
            <div className="mobile-controls__single">
              <ArrowBack clicked={() => toggleDrawerRight()} />
            </div>
          )}
        </section>
      </div>
    </header>
  );
};

Header.propTypes = {
  walletBalance: PropTypes.string.isRequired,
  userContractBalance: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  drawerRightOpen: PropTypes.bool.isRequired,
  drawerRightComponent: PropTypes.string.isRequired
};

const mapStateToProps = ({
  account,
  drawer,
  chat,
  games
}) => ({
  walletBalance: account.walletBalance,
  userContractBalance: account.userContractBalance,
  drawerRightOpen: drawer.drawerRightOpen,
  drawerRightComponent: drawer.drawerRightComponent,
  unreadMessages: chat.unreadMessages,
  adminOnline: chat.adminOnline,
  activeGame: games.activeGame
});

const mapDispatchToProps = {
  toggleModal,
  toggleDrawerRight,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
