import {
  GET_LEADERBOARD_STATS_REQUEST,
  GET_LEADERBOARD_STATS_SUCCESS,
  GET_LEADERBOARD_STATS_ERROR,

  GET_LEADERBOARD_DATA_REQUEST,
  GET_LEADERBOARD_DATA_SUCCESS,
  GET_LEADERBOARD_DATA_ERROR,
} from "../actionTypes/leaderboardStats";
import {getLeaderboardDataFromApi, getLeaderboardStatsFromApi} from '../services/api';


export const getLeaderboardStats = () => async (dispatch) => {
  dispatch({ type: GET_LEADERBOARD_STATS_REQUEST });
  try {
    await dispatch(getLeaderboardPrize());
    const payload = await getLeaderboardStatsFromApi();
    dispatch({ type: GET_LEADERBOARD_STATS_SUCCESS, payload: { data: payload.data } });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_LEADERBOARD_STATS_ERROR, payload: error.message });
  }
};

export const getLeaderboardPrize = () => async (dispatch) => {
  dispatch({ type: GET_LEADERBOARD_DATA_REQUEST });
  try {
    const payload = await getLeaderboardDataFromApi();
    dispatch({ type: GET_LEADERBOARD_DATA_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_LEADERBOARD_DATA_ERROR, payload: error.message });
  }
};
