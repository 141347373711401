import React from "react";

export default function InfoIcon({ color = "#FFFFFF", size = 15, type = 1, clicked }) {
  if (type === 1)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
          fill={color}
        />
        <g clipPath="url(#clip0)">
          <path
            d="M9.28098 10.8608L9.16121 11.3503C8.80192 11.4922 8.51492 11.6001 8.3012 11.6743C8.08726 11.7487 7.83867 11.7858 7.55543 11.7858C7.12047 11.7858 6.78215 11.6792 6.54086 11.4676C6.29956 11.2551 6.17888 10.9858 6.17888 10.6592C6.17888 10.5327 6.18764 10.4027 6.20584 10.2702C6.2242 10.1375 6.25339 9.98809 6.29334 9.82108L6.74236 8.23181C6.7823 8.07963 6.81626 7.93544 6.84345 7.79908C6.87103 7.66357 6.88432 7.53889 6.88432 7.42665C6.88432 7.22369 6.8423 7.08173 6.75864 7.00191C6.67499 6.92232 6.51535 6.88192 6.27836 6.88192C6.16228 6.88192 6.04298 6.90051 5.92137 6.93646C5.79923 6.97257 5.69483 7.00744 5.60718 7.03986L5.72725 6.5499C6.0214 6.43013 6.30256 6.32758 6.57151 6.24246C6.84046 6.15711 7.09458 6.1144 7.33495 6.1144C7.76692 6.1144 8.10024 6.2188 8.33431 6.4276C8.56839 6.63655 8.68539 6.90742 8.68539 7.24121C8.68539 7.31027 8.6777 7.43195 8.66119 7.60588C8.64505 7.78018 8.61502 7.93989 8.57123 8.08516L8.12413 9.66805C8.08749 9.79519 8.05453 9.94053 8.02588 10.1041C7.9963 10.2666 7.98217 10.3907 7.98217 10.474C7.98217 10.6842 8.02903 10.8277 8.12298 10.9039C8.21762 10.9802 8.38064 11.0182 8.61217 11.0182C8.72088 11.0182 8.84456 10.9989 8.98199 10.961C9.11904 10.9231 9.21898 10.8899 9.28098 10.8608ZM9.39436 4.21531C9.39436 4.4911 9.29043 4.72663 9.08155 4.9203C8.87321 5.11466 8.62216 5.21191 8.32848 5.21191C8.03387 5.21191 7.7822 5.11466 7.57148 4.9203C7.36115 4.72656 7.25575 4.4911 7.25575 4.21531C7.25575 3.94007 7.36115 3.70415 7.57148 3.50803C7.78182 3.31221 8.03395 3.21434 8.32848 3.21434C8.62209 3.21434 8.87321 3.31244 9.08155 3.50803C9.29058 3.70415 9.39436 3.94014 9.39436 4.21531Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="8.57143"
              height="8.57143"
              fill={color}
              transform="translate(3.21484 3.21423)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  if (type === 2)
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={clicked}
      >
        <path
          d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
          fill="#F4BD24"
        />
      </svg>
    );
}
