import React from "react";

export default function RollLogo({ width = 210 }) {
  return (
    <svg width={width} height={width / 7.65} viewBox="0 0 153 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.5722 8.172C39.1882 8.54533 39.7016 9.07733 40.1122 9.768C40.5416 10.4587 40.7562 11.3827 40.7562 12.54C40.7562 13.6787 40.3922 14.7147 39.6642 15.648C38.3202 17.4027 36.2762 18.28 33.5322 18.28C32.3376 18.28 31.1522 18.112 29.9762 17.776C28.8189 17.4213 27.9602 17.0667 27.4002 16.712L26.5602 16.208L27.7642 13.8C29.1082 14.4533 30.3216 14.78 31.4042 14.78C32.4869 14.78 33.0282 14.4347 33.0282 13.744C33.0282 13.352 32.8136 12.988 32.3842 12.652C31.9736 12.2973 31.4602 11.9613 30.8442 11.644C30.2469 11.3267 29.6402 10.9627 29.0242 10.552C28.4269 10.1227 27.9136 9.53467 27.4842 8.788C27.0736 8.02267 26.8682 7.136 26.8682 6.128C26.8682 4.336 27.5496 2.97333 28.9122 2.04C30.2936 1.10667 31.9549 0.64 33.8962 0.64C35.0536 0.64 36.1362 0.78 37.1442 1.06C38.1709 1.34 38.8989 1.62 39.3282 1.9L40.0002 2.32L38.9082 4.7C37.9562 4.28933 36.9949 4.084 36.0242 4.084C35.0722 4.084 34.5962 4.448 34.5962 5.176C34.5962 5.568 34.8016 5.92267 35.2122 6.24C35.6416 6.55733 36.1549 6.86533 36.7522 7.164C37.3682 7.444 37.9749 7.78 38.5722 8.172Z" fill="white"/>
        <path d="M50.9135 0.64C51.9961 0.64 53.1628 0.836 54.4135 1.228C55.6828 1.62 56.7188 2.11467 57.5215 2.712L56.3455 5.204C55.5241 4.88667 54.6188 4.728 53.6295 4.728C50.8668 4.728 49.4855 6.33333 49.4855 9.544C49.4855 11.0933 49.8588 12.3067 50.6055 13.184C51.3708 14.0427 52.2855 14.472 53.3495 14.472C54.4321 14.472 55.4401 14.248 56.3735 13.8L57.6335 16.208C55.7481 17.5893 53.4988 18.28 50.8855 18.28C48.2721 18.28 46.1348 17.5147 44.4735 15.984C42.8308 14.4533 42.0095 12.26 42.0095 9.404C42.0095 6.52933 42.8215 4.35467 44.4455 2.88C46.0695 1.38667 48.2255 0.64 50.9135 0.64Z" fill="white"/>
        <path d="M67.9344 9.516C68.7931 9.516 69.4744 9.25467 69.9784 8.732C70.4824 8.20933 70.7344 7.57467 70.7344 6.828C70.7344 6.06267 70.4917 5.49333 70.0064 5.12C69.5397 4.728 68.9331 4.532 68.1864 4.532H67.0664L66.5904 9.516H67.9344ZM71.0984 1.032C73.0957 1.032 74.6917 1.508 75.8864 2.46C77.0997 3.39333 77.7064 4.784 77.7064 6.632C77.7064 7.304 77.5664 7.948 77.2864 8.564C77.0064 9.16133 76.6704 9.64667 76.2784 10.02C75.4571 10.7667 74.7011 11.2987 74.0104 11.616L73.5904 11.784L76.6144 18H71.7144L66.2824 12.54L65.7504 18H60.2904L58.6104 1.032H71.0984Z" fill="white"/>
        <path d="M91.5607 18L90.4407 15.172H83.3007L82.2367 18H78.2607L82.9927 1.032H92.9607L97.6927 18H91.5607ZM89.0127 11.532L86.8007 5.932L84.6727 11.532H89.0127Z" fill="white"/>
        <path d="M113.886 1.032L114.446 6.352L109.938 5.456L108.678 18H103.218L101.958 5.456L97.4221 6.352L97.9821 1.032H113.886Z" fill="white"/>
        <path d="M123.921 0.64C125.004 0.64 126.171 0.836 127.421 1.228C128.691 1.62 129.727 2.11467 130.529 2.712L129.353 5.204C128.532 4.88667 127.627 4.728 126.637 4.728C123.875 4.728 122.493 6.33333 122.493 9.544C122.493 11.0933 122.867 12.3067 123.613 13.184C124.379 14.0427 125.293 14.472 126.357 14.472C127.44 14.472 128.448 14.248 129.381 13.8L130.641 16.208C128.756 17.5893 126.507 18.28 123.893 18.28C121.28 18.28 119.143 17.5147 117.481 15.984C115.839 14.4533 115.017 12.26 115.017 9.404C115.017 6.52933 115.829 4.35467 117.453 2.88C119.077 1.38667 121.233 0.64 123.921 0.64Z" fill="white"/>
        <path d="M145.114 18L144.47 11.56L139.486 12.008L138.898 18H133.438L131.758 1.032H140.578L139.934 7.5H144.078L143.434 1.032H152.254L150.574 18H145.114Z" fill="white"/>
        <path d="M0 0.999999C0 0.447714 0.447715 0 1 0H4.6C5.15229 0 5.6 0.447715 5.6 1V4.6C5.6 5.15229 5.15229 5.6 4.6 5.6H0.999999C0.447714 5.6 0 5.15229 0 4.6V0.999999Z" fill="white"/>
        <path d="M7.19984 0.999999C7.19984 0.447714 7.64755 0 8.19984 0H11.7998C12.3521 0 12.7998 0.447715 12.7998 1V4.6C12.7998 5.15229 12.3521 5.6 11.7998 5.6H8.19983C7.64755 5.6 7.19984 5.15229 7.19984 4.6V0.999999Z" fill="white"/>
        <path d="M14.4002 0.999999C14.4002 0.447714 14.8479 0 15.4002 0H19.0002C19.5525 0 20.0002 0.447715 20.0002 1V4.6C20.0002 5.15229 19.5525 5.6 19.0002 5.6H15.4002C14.8479 5.6 14.4002 5.15229 14.4002 4.6V0.999999Z" fill="white"/>
        <path d="M0 8.19983C0 7.64755 0.447715 7.19984 1 7.19984H4.6C5.15229 7.19984 5.6 7.64755 5.6 8.19984V11.7998C5.6 12.3521 5.15229 12.7998 4.6 12.7998H0.999999C0.447714 12.7998 0 12.3521 0 11.7998V8.19983Z" fill="white"/>
        <path d="M7.19984 8.19983C7.19984 7.64755 7.64755 7.19984 8.19984 7.19984H11.7998C12.3521 7.19984 12.7998 7.64755 12.7998 8.19984V11.7998C12.7998 12.3521 12.3521 12.7998 11.7998 12.7998H8.19983C7.64755 12.7998 7.19984 12.3521 7.19984 11.7998V8.19983Z" fill="white"/>
        <path d="M14.4002 8.19983C14.4002 7.64755 14.8479 7.19984 15.4002 7.19984H19.0002C19.5525 7.19984 20.0002 7.64755 20.0002 8.19984V11.7998C20.0002 12.3521 19.5525 12.7998 19.0002 12.7998H15.4002C14.8479 12.7998 14.4002 12.3521 14.4002 11.7998V8.19983Z" fill="white"/>
        <path d="M0 15.3997C0 14.8474 0.447715 14.3997 1 14.3997H4.6C5.15229 14.3997 5.6 14.8474 5.6 15.3997V18.9997C5.6 19.552 5.15229 19.9997 4.6 19.9997H0.999999C0.447714 19.9997 0 19.552 0 18.9997V15.3997Z" fill="white"/>
        <path d="M7.19984 15.3997C7.19984 14.8474 7.64755 14.3997 8.19984 14.3997H11.7998C12.3521 14.3997 12.7998 14.8474 12.7998 15.3997V18.9997C12.7998 19.552 12.3521 19.9997 11.7998 19.9997H8.19983C7.64755 19.9997 7.19984 19.552 7.19984 18.9997V15.3997Z" fill="white"/>
        <path d="M14.4002 15.3997C14.4002 14.8474 14.8479 14.3997 15.4002 14.3997H19.0002C19.5525 14.3997 20.0002 14.8474 20.0002 15.3997V18.9997C20.0002 19.552 19.5525 19.9997 19.0002 19.9997H15.4002C14.8479 19.9997 14.4002 19.552 14.4002 18.9997V15.3997Z" fill="white"/>
    </svg>
  );
}
