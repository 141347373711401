import React from "react";
import { connect } from "react-redux";
import { changePage } from "../../actions/pagination";

import "./Pagination.scss";

const Pagination = ({ totalPages, currentPage, changePage }) => {
  if (totalPages > 1) {
    let pages = [];
    let page = 1;
    const limit = 5;
    const leftover = Math.floor(limit / 2);
    const breaking = Math.ceil(limit - leftover);

    for (let i = 0; i < totalPages; i++) {
      pages.push(page++);
    }

    if (
      currentPage > breaking &&
      totalPages - leftover >= currentPage &&
      totalPages > limit
    ) {
      pages = pages.slice(currentPage - breaking, currentPage + leftover);
    } else if (totalPages - leftover < currentPage && totalPages > limit) {
      pages = pages.slice(totalPages - limit, totalPages);
    } else {
      pages = pages.slice(0, limit);
    }
    return (
      <div className="pagination">
        {currentPage !== 1 && (
          <span className="page" onClick={() => changePage(currentPage - 1)}>
            Prev
          </span>
        )}
        {pages.map(item => (
          <span
            onClick={() => changePage(item)}
            className={`page${currentPage === item ? " active" : ""}`}
            key={item}
          >
            {item}
          </span>
        ))}
        {currentPage !== totalPages && (
          <span className="page" onClick={() => changePage(currentPage + 1)}>
            Next
          </span>
        )}
      </div>
    );
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  changePage
};

export default connect(
  null,
  mapDispatchToProps
)(Pagination);
