import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import {
  formatAcc,
  spinGameTypeToColor
} from "../../../../../../services/utils";
import { toggleModal } from "../../../../../../actions/modal";

const SpinPlayersActivity = ({ playersActivity, toggleModal }) => {
  return (
    <table className="player-activity">
      <thead className="player-activity__head">
        <tr>
          <th>No</th>
          <th>Player</th>
          <th>
            Win history{" "}
            <span className="game-status">
              <span className="game-status__circle" />
              <span className="game-status__title">
                <span>Outcome</span>
                <span>Chosen color</span>
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody className="player-activity__body">
        {playersActivity.map(
          ({ account, gameType, history, username, amount, round }, index) => (
            <tr className="player-activity__single" key={account}>
              <td>{index + 1}.</td>
              <td>
                <a
                  className="acc"
                  onClick={() =>
                    toggleModal("PlayersHistory", {
                      isLarge: true,
                      account
                    })
                  }
                >
                  {username && username}
                  {!username && formatAcc(account, 5, 5)}
                </a>
              </td>

              {history.length > 0 && history[0] !== 0 && (
                <td>
                  {history.map((item, index) => {
                    if (item.gameType === 0) return;
                    return (
                      <Tooltip
                        key={`${account}-${index}`}
                        theme="roll"
                        position="bottom-start"
                        arrow="true"
                        animation="fade"
                        distance={15}
                        html={
                          <div className="game-status__tooltip">
                            <span
                              className={`game-status__circle ${spinGameTypeToColor(
                                item.gameType
                              )} ${item.result === 2 ? "success" : "danger"}`}
                            />
                            <div className="game-status__tooltip__result">
                              <span className="game-status__tooltip__title">
                                {item.result === 2 ? "win" : "lose"}
                              </span>
                              <span className="game-status__tooltip__title">
                                {spinGameTypeToColor(item.gameType)}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <span
                          className={`game-status__circle ${spinGameTypeToColor(
                            item.gameType
                          )} ${item.result === 2 ? "success" : "danger"}`}
                        />
                      </Tooltip>
                    );
                  })}
                </td>
              )}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

SpinPlayersActivity.propTypes = {
  playersActivity: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ playersActivity }) => ({
  playersActivity: playersActivity.otherPlayersActivity
});

const mapDispatchToProps = {
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpinPlayersActivity);
