import {
  CHANGE_PLAYERS_HISTORY_PER_PAGE,
  GET_PLAYERS_HISTORY_ERROR,
  GET_PLAYERS_HISTORY_REQUEST,
  GET_PLAYERS_HISTORY_SUCCESS
} from "../actionTypes/playersHistory";
import {
  getBetsFromRollContract,
  getLastRoundFromRollContract,
  getTotalBetsFromRollContract
} from "../services/ethereum/roll";
import {
  betValToName,
  getGameNameFromAddress,
  spinGameTypeToColor
} from "../services/utils";
import {
  calculateWinChance,
  calculateWinPrize
} from "./contractConstants/roll";
import { weiToEth } from "../services/ethereum/main";
import {
  getBetsFromSpinContract,
  getTotalBetsFromSpinContract
} from "../services/ethereum/spin";
import {
  calculateSpinWinChance,
  calculateSpinWinPrize
} from "./contractConstants/spin";
import {
  getDonBetsFromContract,
  getQueryIdsFromDoNFinishedFromContract
} from "../services/ethereum/doubleOrNothing";
import { changePage } from "./pagination";
import {
  getPreviousRollsFromRouletteContract,
  getTotalBetsFromRouletteContract
} from "../services/ethereum/roulette";
import { getRouletteBetForRoundFromApi } from "../services/api";
import {
  checkBetArrWinning,
  checkIfPlayerHasWonRouletteBet,
  getPlayersBetDetailsFromRouletteHistory
} from "./contractConstants/roulette";
import BigNumber from "bignumber.js";
import {
  getPreviousRollsFromScratchContract,
  getPreviousRoundsFromScratchContract,
  getTotalBetsFromScratchContract
} from "../services/ethereum/scratch";

export const getPlayersHistory = (
  account,
  page = 1,
  perPage = 5,
  game = "spin"
) => async (dispatch, getState) => {
  dispatch({ type: GET_PLAYERS_HISTORY_REQUEST });
  try {
    let totalBets;
    if (game === "roll") {
      totalBets = await getTotalBetsFromRollContract(account);
    }
    if (game === "spin") {
      totalBets = await getTotalBetsFromSpinContract(account);
    }
    if (game === "roulette") {
      totalBets = await getTotalBetsFromRouletteContract(account);
    }
    if (game === "scratch") {
      totalBets = await getTotalBetsFromScratchContract()(account);
    }
    let DoN;
    if (game === "doubleOrNothing") {
      const donArr = await getQueryIdsFromDoNFinishedFromContract(account);
      DoN = donArr.reverse();
      totalBets = DoN.length;
    }

    let totalPages = Math.ceil(totalBets / perPage);
    let payload = {game, totalPages, currentPage: page, perPage, data: []};

    let startingBet = page === 1 ? totalBets : totalBets - (page - 1) * perPage;

    let iterate = perPage > startingBet ? startingBet : perPage;

    const {state, lastRound} = await getLastRoundFromRollContract(account);

    const isLastRoundActive = state === "2";

    if (!isLastRoundActive && totalBets === 1) return;

    if (game === "roll" || game === "spin" || game === "doubleOrNothing") {
      for (let i = 0; i < iterate; i++) {
        if (game === "doubleOrNothing") {
          const {
            amount,
            finalized,
            game: gameAddress,
            sender,
            won
          } = await getDonBetsFromContract(DoN[startingBet - i - 1]);

          const gameName = getGameNameFromAddress(gameAddress);
          const {houseEdge} = getState().gamesConstants;
          const profit = won ? calculateWinPrize(amount, 50, houseEdge) : "0";

          payload.data.push({
            profit,
            gameName,
            won,
            amount: weiToEth(amount)
          });
        }

        if (game === "spin") {
          const {
            amount,
            gameType,
            round,
            selectedColor,
            won
          } = await getBetsFromSpinContract(account, startingBet - i - 1, account);

          const chosenColor = spinGameTypeToColor(gameType);
          const roundOutcome = spinGameTypeToColor(selectedColor);
          const winChance = calculateSpinWinChance(+gameType);
          const winPrize = calculateSpinWinPrize(amount, winChance);
          let profit = won ? winPrize : 0;

          if (roundOutcome !== "N/A") {
            payload.data.push({
              chosenColor,
              profit,
              roundOutcome,
              round,
              won,
              amount: weiToEth(amount),
              gameType: Number(gameType)
            });
          }
        }

        if (game === "roll") {
          const {
            0: number,
            1: round,
            2: gameType,
            3: amount,
            4: selectedNumber,
            5: won
          } = await getBetsFromRollContract(account, startingBet - i - 1, account);

          if (round === lastRound && !isLastRoundActive) {
            if (startingBet > perPage) iterate += 1;
            continue;
          }

          let roundOutcome;
          let chosenNumber;

          if (round === "0") {
            roundOutcome = "N/A";
            chosenNumber = "N/A";
          } else {
            roundOutcome = selectedNumber;
          }

          if (+gameType > 2) {
            if (gameType === "3") {
              chosenNumber = ["Even", "Odd"][+number];
              roundOutcome =
                +selectedNumber !== 0 ? (+selectedNumber % 2 ? "Odd" : "Even") : "Zero";
            }
            if (+gameType === 4) {
              chosenNumber = ["Dragon", "Tiger", "Tie"][+number];
              roundOutcome = betValToName(selectedNumber);
            }
          } else {
            chosenNumber = +number;
          }

          const chance = calculateWinChance(
            +gameType,
            +number,
            +number
          );

          const {houseEdge} = getState().rollConstants;
          const profit = won ? calculateWinPrize(amount, chance, houseEdge) : 0;

          payload.data.push({
            chosenNumber,
            round,
            won,
            profit,
            roundOutcome,
            amount: Number(weiToEth(amount)),
            gameType: Number(gameType)
          });
        }
      }
    }

    if (game === "scratch") {
      let ScratchRounds = await getPreviousRoundsFromScratchContract(perPage, page - 1, account);
      payload.data = await getPreviousRollsFromScratchContract(
        perPage,
        account,
        page - 1,
        ScratchRounds
      );
    }

    if (game === "roulette") {
      let history = await getPreviousRollsFromRouletteContract(
        perPage,
        account,
        page - 1
      );

      payload.data = await new Promise(async (resolve, reject) => {
        const data = history.map(async item => {
          const res = await getRouletteBetForRoundFromApi(
            account,
            `${item.round}`
          );
          const {data} = await res.json();
          item.data = data;
          item.roundOutcome = item.number;
          const {hasWon, winPrize} = checkIfPlayerHasWonRouletteBet(
            +item.number,
            item.data
          );
          item.won = hasWon;
          item.profit = "0";
          if (item.won) {
            const amountPlaced = item.data
              .map(dataItem =>
                checkBetArrWinning(+item.number, dataItem.betArr).hasWon
                  ? dataItem.betArr[0]
                  : "0"
              )
              .reduce((total, num) =>
                new BigNumber(total).plus(num).toString()
              );
            console.log(amountPlaced);
            item.profit = new BigNumber(winPrize).plus(amountPlaced).toString();
          }
          item.amount = !!item.data.length
            ? item.data
              .map(({betArr}) => betArr[0])
              .reduce((total, num) =>
                new BigNumber(total).plus(num).toString()
              )
            : "0";

          item.betDetails = getPlayersBetDetailsFromRouletteHistory(item.data);
          return item;
        });
        return await Promise.all(data).then(data => resolve(data));
      });
    }


    dispatch({ type: GET_PLAYERS_HISTORY_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_PLAYERS_HISTORY_ERROR, payload: e.message });
  }
};

export const changePlayersHistoryPerPage = payload => dispatch => {
  dispatch(changePage(1));
  dispatch({ type: CHANGE_PLAYERS_HISTORY_PER_PAGE, payload });
};
