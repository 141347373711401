import {
  RollGameContract,
  RollGameEventsContract,
  ethersProvider,
  AutoBetContract,
  rollGameAddress,
  AutoBetEventsContract
} from "../contract";
import { getUserDataFromApi } from "../api";
import { getBlock, hexToNumber, numberToHex } from "./main";

/**
 * Gets round from the contract Rounds[]
 *
 * @return {Promise<{}>}
 */
export const getRollRoundFromContract = async (round, from) => {
  const contract = await RollGameContract();
  return contract.methods.rounds(round).call({ from });
};

/**
 * Gets max bet value from contract settings
 *
 * @return {Promise<string>}
 */
export const getMaxBetFromRollContract = async from => {
  const contract = await RollGameContract();
  return await contract.methods.MAX_BET().call({ from });
};

/**
 * Gets duration of the round from the contract settings
 *
 * @return {Promise<number>}
 */
export const getRoundTimeFromRollContract = async from => {
  const contract = await RollGameContract();
  const roundTime = await contract.methods.ROUND_TIME().call({ from });
  return Number(roundTime);
};

/**
 * Gets benchmark maximum bet size from the contract settings
 *
 * @return {Promise<number>}
 */
export const getBenchmarkMaxBetSizeFromRollContract = async from => {
  const contract = await RollGameContract();
  const benchmarkMaximumBetSize = await contract.methods
    .BENCHMARK_MAXIMUM_BET_SIZE()
    .call({ from });
  return Number(benchmarkMaximumBetSize);
};

export const getTokensWonParametersFromRollContract = async (address) => {
  const contract = await RollGameContract();
  return contract.methods.getTokensWonParameters(address).call();
};

/**
 * Gets house commission in percents from the contract settings
 *
 * @return {Promise<number>}
 */
export const getHouseEdgeFromRollContract = async from => {
  const contract = await RollGameContract();
  const houseEdge = await contract.methods.HOUSE_EDGE().call({ from });
  return Number(houseEdge);
};

/**
 * Gets if max bet is dynamic or not from the contract settings
 *
 * @return {Promise<boolean>}
 */
export const getUseDynamicMaxBetFromRollContract = async from => {
  const contract = await RollGameContract();
  return await contract.methods.USE_DYNAMIC_MAX_BET().call({ from });
};

/**
 * Gets total number of bets for the given address
 *
 * @param address
 * @return {Promise<number>}
 */
export const getTotalBetsFromRollContract = async (address) => {
  const contract = await RollGameContract();
  const totalBets = await contract.methods.getTotalBets(address).call();
  return Number(totalBets);
};

/**
 * Formats players activity retrieved from the contracts event
 *
 * @param data
 * @return {{gameType: (*|number), history: number[], account: (*|number|string), username: *}}
 */
const formatSingleRollPlayerActivity = async data => {
  const { name } = await getUserDataFromApi(data.user);
  console.log("USER PLAYED: ", name, data.user);
  return {
    account: data.user,
    gameType: data.gameType,
    history: hexToNumber(data.history._hex)
      .toString()
      .split("")
      .map(Number),
    username: name,
    amount: hexToNumber(data.amount._hex),
    number: hexToNumber(data.number._hex),
    round: hexToNumber(data.round._hex)
  };
};

export const getAutobetInfo = async (player) => {
  const autoBetContract = AutoBetContract();
  return autoBetContract.methods.getBetInfo(player, rollGameAddress).call();
}

/**
 * Gets players activity for a given round from the contracts event logs
 *
 * @param round
 * @return {Promise<any>}
 */
export const getOtherPlayersActivityFromRollContract = (fromBlock, round) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = RollGameEventsContract;
      const betEvent = contract.interface.events["BetPlayed"];
      const filter = contract.filters.BetPlayed(round, null);
      filter.fromBlock = fromBlock;

      let logs = await ethersProvider.getLogs(filter);
      logs = logs.map(log => betEvent.decode(log.data, log.topics));
      logs = logs.map(log => formatSingleRollPlayerActivity(log));

      Promise.all(logs).then(logs => resolve(logs));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

/**
 * Gets starting block timestamp for a given round
 *
 * @param fromBlock
 * @param lastRound
 * @return {Promise<any>}
 */
export const getRoundTimestampFromRollContract = (fromBlock, lastRound) =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(`ROLL LAST ROUND: #${lastRound} \n________________`);
      const filter = RollGameEventsContract.filters.RoundStarted(
        numberToHex(lastRound)
      );
      filter.fromBlock = fromBlock;
      const logs = await ethersProvider.getLogs(filter);
      const blockNumber = logs[0].blockNumber;
      const { timestamp } = await getBlock(blockNumber);
      resolve(timestamp);
    } catch (err) {
      reject(err);
    }
  });

/**
 * Gets result for a given address
 * and number of a played bet
 *
 * @param address
 * @param id
 * @param from
 * @return {Promise<{number, gameType, amount, selectedNumber, won}>}
 */
export const getBetsFromRollContract = async (address, id, from) => {
  const contract = await RollGameContract();
  return await contract.methods.getBet(address, id).call({ from });
};

export const getParamsForTokenCalculationFromRollContract = async (
  chance,
  from
) => {
  const contract = await RollGameContract();
  const {
    gbs,
    gwp,
    maxB,
    minB
  } = await contract.methods
    .getParamsForTokenCaluclation(chance)
    .call({ from });
  return { gbs, gwp, maxB, minB };
};

/**
 * Gets last played round from the contract
 *
 * @return {Promise<{state, selectedNumber, lastRound}>}
 */
export const getLastRoundFromRollContract = from =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await RollGameContract();
      const lastRound = await contract.methods.lastRound().call({ from });
      const { state, selectedNumber } = await contract.methods
        .rounds(lastRound)
        .call({ from });
      resolve({ state, selectedNumber, lastRound });
    } catch (e) {
      reject(e);
    }
  });

/**
 * Places a regular bet for different game types
 * Also places a first bet if nickname is included
 *
 * @param betValue
 * @param amount
 * @param gameSubType
 * @param gameType
 * @param account
 * @param betWithContractBalance
 * @param betWithContractBonusBalance
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<any>}
 */
export const betFromRollContract = (
  betValue,
  amount,
  gameSubType,
  gameType,
  account,
  betWithContractBalance,
  betWithContractBonusBalance,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      let functionSignature;

      const contract = await RollGameContract();

      if (betWithContractBalance) {
        if (gameType === 3 || gameType === 4) {
          functionSignature = contract.methods
            .placeBetWithContractBalance(gameSubType, amount, gameType)
            .encodeABI();
        } else {
          functionSignature = contract.methods
            .placeBetWithContractBalance(betValue, amount, gameType)
            .encodeABI();
        }
      } else if (betWithContractBonusBalance) {
        if (gameType === 3 || gameType === 4) {
          functionSignature = contract.methods
            .placeBonusBet(gameSubType, amount, gameType)
            .encodeABI();
        } else {
          functionSignature = contract.methods
            .placeBonusBet(betValue, amount, gameType)
            .encodeABI();
        }
      } else {
        if (gameType === 3 || gameType === 4) {
          functionSignature = contract.methods
            .placeBet(gameSubType, amount, gameType)
            .encodeABI();
        } else {
          functionSignature = contract.methods
            .placeBet(betValue, amount, gameType)
            .encodeABI();
        }
      }
      const txObject = {
        functionSignature,
        contract,
        account
      }
      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Placing a bet...",
          successTitle: "Placed a bet.",
          errorTitle: "Bet denied.",
          notifyOf: "place-bet",
          game: "roll",
          amount,
          gameType,
          gameSubType,
          betValue
        },
        dispatch,
        getState
      );

      resolve(status);
    } catch (err) {
      reject(err);
    }
  });

/**
 * A listener for BetPlayed event
 *
 * @param callback
 */
export const listenToOtherPlayersActivityFromRollContract = callback => {
  console.log("ROLL BET PLAYED EVENT OUT");
  RollGameEventsContract.on(
    "BetPlayed",
    async (round, user, gameType, amount, number, history) => {
      console.log("ROLL BET PLAYED EVENT IN");
      if (gameType < 5) {
        callback(
          await formatSingleRollPlayerActivity({
            user,
            gameType,
            number,
            history,
            round,
            amount
          })
        );
      }
    }
  );
};

/**
 * Gets previous selected numbers from the contract using RoundFinished event logs
 *
 * @param startRound
 * @param numberOfRolls
 * @return {Promise<any>}
 */
export const getPreviousRollsFromRollContract = (
  startRound,
  numberOfRolls = 8
) =>
  new Promise(async (resolve, reject) => {
    try {
      const rounds = [];

      for (let i = startRound; i > startRound - numberOfRolls; i--) {
        if (i > 0) rounds.push(i);
      }

      const promise = await Promise.all(rounds.map(async round => {
        const { selectedNumber: number } = await getRollRoundFromContract(round);
        return { round, number };
      }));

      resolve(promise);
    } catch (err) {
        reject(err);
    }
  });

/**
 * A listener for RoundFinished event
 *
 * @param callback
 */
export const listenToRoundFinishedFromRollContract = callback => {
  console.log("ROLL ROUND FINISHED EVENT OUT");
  RollGameEventsContract.on("RoundFinished", async (round, selectedNumber) => {
    await callback({
      round: hexToNumber(round._hex),
      number: hexToNumber(selectedNumber._hex)
    });
    console.log("ROLL ROUND FINISHED EVENT IN");
  });
};

/**
 * A listener for RoundStarted event
 *
 * @param callback
 */
export const listenToRoundStartedFromRollContract = callback => {
  console.log("ROLL ROUND STARTED EVENT OUT");
  RollGameEventsContract.on("RoundStarted", async (id, { blockNumber }) => {
    console.log("ROLL ROUND ID: ", hexToNumber(id._hex), "\nBLOCK: ", blockNumber);
    const { timestamp } = await getBlock(blockNumber);
    console.log("ROLL ROUND STARTED EVENT IN: ", timestamp);
    await callback({id: hexToNumber(id._hex), timestamp, blockNumber});
  });
};

export const listenForBetPlayedEventFromRollContract = (player, callback) => {
  let filter = RollGameEventsContract.filters.BetPlayed(null, player);

  RollGameEventsContract.on(filter, async (round, user, { blockNumber }) => {
    await callback(round, user, blockNumber);
  });
}

export const listenRollAutoBetStartedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStarted(player, rollGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, noOfBets, {blockNumber}) => {
    await callback(user, game, noOfBets, blockNumber);
  })
}

export const listenRollAutoBetStoppedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStopped(player, rollGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, remainingBets, {blockNumber}) => {
    await callback(user, game, remainingBets, blockNumber);
  })
}


/**
 * A listener for UseDynamicBetChanged event
 *
 * @param callback
 */
export const listenToUseDynamicBetChangedFromRollContract = callback => {
  console.log("USE DYNAMIC BET CHANGED OUT");
  RollGameEventsContract.on("UseDynamicBetChanged", useDynamic => {
    console.log("USE DYNAMIC BET CHANGED IN");
    callback({
      useDynamic
    });
  });
};

/**
 * A listener for BenchmarkParameterSet event
 *
 * @param callback
 */
export const listenToBenchmarkParameterSetFromRollContract = callback => {
  console.log("BENCHMARK MAX BET SIZE OUT");
  RollGameEventsContract.on("BenchmarkParameterSet", benchmarkMaxBetSize => {
    console.log("BENCHMARK MAX BET SIZE IN");
    callback({
      benchmarkMaxBetSize
    });
  });
};

/**
 * A listener for MinBetSet event
 *
 * @param callback
 */
export const listenToMinBetSetFromRollContract = callback => {
  console.log("MIN BET SET OUT");
  RollGameEventsContract.on("MinBetSet", minBetAmount => {
    console.log("MIN BET SET IN");
    callback({
      minBetAmount
    });
  });
};

/**
 * A listener for MaxBetSet event
 *
 * @param callback
 */
export const listenToMaxBetSetFromRollContract = callback => {
  console.log("MAX BET SET OUT");
  RollGameEventsContract.on("MaxBetSet", maxBetAmount => {
    console.log("MAX BET SET IN");
    callback({
      maxBetAmount
    });
  });
};

export const getRollBetsAllowedFromContract = async () => {
  const contract = await RollGameContract();
  return await contract.methods.BETS_ALLOWED().call();
};

export const listenToRollPausedFromContract = callback => {
  console.log("ROLL PAUSED EVENT OUT");
  RollGameEventsContract.on("GamePaused", async bool => {
    console.log("ROLL PAUSED EVENT IN");
    callback({ bool });
  });
};
