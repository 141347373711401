import { apiPath } from "../config/client";
import { signString } from "./utils";
import { biconomyAPIPath } from "../config/client";

export const getCheckpointStatus = async (blockNumber) => {
  try {
    if (blockNumber) {
      await fetch(`${biconomyAPIPath}/${blockNumber}`, {
        method: "GET"
      });
      return true;
    }
  } catch (e) {
    return false;
  }
};

export const getUserDataFromApi = async (address, name = null) => {
  try {
    if (address) {
      let response = await fetch(`${apiPath}/user?address=${address}`, {
        method: "GET",
        credentials: "include"
      });
      let { name, banned, admin, _id, usesAffiliateCode } = await response.json();
      if (response.status === 200) {
        return { name, banned, admin, _id, usesAffiliateCode };
      } else {
        return { name: "", banned: false, admin: false, usesAffiliateCode: false };
      }
    }
    if (name) {
      let response = await fetch(`${apiPath}/user?name=${name}`, {
        method: "GET",
        credentials: "include"
      });
      let { address, banned, admin, _id, usesAffiliateCode } = await response.json();
      return { address, banned, admin, _id, usesAffiliateCode };
    }
  } catch (e) {
    console.log(e);
  }
};

export const getUserAcceptedTOSFromApi = async address => {
  try {
    const response = await fetch(
      `${apiPath}/user/acceptedTOS?address=${address}`,
      {
        method: "GET",
        credentials: "include"
      }
    );
    const { acceptedTOS } = await response.json();
    return acceptedTOS;
  } catch (e) {
    console.log(e);
  }
};

export const postNicknameFromApi = async nickname => {
  return await fetch(`${apiPath}/user/nickname`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ nickname })
  });
};

export const postUserAcceptedTOSFromApi = async account => {
  return await fetch(`${apiPath}/user/acceptedTOS?address=${account}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ acceptedTOS: true })
  });
};

export const getUserTitleFromApi = async address => {
  try {
    let response = await fetch(
      `${apiPath}/user/title?address=${address}`,
      {
        method: "GET",
        credentials: "include"
      }
    );
    let { titleNumber } = await response.json();
    return titleNumber;
  } catch (e) {
    console.log(e);
  }
};

export const getAutoBetMaxBetsFromApi = async () => {
  try {
    let response = await fetch(
      `${apiPath}/user/auto-bet/max-bets`,
      {
        method: "GET",
        credentials: "include"
      }
    );
    let { maxBets } = await response.json();
    return { maxBets, status: response.status };
  } catch (error) {
    console.log(error);
  }
};

export const postUserTitleFromApi = async (titleNumber, from) => {
  return await fetch(`${apiPath}/user/title?from=${from}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ titleNumber })
  });
};

export const registerFromApi = async (rememberUser, ethereumAccount) => {
  const signature =
    await signString(`badBitGame-${ethereumAccount}`, `${ethereumAccount}`);
  const data = {
    rememberUser,
    signature,
    msg: `badBitGame-${ethereumAccount}`
  };
  return await fetch(`${apiPath}/user/register`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const sendMessageFromApi = async (messageValue, nickname = "") => {
  const msgData = {
    message: messageValue,
    name: nickname
  };
  return await fetch(`${apiPath}/chat/message`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(msgData)
  });
};

export const getAdminsFromApi = async () =>
  await fetch(`${apiPath}/chat/admins`, {
    method: "GET",
    credentials: "include"
  });

export const banUserFromApi = async address => {
  const data = { userAddress: address };
  return await fetch(`${apiPath}/chat/ban-user`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const removeBanUserFromApi = async address => {
  const data = { userAddress: address };
  return await fetch(`${apiPath}/chat/unban-user`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const getMessagesFromApi = async () =>
  await fetch(`${apiPath}/chat/messages`, {
    method: "GET",
    credentials: "include"
  });

export const deleteMessageFromApi = async id => {
  const data = { messageId: id };
  return await fetch(`${apiPath}/chat/delete-message`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const getAdminsOnlineFromApi = async () =>
  await fetch(`${apiPath}/chat/admins-online`, {
    method: "GET",
    credentials: "include"
  });

export const getRouletteBetForRoundFromApi = async (userAddress, round) =>
  await fetch(
    `${apiPath}/user/roulette-bets?address=${userAddress}&round=${round}`,
    {
      method: "GET",
      credentials: "include"
    }
  );

export const sendRouletteBetsFromApi = async (data) => {
  return await fetch(`${apiPath}/user/roulette-bets`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const sendScratchBetsFromApi = async (data) => {
  return await fetch(`${apiPath}/user/scratch-bets`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const getCurrentTime = async () => {
  try {
    const response = await fetch(
      `${apiPath}/utils/get-current-time`
    );
    const data = await response.json();
    return data.timestamp;
  } catch (e) {
    console.error(e);
  }
  return Math.ceil(Date.now() / 1000);
};

export const postVerificationSubmissionPartOne = async (userAddress, data) => {
  return await fetch(`${apiPath}/user/verify/1?userAddress=${userAddress}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const postVerificationSubmissionPartTwo = async (formData) => {
  return await fetch(`${apiPath}/user/verify/2`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });
};

export const postWithdrewEth = async (amount) =>
  fetch(`${apiPath}/user/withdrew-eth`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount})
  });

export const postMovedToWeth = async (amount) =>
  fetch(`${apiPath}/user/moved-to-weth`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount})
  });

export const getUsersStatusFromApi = async (userAddress) => {
  const response = await fetch(
    `${apiPath}/user/status?address=${userAddress}`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const postDismissedVerification = async (userAddress, step) => {
  return await fetch(`${apiPath}/user/dismiss-verification/${step}?userAddress=${userAddress}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};

export const postUseAffCode = async (usesAffiliateCode) => {
  const data = {
    usesAffiliateCode
  };
  return await fetch(`${apiPath}/user/use-aff-code`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const postTransferAffToken = async (transferTo) => {
  const data = {
    transferTo
  };
  return await fetch(`${apiPath}/user/transfer-aff-token`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const postBreakAffiliation = async () => {
  return await fetch(`${apiPath}/user/break-affiliation`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};

export const postOwnAffCode = async (affiliateCode, tokenId) => {
  const data = {
    affiliateCode,
    tokenId,
  };
  return await fetch(`${apiPath}/user/set-aff-code`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const checkAffCodeAvailability = async (affCode) => {
  return await fetch(`${apiPath}/user/aff-code-available?affCode=${affCode}`,{
    method: "GET",
    credentials: "include"
  });
};

export const getUsersCurrentCycleAffiliateStats = async (userAddress) => {
  return await fetch(`${apiPath}/user/affiliate-stats/current?userAddress=${userAddress}`,{
    method: "GET",
    credentials: "include"
  });
};

export const getUsersPastCyclesAffiliateStats = async (userAddress) => {
  return await fetch(`${apiPath}/user/affiliate-stats/past?userAddress=${userAddress}`,{
    method: "GET",
    credentials: "include"
  });
};

export const clearSession = async () => {
  return await fetch(`${apiPath}/user/clear-cookie`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};

export const postEtherMoveMaticToEthereum = async (amount, service, transactionHash) =>
  fetch(`${apiPath}/user/move/ether/matic-ethereum`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount, service, transactionHash})
  });

export const postEtherMoveEthereumToMatic = async (amount, service) =>
  fetch(`${apiPath}/user/move/ether/ethereum-matic`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount, service})
  });

export const postBadMoveMaticToEthereum = async (amount) =>
  fetch(`${apiPath}/user/move/bad/matic-ethereum`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount})
  });

export const postBadMoveEthereumToMatic = async (amount) =>
  fetch(`${apiPath}/user/move/bad/ethereum-matic`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({amount})
  });

export const getEtherMoveMaticToEthereum = async (page, perPage) => {
  const response = await fetch(
    `${apiPath}/user/move/ether/matic-ethereum?page=${page}&perPage=${perPage}`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const getEtherMoveEthereumToMatic = async (page, perPage) => {
  const response = await fetch(
    `${apiPath}/user/move/ether/ethereum-matic?page=${page}&perPage=${perPage}`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const getBadMoveMaticToEthereum = async (page, perPage) => {
  const response = await fetch(
    `${apiPath}/user/move/bad/matic-ethereum?page=${page}&perPage=${perPage}`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const getBadMoveEthereumToMatic = async (page, perPage) => {
  const response = await fetch(
    `${apiPath}/user/move/bad/ethereum-matic?page=${page}&perPage=${perPage}`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const getAffiliateRequest = async () => {
  const response = await fetch(
    `${apiPath}/user/affiliate-request`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return { status: response.status, data: await response.json() }
};

export const postAffiliateRequest = async (data) => {
  const response = await fetch(
    `${apiPath}/user/affiliate-request`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({...data})
    }
  );
  return { status: response.status, data: await response.json() }
};

export const getLeaderboardStatsFromApi = async () => {
  const response = await fetch(
    `${apiPath}/user/leaderboard-stats`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  return await response.json();
};

export const getLeaderboardDataFromApi = async () => {
  const promise = await fetch(
    `${apiPath}/user/leaderboard-data`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  const response = await promise.json();
  return {
    status: promise.status,
    leaderboardsBonusPositions: response.leaderboardsBonusPositions,
    leaderboardsWeeklyPrize: response.leaderboardsWeeklyPrize,
  }
};

export const getPromotionDataFromApi = async () => {
  const promise = await fetch(
    `${apiPath}/user/promotion-data`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  const response = await promise.json();
  return {
    status: promise.status,
    promotionFlashRacePrize: response.promotionFlashRacePrize,
    promotionRunsUntil: response.promotionRunsUntil,
    promotionIsRunning: response.promotionIsRunning,
    promotionBonusPositions: response.promotionBonusPositions,
  }
};

export const getPromotionStatsFromApi = async () => {
  const promise = await fetch(
    `${apiPath}/user/promotion-stats`,
    {
      method: "GET",
      credentials: "include"
    }
  );
  const response = await promise.json();
  return {
    status: promise.status,
    data: response.data,
  }
};
