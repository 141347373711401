import React from "react";
import { connect } from 'react-redux';

import LeaderboardBanner from "./LeaderboardBanner/LeaderboardBanner";
import "./Footer.scss";

const Footer = ({ activeGame }) => {

  let gameContractsLink = 'https://explorer.matic.network';

  if (activeGame === 'roll') gameContractsLink += '/address/0xCBDD8F8Fb5Bc31CA156C1A032cBA7B7d5ce709AC/transactions';
  if (activeGame === 'spin') gameContractsLink += '/address/0xdaF9dA2629ff037bCC2E34ed7925a68D1AF71157/transactions';
  if (activeGame === 'roulette') gameContractsLink += '/address/0x5e2c36Ad506A3c17c7648Dc5C451108FbfE370cf/transactions';
  if (activeGame === 'scratch') gameContractsLink += '/address/0x32edbc1835972f768f37aba4FFD14Fb8bb3ad895/transactions';

  return (
    <footer className="footer">
      {activeGame !== "" && (
        <div className="container">
          <p className="source-code">This game is provably fair! It is run trustlessly by a smart contract deployed on the Matic Network blockchain. <a
            href={gameContractsLink} target="_blank">You can see the code here.</a></p>
        </div>
      )}

      <div className="container">
        <p className="source-code">BadBit.Games is managed and operated by Lifeblood Gaming S.R.L., a Costa Rican entity <a
          href="https://badbit.games/costaricanlicense.pdf" target="_blank">licensed to operate online casinos.</a></p>
      </div>

      <div className="container">
        <LeaderboardBanner className="footer__banner" />
      </div>
      <div className="container">
        <p>
          By using this site you acknowledge that you do not reside in a
          location where playing lottery games, gambling, or betting over the
          internet or in any other way or form is illegal. If you reside in a
          location where these activities are illegal, please refrain from
          engaging in any such activities on this website. Playing under these
          circumstances would be a violation to our{" "}
          <a href="https://badbit.games/terms-of-service">Terms of Service</a>.
          You also acknowledge that you are at least 18 years of age if you
          click or execute any gambling-related links or activities on this
          website; even if gambling by persons under the age of 18 years is
          legal in your location. Given that the laws and regulations involving
          online gaming are different everywhere, players are advised to check
          the applicable laws within their own jurisdiction or region to
          ascertain the legality of participating in the activities offered on
          this website. Failure to do so will result in violation to our{" "}
          <a href="https://badbit.games/terms-of-service">Terms of Service</a>{" "}
          and grant the company the right to ban you from participating on this
          website and the Ethereum blockchain-based games offered therein.
        </p>
        <p>
          The games offered by BadBit.Games are hosted on the Ethereum public
          blockchain and managed by open-sourced, trustless smart contracts.
          When you start playing these games, please be reminded that online
          gambling is an entertainment vehicle that carries the risk of loss of
          your digital assets as well as risk of addiction. Players should be
          aware of these risks and conduct themselves accordingly. If you want
          to learn more about gambling addiction or if you feel you have a
          gambling addiction please visit{" "}
          <a href="https://www.casino.org/gambling-addiction/" target="_blank">
            https://www.casino.org/gambling-addiction/
          </a>{" "}
          for information and resources on this matter.
        </p>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

const mapStateToProps = ({ games }) => ({
  activeGame: games.activeGame,
});

export default connect(mapStateToProps)(Footer);
