import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { Tooltip } from "react-tippy";
import BigNumber from "bignumber.js";
import { depositETH } from "../../../../actions/account";
import { withdrawETH } from "../../../../actions/withdrawal";
import { listenForNewBlock, removeListenForNewBlock } from '../../../../services/ethereum/main';
import {
  ethToWei, getBlockNumber, getGasPrice, weiToEth,
} from '../../../../services/ethereum/main';
import {
  EthereumDepositContract, MaticDepositContract, POSRootChainManagerContract,
} from '../../../../services/contract';

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import { SELECT_CUSTOM_STYLE } from "../../../../config/constants";
import "./Move.scss";
import { formatNumber } from '../../../../services/utils';
import Loader from '../../../Loader/Loader';

const tabType = ["deposit", "withdraw"];
const DEPOSIT_OPTIONS = [
  { value: "posBridge", label: "Matic PoS Bridge" },
  { value: "badbitService", label: "BadBit Service" },
];
const WITHDRAW_OPTIONS = [
  { value: "posBridge", label: "Matic PoS Bridge" },
  { value: "badbitService", label: "BadBit Service" },
];

class MoveWETH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountWeth: "",
      amountWethWei: "",
      amountEth: "",
      amountEthWei: "",
      activeTab: tabType[0],
      maxEthBalance: "",
      selectedDepositType: DEPOSIT_OPTIONS[1],
      selectedWithdrawType: WITHDRAW_OPTIONS[1],
      badbitServiceDepositGasFee: "",
      badbitServiceMaxDepositAmount: "",
      badbitServiceMaxWithdrawAmount: "",
      maticPosDepositEstimateGasFee: "",
      maticPosWithdrawEstimateGasFee: "",
      etherPrice: "0",
      badbitDepositFee: "0",
      badbitWithdrawFee: "0.005",
      blockLimit: 1,
      withdrawalBlockedUntil: 0,
      isWithdrawalBlocked: false,
      blocksLeft: 0,
      loading: true,
    };

    this.handleWethAmountInput = this.handleWethAmountInput.bind(this);
    this.handleEthAmountInput = this.handleEthAmountInput.bind(this);
    this.withdrawMaxEth = this.withdrawMaxEth.bind(this);
    this.withdrawMaxWeth = this.withdrawMaxWeth.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.setDepositType = this.setDepositType.bind(this);
    this.setWithdrawType = this.setWithdrawType.bind(this);
    this.handleBlurEthAmountInput = this.handleBlurEthAmountInput.bind(this);
  }

  componentWillMount() {
    const { activeTab } = this.props;
    if (activeTab) {
      this.setState({
        activeTab
      });
    }
  }

  async componentDidMount() {
    const gasPrice = await getGasPrice();
    const { account } = this.props;

    const maxEthBalance = new BigNumber(this.props.ethBalance)
      .minus(new BigNumber(gasPrice).times("21000"))
      .toString();

    const badbitServiceDepositGasFee = weiToEth(new BigNumber(gasPrice).times(23000).toString());

    const ethereumDepositContract = await EthereumDepositContract();
    const maticDepositContract = await MaticDepositContract();
    const badbitServiceMaxDepositAmount = weiToEth(await ethereumDepositContract.methods.maxLimit().call());
    const badbitServiceMaxWithdrawAmount = weiToEth(await maticDepositContract.methods.maxLimit().call());

    const rootChainManagerContract = await POSRootChainManagerContract();

    const coinGeckoReq = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
    const ethPrice = (await coinGeckoReq.json());

    const blockLimit = Number(await maticDepositContract.methods.blockLimit().call());
    const lastWithdrawal = Number(await maticDepositContract.methods.lastWithdrawal(account).call());
    const withdrawalBlockedUntil = lastWithdrawal + blockLimit;
    const currentBlock = await getBlockNumber();
    const isWithdrawalBlocked = currentBlock < withdrawalBlockedUntil;
    let maticPosWithdrawEstimateGasFee = weiToEth(new BigNumber(gasPrice).times(195000).toString());
    let maticPosDepositEstimateGasFee = weiToEth(new BigNumber(gasPrice).times(67000).toString());

    console.log('lastWithdrawal: ', lastWithdrawal, blockLimit, currentBlock, withdrawalBlockedUntil, isWithdrawalBlocked);

    // if (!isWithdrawalBlocked) {
      // maticPosDepositEstimateGasFee = await rootChainManagerContract.methods.depositEtherFor(account).estimateGas({ from: account });
      // maticPosDepositEstimateGasFee = weiToEth(new BigNumber(maticPosDepositEstimateGasFee).times(gasPrice).toString());
      //
      // maticPosWithdrawEstimateGasFee = await maticDepositContract.methods.withdraw("0").estimateGas({ from: account });
      // maticPosWithdrawEstimateGasFee = weiToEth(new BigNumber(maticPosWithdrawEstimateGasFee).times(gasPrice).toString());
    // }


    this.setState({
      maxEthBalance,
      badbitServiceDepositGasFee,
      badbitServiceMaxDepositAmount,
      badbitServiceMaxWithdrawAmount,
      maticPosDepositEstimateGasFee,
      maticPosWithdrawEstimateGasFee,
      blockLimit,
      withdrawalBlockedUntil,
      isWithdrawalBlocked,
      etherPrice: coinGeckoReq.status === 200 ? ethPrice.ethereum.usd : "0",
      loading: false,
    });

    const onNewBlockCallback = (_block) => {
      const block = Number(_block);
      if (block > withdrawalBlockedUntil) {
        this.setState({
          isWithdrawalBlocked: false,
          blocksLeft: 0,
        })
        removeListenForNewBlock();
      } else {
        const blocksLeft = withdrawalBlockedUntil - block;
        if (this.state.blocksLeft >= blocksLeft || this.state.blocksLeft === 0) {
          this.setState({
            blocksLeft,
            isWithdrawalBlocked: true,
          })
        }
      }
    }

    if (isWithdrawalBlocked) {
      listenForNewBlock(onNewBlockCallback);
    }
  }

  componentWillUnmount() {
    removeListenForNewBlock();
  }

  setDepositType = (selectedType) => this.setState({ selectedDepositType: selectedType })
  setWithdrawType = (selectedType) => this.setState({ selectedWithdrawType: selectedType })

  handleTabs = data => {
    const { activeTab } = this.state;
    if (activeTab === data) return;
    this.setState({
      activeTab: data
    });
  };

  handleWethAmountInput = async e => {
    const { value } = e.target;

    this.setState({
      amountWeth: Number(value),
      amountWethWei: ethToWei(value),
      amountEth: Number(value),
      amountEthWei: ethToWei(value),
    });
  };

  handleEthAmountInput = async e => {
    const { value } = e.target;

    this.setState({
      amountEth: Number(value),
      amountEthWei: ethToWei(value),
      amountWeth: Number(value),
      amountWethWei: ethToWei(value),
    });
  };

  handleBlurEthAmountInput = async () => {
    try {
      // const { amountEthWei } = this.state;
      //
      // const { account } = this.props;
      // const gasPrice = await getGasPrice();
      // const rootChainManagerContract = await POSRootChainManagerContract();
      // let maticPosDepositEstimateGasFee =
      //   await rootChainManagerContract.methods.depositEtherFor(account).estimateGas({ from: account, value: amountEthWei });
      // maticPosDepositEstimateGasFee = weiToEth(new BigNumber(maticPosDepositEstimateGasFee).times(gasPrice).toString());
      //
      // const maticDepositContract = await MaticDepositContract();
      // let maticPosWithdrawEstimateGasFee = await maticDepositContract.methods.withdraw(amountEthWei).estimateGas({ from: account });
      // maticPosWithdrawEstimateGasFee = weiToEth(new BigNumber(maticPosWithdrawEstimateGasFee).times(gasPrice).toString());
      //
      // this.setState({
      //   maticPosDepositEstimateGasFee,
      //   maticPosWithdrawEstimateGasFee,
      // });
    } catch (error) {
      console.log(error);
    }
  };

  withdrawMaxEth = async () => {
    const { ethBalance } = this.props;
    const gasPrice = await getGasPrice();
    let balance = ethBalance !== "0" ? new BigNumber(ethBalance)
      .minus(new BigNumber(gasPrice).times("21000"))
      .toString() : "0";
    if (new BigNumber(balance).isNegative()) {
      balance = "0"
    }
    this.setState({
      amountEth: Number(weiToEth(balance)),
      amountEthWei: balance,
      amountWeth: Number(weiToEth(balance)),
      amountWethWei: balance
    });

    await this.handleBlurEthAmountInput()
  };

  withdrawMaxWeth = async () => {
    const { walletBalance } = this.props;
    this.setState({
      amountWeth: Number(weiToEth(walletBalance)),
      amountWethWei: walletBalance,
      amountEth: Number(weiToEth(walletBalance)),
      amountEthWei: walletBalance
    });

    await this.handleBlurEthAmountInput()
  };

  render() {
    const {
      toggleModal,
      withdrawMaticPOS,
      withdrawBadbitService,
      depositMaticPOS,
      depositBadbitService,
      walletBalance,
      ethBalance,
    } = this.props;

    const {
      activeTab, selectedWithdrawType, selectedDepositType, badbitServiceDepositGasFee,
      badbitServiceMaxDepositAmount, badbitServiceMaxWithdrawAmount, maticPosDepositEstimateGasFee,
      maticPosWithdrawEstimateGasFee, etherPrice, badbitDepositFee, badbitWithdrawFee, isWithdrawalBlocked,
    } = this.state;

    const isWithdrawDisabled =
      (activeTab === tabType[1] && selectedWithdrawType.value === "badbitService" && isWithdrawalBlocked) ||
      this.state.amountEthWei === "" ||
      this.state.amountWethWei === "" ||
      new BigNumber(this.state.amountEthWei).lte(0) ||
      new BigNumber(this.state.amountWethWei).lte(0) ||
      (activeTab === tabType[0]
        ? new BigNumber(ethBalance).lt(this.state.amountEthWei)
        : new BigNumber(walletBalance).lt(this.state.amountWethWei)) ||
      (activeTab === tabType[1] && selectedWithdrawType.value === "badbitService"
        ? new BigNumber(this.state.amountWeth).gt(badbitServiceMaxWithdrawAmount)
        : false) ||
      (activeTab === tabType[1] && selectedWithdrawType.value === "badbitService"
        ? new BigNumber(this.state.amountWeth).lte(badbitWithdrawFee)
        : false) ||
      (activeTab === tabType[0] && selectedDepositType.value === "badbitService"
        ? new BigNumber(this.state.amountEth).gt(badbitServiceMaxDepositAmount)
        : false) ||
      (activeTab === tabType[0] && selectedDepositType.value === "badbitService"
        ? new BigNumber(this.state.amountEth).lte(badbitDepositFee)
        : false);

    return (
      <div className="modal__content modal__content--withdraw-tofro">
        <p className="title">
          {activeTab === tabType[0] ? "DEPOSIT ETH" : "WITHDRAW ETH"}
        </p>

        {this.state.loading && <Loader size={85} isFullScreen={false} text={"Loading..."} />}

        {!this.state.loading && (
          <Fragment>
            <div className="tabs__list">
              {tabType.map(item => (
                <div
                  key={item}
                  className={`tabs__item ${activeTab === item ? "active" : ""}`}
                  onClick={() => this.handleTabs(item)}
                >
                  {item}
                </div>
              ))}
            </div>

            <div className="tabs__content">

              {activeTab === tabType[0] &&
              <div className="tabs__select">
                <Select
                  className="select"
                  classNamePrefix="select"
                  styles={SELECT_CUSTOM_STYLE}
                  options={DEPOSIT_OPTIONS}
                  value={selectedDepositType}
                  onChange={this.setDepositType}
                  isSearchable={false}
                />
              </div>
              }

              {activeTab === tabType[1] &&
                <div className="tabs__select">
                  <Select
                    className="select"
                    classNamePrefix="select"
                    styles={SELECT_CUSTOM_STYLE}
                    options={WITHDRAW_OPTIONS}
                    value={selectedWithdrawType}
                    onChange={this.setWithdrawType}
                    isSearchable={false}
                  />
                </div>
              }

              {activeTab === tabType[0] && selectedDepositType.value === "posBridge" && (
                <p className={'tabs__select__info'}>Estimate Gas Fee {maticPosDepositEstimateGasFee} ETH{etherPrice !== "0" && ` ($${formatNumber(new BigNumber(etherPrice).times(maticPosDepositEstimateGasFee).toString(), 3)})`}. Deposit takes about 8 to 9 minutes after confirmation of your Ethereum transaction.</p>
              )}

              {activeTab === tabType[0] && selectedDepositType.value === "badbitService" && (
                <p className={'tabs__select__info'}>Estimate Gas Fee {badbitServiceDepositGasFee} ETH{etherPrice !== "0" && ` ($${formatNumber(new BigNumber(etherPrice).times(badbitServiceDepositGasFee).toString(), 3)})`}. Deposit takes about 1 minute after confirmation of your Ethereum transaction.</p>
              )}

              {activeTab === tabType[1] && selectedWithdrawType.value === "posBridge" && (
                <p className={'tabs__select__info'}>Estimate Gas Fee {maticPosWithdrawEstimateGasFee} ETH {etherPrice !== "0" && ` ($${formatNumber(new BigNumber(etherPrice).times(maticPosWithdrawEstimateGasFee).toString(), 3)})`}. Withdrawal takes about 30 minutes.</p>
              )}

              {activeTab === tabType[1] && selectedWithdrawType.value === "badbitService" && (
                <p className={'tabs__select__info'}>There is no gas fee for withdrawal using BadBit Service. Withdrawal takes about 2 minutes.</p>
              )}

              <div className="inputs__wrapper">
                <div className="input--withdraw__wrapper">
                  <div className="input">
                    <input
                      className="input--withdraw"
                      type="number"
                      placeholder="Enter amount"
                      step="0.001"
                      min="0"
                      max={
                        activeTab === tabType[0]
                          ? weiToEth(ethBalance)
                          : weiToEth(walletBalance)
                      }
                      value={
                        activeTab === tabType[0]
                          ? this.state.amountEth
                          : this.state.amountWeth
                      }
                      onChange={
                        activeTab === tabType[0]
                          ? this.handleEthAmountInput
                          : this.handleWethAmountInput
                      }
                      onBlur={this.handleBlurEthAmountInput}
                    />
                    <span>
                      {activeTab === tabType[0] ? "Wallet ETH" : "Matic ETH"}
                    </span>
                  </div>
                  <button
                    className="btn btn--light"
                    onClick={
                      activeTab === tabType[0]
                        ? this.withdrawMaxEth
                        : this.withdrawMaxWeth
                    }
                  >
                    <Tooltip
                      theme="roll"
                      title={`The maximum amount you can ${activeTab} is: ${Number(
                        weiToEth(
                          activeTab === tabType[0] ? this.state.maxEthBalance : walletBalance
                        )
                      )} ETH ${
                        activeTab === tabType[0]
                          ? "(The maximum you can deposit is slightly less than the balance in your Ethereum wallet, because this deposit is a main Ethereum network transaction and requires gas. You need to leave a small ETH balance in your Ethereum wallet to pay for this gas.)"
                          : ""
                      }`}
                    >
                      <InfoIcon color={"#465E71"} />
                    </Tooltip>{" "}
                    Max
                  </button>
                </div>
              </div>

              {activeTab === tabType[0] && selectedDepositType.value === "badbitService" && (
                <Fragment>
                  {this.state.amountWeth !== "" && <p className="exact-amount">
                    You will receive: <span>
                    {new BigNumber(this.state.amountWeth).minus(badbitDepositFee).gt(0) ? new BigNumber(this.state.amountWeth).minus(badbitDepositFee).toString() : "0"} Matic ETH
                  </span>
                  </p>}
                  <p className="info">
                    <ul>
                      <li>Secure and trustless service to move your ETH from main Ethereum network to Matic Network.</li>
                      <li>Deployed and maintained by BadBit.Games. Not audited.</li>
                      <li>Maximum amount per deposit is {badbitServiceMaxDepositAmount} ETH.</li>
                      <li>Fixed fee {badbitDepositFee} ETH per deposit.</li>
                      <li>Please ensure sufficient ETH in your wallet to pay for gas.</li>
                    </ul>
                  </p>
                </Fragment>
              )}

              {activeTab === tabType[0] && selectedDepositType.value === "posBridge" && (
                <p className="info">
                  <ul>
                    <li>Highly secure and trustless service to move your ETH from main Ethereum network to Matic Network.</li>
                    <li>Deployed and maintained by Matic Network team. Audited public service.</li>
                    <li>No maximum amount per deposit.</li>
                    <li>No fee charged by BadBit.Games to use this service.</li>
                    <li>Please ensure sufficient ETH in your wallet to pay for gas.</li>
                  </ul>
                </p>
              )}

              {activeTab === tabType[1] && selectedWithdrawType.value === "badbitService" && (
                <Fragment>
                  {this.state.amountEth !== "" && <p className="exact-amount">
                    You will receive: <span>
                    {new BigNumber(this.state.amountEth).minus(badbitWithdrawFee).gt(0) ? new BigNumber(this.state.amountEth).minus(badbitWithdrawFee).toString() : "0"} ETH
                  </span>
                  </p>}
                  <p className="info">
                    <ul>
                      <li>Secure and trustless service to move your ETH from Matic Network to the main Ethereum network.</li>
                      <li>Deployed and maintained by BadBit.Games. Not audited.</li>
                      <li>Maximum amount per withdrawal is {badbitServiceMaxWithdrawAmount} ETH.</li>
                      <li>Fixed fee {badbitWithdrawFee} ETH per withdrawal. No gas fees.</li>
                      <li>Each address is limited to 1 withdrawal every {this.state.blockLimit} blocks which is approximately {Math.round(+this.state.blockLimit / 30 / 60)} hours.</li>
                    </ul>
                  </p>
                  {this.state.blocksLeft > 0 && <p className="blocks-left">Withdrawal will be available in: {this.state.blocksLeft} blocks</p>}
                </Fragment>
              )}

              {activeTab === tabType[1] && selectedWithdrawType.value === "posBridge" && (
                <p className="info">
                  <ul>
                    <li>Highly secure and trustless service to move your ETH from Matic Network to main Ethereum network.</li>
                    <li>Deployed and maintained by Matic Network team. Audited public service.</li>
                    <li>No maximum amount per withdrawal.</li>
                    <li>No fee charged by BadBit.Games to use this service.</li>
                    <li>Please ensure sufficient ETH in your wallet to pay for gas.</li>
                  </ul>
                </p>
              )}
            </div>
          </Fragment>
        )}


        <div className="btn__group">
          {!this.state.loading && (
            <button
              className="btn btn--primary"
              onClick={() =>
                activeTab === tabType[0]
                  ? selectedDepositType.value === "posBridge"
                    ? depositMaticPOS(this.state.amountWethWei)
                    : depositBadbitService(this.state.amountWethWei, 1)
                  : selectedWithdrawType.value === "posBridge"
                    ? withdrawMaticPOS(this.state.amountWethWei)
                    : withdrawBadbitService(this.state.amountWethWei, 1)
              }
              disabled={isWithdrawDisabled}
            >
              {activeTab}
            </button>
          )}
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

MoveWETH.propTypes = {
  withdrawMaticPOS: PropTypes.func.isRequired,
  withdrawBadbitService: PropTypes.func.isRequired,
  depositMaticPOS: PropTypes.func.isRequired,
  depositBadbitService: PropTypes.func.isRequired,
  walletBalance: PropTypes.string.isRequired,
  ethBalance: PropTypes.string.isRequired
};

const mapStateToProps = ({ account }) => ({
  account: account.account,
  walletBalance: account.walletBalance,
  ethBalance: account.ethBalance,
  canUseGasFreeWithdraw: false,
});

const mapDispatchToProps = {
  depositMaticPOS: depositETH,
  depositBadbitService: depositETH,
  withdrawMaticPOS: withdrawETH,
  withdrawBadbitService: withdrawETH,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveWETH);
