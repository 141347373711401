import {
  GET_SPIN_GAME_STATE_REQUEST,
  GET_SPIN_GAME_STATE_SUCCESS,
  GET_SPIN_GAME_STATE_ERROR,
  GET_SPIN_ROUND_STATE_REQUEST,
  GET_SPIN_ROUND_STATE_SUCCESS,
  GET_SPIN_ROUND_STATE_ERROR,
  SET_SPIN_BET_AMOUNT,
  SET_SPIN_GAME_TYPE,
  SET_SPIN_WIN_CHANCE,
  SET_SPIN_WIN_PRIZE,
  SET_SPIN_LAST_WIN_PRIZE,
  SET_SPIN_LAST_ROUND_PLAYED,
  SET_SPIN_LAST_SELECTED_GAME_TYPE,
  SET_SPIN_LAST_USER_SELECTED_GAME_TYPE,
  PLACE_SPIN_BET_REQUEST,
  PLACE_SPIN_BET_SUCCESS,
  PLACE_SPIN_BET_ERROR,
  GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST,
  GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS,
  GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR,
  CLOSE_SPIN_ROUND,
  GET_ACC_SPIN_TOTAL_BETS_REQUEST,
  GET_ACC_SPIN_TOTAL_BETS_SUCCESS,
  GET_ACC_SPIN_TOTAL_BETS_ERROR,
  SET_SPIN_SHOW_CONTROLS,
  SET_SPIN_SHOW_SPINNER,
  SET_SPIN_PAUSED,
  SET_SPIN_LAST_ROUND_BLOCK,
  SPIN_LISTENERS_INITIATED,
  GET_SPIN_INIT_DATA_REQUEST,
  GET_SPIN_INIT_DATA_SUCCESS,
  GET_SPIN_INIT_DATA_ERROR,
  START_SPIN_AUTO_BET_ERROR,
  START_SPIN_AUTO_BET_REQUEST,
  START_SPIN_AUTO_BET_SUCCESS,
  STOP_SPIN_AUTO_BET_ERROR,
  STOP_SPIN_AUTO_BET_REQUEST,
  STOP_SPIN_AUTO_BET_SUCCESS,
  UPDATE_SPIN_AUTO_BET_REQUEST,
  UPDATE_SPIN_AUTO_BET_SUCCESS,
  UPDATE_SPIN_AUTO_BET_ERROR,
  SPIN_AUTO_BET_STARTED,
  SPIN_AUTO_BET_STOPPED,
  SET_SPIN_AUTO_BET_DATA,
  DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION,
} from "../../actionTypes/games/spin";

const INITIAL_STATE = {
  gettingGameState: false,
  gettingGameStateErr: "",
  spinPaused: false,

  spinLastRoundBlock: 0,
  roundState: "",
  gettingRoundState: false,
  gettingRoundStateErr: "",

  gameType: 1,
  lastSelectedGameType: 0,
  lastUserSelectedGameType: 0,

  betAmount: "0",
  bettingRound: 0,
  lastRoundPlayed: 0,
  lastWinPrize: 0,

  winChance: 4760,
  winPrize: 0,

  placingBet: false,
  placingBetError: "",

  showControls: true,
  showSpinner: false,

  spinTotalBets: 0,
  gettingSpinTotalBets: false,
  gettingSpinTotalBetsErr: "",

  lastRoundTimestamp: 0,
  gettingLastRoundTimestamp: false,
  gettingLastRoundTimestampError: "",

  spinListenersInitiated: false,
  gettingSpinInitData: false,
  gettingSpinInitDataErr: "",

  isAutoBetting: false,
  autoBettingRounds: 0,
  autoBettingCurrentRound: 0,
  autoBetBalanceNotificationDismissed: false,

  updatingAutoBetting: false,
  updatingAutoBettingErr: "",

  startingAutoBetting: false,
  startingAutoBettingErr: "",
  stoppingAutoBetting: false,
  stoppingAutoBettingErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SPIN_GAME_STATE_REQUEST:
      return { ...state, gettingGameState: true, gettingGameStateErr: "" };

    case GET_SPIN_GAME_STATE_SUCCESS:
      return { ...state, gettingGameState: false, gettingGameStateErr: "" };

    case GET_SPIN_GAME_STATE_ERROR:
      return {
        ...state,
        gettingGameState: false,
        gettingGameStateErr: payload
      };

    case GET_SPIN_ROUND_STATE_REQUEST:
      return { ...state, gettingRoundState: true, gettingRoundStateErr: "" };

    case GET_SPIN_ROUND_STATE_SUCCESS:
      return {
        ...state,
        gettingRoundState: false,
        roundState: payload.status,
        bettingRound: payload.round,
        gettingRoundStateErr: ""
      };

    case GET_SPIN_ROUND_STATE_ERROR:
      return {
        ...state,
        gettingRoundState: false,
        gettingRoundStateErr: payload
      };

    case SET_SPIN_SHOW_CONTROLS:
      return { ...state, showControls: payload };

    case SET_SPIN_SHOW_SPINNER:
      return { ...state, showSpinner: payload };

    case SET_SPIN_BET_AMOUNT:
      return { ...state, betAmount: payload };

    case SET_SPIN_GAME_TYPE:
      return { ...state, gameType: payload };

    case SET_SPIN_WIN_CHANCE:
      return { ...state, winChance: payload };

    case SET_SPIN_WIN_PRIZE:
      return { ...state, winPrize: payload };

    case SET_SPIN_LAST_ROUND_PLAYED:
      return {
        ...state,
        lastRoundPlayed: payload
      };

    case SET_SPIN_LAST_SELECTED_GAME_TYPE:
      return {
        ...state,
        lastSelectedGameType: payload
      };

    case SET_SPIN_LAST_USER_SELECTED_GAME_TYPE:
      return {
        ...state,
        lastUserSelectedGameType: payload
      };

    case SET_SPIN_LAST_WIN_PRIZE:
      return {
        ...state,
        lastWinPrize: payload
      };

    case GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST:
      return {
        ...state,
        gettingLastRoundTimestamp: true,
        gettingLastRoundTimestampError: ""
      };

    case GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        lastRoundTimestamp: payload,
        gettingLastRoundTimestampError: ""
      };

    case GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        gettingLastRoundTimestampError: payload
      };

    case GET_ACC_SPIN_TOTAL_BETS_REQUEST:
      return {
        ...state,
        gettingSpinTotalBets: true,
        gettingSpinTotalBetsErr: ""
      };

    case GET_ACC_SPIN_TOTAL_BETS_SUCCESS:
      return {
        ...state,
        gettingSpinTotalBets: false,
        gettingSpinTotalBetsErr: "",
        spinTotalBets: payload
      };

    case GET_ACC_SPIN_TOTAL_BETS_ERROR:
      return {
        ...state,
        gettingSpinTotalBets: false,
        gettingSpinTotalBetsErr: payload
      };

    case PLACE_SPIN_BET_REQUEST:
      return { ...state, placingBet: true, placingBetError: "" };

    case PLACE_SPIN_BET_SUCCESS:
      return { ...state, placingBet: false, placingBetError: "" };

    case PLACE_SPIN_BET_ERROR:
      return { ...state, placingBet: false, placingBetError: payload || "" };

    case CLOSE_SPIN_ROUND:
      return { ...state, roundState: "closed" };

    case SET_SPIN_PAUSED:
      return {
        ...state,
        spinPaused: payload
      };

    case SET_SPIN_LAST_ROUND_BLOCK: return {
      ...state,
      spinLastRoundBlock: payload,
    };

    case SPIN_LISTENERS_INITIATED: return {
      ...state,
      spinListenersInitiated: true
    };

    case GET_SPIN_INIT_DATA_REQUEST: return {
      ...state,
      gettingSpinInitData: true,
      gettingSpinInitDataErr: "",
    };

    case GET_SPIN_INIT_DATA_SUCCESS: return {
      ...state,
      gettingSpinInitData: false,
      gettingSpinInitDataErr: "",
    };

    case GET_SPIN_INIT_DATA_ERROR: return {
      ...state,
      gettingSpinInitData: false,
      gettingSpinInitDataErr: payload,
    };

    case START_SPIN_AUTO_BET_REQUEST: return {
      ...state,
      startingAutoBetting: true,
      startingAutoBettingErr: "",
    };

    case SPIN_AUTO_BET_STARTED: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: +payload
    };

    case START_SPIN_AUTO_BET_SUCCESS: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: "",
    };

    case START_SPIN_AUTO_BET_ERROR: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: payload,
    };

    case UPDATE_SPIN_AUTO_BET_REQUEST: return {
      ...state,
      updatingAutoBetting: true,
      updatingAutoBettingErr: "",
    };

    case UPDATE_SPIN_AUTO_BET_SUCCESS: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: "",
      autoBettingCurrentRound: payload
    };

    case UPDATE_SPIN_AUTO_BET_ERROR: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: payload,
    };

    case STOP_SPIN_AUTO_BET_REQUEST: return {
      ...state,
      stoppingAutoBetting: true,
      stoppingAutoBettingErr: "",
    };

    case STOP_SPIN_AUTO_BET_SUCCESS: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: "",
    };

    case SPIN_AUTO_BET_STOPPED: return {
      ...state,
      isAutoBetting: false,
      autoBettingRounds: 0,
      autoBettingCurrentRound: 0,
      autoBetBalanceNotificationDismissed: false,
    };

    case STOP_SPIN_AUTO_BET_ERROR: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: payload,
    };

    case SET_SPIN_AUTO_BET_DATA: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: payload.autoBettingRounds,
      autoBettingCurrentRound: payload.autoBettingCurrentRound,
    };

    case DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION: return {
      ...state,
      autoBetBalanceNotificationDismissed: true,
    };

    default:
      return state;
  }
};
