import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import BigNumber from "bignumber.js";
import {
  depositTokenToMatic
} from "../../../../actions/badToken";

import {
  withdrawBAD
} from "../../../../actions/withdrawal";
import { ethToWei, weiToEth } from "../../../../services/ethereum/main";

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import ArrowRightLong from "../../../Decorative/Icons/ArrowRightLong";
import "../MoveWETH/Move.scss";

const tabType = ["deposit", "withdraw"];

class MoveBAD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountMatic: "",
      amountMaticWei: "",
      amountEth: "",
      amountEthWei: "",
      activeTab: tabType[0]
    };

    this.handleMaticAmountInput = this.handleMaticAmountInput.bind(this);
    this.handleEthAmountInput = this.handleEthAmountInput.bind(this);
    this.withdrawMaxEth = this.withdrawMaxEth.bind(this);
    this.withdrawMaxMatic = this.withdrawMaxMatic.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.convertMaticToEth = this.convertMaticToEth.bind(this);
    this.convertEthToMatic = this.convertEthToMatic.bind(this);
  }

  handleTabs = data => {
    const { activeTab } = this.state;
    if (activeTab === data) return;
    this.setState({
      activeTab: data
    });
  };

  convertMaticToEth = (_amount, type = "eth") => {
    const amount = type === "eth" ? ethToWei(_amount) : _amount;
    return `${amount}`;
  };

  convertEthToMatic = (_amount, type = "eth") => {
    const amount = type === "eth" ? ethToWei(_amount) : _amount;
    return `${amount}`;
  };

  handleMaticAmountInput = e => {
    const { value } = e.target;
    this.setState({
      amountMatic: Number(value),
      amountMaticWei: ethToWei(value),
      amountEth: Number(weiToEth(this.convertMaticToEth(value))),
      amountEthWei: this.convertMaticToEth(value)
    });
  };

  handleEthAmountInput = e => {
    const { value } = e.target;
    this.setState({
      amountEth: Number(value),
      amountEthWei: ethToWei(value),
      amountMatic: Number(weiToEth(this.convertEthToMatic(value))),
      amountMaticWei: this.convertEthToMatic(value)
    });
  };

  withdrawMaxEth = () => {
    const { badTokenBalanceOnEth } = this.props;
    this.setState({
      amountEth: Number(weiToEth(badTokenBalanceOnEth)),
      amountEthWei: badTokenBalanceOnEth,
      amountMatic: Number(weiToEth(this.convertEthToMatic(badTokenBalanceOnEth, "wei"))),
      amountMaticWei: this.convertEthToMatic(badTokenBalanceOnEth, "wei")
    });
  };

  withdrawMaxMatic = () => {
    const { badTokenBalanceOnMatic } = this.props;
    this.setState({
      amountMatic: Number(weiToEth(badTokenBalanceOnMatic)),
      amountMaticWei: badTokenBalanceOnMatic,
      amountEth: Number(weiToEth(this.convertMaticToEth(badTokenBalanceOnMatic, "wei"))),
      amountEthWei: this.convertMaticToEth(badTokenBalanceOnMatic, "wei")
    });
  };

  render() {
    const {
      toggleModal,
      depositTokenToMatic,
      withdrawBAD,
      badTokenBalanceOnMatic,
      badTokenBalanceOnEth
    } = this.props;

    const { activeTab } = this.state;

    const isWithdrawDisabled =
      this.state.amountEthWei === "" ||
      this.state.amountMaticWei === "" ||
      new BigNumber(this.state.amountEthWei).lte(0) ||
      new BigNumber(this.state.amountMaticWei).lte(0) ||
      (activeTab === tabType[0]
        ? new BigNumber(badTokenBalanceOnEth).lt(this.state.amountEthWei)
        : new BigNumber(badTokenBalanceOnMatic).lt(this.state.amountMaticWei));

    return (
      <div className="modal__content modal__content--withdraw-tofro">
        <p className="title">Moving Your BAD Tokens...</p>

        <div className="tabs__list">
          {tabType.map(item => (
            <div
              key={item}
              className={`tabs__item ${activeTab === item ? "active" : ""}`}
              onClick={() => this.handleTabs(item)}
            >
              {item}
            </div>
          ))}
        </div>

        <div className="tabs__content">
          <div className="inputs__wrapper">
            <div className="input--withdraw__wrapper">
              <div className="input">
                <input
                  className="input--withdraw"
                  type="number"
                  placeholder="Enter amount"
                  step="1"
                  min="0"
                  max={
                    activeTab === tabType[0]
                      ? weiToEth(badTokenBalanceOnEth)
                      : weiToEth(badTokenBalanceOnMatic)
                  }
                  value={
                    activeTab === tabType[0]
                      ? this.state.amountEth
                      : this.state.amountMatic
                  }
                  onChange={
                    activeTab === tabType[0]
                      ? this.handleEthAmountInput
                      : this.handleMaticAmountInput
                  }
                />
                <span>
                  {activeTab === tabType[0] ? "BAD on Ethereum" : "BAD on Matic"}
                </span>
              </div>
              <button
                className="btn btn--light"
                onClick={
                  activeTab === tabType[0]
                    ? this.withdrawMaxEth
                    : this.withdrawMaxMatic
                }
              >
                <Tooltip
                  theme="roll"
                  title={`The maximum amount you can ${activeTab} is: ${Number(
                    weiToEth(
                      activeTab === tabType[0] ? badTokenBalanceOnEth : badTokenBalanceOnMatic
                    )
                  )} BAD`}
                >
                  <InfoIcon color={"#465E71"} />
                </Tooltip>{" "}
                Max
              </button>
            </div>
            <ArrowRightLong />
            <div className="input--withdraw__wrapper">
              <div className="input">
                <input
                  className="input--withdraw"
                  type="number"
                  placeholder="Enter amount"
                  step="1"
                  min="0"
                  max={
                    activeTab === tabType[0]
                      ? weiToEth(badTokenBalanceOnMatic)
                      : weiToEth(badTokenBalanceOnEth)
                  }
                  value={
                    activeTab === tabType[0]
                      ? this.state.amountMatic
                      : this.state.amountEth
                  }
                  onChange={
                    activeTab === tabType[0]
                      ? this.handleMaticAmountInput
                      : this.handleEthAmountInput
                  }
                />
                <span>
                  {activeTab === tabType[0] ? "BAD on Matic" : "BAD on Ethereum"}
                </span>
              </div>
            </div>
          </div>

          {activeTab === tabType[0] && (
            <p className="info">
              You are depositing your BAD tokens from the Main Ethereum Network
              to the Matic Network
            </p>
          )}
          {activeTab === tabType[1] && (
            <p className="info">
              You are withdrawing your BAD tokens from the Matic Network to the
              Main Ethereum Network
            </p>
          )}
        </div>

        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() =>
              activeTab === tabType[0]
                ? depositTokenToMatic(this.state.amountEthWei)
                : withdrawBAD(this.state.amountMaticWei)
            }
            disabled={isWithdrawDisabled}
          >
            {activeTab}
          </button>
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

MoveBAD.propTypes = {
  depositTokenToMatic: PropTypes.func.isRequired,
  withdrawBAD: PropTypes.func.isRequired,
  badTokenBalanceOnEth: PropTypes.string.isRequired,
  badTokenBalanceOnMatic: PropTypes.string.isRequired
};

const mapStateToProps = ({ badToken }) => ({
  badTokenBalanceOnEth: badToken.ethereumBadTokenBalance,
  badTokenBalanceOnMatic: badToken.badTokenBalance,
});

const mapDispatchToProps = {
  depositTokenToMatic,
  withdrawBAD
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveBAD);
