import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BigNumber from 'bignumber.js';
import {
  setSpinBetAmount,
  setSpinGameType,
  placeSpinBet,
} from "../../../../actions/games/spin";

import { weiToEth } from "../../../../services/ethereum/main";
import { formatNumber } from "../../../../services/utils";
import { DP } from "../../../../config/constants";

import Wheel from "../Wheel/Wheel";
import BetSize from "../../Shared/BetSize/BetSize";
import PlaceBet from "../../Shared/PlaceBet/PlaceBet";
import GameType from "../../Shared/GameType/GameType";
import BetData from "../../Shared/BetData/BetData";
import BetPlaced from "../../Shared/BetPlaced/BetPlaced";

import "./Controls.scss";

const ControlComponents = (props) => (
  <Fragment>
    <BetSize
      placingBet={props.isAutoBetting || props.startingAutoBetting || props.placingBet}
      maxVal={props.maxBetValue}
      setAmount={props.setSpinBetAmount}
      amount={props.betAmount}
    />
    <div className="choose-game-type">
      <div>
        <GameType
          disabled={props.isAutoBetting || props.startingAutoBetting || props.placingBet}
          game="spin"
          gameType={props.gameType}
          handleGameType={props.handleSpinGameType}
        />
        <BetData
          game="spin"
          chance={props.winChance}
          gameType={props.gameType}
          prize={props.winPrize}
          multiplier={0}
        />
      </div>
      <Wheel spin={false} />
    </div>
    <p className="gold-win-info">
      Win {formatNumber(props.badTokensOnGold, DP)} extra BAD tokens if the wheel stops on Gold!
    </p>
    <PlaceBet
      isAutoBetting={props.isAutoBetting}
      placeBet={props.placeSpinBet}
      gettingState={props.settingInitData || props.gettingSpinInitData || props.startingAutoBetting}
    />
  </Fragment>
)

class Controls extends Component {
  constructor(props) {
    super(props);
    this.handleSpinGameType = this.handleSpinGameType.bind(this);
  }

  handleSpinGameType = e => {
    const { id } = e.target;
    const { setSpinGameType } = this.props;
    setSpinGameType(Number(id));
  };

  render() {
    const {
      betAmount,
      showSpinner,
      showControls,
      lastSelectedGameType,
      lastUserSelectedGameType,
      lastRoundPlayed,
      bettingRound,
      roundState,
      standardLot,
      GBM,
    } = this.props;

    const badTokensOnGold =
      new BigNumber(weiToEth(betAmount)).dividedBy(weiToEth(GBM)).multipliedBy(standardLot).toString();

    return (
      <section
        className="main__section main__section--controls spin"
        style={{
          justifyContent: `${!showSpinner && !showControls ? "flex-start" : ""}`
        }}
      >
        {!showControls && (
          <BetPlaced
            showVisuals={showSpinner}
            lastUserSelectedGameType={lastUserSelectedGameType}
            lastSelectedGameType={lastSelectedGameType}
            lastRoundPlayed={lastRoundPlayed}
            bettingRound={bettingRound}
            roundState={roundState}
          />
        )}
        {showControls && (
          <ControlComponents
            {...this.props}
            handleSpinGameType={this.handleSpinGameType}
            badTokensOnGold={badTokensOnGold}
          />
        )}
      </section>
    );
  }
}

Controls.propTypes = {
  setSpinGameType: PropTypes.func.isRequired,
  setSpinBetAmount: PropTypes.func.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  maxBetValue: PropTypes.string.isRequired,
  gameType: PropTypes.number.isRequired,
  GBM: PropTypes.string.isRequired,
  betAmount: PropTypes.string.isRequired,
  standardLot: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameSpin, spinConstants, games }) => ({
  betAmount: gameSpin.betAmount,
  lastUserSelectedGameType: gameSpin.lastUserSelectedGameType,
  lastSelectedGameType: gameSpin.lastSelectedGameType,
  bettingRound: gameSpin.bettingRound,
  roundState: gameSpin.roundState,
  lastRoundPlayed: gameSpin.lastRoundPlayed,
  gettingGameState: gameSpin.gettingGameState,
  placingBet: gameSpin.placingBet,
  gameType: gameSpin.gameType,
  winPrize: gameSpin.winPrize,
  winChance: gameSpin.winChance,
  showSpinner: gameSpin.showSpinner,
  showControls: gameSpin.showControls,
  maxBetValue: spinConstants.maxBetValue,
  standardLot: spinConstants.standardLot,
  GBM: spinConstants.GBM,
  settingInitData: games.settingInitData,
  gettingSpinInitData: gameSpin.gettingSpinInitData,
  isAutoBetting: gameSpin.isAutoBetting,
  startingAutoBetting: gameSpin.startingAutoBetting,
});

const mapDispatchToProps = {
  setSpinBetAmount,
  setSpinGameType,
  placeSpinBet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
