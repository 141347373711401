import React from "react";

export default function NotifIcon({ numberOfNotifs = 0, clicked }) {
  let textXPos = numberOfNotifs.toString().length > 1 ? `12.5` : `15.5`;
  if (numberOfNotifs > 99) {
    textXPos = "10";
  }
  return (
    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={clicked} >
      <path d="M10.2 33C11.52 33 12.6 31.92 12.6 30.6H7.8C7.8 31.92 8.88 33 10.2 33ZM18 25.8V19.2C18 15.48 15.48 12.48 12 11.64V10.8C12 9.84 11.16 9 10.2 9C9.24 9 8.4 9.84 8.4 10.8V11.64C4.92 12.48 2.4 15.48 2.4 19.2V25.8L0 28.2V29.4H20.4V28.2L18 25.8Z" fill="#42A8DF"/>
      {numberOfNotifs !== 0 && (
        <circle cx="18" cy="9" r="9" fill="#CC2028" />
      )}
      {numberOfNotifs !== 0 && (
        <text x={textXPos} y="12" fill="white" fontFamily="Roboto" fontSize="9">
          {numberOfNotifs <= 99 && numberOfNotifs}
          {numberOfNotifs > 99 && "99+"}
        </text>
      )}
    </svg>
  );
}
