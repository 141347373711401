import {
  GET_MAX_CARD_COST_ERROR,
  GET_MAX_CARD_COST_REQUEST,
  GET_MAX_CARD_COST_SUCCESS,
  GET_MAX_NUM_OF_CARDS_ERROR,
  GET_MAX_NUM_OF_CARDS_REQUEST,
  GET_MAX_NUM_OF_CARDS_SUCCESS,
  GET_SCRATCH_WIN_MULTIPLIER_ERROR,
  GET_SCRATCH_WIN_MULTIPLIER_REQUEST,
  GET_SCRATCH_WIN_MULTIPLIER_SUCCESS,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS,
} from "../../actionTypes/contractConstants/scratch";
import {
  getCardResultFromScratchContract,
  getMaxCardCostFromScratchContract,
  getMaxNumOfCardsFromScratchContract,
  getWinningMultiplierFromScratchContract,
  getWinningProbabilityFromScratchContract,
} from "../../services/ethereum/scratch";
import { makeBatchRequest } from "../../services/ethereum/main";
import BigNumber from "bignumber.js";
import { groupArray, shuffleArray } from "../../services/utils";
import {getGBS, getGCL, getGWP, getMaxBetChanceForBonusBetting, getMinBetSize} from './games';
import {shouldWinTokensFromContract} from '../../services/ethereum/badToken';
import { getMaxDonsInRow } from '../games/doubleOrNothing';

export const getMaxNumOfCards = (passedPayload) => async (dispatch) => {
  dispatch({ type: GET_MAX_NUM_OF_CARDS_REQUEST });
  try {
    if (passedPayload) return dispatch({ type: GET_MAX_NUM_OF_CARDS_SUCCESS, payload: passedPayload });
    const payload = await getMaxNumOfCardsFromScratchContract()();
    dispatch({ type: GET_MAX_NUM_OF_CARDS_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_MAX_NUM_OF_CARDS_ERROR, payload: error.message });
  }
};

export const getMaxCardCost = (passedPayload) => async (dispatch) => {
  dispatch({ type: GET_MAX_CARD_COST_REQUEST });
  try {
    if (passedPayload) return dispatch({ type: GET_MAX_CARD_COST_SUCCESS, payload: passedPayload });
    const payload = await getMaxCardCostFromScratchContract()();
    dispatch({ type: GET_MAX_CARD_COST_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_MAX_CARD_COST_ERROR, payload: error.message });
  }
};

export const getWinProbForWinTypeZero = (steady, risky, extreme) => async (dispatch) => {
  dispatch({ type: GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST });
  try {
    if (steady) {
      return dispatch({
        type: GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS,
        payload: [steady, risky, extreme]
      });
    }
    const [winProbSteady, winProbRisky, winProbExtreme] = await makeBatchRequest([
      getWinningProbabilityFromScratchContract(true)(0, 0),
      getWinningProbabilityFromScratchContract(true)(0, 1),
      getWinningProbabilityFromScratchContract(true)(0, 2),
    ]);
    dispatch({
      type: GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS,
      payload: [winProbSteady, winProbRisky, winProbExtreme]
    });
  } catch (error) {
    dispatch({ type: GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR, payload: error.message });
    console.log(error);
  }
};

const getWinMultiplierRequestsForBatch = () => {
  let winMultiplierRequests = [];
  for (let i = 1; i < 7; i++) {
    winMultiplierRequests = [...winMultiplierRequests,
      getWinningMultiplierFromScratchContract(true)(i, 0),
      getWinningMultiplierFromScratchContract(true)(i, 1),
      getWinningMultiplierFromScratchContract(true)(i, 2),
    ]
  }
  return winMultiplierRequests;
};

export const getWinMultiplier = (passedPayload) => async (dispatch) => {
  dispatch({ type: GET_SCRATCH_WIN_MULTIPLIER_REQUEST });
  try {
    if (passedPayload) {
      return dispatch({
        type: GET_SCRATCH_WIN_MULTIPLIER_SUCCESS,
        payload: groupArray(passedPayload.map(item => Number(item)))
      });
    }
    const multiplierFlatArray = await makeBatchRequest([
      ...getWinMultiplierRequestsForBatch()
    ]);
    dispatch({
      type: GET_SCRATCH_WIN_MULTIPLIER_SUCCESS,
      payload: groupArray(multiplierFlatArray.map(item => Number(item)))
    });
  } catch (error) {
    dispatch({ type: GET_SCRATCH_WIN_MULTIPLIER_ERROR, payload: error.message });
    console.log(error);
  }
};

export const getScratchContractConstValues = () => async (dispatch) => {
  const [
    maxNumOfCards, maxCardCost, winProbSteady, winProbRisky, winProbExtreme, ...winMultipliers
  ] = await makeBatchRequest([
    getMaxNumOfCardsFromScratchContract(true)(),
    getMaxCardCostFromScratchContract(true)(),
    getWinningProbabilityFromScratchContract(true)(0, 0),
    getWinningProbabilityFromScratchContract(true)(0, 1),
    getWinningProbabilityFromScratchContract(true)(0, 2),
    ...getWinMultiplierRequestsForBatch()
  ]);

  dispatch(getWinMultiplier(winMultipliers));
  dispatch(getWinProbForWinTypeZero(winProbSteady, winProbRisky, winProbExtreme));
  dispatch(getMaxNumOfCards(maxNumOfCards));
  dispatch(getMaxCardCost(maxCardCost));
  dispatch(getMaxBetChanceForBonusBetting());
  await dispatch(getMaxDonsInRow());
  await dispatch(getMinBetSize());
  await dispatch(getGWP());
  await dispatch(getGBS());
  await dispatch(getGCL());
};

/**
 *
 * @param {string} resultHash
 * @param {number} numberOfCards
 * @param {string} costPerCard
 * @param {number} gameMode
 * @param {string} account
 * @return {Promise<{totalWon: string, win: boolean, cards: Array, tokensWon: string, profit: string, betAmount: string, initHash: string}>}
 */
export const getCardsResult =
  async (resultHash, numberOfCards, costPerCard, gameMode, account) => {
    const initHash = resultHash;
    const resultHashToNumber = (hash) => new BigNumber(hash).mod(10000).toString();
    const encode = (resultHash, resultNumber) => window._web3.eth.abi.encodeParameters(['bytes32', 'uint'], [resultHash, resultNumber]);
    let resultNumber = resultHashToNumber(resultHash);

    let totalWon = "0";
    let cards = [];
    let tokensWon = "0";

    const winProb = await getWinningProbabilityFromScratchContract()(0, gameMode);
    const chances = [ new BigNumber(10000).minus(winProb).toString() ];
    for (let i = 0; i < numberOfCards; i++) {
      const { 0: cardAmount, 1: winType } =
        await getCardResultFromScratchContract()(resultNumber, costPerCard, gameMode);
      totalWon = new BigNumber(totalWon).plus(cardAmount).toString();

      const tokenAmount = await shouldWinTokensFromContract(resultHash, account, [costPerCard], chances);
      tokensWon = new BigNumber(tokensWon).plus(tokenAmount).toString();

      cards = [
        ...cards,
        { resultHash, resultNumber, cardAmount, winType: Number(winType), cardWin: +winType > 0 }
        ];

      resultHash = window._web3.utils.keccak256(encode(resultHash, resultNumber));
      resultNumber = resultHashToNumber(window._web3.utils.keccak256(encode(resultHash, resultNumber)));
    }

    const betAmount = new BigNumber(costPerCard).times(numberOfCards).toString();

    let profit;
    let win;
    if (new BigNumber(totalWon).gt(betAmount)) {
      profit = new BigNumber(totalWon).minus(betAmount).toString();
      win = true;
    } else {
      profit = "0";
      win = false;
    }
    console.log(
      "winAll", win, "\ntotalWon", totalWon, "\nbetAmount", betAmount,
      "\nprofit", profit, "\ncards", cards, "\ntokensWon", tokensWon, "\n=======\n"
    );
    return { totalWon, win, cards, tokensWon, profit, betAmount, initHash }
  };

/**
 *
 * @param {number} [min]
 * @param {number} [max]
 * @return {number}
 */
const createRandomNumber = (min = 1, max = 6) => {
  return Math.round(Math.random() * (max - min) + min);
}

/**
 *
 * @param {boolean} winningCard
 * @param {number} winType
 * @param {number} [maxItems]
 * @param {number} [itemsToWin]
 * @return {number[]}
 */
export const createCard =
  (winningCard = true, winType = 4, maxItems = 9, itemsToWin = 3) => {
    let cardItems = [];
    if (winningCard) {
      cardItems = [...new Array(createRandomNumber(itemsToWin, maxItems))];
      cardItems = cardItems.map(() => winType);
    }
    while (cardItems.length < maxItems) {
      const randomNumber = createRandomNumber();
      const quantityOfSameItems = cardItems.filter(item => item === randomNumber).length;
      const shouldAddNumber = quantityOfSameItems !== itemsToWin - 1 && randomNumber !== winType;
      if (shouldAddNumber) cardItems = [...cardItems, randomNumber];
    }
    return shuffleArray(cardItems);
  }

export const calculateScratchWinChance =
  (gameMode, winProbArr) => 100 - winProbArr[gameMode] / 100;

export const getWinningMultiplier = (winType, gameMode, winMultiplierArr) =>
  winMultiplierArr[winType][gameMode];
