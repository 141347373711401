import {rollGameAddress, rouletteGameAddress, scratchGameAddress, spinGameAddress} from './contract';

import { ethereumNetwork, maticNetwork } from "../config/client.js";
const sigUtil = require("eth-sig-util");

/**
 * Adds decimals to a string number
 *
 * @param num {String}
 * @param decimals {Number}
 *
 * @return {string | *}
 */
export const toDecimal = (num, decimals = 3) => {
  const conditional = num.indexOf(".") !== -1;

  return conditional ? +num.substr(0, num.indexOf(".") + decimals + 1) : num;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isEmptyBytes = string =>
  string === "0x0000000000000000000000000000000000000000";

export const saiTubContractTools = {
  u: (e, t, n) => new Array(t - e.length + 1).join(n || "0") + e,
  formatMethodName: e =>
    _web3.utils // eslint-disable-line
      .sha3(e)
      .substring(0, 10),
  formatProxyAddress: e => {
    const t =
      !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1]; // eslint-disable-line
    let n = _web3.utils.toHex(e);

    return (
      (n = n.replace("0x", "")),
      (n = saiTubContractTools.u(n, 64)),
      t && (n = "0x" + n),
      n
    ); // eslint-disable-line // eslint-disable-line // eslint-disable-line // eslint-disable-line
  }
};

export function validateEmail(email) {
  const rgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rgx.test(`${email}`.toLowerCase());
}

/**
 * Returns an instance of the provided contract json
 *
 * @param web3 {Object}
 * @param contractDefinition {Object}
 *
 * @return {Promise<Object>}
 */
export const getContractInstance = async (web3, contractDefinition) => {
  const networkId = await web3.eth.net.getId();
  const deployedAddress = contractDefinition.networks[networkId].address;

  return new web3.eth.Contract(contractDefinition.abi, deployedAddress);
};

/**
 * Finds abi function inside a contract
 *
 * @param contract {Object}
 * @param functionName {String}
 *
 * @return {Object}
 */
export const getAbiFunction = (contract, functionName) => {
  const { abi } = contract.toJSON();
  return abi.find(abi => abi.name === functionName);
};

export const padToBytes32 = _n => {
  let n = _n;

  while (n.length < 64) n = `0${n}`;

  return `0x${n}`;
};

export const formatAcc = (acc, sLen = 12, eLen = 6) =>
  `${acc.substring(0, sLen)}...${acc.substr(acc.length - eLen)}`;

export const formatAccType = accType => {
  if (accType === "metamask") return "MetaMask";
};

const countDecimals = value => {
  if (Math.floor(value) !== value)
    return value.toString().split(".")[1].length || 0;

  return 0;
};

/**
 * Formats a numbers to a number string with the specified decimal places
 *
 * @param _num
 * @param fixed
 * @return {*}
 */
export const formatNumber = (_num, fixed) => {
  try {
    let num = _num;

    if (typeof num === "object") num = num.toNumber();

    if (Number.isInteger(num)) return numberWithCommas(num);

    const decimals = countDecimals(num);
    const numString = num.toString();

    const formatted = numString.substring(
      0,
      numString.length - decimals + fixed
    );

    if (formatted === "Infinity") return "0";
    if (
      parseFloat(_num)
        .toString()
        .includes("e-")
    )
      return numberWithCommas(formatted);

    return numberWithCommas(parseFloat(_num).toFixed(fixed)).replace(
      /\.?0*$/,
      ""
    );
  } catch (err) {
    return numberWithCommas(parseFloat(_num).toFixed(fixed)).replace(
      /\.?0*$/,
      ""
    );
  }
};

/**
 * Formats long numbers with commas as thousands separators
 *
 * @param x
 * @return {String}
 */
export const numberWithCommas = x => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const wait = (returnVal = {}, time = 500) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(returnVal);
    }, time);
  });

/**
 *
 * @param value
 * @param strip
 * @return {string}
 */
export const stripValue = (value, strip = 2) => {
  return value.substring(0, strip);
};

/**
 * Returns name of Ethereum network for given ID
 *
 * @return {String}
 */
export const nameOfNetwork = networkId => {
  const networks = {
    1: "Mainnet",
    3: "Ropsten",
    4: "Rinkeby",
    42: "Kovan"
  };
  return networks[networkId] || "Unknown network";
};

/**
 * Checks which game subtype has won for a given number
 * when played DragonTiger game type
 *
 * @param val
 * @return {string}
 */
export const betValToName = val => {
  if (val === undefined) return "";
  let vals = [];
  let val1 = 0;
  let val2 = 0;

  if (val < 10) {
    val2 = val;
  } else {
    vals = val.toString().split("");
    [val1, val2] = vals;
  }

  if (val1 > val2) return "Dragon";
  if (val2 > val1) return "Tiger";
  if (parseInt(val2, 10) === parseInt(val1, 10)) return "Tie";
};

/**
 * Returns a color name for a given gameType for spin game
 *
 * @param gameType
 * @return {string}
 */
export const spinGameTypeToColor = gameType =>
  ["N/A", "white", "red", "blue", "orange", "gold"][gameType];

export const checkIfPlayerHasWonSpinBet = (
  lastSelectedGameType,
  lastUserSelectedGameType
) => lastSelectedGameType === lastUserSelectedGameType;

/**
 * Returning if player has won the bet and number/game subtype user has selected
 *
 * @param lastSelectedNumber
 * @param lastUserSelectedNumber
 * @param lastUserSelectedGameType
 * @return {{userSelected: (*|string), hasWon: boolean}}
 */
export const checkIfPlayerHasWonRollBet = (
  lastSelectedNumber,
  lastUserSelectedNumber,
  lastUserSelectedGameType
) => {
  let hasWon = false;
  let userSelected = lastUserSelectedNumber;

  if (
    lastUserSelectedGameType === 0 &&
    lastSelectedNumber > lastUserSelectedNumber
  ) {
    hasWon = true;
  }
  if (
    lastUserSelectedGameType === 1 &&
    lastSelectedNumber < lastUserSelectedNumber
  ) {
    hasWon = true;
  }
  if (
    lastUserSelectedGameType === 2 &&
    lastSelectedNumber === lastUserSelectedNumber
  ) {
    hasWon = true;
  }
  if (lastUserSelectedGameType === 3) {
    const mod = lastSelectedNumber % 2;
    hasWon = lastUserSelectedNumber === mod && lastSelectedNumber !== 0;
    userSelected = ["Even", "Odd"][lastUserSelectedNumber];
  }
  if (lastUserSelectedGameType === 4) {
    const firstDigit = Math.floor(lastSelectedNumber / 10);
    const secondDigit = lastSelectedNumber % 10;

    userSelected = ["Dragon", "Tiger", "Tie"][lastUserSelectedNumber];
    hasWon =
      (lastUserSelectedNumber === 0 && firstDigit > secondDigit) ||
      (lastUserSelectedNumber === 1 && firstDigit < secondDigit) ||
      (lastUserSelectedNumber === 2 && firstDigit === secondDigit);
  }

  return { hasWon, userSelected };
};

/**
 * Signs a string with a given address
 *
 * @param stringToSign
 * @param address
 * @return {Promise<any>}
 */
export const signString = (stringToSign, address) =>
  new Promise((resolve, reject) => {
    _web3.eth.personal.sign(stringToSign, address, "", (err, data) => {
      if (err || data.error) return reject(err);
      return resolve(data);
    });
  });

/**
 * Formats timestamp and returns date in Day Month Year format
 *
 * @param timestamp
 * @return {string}
 */
export const formatTimestampToDate = timestamp => {
  const date = new Date(timestamp * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${day} ${month} ${year}`;
};

export const shuffleArray = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const toChecksumAddress = address => window._web3.utils.toChecksumAddress(address);

export const getGameNameFromAddress = address => {
  const games = {
    [`${toChecksumAddress(rollGameAddress)}`]: {
      name: "ROLL"
    },
    [`${toChecksumAddress(spinGameAddress)}`]: {
      name: "SPIN"
    },
    [`${toChecksumAddress(scratchGameAddress)}`]: {
      name: "SCRATCH"
    },
    ["0x32edbc1835972f768f37aba4FFD14Fb8bb3ad895"]: {
      name: "SCRATCH"
    },
    ["0x5e2c36Ad506A3c17c7648Dc5C451108FbfE370cf"]: {
      name: "ROULETTE"
    },
    [`${toChecksumAddress(rouletteGameAddress)}`]: {
      name: "ROULETTE"
    },
  };
  return games[`${toChecksumAddress(address)}`].name;
};


const domainType = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "verifyingContract", type: "address" },
  { name: "salt", type: "bytes32" }
];

const metaTransactionType = [
  { name: "nonce", type: "uint256" },
  { name: "from", type: "address" },
  { name: "functionSignature", type: "bytes" }
];

let globalDomainData = {
  name: "BadBit.Games",
  version: "0.1"
};

const callbackToPromise = (userAddress, dataToSign) =>{
  return new Promise((res, rej)=>{
    window._web3.eth.currentProvider.send(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [userAddress, dataToSign]
      },
      async function(error, response) {

        if (error){
          rej(error);
        }
        else {
          res(response);
        }
      }
    );
  });
}

export const getExecuteMetaTransactionSignature = async(contract, functionSignature, userAddress, domainData)=>{
  if(!domainData) {
    domainData = globalDomainData;
    domainData['verifyingContract'] = contract._address;
  }
  domainData['salt'] = '0x' + maticNetwork.toString(16).padStart(64, '0');

  let nonce = await contract.methods.getNonce(userAddress).call();

  let message = {};
  message.nonce = parseInt(nonce);
  message.from = userAddress;
  message.functionSignature = functionSignature;
  message.network = "Interact with Matic Network";

  const dataToSign = JSON.stringify({
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType
    },
    domain: domainData,
    primaryType: "MetaTransaction",
    message: message
  });

  var response = await callbackToPromise(userAddress, dataToSign);
  let { r, s, v } = getSignatureParameters(response.result);

  return {r, s, v};

}

export const executeMetaTransaction = (contract, functionSignature, userAddress, r, s, v) => {
  return contract.methods.executeMetaTransaction(userAddress, functionSignature, r, s, v).send({ from: userAddress, gasLimit: 9000000 });
}

const getSignatureParameters = (signature) => {
  if (!window._web3.utils.isHexStrict(signature)) {
    throw new Error(
      'Given value "'.concat(signature, '" is not a valid hex string.')
    );
  }
  var r = signature.slice(0, 66);
  var s = "0x".concat(signature.slice(66, 130));
  var v = "0x".concat(signature.slice(130, 132));
  v = window._web3.utils.hexToNumber(v);
  if (![27, 28].includes(v)) v += 27;
  return {
    r: r,
    s: s,
    v: v
  };
}
/**
 *
 * @param {any[]}array
 * @param {number} [groupBy]
 * @return {[]}
 */
export const groupArray = (array, groupBy = 3) => {
  let newArray = [];
  array.map((item, index) => {
    const isNewArrItem = index % groupBy === 0;
    const currArrayIndex = isNewArrItem ? newArray.length : newArray.length - 1;
    newArray[currArrayIndex] = isNewArrItem ? [item] : [...newArray[currArrayIndex], item];
    return item;
  });
  return newArray;
};
