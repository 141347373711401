import {
  CONNECT_PROVIDER,
  CONNECT_PROVIDER_FAILURE,
  CONNECT_PROVIDER_SUCCESS,
  GET_NICKNAME_ERROR,
  GET_NICKNAME_REQUEST,
  GET_NICKNAME_SUCCESS,
  GET_USER_BONUS_CONTRACT_BALANCE_ERROR,
  GET_USER_BONUS_CONTRACT_BALANCE_REQUEST,
  GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS,
  GET_USER_CONTRACT_BALANCE_ERROR,
  GET_USER_CONTRACT_BALANCE_REQUEST,
  GET_USER_CONTRACT_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_ERROR,
  GET_WALLET_BALANCE_REQUEST,
  GET_WALLET_BALANCE_SUCCESS,
  GET_WETH_APPROVAL_REQUEST,
  GET_WETH_APPROVAL_SUCCESS,
  GET_WETH_APPROVAL_ERROR,
  GET_ETH_BALANCE_ERROR,
  GET_ETH_BALANCE_REQUEST,
  GET_ETH_BALANCE_SUCCESS,
  LOGIN_FINISHED,
  LOGIN_STARTED,
  SET_FIRST_BET_FLAG,
  SET_NICKNAME_ERROR,
  SET_NICKNAME_REQUEST,
  SET_NICKNAME_SUCCESS,
  SIGN_TO_SERVER_ERROR,
  SIGN_TO_SERVER_REQUEST,
  SIGN_TO_SERVER_SUCCESS,
  ACCEPT_TOS_REQUEST,
  ACCEPT_TOS_SUCCESS,
  ACCEPT_TOS_ERROR,
  SEND_VERIFICATION_STEP_ONE_REQUEST,
  SEND_VERIFICATION_STEP_ONE_SUCCESS,
  SEND_VERIFICATION_STEP_ONE_ERROR,
  SEND_VERIFICATION_STEP_TWO_REQUEST,
  SEND_VERIFICATION_STEP_TWO_SUCCESS,
  SEND_VERIFICATION_STEP_TWO_ERROR,
  DEPOSIT_ETH_REQUEST,
  DEPOSIT_ETH_SUCCESS,
  DEPOSIT_ETH_ERROR,
  ASSOCIATE_WITH_AFF_CODE_REQUEST,
  ASSOCIATE_WITH_AFF_CODE_SUCCESS,
  ASSOCIATE_WITH_AFF_CODE_ERROR,
  SET_AFF_CODE_REQUEST,
  SET_AFF_CODE_SUCCESS,
  SET_AFF_CODE_ERROR,
  RESUME_WITHDRAWAL_REQUEST,
  RESUME_WITHDRAWAL_SUCCESS,
  RESUME_WITHDRAWAL_ERROR,
  GET_AFF_TOKEN_BALANCE_REQUEST,
  GET_AFF_TOKEN_BALANCE_SUCCESS,
  GET_AFF_TOKEN_BALANCE_ERROR,
  TRANSFER_AFF_TOKEN_REQUEST,
  TRANSFER_AFF_TOKEN_SUCCESS,
  TRANSFER_AFF_TOKEN_ERROR,
  GET_TOKEN_CURRENT_AFFILIATES_REQUEST,
  GET_TOKEN_CURRENT_AFFILIATES_SUCCESS,
  GET_TOKEN_CURRENT_AFFILIATES_ERROR,
  GET_TOKEN_PAST_AFFILIATES_REQUEST,
  GET_TOKEN_PAST_AFFILIATES_SUCCESS,
  GET_TOKEN_PAST_AFFILIATES_ERROR,
  GET_AFF_TOKEN_DATA_REQ,
  GET_AFF_TOKEN_DATA_SUCCESS,
  BREAK_AFFILIATION_REQUEST,
  BREAK_AFFILIATION_SUCCESS,
  BREAK_AFFILIATION_ERROR,
  SET_IS_REGISTERED,
  SET_REMEMBER_USER,
  SET_SENT_AFFILIATE_REQUEST,
  SET_SENT_AFFILIATE_SUCCESS,
  SET_SENT_AFFILIATE_FAILURE,
  SUBMIT_AFFILIATE_REQUEST,
  SUBMIT_AFFILIATE_SUCCESS,
  SUBMIT_AFFILIATE_FAILURE,
} from "../actionTypes/account";

import clientConfig from "../config/client.js";
import { nameOfNetwork } from "../services/utils";
import { setupWeb3 } from "../services/web3";
import { notify } from "./notification";
import { changeModalType, toggleModal } from "./modal";
import { statusTypes } from "../config/constants";
import {
  checkAffCodeAvailability,
  getUserAcceptedTOSFromApi,
  getUserDataFromApi,
  getUsersStatusFromApi,
  postUseAffCode,
  postMovedToWeth,
  postUserAcceptedTOSFromApi,
  postVerificationSubmissionPartOne,
  postVerificationSubmissionPartTwo,
  registerFromApi,
  postOwnAffCode,
  postTransferAffToken,
  getUsersCurrentCycleAffiliateStats,
  postBreakAffiliation,
  postNicknameFromApi,
  getUsersPastCyclesAffiliateStats,
  postEtherMoveEthereumToMatic,
  getAffiliateRequest,
  postAffiliateRequest,
} from "../services/api";
import { connectToSocket } from "./chat";
import {
  getUserBonusBalanceFromContract,
  getUsersBalanceFromContract
} from "../services/ethereum/games";
import {
  ethereumEnable,
  getBalance,
  getEthBalance,
  getNetwork,
  isEthereumApproved,
  depositETHService,
  resumeWithdrawal,
  badbitDepositETHService,
  getAccount,
  getApproval
} from "../services/ethereum/main";
import { sendTx, sendTxETH } from "./txNotifications";
import {
  associateWithAffiliateFromContract,
  balanceOfFromContract,
  disassociateWithAffiliateFromContract,
  getTokenAffiliatesFromContract,
  safeTransferFromFromContract,
  setAffiliateCodeFromContract,
  tokenDataFromContract,
  tokenOfOwnerByIndexFromContract,
} from "../services/ethereum/affiliateToken";

import {
  listenForBurnEventWETH,
  getBurnEventsFromWETH,
  getBurnEventsFromBadToken,
  listenForBurnEventBadToken,
  refreshCheckpointStatus
} from "./withdrawal";

/**
 * Gets a username from contract
 *
 * @return {Function}
 */
export const getUsername = () => async (dispatch, getState) => {
  dispatch({ type: GET_NICKNAME_REQUEST });
  try {
    const { account } = getState().account;

    const { name } = await getUserDataFromApi(account);

    dispatch({ type: GET_NICKNAME_SUCCESS, payload: name });
  } catch (err) {
    dispatch({ type: GET_NICKNAME_ERROR, payload: err.message });
  }
};

export const getAffTokenData = () => async (dispatch, getState) => {
  dispatch({ type: GET_AFF_TOKEN_DATA_REQ });
  try {
    const { account } = getState().account;

    await dispatch(getAffTokenBalance());
    const { affTokenBalance } = getState().account;

    if (affTokenBalance > 0) {
      await dispatch(getCurrentTokenAffiliates());
      await dispatch(getPastTokenAffiliates());
      const tokenId = await tokenOfOwnerByIndexFromContract(account, 0, account);
      let tokenData;
      if (tokenId) {
        tokenData = await tokenDataFromContract(tokenId);
      }
      if (tokenData.affiliateCode !== "") {
        dispatch({
          type: SET_AFF_CODE_SUCCESS,
          payload: {
            ownsAffCode: tokenData.affiliateCode,
            affBalance: tokenData.isBalancePositive ?
              tokenData.balance : "0"
          }
        });
      }
    } else {
      dispatch(setSentAffiliateRequest());
    }

    const { usesAffiliateCode } = await getUserDataFromApi(account);
    if (usesAffiliateCode) {
      dispatch({ type: ASSOCIATE_WITH_AFF_CODE_SUCCESS, payload: usesAffiliateCode });
    }
    dispatch({ type: GET_AFF_TOKEN_DATA_SUCCESS });
  } catch (error) {
    console.log(error);
  }
};

export const getAffTokenBalance = () => async (dispatch, getState) => {
  dispatch({ type: GET_AFF_TOKEN_BALANCE_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await balanceOfFromContract(account, account);
    dispatch({ type: GET_AFF_TOKEN_BALANCE_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_AFF_TOKEN_BALANCE_ERROR, payload: error.message });
  }
};

export const setAffCode = affCode => async (dispatch, getState) => {
  dispatch({ type: SET_AFF_CODE_REQUEST });
  try {
    const { account } = getState().account;

    const promise = await checkAffCodeAvailability(affCode);
    const { available } = await promise.json();

    if (!available) {
      dispatch({ type: SET_AFF_CODE_ERROR, payload: "Affiliate code already in use." });
      return;
    }

    dispatch(toggleModal());
    const tokenId = await tokenOfOwnerByIndexFromContract(account, 0, account);
    const status = await setAffiliateCodeFromContract(
      tokenId,
      affCode,
      account,
      sendTx,
      dispatch,
      getState
    );
    if (status) {
      dispatch({ type: SET_AFF_CODE_SUCCESS, payload: { ownsAffCode: affCode  } });
      await postOwnAffCode(affCode, tokenId);
    }
  } catch (err) {
    dispatch({ type: SET_AFF_CODE_ERROR, payload: err.message });
  }
};

export const getCurrentTokenAffiliates = () => async (dispatch, getState) => {
  dispatch({ type: GET_TOKEN_CURRENT_AFFILIATES_REQUEST });
  try {
    const { account } = getState().account;

    const tokenId = await tokenOfOwnerByIndexFromContract(account, 0, account);
    const tokenAffiliates = await getTokenAffiliatesFromContract(tokenId);

    let payload = [];
    if (tokenAffiliates.length > 0) {
      payload = await Promise.all(tokenAffiliates.map(async (address) => {
        const response = await getUsersCurrentCycleAffiliateStats(address);
        const data = await response.json();

        if (response.status === 200) {
          return data;
        } else if (response.status === 404) {
          return { address, error: "No affiliate stats." };
        } else if (response.status === 500) {
          return { address, error: "Can't fetch data." };
        }
      }));
    }
    dispatch({ type: GET_TOKEN_CURRENT_AFFILIATES_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_TOKEN_CURRENT_AFFILIATES_ERROR });
  }
};

export const getPastTokenAffiliates = () => async (dispatch, getState) => {
  dispatch({ type: GET_TOKEN_PAST_AFFILIATES_REQUEST });
  try {
    const { account } = getState().account;

    let payload = [];
    const response = await getUsersPastCyclesAffiliateStats(account);

    if (response.status === 200) {
      const data = await response.json();
      const addressKeys = Object.keys(data);

      payload = addressKeys.map(address => {
        const pastCyclesAffiliateRevenue = data[address].pastRevenue ? data[address].pastRevenue : "0";
        const pastCyclesTotalBets = data[address].pastTotalBets ? data[address].pastTotalBets : 0;
        return { address, pastCyclesAffiliateRevenue, pastCyclesTotalBets }
      });

      dispatch({ type: GET_TOKEN_PAST_AFFILIATES_SUCCESS, payload });
    } else if (response.status === 500) {
      dispatch({ type: GET_TOKEN_PAST_AFFILIATES_ERROR, payload: "There was an error while getting data." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_TOKEN_PAST_AFFILIATES_ERROR, payload: error.message });
  }
};

export const transferAffiliateToken = (_toAddress) => async (dispatch, getState) => {
  dispatch({ type: TRANSFER_AFF_TOKEN_REQUEST });
  try {
    const { account } = getState().account;

    const toAddress = _toAddress.trim();

    dispatch(toggleModal());
    const tokenId = await tokenOfOwnerByIndexFromContract(account, 0, account);
    const status = await safeTransferFromFromContract(
      account,
      toAddress,
      tokenId,
      account,
      sendTx,
      dispatch,
      getState
    );
    if (status) {
      dispatch({ type: TRANSFER_AFF_TOKEN_SUCCESS });
      await postTransferAffToken(toAddress);
      dispatch(getAffTokenBalance());
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: TRANSFER_AFF_TOKEN_ERROR, payload: error.message });
  }
};

export const breakAffiliation = () => async (dispatch, getState) => {
  dispatch({ type: BREAK_AFFILIATION_REQUEST });
  try {
    const { account } = getState().account;
    dispatch(toggleModal());

    const status = await disassociateWithAffiliateFromContract(account, sendTx, dispatch, getState);

    if (status) {
      await postBreakAffiliation();
      dispatch({ type: BREAK_AFFILIATION_SUCCESS });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: BREAK_AFFILIATION_ERROR, payload: error.message });
  }
};

export const associateWithAffCode = affCode => async (dispatch, getState) => {
  dispatch({ type: ASSOCIATE_WITH_AFF_CODE_REQUEST });
  try {
    const { account } = getState().account;

    console.log(affCode);

    dispatch(toggleModal());
    const status = await associateWithAffiliateFromContract(
      affCode,
      account,
      sendTx,
      dispatch,
      getState
    );
    if (status) {
      dispatch({ type: ASSOCIATE_WITH_AFF_CODE_SUCCESS, payload: affCode });
      await postUseAffCode(affCode);
    }
  } catch (err) {
    dispatch({ type: ASSOCIATE_WITH_AFF_CODE_ERROR, payload: err.message });
  }
};

export const sendVerificationStepOne = modalState => async (
  dispatch,
  getState
) => {
  dispatch({ type: SEND_VERIFICATION_STEP_ONE_REQUEST });
  try {
    const { account } = getState().account;

    const data = {
      firstName: modalState.firstName,
      lastName: modalState.lastName,
      birthDate: modalState.date,
      emailAddress: modalState.email,
      usesAffiliateCode: modalState.usesAffiliateCode,
      userDeclaration: modalState.acceptTerms
    };

    const response = await postVerificationSubmissionPartOne(account, data);
    if (response.status === 200) {
      dispatch(
        changeModalType(
          "VerifyStepTwo",
          { canSkip: true, isMedium: true },
          false
        )
      );
      dispatch({ type: SEND_VERIFICATION_STEP_ONE_SUCCESS });
    } else {
      const err = await response.json();
      dispatch({
        type: SEND_VERIFICATION_STEP_ONE_ERROR,
        payload: err.message
      });
    }
  } catch (err) {
    dispatch({ type: SEND_VERIFICATION_STEP_ONE_ERROR, payload: err.message });
  }
};

export const sendVerificationStepTwo = modalState => async (
  dispatch,
  getState
) => {
  dispatch({ type: SEND_VERIFICATION_STEP_TWO_REQUEST });
  try {
    const { account } = getState().account;

    const formData = new FormData();
    formData.append("idPhoto", modalState.idPhoto);
    formData.append("addressPhoto", modalState.addressPhoto);
    formData.append("userAddress", account);
    formData.append("userDeclaration", modalState.acceptTerms);
    formData.append("idNumber", modalState.idDocNumber);
    formData.append("residentialAddress", modalState.residentialAddress);

    const response = await postVerificationSubmissionPartTwo(formData);
    if (response.status === 200) {
      dispatch({ type: SEND_VERIFICATION_STEP_TWO_SUCCESS });
      dispatch(toggleModal());
    } else {
      const err = await response.json();
      dispatch({
        type: SEND_VERIFICATION_STEP_TWO_ERROR,
        payload: err.message
      });
    }
  } catch (err) {
    dispatch({ type: SEND_VERIFICATION_STEP_TWO_ERROR, payload: err.message });
  }
};

/**
 * Registers a user on server and sets a cookie to the client side
 *
 * @return {Function}
 */
export const register = (rememberUser) => async (dispatch, getState) => {
  dispatch({ type: SIGN_TO_SERVER_REQUEST });
  try {
    const { account, acceptedTOS, userStatus } = getState().account;
    const isFlagged = userStatus.status === statusTypes.flagged;

    const response = await registerFromApi(rememberUser, account);
    if (response.status === 200) {
      dispatch({ type: SIGN_TO_SERVER_SUCCESS });
      dispatch(setIsRegistered(true));
      dispatch(setRememberUser(rememberUser));
      dispatch(connectToSocket());
      if (!acceptedTOS && !isFlagged) {
        dispatch(changeModalType("ToS", {}, false));
      } else if (acceptedTOS && isFlagged) {
        dispatch(verifyUser(changeModalType));
      } else if (acceptedTOS && !isFlagged) {
        dispatch(toggleModal());
        dispatch(checkAffCodeUrlParam());
      }
    } else {
      console.log(response);
      dispatch({ type: SIGN_TO_SERVER_ERROR, payload: response });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SIGN_TO_SERVER_ERROR, payload: error.message });
  }
};

export const acceptTOS = () => async (dispatch, getState) => {
  dispatch({ type: ACCEPT_TOS_REQUEST });
  try {
    const { account } = getState().account;
    const response = await postUserAcceptedTOSFromApi(account);
    console.log(response);
    if (response.status === 200) {
      dispatch({ type: ACCEPT_TOS_SUCCESS });
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());
    } else {
      dispatch({ type: ACCEPT_TOS_ERROR });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: ACCEPT_TOS_ERROR });
  }
};

/**
 * Sets a username to contract
 *
 * @param username
 * @return {Function}*/

export const setUsername = username => async (dispatch, getState) => {
  dispatch({ type: SET_NICKNAME_REQUEST });
  try {
    const { modalOpen } = getState().modal;
    const response = await postNicknameFromApi(username);
    if (response.status === 200) {
      dispatch({ type: SET_NICKNAME_SUCCESS, payload: username });
      if (modalOpen) dispatch(toggleModal());
    } else if (response.status === 409) {
      dispatch({ type: SET_NICKNAME_ERROR, payload: "That nickname has already been taken!" });
    } else {
      dispatch({ type: SET_NICKNAME_ERROR, payload: "There was an error while setting nickname." });
    }
  } catch (err) {
    dispatch({ type: SET_NICKNAME_ERROR, payload: err.message });
  }
};

/**
 * Sets a flag to determine whether to show nickname modal on first bet or not
 *
 * @param bool
 * @return {Function}
 */
export const setFirstBet = bool => dispatch => {
  dispatch({ type: SET_FIRST_BET_FLAG, payload: bool });
};

/**
 * Gets a wallet balance for the current account
 *
 * @param account
 * @return {Function}
 */
export const getWalletBalance = account => async dispatch => {
  dispatch({ type: GET_WALLET_BALANCE_REQUEST });
  try {
    const payload = await getBalance(account);
    dispatch({ type: GET_WALLET_BALANCE_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_WALLET_BALANCE_ERROR, payload: err.message });
  }
};

/**
 * Gets a eth balance for the current account
 *
 * @param account
 * @return {Function}
 */
export const getEthWalletBalance = account => async (dispatch, getState) => {
  dispatch({ type: GET_ETH_BALANCE_REQUEST });
  try {
    if (!account) {
      account = getState().account.account;
    }
    const payload = await getEthBalance(account);
    dispatch({ type: GET_ETH_BALANCE_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_ETH_BALANCE_ERROR, payload: err.message });
  }
};

export const getWETHApproval = account => async (dispatch, getState) => {
  dispatch({ type: GET_WETH_APPROVAL_REQUEST });
  try {
    if (!account) {
      account = getState().account.account;
    }
    const payload = await getApproval(account);
    dispatch({ type: GET_WETH_APPROVAL_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_WETH_APPROVAL_ERROR, payload: err.message });
  }
};

export const getUserBonusContractBalance = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_BONUS_CONTRACT_BALANCE_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await getUserBonusBalanceFromContract(
      account,
      account
    );
    dispatch({ type: GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS, payload });
  } catch (e) {
    dispatch({
      type: GET_USER_BONUS_CONTRACT_BALANCE_ERROR,
      payload: e.message
    });
    console.log(e);
  }
};

/**
 * Gets balance from the contract for the current account
 *
 * @return {Function}
 */
export const getUserContractBalance = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_CONTRACT_BALANCE_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await getUsersBalanceFromContract(account);
    dispatch({ type: GET_USER_CONTRACT_BALANCE_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_USER_CONTRACT_BALANCE_ERROR, payload: err.message });
  }
};

/**
 * Tries to connect to the MetaMask web3 provider, also checks if the app is pre-approved
 *
 * @param silent {Boolean}
 * @return {Function}
 */
export const loginWallet = silent => async (dispatch, getState) => {
  dispatch({ type: CONNECT_PROVIDER });
  try {
    const ethereumApproved = await isEthereumApproved();

    if (silent && !ethereumApproved) {
      throw new Error("Provider not pre-approved");
    }

    if (!ethereumApproved) await ethereumEnable();

    setupWeb3();

    const network = await getNetwork();

    if (clientConfig.ethereumNetwork !== network) {
      throw new Error(
        `⚠️ Wrong network - please set network to ${nameOfNetwork(
          clientConfig.maticNetwork
        )}`
      );
    }

    const account = await getAccount();
    if (getState().account.account === account) return;

    await dispatch(getWalletBalance(account));
    await dispatch(getEthWalletBalance(account));
    await dispatch(getWETHApproval(account));
    const { walletBalance } = getState().account;
    const { ethBalance } = getState().account;

    const acceptedTOS = await getUserAcceptedTOSFromApi(account);
    let userStatus = {};
    if (acceptedTOS) {
      userStatus = await getUsersStatusFromApi(account);
    }

    const cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)badbitPlayer\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (cookieValue.substring(4, 46).toUpperCase() === account.toUpperCase()) {
      await dispatch(setIsRegistered(true));
    }
    if (cookieValue.substring(47, 48) === "1") {
      dispatch(setRememberUser(true));
    }

    await dispatch({
      type: CONNECT_PROVIDER_SUCCESS,
      payload: {
        account: account,
        walletBalance,
        ethBalance,
        network,
        acceptedTOS,
        userStatus
      }
    });

    dispatch(listenToAccChange());
    dispatch(listenForBurnEventWETH());
    dispatch(getBurnEventsFromWETH());
    dispatch(listenForBurnEventBadToken());
    dispatch(getBurnEventsFromBadToken());
    dispatch(refreshCheckpointStatus())
  } catch (err) {
    setupWeb3();
    dispatch({ type: CONNECT_PROVIDER_FAILURE, payload: err.message });
    if (!silent) notify(err.message, "error")(dispatch);
  }
};

const setRememberUser = (payload) => (dispatch) => {
  dispatch({ type: SET_REMEMBER_USER, payload });
};

function checkDate(fromDate, days = 1) {
  const daysUnixStamp = days * 24 * 60 * 60;
  const currentUnixStamp = Math.round(
    new Date(new Date().toUTCString()).getTime() / 1000
  );
  const startUnixStamp = Math.round(
    new Date(new Date(fromDate).toUTCString()).getTime() / 1000
  );
  const ref = currentUnixStamp - startUnixStamp;
  return ref >= daysUnixStamp;
}

export const setIsRegistered = payload => dispatch => {
  dispatch({ type: SET_IS_REGISTERED, payload });
};

export const verifyUser = (modalFunction = toggleModal) => (dispatch, getState) => {
  try {
    const { userStatus } = getState().account;
    const { modalOpen } = getState().modal;
    if (userStatus.status === statusTypes.flagged || userStatus.status === statusTypes.disabled) {
      if (!userStatus.completedStepOne) {
        if (userStatus.dismissedVerificationStepOne) {
          if (checkDate(userStatus.dismissedVerificationStepOne)) {
            dispatch(
              modalFunction(
                "VerifyStepOne",
                { canSkip: userStatus.status !== statusTypes.disabled, isMedium: true },
                false
              )
            );
            return;
          }
        } else {
          dispatch(
            modalFunction(
              "VerifyStepOne",
              { canSkip: userStatus.status !== statusTypes.disabled, isMedium: true },
              false
            )
          );
          return;
        }
      }
      if (userStatus.completedStepOne && !userStatus.completedStepTwo) {
        if (userStatus.dismissedVerificationStepTwo) {
          if (checkDate(userStatus.dismissedVerificationStepTwo)) {
            dispatch(
              modalFunction(
                "VerifyStepTwo",
                { canSkip: userStatus.status !== statusTypes.disabled, isMedium: true },
                false
              )
            );
          }
        } else {
          dispatch(
            modalFunction(
              "VerifyStepTwo",
              { canSkip: userStatus.status !== statusTypes.disabled, isMedium: true },
              false
            )
          );
        }
      }
    }
    if (modalOpen) dispatch(toggleModal());
    dispatch(checkAffCodeUrlParam());
  } catch (error) {
    console.log(error);
  }
};

export const checkAffCodeUrlParam = () => (dispatch) => {
  const searchParamString = window.location.search;
  const searchParams = new URLSearchParams(searchParamString);
  if (searchParamString && searchParams.has("affCode")) {
    const affCodeToAssociateWith = searchParams.get("affCode");
    dispatch(toggleModal("AssociateWithAffiliateCode", { passedCode: affCodeToAssociateWith }));
  }
};

/**
 * Performs a silent login
 *
 * @return {Function}
 */
export const silentLogin = () => async (dispatch, getState) => {
  dispatch({ type: LOGIN_STARTED });
  try {
    await dispatch(loginWallet(false));

    const { acceptedTOS, isRegistered, userStatus } = getState().account;

    if (userStatus.status === statusTypes.suspended) {
      window.location.replace("/");
      return;
    }

    if (!isRegistered) {
      dispatch(toggleModal("SignIn", {}, false))
    } else if (isRegistered && !acceptedTOS) {
      dispatch(toggleModal("ToS", {}, false));
    } else if (acceptedTOS && isRegistered) {
      dispatch(verifyUser());
    }

    return dispatch({ type: LOGIN_FINISHED });
  } catch (err) {
    console.log("LOGIN ERROR", err);
  }
  dispatch({ type: LOGIN_FINISHED });
};

/**
 * Deposit given amount of ETH to Matic Chain
 *
 * @param amount
 */
export const depositETH = (amount, type) => {
  return async (dispatch, getState) => {
    dispatch({ type: DEPOSIT_ETH_REQUEST });
    try {
      let service = 'Matic';
      const { account, walletBalance } = getState().account;
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());
      if(type === 1) {
        await badbitDepositETHService(
        account,
        amount,
        sendTxETH,
        dispatch,
        getState
      );
        service = 'BadBit.Games';
      }
      else {
        await depositETHService(
          account,
          amount,
          sendTxETH,
          dispatch,
          getState
        );
      }

      await postMovedToWeth(amount);
      await postEtherMoveEthereumToMatic(amount, service);
      dispatch({ type: DEPOSIT_ETH_SUCCESS });

      const depositInterval = setInterval(async () => {
        const newBalance = await getBalance(account);
        console.log('depositInterval: ', walletBalance, newBalance);
        if (newBalance !== walletBalance) {
          console.log('depositInterval inside: ', walletBalance, newBalance);
          dispatch({ type: GET_WALLET_BALANCE_SUCCESS, payload: newBalance });
          dispatch(getEthWalletBalance());
          clearInterval(depositInterval);
        }
      }, 10000);

    } catch (e) {
      console.log(e);
      dispatch({ type: DEPOSIT_ETH_ERROR, payload: e.message });
    }
  };
};

export const resumePendingWithdrawal = () => async (dispatch, getState) => {
  dispatch({ type: RESUME_WITHDRAWAL_REQUEST });
  try {
    const { modalOpen } = getState().modal;
    if (modalOpen) dispatch(toggleModal());

    const { account } = getState().account;
    const network = getState().account.network;

    await resumeWithdrawal(account);

    dispatch({ type: RESUME_WITHDRAWAL_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: RESUME_WITHDRAWAL_ERROR, payload: e.message });
  }
};

/**
 * Listens to account change and reloads the page if there is no account or
 * the account changes
 *
 * @return {Function}
 */
export const listenToAccChange = () => (dispatch, getState) => {
  setInterval(async () => {
    const { account, connectingProvider } = getState().account;

    if (connectingProvider) return;
    if (!account) return;

    const accounts = await window._web3.eth.getAccounts();

    if (!accounts[0]) window.location.reload();
    if (accounts[0] !== window._web3.utils.toChecksumAddress(account))
      window.location.reload();
  }, 1000);
};


export const setSentAffiliateRequest = () => async (dispatch) => {
  dispatch({ type: SET_SENT_AFFILIATE_REQUEST });
  try {
    const { data, status } = await getAffiliateRequest();
    if (status === 200) {
      dispatch({ type: SET_SENT_AFFILIATE_SUCCESS, payload: data.exists });
    } else {
      dispatch({ type: SET_SENT_AFFILIATE_FAILURE, payload: 'Error while getting affiliate request.' });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_SENT_AFFILIATE_FAILURE, payload: error.message });
  }
};

export const submitAffiliateRequest = (formData) => async (dispatch) => {
  dispatch({ type: SUBMIT_AFFILIATE_REQUEST });
  try {
    const { data, status } = await postAffiliateRequest(formData);
    if (status === 200) {
      dispatch({ type: SUBMIT_AFFILIATE_SUCCESS });
    } else {
      dispatch({ type: SUBMIT_AFFILIATE_FAILURE, payload: data.message });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SUBMIT_AFFILIATE_FAILURE, payload: error.message });
  }
};

