import { TOGGLE_DRAWER_RIGHT, TOGGLE_DRAWER_LEFT } from "../actionTypes/drawer";
import { clearUnreadMessages } from "./chat";
import {
  getPlayerLevel,
  getUsersBadTokenBalance,
  getUsersEthereumBadTokenBalance,
  getUsersStakedBadTokenBalance,
} from './badToken';

export const toggleDrawerRight = (
  drawerRightOpen = false,
  drawerRightComponent = ""
) => dispatch => {
  dispatch({
    type: TOGGLE_DRAWER_RIGHT,
    payload: {
      drawerRightOpen,
      drawerRightComponent
    }
  });

  if (drawerRightComponent === "Chat") dispatch(clearUnreadMessages());
  if (drawerRightComponent === "AccountInfo") {
    dispatch(getPlayerLevel());
    dispatch(getUsersBadTokenBalance());
    dispatch(getUsersEthereumBadTokenBalance());
    dispatch(getUsersStakedBadTokenBalance());
  }

  if (window.innerWidth <= 425 && drawerRightOpen) {
    document.querySelector("html").classList.add("overflowHidden");
    document.querySelector("body").classList.add("overflowHidden");
  }
  if (window.innerWidth <= 425 && !drawerRightOpen) {
    document.querySelector("html").classList.remove("overflowHidden");
    document.querySelector("body").classList.remove("overflowHidden");
  }
};

export const toggleDrawerLeft = (
  drawerLeftOpen = false,
  drawerLeftComponent = ""
) => dispatch => {
  dispatch({
    type: TOGGLE_DRAWER_LEFT,
    payload: {
      drawerLeftOpen,
      drawerLeftComponent
    }
  });

  if (drawerLeftComponent === "Chat") dispatch(clearUnreadMessages());
};
