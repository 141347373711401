const web3Coder = require('web3-eth-abi');

import {
  GET_SPIN_GAME_STATE_REQUEST,
  GET_SPIN_GAME_STATE_SUCCESS,
  GET_SPIN_GAME_STATE_ERROR,
  GET_SPIN_ROUND_STATE_REQUEST,
  GET_SPIN_ROUND_STATE_SUCCESS,
  GET_SPIN_ROUND_STATE_ERROR,
  SET_SPIN_BET_AMOUNT,
  SET_SPIN_WIN_CHANCE,
  SET_SPIN_WIN_PRIZE,
  SET_SPIN_GAME_TYPE,
  SET_SPIN_LAST_ROUND_PLAYED,
  SET_SPIN_LAST_SELECTED_GAME_TYPE,
  SET_SPIN_LAST_USER_SELECTED_GAME_TYPE,
  SET_SPIN_LAST_WIN_PRIZE,
  PLACE_SPIN_BET_REQUEST,
  PLACE_SPIN_BET_ERROR,
  PLACE_SPIN_BET_SUCCESS,
  START_SPIN_AUTO_BET_REQUEST,
  START_SPIN_AUTO_BET_SUCCESS,
  START_SPIN_AUTO_BET_ERROR,
  STOP_SPIN_AUTO_BET_REQUEST,
  STOP_SPIN_AUTO_BET_SUCCESS,
  STOP_SPIN_AUTO_BET_ERROR,
  GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR,
  GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST,
  GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS,
  CLOSE_SPIN_ROUND,
  GET_ACC_SPIN_TOTAL_BETS_REQUEST,
  GET_ACC_SPIN_TOTAL_BETS_SUCCESS,
  GET_ACC_SPIN_TOTAL_BETS_ERROR,
  SET_SPIN_SHOW_SPINNER,
  SET_SPIN_SHOW_CONTROLS,
  SET_SPIN_PAUSED,
  SET_SPIN_LAST_ROUND_BLOCK,
  SPIN_LISTENERS_INITIATED,
  GET_SPIN_INIT_DATA_REQUEST,
  GET_SPIN_INIT_DATA_SUCCESS,
  GET_SPIN_INIT_DATA_ERROR,
  UPDATE_SPIN_AUTO_BET_REQUEST,
  UPDATE_SPIN_AUTO_BET_SUCCESS,
  UPDATE_SPIN_AUTO_BET_ERROR,
  SPIN_AUTO_BET_STARTED,
  SPIN_AUTO_BET_STOPPED,
  SET_SPIN_AUTO_BET_DATA
} from "../../actionTypes/games/spin";
import BigNumber from "bignumber.js";
import {
  betFromSpinContract,
  getLastRoundFromSpinContract,
  getRoundTimestampFromSpinContract,
  getSpinBetsAllowedFromContract,
  getTotalBetsFromSpinContract,
  listenToRoundFinishedFromSpinContract,
  listenToRoundStartedFromSpinContract,
  listenToSpinPausedFromContract,
  listenForBetPlayedEventFromSpinContract,
  listenSpinAutoBetStartedEvent,
  listenSpinAutoBetStoppedEvent,
  getAutobetInfo
} from "../../services/ethereum/spin";
import {
  calculateSpinWinChance,
  calculateSpinWinPrize,
  getSpinContractConstValues,
  getSpinRoundTime,
  getSpinMaxBetValue,
  getStandardLot
} from "../contractConstants/spin";
import { sendTx } from "../txNotifications";
import {
  addNewLevelModal,
  checkIfRanOutOfContractBalanceWhileAutoBetting,
  checkIsRunningOutOfContractBalanceWhileAutoBetting,
  getAccTotalBets,
  validatePlaceBet,
} from './games';
import {
  getUserBonusContractBalance,
  getUserContractBalance,
  getWalletBalance,
  setFirstBet
} from "../account";
import {
  setSpinLastRoundPlayedToLS,
  setSpinLastUserSelectedGameTypeToLS,
  setSpinLastWinPrizeToLS
} from "../../services/localStorage";
import { setRoundTimer } from "../roundTimer";
import {
  getOtherSpinPlayersActivity,
  listenToOtherSpinPlayersActivity,
  resetPlayersActivity
} from "../playersActivity";
import { addSpins, getPreviousSpins } from "../betHistory";
import { setNumberOfWithdrawals } from "../assetMovmentlHistory";
import { toggleModal } from "../modal";
import { checkIfPlayerHasWonSpinBet } from "../../services/utils";
import {
  addBadTokenWinNotification,
  areTokensDistributed,
  calculateNumberOfTokensWon,
  getBadTokenWinningProbability,
  getPlayerLevel,
} from "../badToken";
import {ethToWei, getBlockNumber, giveInfiniteApproval, weiToEth} from '../../services/ethereum/main';
import { getWETHApproval } from "../account";
import { startAutoBetFromContract, stopAutoBetFromContract } from '../../services/ethereum/games';
import { spinGameAddress } from '../../services/contract';

/**
 *
 * @return {Function} Gives a game state
 */
export const getSpinGameState = () => async (dispatch, getState) => {
  dispatch({ type: GET_SPIN_GAME_STATE_REQUEST });
  const { gameType } = getState().gameSpin;
  try {
    await dispatch(getSpinRoundState());
    const { firstBet } = getState().games;
    const blockNumber = await getBlockNumber();
    await dispatch(setSpinLastRoundBlock(blockNumber));
    await dispatch(setSpinRoundTime());
    await dispatch(getSpinContractConstValues(gameType));
    if (firstBet) await dispatch(getAccTotalBets());
    dispatch(setSpinWinningPrize());

    const { roundState, betAmount } = getState().gameSpin;
    const { roundTime } = getState().spinConstants;
    if (roundState !== "finished") await dispatch(getOtherSpinPlayersActivity(blockNumber - roundTime - 50));
    dispatch(setNumberOfWithdrawals());

    const { minBetSize: minBetValue } = getState().gamesConstants;
    if (betAmount === "0") await dispatch(setSpinBetAmount(minBetValue));

    const { lastRoundPlayed, bettingRound } = getState().gameSpin;
    if (lastRoundPlayed === bettingRound && roundState !== "finished") {
      dispatch(setSpinShowControls(false));
      if (roundState === "closed") dispatch(setSpinShowSpinner(true));
    }

    const { account } = getState().account;
    const { betAmount: amount, numberOfBets, payload, totalNumberOfBets } = await getAutobetInfo(account)
    if (Number(numberOfBets) > 0) {
      const currentRound = Number(totalNumberOfBets) - Number(numberOfBets);
      dispatch(setAutoBetData(Number(totalNumberOfBets), currentRound));
    }

    await dispatch(getPreviousSpins());
    dispatch({ type: GET_SPIN_GAME_STATE_SUCCESS });
  } catch (e) {
    dispatch({ type: GET_SPIN_GAME_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

/**
 * Sets showSpinner game state
 *
 * @param payload
 * @return {Function}
 */
export const setSpinShowSpinner = payload => dispatch => {
  dispatch({ type: SET_SPIN_SHOW_SPINNER, payload });
};

/**
 * Sets showControls game state
 *
 * @param payload
 * @return {Function}
 */
export const setSpinShowControls = payload => dispatch => {
  dispatch({ type: SET_SPIN_SHOW_CONTROLS, payload });
};

/**
 * Gets a total number of placed bets for the current account
 *
 * @return {Function}
 */
export const getAccSpinTotalBets = () => async (dispatch, getState) => {
  dispatch({ type: GET_ACC_SPIN_TOTAL_BETS_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getTotalBetsFromSpinContract(account);
    dispatch({ type: GET_ACC_SPIN_TOTAL_BETS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_ACC_SPIN_TOTAL_BETS_ERROR, payload: err.message });
  }
};

/**
 * Sets a bet amount
 *
 * @param betAmount {string}
 * @return {Function}
 */
export const setSpinBetAmount = betAmount => dispatch => {
  try {
    console.log("betAmount", betAmount);
    dispatch({
      type: SET_SPIN_BET_AMOUNT,
      payload: betAmount
    });
    dispatch(setSpinWinningPrize());
    dispatch(getBadTokenWinningProbability());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets current round state and number
 *
 * @return {Function}
 */
export const getSpinRoundState = () => async (dispatch, getState) => {
  dispatch({ type: GET_SPIN_ROUND_STATE_REQUEST });
  try {
    const { account } = getState().account;
    const { state, lastRound } = await getLastRoundFromSpinContract(account);
    let round = Number(lastRound);

    if (state === "0") {
      console.log("State open.");
      dispatch({
        type: GET_SPIN_ROUND_STATE_SUCCESS,
        payload: { status: "open", round }
      });
    } else if (state === "1") {
      console.log("State closed.");
      dispatch({
        type: GET_SPIN_ROUND_STATE_SUCCESS,
        payload: { status: "closed", round }
      });
      dispatch(closeSpinRound());
    } else if (state === "2") {
      console.log("State finished.");
      dispatch({
        type: GET_SPIN_ROUND_STATE_SUCCESS,
        payload: { status: "finished", round }
      });
      dispatch(setSpinShowControls(true));
    }
  } catch (e) {
    dispatch({ type: GET_SPIN_ROUND_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

/**
 * Calculates and sets a winning chance
 *
 * @return {Function}
 */
export const setSpinWinChance = () => (dispatch, getState) => {
  try {
    const { gameType } = getState().gameSpin;
    const payload = calculateSpinWinChance(gameType);
    dispatch({ type: SET_SPIN_WIN_CHANCE, payload });
    dispatch(setSpinWinningPrize());
    dispatch(getBadTokenWinningProbability());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a winning prize
 *
 * @return {Function}
 */
export const setSpinWinningPrize = () => (dispatch, getState) => {
  try {
    const { betAmount, winChance } = getState().gameSpin;
    const payload = calculateSpinWinPrize(betAmount, winChance);
    dispatch({ type: SET_SPIN_WIN_PRIZE, payload });
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a game type and game subtype
 *
 * @param gameTypeID {number}
 * @return {Function}
 */
export const setSpinGameType = gameTypeID => async dispatch => {
  try {
    await dispatch({ type: SET_SPIN_GAME_TYPE, payload: gameTypeID });
    dispatch(getSpinMaxBetValue(gameTypeID));
    dispatch(setSpinWinChance());
  } catch (e) {
    console.log(e);
  }
};

const getPayload = (_type) => {
  return web3Coder.encodeParameter(
    {
      "ParentStruct": {
        "_type": 'uint256'
      }
    },
    { _type}
  );
}

export const setAutoBetData = (autoBettingRounds, autoBettingCurrentRound) => (dispatch) => {
  dispatch({ type: SET_SPIN_AUTO_BET_DATA, payload: { autoBettingRounds, autoBettingCurrentRound } });
};

export const startSpinAutoBet = (numberOfRounds) => async (dispatch, getState) => {
  dispatch({ type: START_SPIN_AUTO_BET_REQUEST });
  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      roundState,
      gameType,
      betAmount,
      betValue,
      winPrize,
      winChance
    } = getState().gameSpin;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minBetValue } = getState().gamesConstants;
    const { maxBetValue } = getState().spinConstants;
    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBet(
      betAmount,
      minBetValue,
      maxBetValue,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      START_SPIN_AUTO_BET_ERROR,
      dispatch,
      true
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true, isAutoBet: true, numberOfRounds }));
      return;
    }

    dispatch(setFirstBet(false));

    let payload = {};

    payload = getPayload(gameType);

    const hasSucceeded = await startAutoBetFromContract(
      spinGameAddress, numberOfRounds, betAmount, payload, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: START_SPIN_AUTO_BET_SUCCESS, payload: numberOfRounds });
    } else {
      dispatch({ type: START_SPIN_AUTO_BET_ERROR, payload: "There was an error while placing auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: START_SPIN_AUTO_BET_ERROR, payload: error.message });
  }
};

export const stopSpinAutoBet = () => async (dispatch, getState) => {
  dispatch({ type: STOP_SPIN_AUTO_BET_REQUEST });
  try {
    const { account } = getState().account;

    const hasSucceeded = await stopAutoBetFromContract(
      spinGameAddress, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: STOP_SPIN_AUTO_BET_SUCCESS });
    } else {
      dispatch({ type: STOP_SPIN_AUTO_BET_ERROR, payload: "There was an error while stopping auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: STOP_SPIN_AUTO_BET_ERROR, payload: error.message });
  }
};

/**
 * Sets a bet that user placed
 *
 * @return {Function}
 */
export const placeSpinBet = () => async (dispatch, getState) => {
  dispatch({ type: PLACE_SPIN_BET_REQUEST });

  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      roundState,
      gameType,
      betAmount,
      betValue,
      winPrize,
      winChance
    } = getState().gameSpin;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minBetValue } = getState().gamesConstants;
    const { maxBetValue } = getState().spinConstants;
    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBet(
      betAmount,
      minBetValue,
      maxBetValue,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      PLACE_SPIN_BET_ERROR,
      dispatch
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    dispatch(setFirstBet(false));

    const hasSucceeded = await betFromSpinContract(
      betValue,
      betAmount,
      gameType,
      account,
      betWithContractBalance,
      betWithContractBonusBalance,
      sendTx,
      dispatch,
      getState
    );

    if (hasSucceeded) {
      setTimeout(() => dispatch(getWalletBalance(account)), 3000);
      dispatch(getUserBonusContractBalance());
      console.log("PLACE_SPIN_BET_SUCCESS");
      dispatch({ type: PLACE_SPIN_BET_SUCCESS });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: PLACE_SPIN_BET_ERROR, payload: e.message });
  }
};

/**
 * Sets last bat inputs when user places a bet
 *
 * @param selectedGameType
 * @param lastWinPrize
 * @return {Function}
 */
export const setSpinLastBetInputs = (
  selectedGameType,
  lastWinPrize
) => dispatch => {
  dispatch(setSpinLastUserSelectedGameType(selectedGameType));
  dispatch(setSpinLastWinPrize(lastWinPrize));
};

/**
 *
 * @param selectedGameType
 * @return {Function}
 */
export const setSpinLastSelectedGameType = selectedGameType => dispatch => {
  dispatch({
    type: SET_SPIN_LAST_SELECTED_GAME_TYPE,
    payload: selectedGameType
  });
};

/**
 * Sets a last win prize for spin game
 *
 * @param lastWinPrize
 * @param addToLS
 * @return {Function}
 */
export const setSpinLastWinPrize = (lastWinPrize, addToLS = true) => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_SPIN_LAST_WIN_PRIZE, payload: lastWinPrize });
  if (!addToLS) return;
  const { account } = getState().account;
  setSpinLastWinPrizeToLS(account, lastWinPrize);
};

/**
 *
 * @param selectedGameType
 * @param addToLS
 * @return {Function}
 */
export const setSpinLastUserSelectedGameType = (
  selectedGameType,
  addToLS = true
) => (dispatch, getState) => {
  dispatch({
    type: SET_SPIN_LAST_USER_SELECTED_GAME_TYPE,
    payload: selectedGameType
  });
  if (!addToLS) return;
  const { account } = getState().account;
  setSpinLastUserSelectedGameTypeToLS(account, selectedGameType);
};

export const setSpinRoundTime = (timestamp) => async (dispatch, getState) => {
  try {
    await dispatch(getSpinRoundTime());
    if (timestamp) {
      await dispatch(getSpinRoundTimeStamp(timestamp, true));
    } else {
      const { spinLastRoundBlock } = getState().gameSpin;
      const { roundTime } = getState().spinConstants;
      await dispatch(getSpinRoundTimeStamp(spinLastRoundBlock - roundTime - 100));
    }
    await dispatch(setRoundTimer("spin"));
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets round bet state to "closed"
 *
 * @return {Function}
 */
export const closeSpinRound = () => dispatch => {
  dispatch({ type: CLOSE_SPIN_ROUND });
  dispatch(setSpinShowControls(false));
  dispatch(setSpinShowSpinner(true));
};

/**
 * Gets timestamp from the last round
 *
 * @return {Function}
 */
export const getSpinRoundTimeStamp = (payload, fromTimestamp) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameSpin;
  console.log(roundState);
  if (roundState === "finished") return;
  dispatch({ type: GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST });
  try {
    if (fromTimestamp) {
      dispatch({ type: GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS, payload });
      return;
    }
    console.log(bettingRound);
    const data = await getRoundTimestampFromSpinContract(payload, bettingRound);
    dispatch({ type: GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR,
      payload: err.message
    });
  }
};

/**
 * Sets a last round number
 *
 * @param lastRoundPlayed
 * @param addToLS
 * @return {Function}
 */
export const setSpinLastRoundPlayed = (lastRoundPlayed, addToLS = true) => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_SPIN_LAST_ROUND_PLAYED, payload: lastRoundPlayed });
  if (!addToLS) return;
  const { account } = getState().account;
  setSpinLastRoundPlayedToLS(account, lastRoundPlayed);
};

export const setSpinPaused = () => async (dispatch) => {
  try {
    const spin = await getSpinBetsAllowedFromContract();
    dispatch({ type: SET_SPIN_PAUSED, payload: !spin });
  } catch (error) {
    console.log(error);
  }
}

export const listenToSpinPaused = () => dispatch => {
  const callback = ({ bool }) => {
    dispatch({ type: SET_SPIN_PAUSED, payload: bool });
    if (!bool) {
      dispatch(getSpinInitialData(false));
    }
  };
  listenToSpinPausedFromContract(callback);
};

/**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForSpinRoundStarted = () => (dispatch, getState) => {
  try {
    const callback = async ({ id, timestamp, blockNumber }) => {
      console.log("SPIN ROUND STARTED CALLBACK");
      dispatch({
        type: GET_SPIN_ROUND_STATE_SUCCESS,
        payload: { status: "open", round: Number(id) }
      });

      const { activeGame } = getState().games;
      if (activeGame !== "spin") return;

      console.log("IS SPIN");

      dispatch(setSpinLastRoundBlock(blockNumber));
      // await dispatch(getHouseEdge());
      await dispatch(setSpinRoundTime(timestamp));
    };
    listenToRoundStartedFromSpinContract(callback);
  } catch (error) {
    console.log(error);
  }
};

export const listenForSpinBetPlayed = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (round, player, blockNumber) => {
      const { isAutoBetting, autoBetBalanceNotificationDismissed } = getState().gameSpin;
        if (isAutoBetting) {
          try {
            dispatch({
              type: UPDATE_SPIN_AUTO_BET_REQUEST,
              payload: ""
            });
            const { betAmount, numberOfBets, totalNumberOfBets } = await getAutobetInfo(player);
            if (!autoBetBalanceNotificationDismissed) await dispatch(checkIsRunningOutOfContractBalanceWhileAutoBetting(account, betAmount, totalNumberOfBets))
            if (Number(numberOfBets) === 0) {
              return dispatch({
                type: SPIN_AUTO_BET_STOPPED,
                payload: 0
              });
            }
            const currBetNumber = Number(totalNumberOfBets) - Number(numberOfBets);
            dispatch({
              type: UPDATE_SPIN_AUTO_BET_SUCCESS,
              payload: currBetNumber
            });
          } catch (error) {
            dispatch({
              type: UPDATE_SPIN_AUTO_BET_ERROR,
              payload: error.message
            });
          }
        }

    };

    listenForBetPlayedEventFromSpinContract(account, callback);
  } catch (err) {
    console.log(err);
  }
};

export const listenForSpinAutoBetStarted = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, noOfBets, blockNumber) => {
          try {

            dispatch({
              type: SPIN_AUTO_BET_STARTED,
              payload: noOfBets.toString()
            });


          } catch (error) {
            console.log(error);
          }
    };

    listenSpinAutoBetStartedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};


export const listenForSpinAutoBetStopped = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, remainingBets, blockNumber) => {
          try {
            const { betAmount } = await getAutobetInfo(account);
            await dispatch(checkIfRanOutOfContractBalanceWhileAutoBetting(account, betAmount));
            dispatch({
              type: SPIN_AUTO_BET_STOPPED,
              payload: remainingBets
            });
            setTimeout(() => {
              dispatch(setSpinShowControls(true));
              dispatch(setSpinShowSpinner(false));
            }, 3000);
          } catch (error) {
            console.log(error);
          }
    };

    listenSpinAutoBetStoppedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};

export const setSpinLastRoundBlock = (payload) => (dispatch) =>
  dispatch({ type: SET_SPIN_LAST_ROUND_BLOCK, payload });

/**
 * Makes a Event listener to check if a new round has finished
 *
 * @return {Function}
 */
export const listenForSpinRoundFinished = () => (dispatch, getState) => {
  const callback = async ({round, color}) => {
    console.log("SPIN ROUND FINISHED CALLBACK");
    dispatch(getStandardLot());
    dispatch(setSpinLastSelectedGameType(color));

    const { lastRoundPlayed, gameType } = getState().gameSpin;
    console.log(
      `ROUND FINISHED: ${round}, \nUSER PLAYED ROUND: ${lastRoundPlayed}`
    );

    if (round !== lastRoundPlayed) {
      setTimeout(() => {
        dispatch(setSpinShowControls(true));
        dispatch(setSpinShowSpinner(false));
      }, 6000);
    }

    dispatch({
      type: GET_SPIN_ROUND_STATE_SUCCESS,
      payload: { status: "finished", round }
    });

    const { activeGame } = getState().games;
    const { account } = getState().account;

    if (activeGame === "spin" && round === lastRoundPlayed) {
      const areDistributed = await areTokensDistributed(account);
      if (!areDistributed) {
        const tokenAmount = await calculateNumberOfTokensWon(account, "spin");
        const { standardLot, GBM } = getState().spinConstants;
        if (color === 5) {
          const { betAmount } = getState().gameSpin;

        const badTokensOnGold =
          new BigNumber(weiToEth(betAmount)).dividedBy(weiToEth(GBM)).multipliedBy(standardLot).toString();

        dispatch(
            addBadTokenWinNotification(
              new BigNumber(weiToEth(tokenAmount))
                .plus(badTokensOnGold)
                .toString()
            )
          );
        } else {
          dispatch(addBadTokenWinNotification(weiToEth(tokenAmount)));
        }
      }
    }
    const { playerLevel } = getState().badToken;
    if (round === lastRoundPlayed && Number(playerLevel) < 100) await dispatch(addNewLevelModal());
    if (round === lastRoundPlayed) await dispatch(checkSpinWinning());

    dispatch(setSpinLastRoundPlayed(0));
    setTimeout(() => {
      const { activeGame } = getState().games;
      if (activeGame === "spin") {
        dispatch(addSpins({ round, color }));
        dispatch(resetPlayersActivity());
      }
      dispatch(getUserContractBalance());
      dispatch(getPlayerLevel());
      dispatch(getUserBonusContractBalance());
      dispatch(getWalletBalance(account));
      dispatch(getAccTotalBets());
      dispatch(getSpinContractConstValues(gameType));
    }, 3000);
  };

  listenToRoundFinishedFromSpinContract(callback);
};

/**
 * Checks if player has won in the last played round
 * opens a modal and shows a result
 *
 * @return {Function}
 */
export const checkSpinWinning = () => (dispatch, getState) => {
  try {
    const {
      lastRoundPlayed,
      bettingRound,
      lastSelectedGameType,
      lastUserSelectedGameType,
      lastWinPrize
    } = getState().gameSpin;

    const hasWon = checkIfPlayerHasWonSpinBet(
      lastSelectedGameType,
      lastUserSelectedGameType
    );

    const hasPlayed = lastRoundPlayed === bettingRound;
    if (hasPlayed) {
      const { activeGame } = getState().games;
      if (activeGame === "spin") {
        setTimeout(() => {
          const { modalOpen, modalComponent } = getState().modal;

          if (
            modalOpen &&
            (modalComponent !== "WinLose" || modalComponent !== "ModalLoader")
          )
            dispatch(toggleModal());

          dispatch(
            toggleModal('WinLose', {
              hasWon,
              lastSelectedGameType,
              lastUserSelectedGameType,
              game: 'spin',
              isSide: true,
            }),
          );
          const { isAutoBetting } = getState().gameSpin;
          if (isAutoBetting) setTimeout(() => {
            const { modalOpen } = getState().modal;
            if (modalOpen) dispatch(toggleModal());
          }, 2000);
        }, 6000);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const initSpinSpecificListeners = () => (dispatch) => {
  dispatch(listenForSpinRoundStarted());
  dispatch(listenForSpinBetPlayed());
  dispatch(listenForSpinAutoBetStarted());
  dispatch(listenForSpinAutoBetStopped());
  dispatch(listenForSpinRoundFinished());
  dispatch(listenToOtherSpinPlayersActivity());
  dispatch(listenToSpinPaused());
  dispatch({ type: SPIN_LISTENERS_INITIATED })
};

/**
 * Fetches fresh information that is displayed when the player refreshes
 * or first opens the main page and starts event listeners
 *
 * @return {Function}
 */
export const getSpinInitialData = (shouldGetBetsAllowed = true) => async (dispatch, getState) => {
  dispatch({ type: GET_SPIN_INIT_DATA_REQUEST });
  try {
    if (shouldGetBetsAllowed) await dispatch(setSpinPaused());
    await dispatch(getSpinGameState());
    const { spinListenersInitiated } = getState().gameSpin;
    if (!spinListenersInitiated) dispatch(initSpinSpecificListeners());
    dispatch(getBadTokenWinningProbability());
    dispatch({ type: GET_SPIN_INIT_DATA_SUCCESS });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SPIN_INIT_DATA_ERROR, payload: error.message });
  }
};
