import React, { Fragment } from "react";
import { connect } from "react-redux";
import { toggleDrawerRight, toggleDrawerLeft } from "../../actions/drawer";

import ArrowBack from "../Decorative/Icons/ArrowBack";
import ChatIcon from "../Decorative/Icons/ChatIcon";
import NotifIcon from "../Decorative/Icons/NotifIcon";
import "./Drawer.scss";

const DrawerRight = ({ drawerRightOpen, drawerRightComponent, toggleDrawerRight, toggleDrawerLeft, unreadNotifications, unreadMessages, adminOnline, drawerLeftComponent }) => {
  if (drawerRightOpen) {
    const {
      default: DrawerComponent
    } = require(`./DrawerTypes/${drawerRightComponent}/${drawerRightComponent}.js`);

    return (
      <Fragment>
        <aside className="aside">
          <nav className="aside__nav">
            <section className="section">
              {/*{drawerRightComponent === "Notifications" && (*/}
              {/*  <ArrowBack clicked={() => toggleDrawerRight()} />*/}
              {/*)}*/}
              {/*{drawerComponent !== "Notifications" && (*/}
              {/*  <NotifIcon*/}
              {/*    numberOfNotifs={unreadNotifications}*/}
              {/*    clicked={() => toggleDrawerRight(true, "Notifications")}*/}
              {/*  />*/}
              {/*)}*/}
              <ChatIcon
                numberOfUnreadMsg={unreadMessages}
                isAdminOnline={adminOnline}
                clicked={() =>
                  drawerLeftComponent === "Chat"
                    ? toggleDrawerLeft()
                    : toggleDrawerLeft(true, "Chat")} />
            </section>
          </nav>
        </aside>
        <section className="aside__drawer aside__drawer--open">
          <DrawerComponent />
        </section>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <aside className="aside">
          <nav className="aside__nav">
            <section className="section">
              {/*<NotifIcon numberOfNotifs={unreadNotifications} clicked={() => toggleDrawerRight(true, "Notifications")} />*/}
              <ChatIcon
                numberOfUnreadMsg={unreadMessages}
                isAdminOnline={adminOnline}
                clicked={() =>
                  drawerLeftComponent === "Chat"
                    ? toggleDrawerLeft()
                    : toggleDrawerLeft(true, "Chat")} />
            </section>
          </nav>
        </aside>
        <section className="aside__drawer" />
      </Fragment>
    );
  }
};

const mapStateToProps = ({ drawer, txNotifications, chat }) => ({
  drawerRightOpen: drawer.drawerRightOpen,
  drawerRightComponent: drawer.drawerRightComponent,
  drawerLeftComponent: drawer.drawerLeftComponent,
  unreadNotifications: txNotifications.unreadNotifications,
  unreadMessages: chat.unreadMessages,
  adminOnline: chat.adminOnline
});

const mapDispatchToProps = {
  toggleDrawerRight,
  toggleDrawerLeft
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerRight);
