import React, { Component } from "react";
import PropTypes from "prop-types";

class Dice extends Component {
  constructor(props) {
    super(props);
    this.handleLeftAnimationIteration = this.handleLeftAnimationIteration.bind(
      this
    );
    this.handleRightAnimationIteration = this.handleRightAnimationIteration.bind(
      this
    );
  }

  handleLeftAnimationIteration = e => {
    const { roundState, lastSelectedNumber } = this.props;
    if (roundState === "finished") {
      const face =
        lastSelectedNumber.toString().length === 1
          ? "0"
          : `${lastSelectedNumber.toString().substring(0, 1)}`;
      e.target.classList.remove("rolling");
      e.target.dataset.face = face;
    }
  };

  handleRightAnimationIteration = e => {
    const { roundState, lastSelectedNumber } = this.props;
    if (roundState === "finished") {
      const face =
        lastSelectedNumber.toString().length === 1
          ? lastSelectedNumber
          : `${lastSelectedNumber.toString().substring(1, 2)}`;
      e.target.classList.remove("rolling-reverse");
      e.target.dataset.face = face;
    }
  };

  render() {
    return (
      <div className="dice">
        <div
          className={`die left rolling`}
          onAnimationIteration={this.handleLeftAnimationIteration}
          data-face=""
        >
          <figure className="face face-0" />
          <figure className="face face-1" />
          <figure className="face face-2" />
          <figure className="face face-3" />
          <figure className="face face-4" />
          <figure className="face face-5" />
          <figure className="face face-6" />
          <figure className="face face-7" />
          <figure className="face face-8" />
          <figure className="face face-9" />
        </div>
        <div
          className={`die right rolling-reverse`}
          onAnimationIteration={this.handleRightAnimationIteration}
          data-face=""
        >
          <figure className="face face-0" />
          <figure className="face face-1" />
          <figure className="face face-2" />
          <figure className="face face-3" />
          <figure className="face face-4" />
          <figure className="face face-5" />
          <figure className="face face-6" />
          <figure className="face face-7" />
          <figure className="face face-8" />
          <figure className="face face-9" />
        </div>
      </div>
    );
  }
}

Dice.propTypes = {};

export default Dice;
