import React, { Component, Fragment } from "react";

// Components
import RollOver from "../../../Decorative/GameTypeIcons/RollOver/RollOver";
import RollUnder from "../../../Decorative/GameTypeIcons/RollUnder/RollUnder";
import EvenOrOdd from "../../../Decorative/GameTypeIcons/EvenOrOdd/EvenOrOdd";
import DragonOrTiger from "../../../Decorative/GameTypeIcons/DragonOrTiger/DragonOrTiger";
import GameTypeInfo from "./GameTypeInfo/GameTypeInfo";

import "./GameType.scss";

class GameType extends Component {
  render() {
    const { game, gameType, handleGameType, disabled } = this.props;
    return (
      <div className="main__section__body main__section__body--game-type">
        <span className="main__section__heading">
          {game === "roll" && "Game type: "}
          {game === "spin" && "Choose color: "}
        </span>
        <div
          className={`controls ${game}`}
        >
          {game === "roll" && (
            <Fragment>
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="0"
                name="game-type"
                checked={gameType === 0}
                onChange={handleGameType}
              />
              <label className="controls__label" htmlFor="0">
                <RollOver />
              </label>
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="1"
                name="game-type"
                checked={gameType === 1}
                onChange={handleGameType}
              />
              <label className="controls__label" htmlFor="1">
                <RollUnder />
              </label>
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="3"
                name="game-type"
                checked={gameType === 3}
                onChange={handleGameType}
              />
              <label className="controls__label" htmlFor="3">
                <EvenOrOdd />
              </label>
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="4"
                name="game-type"
                checked={gameType === 4}
                onChange={handleGameType}
              />
              <label className="controls__label" htmlFor="4">
                <DragonOrTiger />
              </label>
            </Fragment>
          )}
          {game === "spin" && (
            <Fragment>
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="1"
                name="spin-game-type"
                checked={gameType === 1}
                onChange={handleGameType}
              />
              <label className="controls__label white" htmlFor="1" />
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="2"
                name="spin-game-type"
                checked={gameType === 2}
                onChange={handleGameType}
              />
              <label className="controls__label red" htmlFor="2" />
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="3"
                name="spin-game-type"
                checked={gameType === 3}
                onChange={handleGameType}
              />
              <label className="controls__label blue" htmlFor="3" />
              <input
                disabled={disabled}
                className="controls__radio"
                type="radio"
                id="4"
                name="spin-game-type"
                checked={gameType === 4}
                onChange={handleGameType}
              />
              <label className="controls__label orange" htmlFor="4" />
            </Fragment>
          )}
        </div>
        {game === "roll" && <GameTypeInfo gameType={gameType} />}
      </div>
    );
  }
}

export default GameType;
