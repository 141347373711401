import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleModal } from "../../../../actions/modal";

import "./BanUser.scss";
import { banUser, removeBanUser } from "../../../../actions/chat";

class BanUser extends Component {
  render() {
    const {
      address,
      toggleModal,
      banUser,
      removeBanUser,
      dialog,
      success,
      ban,
      removeBan
    } = this.props;
    return (
      <div className="modal__content modal__content--ban">
        {dialog && (
          <Fragment>
            <p className="title">Are you sure?</p>
            <div className="btn__group">
              <button
                className="btn btn--primary"
                onClick={() => {
                  if (ban) banUser(address)
                  if (removeBan) removeBanUser(address)
                }}
              >
                Yes
              </button>
              <button
                className="btn btn--dark"
                onClick={() => toggleModal()}
              >
                No
              </button>
            </div>
          </Fragment>
        )}
        {!dialog && success && (
          <Fragment>
            <p className="title">
              Successfully {ban ? "banned user" : "removed a ban"}
            </p>
            <p className="description">
              {ban ? "Banned address" : "Address"}: <span>{address}</span>
            </p>
            <div className="btn__group">
              <button className="btn btn--dark" onClick={() => toggleModal()}>
                Close
              </button>
            </div>
          </Fragment>
        )}
        {!dialog && !success && (
          <Fragment>
            <p className="title">Oops, error!</p>
            <p className="description">
              There was an error while trying to ban a user!
            </p>
            <div className="btn__group">
              <button
                className="btn btn--primary"
                onClick={() => {
                  if (ban) banUser(address)
                  if (removeBan) removeBanUser(address)
                }}
              >
                Try again
              </button>
              <button className="btn btn--dark" onClick={() => toggleModal()}>
                Close
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

BanUser.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  banUser: PropTypes.func.isRequired,
  removeBanUser: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  toggleModal,
  banUser,
  removeBanUser
};

export default connect(
  null,
  mapDispatchToProps
)(BanUser);
