import {
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR,
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST,
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS,
  GET_SPIN_MAX_BET_VAL_ERROR,
  GET_SPIN_MAX_BET_VAL_REQUEST,
  GET_SPIN_MAX_BET_VAL_SUCCESS,
  GET_SPIN_ROUND_TIME_ERROR,
  GET_SPIN_ROUND_TIME_REQUEST,
  GET_SPIN_ROUND_TIME_SUCCESS,
  GET_SPIN_STANDARD_LOT_REQUEST,
  GET_SPIN_STANDARD_LOT_SUCCESS,
  GET_SPIN_STANDARD_LOT_ERROR,
  GET_GBM_REQUEST,
  GET_GBM_SUCCESS,
  GET_GBM_ERROR,
} from "../../actionTypes/contractConstants/spin";

const INITIAL_STATE = {
  maxBetValue: "0",
  gettingMaxBetValue: false,
  gettingMaxBetValueErr: "",

  roundTime: 0,
  gettingRoundTime: false,
  gettingRoundTimeError: "",

  benchmarkMaxBetSize: 0,
  gettingBenchmarkMaxBetSize: false,
  gettingBenchmarkMaxBetSizeError: "",

  standardLot: "0",
  gettingStandardLot: false,
  gettingStandardLotErr: "",

  GBM: "0",
  gettingGBM: false,
  gettingGBMErr: '',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SPIN_MAX_BET_VAL_REQUEST:
      return {
        ...state,
        gettingMaxBetValue: true,
        gettingMaxBetValueErr: ""
      };

    case GET_SPIN_MAX_BET_VAL_SUCCESS:
      return {
        ...state,
        maxBetValue: payload,
        gettingMaxBetValue: false,
        gettingMaxBetValueErr: ""
      };

    case GET_SPIN_MAX_BET_VAL_ERROR:
      return {
        ...state,
        gettingMaxBetValue: false,
        gettingMaxBetValueErr: payload
      };

    case GET_SPIN_ROUND_TIME_REQUEST:
      return {
        ...state,
        gettingRoundTime: true,
        gettingRoundTimeError: ""
      };

    case GET_SPIN_ROUND_TIME_SUCCESS:
      return {
        ...state,
        roundTime: payload,
        gettingRoundTime: false,
        gettingRoundTimeError: ""
      };

    case GET_SPIN_ROUND_TIME_ERROR:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        gettingLastRoundTimestampError: payload
      };

    case GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: true,
        gettingBenchmarkMaxBetSizeError: ""
      };

    case GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS:
      return {
        ...state,
        benchmarkMaxBetSize: payload,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeError: ""
      };

    case GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeError: payload
      };

    case GET_SPIN_STANDARD_LOT_REQUEST:
      return {
        ...state,
        gettingStandardLot: true,
        gettingStandardLotErr: ""
      };

    case GET_SPIN_STANDARD_LOT_SUCCESS:
      return {
        ...state,
        gettingStandardLot: false,
        gettingStandardLotErr: "",
        standardLot: payload
      };

    case GET_SPIN_STANDARD_LOT_ERROR:
      return {
        ...state,
        gettingStandardLot: false,
        gettingStandardLotErr: payload
      };

    case GET_GBM_REQUEST: return {
      ...state,
      gettingGBM: true,
      gettingGBMErr: "",
    };

    case GET_GBM_SUCCESS: return {
      ...state,
      GBM: payload,
      gettingGBM: false,
      gettingGBMErr: "",
    };

    case GET_GBM_ERROR: return {
      ...state,
      gettingGBM: false,
      gettingGBMErr: payload,
    };

    default:
      return state;
  }
};
