import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import __html from "../../../../config/tos.html";
const template = { __html: __html };
import { toggleModal } from "../../../../actions/modal";
import { sendVerificationStepOne } from "../../../../actions/account";
import { postDismissedVerification } from "../../../../services/api";
import { validateEmail } from "../../../../services/utils";

import "./VerifySteps.scss";


function validateDate(_string) {
  const date = _string.split('-');
  let year = Number(date[0]) + 18;
  let month = Number(date[1]) - 1;
  let day = Number(date[2]);
  return new Date(year, month, day) <= new Date();
}

function validateAffiliateCode(_string) {
  return true;
}

class VerifyStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      date: "",
      usesAffiliateCode: "",
      acceptTerms: false,
      error: ""
    };

    this.handleFirstNameInput = this.handleFirstNameInput.bind(this);
    this.handleLastNameInput = this.handleLastNameInput.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleDateInput = this.handleDateInput.bind(this);
    this.handleAffiliateInput = this.handleAffiliateInput.bind(this);
    this.handleAcceptInput = this.handleAcceptInput.bind(this);
  }


  handleFirstNameInput = e => {
    const { value } = e.target;
    this.setState({ firstName: value });
    if (value === "") {
      this.setState({ error: "You must enter first name." });
    } else {
      this.setState({ error: "" });
    }
  };

  handleLastNameInput = e => {
    const { value } = e.target;
    this.setState({ lastName: value });
    if (value === "") {
      this.setState({ error: "You must enter last name." });
    } else {
      this.setState({ error: "" });
    }
  };

  handleEmailInput = e => {
    const { value } = e.target;
    this.setState({ email: value });
    if (!validateEmail(value)) {
      this.setState({ error: "Your email address is incorrect." });
    } else {
      this.setState({ error: "" });
    }
  };

  handleDateInput = e => {
    const { value } = e.target;
    this.setState({ date: value });
    if (!validateDate(value)) {
      this.setState({ error: "You must be older than 18 years." });
    } else {
      this.setState({ error: "" });
    }
  };

  handleAffiliateInput = e => {
    const { value } = e.target;
    this.setState({ usesAffiliateCode: value });
    if (!validateAffiliateCode(value)) {
      this.setState({ error: "Wrong affiliate code." });
    } else {
      this.setState({ error: "" });
    }
  };

  handleAcceptInput = e => {
    const { checked } = e.target;
    this.setState({ acceptTerms: checked });
  };

  render() {
    const {
      canSkip,
      toggleModal,
      sendVerificationStepOne,
      sendingVerificationStepOne,
      sendingVerificationStepOneErr,
      account,
    } = this.props;

    const disableSubmit =
      this.state.firstName === ""
      || this.state.lastName === ""
      || this.state.email === ""
      || !validateEmail(this.state.email)
      || this.state.date === ""
      || !validateDate(this.state.date)
      || this.state.acceptTerms === false;

    return (
      <div className="modal__content modal__content--verify">
        <p className="title">Please start your verification</p>
        <div className="input-group">
          <input
            className="input"
            type="text"
            placeholder="First name"
            onBlur={this.handleFirstNameInput}
          />

          <input
            className="input"
            type="text"
            placeholder="Last name"
            onBlur={this.handleLastNameInput}
          />
        </div>
        <div className="input-group">
          <input
            className="input"
            type="text"
            placeholder="Email address"
            onBlur={this.handleEmailInput}
          />

          <input
            className="input"
            type="date"
            placeholder="Birth date"
            onChange={this.handleDateInput}
          />
        </div>

        {/*<div className="input-group">*/}
        {/*  <input*/}
        {/*    className="input"*/}
        {/*    type="text"*/}
        {/*    placeholder="Affiliate code"*/}
        {/*    onBlur={this.handleAffiliateInput}*/}
        {/*  />*/}
        {/*</div>*/}

        <PerfectScrollbar option={{minScrollbarLength: 40}}>
          <div className="description" dangerouslySetInnerHTML={template} />
        </PerfectScrollbar>

        <div className="checkbox__wrapper">
          <input
            className="checkbox"
            id="accept-declaration"
            type="checkbox"
            onChange={this.handleAcceptInput}/>
          <label className="label" htmlFor="accept-declaration">Accept</label>
        </div>

        {this.state.error !== "" && <p className="input__error">{this.state.error}</p>}
        {sendingVerificationStepOneErr !== "" && <p className="input__error">{sendingVerificationStepOneErr}</p>}

        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => sendVerificationStepOne(this.state)}
            disabled={disableSubmit || sendingVerificationStepOne}
          >
            {sendingVerificationStepOne ? 'Submitting' : 'Continue' }
          </button>
          {canSkip && (
            <button
              className="btn btn--dark"
              onClick={async () => {
                toggleModal();
                await postDismissedVerification(account, 1);
              }}
            >
              Dismiss for 24h
            </button>
          )}
        </div>
      </div>
    );
  }
}

VerifyStepOne.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ account }) => ({
  sendingVerificationStepOne: account.sendingVerificationStepOne,
  sendingVerificationStepOneErr: account.sendingVerificationStepOneErr,
  account: account.account,
});

const mapDispatchToProps = {
  toggleModal,
  sendVerificationStepOne,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyStepOne);
