export const BURNING_TOKEN = "BURNING_TOKEN";
export const BURNING_TOKEN_SUCCESS = "BURNING_TOKEN_SUCCESS";
export const BURNING_TOKEN_FAILURE = "BURNING_TOKEN_FAILURE";

export const CLAIMING_TOKEN = "CLAIMING_TOKEN";
export const CLAIMING_TOKEN_SUCCESS = "CLAIMING_TOKEN_SUCCESS";
export const CLAIMING_TOKEN_FAILURE = "CLAIMING_TOKEN_FAILURE";

export const ADD_WITHDRAWAL = "ADD_WITHDRAWAL";
export const ADD_WITHDRAWAL_SUCCESS = "ADD_WITHDRAWAL_SUCCESS";
export const ADD_WITHDRAWAL_FAILURE = "ADD_WITHDRAWAL_FAILURE";

export const UPDATE_WITHDRAWAL = "UPDATE_WITHDRAWAL";
export const UPDATE_WITHDRAWAL_SUCCESS = "UPDATE_WITHDRAWAL_SUCCESS";
export const UPDATE_WITHDRAWAL_FAILURE = "UPDATE_WITHDRAWAL_FAILURE";






