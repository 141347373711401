import {
  GET_BAD_TOKEN_BALANCE_REQUEST,
  GET_BAD_TOKEN_BALANCE_SUCCESS,
  GET_BAD_TOKEN_BALANCE_ERROR,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR,
  GET_STAKED_BAD_TOKEN_BALANCE_REQUEST,
  GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS,
  GET_STAKED_BAD_TOKEN_BALANCE_ERROR,
  STAKE_TOKENS_REQUEST,
  STAKE_TOKENS_SUCCESS,
  STAKE_TOKENS_ERROR,
  WITHDRAW_TOKENS_REQUEST,
  WITHDRAW_TOKENS_SUCCESS,
  WITHDRAW_TOKENS_ERROR,
  ADD_BAD_TOKEN_WIN_NOTIF,
  GET_BAD_TOKEN_REVENUE_SHARE_REQUEST,
  GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS,
  GET_BAD_TOKEN_REVENUE_SHARE_ERROR,
  GET_BAD_TOKEN_WIN_PROB_REQUEST,
  GET_BAD_TOKEN_WIN_PROB_SUCCESS,
  GET_BAD_TOKEN_WIN_PROB_ERROR,
  GET_TOKEN_REVENUE_HISTORY_REQUEST,
  GET_TOKEN_REVENUE_HISTORY_SUCCESS,
  GET_TOKEN_REVENUE_HISTORY_ERROR,
  GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST,
  GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS,
  GET_EXTRA_TOKEN_WIN_CHANCE_ERROR,
  GET_PLAYERS_LEVEL_REQUEST,
  GET_PLAYERS_LEVEL_SUCCESS,
  GET_PLAYERS_LEVEL_ERROR,
  GET_TOKEN_STATS_REQUEST,
  GET_TOKEN_STATS_SUCCESS,
  GET_TOKEN_STATS_ERROR,
  POST_USER_TITLE_REQUEST,
  POST_USER_TITLE_SUCCESS,
  POST_USER_TITLE_ERROR,
  DEPOSIT_BAD_TOKEN_REQUEST,
  DEPOSIT_BAD_TOKEN_SUCCESS,
  DEPOSIT_BAD_TOKEN_ERROR,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR,
  GET_BAD_TOKEN_WIN_PROB_DON_REQUEST,
  GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS,
  GET_BAD_TOKEN_WIN_PROB_DON_ERROR,
} from "../actionTypes/badToken";
import {
  getUserStakedTokensFromContract,
  getUsersBadTokenBalanceFromContract,
  getUsersEthereumBadTokenBalanceFromContract,
  stakeTokensFromContract,
  withdrawStakedTokensFromContract,
  listenToTokensStakedFromContract,
  listenToTokensUnstakedFromContract,
  calculateBadTokenWinningProbabilityFromContract,
  stakedTokensPeriodEndFromContract,
  nextCycleBlockFromContract,
  getRevenueDistributedFromContract,
  getUsersOngoingRevenueFromContract,
  getTotalRevenueShareFromContract,
  getExtraTokenWinChanceForPlayerFromContract,
  getPlayerLevelFromContract,
  getCurrentStageFromContract,
  getTotalSupplyOfTokens,
  getMaxPercentForStage,
  getDistributedTokensFromContract,
  shouldWinTokensFromContract,
  approveEthGatewayToTakeTokens,
  depositTokenToEthGateway,
  transferTokenToTokenHolder,
  listenToTransferBadToken,
  getStakedTokensFromContract,
} from '../services/ethereum/badToken';
import { toggleModal } from "./modal";
import { sendTx, sendTxETH } from "./txNotifications";
import BigNumber from "bignumber.js";
import { getTokensWonParametersFromSpinContract } from "../services/ethereum/spin";
import { getTokensWonParametersFromRollContract } from "../services/ethereum/roll";
import { listenForNewBlock, removeListenForNewBlock, weiToEth } from "../services/ethereum/main";
import {
  amountRequiredForLevelFromContract,
  totalWinningsForPlayerFromContract
} from "../services/ethereum/games";
import {
  getUserTitleFromApi,
  postBadMoveEthereumToMatic,
  postUserTitleFromApi
} from "../services/api";
import {
  BadBitDistributorContract,
  rollGameAddress,
  rouletteGameAddress,
  scratchGameAddress,
  spinGameAddress,
} from '../services/contract';
import {
  lastBetForPlayerFromRouletteContract
} from "../services/ethereum/roulette";

export const getRevenueShare = () => async (dispatch, getState) => {
  dispatch({ type: GET_BAD_TOKEN_REVENUE_SHARE_REQUEST });
  try {
    const { account } = getState().account;
    const current = await getUsersOngoingRevenueFromContract(account);
    const total = await getTotalRevenueShareFromContract(account);

    await areTokensDistributed();

    dispatch({
      type: GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS,
      payload: { current, total }
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_BAD_TOKEN_REVENUE_SHARE_ERROR, payload: e.message });
  }
};

const getRevenueForCurrentCycle = async (from) => {
  const distributorContract = await BadBitDistributorContract();
  const ongoingRevenueData = await distributorContract.methods.getOngoingRevenue().call({from});

  const ongoingRevenue = ongoingRevenueData[1] ? weiToEth(ongoingRevenueData[0]) : weiToEth(ongoingRevenueData[0]) * -1;

  return ongoingRevenue.toString();
};

export const areTokensDistributed = async (ethereumAddress) => {
  try {
    const totalSupply = await getTotalSupplyOfTokens(ethereumAddress);
    const distributedTokens = await getDistributedTokensFromContract(ethereumAddress);
    return new BigNumber(totalSupply).isEqualTo(distributedTokens);
  } catch (error) {
    console.log(error);
  }
};

export const getTokenStats = () => async (dispatch, getState) => {
  dispatch({ type: GET_TOKEN_STATS_REQUEST });
  try {
    const { account } = getState().account;
    const totalSupply = await getTotalSupplyOfTokens(account);

    const currentStage = await getCurrentStageFromContract(account);


    const maxPercentForLastStage = await getMaxPercentForStage(
      currentStage - 1,
      account
    );
    const maxPercentForNextStage = await getMaxPercentForStage(currentStage, account);
    const distributedTokens = await getDistributedTokensFromContract(account);

    const stakedTokens = await getStakedTokensFromContract()

    const distributedTokensInCurrStage = new BigNumber(weiToEth(distributedTokens))
      .minus(
        new BigNumber(weiToEth(totalSupply))
          .times(maxPercentForLastStage / 100)
          .toString()
      )
      .toString();

    const notDistributedTokensInCurrStage = new BigNumber(weiToEth(totalSupply))
      .times(maxPercentForNextStage / 100)
      .minus(weiToEth(distributedTokens))
      .toString();

    const tokensInCurrStageTotal = new BigNumber(distributedTokensInCurrStage)
      .plus(notDistributedTokensInCurrStage)
      .toString();

    const percentOfDistInCurrStage = new BigNumber(distributedTokensInCurrStage)
      .dividedBy(tokensInCurrStageTotal)
      .times(100)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toString();

    const revenueForCurrentDistCycle = await getRevenueForCurrentCycle(account);

    const nextCycleBlocksLeft = await nextCycleBlockFromContract(account);

    const payload = {
      currentStage,
      nextCycleBlocksLeft,
      distributedTokensInCurrStage,
      notDistributedTokensInCurrStage,
      revenueForCurrentDistCycle,
      percentOfDistInCurrStage,
      maxPercentForNextStage,
      stakedTokens,
      distributedTokens: weiToEth(distributedTokens),
      distributedTokensPercent: new BigNumber(weiToEth(distributedTokens))
        .dividedBy(weiToEth(totalSupply))
        .times(100)
        .toString()
    };
    dispatch({ type: GET_TOKEN_STATS_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_TOKEN_STATS_ERROR, payload: e.message });
  }
};

export const getPlayerLevel = () => async (dispatch, getState) => {
  dispatch({ type: GET_PLAYERS_LEVEL_REQUEST });
  try {
    const { account } = getState().account;

    const level = await getPlayerLevelFromContract(account);
    const amountRequiredForNextLevel =
      Number(level) < 100
        ? await amountRequiredForLevelFromContract(Number(level) + 1, account)
        : "0";

    const totalWinningsAmount = await totalWinningsForPlayerFromContract(account);

    const levelAmount = Number(level) < 100
      ? Number(level) > 0
        ? new BigNumber(await amountRequiredForLevelFromContract(Number(level) + 1, account)).minus(
            await amountRequiredForLevelFromContract(Number(level), account)
          ).toString()
        : await amountRequiredForLevelFromContract(Number(level) + 1, account)
      : "0";

    const amountLeftToCompleteLevel =
      Number(level) < 100
        ? new BigNumber(amountRequiredForNextLevel)
          .minus(totalWinningsAmount)
          .toString()
        : "0";

    const amountAcquiredInThisLevel =
      Number(level) < 100
        ? new BigNumber(levelAmount)
            .minus(amountLeftToCompleteLevel)
            .toString()
        : "0";

    const levelPercentCompleted =
      Number(level) < 100
        ? new BigNumber(amountAcquiredInThisLevel)
            .dividedBy(levelAmount)
            .times(100)
            .integerValue(BigNumber.ROUND_FLOOR)
            .toString()
        : "0";

    const titleNumber = await getUserTitleFromApi(account);

    dispatch({
      type: GET_PLAYERS_LEVEL_SUCCESS,
      payload: {
        level,
        amountLeftToCompleteLevel,
        amountAcquiredInThisLevel,
        levelPercentCompleted,
        titleNumber
      }
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_PLAYERS_LEVEL_ERROR, payload: e.message });
  }
};

export const postUserTitle = number => async (dispatch, getState) => {
  try {
    dispatch({ type: POST_USER_TITLE_REQUEST });
    const { account } = getState().account;
    const response = await postUserTitleFromApi(number, account);
    if (response.status === 200) {
      dispatch({ type: POST_USER_TITLE_SUCCESS, payload: number });
    } else {
      dispatch({ type: POST_USER_TITLE_ERROR, payload: response });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: POST_USER_TITLE_ERROR, payload: e.message });
  }
};

export const getBadTokenWinningProbabilityForDon = () => async (dispatch, getState) => {
  dispatch({ type: GET_BAD_TOKEN_WIN_PROB_DON_REQUEST });
  try {
    const state = getState();
    const { activeGame } = state.games;
    const { minBetSize, GBS, GWP, GCL } = state.gamesConstants;
    const _minBetSize = weiToEth(minBetSize);
    const K = 1;
    const _chances = [0.5];

    let _betSize;

    if (activeGame === "roll") {
      const { lastWinPrize } = getState().gameRoll;
      _betSize = weiToEth(lastWinPrize);
    }
    if (activeGame === "spin") {
      const { lastWinPrize } = getState().gameSpin;
      _betSize = weiToEth(lastWinPrize);
    }
    if (activeGame === "scratch") {
      const { winPrize: scratchPrize } = getState().gameScratch;
      _betSize = weiToEth(scratchPrize);
    }
    if (activeGame === "roulette") {
      const { lastWinPrize } = getState().gameRoulette;
      _betSize = weiToEth(lastWinPrize);
    }

    let payload = await calculateBadTokenWinningProbabilityFromContract(
      _betSize, _minBetSize, GBS, GCL, GWP, K, _chances
    );

    await dispatch(getExtraTokenWinChanceForPlayer());

    dispatch({
      type: GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS,
      payload: payload.toString()
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_BAD_TOKEN_WIN_PROB_DON_ERROR, payload: e.message });
  }
};


export const getBadTokenWinningProbability = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_BAD_TOKEN_WIN_PROB_REQUEST });
  try {
    const state = getState();
    const { activeGame } = state.games;
    const { minBetSize, GBS, GWP, GCL } = state.gamesConstants;
    let _chances, _minBetSize, _betSize, K;
    _minBetSize = weiToEth(minBetSize);

    if (activeGame === "spin") {
      const { winChance, betAmount } = state.gameSpin;
      _chances = [winChance / 10000];
      _betSize = weiToEth(betAmount);
      K = 1;
    }

    if (activeGame === "roll") {
      const { winChance, betAmount } = state.gameRoll;
      _chances = [winChance / 100];
      _betSize = weiToEth(betAmount);
      K = 1;
    }

    if (activeGame === "scratch") {
      const { winChance, numberOfCards, costPerCard } = state.gameScratch;
      _chances = [winChance / 1000];
      _betSize = weiToEth(costPerCard);
      K = numberOfCards;
    }

    if (activeGame === "roulette") {
      const { bets, totalBetAmount } = state.gameRoulette;
      _chances = bets.map(({ betData }) => betData.winChance / 10000);
      _betSize = weiToEth(totalBetAmount);
      K = bets.length;
    }

    let payload = await calculateBadTokenWinningProbabilityFromContract(
        _betSize, _minBetSize, GBS, GCL, GWP, K, _chances
      );

    await dispatch(getExtraTokenWinChanceForPlayer());

    dispatch({
      type: GET_BAD_TOKEN_WIN_PROB_SUCCESS,
      payload: payload.toString()
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_BAD_TOKEN_WIN_PROB_ERROR, payload: e.message });
  }
};

export const getExtraTokenWinChanceForPlayer = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getExtraTokenWinChanceForPlayerFromContract(account, account);
    dispatch({ type: GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_EXTRA_TOKEN_WIN_CHANCE_ERROR, payload: e.message });
  }
};

/**
 * Gets balance of bad token for the current account
 *
 * @return {Function}
 */
export const getUsersBadTokenBalance = () => async (dispatch, getState) => {
  dispatch({ type: GET_BAD_TOKEN_BALANCE_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getUsersBadTokenBalanceFromContract(account, account);
    dispatch({ type: GET_BAD_TOKEN_BALANCE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_BAD_TOKEN_BALANCE_ERROR, payload: e.message });
  }
};

/**
 * Gets balance of bad token for the current account
 *
 * @return {Function}
 */
export const getUsersEthereumBadTokenBalance = () => async (dispatch, getState) => {
  dispatch({ type: GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await getUsersEthereumBadTokenBalanceFromContract(account);
    dispatch({ type: GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR, payload: e.message });
  }
};


export const getBlockLeftToStakedTokenPeriodEnd = () => async (dispatch, getState) => {
  dispatch({ type: GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await stakedTokensPeriodEndFromContract(
      account,
      account
    );
    dispatch({ type: GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS, payload })
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR, payload: error.message })
  }
};


export const listenForNewBlockAction = () => (dispatch) => {
  try {
    listenForNewBlock(getBlockLeftToStakedTokenPeriodEnd, dispatch)
  } catch (error) {
    console.log(error);
  }
};

export const removeListenForNewBlockAction = () => () => {
  try {
    removeListenForNewBlock();
  } catch (error) {
    console.log(error);
  }
};

/**
 * Gets balance of staked bad token for the current account
 *
 * @return {Function}
 */
export const getUsersStakedBadTokenBalance = () => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_STAKED_BAD_TOKEN_BALANCE_REQUEST });
  try {
    const { account } = getState().account;
    const stakedBadTokenBalance = await getUserStakedTokensFromContract(account);
    dispatch(getBlockLeftToStakedTokenPeriodEnd());
    const blocksLeftToNextCycle = await nextCycleBlockFromContract(account);

    dispatch({
      type: GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS,
      payload: {
        stakedBadTokenBalance,
        blocksLeftToNextCycle
      }
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_STAKED_BAD_TOKEN_BALANCE_ERROR, payload: e.message });
  }
};

export const getRevenueDistributedHistory = (page = 1, perPage = 5) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_TOKEN_REVENUE_HISTORY_REQUEST });
  try {
    const { account } = getState().account;

    const revenueData = await getRevenueDistributedFromContract(account, null, account);

    dispatch({
      type: GET_TOKEN_REVENUE_HISTORY_SUCCESS,
      payload: { data: revenueData.reverse() }
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_TOKEN_REVENUE_HISTORY_ERROR, payload: e.message });
  }
};

/**
 * Stakes a given amount of Bad tokens
 *
 * @param amount
 * @return {Function}
 */
export const stakeTokens = amount => async (dispatch, getState) => {
  dispatch({ type: STAKE_TOKENS_REQUEST });
  try {
    const { account } = getState().account;

    const { modalOpen } = getState().modal;
    if (modalOpen) dispatch(toggleModal());

    await stakeTokensFromContract(amount, account, sendTx, dispatch, getState, account);
    dispatch({ type: STAKE_TOKENS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: STAKE_TOKENS_ERROR, payload: e.message });
  }
};

/**
 * Withdraws a given amount of Bad tokens
 *
 * @param amount
 * @return {Function}
 */
export const withdrawTokens = amount => async (dispatch, getState) => {
  dispatch({ type: WITHDRAW_TOKENS_REQUEST });
  try {
    const { account } = getState().account;

    const { modalOpen } = getState().modal;
    if (modalOpen) dispatch(toggleModal());
    await withdrawStakedTokensFromContract(
      amount,
      account,
      sendTx,
      dispatch,
      getState,
      account
    );
    dispatch({ type: WITHDRAW_TOKENS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: WITHDRAW_TOKENS_ERROR, payload: e.message });
  }
};

export const addBadTokenWinNotification = (payload) => (
  dispatch
) => {
  dispatch({ type: ADD_BAD_TOKEN_WIN_NOTIF, payload });
};

export const calculateNumberOfTokensWonForRoulette = async (
  address,
  betSizes,
  chances
) => {
  const { resultHash } = await lastBetForPlayerFromRouletteContract(address);
  return await shouldWinTokensFromContract(
    resultHash,
    address,
    betSizes,
    chances,
  );
};

export const calculateNumberOfTokensWon = async (address, game = "roll") => {
  let params;
  if (game === "roll") {
    params = await getTokensWonParametersFromRollContract(address);
  }
  if (game === "spin") {
    params = await getTokensWonParametersFromSpinContract(address);
  }
  const { 0: randomHash, 1: amount, 2: chance } = params;
  return await shouldWinTokensFromContract(
    randomHash,
    address,
    [amount],
    [chance],
  );
};

/**
 * Makes a Event listener to check for TokensStaked event
 *
 * @return {Function}
 */
export const listenToTokensStaked = () => (dispatch, getState) => {
  const callback = async () => {
    dispatch(getUsersBadTokenBalance());
    dispatch(getUsersStakedBadTokenBalance());
    dispatch(getRevenueShare());
  };

  const { account } = getState().account;
  listenToTokensStakedFromContract(callback, account);
};

/**
 * Makes a Event listener to check for TokensStaked event
 *
 * @return {Function}
 */
export const listenToTokensUnstaked = () => (dispatch, getState) => {
  const callback = async () => {
    dispatch(getUsersBadTokenBalance());
    dispatch(getUsersStakedBadTokenBalance());
    dispatch(getRevenueShare());
  };

  const { account } = getState().account;
  listenToTokensUnstakedFromContract(callback, account);
};

export const listenToTokenTransferred = () => (dispatch, getState) => {
  const callback = async ({ to, value }) => {
    const { account } = getState().account;
    if (to.toUpperCase() !== account.toUpperCase()) return;
    dispatch(getUsersBadTokenBalance());
  };
  listenToTransferBadToken(callback);
};

export const initBadTokenListeners = () => dispatch => {
  dispatch(listenToTokensStaked());
  dispatch(listenToTokensUnstaked());
  dispatch(listenToTokenTransferred());
  dispatch(getRevenueShare());
};

/**
 * Deposit given amount of BAD TOKEN to Matic Chain
 *
 * @param amount
 */
export const depositTokenToMatic = amount => {
  return async (dispatch, getState) => {
    dispatch({ type: DEPOSIT_BAD_TOKEN_REQUEST });
    try {
      const { account } = getState().account;
      const { badTokenBalance } = getState().badToken;
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());

      await approveEthGatewayToTakeTokens(amount, account, sendTxETH, dispatch, getState);

      await depositTokenToEthGateway(
        account,
        amount,
        sendTxETH,
        dispatch,
        getState
      );

      await postBadMoveEthereumToMatic(amount);
      dispatch({ type: DEPOSIT_BAD_TOKEN_SUCCESS });

      const depositInterval = setInterval(async () => {
        const newBalance = await getUsersBadTokenBalanceFromContract(account, account);
        if (newBalance !== badTokenBalance) {
          dispatch({ type: GET_BAD_TOKEN_BALANCE_SUCCESS, payload: newBalance });
          const ethBad = await getUsersEthereumBadTokenBalanceFromContract(account);
          dispatch({ type: GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS, payload: ethBad });
          clearInterval(depositInterval);
        }
      }, 10000);
    } catch (e) {
      console.log(e);
      dispatch({ type: DEPOSIT_BAD_TOKEN_ERROR, payload: e.message });
    }
  };
};

export const transferTokens = amount => {
  return async (dispatch, getState) => {
    try {
      const { account } = getState().account;
      await transferTokenToTokenHolder(amount, "0x51a59642505fb943c0ecd9631e1d6e06a593ed58", account)
    } catch (e) {
      console.log(e);
    }
  };
};



