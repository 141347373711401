import {
  WETHEventsContract,
  ethersProvider,
  POSRootChainManagerContract,
  RootChainManagerHelperContract,
  WethContract,
  BadTokenContract,
  BadTokenEventsContract,
  web3Matic,
  badBitGameContractDeployTransactionHash,
  badTokenTxHash,
  MaticDepositContract
} from './contract';
import { provider, maticNetwork, env } from "../config/client.js";
import { FROM_BLOCK } from "../config/constants.js";
import Proofs from 'matic-protocol/contracts-core/helpers/proofs.js'
import ethUtils from 'ethereumjs-util';
const MaticPOSClient = require("@maticnetwork/maticjs").MaticPOSClient;
import networkConfigs from "../config/network-configs.json";
const { soliditySha3 } = require("web3-utils");
import { changeModalType, toggleModal } from '../actions/modal';

import { giveInfiniteApproval, getApproval } from './ethereum/main';
import { getEthWalletBalance, getWalletBalance } from '../actions/account';

let maticNetworkConfig = networkConfigs.networks[maticNetwork];

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const maticPOSClient = new MaticPOSClient({
  network: env && env =="prod" ? "mainnet" : 'testnet',
  version: env && env =="prod" ? "v1" : 'mumbai',
  maticProvider: provider,
  parentProvider: window._web3
});

export const formatTransferEvent = ( log ) => {
  const burnEvent = WETHEventsContract.interface.events["Transfer"];
  const decodedLog = burnEvent.decode(log.data, log.topics)
  return {
    user: decodedLog['from'],
    amount: decodedLog['value'],
    transactionHash: log.transactionHash,
    blockNumber: log.blockNumber
  }
}

export const formatBurnEventBadToken = ( log ) => {
  const burnEvent = BadTokenEventsContract.interface.events["Burned"];
  const decodedLog = burnEvent.decode(log.data, log.topics)
  return {
    user: decodedLog['user'],
    amount: decodedLog['amount'],
    transactionHash: log.transactionHash,
    blockNumber: log.blockNumber
  }
}

var logEventSig = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

/**
 *
 * @param account
 * @return {Promise<object>}
 */
export const getBurnEventsFromWETHContract = account =>
  new Promise(async (resolve, reject) => {
    try {
        const filter = WETHEventsContract.filters.Transfer(account, ZERO_ADDRESS, null);
        filter.fromBlock = FROM_BLOCK;

        let logs = await ethersProvider.getLogs(filter);
        let decoded = logs.map(log => formatTransferEvent(log));

        resolve(decoded);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

  /**
 *
 * @param account
 * @return {Promise<object>}
 */
export const getBurnEventsFromBadTokenContract = account =>
new Promise(async (resolve, reject) => {
  try {
      const filter = BadTokenEventsContract.filters.Burned(account);
    const { blockNumber } = await web3Matic.eth.getTransaction(badTokenTxHash);
    filter.fromBlock = blockNumber;

      let logs = await ethersProvider.getLogs(filter);
      let decoded = logs.map(log => formatBurnEventBadToken(log));

      resolve(decoded);
  } catch (err) {
    console.log(err);
    reject(err);
  }
});

/**
 * A listener for WETH Burn event
 *
 * @param account
 * @param callback
 */
export const listenToWETHBurnEventFromContract = (account, callback) => {
    const filter = WETHEventsContract.filters.Transfer(account, "0x0000000000000000000000000000000000000000", null);
    WETHEventsContract.on(filter, async (user, to, amount, data) => {
      callback(user, amount, data.transactionHash, data.blockNumber);
    });
};

/**
 * A listener for BAD TOKEN Burn event
 *
 * @param account
 * @param callback
 */
export const listenToBadTokenBurnEventFromContract = (account, callback) => {
  const filter = BadTokenEventsContract.filters.Burned(account);

  BadTokenEventsContract.on(filter, async (user, amount, data) => {
    callback(user, amount, data.transactionHash, data.blockNumber);

  });
};

export const getBurnClaimHash = async (txHash) =>{
    const burnTx = await web3Matic.eth.getTransaction(txHash);
    const receipt = await web3Matic.eth.getTransactionReceipt(txHash);
    const r = Proofs.getReceiptBytes(receipt);
    let blockNumber = burnTx.blockNumber;
    blockNumber = "0x" + Number(blockNumber).toString(16);
    let logIndex = receipt.logs.findIndex(log => log.topics[0].toLowerCase() === logEventSig.toLowerCase());
    logIndex = "0x" + Number(logIndex).toString(16);

    if (logIndex === "0x0") {
      logIndex = "0x";
    }

    var returnValue = web3Matic.utils.keccak256(
      blockNumber.toString() + ethUtils.bufferToHex(r) + logIndex
    );

    return returnValue;
    /**return soliditySha3(
      blockNumber,
      ethUtils.bufferToHex(r),
      logIndex
    );*/
}

export const checkBurnClaim = async (hash) => {
  const contract = POSRootChainManagerContract();
  const rootChainManagerHelperContract = RootChainManagerHelperContract();
  const exitHash = await rootChainManagerHelperContract.methods.getExistHash(hash).call();
  return contract.methods.processedExits(exitHash).call();
}


export const burnWETH = async (
  amount,
  account,
  sendTxFunc,
  dispatch,
  getState,
  callback
) => new Promise(async (resolve, reject) => {
  try {
    const contract = WethContract();
    let functionSignature;
    functionSignature = contract.methods.withdraw(amount).encodeABI();

    const domainData = {
      name: "Wrapped Ether",
      version: "1",
      verifyingContract: contract._address
    };
    const txObject = {
      functionSignature,
      contract,
      account,
      domainData
    }

    const { status } = await sendTxFunc(
      txObject,
      {
        promiseTitle: "Burning ETH on Matic...",
        successTitle: "Burning ETH initiated, claim your tokens in Asset Movement History.",
        errorTitle: "Burning ETH on Matic denied."
      },
      dispatch,
      getState,
      false,
      callback
    );
  resolve(status);
  } catch (error) {
    console.log(error);
    reject(error);
  }
});


export const badbitETHWithdrawalService = async (
  amount,
  account,
  sendTxFunc,
  dispatch,
  getState,
  callback
) => new Promise(async (resolve, reject) => {
  try {
    const contract = MaticDepositContract();
    const approval = await getApproval(account, contract._address);
    if(Number(approval) < Number(amount)) {
      await giveInfiniteApproval(account, contract._address);
    }
    let functionSignature;
    functionSignature = contract.methods.withdraw(amount).encodeABI();

    const txObject = {
      functionSignature,
      contract,
      account
    }

    const { status } = await sendTxFunc(
      txObject,
      {
        promiseTitle: "Withdrawing ETH on Matic...",
        successTitle: "Withdrawal of ETH initiated.",
        errorTitle: "Withdrawal of ETH on Matic denied."
      },
      dispatch,
      getState,
      false,
      callback
    );
  resolve(status);
  } catch (error) {
    console.log(error);
    reject(error);
  }
});

export const burnBADToken = async (
  amount,
  account,
  sendTxFunc,
  dispatch,
  getState,
  callback
) => new Promise(async (resolve, reject) => {
  try {
    const contract = BadTokenContract();
    const functionSignature = contract.methods.withdraw(amount).encodeABI();

    const txObject = {
      functionSignature,
      contract,
      account
    }

    const { status } = await sendTxFunc(
      txObject,
      {
        promiseTitle: "Initializing Withdrawal...",
        successTitle: "Withdrawal being processed, please wait for the next Matic Network checkpoint (about 30 minutes) and claim your withdrawal from the Asset Movement History panel.",
        errorTitle: "Burning BAD Token on Matic denied."
      },
      dispatch,
      getState,
      false,
      callback
    );
  resolve(status);
  } catch (error) {
    console.log(error);
    reject(error);
  }
});

export const claimWithdrawalHash = async (
  hash,
  account,
  sendTxFunc,
  dispatch,
  getState,
  callback,
  finalCallback,
  token
) => new Promise(async (resolve, reject) => {
  try {
    dispatch(changeModalType(
      "ModalLoader",
      { modalText: "Generating Ethereum transaction, this might take some time..." },
      false
    ));
    const returnResult = await maticPOSClient.exitERC20(hash, { from: account, encodeAbi: true });
    dispatch(toggleModal());
    const txPromise =  window._web3.eth.sendTransaction(returnResult);
    const { status } = await sendTxFunc(
      txPromise,
      {
        promiseTitle: `Claiming ${token === "WETH" ? "ETH" : token} on main Ethereum network...`,
        successTitle: `Claiming ${token === "WETH" ? "ETH" : token} completed.`,
        errorTitle: `Claiming ${token === "WETH" ? "ETH" : token} denied.`
      },
      dispatch,
      getState,
      callback,
      finalCallback
    );
    dispatch(getEthWalletBalance());
    dispatch(getWalletBalance());
  resolve(status);
  } catch (error) {
    console.log(error);
    dispatch(toggleModal());
    reject(error);
  }
});
