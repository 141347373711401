import { TOGGLE_MODAL, CHANGE_MODAL_TYPE } from "../actionTypes/modal";
import { SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT } from "../actionTypes/games/doubleOrNothing";
import {
  placeRollBet,
  setRollShowControls,
  setRollShowDice, startRollAutoBet,
} from './games/roll';
import {
  placeSpinBet,
  setSpinShowControls,
  setSpinShowSpinner, startSpinAutoBet,
} from './games/spin';
import {addBadTokenWinNotification, getBadTokenWinningProbabilityForDon} from './badToken';
import {
  placeRouletteBet,
  setRouletteDisableControls,
  setRouletteShowControls, startRouletteAutoBet,
} from './games/roulette';
import {checkAffCodeUrlParam, getUserContractBalance} from './account';
import {
  placeScratchBet,
  setActiveCard,
  setCards,
  setScratchShowControls, startScratchAutoBet,
} from './games/scratch';
import { getScratchContractConstValues } from "./contractConstants/scratch";

/**
 * Dispatches action to toggle modal.
 *
 * @param modalComponent {String} - Name of the component to dynamically render
 * @param modalProps {Object} - Props to pass to the component
 * @param canCloseModal {Object}
 */
export const toggleModal = (
  modalComponent = "",
  modalProps = {},
  canCloseModal = true
) => (dispatch, getState) => {
  const { isFirstBet } = getState().account;
  const { firstBet, activeGame } = getState().games;
  const { modalComponent: currentModalComponent, modalOpen } = getState().modal;
  const { drawerRightOpen } = getState().drawer;

  if (!modalProps.isAutoBet && currentModalComponent === "Nickname" && firstBet && isFirstBet) {
    if (activeGame === "roll") dispatch(placeRollBet());
    if (activeGame === "spin") dispatch(placeSpinBet());
    if (activeGame === "roulette") dispatch(placeRouletteBet());
    if (activeGame === "scratch") dispatch(placeScratchBet());
  }

  if (modalProps.isAutoBet && currentModalComponent === "Nickname" && firstBet && isFirstBet) {
    if (activeGame === "roll") dispatch(startRollAutoBet(modalProps.numberOfRounds));
    if (activeGame === "spin") dispatch(startSpinAutoBet(modalProps.numberOfRounds));
    if (activeGame === "roulette") dispatch(startRouletteAutoBet(modalProps.numberOfRounds));
    if (activeGame === "scratch") dispatch(startScratchAutoBet(modalProps.numberOfRounds));
  }

  if (modalComponent === "WinLose" && window.innerWidth <= 425) {
    document.querySelector("html").classList.add("overflowHidden");
    document.querySelector("body").classList.add("overflowHidden");
  }

  if (modalComponent === "WinLose") {
    dispatch(getBadTokenWinningProbabilityForDon());
  }

  if (currentModalComponent === "WinLose" && modalOpen) {
    dispatch(getUserContractBalance());
    dispatch(addBadTokenWinNotification("0"));
    if (!drawerRightOpen && window.innerWidth <= 425) {
      document.querySelector("html").classList.remove("overflowHidden");
      document.querySelector("body").classList.remove("overflowHidden");
    }
    setTimeout(() => {
      if (activeGame === "roll") {
        const { isAutoBetting } = getState().gameRoll;
        if (!isAutoBetting) {
          dispatch(setRollShowControls(true));
          dispatch(setRollShowDice(false));
        }
      }
      if (activeGame === "spin") {
        const { isAutoBetting } = getState().gameSpin;
        if (!isAutoBetting) {
          dispatch(setSpinShowControls(true));
          dispatch(setSpinShowSpinner(false));
        }
      }
      if (activeGame === "roulette") {
        const { isAutoBetting } = getState().gameRoulette;
        if (!isAutoBetting) {
          dispatch(setRouletteShowControls(true));
          dispatch(setRouletteDisableControls(false));
        }
      }
      dispatch({
        type: SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT,
        payload: 0
      });
    }, 300);
    if (activeGame === "scratch") {
      const { isAutoBetting } = getState().gameScratch;
      if (!isAutoBetting) {
        dispatch(setScratchShowControls(true));
        dispatch(setActiveCard(0));
        dispatch(setCards([]));
        dispatch(getScratchContractConstValues());
      }
    }
  }

  dispatch({
    type: TOGGLE_MODAL,
    payload: { modalComponent, modalProps, canCloseModal }
  });

  if (currentModalComponent === "ToS") {
    dispatch(checkAffCodeUrlParam());
  }

  if (window.innerWidth <= 425 && !modalOpen && !drawerRightOpen) {
    document.querySelector("html").classList.add("overflowHidden");
    document.querySelector("body").classList.add("overflowHidden");
  }
  if (window.innerWidth <= 425 && modalOpen && !drawerRightOpen) {
    document.querySelector("html").classList.remove("overflowHidden");
    document.querySelector("body").classList.remove("overflowHidden");
  }
};

/**
 * Dispatches action to change modal type.
 *
 * @param modalComponent {String} - Name of the component to dynamically render
 * @param modalProps {Object} - Props to pass to the component
 * @param canCloseModal {Object}
 */
export const changeModalType = (
  modalComponent = "",
  modalProps = {},
  canCloseModal = true
) => dispatch => {
  dispatch({
    type: CHANGE_MODAL_TYPE,
    payload: { modalComponent, modalProps, canCloseModal }
  });
};
