export const GET_MAX_NUM_OF_CARDS_REQUEST = "GET_MAX_NUM_OF_CARDS_REQUEST";
export const GET_MAX_NUM_OF_CARDS_SUCCESS = "GET_MAX_NUM_OF_CARDS_SUCCESS";
export const GET_MAX_NUM_OF_CARDS_ERROR = "GET_MAX_NUM_OF_CARDS_ERROR";

export const GET_MAX_CARD_COST_REQUEST = "GET_MAX_CARD_COST_REQUEST";
export const GET_MAX_CARD_COST_SUCCESS = "GET_MAX_CARD_COST_SUCCESS";
export const GET_MAX_CARD_COST_ERROR = "GET_MAX_CARD_COST_ERROR";

export const GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST = "GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST";
export const GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS = "GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS";
export const GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR = "GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR";

export const GET_SCRATCH_WIN_MULTIPLIER_REQUEST = "GET_SCRATCH_WIN_MULTIPLIER_REQUEST";
export const GET_SCRATCH_WIN_MULTIPLIER_SUCCESS = "GET_SCRATCH_WIN_MULTIPLIER_SUCCESS";
export const GET_SCRATCH_WIN_MULTIPLIER_ERROR = "GET_SCRATCH_WIN_MULTIPLIER_ERROR";
