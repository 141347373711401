import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveGame } from "../../../actions/games/games";
import {
  getScratchInitialData, startScratchAutoBet, stopScratchAutoBet,
} from '../../../actions/games/scratch';

import Controls from "./Controls/Controls";
import WinType from "./WinType/WinType";
import GamePaused from "../Shared/GamePaused/GamePaused";
import UserDisabled from "../Shared/UserDisabled/UserDisabled";
import AutoBet from '../Shared/AutoBet/AutoBet';

import "./ScratchGame.scss";

class ScratchGame extends Component {
  componentDidMount() {
    this.props.setActiveGame("scratch");
    this.props.getScratchInitialData();
    document.title = "SCRATCH | BadBit.Games";
  }
  render() {
    const {
      showControls,
      gamesPaused,
      scratchPaused,
      userStatus,
      isAutoBetting,
      autoBettingRounds,
      autoBettingCurrentRound,
      startScratchAutoBet,
      stopScratchAutoBet,
      startingAutoBetting,
      stoppingAutoBetting,
      settingInitData,
      gettingScratchInitData,
    } = this.props;
    return (
      <Fragment>
        <main className={`main ${gamesPaused || scratchPaused || userStatus.status === "disabled" ? "center" : ""}`}>
          <div className={`container scratch ${!showControls ? "center" : ""}`}>
            {userStatus.status !== "disabled" &&  !gamesPaused && !scratchPaused && (
              <Fragment>
                {showControls && <WinType />}
                <Controls />
                <AutoBet
                  isLoading={settingInitData || gettingScratchInitData}
                  startingAutoBetting={startingAutoBetting}
                  stoppingAutoBetting={stoppingAutoBetting}
                  startAutoBet={startScratchAutoBet}
                  stopAutoBet={stopScratchAutoBet}
                  isAutoBetting={isAutoBetting}
                  autoBettingRounds={autoBettingRounds}
                  autoBettingCurrentRound={autoBettingCurrentRound}
                />
              </Fragment>
            )}
            {(gamesPaused || scratchPaused) && <GamePaused />}
            {!(gamesPaused || scratchPaused) && userStatus.status === "disabled" && <UserDisabled />}
          </div>
        </main>
      </Fragment>
    );
  }
}

ScratchGame.propTypes = {
  setActiveGame: PropTypes.func.isRequired,
  getScratchInitialData: PropTypes.func.isRequired,
  gettingRoundState: PropTypes.bool.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  gettingGameStateErr: PropTypes.string.isRequired,
  roundState: PropTypes.string.isRequired,
  gamesPaused: PropTypes.bool.isRequired,
  scratchPaused: PropTypes.bool.isRequired,
  showControls: PropTypes.bool.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  autoBettingRounds: PropTypes.number.isRequired,
  autoBettingCurrentRound: PropTypes.number.isRequired,
  startScratchAutoBet: PropTypes.func.isRequired,
  stopScratchAutoBet: PropTypes.func.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
  stoppingAutoBetting: PropTypes.bool.isRequired,
  settingInitData: PropTypes.bool.isRequired,
  gettingScratchInitData: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameScratch, games, account }) => ({
  roundState: gameScratch.roundState,
  showControls: gameScratch.showControls,
  gettingRoundState: gameScratch.gettingRoundState,
  gettingGameState: gameScratch.gettingGameState,
  gettingGameStateErr: gameScratch.gettingGameStateErr,
  scratchPaused: gameScratch.scratchPaused,
  gamesPaused: games.gamesPaused,
  userStatus: account.userStatus,
  isAutoBetting: gameScratch.isAutoBetting,
  autoBettingRounds: gameScratch.autoBettingRounds,
  autoBettingCurrentRound: gameScratch.autoBettingCurrentRound,
  startingAutoBetting: gameScratch.startingAutoBetting,
  stoppingAutoBetting: gameScratch.stoppingAutoBetting,
  settingInitData: games.settingInitData,
  gettingScratchInitData: gameScratch.gettingScratchInitData,
});

const mapDispatchToProps = {
  setActiveGame,
  getScratchInitialData,
  startScratchAutoBet,
  stopScratchAutoBet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScratchGame);
