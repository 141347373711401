import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar/lib/index";
import { toggleModal } from "../../../../actions/modal";

import Loader from "../../../Loader/Loader";

import "./Players.scss";
import RollPlayersActivity from "./Games/Roll/RollPlayersActivity";
import SpinPlayersActivity from "./Games/Spin/SpinPlayersActivity";

class Players extends Component {
  render() {
    const {
      gettingOtherPlayersActivity,
      gettingOtherPlayersActivityError,
      otherPlayersActivity,
      gettingState,
      gettingStateErr,
      state,
      activeGame
    } = this.props;
    const loading = gettingOtherPlayersActivity || gettingState;
    const error = gettingOtherPlayersActivityError || gettingStateErr;
    const finished = state === "finished";

    return (
      <section className="main__section main__section--players">
        <header className="main__section__header">
          <span className="main__section__heading">Round activity:</span>
        </header>
        <div className="main__section__body">
          {loading && <Loader />}

          {!loading && error && <p className="error">{error}</p>}
          {!loading && !error && (
            <Fragment>
              {finished && (
                <p>
                  You will see other players' activities here once the round
                  starts.
                </p>
              )}

              {!finished && (
                <Fragment>
                  {otherPlayersActivity.length === 0 && (
                    <p>There are no other players in this round.</p>
                  )}
                  {otherPlayersActivity.length > 0 && (
                    <PerfectScrollbar>
                      {activeGame === "roll" && <RollPlayersActivity />}
                      {activeGame === "spin" && <SpinPlayersActivity />}
                    </PerfectScrollbar>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </section>
    );
  }
}

Players.propTypes = {
  gettingOtherPlayersActivity: PropTypes.bool.isRequired,
  gettingOtherPlayersActivityError: PropTypes.string.isRequired,
  otherPlayersActivity: PropTypes.array.isRequired,
  gettingState: PropTypes.bool.isRequired,
  gettingStateErr: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  activeGame: PropTypes.string.isRequired
};

const mapStateToProps = ({ playersActivity, games }) => ({
  activeGame: games.activeGame,
  otherPlayersActivity: playersActivity.otherPlayersActivity,
  gettingOtherPlayersActivity: playersActivity.gettingOtherPlayersActivity,
  gettingOtherPlayersActivityError:
    playersActivity.gettingOtherPlayersActivityError
});

const mapDispatchToProps = {
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Players);
