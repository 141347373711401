import {
  BadBitGameContract,
  badBitGameContractDeployTransactionHash,
  BadBitGameEventsContract,
  BadBitSettingsContract,
  BadBitSettingsEventsContract, web3Matic,
  AutoBetContract
} from '../contract';
import { setNumberOfWithdrawals } from "../../actions/assetMovmentlHistory";
import { hexToNumber } from "./main";
import BigNumber from 'bignumber.js';

export const getHouseEdgeGlobalFromContract = async (from) => {
  const contract = await BadBitSettingsContract();
  const houseEdge = await contract.methods.HOUSE_EDGE().call({
    from
  });
  return Number(houseEdge);
};

export const getBetsAllowedFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return await contract.methods.BETS_ALLOWED().call();
};

export const getGWPFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return new BigNumber(await contract.methods.GWP().call()).dividedBy(1000).toString();
};

export const getGBSFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return new BigNumber(await contract.methods.GBS().call()).dividedBy(1000).toString();
};

export const getGCLFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return new BigNumber(await contract.methods.GCL().call()).dividedBy(1000).toString();
};

export const getMinBetFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return await contract.methods.MIN_BET().call();
};

export const listenToGamePausedFromContract = callback => {
  console.log("GAME PAUSED EVENT OUT");
  BadBitSettingsEventsContract.on("GamePaused", async bool => {
    console.log("GAME PAUSED EVENT IN");
    callback({ bool });
  });
};

/**
 * Gives number of withdrawals from contract balance for a given address
 *
 * @param address
 * @return {Promise<number>}
 */
export const getNumberOfUserWithdrawalsFromContract = async ( address, from ) => {
  const contract = await BadBitGameContract();
  const numberOfUserWithdrawals = await contract.methods
    .getNumberOfUserWithdrawals(address)
    .call({ from });

  return Number(numberOfUserWithdrawals);
};

export const moveMaticEthToContractFromContract = (amount, from, sendTxFunc, dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitGameContract();
      const functionSignature = contract.methods.topUp(amount).encodeABI();
      const txObject = {
        functionSignature,
        contract,
        account: from
      }
      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Moving ETH...",
          successTitle: "Deposit to Contract ETH completed.",
          errorTitle: "Moving ETH denied.",
          amount,
          notifyOf: "moving-eth",
        },
        dispatch,
        getState
      );
      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error)
    }
  });

/**
 * Gets the user withdrawal from the contract event
 *
 * @param contract
 * @param _eventParams
 * @return {Promise<any>}
 */
export const getUserWithdrawalTxHash = (contract, _eventParams) =>
  new Promise((resolve, reject) => {
    try {
      contract.getPastEvents("UserWithdrawal", _eventParams, (err, events) => {
        if (err) {
          reject(err);
        } else {
          resolve(events.length > 0 ? events[0].transactionHash : "");
        }
      });
    } catch (err) {
      reject(err);
    }
  });

/**
 * Gives details of a given withdraw id for a given address
 *
 * @param address
 * @param id
 * @param from
 * @return {Promise<Object>}
 */
export const userWithdrawalsFromContract = async (address, id, from) => {
  const contract = await BadBitGameContract();
  const userWithdrawalInfo = await contract.methods
    .userWithdrawals(address, id)
    .call({from});

  const { withdrawalTimestamp } = userWithdrawalInfo;
  const { blockNumber } = await web3Matic.eth.getTransaction(
    badBitGameContractDeployTransactionHash
  );

  const eventParams = {
    fromBlock: blockNumber,
    filter: { timestamp: withdrawalTimestamp }
  };

  const txHash = await getUserWithdrawalTxHash(contract, eventParams);

  return { ...userWithdrawalInfo, txHash };
};

/**
 * Gets users balance from contract
 *
 * @param {string} account
 * @return {Promise<number>}
 */
export const getUsersBalanceFromContract = async (account) => {
  const contract = await BadBitGameContract();
  return await contract.methods.getCurrentBalance(account).call();
};

/**
 * Gets users bonus balance from contract
 *
 * @param account
 * @return {Promise<string>}
 */
export const getUserBonusBalanceFromContract = async (account, from) => {
  const contract = await BadBitGameContract();
  return await contract.methods.userBonusBalances(account).call({
    from
  });
};

export const amountRequiredForLevelFromContract = async (level, from) => {
  const contract = await BadBitGameContract();
  return await contract.methods.amountRequiredForLevel(level).call({from});
};

export const totalWinningsForPlayerFromContract = async (account) => {
  const contract = await BadBitGameContract();
  return await contract.methods.totalWinningsForPlayer(account).call();
};

/**
 * Withdraws user funds from a contract balance
 *
 * @param sendTxFunc
 * @param address
 * @param amount
 * @param dispatch
 * @param getState
 * @return {Promise<any>}
 */
export const withdrawUsersFundsFromContract = (
  sendTxFunc,
  address,
  amount,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitGameContract();
      const functionSignature = contract.methods.withdraw(address, amount).encodeABI();
      let txObject = {
        functionSignature,
        contract,
        account: address
      };

      await sendTxFunc(
        txObject,
        {
          promiseTitle: "Withdrawing...",
          successTitle: "Withdrawal completed.",
          errorTitle: "Withdrawal denied.",
          amount,
          notifyOf: "withdraw"
        },
        dispatch,
        getState
      );

      dispatch(setNumberOfWithdrawals());

      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

/**
 * A listener for BigWin event
 *
 * @param callback
 */
export const listenToBigWinFromContract = callback => {
  console.log("BIG WIN EVENT OUT");
  BadBitGameEventsContract.on("BigWin", (user, game, amount) => {
    console.log("BIG WIN EVENT IN");
    callback({
      user,
      game,
      amount: hexToNumber(amount._hex)
    });
  });
};

/**
 * Gets max bet chance for bonus betting from contract settings
 *
 * @return {Promise<number>}
 */
export const getMaxBetChanceForBonusBettingFromContract = async () => {
  const contract = await BadBitSettingsContract();
  return Number(await contract.methods.MAX_CHANCE_FOR_BONUS_BETTING().call());
};

/**
 * Gets min & max win probability from contract
 *
 * @returns {Promise<{maxWinProb: number, minWinProb: number}>}
 */
export const getWinProbabilityFromContract = async (from) => {
  const contract = await BadBitSettingsContract();
  const minWinProb = (await contract.methods.MIN_WP().call({from})) / 100;
  const maxWinProb = (await contract.methods.MAX_WP().call({from})) / 100;
  return { minWinProb, maxWinProb };
};

/**
 *
 * @param gameAddress
 * @param numberOfBets
 * @param amount
 * @param payload
 * @param account
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<boolean>}
 */
export const startAutoBetFromContract =
  async (gameAddress, numberOfBets, amount, payload, account, sendTxFunc, dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        const contract = await AutoBetContract();
        const functionSignature =
          contract.methods.startAutoBet(gameAddress, numberOfBets, amount, payload).encodeABI();

        const txObject = {
          functionSignature,
          contract,
          account
        }
        const { status } = await sendTxFunc(
          txObject,
          {
            amount,
            promiseTitle: "Placing an auto-bet...",
            successTitle: "Auto-bet session started.",
            errorTitle: "Auto-bet denied.",
            notifyOf: "start-auto-bet",
          },
          dispatch,
          getState
        );

        resolve(status);
      } catch (error) {
        console.log(error);
        reject(error)
      }
    });

/**
 *
 * @param gameAddress
 * @param account
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<unknown>}
 */
export const stopAutoBetFromContract =
  async (gameAddress, account, sendTxFunc, dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        const contract = await AutoBetContract();
        const functionSignature =
          contract.methods.stopAutoBet(account, gameAddress).encodeABI();

        const txObject = {
          functionSignature,
          contract,
          account
        }
        const { status } = await sendTxFunc(
          txObject,
          {
            promiseTitle: "Stopping Auto-Bet...",
            successTitle: "Auto-Bet Stopped",
            errorTitle: "Auto-bet stopping denied.",
            notifyOf: "stop-auto-bet",
          },
          dispatch,
          getState
        );

        resolve(status);
      } catch (error) {
        console.log(error);
        reject(error)
      }
    });
