import Web3 from "web3";
import { provider } from "../config/client.js";

export const setupWeb3 = () => {
  if (window.web3 === undefined && window.ethereum === undefined) return (window._web3 = new Web3(provider));
  if (window.web3) {
    window._web3 = new Web3(window.web3.currentProvider);
  } else if (window.ethereum) {
    window._web3 = new Web3(window.ethereum);
  }
};
