import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { getPreviousRouletteRolls } from "../../../../../actions/betHistory";
import { isNumberRedOrBlue } from "../../../../../actions/contractConstants/roulette";

import ArrowRight from "../../../../Decorative/Icons/ArrowRight";
import ArrowLeft from "../../../../Decorative/Icons/ArrowLeft";

const RouletteHistoryItem = ({ round, number, won, betDetails, isThereData }) => (
  <li>
    <Tooltip
      position="top-start"
      arrow="true"
      animation="fade"
      theme="roll"
      distance={15}
      html={
        <div className="previous-roulette__tooltip">
          <div className="previous-roulette__tooltip__types">
            <span className="previous-roulette__tooltip__type result">
              {isThereData ? won ? "Win" : "Lose" : "No info"}
            </span>
            {!!betDetails &&
              betDetails.map((item, index) => (
                <span key={`${index}-${item}`} className="previous-roulette__tooltip__type">{item}</span>
              ))}
          </div>
          <span className="previous-roulette__tooltip__round">
            #{round} Betting round
          </span>
        </div>
      }
    >
      <div className={`previous-roulette__single ${isNumberRedOrBlue(number)}`}>
        {number}
      </div>
    </Tooltip>
  </li>
);

const RouletteHistory = ({ history, page, getPreviousRouletteRolls }) => {
  if (page === 0)
    return [
      ...history.slice(0, 8).map(({ round, number, won, betDetails, data }) => {
        return (
          <RouletteHistoryItem
            key={round}
            number={number}
            round={round}
            won={won}
            betDetails={betDetails}
            isThereData={!!data.length}
          />
        );
      }),
      <li key="next" onClick={() => getPreviousRouletteRolls(page + 1)}>
        <div className="previous-roulette__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    ];

  return [
    <li key="prev" onClick={() => getPreviousRouletteRolls(page - 1)}>
      <div className="previous-roulette__single pagination">
        <ArrowLeft color="#465e71" />
      </div>
    </li>,
    ...history.map(({ round, number, won, betDetails, data }) => {
      return (
        <RouletteHistoryItem
          key={round}
          number={number}
          round={round}
          won={won}
          betDetails={betDetails}
          isThereData={!!data.length}
        />
      );
    }),
    history.length === 7 && (
      <li key="next" onClick={() => getPreviousRouletteRolls(page + 1)}>
        <div className="previous-roulette__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    )
  ];
};

RouletteHistory.propTypes = {
  history: PropTypes.array.isRequired,
  getPreviousRouletteRolls: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getPreviousRouletteRolls
};

export default connect(
  null,
  mapDispatchToProps
)(RouletteHistory);
