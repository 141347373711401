import React from "react";
import { LEVEL_REWARDS, LEVEL_TITLES } from "../../../../config/constants";

const Level = ({ newLevel, level }) => {
  if (newLevel) {
    return (
      <div className="modal__content modal__content--level-win">
        <p className="title">Congratulations!</p>
        <p className="title--win">
          You have achieved <span>Level {level}!</span>
        </p>
        <div className="details">
          <p className="details__title">
            <span>You have acquired the title </span>
            <span>{LEVEL_TITLES[Number(level)].label}</span>
          </p>
          <p className="details__title">
            <span>Your reward for achieving this level is </span>
            <span>{LEVEL_REWARDS[Number(level) - 1]}</span>
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

Level.propTypes = {};

export default Level;
