import React from "react";

export default function MaticLogo() {
  return (
    <svg width="100" height="31" viewBox="0 0 664 205" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M386.4 61.3H404V153H387.6V90.1L360.6 135.2H352.3L325.3 89.9V153H309V61.3H326.4L356.3 111.4L386.4 61.3ZM604.6 144C597.6 136.9 593.7 127.2 594 117.2C593.7 107.1 597.6 97.4 604.6 90.4C611.7 83.2 621.3 79.3 631.3 79.5C644.4 79.2 656.6 86.3 662.9 98L649.8 105.7C646.4 98.6 639.1 94.1 631.2 94.5C625.3 94.3 619.6 96.7 615.4 100.9C611.3 105.2 609.1 111 609.2 116.9C609.1 122.9 611.3 128.7 615.4 133C619.6 137.2 625.3 139.5 631.2 139.4C639.1 139.6 646.4 135.3 650 128.2L663.2 135.8C656.8 147.5 644.6 154.7 631.4 154.5C621.4 154.7 611.8 151 604.6 144ZM564.9 153.1V81.3H580.2V153L564.9 153.1ZM565.6 67.8C563.7 66 562.7 63.5 562.7 60.9C562.7 58.3 563.7 55.8 565.6 54C567.4 52.1 569.9 51 572.5 51C575.1 51 577.6 52.1 579.4 54C583.2 57.9 583.2 64.1 579.3 68C575.6 71.7 569.4 71.7 565.6 67.8ZM551 96.2H533.4V130.6C533.4 139.7 539.3 139.6 551 139V153C527.6 155.9 518.2 149.4 518.2 130.6V96.2H505.1V81.3H518.2V61.3H533.5V81.3H551.1L551 96.2ZM454.3 140.1C460.3 140.2 466 137.9 470.3 133.7C474.6 129.3 476.9 123.3 476.7 117.2C476.8 111.1 474.5 105.2 470.3 100.9C461.4 92.1 447.2 92.1 438.3 100.9C434 105.2 431.7 111.1 431.9 117.2C431.7 123.4 434 129.3 438.3 133.7C442.5 137.9 448.1 140.2 454 140.1H454.3ZM476.4 81.3H491.7V153H476.4V142.7C470.7 150.7 461.4 155.3 451.7 154.9C442.2 155 433.2 151.1 426.8 144C420 136.8 416.3 127.1 416.5 117.2C416.2 107.2 419.9 97.6 426.7 90.4C433.1 83.3 442.2 79.4 451.7 79.6C461.5 79.1 470.8 83.6 476.5 91.6L476.4 81.3Z" fill="#2BBDF7"/>
      <path d="M102.032 85.1132L131.5 149.396L175.268 124.506H175.352V74.698L102.032 85.1132Z" fill="#2BBDF7"/>
      <path d="M231.881 37.3489L204.568 27.2417L175.352 24.8899V74.698V180.082L219.176 205L254.129 134.837L231.965 67.3346L231.881 37.3489Z" fill="#2891F9"/>
      <path d="M229.334 24.8899L219.176 49.808V204.608L263 179.69V24.8899H229.334Z" fill="#2BBDF7"/>
      <path d="M219.176 0L175.352 24.8901L219.176 49.8081L263 24.8901L219.176 0Z" fill="#2B6DEF"/>
      <path d="M131.5 99.5881L87.676 51.2078L0 24.8899V179.69L43.824 204.608L56.3891 118.935L87.676 124.506L131.5 149.396V99.5881Z" fill="#2891F9"/>
      <path d="M43.824 0L0 24.8901L131.5 99.5882L151.369 88.3331L175.352 74.6982L43.824 0Z" fill="#2B6DEF"/>
      <path d="M43.824 99.5884V204.608L87.676 179.69V124.506L43.824 99.5884Z" fill="#2BBDF7"/>
    </svg>

  );
}
