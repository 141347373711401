import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../../../actions/account";

import "./SignIn.scss";

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = { rememberChecked: false };

    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox = e => {
    const { checked } = e.target;
    this.setState({ rememberChecked: checked });
  };

  render () {
    const { register } = this.props;
    return (
      <div className="modal__content modal__content--sign-in">
        <p className="title">Please sign in to proceed: </p>
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => register(this.state.rememberChecked)}
          >
            Sign In
          </button>
        </div>
        <div className="checkbox__wrapper">
          <input
            className="checkbox"
            id="remember"
            type="checkbox"
            onChange={this.handleCheckbox}/>
          <label className="label" htmlFor="remember"><span>Remember me <span>(for 30 days)</span></span></label>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  register: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  register
};

const mapStateToProps = ({ account }) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
