import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import BigNumber from "bignumber.js";
import { moveMaticEthToContract } from "../../../../actions/games/games";
import { ethToWei, weiToEth } from "../../../../services/ethereum/main";

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import "./MoveMaticEthToContract.scss";

class MoveMaticEthToContract extends Component {
  constructor(props) {
    super(props);
    this.state = { amount: "", amountWei: "" };

    this.handleAmountInput = this.handleAmountInput.bind(this);
    this.withdrawMax = this.withdrawMax.bind(this);
  }

  handleAmountInput = e => {
    const { value } = e.target;
    this.setState({ amount: value, amountWei: ethToWei(value) });
  };

  withdrawMax = () => {
    const { walletBalance } = this.props;
    this.setState({
      amount: Number(weiToEth(walletBalance)),
      amountWei: walletBalance
    });
  };

  render() {
    const { moveMaticEthToContract, toggleModal, walletBalance } = this.props;

    const isDepositDisabled =
      this.state.amountWei === "" ||
      new BigNumber(this.state.amountWei).lte(0) ||
      new BigNumber(walletBalance).lt(this.state.amountWei);

    return (
      <div className="modal__content modal__content--withdraw">
        <p className="title">Please enter amount to deposit to contract:</p>
        <div className="input--withdraw__wrapper">
          <input
            className="input--withdraw"
            type="number"
            placeholder="Enter amount"
            step="0.001"
            min="0"
            max={walletBalance}
            value={this.state.amount}
            onChange={this.handleAmountInput}
          />
          <button className="btn btn--dark" onClick={this.withdrawMax}>
            <Tooltip
              theme="roll"
              title={`The maximum amount you can deposit is: ${Number(
                weiToEth(walletBalance)
              )} ETH`}
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>{" "}
            Max
          </button>
        </div>
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => moveMaticEthToContract(this.state.amountWei)}
            disabled={isDepositDisabled}
          >
            Deposit
          </button>
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

MoveMaticEthToContract.propTypes = {
  moveMaticEthToContract: PropTypes.func.isRequired,
  walletBalance: PropTypes.string.isRequired
};

const mapStateToProps = ({ account }) => ({
  walletBalance: account.walletBalance
});

const mapDispatchToProps = {
  moveMaticEthToContract
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveMaticEthToContract);
