import React from "react";
// import PropTypes from "prop-types";

import "./Loader.scss";

const Loader = ({
  text = "",
  color = "#FFFFFF",
  isFullScreen = false,
  size = 38
}) => {
  return (
    <div className={`loader${isFullScreen ? " loader--full-screen" : ""}`}>
      <div className="loader__body">
        {text !== "" && <p className="loader__body__text">{text}</p>}
        <svg
          width={size}
          height={size}
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          stroke={color}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="2">
              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </g>
          </g>
        </svg>
      </div>
      {isFullScreen && <div className="loader__background" />}
    </div>
  );
};

Loader.propTypes = {};

export default Loader;
