export const GET_ROLL_GAME_STATE_REQUEST = "GET_ROLL_GAME_STATE_REQUEST";
export const GET_ROLL_GAME_STATE_SUCCESS = "GET_ROLL_GAME_STATE_SUCCESS";
export const GET_ROLL_GAME_STATE_ERROR = "GET_ROLL_GAME_STATE_ERROR";

export const GET_ROLL_ROUND_STATE_REQUEST = "GET_ROLL_ROUND_STATE_REQUEST";
export const GET_ROLL_ROUND_STATE_SUCCESS = "GET_ROLL_ROUND_STATE_SUCCESS";
export const GET_ROLL_ROUND_STATE_ERROR = "GET_ROLL_ROUND_STATE_ERROR";

export const GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST =
  "GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST";
export const GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS =
  "GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS";
export const GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR = "GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR";

export const SET_ROLL_GAME_TYPE = "SET_ROLL_GAME_TYPE";
export const SET_ROLL_GAME_SUBTYPE = "SET_ROLL_GAME_SUBTYPE";
export const SET_ROLL_BET_AMOUNT = "SET_ROLL_BET_AMOUNT";
export const SET_ROLL_BET_VALUE = "SET_ROLL_BET_VALUE";
export const SET_ROLL_WIN_CHANCE = "SET_ROLL_WIN_CHANCE";
export const SET_ROLL_WIN_PRIZE = "SET_ROLL_WIN_PRIZE";
export const SET_ROLL_LAST_SELECTED_NUMBER = "SET_ROLL_LAST_SELECTED_NUMBER";
export const SET_ROLL_LAST_USER_SELECTED_NUMBER = "SET_ROLL_LAST_USER_SELECTED_NUMBER";
export const SET_ROLL_LAST_USER_SELECTED_GAME_TYPE =
  "SET_ROLL_LAST_USER_SELECTED_GAME_TYPE";
export const SET_ROLL_LAST_ROUND_PLAYED = "SET_ROLL_LAST_ROUND_PLAYED";
export const SET_ROLL_LAST_WIN_PRIZE = "SET_ROLL_LAST_WIN_PRIZE";
export const SET_ROLL_RANGE_LIMITS = "SET_ROLL_RANGE_LIMITS";
export const SET_ROLL_SHOW_CONTROLS = "SET_ROLL_SHOW_CONTROLS";
export const SET_ROLL_SHOW_DICE = "SET_ROLL_SHOW_DICE";
export const SET_ROLL_AUTO_BET_DATA = "SET_ROLL_AUTO_BET_DATA";

export const PLACE_ROLL_BET_REQUEST = "PLACE_ROLL_BET_REQUEST";
export const PLACE_ROLL_BET_SUCCESS = "PLACE_ROLL_BET_SUCCESS";
export const PLACE_ROLL_BET_ERROR = "PLACE_ROLL_BET_ERROR";
export const CLOSE_ROLL_ROUND = "CLOSE_ROLL_ROUND";

export const START_ROLL_AUTO_BET_REQUEST = "START_ROLL_AUTO_BET_REQUEST";
export const START_ROLL_AUTO_BET_SUCCESS = "START_ROLL_AUTO_BET_SUCCESS";
export const START_ROLL_AUTO_BET_ERROR = "START_ROLL_AUTO_BET_ERROR";

export const ROLL_AUTO_BET_STARTED = "ROLL_AUTO_BET_STARTED";
export const ROLL_AUTO_BET_STOPPED = "ROLL_AUTO_BET_STOPPED";

export const UPDATE_ROLL_AUTO_BET_REQUEST = "UPDATE_ROLL_AUTO_BET_REQUEST";
export const UPDATE_ROLL_AUTO_BET_SUCCESS = "UPDATE_ROLL_AUTO_BET_SUCCESS";
export const UPDATE_ROLL_AUTO_BET_ERROR = "UPDATE_ROLL_AUTO_BET_ERROR";

export const STOP_ROLL_AUTO_BET_REQUEST = "STOP_ROLL_AUTO_BET_REQUEST";
export const STOP_ROLL_AUTO_BET_SUCCESS = "STOP_ROLL_AUTO_BET_SUCCESS";
export const STOP_ROLL_AUTO_BET_ERROR = "STOP_ROLL_AUTO_BET_ERROR";

export const GET_ACC_ROLL_TOTAL_BETS_ERROR = "GET_ACC_ROLL_TOTAL_BETS_ERROR";
export const GET_ACC_ROLL_TOTAL_BETS_REQUEST = "GET_ACC_ROLL_TOTAL_BETS_REQUEST";
export const GET_ACC_ROLL_TOTAL_BETS_SUCCESS = "GET_ACC_ROLL_TOTAL_BETS_SUCCESS";

export const SET_ROLL_PAUSED = "SET_ROLL_PAUSED";

export const SET_ROLL_LAST_ROUND_BLOCK = "SET_ROLL_LAST_ROUND_BLOCK";

export const ROLL_LISTENERS_INITIATED = "ROLL_LISTENERS_INITIATED";

export const GET_ROLL_INIT_DATA_SUCCESS = "GET_ROLL_INIT_DATA_SUCCESS";
export const GET_ROLL_INIT_DATA_REQUEST = "GET_ROLL_INIT_DATA_REQUEST";
export const GET_ROLL_INIT_DATA_ERROR = "GET_ROLL_INIT_DATA_ERROR";

export const DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION = "DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION";
