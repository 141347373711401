import {
  GET_PLAYERS_HISTORY_REQUEST,
  GET_PLAYERS_HISTORY_SUCCESS,
  GET_PLAYERS_HISTORY_ERROR,
  CHANGE_PLAYERS_HISTORY_PER_PAGE
} from "../actionTypes/playersHistory";
import { PAGINATION_PER_PAGE_OPTIONS } from '../config/constants';

const INITIAL_STATE = {
  gettingPlayersHistory: false,
  gettingPlayersHistoryError: "",
  perPage: PAGINATION_PER_PAGE_OPTIONS[0],
  playersHistory: { data: [] }
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PLAYERS_HISTORY_REQUEST:
      return {
        ...state,
        gettingPlayersHistory: true,
        gettingPlayersHistoryError: ""
      };

    case GET_PLAYERS_HISTORY_SUCCESS:
      return {
        ...state,
        gettingPlayersHistory: false,
        gettingPlayersHistoryError: "",
        playersHistory: payload
      };

    case GET_PLAYERS_HISTORY_ERROR:
      return {
        ...state,
        gettingPlayersHistory: false,
        gettingPlayersHistoryError: payload
      };

    case CHANGE_PLAYERS_HISTORY_PER_PAGE:
      return { ...state, perPage: payload };

    default:
      return state;
  }
};
