import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import EmojiPicker from "emoji-picker-react";
import * as linkify from "linkifyjs";
import Linkify from "linkifyjs/react";
import mention from "linkifyjs/plugins/mention";
import { formatAcc } from "../../../../services/utils";
import { toggleModal } from "../../../../actions/modal";
import {
  sendMessage,
  setChatMessageVal,
  toggleEmojis
} from "../../../../actions/chat";
import Loader from "../../../Loader/Loader";
import "./Chat.scss";
import { register } from "../../../../actions/account";
import { getUserDataFromApi } from "../../../../services/api";
import { LEVEL_TITLES } from "../../../../config/constants";

mention(linkify);

class Chat extends Component {
  constructor(props) {
    super(props);

    this.textareaInput = createRef();
    this.onEnterPress = this.onEnterPress.bind(this);
    this.tagUser = this.tagUser.bind(this);
  }

  onEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();

      this.props.setChatMessageVal(e.target.value);
      this.props.sendMessage();
      this.textareaInput.current.value = "";
    } else {
      this.props.setChatMessageVal(e.target.value);
    }
  }

  tagUser(string) {
    this.textareaInput.current.value = `${
      this.textareaInput.current.value !== ""
        ? `${this.textareaInput.current.value} `
        : ""
    }@${string} `;
  }

  render() {
    const {
      chatHistory,
      connectingToSocket,
      gettingChatHistory,
      gettingChatHistoryError,
      showEmojis,
      toggleEmojis,
      adminOnline,
      isAdmin,
      toggleModal,
      currentAcc,
      isRegistered,
      register,
      playerLevel
    } = this.props;

    let linkProps = {
      onClick: async val => {
        const [linkified] = linkify.find(val.currentTarget.innerText);
        if (linkified.type === "mention") {
          val.preventDefault();
          const mention = linkified.value.substr(1, linkified.value.length);
          const isUsername = mention.length !== 42;
          if (isUsername) {
            const { address } = await getUserDataFromApi(null, mention);
            toggleModal("PlayersHistory", {
              isLarge: true,
              account: address
            });
          } else {
            toggleModal("PlayersHistory", {
              isLarge: true,
              account: mention
            });
          }
        }
      }
    };

    return (
      <PerfectScrollbar
        option={{ suppressScrollX: true }}
        ref={ref => {
          this._scrollBarRef = ref;
          if (this._scrollBarRef)
            this._scrollBarRef._container.scrollTop = this._scrollBarRef._container.scrollHeight;
        }}
      >
        <div
          className={`aside__drawer-content aside__drawer-content--chat${
            (gettingChatHistory || connectingToSocket) && isRegistered
              ? " loader"
              : ""
          }`}
        >
          {(connectingToSocket || gettingChatHistory) && isRegistered && (
            <Loader />
          )}
          {!connectingToSocket &&
            !gettingChatHistory &&
            gettingChatHistoryError === "" &&
            isRegistered && (
              <Fragment>
                <ul className="chat">
                  {chatHistory.map(
                    ({ _id, name, address, message, banned, titleNumber }) => (
                      <li className="message" key={_id}>
                        <div
                          className={`message__header${
                            address === currentAcc ? " isUser" : ""
                          }`}
                          style={{
                            flexDirection:
                              isAdmin && address === currentAcc
                                ? "row-reverse"
                                : ""
                          }}
                        >
                          {!isAdmin && (
                            <div className="message__user-title">
                              {titleNumber === null
                                ? ""
                                : `"${LEVEL_TITLES[Number(titleNumber)].label}"`}
                            </div>
                          )}

                          {isAdmin && !banned && (
                            <span
                              className="admin__control"
                              onClick={() =>
                                toggleModal("BanUser", {
                                  address,
                                  dialog: true,
                                  ban: true
                                })
                              }
                            >
                              Ban
                            </span>
                          )}

                          {name === "" && (
                            <div
                              className="message__sender"
                              onClick={() => this.tagUser(address)}
                            >
                              {formatAcc(address)}
                            </div>
                          )}
                          {name !== "" && (
                            <div
                              className="message__sender"
                              onClick={() => this.tagUser(name)}
                            >
                              {name}
                            </div>
                          )}

                          {isAdmin && banned && (
                            <span
                              className="admin__control"
                              onClick={() =>
                                toggleModal("BanUser", {
                                  address,
                                  dialog: true,
                                  removeBan: true
                                })
                              }
                            >
                              Remove ban
                            </span>
                          )}
                        </div>
                        <div className="message__content">
                          <Linkify options={{ attributes: linkProps }}>
                            {message}
                          </Linkify>
                        </div>
                        {isAdmin && (
                          <span
                            className="admin__control"
                            onClick={() =>
                              toggleModal("DeleteMessage", {
                                _id,
                                dialog: true
                              })
                            }
                          >
                            Delete
                          </span>
                        )}
                      </li>
                    )
                  )}
                </ul>
                <form onSubmit={() => {}}>
                  <textarea
                    ref={this.textareaInput}
                    onKeyDown={this.onEnterPress}
                    onFocus={showEmojis ? () => toggleEmojis() : null}
                    className="chat__input"
                    placeholder="Type here"
                    cols="30"
                    rows="10"
                  />
                </form>

                <div className="emoji-open">
                  <img
                    onClick={() => {
                      toggleEmojis();
                    }}
                    src="https://cdn.jsdelivr.net/emojione/assets/4.5/png/128/1f601.png"
                    alt="emoji"
                  />
                </div>

                <div
                  className={`emoji-wrapper ${showEmojis ? "show" : "hide"}`}
                >
                  <EmojiPicker
                    onEmojiClick={e => {
                      this.textareaInput.current.value += String.fromCodePoint(
                        parseInt(e, 16)
                      );
                      this.props.setChatMessageVal(
                        this.textareaInput.current.value
                      );
                    }}
                  />
                </div>
              </Fragment>
            )}
          {!gettingChatHistory && gettingChatHistoryError !== "" && (
            <div className="chat__register">
              <p>{gettingChatHistoryError}</p>
            </div>
          )}
        </div>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = ({ chat, account, badToken }) => ({
  chatHistory: chat.chatHistory,
  gettingChatHistory: chat.gettingChatHistory,
  gettingChatHistoryError: chat.gettingChatHistoryError,
  showEmojis: chat.showEmojis,
  adminOnline: chat.adminOnline,
  isAdmin: chat.isAdmin,
  currentAcc: account.account,
  isRegistered: account.isRegistered,
  connectingToSocket: chat.connectingToSocket,
  playerLevel: badToken.playerLevel
});

const mapDispatchToProps = {
  sendMessage,
  setChatMessageVal,
  toggleEmojis,
  toggleModal,
  register
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat);
