import {
  badBitDistributorTxHash,
  BadBitGameContract, badBitGameContractDeployTransactionHash,
  BadBitGameEventsContract,
  BadBitSettingsContract,
  ethersProvider, web3Matic,
} from '../contract';
import { hexToNumber, numberToHex } from "./main";
import { executeMetaTransaction, getExecuteMetaTransactionSignature } from "../utils.js";
import { getUserContractBalance } from '../../actions/account';
import { addBadTokenWinNotification } from '../../actions/badToken';
import { setRollShowControls, setRollShowDice } from '../../actions/games/roll';
import { setSpinShowControls, setSpinShowSpinner } from '../../actions/games/spin';
import { setRouletteDisableControls, setRouletteShowControls } from '../../actions/games/roulette';
import { setActiveCard, setCards, setScratchShowControls } from '../../actions/games/scratch';
import { getScratchContractConstValues } from '../../actions/contractConstants/scratch';

export const formatDonPlayed = ({ betId, queryId, user }) => ({
  betId: hexToNumber(betId._hex),
  queryId,
  user
});

/**
 * Plays a Double Or Nothing game type for a given address
 *
 * @param from
 * @param changeModalType
 * @param toggleModal
 * @param notify
 * @param dispatch
 * @param lastWinPrize
 * @param gameAddress
 * @param getState
 * @return {Promise<any>}
 */
export const doubleOrNothingFromContract = (
  from,
  changeModalType,
  toggleModal,
  notify,
  dispatch,
  lastWinPrize,
  gameAddress,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch(changeModalType(
        "ModalLoader",
        { modalText: "Sign a transaction..." },
        false
      ));

      const { activeGame } = getState().games;
      const { drawerRightOpen } = getState().drawer;

      dispatch(getUserContractBalance());
      dispatch(addBadTokenWinNotification("0"));
      if (!drawerRightOpen && window.innerWidth <= 425) {
        document.querySelector("html").classList.remove("overflowHidden");
        document.querySelector("body").classList.remove("overflowHidden");
      }
      setTimeout(() => {
        if (activeGame === "roll") {
          dispatch(setRollShowControls(true));
          dispatch(setRollShowDice(false));
        }
        if (activeGame === "spin") {
          dispatch(setSpinShowControls(true));
          dispatch(setSpinShowSpinner(false));
        }
        if (activeGame === "roulette") {
          dispatch(setRouletteShowControls(true));
          dispatch(setRouletteDisableControls(false));
        }
      }, 300);
      if (activeGame === "scratch") {
        dispatch(setScratchShowControls(true));
        dispatch(setActiveCard(0));
        dispatch(setCards([]));
        dispatch(getScratchContractConstValues());
      }

      const contract = await BadBitGameContract();
      const functionSignature = contract.methods
        .placeBetForDoubleOrNothing(gameAddress, lastWinPrize)
        .encodeABI();
      const signature = await getExecuteMetaTransactionSignature(contract, functionSignature, from);
      const promise = executeMetaTransaction(contract, functionSignature, from, signature.r, signature.s, signature.v);

      promise
        .on("transactionHash", hash => {
          dispatch(
            changeModalType(
              "ModalLoader",
              { modalText: "Sending a transaction..." },
              false
            )
          );
        })
        .on("receipt", (receipt) => {
          dispatch(
            changeModalType(
              "ModalLoader",
              {
                modalText: "Waiting for a result...",
                modalTitle: "Double or Nothing"
              },
              false
            )
          );
          resolve(receipt);
        })
        .on("error", err => {
          console.log(err);
          dispatch(toggleModal());
          notify("Oops, there was an error!", "error")(dispatch);
          reject(err);
        });
    } catch (err) {
      dispatch(toggleModal());
      reject(err);
    }
  });

/**
 *
 *
 * @param queryId
 * @return {Promise<object>}
 */
export const donBetsFromContract = async (queryId, from) => {
  const contract = await BadBitGameContract();
  return await contract.methods.donBets(queryId).call({from});
};

/**
 * A listener for DoubleOrNothingFinished event
 *
 * @param callback
 * @param getState
 */
export const listenToDoubleOrNothingFinishedFromContract = (
  callback,
  getState
) => {
  console.log("DON FINISHED EVENT OUT");
  BadBitGameEventsContract.on(
    "DoubleOrNothingFinished",
    async (queryId, user, won, tokensWon) => {
      const { account } = getState().account;

      if (user.toUpperCase() === account.toUpperCase()) {
        await callback({ won, tokensWon });
        console.log("DON FINISHED EVENT IN");
      }
    }
  );
};

export const formatDonFinished = ({ won, queryId, user }) => ({
  won,
  queryId,
  user
});

export const getTotalBetsFromDoNContract = async address => {
  const contract = await BadBitGameContract();
  const totalBets = await contract.methods.getTotalBets(address).call();
  return Number(totalBets);
};

/**
 * Gets Double or Nothing for a given account from the contracts event logs
 *
 * @param account
 * @return {Promise<object>}
 */
export const getDoubleOrNothingFinishedFromContract = account =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = BadBitGameEventsContract;
      const donFinishedEvent =
        contract.interface.events["DoubleOrNothingFinished"];
      const filter = contract.filters.DoubleOrNothingFinished(null, account);
      const { blockNumber } = await web3Matic.eth.getTransaction(badBitDistributorTxHash);
      filter.fromBlock = blockNumber;

      let logs = await ethersProvider.getLogs(filter);
      logs = logs.map(log => donFinishedEvent.decode(log.data, log.topics));
      logs = logs.map(log => formatDonFinished(log));

      resolve(logs);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const getDonBetsFromContract = async (queryId, from) => {
  const contract = await BadBitGameContract();
  return await contract.methods.donBets(queryId).call({from});
};

export const getQueryIdsFromDoNFinishedFromContract = async account => {
  try {
    const DoNFinished = await getDoubleOrNothingFinishedFromContract(account);
    const arr = [];
    DoNFinished.forEach(item => {
      arr.push(item.queryId);
    });
    return arr.reverse();
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets Double or Nothing for a given account from the contracts event logs
 *
 * @param account
 * @param betId
 * @param game
 * @return {Promise<object>}
 */
export const getDoubleOrNothingPlayedFromContract = (account, betId, game) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = BadBitGameEventsContract;
      const donPlayedEvent = contract.interface.events["DoubleOrNothingPlayed"];
      const filter = contract.filters.DoubleOrNothingPlayed(
        null,
        account,
        numberToHex(betId),
        game
      );
      const { blockNumber } = await web3Matic.eth.getTransaction(badBitGameContractDeployTransactionHash);
      filter.fromBlock = blockNumber;

      let logs = await ethersProvider.getLogs(filter);
      logs = logs.map(log => donPlayedEvent.decode(log.data, log.topics));
      logs = logs.map(log => formatDonPlayed(log));

      resolve(logs);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
/**
 * Gets max number of DoNs that could be played in a round from contract settings
 *
 * @return {Promise<number>}
 */
export const getMaxDonsInRowFromContract = async (from) => {
  const contract = await BadBitSettingsContract();
  const maxDon = await contract.methods.MAX_DON_IN_ROW().call( {from} );
  return Number(maxDon);
};
