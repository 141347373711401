import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setRouletteBetAmount } from "../../../../actions/contractConstants/roulette";
import {
  placeRouletteBet,
  clearAllBetsRoulette,
} from "../../../../actions/games/roulette";

import Table from "../Table/Table";
import BetSize from "../../Shared/BetSize/BetSize";
import PlaceBet from "../../Shared/PlaceBet/PlaceBet";
import BetData from "../../Shared/BetData/BetData";
import History from "../../Shared/History/History";
import Wheel from "../Wheel/Wheel";

import "./Controls.scss";

class Controls extends Component {
  render() {
    const {
      showControls,
      gettingGameState,
      gettingGameStateErr,
      betAmount,
      maxBetSize,
      exceededMaxBetSize,
      setRouletteBetAmount,
      placeRouletteBet,
      roundState,
      disableControls,
      clearAllBetsRoulette,
      lastSelectedNumber,
      bets,
      placingBet,
      settingInitData,
      isAutoBetting,
      startingAutoBetting,
    } = this.props;
    return (
      <section
        className={`main__section main__section--controls roulette ${
          !showControls ? "center" : ""
        }`}
      >
        {!showControls && (
          <Fragment>
            <span className="placed-bets-title">Your Bets: </span>
            <div className="placed-bets">
              {bets.map(item => (
                <div
                  key={`${item.betData.details}-${item.betData.bet}`}
                  className={`bet-type ${item.betData.color}`}
                >
                  {
                    item.betData.bet === "2-1"
                      ? item.betData.details.slice(7, item.betData.details.length)
                      : item.betData.bet
                  }
                </div>
              ))}
            </div>
            <Wheel
              roundState={roundState}
              lastSelectedNumber={lastSelectedNumber}
            />
          </Fragment>
        )}
        {showControls && (
          <Fragment>
            <BetSize
              placingBet={isAutoBetting || startingAutoBetting || placingBet}
              maxVal={maxBetSize}
              adjustMax={false}
              exceededMax={exceededMaxBetSize}
              amount={betAmount}
              setAmount={setRouletteBetAmount}
              betId={bets.length + 1}
            />
            {!!bets.length && (
              <button className="clear-bets"
                      onClick={clearAllBetsRoulette}
                      disabled={isAutoBetting || startingAutoBetting || placingBet}
              >
                Clear All Bets
              </button>
            )}
            <div className={'table__outer'}>
              <div className={'table__inner'}>
                <Table />
              </div>
            </div>
            <BetData game="roulette" />
            <PlaceBet
              isAutoBetting={isAutoBetting}
              placeBet={placeRouletteBet}
              gettingState={gettingGameState || disableControls || settingInitData || startingAutoBetting}
            />
            <History
              gettingGameState={gettingGameState}
              gettingGameStateErr={gettingGameStateErr}
            />
          </Fragment>
        )}
      </section>
    );
  }
}

Controls.propTypes = {
  setRouletteBetAmount: PropTypes.func.isRequired,
  clearAllBetsRoulette: PropTypes.func.isRequired,
  showControls: PropTypes.bool.isRequired,
  lastSelectedNumber: PropTypes.number.isRequired,
  roundState: PropTypes.string.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  gettingGameStateErr: PropTypes.string.isRequired,
  disableControls: PropTypes.bool.isRequired,
  betAmount: PropTypes.string.isRequired,
  maxBetSize: PropTypes.string.isRequired,
  exceededMaxBetSize: PropTypes.bool.isRequired,
  bets: PropTypes.array.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameRoulette, rouletteConstants, games }) => ({
  showControls: gameRoulette.showControls,
  lastSelectedNumber: gameRoulette.lastSelectedNumber,
  roundState: gameRoulette.roundState,
  gettingGameState: gameRoulette.gettingGameState,
  gettingGameStateErr: gameRoulette.gettingGameStateErr,
  exceededMaxBetSize: gameRoulette.exceededMaxBetSize,
  placingBet: gameRoulette.placingBet,
  disableControls: gameRoulette.disableControls,
  bets: gameRoulette.bets,
  betAmount: rouletteConstants.betAmount,
  maxBetSize: rouletteConstants.maxBetSize,
  settingInitData: games.settingInitData,
  isAutoBetting: gameRoulette.isAutoBetting,
  startingAutoBetting: gameRoulette.startingAutoBetting,
});

const mapDispatchToProps = {
  setRouletteBetAmount,
  placeRouletteBet,
  clearAllBetsRoulette,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
