export const CONNECTING_TO_SOCKET_REQUEST = "CONNECTING_TO_SOCKET_REQUEST";
export const CONNECTING_TO_SOCKET_SUCCESS = "CONNECTING_TO_SOCKET_SUCCESS";
export const CONNECTING_TO_SOCKET_ERROR = "CONNECTING_TO_SOCKET_ERROR";

export const GET_CHAT_HISTORY_REQUEST = "GET_CHAT_HISTORY_REQUEST";
export const GET_CHAT_HISTORY_SUCCESS = "GET_CHAT_HISTORY_SUCCESS";
export const GET_CHAT_HISTORY_ERROR = "GET_CHAT_HISTORY_ERROR";

export const CHECK_IF_IS_ADMIN_REQUEST = "CHECK_IF_IS_ADMIN_REQUEST";
export const CHECK_IF_IS_ADMIN_SUCCESS = "CHECK_IF_IS_ADMIN_SUCCESS";
export const CHECK_IF_IS_ADMIN_ERROR = "CHECK_IF_IS_ADMIN_ERROR";

export const BAN_USER_REQUEST = "BAN_USER_REQUEST";
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_ERROR = "BAN_USER_ERROR";

export const TOGGLE_BAN_USER_REQUEST = "TOGGLE_BAN_USER_REQUEST";
export const TOGGLE_BAN_USER_SUCCESS = "TOGGLE_BAN_USER_SUCCESS";
export const TOGGLE_BAN_USER_ERROR = "TOGGLE_BAN_USER_ERROR";

export const UNBAN_USER_REQUEST = "UNBAN_USER_REQUEST";
export const UNBAN_USER_SUCCESS = "UNBAN_USER_SUCCESS";
export const UNBAN_USER_ERROR = "UNBAN_USER_ERROR";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const ADD_MESSAGE_REQUEST = "ADD_MESSAGE_REQUEST";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
export const ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR";

export const REMOVE_MESSAGE_REQUEST = "REMOVE_MESSAGE_REQUEST";
export const REMOVE_MESSAGE_SUCCESS = "REMOVE_MESSAGE_SUCCESS";
export const REMOVE_MESSAGE_ERROR = "REMOVE_MESSAGE_ERROR";

export const DELETE_MSG_REQUEST = "DELETE_MSG_REQUEST";
export const DELETE_MSG_SUCCESS = "DELETE_MSG_SUCCESS";
export const DELETE_MSG_ERROR = "DELETE_MSG_ERROR";

export const INCREMENT_UNREAD = "INCREMENT_UNREAD";
export const CLEAR_UNREAD = "CLEAR_UNREAD";

export const SET_ADMIN_ONLINE = "SET_ADMIN_ONLINE";

export const SET_MESSAGE_VALUE = "SET_MESSAGE_VALUE";

export const TOGGLE_EMOJIS = "TOGGLE_EMOJIS";
