import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import { NavLink, withRouter } from "react-router-dom";

import BadBitLogo from "../Decorative/BadBitLogo.png";
import EthLogo from "../Decorative/EthLogo";
import MaticLogo from "../Decorative/MaticLogo";
import BiconomyLogo from '../Decorative/BiconomyLogo';

import "./HeaderMain.scss";

class HeaderMain extends Component {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
    document.querySelector("html").classList.toggle("overflowHidden");
    document.querySelector("body").classList.toggle("overflowHidden");
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
    document.querySelector("html").classList.remove("overflowHidden");
    document.querySelector("body").classList.remove("overflowHidden");
  };

  render() {
    return (
      <header className="header header--main">
        <div className="container">
          <div className="logos">
            <img className="logo" src={BadBitLogo} alt="BadBit Games" />
            <EthLogo />
            <MaticLogo />
            <BiconomyLogo />
          </div>
          <nav
            className={`header--main__nav ${
              this.state.menuOpen ? "header--main__nav--open" : ""
            }`}
          >
            <ul className="header--main__nav__list">
              <li className="header--main__nav__item">
                <a href="/">Home</a>
              </li>
              <li className="header--main__nav__item">
                <Tooltip
                  useContext
                  position="bottom"
                  html={
                    <ul className="header--main__nav__sublist">
                      <li className="header--main__nav__item">
                        <NavLink activeClassName="active" to="/roll" onClick={this.closeMenu}>
                          ROLL
                        </NavLink>
                      </li>
                      <li className="header--main__nav__item" onClick={this.closeMenu}>
                        <NavLink activeClassName="active" to="/spin">
                          SPIN
                        </NavLink>
                      </li>
                      <li className="header--main__nav__item" onClick={this.closeMenu}>
                        <NavLink activeClassName="active" to="/scratch">
                          SCRATCH
                        </NavLink>
                      </li>
                      <li className="header--main__nav__item" onClick={this.closeMenu}>
                        <NavLink activeClassName="active" to="/roulette">
                          ROULETTE
                        </NavLink>
                      </li>
                    </ul>
                  }
                  interactive={true}
                  theme="roll"
                >
                  Games
                </Tooltip>
              </li>
              <li className="header--main__nav__item">
                <NavLink to="/bad-token" activeClassName="active">
                  BAD Token
                </NavLink>
              </li>
              <li className="header--main__nav__item">
                <NavLink to="/affiliates" activeClassName="active">
                  Affiliates
                </NavLink>
              </li>
              <li className="header--main__nav__item">
                <a href="/about-us">About Us</a>
              </li>
              <li className="header--main__nav__item">
                <a href="/help">Contact</a>
              </li>
            </ul>
          </nav>
          <div
            onClick={this.toggleMenu}
            className={`ham-menu ${
              this.state.menuOpen ? "ham-menu--open" : ""
            }`}
          >
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(HeaderMain);
