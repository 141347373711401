import { TOGGLE_MODAL, CHANGE_MODAL_TYPE } from "../actionTypes/modal";

const INITIAL_STATE = {
  modalOpen: false,
  modalComponent: "",
  modalProps: {},
  canCloseModal: true
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        modalComponent: payload.modalComponent,
        modalProps: payload.modalProps,
        canCloseModal: payload.canCloseModal
      };

    case CHANGE_MODAL_TYPE:
      return {
        ...state,
        modalComponent: payload.modalComponent,
        modalProps: payload.modalProps,
        canCloseModal: payload.canCloseModal
      };

    default:
      return state;
  }
};
