import React from "react";

export default function ChainLink() {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="85" height="85" rx="42.5" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M66.3346 29L42.0859 15L17.8372 29V57L42.0859 71L66.3346 57V29ZM55.7074 35.0547L42.0858 27.1087L28.4642 35.0547V50.9466L42.0858 58.8925L55.7074 50.9466V35.0547Z" fill="#2B5BDB"/>
    </svg>
  );
}
