import React from "react";
import { connect } from "react-redux";
import { toggleModal } from "../../../actions/modal";


import leaderboardBannerD from "./leaderboard-d.jpg";
import leaderboardBannerM from "./leaderboard-m.jpg";

const LeaderboardBanner = ({ toggleModal, ...props }) => {
  return (
    <img src={leaderboardBannerD}
         srcSet={`${leaderboardBannerM} 425w, ${leaderboardBannerD} 500w`}
         alt="Leaderboard banners"
         {...props}
         onClick={() => toggleModal("LeaderboardStats", { isLarge: true })}
    />
  );
};

const mapDispatchToProps = { toggleModal };

export default connect(null, mapDispatchToProps)(LeaderboardBanner);
