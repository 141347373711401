import {
  GET_MAX_NUM_OF_CARDS_REQUEST,
  GET_MAX_NUM_OF_CARDS_SUCCESS,
  GET_MAX_NUM_OF_CARDS_ERROR,
  GET_MAX_CARD_COST_REQUEST,
  GET_MAX_CARD_COST_SUCCESS,
  GET_MAX_CARD_COST_ERROR,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS,
  GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR,
  GET_SCRATCH_WIN_MULTIPLIER_REQUEST,
  GET_SCRATCH_WIN_MULTIPLIER_SUCCESS,
  GET_SCRATCH_WIN_MULTIPLIER_ERROR,
} from "../../actionTypes/contractConstants/scratch";

const INITIAL_STATE = {
  maxNumOfCards: 1,
  gettingMaxNumOfCards: false,
  gettingMaxNumOfCardsErr: "",

  maxCardCost: "0",
  gettingMaxCardCost: false,
  gettingMaxCardCostErr: "",

  winProbForWinTypeZero: [],
  gettingWinProbForWinTypeZero: false,
  gettingWinProbForWinTypeZeroErr: "",

  winningMultiplier: [],
  gettingWinningMultiplier: false,
  gettingWinningMultiplierErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MAX_NUM_OF_CARDS_REQUEST:
      return {
        ...state,
        gettingMaxNumOfCards: true,
        gettingMaxNumOfCardsErr: ""
      };

    case GET_MAX_NUM_OF_CARDS_SUCCESS:
      return {
        ...state,
        maxNumOfCards: Number(payload),
        gettingMaxNumOfCards: false,
        gettingMaxNumOfCardsErr: ""
      };

    case GET_MAX_NUM_OF_CARDS_ERROR:
      return {
        ...state,
        gettingMaxNumOfCards: false,
        gettingMaxNumOfCardsErr: payload
      };

    case GET_MAX_CARD_COST_REQUEST:
      return {
        ...state,
        gettingMaxCardCost: true,
        gettingMaxCardCostErr: "",
      };

    case GET_MAX_CARD_COST_SUCCESS:
      return {
        ...state,
        maxCardCost: payload,
        gettingMaxCardCost: false,
        gettingMaxCardCostErr: "",
      };

    case GET_MAX_CARD_COST_ERROR:
      return {
        ...state,
        gettingMaxCardCost: false,
        gettingMaxCardCostErr: payload,
      };

    case GET_WIN_PROB_FOR_WIN_TYPE_ZERO_REQUEST:
      return {
        ...state,
        gettingWinProbForWinTypeZero: true,
        gettingWinProbForWinTypeZeroErr: "",
      };

    case GET_WIN_PROB_FOR_WIN_TYPE_ZERO_SUCCESS:
      return {
        ...state,
        winProbForWinTypeZero: payload,
        gettingWinProbForWinTypeZero: false,
        gettingWinProbForWinTypeZeroErr: "",
      };

    case GET_WIN_PROB_FOR_WIN_TYPE_ZERO_ERROR:
      return {
        ...state,
        gettingWinProbForWinTypeZero: false,
        gettingWinProbForWinTypeZeroErr: payload,
      };

    case GET_SCRATCH_WIN_MULTIPLIER_REQUEST:
      return {
        ...state,
        gettingWinningMultiplier: true,
        gettingWinningMultiplierErr: "",
      };

    case GET_SCRATCH_WIN_MULTIPLIER_SUCCESS:
      return {
        ...state,
        winningMultiplier: payload,
        gettingWinningMultiplier: false,
        gettingWinningMultiplierErr: "",
      };

    case GET_SCRATCH_WIN_MULTIPLIER_ERROR:
      return {
        ...state,
        gettingWinningMultiplier: false,
        gettingWinningMultiplierErr: payload,
      };

    default:
      return state;
  }
};
