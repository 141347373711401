import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveGame } from "../../../actions/games/games";
import {getRouletteInitialData, startRouletteAutoBet, stopRouletteAutoBet} from '../../../actions/games/roulette';

import GamePaused from "../Shared/GamePaused/GamePaused";
import Controls from "./Controls/Controls";

import "./RouletteGame.scss";
import BetsList from "./BetsList/BetsList";
import UserDisabled from "../Shared/UserDisabled/UserDisabled";
import AutoBet from '../Shared/AutoBet/AutoBet';

class RouletteGame extends Component {
  componentWillMount() {
    this.props.setActiveGame("roulette");
    this.props.getRouletteInitialData();
  }

  componentDidMount() {
    document.title = "ROULETTE | BadBit.Games";
  }

  render() {
    const {
      gamesPaused,
      roulettePaused,
      userStatus,
      isAutoBetting,
      autoBettingRounds,
      autoBettingCurrentRound,
      startRouletteAutoBet,
      stopRouletteAutoBet,
      startingAutoBetting,
      stoppingAutoBetting,
    } = this.props;
    return (
      <main
        className={`main roulette ${
          gamesPaused || roulettePaused || userStatus.status === "disabled" ? "center" : ""
        }`}
      >
        <div className="container">
          {userStatus.status !== "disabled" && !gamesPaused && !roulettePaused && (
            <Fragment>
              <Controls />
              {/*<AutoBet*/}
              {/*  startingAutoBetting={startingAutoBetting}*/}
              {/*  stoppingAutoBetting={stoppingAutoBetting}*/}
              {/*  startAutoBet={startRouletteAutoBet}*/}
              {/*  stopAutoBet={stopRouletteAutoBet}*/}
              {/*  isAutoBetting={isAutoBetting}*/}
              {/*  autoBettingRounds={autoBettingRounds}*/}
              {/*  autoBettingCurrentRound={autoBettingCurrentRound}*/}
              {/*/>*/}
              <BetsList />
            </Fragment>
          )}
          {(gamesPaused || roulettePaused) && <GamePaused />}
          {!(gamesPaused || roulettePaused) && userStatus.status === "disabled" && <UserDisabled />}
        </div>
      </main>
    );
  }
}

RouletteGame.propTypes = {
  setActiveGame: PropTypes.func.isRequired,
  getRouletteInitialData: PropTypes.func.isRequired,
  gamesPaused: PropTypes.bool.isRequired,
  roulettePaused: PropTypes.bool.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  autoBettingRounds: PropTypes.number.isRequired,
  autoBettingCurrentRound: PropTypes.number.isRequired,
  startRouletteAutoBet: PropTypes.func.isRequired,
  stopRouletteAutoBet: PropTypes.func.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
  stoppingAutoBetting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ games, gameRoulette, account }) => ({
  gamesPaused: games.gamesPaused,
  roulettePaused: gameRoulette.roulettePaused,
  userStatus: account.userStatus,
  isAutoBetting: gameRoulette.isAutoBetting,
  autoBettingRounds: gameRoulette.autoBettingRounds,
  autoBettingCurrentRound: gameRoulette.autoBettingCurrentRound,
  startingAutoBetting: gameRoulette.startingAutoBetting,
  stoppingAutoBetting: gameRoulette.stoppingAutoBetting,
});

const mapDispatchToProps = {
  setActiveGame,
  getRouletteInitialData,
  startRouletteAutoBet,
  stopRouletteAutoBet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouletteGame);
