import {
  SET_ROULETTE_PAUSED,
  TOGGLE_BETS,
  EXCEEDED_MAX_BET_SIZE,
  CHANGE_TABLE_HIGHLIGHT_COLOR,
  TOGGLE_CHIPS,
  SET_TOTAL_BET_AMOUNT,
  PLACE_ROULETTE_BET_REQUEST,
  PLACE_ROULETTE_BET_SUCCESS,
  PLACE_ROULETTE_BET_ERROR,
  START_ROULETTE_AUTO_BET_REQUEST,
  START_ROULETTE_AUTO_BET_SUCCESS,
  START_ROULETTE_AUTO_BET_ERROR,
  STOP_ROULETTE_AUTO_BET_REQUEST,
  STOP_ROULETTE_AUTO_BET_SUCCESS,
  STOP_ROULETTE_AUTO_BET_ERROR,
  GET_ROULETTE_ROUND_STATE_REQUEST,
  GET_ROULETTE_ROUND_STATE_SUCCESS,
  GET_ROULETTE_ROUND_STATE_ERROR,
  GET_ROULETTE_GAME_STATE_REQUEST,
  GET_ROULETTE_GAME_STATE_SUCCESS,
  GET_ROULETTE_GAME_STATE_ERROR,
  GET_ACC_ROULETTE_TOTAL_BETS_REQUEST,
  GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS,
  GET_ACC_ROULETTE_TOTAL_BETS_ERROR,
  SET_ROULETTE_SHOW_CONTROLS,
  SET_ROULETTE_DISABLE_CONTROLS,
  SET_ROULETTE_LAST_WIN_PRIZE,
  SET_ROULETTE_LAST_SELECTED_NUMBER,
  CLEAR_TABLE,
  ROULETTE_LISTENERS_INITIATED
} from "../../actionTypes/games/roulette";
import {
  getRouletteBetsAllowedFromContract,
  getTotalBetsFromRouletteContract,
  lastBetForPlayerFromRouletteContract,
  listenToRoulettePausedFromContract,
  listenToRoundFinishedFromRouletteContract,
  listenToRoundStartedFromRouletteContract,
  placeBetFromRouletteContract,
} from '../../services/ethereum/roulette';
import {
  betArrToBetListData,
  checkBetArrWinning,
  checkIfPlayerHasWonRouletteBet,
  createBetArr,
  getChanceAndMultiplierDuplicatedFromContract,
  getRouletteContractConstValues,
  setRouletteMaxBetSize
} from "../contractConstants/roulette";
import { notify } from "../notification";
import { formatNumber } from "../../services/utils";
import { weiToEth, giveInfiniteApproval } from "../../services/ethereum/main";
import { DP } from "../../config/constants";
import BigNumber from "bignumber.js";
import { sendTx } from "../txNotifications";
import {
  addNewLevelModal,
  getAccTotalBets,
  validatePlaceBetForRoulette
} from "./games";
import {
  getUserBonusContractBalance,
  getWalletBalance,
  setFirstBet,
  getWETHApproval
} from "../account";
import { toggleModal } from "../modal";
import { addRouletteRolls, getPreviousRouletteRolls } from "../betHistory";
import { setNumberOfWithdrawals } from "../assetMovmentlHistory";
import {
  addBadTokenWinNotification,
  areTokensDistributed,
  calculateNumberOfTokensWonForRoulette,
  getBadTokenWinningProbability,
  getPlayerLevel,
} from "../badToken";
import { sendRouletteBetsFromApi } from "../../services/api";
import { startAutoBetFromContract, stopAutoBetFromContract } from '../../services/ethereum/games';
import { rouletteGameAddress } from '../../services/contract';

export const toggleBets = payload => dispatch => {
  dispatch({ type: TOGGLE_BETS, payload });
  if (!!payload.length) {
    const totalBetAmount = payload
      .map(({ betArr }) => betArr[0])
      .reduce((total, num) => new BigNumber(total).plus(num).toString());
    dispatch(setTotalBetAmount(totalBetAmount));
  }
  dispatch(getBadTokenWinningProbability());
};

export const toggleChangeBetSizeDialog = itemIndex => (dispatch, getState) => {
  const { bets } = getState().gameRoulette;
  const payload = [...bets].map((item, index) => {
    if (itemIndex === index) {
      item.showControls = !item.showControls;
      return item;
    }
    return item;
  });
  dispatch(toggleBets(payload));
};

export const startRouletteAutoBet = (numberOfRounds) => async (dispatch, getState) => {
  dispatch({ type: START_ROULETTE_AUTO_BET_REQUEST });
  try {
    const { bets, totalBetAmount } = getState().gameRoulette;
    const {
      account,
      nickname,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      isFirstBet
    } = getState().account;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { maxChanceForBonusBet } = getState().gamesConstants;


    const betsArr = [...bets].map(({ betArr }) => betArr);

    const isValid = validatePlaceBetForRoulette(
      betsArr,
      totalBetAmount,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      START_ROULETTE_AUTO_BET_ERROR,
      dispatch,
      true
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    await dispatch(setFirstBet(false));
    dispatch(setRouletteDisableControls(true));

    const payload = {};

    const hasSucceeded = await startAutoBetFromContract(
      rouletteGameAddress, numberOfRounds, totalBetAmount, payload, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: START_ROULETTE_AUTO_BET_SUCCESS, payload: numberOfRounds });
    } else {
      dispatch({ type: START_ROULETTE_AUTO_BET_ERROR, payload: "There was an error while placing auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: START_ROULETTE_AUTO_BET_ERROR, payload: error.message });
  }
};

export const stopRouletteAutoBet = () => async (dispatch, getState) => {
  dispatch({ type: STOP_ROULETTE_AUTO_BET_REQUEST });
  try {
    const { account } = getState().account;

    const hasSucceeded = await stopAutoBetFromContract(
      rouletteGameAddress, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: STOP_ROULETTE_AUTO_BET_SUCCESS });
    } else {
      dispatch({ type: STOP_ROULETTE_AUTO_BET_ERROR, payload: "There was an error while stopping auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: STOP_ROULETTE_AUTO_BET_ERROR, payload: error.message });
  }
};

export const placeRouletteBet = () => async (dispatch, getState) => {
  dispatch({ type: PLACE_ROULETTE_BET_REQUEST });
  try {
    const { bets, totalBetAmount } = getState().gameRoulette;
    const {
      account,
      nickname,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      isFirstBet
    } = getState().account;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { maxChanceForBonusBet } = getState().gamesConstants;


    const betsArr = [...bets].map(({ betArr }) => betArr);

    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(totalBetAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBetForRoulette(
      betsArr,
      totalBetAmount,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      PLACE_ROULETTE_BET_ERROR,
      dispatch
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    await dispatch(setFirstBet(false));
    dispatch(setRouletteDisableControls(true));

    const hasSucceeded = await placeBetFromRouletteContract(
      betsArr,
      account,
      betWithContractBalance,
      betWithContractBonusBalance,
      totalBetAmount,
      sendTx,
      dispatch,
      getState
    );

    if (hasSucceeded) {
      setTimeout(() => dispatch(getWalletBalance(account)), 3000);
      dispatch(getUserBonusContractBalance());
      console.log("PLACE_ROULETTE_BET_SUCCESS");
      dispatch({ type: PLACE_ROULETTE_BET_SUCCESS });
    } else {
      dispatch(setRouletteDisableControls(false));
    }
  } catch (e) {
    console.log(e);
    dispatch(setRouletteDisableControls(false));
    dispatch({ type: PLACE_ROULETTE_BET_ERROR, payload: e.message });
  }
};

export const clearAllBetsRoulette = () => dispatch => {
  dispatch({ type: TOGGLE_BETS, payload: [] });
  dispatch({ type: CLEAR_TABLE })
};

export const setRouletteShowControls = payload => dispatch => {
  dispatch({ type: SET_ROULETTE_SHOW_CONTROLS, payload });
};

export const setRouletteDisableControls = payload => dispatch => {
  dispatch({ type: SET_ROULETTE_DISABLE_CONTROLS, payload });
};

export const setTotalBetAmount = payload => dispatch => {
  dispatch({ type: SET_TOTAL_BET_AMOUNT, payload });
};

export const changeHighlightColor = payload => dispatch => {
  dispatch({ type: CHANGE_TABLE_HIGHLIGHT_COLOR, payload });
};

export const highlight = (_data, type) => async (dispatch, getState) => {
  let nums, key;
  const betArr = createBetArr(_data, type);
  await dispatch(setRouletteMaxBetSize(betArr));
  const rouletteState = getState().gameRoulette;

  if (typeof _data === "string") {
    nums = rouletteState[`${type}`][`${_data}`].numbers;
    key = _data;
  } else {
    nums = _data;
    key = _data.toString();
  }

  const { betAmount, maxBetSize, maxNumOfBets } = getState().rouletteConstants;

  if (
    !rouletteState[`${type}`][`${key}`].chip &&
    new BigNumber(betAmount).gt(maxBetSize)
  ) {
    await dispatch(changeHighlightColor("#dd0f30b3"));
    dispatch(setExceededMaxBetSize(true));
  }
  if (rouletteState.bets.length === maxNumOfBets) {
    await dispatch(changeHighlightColor("#dd0f30b3"));
  }

  nums.map(async item => {
    let tableNumbers = { ...rouletteState.tableNumbers };
    tableNumbers[item].highlight = true;
    await dispatch({ type: TOGGLE_CHIPS, payload: { tableNumbers } });
  });
  if (rouletteState[`${type}`][`${key}`].chip) {
    let newState = { ...rouletteState };
    newState[`${type}`][`${key}`].removeChip = true;
    await dispatch({ type: TOGGLE_CHIPS, payload: newState });
  }
};

export const unhighlight = (_data, type) => async (dispatch, getState) => {
  let nums, key;
  const rouletteState = getState().gameRoulette;
  if (typeof _data === "string") {
    nums = rouletteState[`${type}`][`${_data}`].numbers;
    key = _data;
  } else {
    nums = _data;
    key = _data.toString();
  }
  await dispatch(changeHighlightColor("#f4bd2421"));
  dispatch(setExceededMaxBetSize(false));
  nums.map(async item => {
    let tableNumbers = { ...rouletteState.tableNumbers };
    tableNumbers[item].highlight = false;
    await dispatch({ type: TOGGLE_CHIPS, payload: { tableNumbers } });
  });
  if (rouletteState[`${type}`][`${key}`].chip) {
    let newState = { ...rouletteState };
    newState[`${type}`][`${key}`].removeChip = false;
    await dispatch({ type: TOGGLE_CHIPS, payload: newState });
  }
};

export const toggleChips = (data, type) => async (dispatch, getState) => {
  const rouletteState = getState().gameRoulette;
  if (rouletteState.disableControls) return;
  if (rouletteState[`${type}`][`${data}`].chip === true) {
    let payload = {
      tableNumbers: { ...rouletteState.tableNumbers },
      commands: { ...rouletteState.commands },
      combinations: { ...rouletteState.combinations }
    };
    let newBets = [...rouletteState.bets];
    let currChipID = payload[`${type}`][`${data}`].chipID;
    newBets.splice(currChipID - 1, 1);
    newBets.map(({ type, data }) => {
      if (currChipID < payload[`${type}`][`${data}`].chipID)
        payload[`${type}`][`${data}`].chipID =
          payload[`${type}`][`${data}`].chipID - 1;
    });
    payload[`${type}`][`${data}`].chip = false;
    payload[`${type}`][`${data}`].chipID = 0;
    payload[`${type}`][`${data}`].removeChip = false;
    await dispatch({ type: TOGGLE_CHIPS, payload });
    await dispatch(toggleBets(newBets));
    return;
  }

  const { minBetSize } = getState().gamesConstants;
  const {
    betAmount,
    maxBetSize,
    maxNumOfBets
  } = getState().rouletteConstants;

  if (new BigNumber(betAmount).gt(maxBetSize)) {
    notify(
      `Invalid bet size, it needs to be between ${formatNumber(
        weiToEth(minBetSize),
        DP
      )} and ${formatNumber(weiToEth(maxBetSize), DP)} ETH.`,
      "error"
    )(dispatch);
    return;
  }
  if (rouletteState.bets.length + 1 > maxNumOfBets) {
    notify(`You've reached max number of ${maxNumOfBets} bets.`, "error")(
      dispatch
    );
    return;
  }

  let payload = {
    tableNumbers: { ...rouletteState.tableNumbers },
    commands: { ...rouletteState.commands },
    combinations: { ...rouletteState.combinations }
  };
  payload[`${type}`][`${data}`].chip = true;
  payload[`${type}`][`${data}`].chipID = rouletteState.bets.length + 1;
  const betArr = createBetArr(
    type === "commands" ? data : data.split(",").map(num => +num),
    type,
    betAmount
  );
  const betData = betArrToBetListData(data, type, betArr);
  let newBets = [
    ...rouletteState.bets,
    { type, data, betArr, betData, showControls: false }
  ];
  await dispatch({ type: TOGGLE_CHIPS, payload });
  await dispatch(toggleBets(newBets));
};

/**
 *
 * @return {Function} Gives a game state
 */
export const getRouletteGameState = () => async (dispatch, getState) => {
  dispatch({ type: GET_ROULETTE_GAME_STATE_REQUEST });
  const { firstBet } = getState().games;
  try {
    await dispatch(getRouletteRoundState());
    await dispatch(getRouletteContractConstValues());
    if (firstBet) await dispatch(getAccRouletteTotalBets());
    await dispatch(getPreviousRouletteRolls());
    await dispatch(setNumberOfWithdrawals());
    dispatch({ type: GET_ROULETTE_GAME_STATE_SUCCESS });
  } catch (e) {
    dispatch({ type: GET_ROULETTE_GAME_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

export const getAccRouletteTotalBets = () => async (dispatch, getState) => {
  dispatch({ type: GET_ACC_ROULETTE_TOTAL_BETS_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getTotalBetsFromRouletteContract(account);
    dispatch({ type: GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_ACC_ROULETTE_TOTAL_BETS_ERROR, payload: e.message });
  }
};

/**
 * Gets current round state and number
 *
 * @return {Function}
 */
export const getRouletteRoundState = () => async (dispatch, getState) => {
  dispatch({ type: GET_ROULETTE_ROUND_STATE_REQUEST });
  try {
    const { account } = getState().account;
    const { roundNumber, status } = await lastBetForPlayerFromRouletteContract(
      account,
      account,
    );
    let round = +roundNumber;

    if (status === "0") {
      dispatch({
        type: GET_ROULETTE_ROUND_STATE_SUCCESS,
        payload: { status: "open", round }
      });
    } else if (status === "1") {
      dispatch({
        type: GET_ROULETTE_ROUND_STATE_SUCCESS,
        payload: { status: "closed", round }
      });
    } else if (status === "2") {
      dispatch({
        type: GET_ROULETTE_ROUND_STATE_SUCCESS,
        payload: { status: "finished", round }
      });
      dispatch(setRouletteShowControls(true));
    }
  } catch (e) {
    dispatch({ type: GET_ROULETTE_ROUND_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

export const setExceededMaxBetSize = payload => dispatch => {
  dispatch({ type: EXCEEDED_MAX_BET_SIZE, payload });
};

export const setRoulettePaused = () => async (dispatch) => {
  try {
    const roulette = await getRouletteBetsAllowedFromContract();
    dispatch({ type: SET_ROULETTE_PAUSED, payload: !roulette });
  } catch (error) {
    console.log(error);
  }
}


export const listenToRoulettePaused = () => dispatch => {
  const callback = ({ bool }) => {
    dispatch({ type: SET_ROULETTE_PAUSED, payload: bool });
    if (!bool) {
      dispatch(getRouletteInitialData(false));
    }
  };
  listenToRoulettePausedFromContract(callback);
};

export const checkRouletteWinning = selectedNumber => async (
  dispatch,
  getState
) => {
  try {
    const { bets } = getState().gameRoulette;
    let totalWon = "0";

    const { hasWon, winPrize } = checkIfPlayerHasWonRouletteBet(
      selectedNumber,
      bets
    );

    const betHistory = !!bets.length
      ? [...bets].map(item => {
          const { hasWon, winPrize } = checkBetArrWinning(
            selectedNumber,
            item.betArr
          );
          item.hasWon = hasWon;
          item.winPrize = new BigNumber(winPrize).plus(item.betArr[0]).toString();
          return item;
        })
      : [];

    if (hasWon) {
      const amountPlaced = betHistory
        .map(item => (item.hasWon ? item.betArr[0] : "0"))
        .reduce((total, num) => new BigNumber(total).plus(num).toString());
      totalWon = new BigNumber(winPrize).plus(amountPlaced).toString();
    }

    dispatch(setRouletteLastWinPrize(totalWon));

    setTimeout(() => {
      const { activeGame } = getState().games;
      console.log(activeGame, activeGame === "roulette");
      if (activeGame === "roulette") {
        const { modalOpen, modalComponent } = getState().modal;
        if (
          modalOpen &&
          (modalComponent !== "WinLose" || modalComponent !== "ModalLoader")
        ) {
          dispatch(toggleModal());
        }

        dispatch(
          toggleModal("WinLose", {
            hasWon,
            winPrize: totalWon,
            betHistory,
            game: "roulette",
            isSide: true
          })
        );
        const { isAutoBetting } = getState().gameRoulette;
        if (isAutoBetting) setTimeout(() => {
          const { modalOpen } = getState().modal;
          if (modalOpen) dispatch(toggleModal());
        }, 2000);
      }
    }, 4000);
  } catch (e) {
    console.log(e);
  }
};

/**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForRouletteRoundStarted = () => (dispatch, getState) => {
  const callback = async item => {
    const { account } = getState().account;
    if (account.toUpperCase() !== item.player.toUpperCase()) return;
    const { bets } = getState().gameRoulette;
    dispatch({
      type: GET_ROULETTE_ROUND_STATE_SUCCESS,
      payload: { status: "open", round: item.round + 1 }
    });
    dispatch(setRouletteShowControls(false));
    dispatch(setRouletteDisableControls(true));

    const { betWithContractBonusBalance } = getState().games;
    await sendRouletteBetsFromApi({
      [`${item.round}`]: { bets, isBonus: betWithContractBonusBalance }
    });
  };
  listenToRoundStartedFromRouletteContract(callback);
};

export const setRouletteLastWinPrize = lastWinPrize => dispatch => {
  dispatch({ type: SET_ROULETTE_LAST_WIN_PRIZE, payload: lastWinPrize });
};

/**
 * Sets the last selected betting number
 *
 * @param selectedNumber {number}
 * @return {Function}
 */
export const setRouletteLastSelectedNumber = selectedNumber => dispatch => {
  dispatch({
    type: SET_ROULETTE_LAST_SELECTED_NUMBER,
    payload: selectedNumber
  });
};

/**
 * Makes a Event listener to check if a new round has finished
 *
 * @return {Function}
 */
export const listenForRouletteRoundFinished = () => (dispatch, getState) => {
  const callback = async item => {
    console.log("ROULETTE ROUND FINISHED CALLBACK");
    const { account } = getState().account;
    if (account.toUpperCase() !== item.player.toUpperCase()) return;

    dispatch(setRouletteLastSelectedNumber(+item.number));
    console.log(
      `ROUND FINISHED: ${item.round}, \nROUND RESULT: ${item.number}`
    );

    dispatch({
      type: GET_ROULETTE_ROUND_STATE_SUCCESS,
      payload: { status: "finished", round: +item.round }
    });

    const { activeGame } = getState().games;
    if (activeGame === "roulette") {
      const { account } = getState().account;
      const { bets } = getState().gameRoulette;
      const betSizes = [...bets].map(({ betArr }) => betArr[0]);
      const betChances = [...bets].map(({ betArr }) => {
        const { chance } = getChanceAndMultiplierDuplicatedFromContract(betArr);
        return chance;
      });
      const areDistributed = await areTokensDistributed(account);
      if (!areDistributed) {
        const tokenAmount = await calculateNumberOfTokensWonForRoulette(
          account,
          betSizes,
          betChances
        );
        dispatch(addBadTokenWinNotification(weiToEth(tokenAmount)));
      }
    }
    const { playerLevel } = getState().badToken;
    if (Number(playerLevel) < 100) dispatch(addNewLevelModal());
    dispatch(checkRouletteWinning(+item.number));

    setTimeout(() => {
      if (activeGame === "roulette") {
        dispatch(addRouletteRolls(item));
      }
      dispatch(getWalletBalance(account))
      dispatch(getPlayerLevel());
      dispatch(getUserBonusContractBalance());
      dispatch(getAccTotalBets());
      dispatch(getRouletteContractConstValues(false));
    }, 3000);
  };
  listenToRoundFinishedFromRouletteContract(callback);
};

export const initRouletteSpecificListeners = () => dispatch => {
  dispatch(listenToRoulettePaused());
  dispatch(listenForRouletteRoundStarted());
  dispatch(listenForRouletteRoundFinished());
  dispatch({ type: ROULETTE_LISTENERS_INITIATED });
};

export const getRouletteInitialData = (shouldGetBetsAllowed = true) => async (dispatch, getState) => {
  dispatch(getRouletteGameState());
  if (shouldGetBetsAllowed) dispatch(setRoulettePaused());
  const { rouletteListenersInitiated } = getState().gameRoulette;
  if (!rouletteListenersInitiated) dispatch(initRouletteSpecificListeners());
  dispatch(getBadTokenWinningProbability());
};
