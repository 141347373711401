import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseIcon from "../Decorative/Icons/CloseIcon";
import TxErrorIcon from "../Decorative/Icons/TxErrorIcon";
import TxSuccessIcon from "../Decorative/Icons/TxSuccessIcon";
import Loader from "../Loader/Loader";
import { closeNotification } from "../../actions/txNotifications";

import "./TxNotifications.scss";
import { weiToEth } from "../../services/ethereum/main";
import { DP } from "../../config/constants";
import { formatNumber } from "../../services/utils";

const TxNotifications = ({
  notifications,
  closeNotification,
  network,
  drawerRightOpen
}) => (
  <div
    className={`tx-notifications-wrapper${
      drawerRightOpen ? " tx-notifications-wrapper--drawerOpen" : ""
    }`}
  >
    {notifications.map(({ id, title, amount, description, type, tx, isEth }) => (
      <div
        className={`single-notification ${type} ${tx ? "has-tx" : ""}`}
        key={id}
      >
        <span
          onClick={() => {
            closeNotification(id);
          }}
        >
          <CloseIcon size={12} />
        </span>

        <div className="content">
          <span
            className={`status-wrapper${type === "loading" ? " loading" : ""}`}
          >
            {type === "error" && <TxErrorIcon />}
            {type === "success" && <TxSuccessIcon />}
            {type === "loading" && <Loader isFullScreen={false} />}
          </span>

          <div className="info-wrapper">
            <div className="title">{title}</div>
            {!tx && <div className="description">{description}</div>}

            {tx && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="description"
                href={isEth ? `https://etherscan.io/tx/${tx}` : `https://${
                  network === 3 ? "testnetv3-explorer." : "explorer."
                }matic.network/tx/${tx}`}
              >
                {description}
              </a>
            )}
            {amount && (
              <p className="amount">
                <span>{formatNumber(weiToEth(amount), DP)}</span> ETH
              </p>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

TxNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  closeNotification: PropTypes.func.isRequired,
  network: PropTypes.number.isRequired,
  drawerRightOpen: PropTypes.bool.isRequired
};

const mapStateToProps = ({ txNotifications, account, drawer }) => ({
  notifications: txNotifications.notifications,
  network: account.network,
  drawerRightOpen: drawer.drawerRightOpen
});

const mapDispatchToProps = {
  closeNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TxNotifications);
