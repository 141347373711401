import React from "react";

export default function Matic() {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="85" height="85" rx="42.5" fill="white"/>
      <path d="M35.7332 39.121L42.0099 52.8071L51.3327 47.5079H51.3506V36.9035L35.7332 39.121Z" fill="#2BBDF7"/>
      <path d="M63.3916 28.9518L57.5738 26.7999L51.3507 26.2992V36.9035V59.3401L60.6854 64.6453L68.1305 49.7074L63.4095 35.3358L63.3916 28.9518Z" fill="#2891F9"/>
      <path d="M62.8491 26.2992L60.6853 31.6043V64.5618L70.02 59.2567V26.2992H62.8491Z" fill="#2BBDF7"/>
      <path d="M60.6854 21L51.3507 26.2992L60.6854 31.6043L70.0201 26.2992L60.6854 21Z" fill="#2B6DEF"/>
      <path d="M42.01 42.2027L32.6753 31.9024L14 26.2992V59.2567L23.3347 64.5618L26.0111 46.3217L32.6753 47.5079L42.01 52.8071V42.2027Z" fill="#2891F9"/>
      <path d="M23.3347 21L14 26.2992L42.01 42.2027L46.2422 39.8065L51.3506 36.9035L23.3347 21Z" fill="#2B6DEF"/>
      <path d="M23.3347 42.2027V64.5618L32.6754 59.2567V47.5079L23.3347 42.2027Z" fill="#2BBDF7"/>
    </svg>
  );
}
