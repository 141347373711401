import React from "react";

export default function RollLogo({ width = 130 }) {
  return (
    <svg
      width={width}
      height={width / 4.47619047619}
      viewBox="0 0 94 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15998 15.1956C9.15998 16.6286 9.12487 18.0642 9.17168 19.496C9.20289 20.444 8.33423 21.1011 7.37844 20.6122C5.41225 19.6055 3.42006 18.6484 1.43956 17.6692C0.478573 17.1945 0.0143342 16.3926 0.00913267 15.3429C-0.00517162 12.6163 2.99438e-05 9.89108 0.00653189 7.16452C0.00783228 6.40171 0.561798 6.03139 1.25751 6.32478C3.54099 7.2871 5.82318 8.25463 8.10276 9.22607C8.80237 9.52337 9.15088 10.0736 9.15608 10.8286C9.16388 12.2838 9.15868 13.739 9.15868 15.1943C9.15868 15.1956 9.15998 15.1956 9.15998 15.1956ZM1.67623 9.57944C1.69704 10.0006 1.82578 10.4257 2.12227 10.796C2.29392 11.0099 2.50588 11.1181 2.77246 11.0646C3.02604 11.0138 3.16518 10.8247 3.25231 10.5861C3.42916 10.1036 3.33943 9.64725 3.12747 9.20521C2.9051 8.7423 2.53579 8.48412 2.2211 8.55584C1.9012 8.62756 1.68013 9.02526 1.67623 9.57944ZM5.00003 16.2505C5.02864 16.6912 5.15998 17.0993 5.43956 17.4449C5.59951 17.6431 5.81537 17.8009 6.07935 17.75C6.35244 17.6965 6.53579 17.484 6.59691 17.2206C6.75296 16.5464 6.62812 15.9271 6.13787 15.4185C5.82448 15.0938 5.33683 15.1786 5.14957 15.5907C5.05595 15.7941 4.98182 16.0131 5.00003 16.2505Z"
        fill="white"
      />
      <path
        d="M10.8439 15.2008C10.8439 13.7886 10.8556 12.3751 10.84 10.9629C10.8309 10.0828 11.2418 9.50249 12.026 9.16868C14.2483 8.22332 16.4733 7.28578 18.697 6.34563C19.4395 6.03138 19.9922 6.37432 19.9935 7.17494C20 9.91324 20.0039 12.6515 19.9883 15.3885C19.9817 16.506 19.42 17.2688 18.4148 17.7539C16.472 18.6901 14.537 19.6407 12.6124 20.6148C11.5994 21.1272 10.7945 20.3331 10.827 19.5038C10.8842 18.0708 10.8439 16.6351 10.8439 15.2008ZM15.0091 16.2048C14.9909 16.0953 14.9791 15.9649 14.944 15.841C14.8621 15.5515 14.7451 15.2777 14.4031 15.2229C14.0858 15.1721 13.8725 15.3585 13.6983 15.5906C13.3303 16.0848 13.2574 16.6521 13.407 17.2258C13.5617 17.8204 14.1287 17.9325 14.5461 17.4775C14.8699 17.1254 14.9635 16.6847 15.0091 16.2048ZM15.0104 11.2159C14.9896 11.092 14.9778 10.9394 14.9349 10.796C14.853 10.5157 14.7191 10.2627 14.3979 10.2145C14.0975 10.1688 13.8855 10.3422 13.7152 10.5652C13.3342 11.0659 13.2548 11.6397 13.4109 12.2264C13.5708 12.8237 14.1326 12.9293 14.5513 12.4677C14.866 12.1208 14.9609 11.6918 15.0104 11.2159ZM18.3303 14.5683C18.3225 14.3519 18.2808 14.1393 18.1885 13.9424C17.9961 13.533 17.6202 13.4352 17.2548 13.6934C16.7204 14.0715 16.4889 15.2008 16.8309 15.7667C17.052 16.1344 17.3992 16.197 17.7308 15.9258C18.1573 15.5763 18.2756 15.0873 18.3303 14.5683ZM18.329 9.88846C18.3277 9.64984 18.2886 9.44903 18.2054 9.25996C17.9961 8.78011 17.6293 8.69404 17.2301 9.02655C16.7373 9.4373 16.5188 10.3318 16.7659 10.9251C16.9778 11.4349 17.459 11.5275 17.8478 11.1285C18.1898 10.7751 18.2886 10.3266 18.329 9.88846Z"
        fill="white"
      />
      <path
        d="M9.89982 0.777771C10.4395 0.781683 10.9349 0.966844 11.4291 1.16113C13.5552 1.99827 15.6787 2.83932 17.8075 3.67124C18.0988 3.78468 18.3055 3.9555 18.3055 4.27627C18.3055 4.59052 18.1092 4.78351 17.8231 4.90347C15.4096 5.92186 12.9986 6.94416 10.5877 7.96515C10.2002 8.12945 9.80619 8.13205 9.41997 7.96906C6.99735 6.94416 4.57732 5.91403 2.1547 4.88913C1.87641 4.77177 1.70866 4.57618 1.69956 4.27758C1.69176 3.99853 1.8478 3.80685 2.10268 3.70644C4.5006 2.7663 6.89852 1.82615 9.29774 0.887303C9.49019 0.811674 9.69436 0.784291 9.89982 0.777771ZM10.1027 4.92173C10.4668 4.96737 10.8426 4.88522 11.1937 4.70136C11.4395 4.57227 11.6488 4.40927 11.6397 4.08589C11.6306 3.78338 11.4096 3.6582 11.1872 3.54997C10.5578 3.24485 9.90112 3.21486 9.23142 3.39219C8.99215 3.45609 8.75678 3.53432 8.57082 3.71427C8.26653 4.00896 8.27953 4.30757 8.62414 4.55271C9.05586 4.86044 9.55001 4.94259 10.1027 4.92173Z"
        fill="white"
      />
      <path
        d="M35.8018 10.516C36.6605 10.516 37.3418 10.2547 37.8458 9.73201C38.3498 9.20935 38.6018 8.57468 38.6018 7.82801C38.6018 7.06268 38.3592 6.49335 37.8738 6.12001C37.4072 5.72801 36.8005 5.53201 36.0538 5.53201H34.9338L34.4578 10.516H35.8018ZM38.9658 2.03201C40.9632 2.03201 42.5592 2.50801 43.7538 3.46001C44.9672 4.39335 45.5738 5.78402 45.5738 7.63201C45.5738 8.30401 45.4338 8.94801 45.1538 9.56401C44.8738 10.1613 44.5378 10.6467 44.1458 11.02C43.3245 11.7667 42.5685 12.2987 41.8778 12.616L41.4578 12.784L44.4818 19H39.5818L34.1498 13.54L33.6178 19H28.1578L26.4778 2.03201H38.9658Z"
        fill="white"
      />
      <path
        d="M46.4082 10.628C46.4082 7.69735 47.2575 5.46668 48.9562 3.93601C50.6735 2.40535 53.1188 1.64001 56.2922 1.64001C59.4842 1.64001 61.9295 2.40535 63.6282 3.93601C65.3455 5.46668 66.2042 7.69735 66.2042 10.628C66.2042 13.5587 65.2988 15.7333 63.4882 17.152C61.6962 18.5707 59.2975 19.28 56.2922 19.28C53.3055 19.28 50.9068 18.5707 49.0962 17.152C47.3042 15.7333 46.4082 13.5587 46.4082 10.628ZM58.7562 10.516C58.7562 6.74535 57.9348 4.86002 56.2922 4.86002C54.6682 4.86002 53.8562 6.74535 53.8562 10.516C53.8562 14.2867 54.6682 16.172 56.2922 16.172C57.9348 16.172 58.7562 14.2867 58.7562 10.516Z"
        fill="white"
      />
      <path
        d="M69.0061 19L67.3261 2.03201H76.1461L74.8861 14.66L79.9541 14.38L78.3581 19H69.0061Z"
        fill="white"
      />
      <path
        d="M82.76 19L81.08 2.03201H89.9L88.64 14.66L93.708 14.38L92.112 19H82.76Z"
        fill="white"
      />
    </svg>
  );
}
