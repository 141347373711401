import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { stripValue } from "../../../../../services/utils";
import {
  setRollBetValue,
  setRollGameSubType
} from "../../../../../actions/games/roll";
import BigNumber from "bignumber.js";

import BetData from "../../../Shared/BetData/BetData";

class Bet extends Component {
  constructor(props) {
    super(props);
    this.handleGameSubType = this.handleGameSubType.bind(this);
    this.handleBetValue = this.handleBetValue.bind(this);
    this.handleInputRange = this.handleInputRange.bind(this);
  }

  handleBetValue = e => {
    const { value } = e.target;
    const { setBetValue, gameType, inputRangeMin, inputRangeMax } = this.props;
    if (
      (gameType === 0 || gameType === 1) &&
      (value < inputRangeMin || value > inputRangeMax)
    )
      return;
    if (value.length > 2) {
      setBetValue(Number(stripValue(value)));
      return;
    }
    setBetValue(Number(value));
  };

  handleInputRange = e => {
    const { value } = e.target;
    const { setBetValue } = this.props;
    setBetValue(Number(value));
  };

  handleGameSubType = e => {
    const { value } = e.target.dataset;
    const { setGameSubType } = this.props;
    setGameSubType(Number(value));
  };

  render() {
    const {
      betValue,
      betAmount,
      gameType,
      winChance,
      winPrize,
      roundState,
      inputRangeMin,
      inputRangeMax,
      gameSubType,
      disabled
    } = this.props;

    const multiplier = new BigNumber(winPrize)
      .dividedBy(betAmount)
      .toString();

    return (
      <div className="main__section__body main__section__body--bet">
        {(gameType === 0 || gameType === 1 || gameType === 2) && (
          <span className="main__section__heading">Choose number:</span>
        )}
        {(gameType === 3 || gameType === 4) && (
          <span className="main__section__heading">Choose outcome:</span>
        )}
        {gameType === 3 && (
          <div className="bet__controls">
            <input
              disabled={disabled}
              className="bet__controls__radio"
              type="radio"
              id="even"
              data-value="0"
              name="even-odd"
              checked={gameSubType === 0}
              onChange={this.handleGameSubType}
            />
            <label className="bet__controls__label" htmlFor="even">
              Even
            </label>
            <input
              disabled={disabled}
              className="bet__controls__radio"
              type="radio"
              id="odd"
              data-value="1"
              name="even-odd"
              checked={gameSubType === 1}
              onChange={this.handleGameSubType}
            />
            <label className="bet__controls__label" htmlFor="odd">
              Odd
            </label>
          </div>
        )}

        {gameType === 4 && (
          <div className="bet__controls">
            <input
              disabled={disabled}
              className="bet__controls__radio"
              type="radio"
              id="dragon"
              data-value="0"
              name="dragon-tiger"
              checked={gameSubType === 0}
              onChange={this.handleGameSubType}
            />
            <label className="bet__controls__label" htmlFor="dragon">
              Dragon
            </label>
            <input
              disabled={disabled}
              className="bet__controls__radio"
              type="radio"
              id="tiger"
              data-value="1"
              name="dragon-tiger"
              checked={gameSubType === 1}
              onChange={this.handleGameSubType}
            />
            <label className="bet__controls__label" htmlFor="tiger">
              Tiger
            </label>
            <input
              disabled={disabled}
              className="bet__controls__radio"
              type="radio"
              id="tie"
              data-value="2"
              name="dragon-tiger"
              checked={gameSubType === 2}
              onChange={this.handleGameSubType}
            />
            <label className="bet__controls__label" htmlFor="tie">
              Tie
            </label>
          </div>
        )}

        <BetData
          game="roll"
          chance={winChance}
          prize={winPrize}
          multiplier={multiplier}
        />

        {(gameType === 0 || gameType === 1 || gameType === 2) && (
          <Fragment>
            <span className="bet__input--number">
              <div className="input-wrapper">
                <input
                  className="bet__value"
                  type="number"
                  value={betValue}
                  min={inputRangeMin}
                  max={inputRangeMax}
                  step="1"
                  onChange={this.handleBetValue}
                />
              </div>
            </span>
            <span className="bet__input--range">
              <input
                disabled={disabled}
                type="range"
                value={betValue}
                min={inputRangeMin}
                max={inputRangeMax}
                step="1"
                onChange={this.handleInputRange || roundState === "closed"}
              />
              <div className="bet__input--range__scale">
                <span>{inputRangeMin}</span>
                <span>25</span>
                <span>50</span>
                <span>75</span>
                <span>{inputRangeMax}</span>
              </div>
            </span>
          </Fragment>
        )}
      </div>
    );
  }
}

Bet.propTypes = {
  betValue: PropTypes.number.isRequired,
  betAmount: PropTypes.string.isRequired,
  winChance: PropTypes.number.isRequired,
  winPrize: PropTypes.any.isRequired,
  gameType: PropTypes.number.isRequired,
  gameSubType: PropTypes.number.isRequired,
  roundState: PropTypes.string.isRequired,
  inputRangeMin: PropTypes.number.isRequired,
  inputRangeMax: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameRoll }) => ({
  betValue: gameRoll.betValue,
  betAmount: gameRoll.betAmount,
  winChance: gameRoll.winChance,
  winPrize: gameRoll.winPrize,
  gameType: gameRoll.gameType,
  gameSubType: gameRoll.gameSubType,
  roundState: gameRoll.roundState,
  inputRangeMin: gameRoll.inputRangeMin,
  inputRangeMax: gameRoll.inputRangeMax
});

const mapDispatchToProps = {
  setBetValue: setRollBetValue,
  setGameSubType: setRollGameSubType
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bet);
