import React from "react";

const RollOver = () => {
  return (
    <div className="icon">
      <svg
        width="23"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.417 0L0 19.4088L22.8339 19.4088L11.417 0ZM11.417 3.37766L2.99424 17.6963L19.8397 17.6963L11.417 3.37766Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default RollOver;
