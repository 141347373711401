import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Wheel extends Component {
    constructor(props) {
        super(props);
        this.handleBallAnimationIteration = this.handleBallAnimationIteration.bind(this);
        this.handleFieldAnimationIteration = this.handleFieldAnimationIteration.bind(this);
    }

    handleBallAnimationIteration = e => {
        const { roundState, lastSelectedNumber } = this.props;
        if (roundState === "finished") {
            console.log("OVDE", roundState, lastSelectedNumber);
            e.target.classList.remove("spin");
            e.target.classList.add(`spinTo${lastSelectedNumber}`);
        }
    };

    handleFieldAnimationIteration = e => {
        const { roundState } = this.props;
        if (roundState === "finished") {
            e.target.classList.remove("spin");
            e.target.classList.add("spinToEnd");
        }
    };


    render() {
    return (
      <svg
        className="wheel--roulette"
        width="374"
        height="374"
        viewBox="0 0 374 374"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="wheel__fields spin"
           onAnimationIteration={this.handleFieldAnimationIteration}>
          <circle
            cx="186.699"
            cy="186.699"
            r="181.5"
            fill="url(#paint0_radial)"
            stroke="white"
            strokeWidth="6"
          />
          <path
            d="M365.001 195.095C364.526 205.184 363.195 215.214 361.024 225.078L320.929 216.25C322.601 208.655 323.626 200.932 323.991 193.164L365.001 195.095Z"
            fill="#42A8DF"
          />
          <path
            d="M361.024 225.078C358.852 234.941 355.847 244.603 352.042 253.959L314.013 238.489C316.943 231.285 319.257 223.846 320.929 216.25L361.024 225.078Z"
            fill="#CC2028"
          />
          <path
            d="M352.042 253.959C348.236 263.314 343.643 272.33 338.312 280.909L303.441 259.24C307.546 252.635 311.082 245.693 314.013 238.489L352.042 253.959Z"
            fill="#42A8DF"
          />
          <path
            d="M338.312 280.909C332.982 289.487 326.933 297.598 320.23 305.154L289.518 277.909C294.679 272.091 299.337 265.846 303.441 259.24L338.312 280.909Z"
            fill="#CC2028"
          />
          <path
            d="M320.23 305.154C313.528 312.709 306.196 319.682 298.314 325.998L272.643 293.959C278.712 289.096 284.357 283.727 289.518 277.909L320.23 305.154Z"
            fill="#42A8DF"
          />
          <path
            d="M298.314 325.998C290.432 332.313 282.028 337.948 273.193 342.842L253.3 306.929C260.103 303.161 266.573 298.822 272.643 293.959L298.314 325.998Z"
            fill="#CC2028"
          />
          <path
            d="M273.193 342.842C264.358 347.737 255.124 351.872 245.59 355.204L232.045 316.448C239.386 313.882 246.497 310.698 253.3 306.929L273.193 342.842Z"
            fill="#42A8DF"
          />
          <path
            d="M245.589 355.204C236.055 358.536 226.255 361.053 216.295 362.728L209.488 322.241C217.157 320.952 224.703 319.014 232.045 316.448L245.589 355.204Z"
            fill="#CC2028"
          />
          <path
            d="M216.295 362.728C206.335 364.403 196.25 365.229 186.15 365.198L186.276 324.143C194.053 324.167 201.818 323.531 209.488 322.241L216.295 362.728Z"
            fill="#42A8DF"
          />
          <path
            d="M186.15 365.198C176.05 365.167 165.971 364.279 156.021 362.543L163.077 322.099C170.738 323.435 178.499 324.119 186.276 324.143L186.15 365.198Z"
            fill="#CC2028"
          />
          <path
            d="M156.021 362.543C146.072 360.807 136.287 358.23 126.773 354.839L140.556 316.167C147.882 318.778 155.416 320.762 163.077 322.099L156.021 362.543Z"
            fill="#42A8DF"
          />
          <path
            d="M126.773 354.839C117.26 351.448 108.051 347.256 99.246 342.308L119.36 306.518C126.14 310.328 133.231 313.556 140.556 316.167L126.773 354.839Z"
            fill="#CC2028"
          />
          <path
            d="M99.246 342.308C90.4412 337.36 82.0723 331.673 74.2293 325.309L100.097 293.429C106.136 298.329 112.58 302.708 119.36 306.518L99.246 342.308Z"
            fill="#42A8DF"
          />
          <path
            d="M74.2293 325.309C66.3864 318.945 59.0977 311.927 52.4418 304.331L83.3209 277.275C88.4459 283.125 94.0582 288.529 100.097 293.429L74.2293 325.309Z"
            fill="#CC2028"
          />
          <path
            d="M52.4417 304.331C45.7858 296.734 39.7866 288.586 34.5088 279.975L69.5125 258.522C73.5764 265.152 78.1958 271.426 83.3208 277.275L52.4417 304.331Z"
            fill="#42A8DF"
          />
          <path
            d="M34.5088 279.975C29.231 271.364 24.6936 262.32 20.9454 252.941L59.0686 237.706C61.9547 244.927 65.4485 251.891 69.5125 258.522L34.5088 279.975Z"
            fill="#CC2028"
          />
          <path
            d="M20.9453 252.941C17.1972 243.563 14.2518 233.883 12.1408 224.006L52.2891 215.425C53.9145 223.03 56.1825 230.484 59.0686 237.706L20.9453 252.941Z"
            fill="#42A8DF"
          />
          <path
            d="M12.1408 224.006C10.0299 214.129 8.76105 204.091 8.34797 193.999L49.3686 192.32C49.6867 200.09 50.6637 207.82 52.2891 215.425L12.1408 224.006Z"
            fill="#CC2028"
          />
          <path
            d="M8.34797 193.999C7.9349 183.907 8.37907 173.799 9.67571 163.783L50.391 169.053C49.3926 176.766 49.0506 184.549 49.3686 192.32L8.34797 193.999Z"
            fill="#42A8DF"
          />
          <path
            d="M9.67572 163.783C10.9724 153.766 13.1168 143.878 16.0859 134.224L55.3269 146.293C53.0406 153.727 51.3894 161.341 50.391 169.053L9.67572 163.783Z"
            fill="#CC2028"
          />
          <path
            d="M16.0859 134.224C19.0551 124.57 22.8382 115.186 27.3946 106.172L64.0345 124.693C60.5261 131.634 57.6131 138.86 55.3269 146.293L16.0859 134.224Z"
            fill="#42A8DF"
          />
          <path
            d="M27.3946 106.172C31.951 97.1585 37.2642 88.5477 43.277 80.4325L76.264 104.874C71.6341 111.122 67.5429 117.753 64.0345 124.693L27.3946 106.172Z"
            fill="#CC2028"
          />
          <path
            d="M43.277 80.4325C49.2898 72.3173 55.9805 64.7271 63.2771 57.7436L91.6641 87.4033C86.0457 92.7806 80.8938 98.625 76.264 104.874L43.277 80.4325Z"
            fill="#42A8DF"
          />
          <path
            d="M63.2772 57.7436C70.5738 50.7601 78.4499 44.4085 86.8208 38.7571L109.793 72.7837C103.347 77.1352 97.2825 82.0259 91.6641 87.4032L63.2772 57.7436Z"
            fill="#CC2028"
          />
          <path
            d="M86.8209 38.7571C95.1918 33.1058 104.027 28.1751 113.232 24.0182L130.129 61.4347C123.042 64.6355 116.238 68.4321 109.793 72.7836L86.8209 38.7571Z"
            fill="#42A8DF"
          />
          <path
            d="M113.232 24.0182C122.437 19.8613 131.978 16.4931 141.752 13.95L152.09 53.6821C144.564 55.6404 137.217 58.2339 130.129 61.4347L113.232 24.0182Z"
            fill="#CC2028"
          />
          <path
            d="M141.753 13.9499C151.527 11.4068 161.5 9.69779 171.563 8.84141L175.045 49.7486C167.296 50.408 159.617 51.7239 152.09 53.6821L141.753 13.9499Z"
            fill="#42A8DF"
          />
          <path
            d="M171.564 8.84139C181.627 7.98502 191.745 7.98432 201.809 8.8393L198.334 49.7469C190.585 49.0886 182.794 49.0891 175.045 49.7486L171.564 8.84139Z"
            fill="#F4BD24"
          />
          <path
            d="M201.809 8.8393C211.873 9.69429 221.846 11.4019 231.621 13.9437L221.289 53.6773C213.762 51.7201 206.083 50.4053 198.334 49.7469L201.809 8.8393Z"
            fill="#CC2028"
          />
          <path
            d="M231.621 13.9437C241.396 16.4855 250.937 19.8523 260.143 24.008L243.25 61.4268C236.162 58.227 228.815 55.6345 221.289 53.6773L231.621 13.9437Z"
            fill="#42A8DF"
          />
          <path
            d="M260.143 24.008C269.348 28.1637 278.184 33.0931 286.556 38.7433L263.589 72.773C257.143 68.4224 250.339 64.6267 243.251 61.4269L260.143 24.008Z"
            fill="#CC2028"
          />
          <path
            d="M286.556 38.7434C294.928 44.3935 302.805 50.744 310.102 57.7265L281.719 87.3901C276.1 82.0136 270.035 77.1237 263.589 72.7731L286.556 38.7434Z"
            fill="#42A8DF"
          />
          <path
            d="M310.102 57.7266C317.4 64.709 324.092 72.2983 330.106 80.4126L297.122 104.858C292.491 98.6103 287.339 92.7666 281.719 87.3901L310.102 57.7266Z"
            fill="#CC2028"
          />
          <path
            d="M330.106 80.4126C336.119 88.5269 341.434 97.137 345.991 106.15L309.354 124.676C305.845 117.736 301.753 111.106 297.122 104.858L330.106 80.4126Z"
            fill="#42A8DF"
          />
          <path
            d="M345.992 106.15C350.549 115.164 354.334 124.547 357.304 134.201L318.065 146.275C315.778 138.842 312.864 131.617 309.354 124.676L345.992 106.15Z"
            fill="#CC2028"
          />
          <path
            d="M357.304 134.201C360.275 143.854 362.42 153.742 363.718 163.758L323.004 169.034C322.004 161.322 320.352 153.708 318.065 146.275L357.304 134.201Z"
            fill="#42A8DF"
          />
          <path
            d="M363.702 163.631C365.065 174.094 365.499 184.657 364.996 195.196L323.988 193.241C324.375 185.126 324.041 176.993 322.991 168.937L363.702 163.631Z"
            fill="#CC2028"
          />
          <path
            d="M317.054 192.837C316.707 200.213 315.734 207.546 314.147 214.757L284.834 208.304C286.056 202.751 286.805 197.105 287.072 191.425L317.054 192.837Z"
            fill="#42A8DF"
          />
          <path
            d="M314.147 214.757C312.559 221.969 310.362 229.032 307.58 235.872L279.777 224.562C281.919 219.295 283.611 213.856 284.834 208.304L314.147 214.757Z"
            fill="#CC2028"
          />
          <path
            d="M307.58 235.872C304.797 242.712 301.44 249.303 297.542 255.575L272.048 239.733C275.049 234.904 277.635 229.829 279.777 224.562L307.58 235.872Z"
            fill="#42A8DF"
          />
          <path
            d="M297.542 255.575C293.645 261.847 289.223 267.776 284.323 273.3L261.869 253.382C265.642 249.128 269.047 244.563 272.048 239.733L297.542 255.575Z"
            fill="#CC2028"
          />
          <path
            d="M284.323 273.3C279.422 278.824 274.062 283.922 268.3 288.539L249.532 265.116C253.969 261.561 258.096 257.635 261.869 253.382L284.323 273.3Z"
            fill="#42A8DF"
          />
          <path
            d="M268.3 288.539C262.537 293.156 256.394 297.276 249.934 300.854L235.39 274.598C240.364 271.843 245.095 268.671 249.532 265.116L268.3 288.539Z"
            fill="#CC2028"
          />
          <path
            d="M249.934 300.854C243.475 304.432 236.724 307.456 229.753 309.892L219.851 281.557C225.218 279.681 230.417 277.353 235.39 274.598L249.934 300.854Z"
            fill="#42A8DF"
          />
          <path
            d="M229.753 309.892C222.783 312.328 215.618 314.168 208.336 315.392L203.359 285.793C208.966 284.85 214.483 283.433 219.851 281.557L229.753 309.892Z"
            fill="#CC2028"
          />
          <path
            d="M208.336 315.392C201.054 316.617 193.682 317.221 186.298 317.198L186.39 287.183C192.076 287.201 197.752 286.735 203.359 285.793L208.336 315.392Z"
            fill="#42A8DF"
          />
          <path
            d="M186.298 317.198C178.914 317.175 171.545 316.526 164.271 315.257L169.429 285.688C175.03 286.666 180.704 287.166 186.39 287.183L186.298 317.198Z"
            fill="#CC2028"
          />
          <path
            d="M164.271 315.257C156.997 313.988 149.843 312.104 142.888 309.625L152.964 281.352C158.32 283.261 163.828 284.711 169.429 285.688L164.271 315.257Z"
            fill="#42A8DF"
          />
          <path
            d="M142.888 309.625C135.932 307.146 129.2 304.081 122.763 300.463L137.468 274.298C142.425 277.083 147.609 279.443 152.964 281.352L142.888 309.625Z"
            fill="#CC2028"
          />
          <path
            d="M122.763 300.463C116.326 296.846 110.207 292.688 104.473 288.036L123.385 264.728C127.8 268.311 132.511 271.512 137.468 274.298L122.763 300.463Z"
            fill="#42A8DF"
          />
          <path
            d="M104.473 288.036C98.7393 283.383 93.4106 278.252 88.5445 272.699L111.12 252.919C114.867 257.195 118.97 261.146 123.385 264.728L104.473 288.036Z"
            fill="#CC2028"
          />
          <path
            d="M88.5444 272.699C83.6783 267.145 79.2924 261.188 75.4338 254.892L101.025 239.208C103.996 244.055 107.373 248.642 111.12 252.919L88.5444 272.699Z"
            fill="#42A8DF"
          />
          <path
            d="M75.4338 254.892C71.5752 248.597 68.2579 241.985 65.5177 235.128L93.3893 223.989C95.4993 229.269 98.0536 234.36 101.025 239.208L75.4338 254.892Z"
            fill="#CC2028"
          />
          <path
            d="M65.5177 235.128C62.7774 228.271 60.624 221.195 59.0807 213.974L88.4329 207.7C89.6212 213.26 91.2793 218.71 93.3893 223.989L65.5177 235.128Z"
            fill="#42A8DF"
          />
          <path
            d="M59.0807 213.974C57.5375 206.753 56.6098 199.414 56.3078 192.036L86.2977 190.808C86.5302 196.489 87.2445 202.14 88.4329 207.7L59.0807 213.974Z"
            fill="#CC2028"
          />
          <path
            d="M56.3078 192.036C56.0058 184.658 56.3306 177.268 57.2785 169.945L87.0451 173.798C86.3152 179.437 86.0652 185.127 86.2977 190.808L56.3078 192.036Z"
            fill="#42A8DF"
          />
          <path
            d="M57.2785 169.945C58.2265 162.622 59.7943 155.393 61.965 148.335L90.6537 157.159C88.9823 162.593 87.7751 168.16 87.0451 173.798L57.2785 169.945Z"
            fill="#CC2028"
          />
          <path
            d="M61.965 148.335C64.1357 141.277 66.9015 134.416 70.2326 127.826L97.0198 141.367C94.4549 146.441 92.3252 151.724 90.6537 157.159L61.965 148.335Z"
            fill="#42A8DF"
          />
          <path
            d="M70.2327 127.826C73.5638 121.237 77.4482 114.941 81.8441 109.008L105.961 126.877C102.576 131.445 99.5848 136.293 97.0198 141.367L70.2327 127.826Z"
            fill="#CC2028"
          />
          <path
            d="M81.8442 109.008C86.2401 103.075 91.1316 97.5262 96.4661 92.4206L117.22 114.105C113.112 118.036 109.346 122.309 105.961 126.877L81.8442 109.008Z"
            fill="#42A8DF"
          />
          <path
            d="M96.4661 92.4206C101.801 87.315 107.559 82.6714 113.679 78.5397L130.473 103.416C125.761 106.598 121.327 110.173 117.22 114.105L96.4661 92.4206Z"
            fill="#CC2028"
          />
          <path
            d="M113.679 78.5397C119.799 74.408 126.258 70.8033 132.988 67.7642L145.341 95.1191C140.159 97.4592 135.186 100.235 130.473 103.416L113.679 78.5397Z"
            fill="#42A8DF"
          />
          <path
            d="M132.988 67.7642C139.717 64.7251 146.693 62.2627 153.839 60.4034L161.397 89.4513C155.894 90.8829 150.523 92.779 145.341 95.1191L132.988 67.7642Z"
            fill="#CC2028"
          />
          <path
            d="M153.839 60.4033C160.985 58.5441 168.276 57.2946 175.633 56.6685L178.178 86.5755C172.513 87.0575 166.899 88.0196 161.397 89.4512L153.839 60.4033Z"
            fill="#42A8DF"
          />
          <path
            d="M175.634 56.6685C182.991 56.0424 190.388 56.0419 197.746 56.667L195.205 86.5743C189.54 86.093 183.844 86.0934 178.179 86.5754L175.634 56.6685Z"
            fill="#F4BD24"
          />
          <path
            d="M197.746 56.667C205.103 57.2921 212.394 58.5405 219.541 60.3988L211.987 89.4477C206.484 88.0168 200.87 87.0556 195.205 86.5743L197.746 56.667Z"
            fill="#CC2028"
          />
          <path
            d="M219.541 60.3988C226.687 62.2571 233.663 64.7185 240.393 67.7567L228.043 95.1133C222.861 92.774 217.49 90.8786 211.987 89.4477L219.541 60.3988Z"
            fill="#42A8DF"
          />
          <path
            d="M240.393 67.7568C247.123 70.7949 253.583 74.3988 259.704 78.5296L242.912 103.408C238.2 100.228 233.225 97.4528 228.043 95.1134L240.393 67.7568Z"
            fill="#CC2028"
          />
          <path
            d="M259.704 78.5296C265.824 82.6604 271.583 87.3032 276.918 92.4081L256.168 114.095C252.06 110.164 247.625 106.589 242.912 103.408L259.704 78.5296Z"
            fill="#42A8DF"
          />
          <path
            d="M276.918 92.4081C282.253 97.5129 287.146 103.061 291.542 108.994L267.428 126.866C264.043 122.298 260.276 118.026 256.168 114.095L276.918 92.4081Z"
            fill="#CC2028"
          />
          <path
            d="M291.542 108.994C295.939 114.926 299.824 121.221 303.156 127.81L276.371 141.355C273.805 136.281 270.814 131.434 267.428 126.866L291.542 108.994Z"
            fill="#42A8DF"
          />
          <path
            d="M303.156 127.81C306.489 134.4 309.255 141.26 311.427 148.318L282.739 157.145C281.067 151.711 278.937 146.429 276.371 141.355L303.156 127.81Z"
            fill="#CC2028"
          />
          <path
            d="M311.427 148.318C313.599 155.375 315.167 162.604 316.116 169.927L286.35 173.784C285.62 168.146 284.412 162.58 282.739 157.145L311.427 148.318Z"
            fill="#42A8DF"
          />
          <path
            d="M316.104 169.834C317.101 177.483 317.418 185.206 317.051 192.911L287.07 191.482C287.352 185.549 287.109 179.603 286.341 173.713L316.104 169.834Z"
            fill="#CC2028"
          />
          <path
            d="M186.299 28.3033C186.16 27.843 185.88 27.6124 185.46 27.6114C185.04 27.6105 184.755 27.8399 184.604 28.2995C184.463 28.7592 184.391 29.4691 184.389 30.4291C184.385 32.2791 184.738 33.2048 185.448 33.2064C186.158 33.208 186.515 32.2838 186.519 30.4338C186.521 29.4738 186.448 28.7636 186.299 28.3033ZM188.716 33.9036C187.815 34.7317 186.724 35.1442 185.444 35.1414C184.164 35.1386 183.075 34.7212 182.176 33.8892C181.278 33.0472 180.831 31.9012 180.834 30.4512C180.837 29.0012 181.255 27.8471 182.087 26.9889C182.919 26.1208 184.045 25.6883 185.465 25.6914C186.885 25.6946 188.009 26.1321 188.837 27.0039C189.665 27.8657 190.077 29.0216 190.074 30.4716C190.071 31.9216 189.618 33.0656 188.716 33.9036Z"
            fill="white"
          />
          <path
            d="M159.364 38.2906L151.964 39.4164L151.745 37.978C151.819 37.8656 152.023 37.5766 152.356 37.1111C152.69 36.6456 152.939 36.2842 153.102 36.0267C153.273 35.7578 153.48 35.4229 153.723 35.0219C154.178 34.2243 154.368 33.5784 154.293 33.0841C154.18 32.3426 153.758 32.0275 153.026 32.1388C152.601 32.2035 152.157 32.3773 151.694 32.6601L151.031 31.092C151.409 30.7817 151.9 30.4894 152.506 30.2152C153.12 29.9296 153.664 29.7507 154.139 29.6785C154.623 29.6048 155.07 29.5823 155.479 29.6112C155.898 29.6385 156.305 29.7231 156.701 29.8652C157.107 30.0058 157.447 30.2474 157.722 30.5899C157.995 30.9226 158.169 31.3362 158.244 31.8305C158.455 33.2852 157.514 34.8799 155.422 36.6144L155.542 36.8085L158.946 35.5472L159.364 38.2906ZM165.57 34.251C165.53 33.9841 165.409 33.7851 165.207 33.654C165.003 33.513 164.768 33.4628 164.501 33.5034C164.234 33.544 163.991 33.6417 163.772 33.7964C163.562 33.9496 163.425 34.1425 163.359 34.3751C163.398 34.6321 163.548 34.8521 163.808 35.035C164.067 35.208 164.35 35.2712 164.656 35.2246C164.973 35.1764 165.214 35.0689 165.381 34.9019C165.548 34.7349 165.611 34.5179 165.57 34.251ZM166.57 30.3513C165.889 30.192 165.246 30.1582 164.643 30.25C164.05 30.3402 163.587 30.5877 163.254 30.9924C162.921 31.3971 162.794 31.8564 162.872 32.3705C162.902 32.3052 162.95 32.222 163.016 32.121C163.082 32.02 163.266 31.8705 163.57 31.6726C163.883 31.4732 164.248 31.3419 164.663 31.2787C165.671 31.1253 166.523 31.2688 167.217 31.7094C167.921 32.1485 168.344 32.8327 168.485 33.762C168.625 34.6815 168.376 35.5033 167.737 36.2276C167.109 36.9503 166.137 37.4117 164.822 37.6118C163.517 37.8103 162.372 37.5951 161.386 36.9663C160.409 36.326 159.806 35.2545 159.577 33.7518C159.347 32.2392 159.608 30.9958 160.36 30.0216C161.112 29.0474 162.101 28.4671 163.327 28.2806C164.553 28.094 165.747 28.231 166.909 28.6914L166.57 30.3513Z"
            fill="white"
          />
          <path
            d="M134.567 41.7391C134.249 40.8441 133.397 40.643 132.012 41.1359L131.758 41.2264L132.189 38.6368L130.005 38.9844L129.899 37.2549L135.905 35.1175L135.279 38.4606C135.333 38.4627 135.409 38.4675 135.507 38.475C135.602 38.4731 135.778 38.5059 136.036 38.5735C136.29 38.6316 136.531 38.7264 136.758 38.8578C136.982 38.9798 137.218 39.1822 137.468 39.4651C137.714 39.7384 137.905 40.0683 138.043 40.4545C138.378 41.3966 138.29 42.3141 137.78 43.2068C137.267 44.0901 136.492 44.7162 135.455 45.085C134.928 45.2727 134.379 45.3724 133.81 45.3838C133.247 45.3825 132.816 45.3343 132.517 45.2391L132.059 45.1155L132.255 43.5174C132.797 43.6109 133.276 43.5838 133.69 43.4363C134.105 43.2888 134.388 43.0712 134.541 42.7834C134.703 42.4923 134.711 42.1442 134.567 41.7391Z"
            fill="white"
          />
          <path
            d="M106.436 54.1268C105.965 53.3021 105.091 53.2546 103.815 53.9842L103.58 54.1182L103.548 51.493L101.459 52.221L101.049 50.5373L106.583 47.3731L106.558 50.7742C106.612 50.7667 106.687 50.758 106.785 50.7481C106.878 50.7294 107.057 50.7306 107.323 50.7517C107.583 50.764 107.837 50.8148 108.084 50.904C108.326 50.9846 108.594 51.1421 108.89 51.3764C109.18 51.6021 109.427 51.8929 109.63 52.2488C110.127 53.117 110.202 54.0354 109.858 55.0042C109.508 55.9643 108.856 56.7174 107.901 57.2633C107.415 57.5413 106.893 57.7361 106.335 57.8479C105.78 57.946 105.347 57.9747 105.036 57.9338L104.564 57.893L104.474 56.2855C105.025 56.2817 105.491 56.1705 105.873 55.9521C106.255 55.7338 106.495 55.4695 106.595 55.1594C106.702 54.8443 106.65 54.5001 106.436 54.1268ZM116.688 48.3864C117.189 49.2632 117.248 50.1625 116.865 51.0841C116.491 52.0009 115.835 52.7272 114.897 53.2633C114.429 53.5313 113.92 53.7187 113.37 53.8255C112.821 53.9323 112.393 53.9696 112.086 53.9374L111.614 53.8966L111.509 52.2631C112.066 52.2096 112.51 52.0886 112.839 51.9C113.169 51.7114 113.373 51.4738 113.451 51.1873C113.533 50.8871 113.485 50.5807 113.306 50.2682C113.122 49.947 112.877 49.73 112.571 49.6172C112.273 49.4994 111.966 49.4561 111.65 49.4872C110.994 49.5283 110.449 49.6437 110.017 49.8336L109.721 49.9509L108.788 46.1129L113.723 43.2912L114.133 44.9748L112.542 46.8171L112.624 46.9604C113.496 46.7729 114.29 46.7913 115.006 47.0156C115.297 47.1024 115.597 47.2648 115.906 47.5029C116.224 47.736 116.485 48.0305 116.688 48.3864Z"
            fill="white"
          />
          <path
            d="M86.949 73.0334L81.679 68.0864L80.5334 68.7014L79.3809 67.4938L84.0312 63.6551L89.0891 71.2668L86.949 73.0334ZM96.399 65.2326L90.6266 69.9976L89.7004 68.8755C89.7061 68.7411 89.7327 68.3885 89.7802 67.8177C89.8277 67.2468 89.8553 66.8091 89.8631 66.5044C89.8723 66.1856 89.878 65.7919 89.8802 65.3233C89.8614 64.4052 89.6929 63.7533 89.3746 63.3677C88.8971 62.7893 88.373 62.7357 87.8024 63.2068C87.4707 63.4805 87.179 63.8575 86.9271 64.3377L85.5528 63.3326C85.7175 62.8725 85.9894 62.3693 86.3684 61.823C86.7487 61.2627 87.1239 60.8298 87.4941 60.5242C87.872 60.2123 88.2438 59.9637 88.6096 59.7784C88.9831 59.5868 89.3761 59.4504 89.7888 59.3691C90.2092 59.2814 90.6251 59.3141 91.0367 59.4671C91.4419 59.6124 91.8037 59.8778 92.122 60.2634C93.0501 61.4034 93.0613 63.2549 92.1557 65.8179L92.3588 65.9225L94.6324 63.0926L96.399 65.2326Z"
            fill="white"
          />
          <path
            d="M71.9488 88.0755L66.8483 93.5537L65.7834 92.5622C65.7716 92.4282 65.7519 92.0751 65.7244 91.503C65.6969 90.9308 65.6671 90.4932 65.635 90.1901C65.6025 89.8729 65.5567 89.4818 65.4976 89.0169C65.3591 88.1091 65.1068 87.4849 64.7409 87.1442C64.192 86.6331 63.6654 86.6484 63.1611 87.19C62.8681 87.5047 62.6281 87.9166 62.4411 88.4256L60.9473 87.6086C61.0505 87.1309 61.2543 86.5965 61.5587 86.0054C61.8625 85.4002 62.178 84.922 62.5051 84.5707C62.839 84.212 63.1752 83.917 63.5136 83.6856C63.8589 83.4468 64.2307 83.2602 64.6293 83.1257C65.0346 82.9839 65.4512 82.9619 65.8793 83.0598C66.3 83.1509 66.6933 83.3669 67.0593 83.7076C68.1283 84.7166 68.3813 86.5507 67.8182 89.21L68.0333 89.2873L69.9178 86.1846L71.9488 88.0755ZM70.4668 82.9534C69.1915 83.1051 68.2794 82.9254 67.7305 82.4143C67.1816 81.9032 66.9785 81.2702 67.1214 80.515C67.2711 79.7526 67.7547 78.9323 68.5725 78.054C69.397 77.1684 70.1593 76.6211 70.8594 76.4122C71.5522 76.1964 72.1401 76.3134 72.6231 76.7631C72.9305 77.0493 73.1269 77.3961 73.2122 77.8034C73.2901 78.204 73.2659 78.7143 73.1395 79.3344C74.5553 78.9583 75.5779 79.0633 76.2074 79.6493C76.8368 80.2354 77.0721 80.9805 76.9134 81.8848C76.7541 82.7749 76.2418 83.6847 75.3764 84.6142C74.5178 85.5364 73.6965 86.1176 72.9126 86.3579C72.1282 86.584 71.4287 86.4109 70.8139 85.8385C70.1918 85.2593 70.0761 84.2976 70.4668 82.9534ZM72.1964 84.1334C72.3867 84.3106 72.6122 84.3839 72.8729 84.3534C73.1263 84.316 73.3484 84.1949 73.5392 83.99C73.73 83.785 73.8177 83.5661 73.8023 83.3331C73.7937 83.0929 73.7199 82.908 73.5808 82.7785C73.4345 82.6423 73.2553 82.5779 73.0433 82.5855C72.824 82.5862 72.5106 82.6293 72.1033 82.7146C71.9165 83.4288 71.9476 83.9017 72.1964 84.1334ZM71.4249 78.5783C71.0883 78.2649 70.7394 78.3021 70.3782 78.69C70.201 78.8803 70.1123 79.071 70.1121 79.262C70.1118 79.4531 70.1959 79.627 70.3642 79.7837C70.5252 79.9336 70.9112 79.9446 71.5222 79.8166C71.6989 79.216 71.6665 78.8032 71.4249 78.5783Z"
            fill="white"
          />
          <path
            d="M52.0695 99.6833L54.1756 101.04L57.9089 107.405L56.3252 109.864L51.9332 104.466L50.8009 107.056L48.2605 105.598L52.0695 99.6833Z"
            fill="white"
          />
          <path
            d="M44.9429 132.16L41.9696 139.029L40.6343 138.451C40.5783 138.329 40.4416 138.003 40.2242 137.473C40.0069 136.943 39.8324 136.541 39.7007 136.266C39.5639 135.978 39.3899 135.624 39.1787 135.206C38.7444 134.397 38.2978 133.893 37.8389 133.694C37.1506 133.397 36.6595 133.587 36.3655 134.266C36.1947 134.661 36.1064 135.129 36.1005 135.672L34.4194 135.402C34.3568 134.917 34.37 134.345 34.4591 133.686C34.5429 133.014 34.6802 132.458 34.8709 132.017C35.0655 131.568 35.2836 131.177 35.5251 130.846C35.7705 130.505 36.0585 130.205 36.3891 129.945C36.7236 129.676 37.1089 129.516 37.545 129.465C37.9719 129.41 38.4148 129.482 38.8737 129.68C40.2188 130.273 41.0709 131.917 41.4301 134.612L41.6586 134.612L42.3963 131.058L44.9429 132.16ZM40.8234 126.347C41.0712 126.454 41.3089 126.459 41.5364 126.361C41.7547 126.259 41.9175 126.085 42.0248 125.837C42.132 125.589 42.1763 125.336 42.1575 125.077C42.1427 124.809 42.0542 124.586 41.8921 124.406C41.6535 124.303 41.3921 124.315 41.108 124.443C40.8148 124.567 40.6046 124.775 40.4775 125.069C40.3543 125.353 40.3192 125.611 40.3722 125.841C40.4253 126.071 40.5756 126.239 40.8234 126.347ZM43.5979 129.264C44.0947 128.77 44.4623 128.249 44.7006 127.698C44.943 127.138 44.981 126.61 44.8147 126.113C44.6484 125.616 44.3267 125.264 43.8495 125.058C43.8886 125.118 43.9336 125.203 43.9845 125.312C44.0353 125.421 44.062 125.662 44.0646 126.033C44.0711 126.396 43.991 126.77 43.8242 127.155C43.419 128.091 42.8435 128.741 42.0976 129.105C41.3558 129.459 40.5581 129.452 39.7046 129.082C38.842 128.709 38.2712 128.064 37.9922 127.148C37.7171 126.222 37.8418 125.154 38.3662 123.943C38.8945 122.722 39.6894 121.867 40.7508 121.379C41.807 120.877 43.0372 120.93 44.4413 121.538C45.8362 122.142 46.7469 123.021 47.1734 124.175C47.5999 125.33 47.5669 126.476 47.0743 127.614C46.5817 128.752 45.8319 129.691 44.8247 130.432L43.5979 129.264Z"
            fill="white"
          />
          <path
            d="M37.3307 159.643L30.1317 158.994L29.6084 160.185L27.9566 159.944L29.2538 154.055L37.9277 156.933L37.3307 159.643ZM34.2856 152.2C33.2642 152.979 32.3873 153.287 31.6549 153.126C30.9224 152.965 30.4259 152.522 30.1653 151.799C29.9069 151.067 29.9068 150.114 30.165 148.942C30.4253 147.761 30.8038 146.902 31.3007 146.366C31.7878 145.829 32.3536 145.631 32.9982 145.773C33.4083 145.863 33.7536 146.062 34.034 146.369C34.3047 146.675 34.5431 147.127 34.7492 147.725C35.7777 146.682 36.7118 146.253 37.5517 146.438C38.3916 146.623 38.9728 147.145 39.2955 148.005C39.6105 148.852 39.6314 149.896 39.3582 151.136C39.0871 152.367 38.675 153.285 38.1219 153.89C37.5611 154.483 36.8706 154.69 36.0503 154.509C35.2202 154.326 34.6319 153.556 34.2856 152.2ZM36.3749 152.338C36.6288 152.394 36.8603 152.342 37.0693 152.184C37.2686 152.023 37.3984 151.806 37.4586 151.532C37.5188 151.259 37.4832 151.025 37.3516 150.833C37.2221 150.63 37.0646 150.508 36.8791 150.467C36.6838 150.424 36.4967 150.46 36.318 150.574C36.1294 150.686 35.8814 150.883 35.5739 151.163C35.7759 151.873 36.0429 152.265 36.3749 152.338ZM32.8883 147.945C32.4391 147.846 32.1575 148.055 32.0434 148.573C31.9875 148.827 32.008 149.036 32.1048 149.201C32.2016 149.365 32.3624 149.473 32.587 149.522C32.8018 149.569 33.1399 149.383 33.6012 148.962C33.4482 148.355 33.2106 148.016 32.8883 147.945Z"
            fill="white"
          />
          <path
            d="M35.4246 180.167L34.9804 187.639L33.528 187.553C33.4335 187.457 33.1935 187.197 32.8079 186.774C32.4224 186.35 32.1208 186.032 31.903 185.818C31.6759 185.594 31.3916 185.322 31.05 185.001C30.3651 184.39 29.7731 184.069 29.274 184.039C28.5253 183.995 28.129 184.342 28.0851 185.08C28.0596 185.51 28.1368 185.98 28.3168 186.492L26.6447 186.813C26.4201 186.379 26.2369 185.837 26.0952 185.187C25.9441 184.527 25.8828 183.958 25.9113 183.479C25.9404 182.989 26.0117 182.548 26.1253 182.154C26.2395 181.75 26.4074 181.369 26.629 181.012C26.8512 180.644 27.1585 180.362 27.5509 180.165C27.9333 179.967 28.3741 179.883 28.8732 179.913C30.34 180.01 31.703 181.263 32.9623 183.672L33.1772 183.595L32.6545 180.003L35.4246 180.167ZM35.9219 171.803L35.4777 179.275L34.0252 179.189C33.9308 179.093 33.6908 178.833 33.3052 178.41C32.9197 177.986 32.618 177.667 32.4003 177.454C32.1732 177.23 31.8889 176.958 31.5473 176.637C30.8624 176.025 30.2704 175.705 29.7713 175.675C29.0226 175.63 28.6263 175.978 28.5824 176.716C28.5569 177.146 28.6341 177.616 28.814 178.127L27.142 178.449C26.9174 178.015 26.7342 177.473 26.5925 176.823C26.4414 176.163 26.3801 175.594 26.4086 175.114C26.4377 174.625 26.509 174.184 26.6226 173.79C26.7368 173.386 26.9047 173.005 27.1263 172.648C27.3485 172.28 27.6558 171.998 28.0482 171.801C28.4306 171.603 28.8713 171.519 29.3705 171.549C30.8373 171.646 32.2003 172.899 33.4596 175.308L33.6745 175.231L33.1518 171.639L35.9219 171.803Z"
            fill="white"
          />
          <path
            d="M30.5477 208.634C30.8152 208.598 31.0208 208.478 31.1646 208.277C31.2985 208.077 31.347 207.843 31.3101 207.575C31.2731 207.308 31.1795 207.068 31.0292 206.857C30.8775 206.636 30.6859 206.49 30.4543 206.421C30.1967 206.457 29.9796 206.603 29.8031 206.86C29.6166 207.118 29.5452 207.405 29.589 207.722C29.6313 208.029 29.7349 208.267 29.8995 208.436C30.0642 208.605 30.2802 208.671 30.5477 208.634ZM34.4333 209.688C34.6019 209.009 34.6453 208.372 34.5632 207.777C34.4798 207.173 34.2381 206.702 33.8381 206.363C33.4381 206.025 32.9805 205.891 32.4654 205.962C32.5303 205.994 32.6128 206.043 32.7129 206.11C32.813 206.177 32.9605 206.368 33.1556 206.685C33.3493 206.991 33.4749 207.352 33.5323 207.768C33.6718 208.779 33.5171 209.633 33.0683 210.331C32.6181 211.019 31.9324 211.426 31.0112 211.554C30.08 211.682 29.2574 211.427 28.5434 210.789C27.8281 210.14 27.3802 209.162 27.1997 207.855C27.0179 206.537 27.2538 205.394 27.9075 204.426C28.55 203.449 29.629 202.856 31.1447 202.647C32.6504 202.439 33.8851 202.718 34.8488 203.483C35.8125 204.248 36.3791 205.245 36.5486 206.474C36.7182 207.702 36.5647 208.894 36.0883 210.05L34.4333 209.688Z"
            fill="white"
          />
          <path
            d="M39.5485 235.173C38.6346 235.433 38.3783 236.269 38.7796 237.684L38.8534 237.943L36.2974 237.343L36.5017 239.546L34.7689 239.539L33.0283 233.406L36.3235 234.249C36.3291 234.195 36.3388 234.12 36.3527 234.022C36.357 233.927 36.4013 233.754 36.4855 233.501C36.5601 233.251 36.6704 233.017 36.8164 232.799C36.9528 232.584 37.1702 232.361 37.4686 232.131C37.7575 231.903 38.0991 231.733 38.4936 231.621C39.4556 231.348 40.3653 231.496 41.2228 232.063C42.0707 232.633 42.6448 233.447 42.9452 234.506C43.0981 235.044 43.1617 235.598 43.136 236.167C43.0979 236.728 43.0216 237.155 42.9071 237.448L42.7538 237.897L41.1719 237.597C41.3006 237.062 41.3049 236.582 41.1848 236.159C41.0646 235.736 40.866 235.439 40.5888 235.268C40.3089 235.087 39.9621 235.056 39.5485 235.173ZM40.8749 227.76L34.2271 230.598L34.3299 231.895L32.761 232.465L31.1145 226.664L40.1172 225.091L40.8749 227.76Z"
            fill="white"
          />
          <path
            d="M53.778 259.026L47.896 263.227L48.2751 264.471L46.8654 265.365L44.0101 260.054L52.464 256.582L53.778 259.026ZM42.5192 257.28L39.8983 252.405L44.2274 250.606L43.7728 249.76L45.79 248.982L46.2232 249.788L48.3379 248.907L49.5738 251.206L47.6721 252.484L49.5828 256.038L47.6366 256.948L42.5192 257.28ZM43.7967 255.095L46.557 254.939L45.8894 253.697L43.7967 255.095Z"
            fill="white"
          />
          <path
            d="M65.4944 277.265L69.9089 283.309L68.7339 284.167C68.6 284.154 68.2496 284.106 67.6826 284.025C67.1156 283.944 66.6802 283.89 66.3765 283.864C66.0589 283.836 65.6662 283.807 65.1985 283.777C64.2809 283.742 63.6202 283.871 63.2164 284.166C62.6108 284.609 62.5262 285.128 62.9626 285.726C63.2162 286.073 63.5752 286.387 64.0397 286.667L62.955 287.979C62.5054 287.787 62.0191 287.486 61.4963 287.076C60.9595 286.663 60.5496 286.262 60.2665 285.875C59.9775 285.479 59.7514 285.093 59.5882 284.717C59.419 284.333 59.3061 283.932 59.2494 283.516C59.1868 283.091 59.244 282.678 59.4212 282.276C59.5902 281.88 59.8766 281.535 60.2804 281.24C61.4734 280.381 63.3223 280.479 65.8271 281.535L65.9436 281.339L63.2534 278.901L65.4944 277.265ZM56.4328 276.2C56.1439 276.585 56.1233 276.946 56.371 277.285C56.6187 277.625 56.9726 277.719 57.4325 277.569C57.8865 277.411 58.5012 277.049 59.2765 276.482C60.7704 275.391 61.3081 274.559 60.8893 273.986C60.4706 273.412 59.5142 273.671 58.0202 274.762C57.245 275.328 56.7158 275.808 56.4328 276.2ZM59.518 270.938C60.719 271.175 61.697 271.811 62.452 272.844C63.2069 273.878 63.5146 275.003 63.3751 276.219C63.2276 277.442 62.5683 278.48 61.3974 279.336C60.2264 280.191 59.0489 280.537 57.8649 280.374C56.6728 280.217 55.658 279.565 54.8205 278.418C53.983 277.271 53.6708 276.106 53.8838 274.923C54.0888 273.745 54.7768 272.729 55.9478 271.874C57.1187 271.018 58.3088 270.707 59.518 270.938Z"
            fill="white"
          />
          <path
            d="M82.1603 296.19L77.7857 301.944L78.516 303.02L77.4342 304.291L73.1346 300.063L80.1816 294.244L82.1603 296.19Z"
            fill="white"
          />
          <path
            d="M103.346 317.706C102.793 318.478 103.114 319.292 104.31 320.148L104.529 320.305L102.157 321.429L103.69 323.024L102.331 324.099L97.1463 320.389L100.248 318.993C100.219 318.948 100.179 318.883 100.129 318.798C100.074 318.721 100 318.558 99.9084 318.308C99.811 318.066 99.7513 317.814 99.7294 317.552C99.7018 317.299 99.7328 316.989 99.8227 316.623C99.9067 316.265 100.068 315.919 100.307 315.586C100.889 314.773 101.692 314.321 102.716 314.23C103.734 314.147 104.691 314.426 105.585 315.066C106.041 315.392 106.436 315.785 106.77 316.246C107.09 316.708 107.297 317.09 107.39 317.39L107.55 317.836L106.126 318.589C105.893 318.09 105.597 317.712 105.24 317.456C104.882 317.2 104.541 317.092 104.218 317.131C103.887 317.164 103.596 317.356 103.346 317.706ZM96.8773 313.077C96.3244 313.849 96.6457 314.663 97.8412 315.519L98.0608 315.676L95.6883 316.8L97.2214 318.395L95.8621 319.47L90.6778 315.76L93.7793 314.364C93.7503 314.319 93.7108 314.254 93.6609 314.169C93.6052 314.092 93.5315 313.929 93.4399 313.679C93.3425 313.437 93.2828 313.185 93.261 312.924C93.2333 312.67 93.2644 312.36 93.3542 311.994C93.4382 311.636 93.5995 311.291 93.8381 310.957C94.42 310.144 95.2231 309.692 96.2473 309.601C97.2657 309.518 98.2222 309.797 99.1168 310.437C99.5722 310.763 99.9671 311.156 100.301 311.617C100.622 312.08 100.829 312.461 100.921 312.761L101.081 313.208L99.6577 313.96C99.4245 313.461 99.129 313.083 98.7712 312.827C98.4134 312.571 98.0729 312.463 97.7496 312.502C97.4183 312.535 97.1275 312.727 96.8773 313.077Z"
            fill="white"
          />
          <path
            d="M129.201 327.056L127.038 333.953L128.09 334.717L127.504 336.28L122.024 333.762L126.679 325.897L129.201 327.056ZM118.274 325.402C118.161 325.647 118.153 325.88 118.25 326.101C118.343 326.33 118.512 326.502 118.757 326.614C119.002 326.727 119.259 326.779 119.527 326.77C119.786 326.757 120.007 326.672 120.19 326.514C120.299 326.277 120.294 326.011 120.177 325.715C120.056 325.428 119.854 325.22 119.573 325.091C119.282 324.957 119.021 324.914 118.79 324.962C118.559 325.01 118.387 325.157 118.274 325.402ZM115.296 328.111C115.778 328.618 116.296 329 116.85 329.254C117.395 329.505 117.918 329.553 118.419 329.398C118.919 329.242 119.278 328.929 119.495 328.456C119.434 328.494 119.348 328.537 119.238 328.585C119.128 328.634 118.891 328.657 118.529 328.656C118.157 328.65 117.781 328.559 117.399 328.384C116.472 327.958 115.84 327.37 115.502 326.621C115.155 325.867 115.178 325.063 115.57 324.209C115.959 323.364 116.619 322.814 117.55 322.56C118.472 322.301 119.537 322.45 120.746 323.005C121.945 323.556 122.784 324.366 123.262 325.433C123.727 326.505 123.642 327.731 123.007 329.113C122.368 330.503 121.467 331.398 120.303 331.799C119.14 332.2 117.994 332.141 116.868 331.623C115.741 331.105 114.819 330.335 114.1 329.311L115.296 328.111Z"
            fill="white"
          />
          <path
            d="M149.415 333.374L156.624 335.39L156.232 336.791C156.118 336.863 155.814 337.043 155.318 337.33C154.822 337.617 154.447 337.845 154.193 338.012C153.926 338.187 153.599 338.407 153.214 338.673C152.471 339.213 152.032 339.724 151.897 340.205C151.695 340.927 151.951 341.388 152.663 341.587C153.077 341.703 153.554 341.727 154.092 341.66L154.052 343.362C153.58 343.49 153.012 343.554 152.347 343.555C151.67 343.563 151.1 343.502 150.638 343.373C150.166 343.241 149.749 343.078 149.388 342.883C149.018 342.686 148.681 342.442 148.379 342.149C148.067 341.854 147.856 341.494 147.746 341.069C147.634 340.653 147.645 340.205 147.78 339.723C148.185 338.31 149.699 337.244 152.32 336.523L152.289 336.296L148.668 336.047L149.415 333.374ZM144.084 341.322L138.753 339.831L140.458 335.464L139.533 335.205L140.371 333.212L141.252 333.459L142.083 331.324L144.596 332.027L144.199 334.283L148.085 335.37L147.392 337.403L144.084 341.322ZM143.394 338.886L145.196 336.789L143.838 336.409L143.394 338.886Z"
            fill="white"
          />
          <path
            d="M169.546 340.883C169.623 339.876 170.079 339.099 170.914 338.551C171.739 338.003 172.689 337.77 173.766 337.853C174.304 337.895 174.831 338.025 175.346 338.246C175.86 338.466 176.235 338.675 176.471 338.874L176.839 339.173L176.008 340.583C175.517 340.315 175.082 340.166 174.703 340.137C174.324 340.108 174.022 340.19 173.797 340.383C173.561 340.585 173.429 340.866 173.401 341.225C173.372 341.594 173.453 341.911 173.643 342.176C173.824 342.441 174.053 342.649 174.333 342.801C174.899 343.136 175.414 343.346 175.879 343.432L176.189 343.501L174.806 347.201L169.138 346.764L169.744 345.141L172.095 344.509L172.107 344.345C171.281 344.01 170.634 343.549 170.168 342.962C169.975 342.726 169.818 342.423 169.696 342.053C169.564 341.682 169.514 341.292 169.546 340.883Z"
            fill="white"
          />
          <path
            d="M205.215 337.403L206.739 344.468L208.031 344.614L208.293 346.262L202.285 346.777L202.45 337.64L205.215 337.403ZM195.745 344.929C195.923 345.375 196.222 345.581 196.641 345.545C197.059 345.509 197.323 345.255 197.434 344.784C197.534 344.314 197.543 343.601 197.461 342.644C197.303 340.801 196.87 339.91 196.163 339.97C195.455 340.031 195.181 340.983 195.339 342.826C195.421 343.783 195.556 344.484 195.745 344.929ZM192.846 339.562C193.672 338.658 194.722 338.152 195.998 338.042C197.273 337.933 198.394 338.253 199.362 339.003C200.33 339.764 200.876 340.866 201 342.311C201.124 343.755 200.809 344.942 200.056 345.869C199.303 346.807 198.219 347.336 196.805 347.458C195.39 347.579 194.232 347.242 193.33 346.446C192.43 345.66 191.918 344.544 191.794 343.1C191.67 341.655 192.021 340.476 192.846 339.562Z"
            fill="white"
          />
          <path
            d="M224.524 333.091L231.769 331.21L232.135 332.619C232.073 332.738 231.9 333.047 231.615 333.544C231.331 334.041 231.121 334.426 230.986 334.699C230.843 334.984 230.671 335.339 230.471 335.763C230.1 336.603 229.978 337.265 230.103 337.749C230.292 338.475 230.744 338.745 231.461 338.559C231.877 338.451 232.3 338.232 232.732 337.903L233.553 339.395C233.209 339.742 232.75 340.084 232.176 340.419C231.594 340.766 231.071 341 230.607 341.12C230.133 341.244 229.69 341.312 229.281 341.325C228.861 341.341 228.447 341.299 228.039 341.198C227.621 341.1 227.258 340.895 226.949 340.582C226.643 340.279 226.428 339.886 226.302 339.402C225.942 337.977 226.714 336.294 228.618 334.353L228.478 334.173L225.221 335.777L224.524 333.091ZM221.25 337.35C221.489 338.27 222.32 338.545 223.743 338.176L224.004 338.108L223.347 340.65L225.554 340.495L225.507 342.227L219.337 343.829L220.253 340.554C220.2 340.547 220.125 340.536 220.027 340.519C219.933 340.513 219.76 340.465 219.51 340.375C219.261 340.295 219.03 340.179 218.815 340.028C218.603 339.887 218.385 339.665 218.162 339.361C217.941 339.067 217.779 338.722 217.676 338.325C217.424 337.357 217.592 336.451 218.179 335.606C218.768 334.772 219.595 334.216 220.659 333.94C221.201 333.799 221.756 333.748 222.324 333.786C222.885 333.837 223.31 333.923 223.6 334.044L224.045 334.207L223.71 335.782C223.178 335.641 222.699 335.626 222.273 335.737C221.847 335.847 221.545 336.039 221.368 336.313C221.182 336.588 221.142 336.934 221.25 337.35Z"
            fill="white"
          />
          <path
            d="M253.333 328.245C254.561 328.622 255.322 329.156 255.617 329.846C255.912 330.535 255.841 331.196 255.405 331.829C254.959 332.465 254.185 333.02 253.081 333.491C251.969 333.967 251.05 334.159 250.325 334.066C249.605 333.983 249.115 333.638 248.855 333.031C248.69 332.645 248.651 332.249 248.738 331.842C248.829 331.444 249.057 330.987 249.424 330.471C247.977 330.242 247.085 329.732 246.747 328.941C246.408 328.15 246.495 327.374 247.006 326.611C247.512 325.862 248.349 325.237 249.517 324.738C250.676 324.242 251.662 324.044 252.476 324.141C253.285 324.252 253.854 324.694 254.184 325.466C254.519 326.248 254.235 327.174 253.333 328.245ZM252.23 326.465C252.128 326.226 251.951 326.068 251.7 325.99C251.454 325.922 251.201 325.943 250.944 326.053C250.687 326.163 250.518 326.327 250.437 326.547C250.348 326.77 250.341 326.969 250.415 327.143C250.494 327.327 250.632 327.459 250.829 327.538C251.03 327.626 251.333 327.713 251.74 327.8C252.2 327.223 252.364 326.778 252.23 326.465ZM250.686 331.857C250.866 332.28 251.201 332.387 251.688 332.179C251.927 332.076 252.085 331.938 252.163 331.763C252.24 331.589 252.234 331.396 252.144 331.184C252.057 330.982 251.709 330.816 251.098 330.685C250.693 331.163 250.556 331.553 250.686 331.857Z"
            fill="white"
          />
          <path
            d="M278.044 312.145C278.554 312.947 279.429 312.953 280.669 312.163L280.897 312.018L281.054 314.639L283.106 313.813L283.595 315.475L278.217 318.898L278.081 315.5C278.028 315.51 277.953 315.522 277.856 315.537C277.764 315.56 277.584 315.567 277.318 315.559C277.058 315.559 276.802 315.52 276.551 315.443C276.305 315.374 276.03 315.229 275.724 315.009C275.423 314.798 275.163 314.519 274.942 314.173C274.405 313.329 274.286 312.416 274.584 311.431C274.888 310.456 275.503 309.673 276.431 309.082C276.904 308.781 277.416 308.562 277.968 308.424C278.517 308.299 278.948 308.25 279.261 308.276L279.735 308.295L279.901 309.896C279.351 309.926 278.891 310.059 278.52 310.295C278.148 310.532 277.921 310.807 277.836 311.121C277.744 311.441 277.813 311.783 278.044 312.145ZM271.497 320.332C271.862 320.645 272.222 320.688 272.576 320.463C272.93 320.237 273.047 319.89 272.927 319.422C272.798 318.958 272.475 318.322 271.96 317.512C270.966 315.951 270.17 315.362 269.571 315.743C268.972 316.124 269.17 317.095 270.163 318.656C270.679 319.466 271.123 320.024 271.497 320.332ZM266.442 316.917C266.756 315.733 267.452 314.798 268.532 314.111C269.612 313.423 270.754 313.188 271.959 313.405C273.169 313.63 274.164 314.354 274.943 315.578C275.721 316.801 275.991 317.998 275.753 319.169C275.52 320.349 274.805 321.32 273.607 322.082C272.409 322.845 271.226 323.082 270.059 322.794C268.897 322.514 267.927 321.763 267.148 320.54C266.369 319.316 266.134 318.109 266.442 316.917Z"
            fill="white"
          />
          <path
            d="M294.95 293.24L300.474 297.902L301.586 297.227L302.8 298.373L298.359 302.451L292.906 295.117L294.95 293.24ZM289.847 297.926L295.371 302.589L296.482 301.914L297.697 303.059L293.255 307.138L287.803 299.803L289.847 297.926Z"
            fill="white"
          />
          <path
            d="M314.088 274.913C314.836 275.499 315.663 275.213 316.569 274.055L316.736 273.843L317.757 276.261L319.417 274.798L320.432 276.202L316.503 281.222L315.242 278.064C315.195 278.091 315.128 278.127 315.041 278.174C314.962 278.226 314.796 278.293 314.542 278.373C314.296 278.46 314.042 278.509 313.78 278.52C313.526 278.537 313.217 278.492 312.856 278.387C312.502 278.288 312.163 278.112 311.84 277.859C311.053 277.243 310.635 276.421 310.588 275.394C310.549 274.373 310.869 273.429 311.547 272.563C311.892 272.122 312.302 271.744 312.776 271.43C313.253 271.129 313.643 270.939 313.946 270.86L314.399 270.719L315.09 272.173C314.581 272.385 314.191 272.664 313.92 273.011C313.649 273.357 313.526 273.693 313.551 274.017C313.571 274.35 313.749 274.648 314.088 274.913ZM307.453 283.002C307.666 283.169 307.891 283.23 308.128 283.187C308.372 283.15 308.578 283.025 308.744 282.813C308.911 282.6 309.021 282.362 309.074 282.099C309.121 281.844 309.089 281.61 308.978 281.395C308.773 281.235 308.513 281.178 308.198 281.223C307.891 281.275 307.642 281.423 307.451 281.667C307.253 281.919 307.151 282.163 307.145 282.399C307.138 282.635 307.241 282.836 307.453 283.002ZM309.4 286.526C310.006 286.174 310.496 285.758 310.872 285.278C311.242 284.805 311.409 284.308 311.374 283.785C311.339 283.262 311.116 282.84 310.707 282.52C310.729 282.588 310.752 282.682 310.773 282.8C310.795 282.919 310.763 283.154 310.678 283.507C310.586 283.867 310.411 284.212 310.152 284.543C309.524 285.346 308.806 285.826 307.998 285.981C307.185 286.144 306.408 285.936 305.668 285.357C304.935 284.784 304.553 284.015 304.521 283.05C304.482 282.093 304.873 281.091 305.693 280.044C306.506 279.004 307.487 278.375 308.636 278.157C309.786 277.952 310.96 278.318 312.157 279.255C313.362 280.198 314.025 281.282 314.146 282.506C314.267 283.731 313.945 284.832 313.181 285.808C312.417 286.785 311.454 287.504 310.292 287.967L309.4 286.526Z"
            fill="white"
          />
          <path
            d="M323.676 250.959L330.51 253.312L331.303 252.282L332.85 252.912L330.18 258.318L322.448 253.447L323.676 250.959ZM323.043 259.695C323.895 260.115 324.646 259.667 325.297 258.349L325.417 258.106L326.91 260.266L328.236 258.495L329.517 259.662L326.694 265.379L324.815 262.544C324.775 262.58 324.717 262.629 324.641 262.692C324.575 262.76 324.425 262.859 324.193 262.99C323.971 263.125 323.732 263.224 323.477 263.288C323.232 263.357 322.921 263.376 322.545 263.347C322.178 263.322 321.811 263.218 321.443 263.037C320.547 262.594 319.971 261.875 319.715 260.879C319.469 259.887 319.589 258.898 320.076 257.912C320.324 257.41 320.648 256.957 321.048 256.552C321.453 256.161 321.796 255.895 322.077 255.755L322.492 255.525L323.465 256.808C323.01 257.119 322.685 257.472 322.491 257.866C322.296 258.261 322.244 258.614 322.335 258.927C322.422 259.249 322.658 259.505 323.043 259.695Z"
            fill="white"
          />
          <path
            d="M331.683 230.745L334.163 223.683L335.536 224.165C335.6 224.283 335.76 224.599 336.014 225.112C336.268 225.625 336.471 226.014 336.622 226.279C336.778 226.557 336.977 226.897 337.217 227.299C337.708 228.076 338.189 228.547 338.661 228.712C339.368 228.961 339.845 228.736 340.09 228.038C340.232 227.632 340.287 227.159 340.255 226.617L341.951 226.768C342.047 227.247 342.075 227.818 342.032 228.482C341.996 229.158 341.899 229.723 341.74 230.175C341.577 230.638 341.387 231.043 341.17 231.39C340.949 231.747 340.683 232.067 340.372 232.35C340.057 232.642 339.684 232.829 339.253 232.911C338.831 232.996 338.384 232.955 337.912 232.79C336.528 232.293 335.562 230.714 335.013 228.052L334.785 228.067L334.301 231.665L331.683 230.745ZM337.761 240.87L335.397 240.04L330.288 234.716L331.257 231.956L336.783 236.186L337.283 233.405L340.092 234.233L337.761 240.87Z"
            fill="white"
          />
          <path
            d="M339.844 207.582C340.112 207.612 340.336 207.547 340.515 207.386C340.704 207.226 340.813 207.012 340.844 206.744C340.874 206.475 340.842 206.215 340.75 205.963C340.656 205.721 340.506 205.538 340.298 205.415C340.04 205.386 339.789 205.473 339.544 205.677C339.31 205.882 339.176 206.139 339.141 206.447C339.105 206.765 339.146 207.026 339.264 207.231C339.382 207.435 339.575 207.552 339.844 207.582ZM343.35 209.561C343.681 208.944 343.88 208.332 343.948 207.726C344.015 207.13 343.897 206.619 343.592 206.192C343.288 205.765 342.877 205.523 342.361 205.465C342.416 205.511 342.484 205.579 342.564 205.669C342.645 205.758 342.741 205.976 342.853 206.32C342.964 206.675 342.997 207.061 342.95 207.478C342.836 208.492 342.476 209.277 341.87 209.833C341.263 210.398 340.493 210.629 339.559 210.524C338.635 210.42 337.906 209.966 337.372 209.161C336.837 208.367 336.644 207.309 336.793 205.987C336.94 204.675 337.445 203.625 338.309 202.836C339.181 202.059 340.372 201.755 341.883 201.925C343.403 202.095 344.536 202.67 345.281 203.649C346.027 204.629 346.33 205.735 346.192 206.967C346.054 208.199 345.611 209.317 344.865 210.32L343.35 209.561Z"
            fill="white"
          />
          <path
            d="M341.364 176.003C342.314 175.974 342.766 175.225 342.722 173.756L342.714 173.486L345.046 174.692L345.386 172.505L347.064 172.935L347.256 179.307L344.266 177.686C344.247 177.737 344.22 177.808 344.182 177.899C344.155 177.99 344.07 178.148 343.926 178.372C343.793 178.596 343.629 178.796 343.434 178.972C343.249 179.148 342.984 179.311 342.639 179.461C342.303 179.611 341.93 179.693 341.52 179.705C340.521 179.735 339.674 179.37 338.981 178.611C338.298 177.851 337.94 176.921 337.907 175.822C337.89 175.262 337.964 174.71 338.127 174.164C338.301 173.629 338.479 173.233 338.662 172.978L338.92 172.58L340.381 173.256C340.126 173.744 340.004 174.208 340.018 174.648C340.031 175.088 340.151 175.424 340.378 175.657C340.606 175.901 340.934 176.016 341.364 176.003ZM347.353 182.535L347.519 188.067L342.843 187.743L342.871 188.702L340.719 188.497L340.692 187.582L338.406 187.426L338.328 184.817L340.6 184.524L340.478 180.491L342.626 180.546L347.353 182.535ZM345.234 183.919L342.695 182.825L342.737 184.234L345.234 183.919Z"
            fill="white"
          />
          <path
            d="M333.642 150.136L340.462 147.741L340.444 146.441L342.047 145.975L343.31 151.871L334.223 152.85L333.642 150.136ZM344.92 159.394L342.471 159.919L335.335 158.041L334.722 155.181L341.643 155.908L340.619 153.274L343.448 152.515L344.92 159.394Z"
            fill="white"
          />
          <path
            d="M326.517 129.493L323.59 122.604L324.93 122.035C325.057 122.079 325.387 122.205 325.921 122.412C326.455 122.62 326.867 122.771 327.157 122.865C327.46 122.965 327.835 123.082 328.284 123.218C329.17 123.461 329.842 123.485 330.303 123.289C330.993 122.996 331.193 122.509 330.904 121.828C330.736 121.432 330.457 121.045 330.069 120.667L331.423 119.635C331.817 119.924 332.223 120.328 332.638 120.846C333.067 121.37 333.376 121.853 333.564 122.295C333.755 122.746 333.888 123.173 333.961 123.576C334.039 123.989 334.058 124.405 334.018 124.823C333.983 125.251 333.833 125.641 333.57 125.992C333.315 126.339 332.958 126.61 332.497 126.806C331.141 127.371 329.362 126.856 327.163 125.259L327.005 125.423L329.071 128.408L326.517 129.493ZM332.599 135.638C331.67 136.033 330.77 135.986 329.899 135.498C329.033 135.018 328.388 134.282 327.966 133.288C327.755 132.791 327.629 132.263 327.587 131.705C327.546 131.147 327.559 130.717 327.627 130.417L327.723 129.952L329.357 130.04C329.345 130.599 329.413 131.054 329.562 131.404C329.71 131.753 329.922 131.984 330.198 132.095C330.486 132.212 330.796 132.199 331.127 132.059C331.468 131.914 331.712 131.696 331.86 131.405C332.012 131.123 332.091 130.823 332.097 130.506C332.134 129.849 332.083 129.295 331.945 128.843L331.863 128.535L335.784 128.059L338.007 133.292L336.287 133.501L334.644 131.705L334.492 131.77C334.576 132.658 334.465 133.444 334.158 134.128C334.038 134.408 333.841 134.687 333.568 134.966C333.3 135.254 332.976 135.478 332.599 135.638Z"
            fill="white"
          />
          <path
            d="M318.082 110.364L314.127 104.01L315.362 103.241C315.494 103.264 315.84 103.338 316.4 103.461C316.959 103.584 317.389 103.669 317.69 103.718C318.005 103.769 318.394 103.827 318.859 103.891C319.771 103.995 320.44 103.915 320.864 103.65C321.501 103.254 321.624 102.742 321.233 102.114C321.005 101.748 320.671 101.409 320.228 101.096L321.407 99.8672C321.841 100.092 322.304 100.428 322.795 100.876C323.3 101.328 323.679 101.757 323.932 102.165C324.191 102.581 324.388 102.982 324.523 103.369C324.663 103.765 324.746 104.173 324.772 104.593C324.803 105.021 324.715 105.429 324.509 105.816C324.311 106.199 324 106.522 323.575 106.786C322.322 107.554 320.486 107.319 318.066 106.081L317.935 106.268L320.438 108.898L318.082 110.364Z"
            fill="white"
          />
          <path
            d="M300.154 85.9937L295.22 80.3652L296.314 79.4061C296.448 79.4078 296.802 79.424 297.374 79.4545C297.946 79.485 298.384 79.4996 298.689 79.4984C299.008 79.4981 299.401 79.4921 299.87 79.4804C300.787 79.4344 301.434 79.2466 301.81 78.917C302.374 78.4225 302.412 77.8971 301.924 77.3407C301.64 77.0173 301.255 76.7369 300.767 76.4993L301.731 75.0958C302.196 75.2468 302.707 75.5036 303.264 75.8662C303.836 76.2298 304.28 76.592 304.596 76.953C304.919 77.3214 305.179 77.6857 305.375 78.0458C305.577 78.4134 305.725 78.8023 305.819 79.2124C305.919 79.63 305.899 80.0467 305.758 80.4626C305.625 80.872 305.37 81.2415 304.994 81.5711C303.882 82.5326 302.032 82.5987 299.443 81.7695L299.344 81.9757L302.241 84.1644L300.154 85.9937ZM302.167 88.2901L306.956 82.8755L306.307 81.7487L307.48 80.5609L311.455 85.0952L303.996 90.3768L302.167 88.2901Z"
            fill="white"
          />
          <path
            d="M284.778 60.273L289.025 63.8227L285.677 67.1038L286.413 67.7195L284.83 69.1921L284.128 68.6053L282.494 70.2104L280.491 68.5365L281.781 66.6428L278.685 64.0551L280.152 62.4863L284.778 60.273ZM284.406 62.7768L281.902 63.9485L282.984 64.8527L284.406 62.7768Z"
            fill="white"
          />
          <path
            d="M255.547 51.7699L258.405 45.1308L257.437 44.2629L258.18 42.7682L263.372 45.8345L257.936 53.181L255.547 51.7699ZM265.165 50.3773C265.028 50.6098 264.993 50.845 265.062 51.0829C265.136 51.3122 265.289 51.4955 265.521 51.6328C265.754 51.7701 265.999 51.8456 266.258 51.8592C266.526 51.8779 266.759 51.818 266.957 51.6794C267.089 51.4555 267.11 51.1947 267.018 50.8969C266.932 50.5905 266.751 50.356 266.476 50.1932C266.209 50.0356 265.958 49.9688 265.723 49.9927C265.488 50.0166 265.302 50.1448 265.165 50.3773ZM261.925 52.7666C262.352 53.321 262.824 53.7508 263.341 54.0559C263.866 54.3661 264.386 54.4697 264.9 54.3666C265.413 54.2635 265.802 53.9881 266.067 53.5404C266.002 53.5717 265.912 53.6058 265.798 53.6426C265.683 53.6794 265.441 53.676 265.072 53.6322C264.712 53.5936 264.351 53.4675 263.989 53.2539C263.111 52.7352 262.538 52.0832 262.27 51.2979C262.01 50.5176 262.117 49.7271 262.59 48.9263C263.068 48.1169 263.779 47.6309 264.723 47.4683C265.675 47.3107 266.72 47.5676 267.856 48.2388C269.002 48.9151 269.751 49.8103 270.103 50.9243C270.469 52.0348 270.263 53.2488 269.485 54.5662C268.712 55.875 267.727 56.669 266.528 56.9484C265.329 57.2277 264.196 57.0521 263.129 56.4215C262.061 55.791 261.222 54.9299 260.613 53.8382L261.925 52.7666Z"
            fill="white"
          />
          <path
            d="M231.469 42.4327L232.797 35.3276L231.662 34.6938L232.059 33.0723L237.798 34.9217L234.111 43.2838L231.469 42.4327ZM243.784 43.0442C243.474 44.0055 242.85 44.6553 241.911 44.9936C240.981 45.335 240.003 45.34 238.975 45.0088C238.461 44.8432 237.979 44.5934 237.53 44.2595C237.081 43.9257 236.765 43.6347 236.582 43.3867L236.293 43.0101L237.43 41.8318C237.844 42.2071 238.233 42.4531 238.594 42.5696C238.956 42.6862 239.269 42.6767 239.533 42.5412C239.81 42.3993 240.004 42.157 240.114 41.8144C240.228 41.4622 240.223 41.135 240.1 40.8326C239.986 40.5333 239.811 40.2773 239.575 40.0646C239.102 39.6077 238.65 39.2833 238.218 39.0915L237.932 38.9521L240.138 35.6759L245.549 37.4195L244.582 38.857L242.149 38.9241L242.098 39.0811C242.824 39.5988 243.346 40.1976 243.663 40.8775C243.795 41.1513 243.877 41.4826 243.91 41.8712C243.952 42.263 243.91 42.6539 243.784 43.0442Z"
            fill="white"
          />
          <path
            d="M207.761 33.0248C207.916 32.0875 207.268 31.4988 205.818 31.2586L205.552 31.2145L207.185 29.1587L205.105 28.4037L205.851 26.8395L212.14 27.8813L209.973 30.5023C210.019 30.5302 210.083 30.5713 210.165 30.6254C210.249 30.6697 210.387 30.7838 210.58 30.9678C210.774 31.1418 210.938 31.3414 211.073 31.5665C211.21 31.7817 211.319 32.0734 211.4 32.4416C211.482 32.7999 211.49 33.1813 211.423 33.5858C211.26 34.5724 210.739 35.3324 209.86 35.8659C208.982 36.3896 208.001 36.5616 206.916 36.3818C206.363 36.2903 205.835 36.1116 205.332 35.8458C204.84 35.5717 204.486 35.3206 204.271 35.0923L203.93 34.7622L204.876 33.4592C205.305 33.804 205.737 34.0123 206.171 34.0842C206.605 34.1562 206.959 34.1032 207.231 33.9254C207.514 33.7492 207.69 33.449 207.761 33.0248ZM219.153 38.2568L211.769 37.0336L212.006 35.5982C212.111 35.5142 212.395 35.3027 212.856 34.9635C213.318 34.6244 213.666 34.3578 213.901 34.1635C214.148 33.9611 214.448 33.7068 214.803 33.4006C215.483 32.7835 215.864 32.2282 215.946 31.735C216.068 30.995 215.764 30.5646 215.034 30.4437C214.61 30.3734 214.134 30.401 213.607 30.5265L213.462 28.83C213.917 28.652 214.475 28.5265 215.136 28.4535C215.808 28.3723 216.381 28.3709 216.855 28.4494C217.338 28.5294 217.77 28.6465 218.15 28.8007C218.539 28.9565 218.901 29.1633 219.233 29.4211C219.575 29.6805 219.824 30.0156 219.979 30.4264C220.135 30.8274 220.173 31.2745 220.091 31.7678C219.841 33.2164 218.452 34.4409 215.925 35.4414L215.979 35.6632L219.606 35.5191L219.153 38.2568Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.699 138.199C182.699 135.989 184.49 134.199 186.699 134.199C188.908 134.199 190.699 135.989 190.699 138.199C190.699 139.633 189.943 140.892 188.808 141.598L189.625 166.917C198.213 168.209 204.967 175.103 206.047 183.758L231.8 184.589C232.505 183.454 233.764 182.699 235.199 182.699C237.408 182.699 239.199 184.489 239.199 186.699C239.199 188.908 237.408 190.699 235.199 190.699C233.764 190.699 232.505 189.943 231.8 188.808L205.895 189.644C204.439 197.813 197.895 204.222 189.657 205.476L188.808 231.799C189.943 232.505 190.699 233.764 190.699 235.199C190.699 237.408 188.908 239.199 186.699 239.199C184.49 239.199 182.699 237.408 182.699 235.199C182.699 233.764 183.454 232.505 184.589 231.799L183.74 205.476C175.502 204.222 168.958 197.813 167.502 189.644L141.598 188.808C140.892 189.943 139.633 190.699 138.199 190.699C135.99 190.699 134.199 188.908 134.199 186.699C134.199 184.489 135.99 182.699 138.199 182.699C139.633 182.699 140.892 183.454 141.598 184.589L167.35 183.758C168.431 175.103 175.184 168.209 183.772 166.917L184.589 141.598C183.454 140.892 182.699 139.633 182.699 138.199Z"
            fill="white"
          />
        </g>
        <g className="wheel__ball spin"
           onAnimationIteration={this.handleBallAnimationIteration}
        >
          <circle cx="186" cy="71" r="5" fill="white" />
          <circle cx="186" cy="187" r="116" fill="transparent" />
        </g>
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(186.699 186.699) rotate(90) scale(103)"
          >
            <stop stopColor="#101A29" />
            <stop offset="1" stopColor="#22395B" />
          </radialGradient>
        </defs>
      </svg>
    );
  }
}

Wheel.propTypes = {};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wheel);
