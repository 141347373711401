import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  placeRollBet,
  setRollBetAmount,
  setRollGameType,
  setRollWinChance,
} from "../../../../actions/games/roll";

import PlaceBet from "../../Shared/PlaceBet/PlaceBet";
import BetSize from "../../Shared/BetSize/BetSize";
import GameType from "../../Shared/GameType/GameType";
import Bet from "./Bet/Bet";
import BetPlaced from "../../Shared/BetPlaced/BetPlaced";

import "./Controls.scss";

const ControlComponents = (props) => (
  <Fragment>
    <BetSize
      placingBet={props.isAutoBetting || props.startingAutoBetting || props.placingBet}
      maxVal={props.maxBetValue}
      amount={props.betAmount}
      setAmount={props.setBetAmount}
    />
    <GameType
      disabled={props.isAutoBetting || props.startingAutoBetting || props.placingBet}
      game="roll"
      gameType={props.gameType}
      handleGameType={props.handleGameType}
    />
    <Bet disabled={props.isAutoBetting || props.startingAutoBetting || props.placingBet} />
    <PlaceBet
      isAutoBetting={props.isAutoBetting}
      placeBet={props.placeBet}
      gettingState={props.settingInitData || props.gettingRollInitData || props.startingAutoBetting}
    />
  </Fragment>
)

class Controls extends Component {
  constructor(props) {
    super(props);
    this.handleGameType = this.handleGameType.bind(this);
  }

  componentWillMount() {
    this.props.setWinChance();
  }

  handleGameType = e => {
    const { id } = e.target;
    const { setGameType } = this.props;
    setGameType(Number(id));
  };

  render() {
    const {
      showControls,
      showDice,
      lastUserSelectedGameType,
      lastRoundPlayed,
      bettingRound,
      roundState,
      lastSelectedNumber,
      lastUserSelectedNumber,
      isAutoBetting,
    } = this.props;

    return (
      <section
        className="main__section main__section--controls"
        style={{
          justifyContent: `${!showDice && !showControls ? "flex-start" : ""}`
        }}
      >
        {!showControls && (
          <BetPlaced
            showVisuals={showDice}
            lastUserSelectedGameType={lastUserSelectedGameType}
            lastUserSelectedNumber={lastUserSelectedNumber}
            lastSelectedNumber={lastSelectedNumber}
            lastRoundPlayed={lastRoundPlayed}
            bettingRound={bettingRound}
            roundState={roundState}
          />
        )}
        {showControls && (
          <ControlComponents {...this.props} handleGameType={this.handleGameType} />
        )}
      </section>
    );
  }
}

Controls.propTypes = {
  gameType: PropTypes.number.isRequired,
  showControls: PropTypes.bool.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  showDice: PropTypes.bool.isRequired,
  setWinChance: PropTypes.func.isRequired,
  betAmount: PropTypes.string.isRequired,
  maxBetValue: PropTypes.string.isRequired,
  setBetAmount: PropTypes.func.isRequired,
  placeBet: PropTypes.func.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameRoll, rollConstants, games }) => ({
  gameType: gameRoll.gameType,
  showControls: gameRoll.showControls,
  showDice: gameRoll.showDice,
  betAmount: gameRoll.betAmount,
  lastSelectedNumber: gameRoll.lastSelectedNumber,
  placingBet: gameRoll.placingBet,
  lastUserSelectedGameType: gameRoll.lastUserSelectedGameType,
  lastRoundPlayed: gameRoll.lastRoundPlayed,
  lastUserSelectedNumber: gameRoll.lastUserSelectedNumber,
  bettingRound: gameRoll.bettingRound,
  roundState: gameRoll.roundState,
  gettingGameState: gameRoll.gettingGameState,
  maxBetValue: rollConstants.maxBetValue,
  settingInitData: games.settingInitData,
  gettingRollInitData: gameRoll.gettingRollInitData,
  isAutoBetting: gameRoll.isAutoBetting,
  startingAutoBetting: gameRoll.startingAutoBetting,
});

const mapDispatchToProps = {
  placeBet: placeRollBet,
  setGameType: setRollGameType,
  setWinChance: setRollWinChance,
  setBetAmount: setRollBetAmount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
