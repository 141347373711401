import {
  ethersProvider,
  WethContract,
  BadBitGameContract,
  tokenProxyAddress,
  web3Matic,
  POSRootChainManagerContract,
  WETHEventsContract,
  BadTokenEthereumContract,
  badTokenEthereumAddress,
  ethereumDepositContractAddress
} from "../contract";
import { executeMetaTransaction, getExecuteMetaTransactionSignature } from "../utils";
import { ethereumNetwork, maticNetwork } from "../../config/client";

import { FROM_BLOCK } from "../../config/constants.js";

/**
 * Converts wei to eth value
 *
 * @param {string} weiVal
 * @return {string}
 */
export const weiToEth = weiVal => {
  const value = weiVal === "" ? "0" : weiVal;
  return window._web3.utils.fromWei(
    new window._web3.utils.BN(`${value}`),
    "ether"
  );
};

/**
 * Converts eth to wei value
 *
 * @param {string} ethVal
 * @return {string}
 */
export const ethToWei = ethVal => {
  const value = ethVal === "" ? "0" : ethVal;
  const parts = value.toString().split(".");
  let val = parts[0];

  if (parts[1]) {
    const decimals =
      parts[1].length > 18 ? parts[1].substring(0, 18) : parts[1];
    val += `.${decimals}`;
  }

  return window._web3.utils.toWei(`${val}`, "ether");
};

/**
 * Converts hex value to number
 *
 * @param val
 * @return {number}
 */
export const hexToNumber = val =>
  parseFloat(window._web3.utils.hexToNumberString(val));

/**
 * Converts number to hex value
 *
 * @param val
 * @return {string}
 */
export const numberToHex = val => window._web3.utils.numberToHex(val);

/**
 * Converts string to hex value
 *
 * @param val
 * @return {string}
 */
export const toHex = val => window._web3.utils.asciiToHex(val);

/**
 * Converts string to hex value
 *
 * @param val
 * @return {string}
 */
export const fromHex = val => window._web3.utils.hexToString(val);

/**
 * Gets the current block
 *
 * @param val
 * @return {Promise<Block>}
 */
export const getBlock = val => web3Matic.eth.getBlock(val);

/**
 * Gets the network number
 *
 * @return {Promise<number>}
 */
export const getNetwork = () => window._web3.eth.net.getId();

/**
 * Gets te current average gas price for the current network
 *
 * @return {Promise}
 */
export const getGasPrice = () =>
  new Promise((resolve, reject) => {
    window._web3.eth.getGasPrice((error, gasPrice) => {
      if (error) reject(error);

      resolve(gasPrice);
    });
  });

/**
 * Checks if the user has approved to use MM as the provider
 *
 * @return {Promise<*>}
 */
export const isEthereumApproved = async () => {
  if (!window.ethereum || !window.ethereum.enable) return true;
  if (window.ethereum._metamask && window.ethereum._metamask.isApproved)
    return window.ethereum._metamask.isApproved();
  if (!window.web3 || !window.web3.eth) return false;
  const acc = await new Promise(res =>
    window.web3.eth.getAccounts((err, acc) => res(acc))
  );
  return !!acc.length;
};

/**
 * Opens a wallet-dapp connection dialog
 *
 * @return {Promise<*>}
 */
export const ethereumEnable = async () => {
  try {
    if (window.ethereum) return window.ethereum.enable();
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * Gets block number
 *
 * @return {Promise<number>}
 */
export const getBlockNumber = () => web3Matic.eth.getBlockNumber();

/**
 * Gets a current wallet balance
 *
 * @param account
 * @return {Promise<string | >}
 */
export const getBalance = async account => {
  const weth = await WethContract();
  return (await weth.methods.balanceOf(account).call({ from: account })).toString(10);
};

/**
 * Deposit ETH to MATIC Network
 *
 * @param from
 * @param amount
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 */
export const depositETHService = async (
  from,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const rootChainManagerContract = POSRootChainManagerContract();
      const promise = rootChainManagerContract.methods.depositEtherFor(from).send({from, value: amount})

      const { status } = await sendTxFunc(
        promise,
        {
          promiseTitle: "Depositing ETH to Matic...",
          successTitle: "Please wait 12 Ethereum blocks + 256 Matic blocks (about 8 to 9 minutes) for your deposit to show up on Matic Network.",
          errorTitle: "Depositing ETH to Matic denied.",
          isEth: true,
        },
        dispatch,
        getState,
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

  /**
 * Deposit ETH to MATIC Network
 *
 * @param from
 * @param amount
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 */
export const badbitDepositETHService = async (
  from,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const promise = window._web3.eth.sendTransaction({
        from,
        to: ethereumDepositContractAddress,
        value: amount
      })
      const { status } = await sendTxFunc(
        promise,
        {
          promiseTitle: "Depositing ETH to Matic...",
          successTitle: "Please wait 2 Ethereum Blocks (approximately 30 seconds) for your deposit to show up on Matic Network.",
          errorTitle: "Depositing ETH to Matic denied.",
          isEth: true,
        },
        dispatch,
        getState,
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

  /**
 * Deposit ETH to MATIC Network
 *
 * @param from
 * @param amount
 * @param gateway
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 */
export const depositBadTokenService = async (
  from,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      var rootChainManagerContract = POSRootChainManagerContract();
      var badTokenEthereum = BadTokenEthereumContract();

      const approval = await badTokenEthereum.methods.allowance(from, rootChainManagerContract._address).call({from});

      if(Number(approval.toString(10) < Number(amount))){
        const approvalPromise = badTokenEthereum.methods.approve(rootChainManagerContract._address, amount).send({from});
        await sendTxFunc(
          approvalPromise,
          {
            promiseTitle: "Approving BAD Token Transactions...",
            successTitle: "Approving BAD Token Transactions success...",
            errorTitle: "Approving BAD Token Transactions denied..."
          },
          dispatch,
          getState,
          false
        );
      }
      const promise = rootChainManagerContract.methods.depositERC20ForUser(badTokenEthereumAddress, from, amount).send({from})

      const { status } = await sendTxFunc(
        promise,
        {
          promiseTitle: "Depositing BAD Tokens to Matic...",
          successTitle: "Please wait 12 Ethereum blocks + 256 Matic blocks (about 8 to 9 minutes) for your deposit to show up on Matic Network.",
          errorTitle: "Depositing BAD Tokens to Matic denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

/**
 * Gets account from the users wallet
 *
 * @return {Promise<string>}
 */
export const getAccount = () =>
  new Promise(async (resolve, reject) => {
    try {
      const accounts = await window._web3.eth.getAccounts();
      if (!accounts.length)
        throw new Error("⚠️ Your browser is not Web3 enabled.");
      resolve(accounts[0]);
    } catch (err) {
      reject(err);
    }
  });

/**
 * Gets a current wallet eth balance
 *
 * @param _account
 * @return {Promise<string | >}
 */
export const getEthBalance = async _account => {
  const account = _account;
  var balance = await window._web3.eth.getBalance(account);
  return balance.toString(10);
};

export const giveInfiniteApproval = async(account, spender) => {
  const weth = WethContract();
  let domainData = {
    name: "Wrapped Ether",
    version: "1",
    verifyingContract: weth._address,
    salt: '0x' + maticNetwork.toString(16).padStart(64, '0')

  };
  var amount = "11579208923731619542357098500868790785326998466564056403945758400791312963";

  if(spender === undefined || spender === null || spender === "") {
    spender = tokenProxyAddress;
  }
  var functionSignature = weth.methods.approve(spender, amount).encodeABI();
  var signature = await getExecuteMetaTransactionSignature(weth, functionSignature, account, domainData)
  await executeMetaTransaction(weth, functionSignature, account, signature.r, signature.s, signature.v);
}

export const getApproval = async (_account, spender) => {
  const account = _account;
  const weth = WethContract();
  if(spender === undefined || spender === null || spender === "") {
    spender = tokenProxyAddress;
  }
  var balance = await weth.methods.allowance(account, spender).call({from:account});
  return balance.toString(10);
};

export const listenForNewBlock = (callback, dispatch) => {
  console.log("LISTEN FOR NEW BLOCK OUT");
  ethersProvider.on("block", (blockNumber) => {
    console.log("LISTEN FOR NEW BLOCK IN ", blockNumber);
    if (dispatch) {
      dispatch(callback());
    } else {
      callback(blockNumber)
    }
  });
};

export const removeListenForNewBlock = () => {
  console.log("REMOVE LISTEN FOR NEW BLOCK");
  ethersProvider.removeAllListeners("block");
};

/**
 *
 * @param {boolean} forBatch
 * @param {function} method
 * @param {[]} [params]
 * @return {*}
 */
export const prepareCall = (forBatch, method, params = []) => {
  if (forBatch) return method(...params).call;
  return method(...params).call();
};

/**
 *
 * @param {Array<Function|{method: Function, params: {}}>} methods
 * @param {{}} [params] In case that every function uses same params
 * @return {Promise<unknown[]>}
 */
export const makeBatchRequest = (methods, params) => {
  const batch = new web3Matic.BatchRequest();

  const promises = methods.map(method => {
    const isFunction = typeof method === "function";
    const callingFunction = isFunction ? method : method.method;
    const passParams = params ? params : isFunction ? {} : method.params;
    return new Promise((res, rej) => {
      const req = callingFunction.request(passParams, (err, data) => {
        if(err) rej(err);
        else res(data)
      });
      batch.add(req);
    })
  })
  batch.execute();

  return Promise.all(promises)
};

/**
 * A listener for WETH Burn event
 *
 * @param callback
 */
export const listenToWETHBurnEventFromContract = callback => {
  const filter = WETHEventsContract.filters.Burned(null, null);
  filter.fromBlock = FROM_BLOCK;

  WETHEventsContract.on(filter, async (rootToken, user, amount, {transactionHash}) => {
    console.log("WETH burned event: ", rootToken, user, amount, transactionHash);
    /**const { timestamp } = await getBlock(blockNumber);
    console.log("ROLL ROUND STARTED EVENT IN: ", timestamp);
    await callback({id: hexToNumber(id._hex), timestamp, blockNumber});*/
  });
};
