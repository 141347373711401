import {
  GET_WITHDRAWAL_HISTORY_ERROR,
  GET_WITHDRAWAL_HISTORY_REQUEST,
  GET_WITHDRAWAL_HISTORY_SUCCESS,
  NUM_OF_WITHDRAWALS_ERROR,
  NUM_OF_WITHDRAWALS_REQUEST,
  NUM_OF_WITHDRAWALS_SUCCESS
} from "../actionTypes/assetMovementHistory";
import { formatTimestampToDate } from "../services/utils";
import { weiToEth } from "../services/ethereum/main";
import {
  getNumberOfUserWithdrawalsFromContract,
  userWithdrawalsFromContract
} from "../services/ethereum/games";
import {
  getBadMoveEthereumToMatic,
  getEtherMoveEthereumToMatic,
} from "../services/api";

/**
 * Sets a number of withdrawals from contract balance
 *
 * @return {Function}
 */
export const setNumberOfWithdrawals = () => async (dispatch, getState) => {
  dispatch({ type: NUM_OF_WITHDRAWALS_REQUEST });
  const { account } = getState().account;
  try {
    const payload = await getNumberOfUserWithdrawalsFromContract(
      account,
      account
    );
    dispatch({ type: NUM_OF_WITHDRAWALS_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: NUM_OF_WITHDRAWALS_ERROR, payload: e.message });
  }
};

/**
 * Gets withdrawal history by pages
 *
 * @param page
 * @param perPage
 * @param asset
 * @param fromAsset
 * @param toAsset
 * @return {Function}
 */
export const getAssetMovementHistory = (
  page = 1,
  perPage = 5,
  asset = "ETH",
  fromAsset = "ethereum",
  toAsset = "matic"
) => async (dispatch) => {
  dispatch({ type: GET_WITHDRAWAL_HISTORY_REQUEST });
  try {
    let payload;
    if (asset === "ETH") {
      if (fromAsset === "ethereum" && toAsset === "matic") {
        payload = await getEtherMoveEthereumToMatic(page, perPage);
      }
    }

    if (asset === "BAD") {
      if (fromAsset === "ethereum" && toAsset === "matic") {
        payload = await getBadMoveEthereumToMatic(page, perPage);
      }
    }

    console.log(payload);

    dispatch({ type: GET_WITHDRAWAL_HISTORY_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_WITHDRAWAL_HISTORY_ERROR, payload: e.message });
  }
};
