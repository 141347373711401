import {
  GET_BAD_TOKEN_BALANCE_REQUEST,
  GET_BAD_TOKEN_BALANCE_SUCCESS,
  GET_BAD_TOKEN_BALANCE_ERROR,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS,
  GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR,
  GET_STAKED_BAD_TOKEN_BALANCE_REQUEST,
  GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS,
  GET_STAKED_BAD_TOKEN_BALANCE_ERROR,
  ADD_BAD_TOKEN_WIN_NOTIF,
  GET_BAD_TOKEN_REVENUE_SHARE_REQUEST,
  GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS,
  GET_BAD_TOKEN_REVENUE_SHARE_ERROR,
  GET_BAD_TOKEN_WIN_PROB_REQUEST,
  GET_BAD_TOKEN_WIN_PROB_SUCCESS,
  GET_BAD_TOKEN_WIN_PROB_ERROR,
  GET_BAD_TOKEN_WIN_PROB_DON_REQUEST,
  GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS,
  GET_BAD_TOKEN_WIN_PROB_DON_ERROR,
  GET_TOKEN_REVENUE_HISTORY_REQUEST,
  GET_TOKEN_REVENUE_HISTORY_SUCCESS,
  GET_TOKEN_REVENUE_HISTORY_ERROR,
  GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST,
  GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS,
  GET_EXTRA_TOKEN_WIN_CHANCE_ERROR,
  GET_PLAYERS_LEVEL_REQUEST,
  GET_PLAYERS_LEVEL_SUCCESS,
  GET_PLAYERS_LEVEL_ERROR,
  GET_TOKEN_STATS_REQUEST,
  GET_TOKEN_STATS_SUCCESS,
  GET_TOKEN_STATS_ERROR,
  POST_USER_TITLE_REQUEST,
  POST_USER_TITLE_SUCCESS,
  POST_USER_TITLE_ERROR,
  DEPOSIT_BAD_TOKEN_REQUEST,
  DEPOSIT_BAD_TOKEN_SUCCESS,
  DEPOSIT_BAD_TOKEN_ERROR,
  WITHDRAW_BAD_TOKEN_REQUEST,
  WITHDRAW_BAD_TOKEN_SUCCESS,
  WITHDRAW_BAD_TOKEN_ERROR,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS,
  GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR,
} from "../actionTypes/badToken";

const INITIAL_STATE = {
  badTokenBalance: "0",
  gettingBadTokenBalance: false,
  gettingBadTokenBalanceErr: "",


  ethereumBadTokenBalance: "0",
  gettingEthereumBadTokenBalance: false,
  gettingEthereumBadTokenBalanceErr: "",

  depositingBadToken: false,
  depositingBadTokenErr: "",

  withdrawingBadToken: false,
  withdrawingBadTokenErr: "",

  stakedBadTokenBalance: "0",
  blockLeftToStakedTokenPeriodEnd: "0",
  blocksLeftToNextCycle: "0",
  gettingStakedBadTokenBalance: false,
  gettingStakedBadTokenBalanceErr: "",

  tokensWon: "0",

  currentRevenueShare: "0",
  totalRevenueShare: "0",
  gettingRevenueShare: false,
  gettingRevenueShareErr: "",

  badTokenWinProb: "0",
  gettingBadTokenWinProb: false,
  gettingBadTokenWinProbErr: "",

  extraTokenWinChance: "0",
  gettingExtraTokenWinChance: false,
  gettingExtraTokenWinChanceErr: "",

  tokenRevenueHistory: { data: [] },
  gettingTokenRevenueHistory: false,
  gettingTokenRevenueHistoryErr: "",

  playerLevel: "0",
  amountLeftToCompleteLevel: "0",
  amountAcquiredInThisLevel: "0",
  levelPercentCompleted: "0",
  gettingPlayerLevel: false,
  gettingPlayerLevelErr: "",

  titleNumber: null,
  postingTitleNumber: false,
  postingTitleNumberError: "",

  currentStage: "0",
  distributedTokensInCurrStage: "0",
  notDistributedTokensInCurrStage: "0",
  distributedTokens: "0",
  distributedTokensPercent: "0",
  revenueForCurrentDistCycle: "0",
  percentOfDistInCurrStage: "0",
  nextCycleBlocksLeft: "0",
  gettingTokenStats: false,
  gettingTokenStatsErr: "",
  stakedTokens: "0",

  gettingBadTokenWinProbDon: false,
  gettingBadTokenWinProbDonErr: "",
  badTokenWinProbDon: "0",
  maxPercentForNextStage: "0",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BAD_TOKEN_BALANCE_REQUEST:
      return {
        ...state,
        gettingBadTokenBalance: true,
        gettingBadTokenBalanceErr: ""
      };

    case GET_BAD_TOKEN_BALANCE_SUCCESS:
      return {
        ...state,
        badTokenBalance: payload,
        gettingBadTokenBalance: false,
        gettingBadTokenBalanceErr: ""
      };

    case GET_BAD_TOKEN_BALANCE_ERROR:
      return {
        ...state,
        gettingBadTokenBalance: false,
        gettingBadTokenBalanceErr: payload
      };

      case GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST:
        return {
          ...state,
          gettingEthereumBadTokenBalance: true,
          gettingEthereumBadTokenBalanceErr: ""
        };

      case GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS:
        return {
          ...state,
          ethereumBadTokenBalance: payload,
          gettingEthereumBadTokenBalance: false,
          gettingEthereumBadTokenBalanceErr: ""
        };

      case GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR:
        return {
          ...state,
          gettingEthereumBadTokenBalance: false,
          gettingEthereumBadTokenBalanceErr: payload
        };

        case DEPOSIT_BAD_TOKEN_REQUEST:
          return {
            ...state,
            depositingBadToken: true,
            depositingBadTokenErr: ""
          };

        case DEPOSIT_BAD_TOKEN_SUCCESS:
          return {
            ...state,
            depositingBadToken: false,
            depositingBadTokenErr: ""
          };

        case DEPOSIT_BAD_TOKEN_ERROR:
          return {
            ...state,
            depositingBadToken: false,
            depositingBadTokenErr: payload
          };
          case WITHDRAW_BAD_TOKEN_REQUEST:
            return {
              ...state,
              withdrawingBadToken: true,
              withdrawingBadTokenErr: ""
            };

          case WITHDRAW_BAD_TOKEN_SUCCESS:
            return {
              ...state,
              withdrawingBadToken: false,
              withdrawingBadTokenErr: ""
            };

          case WITHDRAW_BAD_TOKEN_ERROR:
            return {
              ...state,
              withdrawingBadToken: false,
              withdrawingBadTokenErr: payload
            };

    case GET_STAKED_BAD_TOKEN_BALANCE_REQUEST:
      return {
        ...state,
        gettingStakedBadTokenBalance: true,
        gettingStakedBadTokenBalanceErr: ""
      };

    case GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS:
      return {
        ...state,
        gettingStakedBadTokenBalance: false,
        gettingStakedBadTokenBalanceErr: "",
        stakedBadTokenBalance: payload.stakedBadTokenBalance,
        blocksLeftToNextCycle: payload.blocksLeftToNextCycle
      };

    case GET_STAKED_BAD_TOKEN_BALANCE_ERROR:
      return {
        ...state,
        gettingStakedBadTokenBalance: false,
        gettingStakedBadTokenBalanceErr: payload
      };

    case ADD_BAD_TOKEN_WIN_NOTIF:
      return { ...state, tokensWon: payload };

    case GET_BAD_TOKEN_REVENUE_SHARE_REQUEST:
      return {
        ...state,
        gettingRevenueShare: true,
        gettingRevenueShareErr: ""
      };

    case GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS:
      return {
        ...state,
        currentRevenueShare: payload.current,
        totalRevenueShare: payload.total,
        gettingRevenueShare: false,
        gettingRevenueShareErr: ""
      };

    case GET_BAD_TOKEN_REVENUE_SHARE_ERROR:
      return {
        ...state,
        gettingRevenueShare: false,
        gettingRevenueShareErr: payload
      };

    case GET_BAD_TOKEN_WIN_PROB_REQUEST:
      return {
        ...state,
        gettingBadTokenWinProb: true,
        gettingBadTokenWinProbErr: ""
      };

    case GET_BAD_TOKEN_WIN_PROB_SUCCESS:
      return {
        ...state,
        gettingBadTokenWinProb: false,
        gettingBadTokenWinProbErr: "",
        badTokenWinProb: payload
      };

    case GET_BAD_TOKEN_WIN_PROB_ERROR:
      return {
        ...state,
        gettingBadTokenWinProb: false,
        gettingBadTokenWinProbErr: payload
      };

    case GET_BAD_TOKEN_WIN_PROB_DON_REQUEST:
      return {
        ...state,
        gettingBadTokenWinProbDon: true,
        gettingBadTokenWinProbDonErr: ""
      };

    case GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS:
      return {
        ...state,
        gettingBadTokenWinProbDon: false,
        gettingBadTokenWinProbDonErr: "",
        badTokenWinProbDon: payload
      };

    case GET_BAD_TOKEN_WIN_PROB_DON_ERROR:
      return {
        ...state,
        gettingBadTokenWinProbDon: false,
        gettingBadTokenWinProbDonErr: payload
      };

    case GET_TOKEN_REVENUE_HISTORY_REQUEST:
      return {
        ...state,
        gettingTokenRevenueHistory: true,
        gettingTokenRevenueHistoryErr: ""
      };

    case GET_TOKEN_REVENUE_HISTORY_SUCCESS:
      return {
        ...state,
        gettingTokenRevenueHistory: false,
        gettingTokenRevenueHistoryErr: "",
        tokenRevenueHistory: payload
      };

    case GET_TOKEN_REVENUE_HISTORY_ERROR:
      return {
        ...state,
        gettingTokenRevenueHistory: false,
        gettingTokenRevenueHistoryErr: payload
      };

    case GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST:
      return {
        ...state,
        gettingExtraTokenWinChance: true,
        gettingExtraTokenWinChanceErr: ""
      };

    case GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS:
      return {
        ...state,
        extraTokenWinChance: payload,
        gettingExtraTokenWinChance: false,
        gettingExtraTokenWinChanceErr: ""
      };

    case GET_EXTRA_TOKEN_WIN_CHANCE_ERROR:
      return {
        ...state,
        gettingExtraTokenWinChance: false,
        gettingExtraTokenWinChanceErr: payload
      };

    case GET_PLAYERS_LEVEL_REQUEST:
      return {
        ...state,
        gettingPlayerLevel: true,
        gettingPlayerLevelErr: ""
      };

    case GET_PLAYERS_LEVEL_SUCCESS:
      return {
        ...state,
        playerLevel: payload.level,
        amountLeftToCompleteLevel: payload.amountLeftToCompleteLevel,
        amountAcquiredInThisLevel: payload.amountAcquiredInThisLevel,
        levelPercentCompleted: payload.levelPercentCompleted,
        titleNumber: payload.titleNumber,
        gettingPlayerLevel: false,
        gettingPlayerLevelErr: ""
      };

    case GET_PLAYERS_LEVEL_ERROR:
      return {
        ...state,
        gettingPlayerLevel: false,
        gettingPlayerLevelErr: payload
      };

    case GET_TOKEN_STATS_REQUEST:
      return {
        ...state,
        gettingTokenStats: true,
        gettingTokenStatsErr: ""
      };

    case GET_TOKEN_STATS_SUCCESS:
      return {
        ...state,
        gettingTokenStats: false,
        gettingTokenStatsErr: "",
        currentStage: payload.currentStage,
        stakedTokens: payload.stakedTokens,
        distributedTokensInCurrStage: payload.distributedTokensInCurrStage,
        maxPercentForNextStage: payload.maxPercentForNextStage,
        notDistributedTokensInCurrStage:
          payload.notDistributedTokensInCurrStage,
        distributedTokens: payload.distributedTokens,
        distributedTokensPercent: payload.distributedTokensPercent,
        revenueForCurrentDistCycle: payload.revenueForCurrentDistCycle,
        percentOfDistInCurrStage: payload.percentOfDistInCurrStage,
        nextCycleBlocksLeft: payload.nextCycleBlocksLeft
      };

    case GET_TOKEN_STATS_ERROR:
      return {
        ...state,
        gettingTokenStats: false,
        gettingTokenStatsErr: payload
      };

    case POST_USER_TITLE_REQUEST:
      return {
        ...state,
        postingTitleNumber: true,
        postingTitleNumberError: ""
      };

    case POST_USER_TITLE_SUCCESS:
      return {
        ...state,
        titleNumber: payload,
        postingTitleNumber: false,
        postingTitleNumberError: ""
      };

    case POST_USER_TITLE_ERROR:
      return {
        ...state,
        postingTitleNumber: false,
        postingTitleNumberError: payload
      };

    case GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST: return {
      ...state,
      gettingBlockLeftToStakedTokenPeriodEnd: true,
      gettingBlockLeftToStakedTokenPeriodEndError: "",
    };

    case GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS: return {
      ...state,
      blockLeftToStakedTokenPeriodEnd: payload,
      gettingBlockLeftToStakedTokenPeriodEnd: false,
      gettingBlockLeftToStakedTokenPeriodEndError: "",
    };

    case GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR: return {
      ...state,
      gettingBlockLeftToStakedTokenPeriodEnd: false,
      gettingBlockLeftToStakedTokenPeriodEndError: payload,
    };

    default:
      return state;
  }
};
