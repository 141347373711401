import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import __html from "../../../../config/tos.html";
const template = { __html: __html };
import { toggleModal } from "../../../../actions/modal";
import { sendVerificationStepTwo } from "../../../../actions/account";

import "../VerifyStepOne/VerifySteps.scss";
import {postDismissedVerification} from "../../../../services/api";

function validateFileType(string) {
  const regexFileType = /^image\/(png|jpg|jpeg)$/;
  return regexFileType.test(String(string));
}

class VerifyStepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residentialAddress: "",
      idDocNumber: "",
      idPhoto: {},
      addressPhoto: {},
      addedIdPhoto: false,
      addedAddressPhoto: false,
      acceptTerms: false,
      error: "",
    };

    this.handleResidentialAddress = this.handleResidentialAddress.bind(this);
    this.handleIdDocNumber = this.handleIdDocNumber.bind(this);
    this.handleIdPhoto = this.handleIdPhoto.bind(this);
    this.handleAddressPhoto = this.handleAddressPhoto.bind(this);
    this.handleAcceptInput = this.handleAcceptInput.bind(this);
  }

  handleResidentialAddress = e => {
    const { value } = e.target;
    this.setState({ residentialAddress: value });
  };

  handleIdDocNumber = e => {
    const { value } = e.target;
    this.setState({ idDocNumber: value });
  };

  handleIdPhoto = e => {
    const { files } = e.target;
    console.log(e, files);
    if (!files.length) return;
    const {size, type} = files[0];
    if (size > 7000000) {
      this.setState({ error: "File size can't be larger than 7MB." });
      this.setState({ addedIdPhoto: false, idPhoto: {} });
      return;
    }
    const isRightType = validateFileType(type);
    if (!isRightType) {
      this.setState({ error: "File must be jpg or png format." });
      this.setState({ addedIdPhoto: false, idPhoto: {} });
      return;
    }
    this.setState({ addedIdPhoto: true,  idPhoto: files[0] });
    this.setState({ error: "" });
  };

  handleAddressPhoto = e => {
    const { files } = e.target;
    console.log(e, files);
    if (!files.length) return;
    const {size, type} = files[0];
    if (size > 7000000) {
      this.setState({ error: "File size can't be larger than 7MB." });
      this.setState({ addedAddressPhoto: false, addressPhoto: {} });
      return;
    }
    const isRightType = validateFileType(type);
    if (!isRightType) {
      this.setState({ error: "File must be jpg or png format." });
      this.setState({ addedAddressPhoto: false, addressPhoto: {} });
      return;
    }
    this.setState({ addedAddressPhoto: true, addressPhoto: files[0] });
    this.setState({ error: "" });
  };

  handleAcceptInput = e => {
    const { checked } = e.target;
    this.setState({ acceptTerms: checked });
  };

  render() {
    const {
      account,
      canSkip,
      toggleModal,
      sendVerificationStepTwo,
      sendingVerificationStepTwo,
      sendingVerificationStepTwoErr,
    } = this.props;

    const disableSubmit =
      this.state.residentialAddress === ""
      || this.state.idDocNumber === ""
      || this.state.addedIdPhoto === false
      || this.state.addedAddressPhoto === false
      || this.state.acceptTerms === false;

    return (
      <div className="modal__content modal__content--verify">
        <p className="title">Please finish your verification</p>
        <input
          className="input wide"
          type="text"
          placeholder="Residential address"
          onBlur={this.handleResidentialAddress}
        />

        <input
          className="input wide"
          type="text"
          placeholder="Identification document number"
          onBlur={this.handleIdDocNumber}
        />

        <div className={`input-file-wrapper ${this.state.addedIdPhoto ? 'uploaded' : ''}`}>
          <div className="label">
            <div className="file-status"/>
            <span className="file-name">Copy of photo identification document</span>
          </div>
          <input
            className="input"
            type="file"
            id="input-file-id"
            onChange={this.handleIdPhoto}
          />
          <label className="btn btn--primary" htmlFor="input-file-id">Upload</label>
        </div>

        <div className={`input-file-wrapper ${this.state.addedAddressPhoto ? 'uploaded' : ''}`}>
          <div className="label">
            <div className="file-status"/>
            <span className="file-name">Copy of proof of address</span>
          </div>
          <input
            className="input"
            type="file"
            id="input-file-address"
            onChange={this.handleAddressPhoto}
          />
          <label className="btn btn--primary" htmlFor="input-file-address">Upload</label>
        </div>

        <PerfectScrollbar option={{ minScrollbarLength: 40 }}>
          <div className="description" dangerouslySetInnerHTML={template} />
        </PerfectScrollbar>

        <div className="checkbox__wrapper">
          <input
            className="checkbox"
            id="accept-declaration"
            type="checkbox"
            onChange={this.handleAcceptInput}/>
          <label className="label" htmlFor="accept-declaration">Accept</label>
        </div>

        {this.state.error !== "" && <p className="input__error">{this.state.error}</p>}
        {sendingVerificationStepTwoErr !== "" && <p className="input__error">{sendingVerificationStepTwoErr}</p>}

        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={async () => await sendVerificationStepTwo(this.state)}
            disabled={disableSubmit || sendingVerificationStepTwo}
          >
            {sendingVerificationStepTwo ? 'Submitting' : 'Submit' }
          </button>
          {canSkip && (
            <button
              className="btn btn--dark"
              onClick={async () => {
                toggleModal();
                await postDismissedVerification(account, 2);
              }}
            >
              Dismiss for 24h
            </button>
          )}
        </div>
      </div>
    );
  }
}

VerifyStepTwo.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ account }) => ({
  sendingVerificationStepTwo: account.sendingVerificationStepTwo,
  sendingVerificationStepTwoErr: account.sendingVerificationStepTwoErr,
  account: account.account,
});

const mapDispatchToProps = {
  toggleModal,
  sendVerificationStepTwo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyStepTwo);
