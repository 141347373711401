import {
  GET_PROMOTION_STATS_REQUEST,
  GET_PROMOTION_STATS_SUCCESS,
  GET_PROMOTION_STATS_ERROR,
  GET_PROMOTION_DATA_REQUEST,
  GET_PROMOTION_DATA_SUCCESS,
  GET_PROMOTION_DATA_ERROR,
} from "../actionTypes/promotionStats";

const INITIAL_STATE = {
  promotionStats: { data: [] },
  gettingPromotionStats: false,
  gettingPromotionStatsError: "",

  promotionFlashRacePrize: 0,
  promotionRunsUntil: "",
  promotionBonusPositions: [],
  promotionIsRunning: false,
  gettingPromotionStatsData: false,
  gettingPromotionStatsDataError: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROMOTION_STATS_REQUEST:
      return {
        ...state,
        gettingPromotionStats: true,
        gettingPromotionStatsError: ""
      };

    case GET_PROMOTION_STATS_SUCCESS:
      return {
        ...state,
        promotionStats: payload,
        gettingPromotionStats: false,
        gettingPromotionStatsError: ""
      };

    case GET_PROMOTION_STATS_ERROR:
      return {
        ...state,
        gettingPromotionStats: false,
        gettingPromotionStatsError: payload
      };


    case GET_PROMOTION_DATA_REQUEST:
      return {
        ...state,
        gettingPromotionStatsData: true,
        gettingPromotionStatsDataError: ""
      };

    case GET_PROMOTION_DATA_SUCCESS:
      return {
        ...state,
        promotionFlashRacePrize: payload.promotionFlashRacePrize,
        promotionRunsUntil: payload.promotionRunsUntil,
        promotionIsRunning: payload.promotionIsRunning,
        promotionBonusPositions: payload.promotionBonusPositions.split(","),
        gettingPromotionStatsData: false,
        gettingPromotionStatsDataError: ""
      };

    case GET_PROMOTION_DATA_ERROR:
      return {
        ...state,
        gettingPromotionStatsData: false,
        gettingPromotionStatsDataError: payload
      };

    default:
      return state;
  }
};
