export const GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST =
  "GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST";
export const GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS =
  "GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS";
export const GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR =
  "GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR";

export const GET_MAX_BET_SIZES_REQUEST = "GET_MAX_BET_SIZES_REQUEST";
export const GET_MAX_BET_SIZES_SUCCESS = "GET_MAX_BET_SIZES_SUCCESS";
export const GET_MAX_BET_SIZES_ERROR = "GET_MAX_BET_SIZES_ERROR";

export const GET_MAX_NUM_OF_BETS_REQUEST = "GET_MAX_NUM_OF_BETS_REQUEST";
export const GET_MAX_NUM_OF_BETS_SUCCESS = "GET_MAX_NUM_OF_BETS_SUCCESS";
export const GET_MAX_NUM_OF_BETS_ERROR = "GET_MAX_NUM_OF_BETS_ERROR";

export const SET_ROULETTE_MAX_BET_SIZE = "SET_ROULETTE_MAX_BET_SIZE";
export const SET_ROULETTE_BET_AMOUNT = "SET_ROULETTE_BET_AMOUNT";
