import React from "react";

export default function FaceBook({ clicked }) {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clicked}
    >
      <path
        d="M22.0452 0H5.79517C3.10376 0 0.920166 2.18359 0.920166 4.875V21.125C0.920166 23.8164 3.10376 26 5.79517 26H22.0452C24.7366 26 26.9202 23.8164 26.9202 21.125V4.875C26.9202 2.18359 24.7366 0 22.0452 0ZM21.385 14.0039H18.9514V23.0078H14.8889V14.0039H13.3108V10.9688H14.8889V9.03516C14.8889 6.50391 15.9397 5 18.928 5H21.9514V8.02344H20.1936C19.0334 8.02344 18.9553 8.45312 18.9553 9.26562L18.9514 10.9688H21.717L21.385 14.0039Z"
        fill="white"
      />
    </svg>
  );
}
