import React from "react";

export default function ChatIcon({ numberOfUnreadMsg = 0, isAdminOnline = false, clicked }) {
  let textXPos = numberOfUnreadMsg.toString().length > 1 ? `12.5` : `15.5`;
  if (numberOfUnreadMsg > 99) {
    textXPos = "10";
  }
  return (
    <svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clicked}
    >
      <path
        d="M2 9C0.9 9 0 9.9 0 11V23L3 20H12C13.1 20 14 19.1 14 18V11C14 9.9 13.1 9 12 9H2ZM16 14V18C16 20.206 14.206 22 12 22H6V23C6 24.1 6.9 25 8 25H17L20 28V16C20 14.9 19.1 14 18 14H16Z"
        fill="#42A8DF"
      />
      {numberOfUnreadMsg !== 0 && (
        <circle cx="18" cy="9" r="9" fill="#CC2028" />
      )}
      {numberOfUnreadMsg !== 0 && (
        <text x={textXPos} y="12" fill="white" fontFamily="Roboto" fontSize="9">
          {numberOfUnreadMsg <= 99 && numberOfUnreadMsg}
          {numberOfUnreadMsg > 99 && "99+"}
        </text>
      )}
      <circle cx="3" cy="27" r="3" fill={ isAdminOnline ? `#27AE60` : `#CC2028`} />
    </svg>
  );
}
