import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { weiToEth } from "../../../../../services/ethereum/main";
import { formatNumber } from "../../../../../services/utils";
import { DP } from "../../../../../config/constants";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import FaceBook from "../../../../Decorative/SocialIcons/FaceBook";
import Twitter from "../../../../Decorative/SocialIcons/Twitter";
import InfoIcon from "../../../../Decorative/Icons/InfoIcon";

const DoN = ({
  title,
  shareUrl,
  hasWon,
  toggleModal,
  lastSelectedGameType,
  canPlayDoubleOrNothing,
  setDoubleOrNothing,
  hideRes,
  lastUserSelectedGameType,
  showDonTips,
  maxNumOfDonInRow,
  lastWinPrize
}) => {
  return (
    <div className="modal__content modal__content--winlose">
      <div className="content">
        {hasWon && (
          <Fragment>
            <p>Congratulations!</p>
            <h3 className="win">YOU WIN!</h3>
          </Fragment>
        )}
        {hasWon && (
          <Tooltip title={weiToEth(lastWinPrize)} theme="roll">
            <p className="prize">
              {formatNumber(weiToEth(lastWinPrize), DP + 3)}
            </p>
          </Tooltip>
        )}
        {!hasWon && (
          <Fragment>
            <p>Sorry!</p>
            <h3 className="lost">NO WIN</h3>
          </Fragment>
        )}
        {hasWon && (
          <div className="social">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="some-network__share-button"
            >
              <FaceBook />
            </FacebookShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="some-network__share-button"
            >
              <Twitter />
            </TwitterShareButton>
          </div>
        )}
        {hasWon && showDonTips && canPlayDoubleOrNothing && (
          <p>Would you like to try again?</p>
        )}
        {hasWon && showDonTips && !canPlayDoubleOrNothing && (
          <p>You reached the limit of double or nothing attempts!</p>
        )}
      </div>
      <div className="btn__group">
        {hasWon && canPlayDoubleOrNothing && (
          <button className="btn btn--primary" onClick={setDoubleOrNothing}>
            <Tooltip
              theme="roll"
              title={`Allows you to play another bet with a 50% chance to double the amount you won. Double or nothing can be played for maximum ${maxNumOfDonInRow} times in a row.`}
            >
              <InfoIcon />
            </Tooltip>{" "}
            Double Or Nothing
          </button>
        )}
        <button className="btn btn--dark" onClick={() => toggleModal()}>
          Done
        </button>
      </div>
    </div>
  );
};

DoN.propTypes = {};

export default DoN;
