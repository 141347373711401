import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleModal } from "../../../../actions/modal";

import Loader from '../../../Loader/Loader';
import "./AutoBet.scss";

class AutoBet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfRounds: 1,
      maxNumberOfRounds: props.autoBetMaxBets,
    };

    this.handleNumOfRounds = this.handleNumOfRounds.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.autoBetMaxBets !== this.props.autoBetMaxBets) {
      this.setState({ maxNumberOfRounds: this.props.autoBetMaxBets });
    }
  }

  handleNumOfRounds = (e) => {
    const { value } = e.target;
    const { maxNumberOfRounds } = this.state;

    if (+value > maxNumberOfRounds) return this.setState({ numOfRounds: maxNumberOfRounds });
    else if (+value < 1) return this.setState({ numOfRounds: 1 });

    this.setState({ numOfRounds: +value })
  };

  render() {
    const {
      toggleModal,
      isAutoBetting,
      autoBettingRounds,
      autoBettingCurrentRound,
      startAutoBet,
      stopAutoBet,
      startingAutoBetting,
      stoppingAutoBetting,
      isLoading,
    } = this.props;
    const { numOfRounds, maxNumberOfRounds } = this.state;

    return (
      <div className="main__section main__section--auto-bet bet__auto-bet">
        {isLoading && (
          <Loader size={85} isFullScreen={false} text={'Loading...'} />
        )}
        {!isLoading && !isAutoBetting && (
          <Fragment>
            <div className="bet__auto-bet__controls">
              <div className="bet__auto-bet__input-wrapper">
                <p>Auto-Bet: </p>
                <div>
                  <input type="number" min={1} max={maxNumberOfRounds}
                         disabled={startingAutoBetting}
                         value={numOfRounds} onChange={this.handleNumOfRounds}
                  />
                  <span>Number of Rounds</span>
                </div>
              </div>
              <button
                className="btn btn--primary"
                disabled={startingAutoBetting}
                onClick={() => toggleModal("ConfirmAutoBet", { startAutoBet, numOfRounds })}
              >
                Confirm
              </button>
            </div>
            <button
              onClick={() => toggleModal("MoveMaticEthToContract")}
              className="btn btn--primary"
            >
              Deposit to contract balance
            </button>
          </Fragment>
        )}
        {!isLoading && isAutoBetting && (
          <div className="bet__auto-bet__controls betting">
            <p>Auto-Betting: {autoBettingCurrentRound} of {autoBettingRounds}</p>
            <button
              className="btn btn--primary"
              disabled={stoppingAutoBetting}
              onClick={() => stopAutoBet()}
            >
              Cancel Auto-Betting
            </button>
          </div>
        )}
      </div>
    );
  }
}

AutoBet.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  autoBettingRounds: PropTypes.number.isRequired,
  autoBettingCurrentRound: PropTypes.number.isRequired,
  startAutoBet: PropTypes.func.isRequired,
  stopAutoBet: PropTypes.func.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
  stoppingAutoBetting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  autoBetMaxBets: PropTypes.number.isRequired,
};

const mapStateToProps = ({ games }) => ({
  autoBetMaxBets: games.autoBetMaxBets,
});

const mapDispatchToProps = { toggleModal };

export default connect(mapStateToProps, mapDispatchToProps)(AutoBet);
