import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./Notification.scss";

const Notification = ({ showNotif, notifType, notifMessage }) => (
  <div className={`notification${showNotif ? " notification--active" : ""}`}>
    <div className={`notification__body ${"notification__body--" + notifType}`}>
      <p>{notifMessage}</p>
    </div>
  </div>
);

Notification.propTypes = {
  showNotif: PropTypes.bool.isRequired,
  notifMessage: PropTypes.string.isRequired,
  notifType: PropTypes.string.isRequired
};

const mapStateToProps = ({ notification }) => ({
  showNotif: notification.displayed,
  notifMessage: notification.message,
  notifType: notification.type
});

export default connect(mapStateToProps)(Notification);
