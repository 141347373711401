import {
  ADD_MESSAGE_ERROR,
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  BAN_USER_ERROR,
  BAN_USER_REQUEST,
  BAN_USER_SUCCESS,
  UNBAN_USER_ERROR,
  UNBAN_USER_REQUEST,
  UNBAN_USER_SUCCESS,
  TOGGLE_BAN_USER_REQUEST,
  TOGGLE_BAN_USER_SUCCESS,
  TOGGLE_BAN_USER_ERROR,
  CHECK_IF_IS_ADMIN_ERROR,
  CHECK_IF_IS_ADMIN_REQUEST,
  CHECK_IF_IS_ADMIN_SUCCESS,
  CLEAR_UNREAD,
  CONNECTING_TO_SOCKET_ERROR,
  CONNECTING_TO_SOCKET_REQUEST,
  CONNECTING_TO_SOCKET_SUCCESS,
  DELETE_MSG_ERROR,
  DELETE_MSG_REQUEST,
  DELETE_MSG_SUCCESS,
  GET_CHAT_HISTORY_ERROR,
  GET_CHAT_HISTORY_REQUEST,
  GET_CHAT_HISTORY_SUCCESS,
  INCREMENT_UNREAD,
  REMOVE_MESSAGE_ERROR,
  REMOVE_MESSAGE_REQUEST,
  REMOVE_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SET_ADMIN_ONLINE,
  SET_MESSAGE_VALUE,
  TOGGLE_EMOJIS
} from "../actionTypes/chat";
import { apiPath, env } from "../config/client";
import io from "socket.io-client";
import { changeModalType } from "./modal";
import {
  removeBanUserFromApi,
  banUserFromApi,
  deleteMessageFromApi,
  getAdminsFromApi,
  getAdminsOnlineFromApi,
  getMessagesFromApi,
  sendMessageFromApi,
  getUserTitleFromApi
} from "../services/api";

export const connectToSocket = () => async (dispatch, getState) => {
  dispatch({ type: CONNECTING_TO_SOCKET_REQUEST });
  try {
    const { account } = getState().account;
    let socket;
    if (env === "dev") {
      socket = io.connect(`${apiPath}`)
    } else if (env === "prod" || env === "staging") {
      socket = io.connect(
        `${window.location.origin}`,
        { secure: true, path: "/chat/socket.io/" }
      );
    }

    socket.on("user-connected", ({ isAdminOnline }) => {
      dispatch(setAdminOnline(isAdminOnline));
    });

    socket.on("message-deleted", ({ messageId }) => {
      const { chatHistory } = getState().chat;
      chatHistory.forEach((item, index) => {
        if (item._id === messageId) {
          dispatch(removeMessage(index));
        }
      });
    });

    socket.on("user-disconnected", ({ isAdminOnline }) => {
      dispatch(setAdminOnline(isAdminOnline));
    });

    socket.on("user-banned", ({ address }) => {
      const { isAdmin } = getState().chat;
      if (address === account) {
        dispatch(connectToSocket());
      } else if (isAdmin) {
        const { chatHistory } = getState().chat;
        chatHistory.forEach((item, index) => {
          if (item.address === address) {
            dispatch(toggleBanUser(true, index));
          }
        });
      }
    });

    socket.on("user-unbanned", ({ address }) => {
      const { isAdmin } = getState().chat;
      if (address === account) {
        dispatch(connectToSocket());
      } else if (isAdmin) {
        const { chatHistory } = getState().chat;
        chatHistory.forEach((item, index) => {
          if (item.address === address) {
            dispatch(toggleBanUser(false, index));
          }
        });
      }
    });

    socket.on("user-tagged", ({ address }) => {
      const { account } = getState().account;
      if (address === account.toUpperCase()) {
        const { drawerRightComponent, drawerLeftComponent } = getState().drawer;
        if (drawerRightComponent !== "Chat" && drawerLeftComponent !== "Chat" ) dispatch(incrementUnreadMessages());
      }
    });

    socket.on("message", data => {
      dispatch(addMessage(data));
    });

    socket.on("error", e => {
      dispatch({ type: CONNECTING_TO_SOCKET_ERROR, payload: e.message });
      console.log(e);
    });

    socket.on("connect", async () => {
      dispatch(getAdmins());
      dispatch(getChatHistory());

      const numberOfAdminsOnlineRequest = await getAdminsOnlineFromApi();
      const numberOfAdminsOnline = await numberOfAdminsOnlineRequest.json();
      if (numberOfAdminsOnline > 0) {
        dispatch(setAdminOnline(true));
      }

      dispatch({ type: CONNECTING_TO_SOCKET_SUCCESS });
    });

    socket.on("reconnect", () => {
      dispatch({ type: CONNECTING_TO_SOCKET_REQUEST });
    });

    dispatch({ type: CONNECTING_TO_SOCKET_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: CONNECTING_TO_SOCKET_ERROR, payload: e.message });
  }
};

export const setAdminOnline = payload => dispatch => {
  dispatch({ type: SET_ADMIN_ONLINE, payload });
};

export const sendMessage = () => async (dispatch, getState) => {
  dispatch({ type: SEND_MESSAGE_REQUEST });
  const { nickname } = getState().account;
  const { messageValue } = getState().chat;
  if (messageValue === "") return;
  try {
    let response = await sendMessageFromApi(messageValue, nickname);
    if (response.status === 200) {
      dispatch({ type: SEND_MESSAGE_SUCCESS });
    } else {
      dispatch({ type: SEND_MESSAGE_ERROR, payload: response });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: SEND_MESSAGE_ERROR, payload: e.message });
  }
};

export const addMessage = message => async dispatch => {
  dispatch({ type: ADD_MESSAGE_REQUEST });
  try {
    const titleNumber = await getUserTitleFromApi(message.address);
    message.titleNumber = titleNumber;
    dispatch({ type: ADD_MESSAGE_SUCCESS, payload: message });
  } catch (e) {
    console.log(e);
    dispatch({ type: ADD_MESSAGE_ERROR, payload: e.message });
  }
};

export const removeMessage = index => (dispatch, getState) => {
  dispatch({ type: REMOVE_MESSAGE_REQUEST });
  try {
    const { chatHistory } = getState().chat;
    const payload = [...chatHistory];
    payload.splice(index, 1);
    dispatch({ type: REMOVE_MESSAGE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: REMOVE_MESSAGE_ERROR, payload: e.message });
  }
};

export const toggleBanUser = (bool, itemIndex) => (dispatch, getState) => {
  dispatch({ type: TOGGLE_BAN_USER_REQUEST });
  try {
    const { chatHistory } = getState().chat;
    const payload = [...chatHistory];
    payload.forEach((item, index) => {
      if (itemIndex === index) {
        item.banned = bool;
      }
    });
    dispatch({ type: TOGGLE_BAN_USER_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: TOGGLE_BAN_USER_ERROR, payload: e.message });
  }
};

export const getAdmins = () => async (dispatch, getState) => {
  dispatch({ type: CHECK_IF_IS_ADMIN_REQUEST });
  try {
    const response = await getAdminsFromApi();
    const data = await response.json();
    const admins = [];
    data.forEach(item => {
      admins.push(item.address);
    });
    const { account } = getState().account;

    const payload = admins.indexOf(account.toUpperCase()) > -1;
    dispatch({ type: CHECK_IF_IS_ADMIN_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: CHECK_IF_IS_ADMIN_ERROR, payload: e.message });
  }
};

export const banUser = address => async dispatch => {
  dispatch({ type: BAN_USER_REQUEST });
  dispatch(
    changeModalType("ModalLoader", { modalText: "Please wait..." }, false)
  );
  try {
    const response = await banUserFromApi(address);
    if (response.status === 200) {
      dispatch(
        changeModalType("BanUser", { success: true, ban: true, address })
      );
    } else {
      dispatch(
        changeModalType("BanUser", { success: false, ban: true, address })
      );
    }
    dispatch({ type: BAN_USER_SUCCESS });
  } catch (e) {
    dispatch({ type: BAN_USER_ERROR, payload: e.message });
    dispatch(
      changeModalType("BanUser", { success: false, ban: true, address })
    );
  }
};

export const removeBanUser = address => async dispatch => {
  dispatch({ type: UNBAN_USER_REQUEST });
  dispatch(
    changeModalType("ModalLoader", { modalText: "Please wait..." }, false)
  );
  try {
    const response = await removeBanUserFromApi(address);
    if (response.status === 200) {
      dispatch(
        changeModalType("BanUser", { success: true, removeBan: true, address })
      );
    } else {
      dispatch(
        changeModalType("BanUser", { success: false, removeBan: true, address })
      );
    }
    dispatch({ type: UNBAN_USER_SUCCESS });
  } catch (e) {
    dispatch({ type: UNBAN_USER_ERROR, payload: e.message });
    dispatch(
      changeModalType("BanUser", { success: false, removeBan: true, address })
    );
  }
};

export const deleteMessage = id => async dispatch => {
  dispatch({ type: DELETE_MSG_REQUEST });
  dispatch(
    changeModalType("ModalLoader", { modalText: "Please wait..." }, false)
  );
  try {
    const response = await deleteMessageFromApi(id);
    if (response.status === 200) {
      dispatch(changeModalType("DeleteMessage", { success: true }));
    } else {
      dispatch(changeModalType("DeleteMessage", { success: false }));
    }
    dispatch({ type: DELETE_MSG_SUCCESS });
  } catch (e) {
    dispatch({ type: DELETE_MSG_ERROR, payload: e.message });
    dispatch(changeModalType("DeleteMessage", { success: false }));
  }
};

export const getChatHistory = () => async dispatch => {
  dispatch({ type: GET_CHAT_HISTORY_REQUEST });
  try {
    const response = await getMessagesFromApi();
    let data = await response.json();
    data = await Promise.all(data.map(async item => {
      const titleNumber = await getUserTitleFromApi(item.address);
      item.titleNumber = titleNumber;
      return item;
    }));
    if (response.status === 200) {
      dispatch({ type: GET_CHAT_HISTORY_SUCCESS, payload: data });
    } else if (response.status !== 200) {
      dispatch({ type: GET_CHAT_HISTORY_ERROR, payload: data.message });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_CHAT_HISTORY_ERROR,
      payload: "Oops, there was an error getting chat data."
    });
  }
};

export const incrementUnreadMessages = () => (dispatch, getState) => {
  const { unreadMessages } = getState().chat;
  dispatch({ type: INCREMENT_UNREAD, payload: unreadMessages + 1 });
};

export const clearUnreadMessages = () => dispatch => {
  dispatch({ type: CLEAR_UNREAD });
};

export const setChatMessageVal = payload => dispatch => {
  dispatch({ type: SET_MESSAGE_VALUE, payload });
};

export const toggleEmojis = showOrHide => (dispatch, getState) => {
  const payload = showOrHide || !getState().chat.showEmojis;

  dispatch({ type: TOGGLE_EMOJIS, payload });
};
