import { CHANGE_PAGE } from "../actionTypes/pagination";

const INITIAL_STATE = {
  currentPage: 1,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: payload
      };

    default:
      return state;
  }
};
