import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { spinGameTypeToColor } from "../../../../../services/utils";
import { getPreviousSpins } from "../../../../../actions/betHistory";
import { connect } from "react-redux";
import ArrowRight from "../../../../Decorative/Icons/ArrowRight";
import ArrowLeft from "../../../../Decorative/Icons/ArrowLeft";

const SpinHistoryItem = ({ round, color }) => (
  <li>
    <Tooltip
      position="bottom-start"
      arrow="true"
      animation="fade"
      theme="roll"
      distance={15}
      html={
        <div className="previous-spin__tooltip">
          <div className="previous-spin__tooltip__types">
            <span className="previous-spin__tooltip__type">
              {spinGameTypeToColor(color)}
            </span>
            <span className="previous-spin__tooltip__type" />
          </div>
          <span className="previous-spin__tooltip__round">
            #{round} Betting round
          </span>
        </div>
      }
    >
      <div className={`previous-spin__single ${spinGameTypeToColor(color)}`} />
    </Tooltip>
  </li>
);

const SpinHistory = ({ history, page, getPreviousSpins }) => {
  if (page === 0)
    return [
      ...history.slice(0, 8).map(({ round, color }) => {
        return <SpinHistoryItem key={round} color={color} round={round} />;
      }),
      <li key="next" onClick={() => getPreviousSpins(page + 1)}>
        <div className="previous-spin__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    ];

  return [
    <li key="prev" onClick={() => getPreviousSpins(page - 1)}>
      <div className="previous-spin__single pagination">
        <ArrowLeft color="#465e71" />
      </div>
    </li>,
    ...history.map(({ round, color }) => {
      return <SpinHistoryItem key={round} color={color} round={round} />;
    }),
    history.length === 7 && (
      <li key="next" onClick={() => getPreviousSpins(page + 1)}>
        <div className="previous-spin__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    )
  ];
};

SpinHistory.propTypes = {
  history: PropTypes.array.isRequired,
  getPreviousSpins: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getPreviousSpins
};

export default connect(
  undefined,
  mapDispatchToProps
)(SpinHistory);
