import { sendScratchBetsFromApi } from '../../services/api';

const web3Coder = require('web3-eth-abi');
import BigNumber from "bignumber.js";
import {
  SET_SCRATCH_GAME_MODE,
  SET_SCRATCH_BET_AMOUNT,
  SET_SCRATCH_LISTENERS_INITIATED,
  SET_SCRATCH_LAST_ROUND_BLOCK,
  SET_SCRATCH_SHOW_CONTROLS,
  SET_CARDS,
  SET_ACTIVE_CARD,
  SET_SCRATCH_NUM_OF_CARDS,
  SET_SCRATCH_WIN_CHANCE,
  SET_SCRATCH_PAUSED,
  SET_SCRATCH_WIN_PRIZE,
  GET_SCRATCH_GAME_STATE_REQUEST,
  GET_SCRATCH_GAME_STATE_SUCCESS,
  GET_SCRATCH_GAME_STATE_ERROR,
  GET_SCRATCH_ROUND_STATE_REQUEST,
  GET_SCRATCH_ROUND_STATE_SUCCESS,
  GET_SCRATCH_ROUND_STATE_ERROR,
  GET_ACC_SCRATCH_TOTAL_BETS_REQUEST,
  GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS,
  GET_ACC_SCRATCH_TOTAL_BETS_ERROR,
  GET_SCRATCH_INIT_DATA_REQUEST,
  GET_SCRATCH_INIT_DATA_SUCCESS,
  GET_SCRATCH_INIT_DATA_ERROR,
  PLACE_SCRATCH_BET_REQUEST,
  PLACE_SCRATCH_BET_SUCCESS,
  PLACE_SCRATCH_BET_ERROR,
  START_SCRATCH_AUTO_BET_REQUEST,
  START_SCRATCH_AUTO_BET_SUCCESS,
  START_SCRATCH_AUTO_BET_ERROR,
  STOP_SCRATCH_AUTO_BET_REQUEST,
  STOP_SCRATCH_AUTO_BET_SUCCESS,
  STOP_SCRATCH_AUTO_BET_ERROR,
  UPDATE_SCRATCH_AUTO_BET_REQUEST,
  UPDATE_SCRATCH_AUTO_BET_SUCCESS,
  UPDATE_SCRATCH_AUTO_BET_ERROR,
  SCRATCH_AUTO_BET_STARTED,
  SCRATCH_AUTO_BET_STOPPED,
  SET_SCRATCH_AUTO_BET_DATA,
} from "../../actionTypes/games/scratch";
import {
  getBetsAllowedFromScratchContract,
  getTotalBetsFromScratchContract,
  lastBetForPlayerFromScratchContract,
  listenToGamePausedFromScratchContract,
  listenToRoundFinishedFromScratchContract,
  listenToRoundStartedFromScratchContract,
  placeBetFromScratchContract,
  getAutobetInfo,
  listenScratchAutoBetStartedEvent,
  listenScratchAutoBetStoppedEvent,
} from "../../services/ethereum/scratch";
import {
  calculateScratchWinChance,
  createCard,
  getCardsResult,
  getScratchContractConstValues
} from "../contractConstants/scratch";
import {
  addNewLevelModal,
  checkIfRanOutOfContractBalanceWhileAutoBetting,
  checkIsRunningOutOfContractBalanceWhileAutoBetting,
  getAccTotalBets,
  validatePlaceBetForScratch,
} from './games';
import { getUserBonusContractBalance, getWalletBalance, setFirstBet, } from "../account";
import { toggleModal } from "../modal";
import { sendTx } from "../txNotifications";
import { giveInfiniteApproval, weiToEth } from '../../services/ethereum/main';
import { getWETHApproval } from "../account";
import {
  addBadTokenWinNotification,
  areTokensDistributed,
  getBadTokenWinningProbability,
  getPlayerLevel,
} from "../badToken";
import { scratchGameAddress } from '../../services/contract';
import { startAutoBetFromContract, stopAutoBetFromContract } from '../../services/ethereum/games';
import {
  setScratchCostPerCardToLS,
  setScratchGameModeToLS,
  setScratchNumberOfCardsToLS,
} from '../../services/localStorage';

export const setScratchGameMode = (gameMode, addToLS = true) => (dispatch, getState) => {
  dispatch({ type: SET_SCRATCH_GAME_MODE, payload: gameMode });
  dispatch(setScratchWinChance());
  dispatch(getBadTokenWinningProbability());
  if (!addToLS) return;
  const { account } = getState().account;
  setScratchGameModeToLS(account, gameMode)

}

export const setScratchNumOfCards = (numOfCards, addToLS = true) => (dispatch, getState) => {
  dispatch({type: SET_SCRATCH_NUM_OF_CARDS, payload: numOfCards });
  const { costPerCard } = getState().gameScratch;
  dispatch(setScratchBetAmount(costPerCard));
  dispatch(getBadTokenWinningProbability());
  if (!addToLS) return;
  const { account } = getState().account;
  setScratchNumberOfCardsToLS(account, numOfCards);
}

export const getScratchGameState = () => async (dispatch, getState) => {
  dispatch({ type: GET_SCRATCH_GAME_STATE_REQUEST });
  try {
    const { firstBet } = getState().games;
    await dispatch(getScratchContractConstValues());
    await dispatch(getScratchGameRoundState());
    if (firstBet) await dispatch(getAccScratchTotalBets());

    dispatch({ type: GET_SCRATCH_GAME_STATE_SUCCESS });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SCRATCH_GAME_STATE_ERROR, payload: error.message });
  }
};

export const getScratchGameRoundState = () => async (dispatch, getState) => {
  dispatch({ type: GET_SCRATCH_ROUND_STATE_REQUEST });
  try {
    const { account } = getState().account;
    const { status, roundNumber, numberOfCards, costPerCard, gameMode, ...rest } =
      await lastBetForPlayerFromScratchContract()(account);

    console.log(
      "status: ", status,
      "\nroundNumber: ", roundNumber,
      "\nnumberOfCards: ", numberOfCards,
      "\ncostPerCard: ", costPerCard,
      "\ngameMode: ", gameMode,
      "\nrest: ", rest
    );

    if (status === "0" && roundNumber !== "0") {
      dispatch({
        type: GET_SCRATCH_ROUND_STATE_SUCCESS,
        payload: { status: "open", roundNumber }
      });
      dispatch(setScratchNumOfCards(numberOfCards));
      dispatch(setScratchBetAmount(costPerCard));
      dispatch(setScratchGameMode(gameMode));
      dispatch(setScratchShowControls(false));
      dispatch(handleRoundFinished());
    } else if (status === "1") {
      dispatch({
        type: GET_SCRATCH_ROUND_STATE_SUCCESS,
        payload: { status: "closed", roundNumber }
      });
      dispatch(setScratchNumOfCards(numberOfCards));
      dispatch(setScratchBetAmount(costPerCard));
      dispatch(setScratchGameMode(gameMode));
      dispatch(setScratchShowControls(false));
      dispatch(handleRoundFinished());
    } else if (status === "2" || (status === "0" && roundNumber === "0")) {
      dispatch({
        type: GET_SCRATCH_ROUND_STATE_SUCCESS,
        payload: { status: "finished", roundNumber }
      });
      dispatch(setScratchShowControls(true));
    }

    const { betAmount: amount, numberOfBets, payload, totalNumberOfBets } = await getAutobetInfo(account)
    if (Number(numberOfBets) > 0) {
      const currentRound = Number(totalNumberOfBets) - Number(numberOfBets);
      dispatch(setAutoBetData(Number(totalNumberOfBets), currentRound));
    }

  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SCRATCH_ROUND_STATE_ERROR, payload: error.message });
  }
};

export const setScratchBetAmount = (costPerCard, addToLS = true) => (dispatch, getState) => {
  try {
    const { numberOfCards } = getState().gameScratch;
    const betAmount = new BigNumber(costPerCard).times(numberOfCards).toString();
    dispatch({
      type: SET_SCRATCH_BET_AMOUNT,
      payload: {
        betAmount,
        costPerCard
      }
    });
    dispatch(getBadTokenWinningProbability());
    if (!addToLS) return;
    const { account } = getState().account;
    setScratchCostPerCardToLS(account, costPerCard);
  } catch (e) {
    console.log(e);
  }
};

export const getAccScratchTotalBets = () => async (dispatch, getState) => {
  dispatch({ type: GET_ACC_SCRATCH_TOTAL_BETS_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getTotalBetsFromScratchContract()(account);
    dispatch({ type: GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_ACC_SCRATCH_TOTAL_BETS_ERROR, payload: e.message });
  }
};

export const setScratchWinChance = () => (dispatch, getState) => {
  const { gameMode } = getState().gameScratch;
  const { winProbForWinTypeZero } = getState().scratchConstants;
  const payload = calculateScratchWinChance(gameMode, winProbForWinTypeZero);
  dispatch({ type: SET_SCRATCH_WIN_CHANCE, payload });
}

const getPayload = (numberOfCards, gameMode) => {
  return web3Coder.encodeParameter(
    {
      "ParentStruct": {
        "numberOfCards": 'uint256',
        "gameMode": "uint256"
      }
    },
    { numberOfCards, gameMode }
  );
}

export const setAutoBetData = (autoBettingRounds, autoBettingCurrentRound) => (dispatch) => {
  dispatch({ type: SET_SCRATCH_AUTO_BET_DATA, payload: { autoBettingRounds, autoBettingCurrentRound } });
};


export const startScratchAutoBet = (numberOfRounds) => async (dispatch, getState) => {
  dispatch({ type: START_SCRATCH_AUTO_BET_REQUEST });
  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      numberOfCards,
      costPerCard,
      betAmount,
      roundState,
      gameMode,
      winChance,
    } = getState().gameScratch;

    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minCardCost } = getState().gamesConstants;
    const { maxCardCost } = getState().scratchConstants;

    const { wethApproval } = getState().account;
    if (Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBetForScratch(
      betAmount,
      costPerCard,
      minCardCost,
      maxCardCost,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      START_SCRATCH_AUTO_BET_ERROR,
      dispatch,
      true
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    dispatch(setFirstBet(false));

    let payload = {};
    payload = getPayload( numberOfCards, gameMode);

    const hasSucceeded = await startAutoBetFromContract(
      scratchGameAddress, numberOfRounds, costPerCard, payload, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: START_SCRATCH_AUTO_BET_SUCCESS, payload: numberOfRounds });
    } else {
      dispatch({ type: START_SCRATCH_AUTO_BET_ERROR, payload: "There was an error while placing auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: START_SCRATCH_AUTO_BET_ERROR, payload: error.message });
  }
};

export const stopScratchAutoBet = () => async (dispatch, getState) => {
  dispatch({ type: STOP_SCRATCH_AUTO_BET_REQUEST });
  try {
    const { account } = getState().account;

    const hasSucceeded = await stopAutoBetFromContract(
      scratchGameAddress, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: STOP_SCRATCH_AUTO_BET_SUCCESS });
    } else {
      dispatch({ type: STOP_SCRATCH_AUTO_BET_ERROR, payload: "There was an error while stopping auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: STOP_SCRATCH_AUTO_BET_ERROR, payload: error.message });
  }
};

export const placeScratchBet = () => async (dispatch, getState) => {
  dispatch({ type: PLACE_SCRATCH_BET_REQUEST });

  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      numberOfCards,
      costPerCard,
      betAmount,
      roundState,
      gameMode,
      winChance,
    } = getState().gameScratch;

    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minCardCost } = getState().gamesConstants;
    const { maxCardCost } = getState().scratchConstants;

    const { wethApproval } = getState().account;
    if (Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    console.log(winChance);

    const isValid = validatePlaceBetForScratch(
      betAmount,
      costPerCard,
      minCardCost,
      maxCardCost,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      PLACE_SCRATCH_BET_ERROR,
      dispatch
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    dispatch(setFirstBet(false));

    const hasSucceeded = await placeBetFromScratchContract(
      numberOfCards,
      costPerCard,
      gameMode,
      account,
      betWithContractBalance,
      betWithContractBonusBalance,
      betAmount,
      sendTx,
      dispatch,
      getState
    );

    if (hasSucceeded) {
      setTimeout(() => dispatch(getWalletBalance(account)), 3000);
      dispatch(getUserBonusContractBalance());
      console.log("PLACE_SCRATCH_BET_SUCCESS");
      dispatch({ type: PLACE_SCRATCH_BET_SUCCESS });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: PLACE_SCRATCH_BET_ERROR, payload: error.message });
  }
};

export const listenForScratchRoundFinished = () => async (dispatch, getState) => {
  try {
    const callback = ({ round, player, resultHash }) => {
      const { account } = getState().account;
      const { activeGame } = getState().games;
      if (account.toUpperCase() !== player.toUpperCase() || activeGame !== "scratch") return;
      console.log("SCRATCH ROUND FINISHED CALLBACK");
      console.log(round, player, resultHash);
      dispatch({
        type: GET_SCRATCH_ROUND_STATE_SUCCESS,
        payload: { status: "finished", roundNumber: round }
      });
    };
    await listenToRoundFinishedFromScratchContract(callback);
  } catch (error) {
    console.log(error);
  }
};

export const setScratchLastRoundBlock = (payload) => (dispatch) =>
  dispatch({ type: SET_SCRATCH_LAST_ROUND_BLOCK, payload });

export const setScratchShowControls = payload => dispatch => {
  dispatch({ type: SET_SCRATCH_SHOW_CONTROLS, payload });
};

export const setCards = payload => (dispatch) => dispatch({ type: SET_CARDS, payload });

export const listenForScratchRoundStarted = () => async (dispatch, getState) => {
  const callback = async({ round, player, blockNumber }) => {
    const { account } = getState().account;
    const { activeGame, betWithContractBonusBalance } = getState().games;
    if (account.toUpperCase() === player.toUpperCase()) {
      const dataForServer = {
        [`${round}`]: {
          isBonus: betWithContractBonusBalance,
        }
      }
      await sendScratchBetsFromApi(dataForServer);
    }
    if (account.toUpperCase() !== player.toUpperCase() || activeGame !== "scratch") return;
    console.log("SCRATCH ROUND STARTED CALLBACK");
    console.log(round, player);
    dispatch({
      type: GET_SCRATCH_ROUND_STATE_SUCCESS,
      payload: { status: "open", roundNumber: round }
    });

    dispatch(setScratchShowControls(false));
    dispatch(setScratchLastRoundBlock(blockNumber));
    dispatch(handleRoundFinished());

    const { isAutoBetting, autoBetBalanceNotificationDismissed } = getState().gameScratch;
      if (isAutoBetting) {
        try {
          dispatch({
            type: UPDATE_SCRATCH_AUTO_BET_REQUEST,
            payload: ""
          });
          const { betAmount, numberOfBets, totalNumberOfBets } = await getAutobetInfo(player);
          if (!autoBetBalanceNotificationDismissed) await dispatch(checkIsRunningOutOfContractBalanceWhileAutoBetting(account, betAmount, totalNumberOfBets))
          if (Number(numberOfBets) === 0) {
            return dispatch({
              type: SCRATCH_AUTO_BET_STOPPED,
              payload: 0
            });
          }
          const currBetNumber = Number(totalNumberOfBets) - Number(numberOfBets);
          dispatch({
            type: UPDATE_SCRATCH_AUTO_BET_SUCCESS,
            payload: currBetNumber
          });
        } catch (error) {
          dispatch({
            type: UPDATE_SCRATCH_AUTO_BET_ERROR,
            payload: error.message
          });
        }
      }
  };
  await listenToRoundStartedFromScratchContract(callback);
};

export const setActiveCard = (payload) => (dispatch) => dispatch({ type: SET_ACTIVE_CARD, payload })

export const revealCard = () => (dispatch, getState) => {
  const { activeCard, cards } = getState().gameScratch;
  const payload = [...cards];
  payload[activeCard].cardRevealed = true;
  payload[activeCard].itemsRevealed = 9;
  if (areAllCardsRevealed(payload)) dispatch(checkScratchWinning());
  dispatch(setCards(payload));
}

export const revealAllCards = () => (dispatch, getState) => {
  const { cards } = getState().gameScratch;
  const payload = [...cards].map(card => {
    card.cardRevealed = true;
    card.itemsRevealed = 9;
    return card;
  });
  dispatch(checkScratchWinning());
  dispatch(setCards(payload));
}

export const itemRevealed = (iconType) => (dispatch, getState) => {
  const { activeCard, cards } = getState().gameScratch;
  const payload = [...cards];
  payload[activeCard].itemsRevealed += 1;
  if (iconType === payload[activeCard].winType) payload[activeCard].winItemsRevealed += 1;
  if (payload[activeCard].cardWin && payload[activeCard].winItemsRevealed === 3) payload[activeCard].cardRevealed = true;
  if (payload[activeCard].itemsRevealed === 9) payload[activeCard].cardRevealed = true;
  if (areAllCardsRevealed(payload)) dispatch(checkScratchWinning());
  dispatch(setCards(payload));
}

export const areAllCardsRevealed = (cards) =>
  !cards.map(({ cardRevealed }) => cardRevealed).includes(false);

export const hasWonAnyCard = (cards) => cards.map(({ cardWin }) => cardWin).includes(true);

export const numOfWinningCards = (cards) =>
  cards.map(({ cardWin }) => cardWin).filter(item => item === true).length;

export const setScratchWinningPrize = (payload) => (dispatch) => dispatch({ type: SET_SCRATCH_WIN_PRIZE, payload });

export const handleRoundFinished = () => (dispatch, getState) => {
  dispatch({ type: GET_SCRATCH_ROUND_STATE_REQUEST });
  try {
    const { account } = getState().account;
    const { bettingRound } = getState().gameScratch;
    const handleRoundFinishedInterval = setInterval(async () => {
      const { status, roundNumber, resultHash, costPerCard, numberOfCards, gameMode } =
        await lastBetForPlayerFromScratchContract()(account);
      console.log(bettingRound, status, roundNumber);
      if (bettingRound === +roundNumber && status === "2") {
        console.log("SCRATCH ROUND FINISHED", resultHash);
        clearInterval(handleRoundFinishedInterval);

        const { cards, totalWon, tokensWon, profit, betAmount, initHash } = await getCardsResult(resultHash, +numberOfCards, costPerCard, +gameMode, account);
        dispatch(setScratchWinningPrize(totalWon));
        let cardsArray = cards.map(
          ({ cardWin, winType, cardAmount, resultHash, resultNumber }) =>
            ({
              cardWin,
              winType,
              cardAmount,
              costPerCard,
              resultHash,
              resultNumber,
              numberOfCards: +numberOfCards,
              itemsRevealed: 0,
              winItemsRevealed: 0,
              cardRevealed: false,
              cardItems: createCard(cardWin, winType)
            })
        );

        dispatch(setCards(cardsArray));
        dispatch({
          type: GET_SCRATCH_ROUND_STATE_SUCCESS,
          payload: { status: "finished", roundNumber }
        });

        const { activeGame } = getState().games;
        if (activeGame === "scratch") {
          dispatch(addBadTokenWinNotification("0"));
          const areDistributed = await areTokensDistributed(account);
          if (!areDistributed) {
            dispatch(addBadTokenWinNotification(weiToEth(tokensWon)));
          }
        }
        const { playerLevel } = getState().badToken;
        if (Number(playerLevel) < 100) await dispatch(addNewLevelModal());
        dispatch(getPlayerLevel());
        dispatch(getAccTotalBets());
        dispatch(getWalletBalance(account));
        const { isAutoBetting } = getState().gameScratch;
        if (isAutoBetting) dispatch(revealAllCards());
      }
    }, 3000);
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SCRATCH_ROUND_STATE_REQUEST, payload: error.message });
  }
};

export const checkScratchWinning = () => (dispatch, getState) => {
  const { activeGame } = getState().games;

  if (activeGame === "scratch") {
    const { cards, winPrize } = getState().gameScratch;
    const { modalOpen, modalComponent } = getState().modal;

    if (modalOpen && (modalComponent !== "WinLose" || modalComponent !== "ModalLoader"))
      dispatch(toggleModal());

    dispatch(
      toggleModal("WinLose", {
        cards,
        winPrize,
        hasWon: hasWonAnyCard(cards),
        game: "scratch",
        isSide: true,
      })
    );
    const { isAutoBetting } = getState().gameScratch;
    if (isAutoBetting) setTimeout(() => {
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());
    }, 2000);
  }

  dispatch(getUserBonusContractBalance());
};

export const setScratchPaused = () => async (dispatch) => {
  try {
    const scratch = await getBetsAllowedFromScratchContract()();
    dispatch({ type: SET_SCRATCH_PAUSED, payload: !scratch });
  } catch (error) {
    console.log(error);
  }
}

export const listenForScratchPaused = () => (dispatch) => {
  const callback = ({ bool }) => {
    console.log("SCRATCH PAUSED CALLBACK");
    dispatch({ type: SET_SCRATCH_PAUSED, payload: bool });
    if (!bool) {
      dispatch(getScratchInitialData(false));
    }
  };
  listenToGamePausedFromScratchContract(callback);
};

export const listenForScratchAutoBetStarted = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, noOfBets, blockNumber) => {
          try {

            dispatch({
              type: SCRATCH_AUTO_BET_STARTED,
              payload: noOfBets.toString()
            });


          } catch (error) {
            console.log(error);
          }
    };

    listenScratchAutoBetStartedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};


export const listenForScratchAutoBetStopped = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, remainingBets, blockNumber) => {
          try {
            const { betAmount } = await getAutobetInfo(account);
            await dispatch(checkIfRanOutOfContractBalanceWhileAutoBetting(account, betAmount));
            dispatch({
              type: SCRATCH_AUTO_BET_STOPPED,
              payload: remainingBets
            });
            dispatch(setScratchShowControls(true));
            dispatch(setActiveCard(0));
            dispatch(setCards([]));
            dispatch(getScratchContractConstValues());
          } catch (error) {
            console.log(error);
          }
    };

    listenScratchAutoBetStoppedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};

export const initScratchSpecificListeners = () => (dispatch) => {
  dispatch(listenForScratchRoundStarted());
  dispatch(listenForScratchPaused());
  dispatch(listenForScratchAutoBetStarted());
  dispatch(listenForScratchAutoBetStopped());
  dispatch({ type: SET_SCRATCH_LISTENERS_INITIATED })
};

export const getScratchInitialData = (shouldGetBetsAllowed = true) => async (dispatch, getState) => {
  dispatch({ type: GET_SCRATCH_INIT_DATA_REQUEST });
  try {
    if (shouldGetBetsAllowed) dispatch(setScratchPaused());
    await dispatch(getScratchGameState());
    const { scratchListenersInitiated } = getState().gameScratch;
    if (!scratchListenersInitiated) {
      dispatch(initScratchSpecificListeners());
      const { minBetSize } = getState().gamesConstants;
      dispatch(setScratchBetAmount(minBetSize));
    }
    dispatch(setScratchWinChance());
    dispatch(getBadTokenWinningProbability());
    dispatch({ type: GET_SCRATCH_INIT_DATA_SUCCESS });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SCRATCH_INIT_DATA_ERROR, payload: error.message });
  }
};
