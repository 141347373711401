export const CONNECT_PROVIDER = "CONNECT_PROVIDER";
export const CONNECT_PROVIDER_SUCCESS = "CONNECT_PROVIDER_SUCCESS";
export const CONNECT_PROVIDER_FAILURE = "CONNECT_PROVIDER_FAILURE";

export const GET_WETH_APPROVAL_REQUEST = "GET_WETH_APPROVAL_REQUEST";
export const GET_WETH_APPROVAL_SUCCESS = "GET_WETH_APPROVAL_SUCCESS";
export const GET_WETH_APPROVAL_ERROR = "GET_WETH_APPROVAL_ERROR";

export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_FINISHED = "LOGIN_FINISHED";

export const SET_IS_REGISTERED = "SET_IS_REGISTERED";

export const SET_REMEMBER_USER = "SET_REMEMBER_USER";

export const GET_NICKNAME_REQUEST = "GET_NICKNAME_REQUEST";
export const GET_NICKNAME_SUCCESS = "GET_NICKNAME_SUCCESS";
export const GET_NICKNAME_ERROR = "GET_NICKNAME_ERROR";

export const GET_WALLET_BALANCE_REQUEST = "GET_WALLET_BALANCE_REQUEST";
export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_ERROR = "GET_WALLET_BALANCE_ERROR";

export const DEPOSIT_ETH_REQUEST = "DEPOSIT_ETH_REQUEST";
export const DEPOSIT_ETH_SUCCESS = "DEPOSIT_ETH_SUCCESS";
export const DEPOSIT_ETH_ERROR = "DEPOSIT_ETH_ERROR";

export const DEPOSIT_BAD_REQUEST = "DEPOSIT_BAD_REQUEST";
export const DEPOSIT_BAD_SUCCESS = "DEPOSIT_BAD_SUCCESS";
export const DEPOSIT_BAD_ERROR = "DEPOSIT_BAD_ERROR";

export const WITHDRAW_ETH_REQUEST = "WITHDRAW_ETH_REQUEST";
export const WITHDRAW_ETH_SUCCESS = "WITHDRAW_ETH_SUCCESS";
export const WITHDRAW_ETH_ERROR = "WITHDRAW_ETH_ERROR";

export const GET_ETH_BALANCE_REQUEST = "GET_ETH_BALANCE_REQUEST";
export const GET_ETH_BALANCE_SUCCESS = "GET_ETH_BALANCE_SUCCESS";
export const GET_ETH_BALANCE_ERROR = "GET_WALLET_BALANCE_ERROR";

export const RESUME_WITHDRAWAL_REQUEST = "RESUME_WITHDRAWAL_REQUEST";
export const RESUME_WITHDRAWAL_SUCCESS = "RESUME_WITHDRAWAL_SUCCESS";
export const RESUME_WITHDRAWAL_ERROR = "RESUME_WITHDRAWAL_ERROR";

export const GET_USER_CONTRACT_BALANCE_REQUEST =
  "GET_USER_CONTRACT_BALANCE_REQUEST";
export const GET_USER_CONTRACT_BALANCE_SUCCESS =
  "GET_USER_CONTRACT_BALANCE_SUCCESS";
export const GET_USER_CONTRACT_BALANCE_ERROR =
  "GET_USER_CONTRACT_BALANCE_ERROR";

export const SIGN_TO_SERVER_REQUEST = "SIGN_TO_SERVER_REQUEST";
export const SIGN_TO_SERVER_SUCCESS = "SIGN_TO_SERVER_SUCCESS";
export const SIGN_TO_SERVER_ERROR = "SIGN_TO_SERVER_ERROR";

export const SET_NICKNAME_REQUEST = "SET_NICKNAME_REQUEST";
export const SET_NICKNAME_SUCCESS = "SET_NICKNAME_SUCCESS";
export const SET_NICKNAME_ERROR = "SET_NICKNAME_ERROR";

export const GET_USER_BONUS_CONTRACT_BALANCE_REQUEST =
  "GET_USER_BONUS_CONTRACT_BALANCE_REQUEST";
export const GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS =
  "GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS";
export const GET_USER_BONUS_CONTRACT_BALANCE_ERROR =
  "GET_USER_BONUS_CONTRACT_BALANCE_ERROR";

export const SET_FIRST_BET_FLAG = "SET_FIRST_BET_FLAG";

export const ACCEPT_TOS_REQUEST = "ACCEPT_TOS_REQUEST";
export const ACCEPT_TOS_SUCCESS = "ACCEPT_TOS_SUCCESS";
export const ACCEPT_TOS_ERROR = "ACCEPT_TOS_ERROR";

export const SEND_VERIFICATION_STEP_ONE_REQUEST = "SEND_VERIFICATION_STEP_ONE_REQUEST";
export const SEND_VERIFICATION_STEP_ONE_SUCCESS = "SEND_VERIFICATION_STEP_ONE_SUCCESS";
export const SEND_VERIFICATION_STEP_ONE_ERROR = "SEND_VERIFICATION_STEP_ONE_ERROR";

export const SEND_VERIFICATION_STEP_TWO_REQUEST = "SEND_VERIFICATION_STEP_TWO_REQUEST";
export const SEND_VERIFICATION_STEP_TWO_SUCCESS = "SEND_VERIFICATION_STEP_TWO_SUCCESS";
export const SEND_VERIFICATION_STEP_TWO_ERROR = "SEND_VERIFICATION_STEP_TWO_ERROR";

export const ASSOCIATE_WITH_AFF_CODE_REQUEST = "ASSOCIATE_WITH_AFF_CODE_REQUEST";
export const ASSOCIATE_WITH_AFF_CODE_SUCCESS = "ASSOCIATE_WITH_AFF_CODE_SUCCESS";
export const ASSOCIATE_WITH_AFF_CODE_ERROR = "ASSOCIATE_WITH_AFF_CODE_ERROR";

export const SET_AFF_CODE_REQUEST = "SET_AFF_CODE_REQUEST";
export const SET_AFF_CODE_SUCCESS = "SET_AFF_CODE_SUCCESS";
export const SET_AFF_CODE_ERROR = "SET_AFF_CODE_ERROR";

export const GET_AFF_TOKEN_BALANCE_REQUEST = "GET_AFF_TOKEN_BALANCE_REQUEST";
export const GET_AFF_TOKEN_BALANCE_SUCCESS = "GET_AFF_TOKEN_BALANCE_SUCCESS";
export const GET_AFF_TOKEN_BALANCE_ERROR = "GET_AFF_TOKEN_BALANCE_ERROR";

export const TRANSFER_AFF_TOKEN_REQUEST = "TRANSFER_AFF_TOKEN_REQUEST";
export const TRANSFER_AFF_TOKEN_SUCCESS = "TRANSFER_AFF_TOKEN_SUCCESS";
export const TRANSFER_AFF_TOKEN_ERROR = "TRANSFER_AFF_TOKEN_ERROR";

export const GET_TOKEN_CURRENT_AFFILIATES_REQUEST = "GET_TOKEN_CURRENT_AFFILIATES_REQUEST";
export const GET_TOKEN_CURRENT_AFFILIATES_SUCCESS = "GET_TOKEN_CURRENT_AFFILIATES_SUCCESS";
export const GET_TOKEN_CURRENT_AFFILIATES_ERROR = "GET_TOKEN_CURRENT_AFFILIATES_ERROR";

export const GET_AFF_TOKEN_DATA_REQ = "GET_AFF_TOKEN_DATA_REQ";
export const GET_AFF_TOKEN_DATA_SUCCESS = "GET_AFF_TOKEN_DATA_SUCCESS";

export const BREAK_AFFILIATION_REQUEST = "BREAK_AFFILIATION_REQUEST";
export const BREAK_AFFILIATION_SUCCESS = "BREAK_AFFILIATION_SUCCESS";
export const BREAK_AFFILIATION_ERROR = "BREAK_AFFILIATION_ERROR";

export const GET_TOKEN_PAST_AFFILIATES_REQUEST = "GET_TOKEN_PAST_AFFILIATES_REQUEST";
export const GET_TOKEN_PAST_AFFILIATES_SUCCESS = "GET_TOKEN_PAST_AFFILIATES_SUCCESS";
export const GET_TOKEN_PAST_AFFILIATES_ERROR = "GET_TOKEN_PAST_AFFILIATES_ERROR";

export const SET_SENT_AFFILIATE_REQUEST = "SET_SENT_AFFILIATE_REQUEST";
export const SET_SENT_AFFILIATE_SUCCESS = "SET_SENT_AFFILIATE_SUCCESS";
export const SET_SENT_AFFILIATE_FAILURE = "SET_SENT_AFFILIATE_FAILURE";

export const SUBMIT_AFFILIATE_REQUEST = "SUBMIT_AFFILIATE_REQUEST";
export const SUBMIT_AFFILIATE_SUCCESS = "SUBMIT_AFFILIATE_SUCCESS";
export const SUBMIT_AFFILIATE_FAILURE = "SUBMIT_AFFILIATE_FAILURE";
