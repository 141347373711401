import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUsername } from "../../../../actions/account";
import { toggleModal } from "../../../../actions/modal";

import "./Nickname.scss";

class Nickname extends Component {
  constructor(props) {
    super(props);
    this.state = { nickname: "" };

    this.handleNicknameInput = this.handleNicknameInput.bind(this);
  }

  handleNicknameInput = e => {
    const { value } = e.target;
    this.setState({ nickname: value });
  };

  render() {
    const {
      setUsername, showSkip, toggleModal, isAutoBet, numberOfRounds, settingNickname, settingNicknameErr
    } = this.props;

    const disableSubmit =
      settingNickname ||
      this.state.nickname === "" ||
      this.state.nickname.length > 15 ||
      this.state.nickname.includes(" ");

    let error = "";

    if (this.state.nickname.includes(" ")) {
      error = "Nickname can't have space in it.";
    }
    if (this.state.nickname.length > 15) {
      error = "Nickname can be 15 characters long";
    }

    return (
      <div className="modal__content modal__content--nickname">
        <p className="description">
          You can choose to create a nickname, which will be associated with
          your current Ethereum address. Think of it like a username! If you
          don't set a nickname we'll simply display your Ethereum address.
        </p>
        <p className="title">Please enter your nickname:</p>
        <input
          className="input--nickname"
          type="text"
          placeholder="Your name here"
          onChange={this.handleNicknameInput}
        />
        {error !== "" && <p className="input__error">{error}</p>}
        {settingNicknameErr !== "" && <p className="input__error">{settingNicknameErr}</p>}
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => setUsername(this.state.nickname)}
            disabled={disableSubmit}
          >
            Done
          </button>
          {showSkip && (
            <button
              className="btn btn--dark"
              onClick={() => toggleModal("", { isAutoBet, numberOfRounds })}
            >
              Skip
            </button>
          )}
          {!showSkip && (
            <button
              className="btn btn--dark"
              onClick={() => toggleModal()}
            >
              Close
            </button>
          )}
        </div>
      </div>
    );
  }
}

Nickname.propTypes = {
  setUsername: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  settingNickname: PropTypes.bool.isRequired,
  settingNicknameErr: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account }) => ({
  settingNickname: account.settingNickname,
  settingNicknameErr: account.settingNicknameErr,
});

const mapDispatchToProps = {
  setUsername,
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nickname);
