import {
  SET_DOUBLE_OR_NOTHING_RES,
  SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT,
  GET_MAX_NUM_DON_IN_ROW_REQUEST,
  GET_MAX_NUM_DON_IN_ROW_SUCCESS,
  GET_MAX_NUM_DON_IN_ROW_ERROR
} from "../../actionTypes/games/doubleOrNothing";

const INITIAL_STATE = {
  hasWonDoubleOrNothing: false,
  doubleOrNothingWinCount: 0,

  maxNumOfDonInRow: 0,
  gettingMaxNumOfDonInRow: false,
  gettingMaxNumOfDonInRowError: ""
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DOUBLE_OR_NOTHING_RES:
      return { ...state, hasWonDoubleOrNothing: payload };

    case SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT:
      return { ...state, doubleOrNothingWinCount: payload };

    case GET_MAX_NUM_DON_IN_ROW_REQUEST:
      return {
        ...state,
        gettingMaxNumOfDonInRow: true,
        gettingMaxNumOfDonInRowError: ""
      };

    case GET_MAX_NUM_DON_IN_ROW_SUCCESS:
      return {
        ...state,
        maxNumOfDonInRow: payload,
        gettingMaxNumOfDonInRow: false,
        gettingMaxNumOfDonInRowError: ""
      };

    case GET_MAX_NUM_DON_IN_ROW_ERROR:
      return {
        ...state,
        gettingMaxNumOfDonInRow: false,
        gettingMaxNumOfDonInRowError: payload
      };

    default:
      return state;
  }
};
