import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { betValToName } from "../../../../../services/utils";
import { getPreviousRolls } from "../../../../../actions/betHistory";
import ArrowRight from "../../../../Decorative/Icons/ArrowRight";
import ArrowLeft from "../../../../Decorative/Icons/ArrowLeft";

const RollHistoryItem = ({ round, number }) => (
  <li>
    <Tooltip
      position="bottom-start"
      arrow="true"
      animation="fade"
      theme="roll"
      distance={15}
      html={
        <div className="previous-roll__tooltip">
          <div className="previous-roll__tooltip__types">
            <span className="previous-roll__tooltip__type">
              {Number(number) !== 0 ? (Number(number) % 2 ? "Odd" : "Even") : ""}
            </span>
            <span className="previous-roll__tooltip__type">
              {betValToName(number)}
            </span>
          </div>
          <span className="previous-roll__tooltip__round">
            #{round} Betting round
          </span>
        </div>
      }
    >
      <div className="previous-roll__single">{number}</div>
    </Tooltip>
  </li>
);

const RollHistory = ({ history, page, getPreviousRolls }) => {
  if (page === 0)
    return [
      ...history.slice(0, 8).map(({ round, number }) => {
        return <RollHistoryItem key={round} number={number} round={round} />;
      }),
      <li key="next" onClick={() => getPreviousRolls(page + 1)}>
        <div className="previous-spin__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    ];

  return [
    <li key="prev" onClick={() => getPreviousRolls(page - 1)}>
      <div className="previous-spin__single pagination">
        <ArrowLeft color="#465e71" />
      </div>
    </li>,
    ...history.map(({ round, number }) => {
      return <RollHistoryItem key={round} number={number} round={round} />;
    }),
    history.length === 7 && (
      <li key="next" onClick={() => getPreviousRolls(page + 1)}>
        <div className="previous-spin__single pagination">
          <ArrowRight color="#465e71" />
        </div>
      </li>
    )
  ];
};

RollHistory.propTypes = {
  history: PropTypes.array.isRequired,
  getPreviousRolls: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getPreviousRolls
};

export default connect(
  null,
  mapDispatchToProps
)(RollHistory);
