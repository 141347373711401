import {
  TIME_OFFSET,
  START_ROUND_TIMER,
  CLEAR_ROUND_TIMER,
  SET_ROUND_TIMER_REQUEST,
  SET_ROUND_TIMER_SUCCESS,
  SET_ROUND_TIMER_ERROR
} from "../actionTypes/roundTimer";
import { closeRollRound } from "./games/roll";
import { getCurrentTime } from "../services/api";
import { closeSpinRound } from "./games/spin";

/**
 * Sets a time offset for the current player by comparing systems
 * UTC time and UTC time from the 3rd party API
 *
 * @return {Function}
 */
export const setTimeOffset = () => async dispatch => {
  const correctTime = await getCurrentTime();
  const payload = Math.ceil(Date.now() / 1000) - correctTime;
  dispatch({
    type: TIME_OFFSET,
    payload
  });
};

/**
 * Start a betting timer for a given duration
 *
 * @param game
 * @param duration
 * @return {Function}
 */
export const startTimer = (game, duration) => (dispatch, getState) => {
  let timer = duration,
    minutes,
    seconds;
  const interval = setInterval(async () => {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    const { activeGame } = getState().games;

    if (activeGame !== game) {
      clearInterval(interval);
      dispatch({ type: CLEAR_ROUND_TIMER });
      return;
    }

    if (minutes === "00" && seconds === "00") {
      clearInterval(interval);
      dispatch({ type: CLEAR_ROUND_TIMER });
      console.log("ODJE LI SI 1");
      if (game === "roll") dispatch(closeRollRound());
      if (game === "spin") dispatch(closeSpinRound());
      return;
    }

    if (--timer < 0) {
      timer = duration;
    }

    dispatch({ type: START_ROUND_TIMER, payload: `${minutes}:${seconds}` });
  }, 1000);
};

/**
 * Sets a round timer
 *
 * @return {Function}
 */
export const setRoundTimer = game => (dispatch, getState) => {
  let roundTimestamp, state, time;

  dispatch({ type: SET_ROUND_TIMER_REQUEST });

  if (game === "roll") {
    let { lastRoundTimestamp, roundState } = getState().gameRoll;
    const { roundTime } = getState().rollConstants;
    roundTimestamp = lastRoundTimestamp;
    state = roundState;
    time = roundTime;
  }
  if (game === "spin") {
    let { lastRoundTimestamp, roundState } = getState().gameSpin;
    const { roundTime } = getState().spinConstants;
    roundTimestamp = lastRoundTimestamp;
    state = roundState;
    time = roundTime;
    console.log(state, roundTimestamp, time);
  }

  const { timeOffset } = getState().roundTimer;
  if (state !== "open") return dispatch({ type: SET_ROUND_TIMER_SUCCESS });
  try {
    const date = Date.now();
    const dateNow = Math.floor(date / 1000) - timeOffset;
    const timeElapsed = dateNow - roundTimestamp;
    const timer = time - timeElapsed;
    console.log(timer, time, timeElapsed, roundTimestamp);
    if (timer > 0) dispatch(startTimer(game, timer));
    else {
      console.log("ODJE LI SI 2");
      if (game === "roll") dispatch(closeRollRound());
      if (game === "spin") dispatch(closeSpinRound());
    }
    dispatch({ type: SET_ROUND_TIMER_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({ type: SET_ROUND_TIMER_ERROR, payload: e.message });
  }
};
