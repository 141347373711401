import {
  ADD_NOTIFICATION,
  CLOSE_NOTIFICATION,
  CHANGE_NOTIFICATION,
} from "../actionTypes/txNotifications";

import { executeMetaTransaction, getExecuteMetaTransactionSignature } from "../services/utils.js";

/**
 * Adds a tx notification to the right corner of the app
 *
 * @return {Function}
 */
export const addNotification = (
  id,
  type,
  title,
  description,
  hash = ""
) => dispatch => {
  const payload = {
    id,
    type,
    title,
    description,
    hash
  };

  dispatch({ type: ADD_NOTIFICATION, payload });
};

/**
 * Closes a tx if the tx is still present
 *
 * @param id {Number}
 *
 * @return {Function}
 */
export const closeNotification = id => (dispatch, getState) => {
  const notifications = [...getState().txNotifications.notifications];
  const index = notifications.findIndex(notif => notif.id === id);

  if (index < 0) return;

  notifications.splice(index, 1);

  dispatch({ type: CLOSE_NOTIFICATION, payload: notifications });
};

export const changeNotification = (id, changes) => (dispatch, getState) => {
  const notifications = [...getState().txNotifications.notifications];
  const index = notifications.findIndex(notif => notif.id === id);

  if (index < 0) return;

  notifications.splice(index, 1, { ...notifications[index], ...changes });

  dispatch({ type: CHANGE_NOTIFICATION, payload: notifications });
};

/**
 * Handles a transactions status in the notification
 *
 * @param txPromise {Promise}
 * @param meta {Object}
 * @param dispatch {Function}
 * @param getState {Function}
 * @param shouldStore {Boolean}
 * @param firstCallback {Function}
 * @return {Promise<any>}
 */
export const sendTx = (
  txObject,
  {
    promiseTitle,
    successTitle,
    errorTitle,
    notifyOf,
    amount,
    gameType,
    gameSubType,
    betValue,
    nickname,
    game
  },
  dispatch,
  getState,
  shouldStore = true,
  firstCallback
) =>
  new Promise(async (resolve, reject) => {
    const id = getState().txNotifications.notifications.length;
    let confirmed = false;

    dispatch(
      addNotification(id, "loading", promiseTitle, "Waiting for transaction signature...")
    );

    const closeThisNotification = () => {
      setTimeout(() => {
        dispatch(closeNotification(id));
      }, 5000);
    };


    let signature;

    try {
      signature =
      await getExecuteMetaTransactionSignature(txObject.contract, txObject.functionSignature, txObject.account, txObject.domainData);
    } catch (error) {
      dispatch(
        changeNotification(id, {
          tx: "",
          title: errorTitle,
          type: "error",
          description: error.code
        })
      );
      closeThisNotification();
      reject(error);
    }

    const formatTx = hash => `${hash.slice(0, 8)}...`;


    try {
      executeMetaTransaction(txObject.contract, txObject.functionSignature, txObject.account, signature.r, signature.s, signature.v)
        .on("transactionHash", hash => {
          const description = `Transaction ${formatTx(
            hash
          )} was created. Waiting for confirmation`;
          dispatch(changeNotification(id, { tx: hash, description }));
          if(firstCallback){
            firstCallback(hash);
          }

        })
        .on("confirmation", (number, receipt) => {
          if (number > 1) return;
          const { transactionHash } = receipt;
          const description = `Transaction ${formatTx(
            transactionHash
          )} was confirmed`;
          dispatch(
            changeNotification(id, {
              title: successTitle,
              type: "success",
              amount,
              description
            })
          );
          closeThisNotification();
          confirmed = true;
          resolve(receipt);
        })
        .on("error", err => {
          console.log(err);
          dispatch(
            changeNotification(id, {
              tx: "",
              title: errorTitle,
              type: "error",
              description: err.code
            })
          );
          closeThisNotification();
          reject(err);
        });
    } catch (err) {
      closeThisNotification();
      reject(err);
    }
  });

  /**
 * Handles a transactions status in the notification
 *
 * @param txPromise {Promise}
 * @param title {String}
 * @param dispatch {Function}
 * @param getState {Function}
 * @param callback {Function}
 * @param finalCallback {Function}
 * @return {Promise<any>}
 */
export const sendTxETH = (
  txPromise,
  {
    promiseTitle,
    successTitle,
    errorTitle,
    notifyOf,
    amount,
    isEth,
  },
  dispatch,
  getState,
  callback,
  finalCallback
) =>
  new Promise(async (resolve, reject) => {
    const id = getState().txNotifications.notifications.length;
    let confirmed = false;

    dispatch(
      addNotification(
        id,
        "loading",
        promiseTitle,
        "Waiting for transaction signature...",
        isEth
      )
    );
    const formatTx = hash => `${hash.slice(0, 8)}...`;
    const closeThisNotification = () => {
      setTimeout(() => {
        dispatch(closeNotification(id));
      }, 3000);
    };

    try {
      txPromise
        .on("transactionHash", hash => {
          const description = `Transaction ${formatTx(
            hash
          )} was created. Waiting for confirmation`;

          dispatch(changeNotification(id, { tx: hash, description, isEth }));
          if (callback) callback();
        })
        .on("receipt", (receipt) => {
          if (confirmed) return;

          const { status, transactionHash } = receipt;
          const description = `Transaction ${formatTx(
            transactionHash
          )} was confirmed`;

          dispatch(
            changeNotification(id, {
              title: successTitle,
              type: "success",
              amount,
              description,
              isEth,
            })
          );

          closeThisNotification();

          confirmed = true;
          if (finalCallback) finalCallback()
          resolve(receipt);
        })
        .on("error", err => {
          dispatch(
            changeNotification(id, {
              tx: "",
              title: errorTitle,
              type: "error",
              description: err.message,
              isEth,
            })
          );

          closeThisNotification();

          reject(err);
        });
    } catch (err) {
      closeThisNotification();
      reject(err);
    }
  });
