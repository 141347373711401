export const GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR =
  "GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR";
export const GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST =
  "GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST";
export const GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS =
  "GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS";

export const GET_SPIN_MAX_BET_VAL_ERROR = "GET_SPIN_MAX_BET_VAL_ERROR";
export const GET_SPIN_MAX_BET_VAL_REQUEST = "GET_SPIN_MAX_BET_VAL_REQUEST";
export const GET_SPIN_MAX_BET_VAL_SUCCESS = "GET_SPIN_MAX_BET_VAL_SUCCESS";

export const GET_SPIN_ROUND_TIME_ERROR = "GET_SPIN_ROUND_TIME_ERROR";
export const GET_SPIN_ROUND_TIME_REQUEST = "GET_SPIN_ROUND_TIME_REQUEST";
export const GET_SPIN_ROUND_TIME_SUCCESS = "GET_SPIN_ROUND_TIME_SUCCESS";

export const GET_SPIN_STANDARD_LOT_REQUEST = "GET_SPIN_STANDARD_LOT_REQUEST";
export const GET_SPIN_STANDARD_LOT_SUCCESS = "GET_SPIN_STANDARD_LOT_SUCCESS";
export const GET_SPIN_STANDARD_LOT_ERROR = "GET_SPIN_STANDARD_LOT_ERROR";

export const GET_GBM_REQUEST = "GET_GBM_REQUEST";
export const GET_GBM_SUCCESS = "GET_GBM_SUCCESS";
export const GET_GBM_ERROR = "GET_GBM_ERROR";
