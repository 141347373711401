import {
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST,
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS,
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR,
  ADD_OTHER_ROLL_PLAYER_ACTIVITY,
  ADD_OTHER_SPIN_PLAYER_ACTIVITY,
  RESET_OTHER_PLAYERS_ACTIVITY
} from "../actionTypes/playersActivity";
import {
  listenToOtherPlayersActivityFromRollContract,
  getOtherPlayersActivityFromRollContract
} from "../services/ethereum/roll";
import {
  getOtherPlayersActivityFromSpinContract,
  listenToOtherPlayersActivityFromSpinContract
} from "../services/ethereum/spin";
import { setSpinLastBetInputs, setSpinLastRoundPlayed, setSpinShowControls } from "./games/spin";
import { setRollLastBetInputs, setRollLastRoundPlayed, setRollShowControls } from "./games/roll";
import { calculateSpinWinChance, calculateSpinWinPrize } from "./contractConstants/spin";
import { calculateWinChance, calculateWinPrize } from "./contractConstants/roll";

/**
 * Makes a Event listener to check if new players have joined the current bet
 *
 * @return {Function}
 */
export const listenToOtherRollPlayersActivity = () => (dispatch, getState) => {
  const callback = payload => {
    const { account } = getState().account;
    const { activeGame } = getState().games;
    console.log(activeGame, account, payload);
    if (payload.account.toUpperCase() === account.toUpperCase()) {
      dispatch(setRollLastRoundPlayed(+payload.round));
      dispatch(setRollShowControls(false));
      const { houseEdge } = getState().rollConstants;
      const winChance = calculateWinChance(+payload.gameType, +payload.number, +payload.number);
      const winPrize = calculateWinPrize(`${payload.amount}`, winChance, houseEdge);
      dispatch(setRollLastBetInputs(+payload.number, +payload.gameType, winPrize));
      return;
    }
    if (activeGame !== "roll") return;
    dispatch({ type: ADD_OTHER_ROLL_PLAYER_ACTIVITY, payload });
  };
  listenToOtherPlayersActivityFromRollContract(callback);
};

/**
 * Makes a Event listener to check if new players have joined the current bet
 *
 * @return {Function}
 */
export const listenToOtherSpinPlayersActivity = () => (dispatch, getState) => {
  const callback = payload => {
    const { account } = getState().account;
    const { activeGame } = getState().games;
    console.log(activeGame, account, payload);
    if (payload.account.toUpperCase() === account.toUpperCase()) {
      dispatch(setSpinLastRoundPlayed(+payload.round));
      dispatch(setSpinShowControls(false));
      const winChance = calculateSpinWinChance(+payload.gameType);
      const winPrize = calculateSpinWinPrize(`${payload.amount}`, winChance);
      dispatch(setSpinLastBetInputs(+payload.gameType, winPrize));
      return;
    }
    if (activeGame !== "spin") return;
    dispatch({ type: ADD_OTHER_SPIN_PLAYER_ACTIVITY, payload });
  };
  listenToOtherPlayersActivityFromSpinContract(callback);
};

export const resetPlayersActivity = () => dispatch => {
  dispatch({ type: RESET_OTHER_PLAYERS_ACTIVITY });
};

/**
 * Gets data about other players that are in the current bet for roll game. If it is called on app load
 * then it calls a listener to see if new players joined
 *
 * @return {Function}
 */
export const getOtherRollPlayersActivity = (fromBlock) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameRoll;
  if (roundState === "finished") return;
  dispatch({ type: GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST });
  try {
    const payload = await getOtherPlayersActivityFromRollContract(fromBlock, bettingRound);
    dispatch({ type: GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS, payload });
  } catch (err) {
    dispatch({
      type: GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR,
      payload: err.message
    });
  }
};

/**
 * Gets data about other players that are in the current bet for spin game. If it is called on app load
 * then it calls a listener to see if new players joined
 *
 * @return {Function}
 */
export const getOtherSpinPlayersActivity = (fromBlock) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameSpin;
  if (roundState === "finished") return;
  dispatch({ type: GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST });
  try {
    const payload = await getOtherPlayersActivityFromSpinContract(fromBlock, bettingRound);
    dispatch({ type: GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS, payload });
  } catch (err) {
    dispatch({
      type: GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR,
      payload: err.message
    });
  }
};
