import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleModal } from "../../actions/modal";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./Modal.scss";

class Modal extends Component {
  render() {
    const {
      modalOpen,
      modalComponent,
      modalProps,
      toggleModal,
      canCloseModal
    } = this.props;
    if (!modalOpen) return null;
    if (modalOpen) {
      const {
        default: ModalComponent
      } = require(`./ModalTypes/${modalComponent}/${modalComponent}.js`);
      return (
        <Fragment>
          {modalOpen && (
            <div className="modal">
              <PerfectScrollbar>
                <div
                  className={`modal__body ${
                    modalProps.isLarge ? "modal__body--large" : ""
                  } ${modalProps.isSide ? "modal__body--side" : ""} ${
                    modalProps.isMedium ? "modal__body--medium" : ""
                  }`}
                >
                  <ModalComponent
                    toggleModal={canCloseModal ? toggleModal : null}
                    {...modalProps}
                  />
                </div>
              </PerfectScrollbar>
              <div
                className={`modal__background ${
                  modalProps.isSide ? "modal__background--side" : ""
                }`}
                onClick={canCloseModal ? () => toggleModal() : null}
              />
            </div>
          )}
        </Fragment>
      );
    }
  }
}

Modal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  modalComponent: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
  canCloseModal: PropTypes.bool.isRequired
};

const mapStateToProps = ({ modal }) => ({
  modalOpen: modal.modalOpen,
  modalComponent: modal.modalComponent,
  modalProps: modal.modalProps,
  canCloseModal: modal.canCloseModal
});

const mapDispatchToProps = {
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
