import {
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR,
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST,
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS,
  GET_HOUSE_EDGE_ERROR,
  GET_HOUSE_EDGE_REQUEST,
  GET_HOUSE_EDGE_SUCCESS,
  GET_MAX_BET_VAL_ERROR,
  GET_MAX_BET_VAL_REQUEST,
  GET_MAX_BET_VAL_SUCCESS,
  GET_ROUND_TIME_ERROR,
  GET_ROUND_TIME_REQUEST,
  GET_ROUND_TIME_SUCCESS,
  GET_USE_DYNAMIC_MAX_BET_ERROR,
  GET_USE_DYNAMIC_MAX_BET_REQUEST,
  GET_USE_DYNAMIC_MAX_BET_SUCCESS,
} from "../../actionTypes/contractConstants/roll";

const INITIAL_STATE = {
  maxBetValue: "0",
  gettingMaxBetValue: false,
  gettingMaxBetValueErr: "",

  roundTime: 0,
  gettingRoundTime: false,
  gettingRoundTimeError: "",

  houseEdge: 0,
  gettingHouseEdge: false,
  gettingHouseEdgeError: "",

  useDynamicMaxBet: true,
  gettingUseDynamicMaxBet: false,
  gettingUseDynamicMaxBetError: "",

  benchmarkMaxBetSize: 0,
  gettingBenchmarkMaxBetSize: false,
  gettingBenchmarkMaxBetSizeError: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MAX_BET_VAL_REQUEST:
      return {
        ...state,
        gettingMaxBetValue: true,
        gettingMaxBetValueErr: ""
      };

    case GET_MAX_BET_VAL_SUCCESS:
      return {
        ...state,
        maxBetValue: payload,
        gettingMaxBetValue: false,
        gettingMaxBetValueErr: ""
      };

    case GET_MAX_BET_VAL_ERROR:
      return {
        ...state,
        gettingMaxBetValue: false,
        gettingMaxBetValueErr: payload
      };

    case GET_ROUND_TIME_REQUEST:
      return {
        ...state,
        gettingRoundTime: true,
        gettingRoundTimeError: ""
      };

    case GET_ROUND_TIME_SUCCESS:
      return {
        ...state,
        roundTime: payload,
        gettingRoundTime: false,
        gettingRoundTimeError: ""
      };

    case GET_ROUND_TIME_ERROR:
      return {
        ...state,
        gettingLastRoundTimestamp: false,
        gettingLastRoundTimestampError: payload
      };

    case GET_HOUSE_EDGE_REQUEST:
      return {
        ...state,
        gettingHouseEdge: true,
        gettingHouseEdgeError: ""
      };

    case GET_HOUSE_EDGE_SUCCESS:
      return {
        ...state,
        houseEdge: payload,
        gettingHouseEdge: false,
        gettingHouseEdgeError: ""
      };

    case GET_HOUSE_EDGE_ERROR:
      return {
        ...state,
        gettingHouseEdge: false,
        gettingHouseEdgeError: payload
      };

    case GET_USE_DYNAMIC_MAX_BET_REQUEST:
      return {
        ...state,
        gettingUseDynamicMaxBet: true,
        gettingUseDynamicMaxBetError: ""
      };

    case GET_USE_DYNAMIC_MAX_BET_SUCCESS:
      return {
        ...state,
        useDynamicMaxBet: payload,
        gettingUseDynamicMaxBet: false,
        gettingUseDynamicMaxBetError: ""
      };

    case GET_USE_DYNAMIC_MAX_BET_ERROR:
      return {
        ...state,
        gettingUseDynamicMaxBet: false,
        gettingUseDynamicMaxBetError: payload
      };

    case GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: true,
        gettingBenchmarkMaxBetSizeError: ""
      };

    case GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS:
      return {
        ...state,
        benchmarkMaxBetSize: payload,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeError: ""
      };

    case GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeError: payload
      };

    default:
      return state;
  }
};
