import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setAffCode } from "../../../../actions/account";
import { toggleModal } from "../../../../actions/modal";

import "./SetAffiliateCode.scss";

const codeMaxLength = 5;

class SetAffiliateCode extends Component {
  constructor(props) {
    super(props);
    this.state = { affCode: "", error: "" };

    this.handleCodeInput = this.handleCodeInput.bind(this);
  }

  handleCodeInput = e => {
    const { value } = e.target;
    const alphanumericRegex = /[^a-z0-9]/gi;
    if (alphanumericRegex.test(value)) {
      this.setState({ affCode: value, error: "Only alphanumeric values allowed." });
      return;
    }
    if (value === "") {
      this.setState({ affCode: value, error: "" });
      return;
    }
    if (value !== "" && value.length !== codeMaxLength) {
      this.setState({ error: `Code must be ${codeMaxLength} characters long` });
      return;
    }
    this.setState({ affCode: value, error: "" });
  };

  render() {
    const { setAffCode, toggleModal, settingOwnAffCodeErr, currentAffCode } = this.props;

    const disableSubmit =
      this.state.affCode === "" || this.state.affCode.length !== codeMaxLength;

    return (
      <div className="modal__content modal__content--affiliate">
        {currentAffCode !== "" && (
          <p className="description">
            Your affiliate code: <span>{currentAffCode}</span>
          </p>
        )}
        <p className="title">Set your affiliate code:</p>
        <input
          className="input--nickname"
          type="text"
          placeholder="Your code here"
          onChange={this.handleCodeInput}
        />
        {this.state.error !== "" && <p className="input__error">{this.state.error}</p>}
        {settingOwnAffCodeErr !== "" && <p className="input__error">{settingOwnAffCodeErr}</p>}
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => setAffCode(this.state.affCode)}
            disabled={disableSubmit}
          >
            Set
          </button>
          <button
            className="btn btn--dark"
            onClick={() => toggleModal()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

SetAffiliateCode.propTypes = {
  setAffCode: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setAffCode,
  toggleModal
};

const mapStateToProps = ({ account }) => ({
  settingOwnAffCode: account.settingOwnAffCode,
  settingOwnAffCodeErr: account.settingOwnAffCodeErr,
  currentAffCode: account.ownsAffCode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAffiliateCode);
