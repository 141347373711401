import React from "react";

export default function Ethereum() {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="85" height="85" rx="42.5" fill="white"/>
      <path d="M42.2931 9.37109L41.8448 10.8948V55.1086L42.2931 55.5561L62.8167 43.4247L42.2931 9.37109Z" fill="#203655"/>
      <path d="M42.2932 9.37012L21.7697 43.4237L42.2932 55.5553V34.0952V9.37012Z" fill="#3E5473"/>
      <path d="M42.2931 59.4421L42.0405 59.75V75.5L42.2931 76.2379L62.8289 47.3168L42.2931 59.4421Z" fill="#203655"/>
      <path d="M42.2933 76.2379V59.4421L21.7698 47.3168L42.2933 76.2379Z" fill="#3E5473"/>
      <path d="M42.2932 55.5553L62.8165 43.4241L42.2932 34.0956V55.5553Z" fill="#000E2D"/>
      <path d="M21.7699 43.4241L42.2931 55.5553V34.0956L21.7699 43.4241Z" fill="#203655"/>
    </svg>
  );
}
