const web3Coder = require('web3-eth-abi');

import {
  CLOSE_ROLL_ROUND,
  GET_ACC_ROLL_TOTAL_BETS_ERROR,
  GET_ACC_ROLL_TOTAL_BETS_REQUEST,
  GET_ACC_ROLL_TOTAL_BETS_SUCCESS,
  GET_ROLL_GAME_STATE_ERROR,
  GET_ROLL_GAME_STATE_REQUEST,
  GET_ROLL_GAME_STATE_SUCCESS,
  GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR,
  GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST,
  GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS,
  GET_ROLL_ROUND_STATE_ERROR,
  GET_ROLL_ROUND_STATE_REQUEST,
  GET_ROLL_ROUND_STATE_SUCCESS,
  PLACE_ROLL_BET_ERROR,
  PLACE_ROLL_BET_REQUEST,
  PLACE_ROLL_BET_SUCCESS,
  START_ROLL_AUTO_BET_REQUEST,
  START_ROLL_AUTO_BET_SUCCESS,
  START_ROLL_AUTO_BET_ERROR,
  STOP_ROLL_AUTO_BET_REQUEST,
  STOP_ROLL_AUTO_BET_SUCCESS,
  STOP_ROLL_AUTO_BET_ERROR,
  UPDATE_ROLL_AUTO_BET_REQUEST,
  UPDATE_ROLL_AUTO_BET_SUCCESS,
  UPDATE_ROLL_AUTO_BET_ERROR,
  SET_ROLL_BET_AMOUNT,
  SET_ROLL_BET_VALUE,
  SET_ROLL_GAME_SUBTYPE,
  SET_ROLL_GAME_TYPE,
  SET_ROLL_LAST_ROUND_PLAYED,
  SET_ROLL_LAST_SELECTED_NUMBER,
  SET_ROLL_LAST_USER_SELECTED_GAME_TYPE,
  SET_ROLL_LAST_USER_SELECTED_NUMBER,
  SET_ROLL_LAST_WIN_PRIZE,
  SET_ROLL_RANGE_LIMITS,
  SET_ROLL_SHOW_CONTROLS,
  SET_ROLL_SHOW_DICE,
  SET_ROLL_WIN_CHANCE,
  SET_ROLL_WIN_PRIZE,
  SET_ROLL_PAUSED,
  SET_ROLL_LAST_ROUND_BLOCK,
  ROLL_LISTENERS_INITIATED,
  GET_ROLL_INIT_DATA_SUCCESS,
  GET_ROLL_INIT_DATA_REQUEST,
  GET_ROLL_INIT_DATA_ERROR,
  ROLL_AUTO_BET_STARTED,
  ROLL_AUTO_BET_STOPPED,
  SET_ROLL_AUTO_BET_DATA,
} from "../../actionTypes/games/roll";
import { toggleModal } from "../modal";
import { sendTx } from "../txNotifications";
import {
  getOtherRollPlayersActivity,
  listenToOtherRollPlayersActivity,
  resetPlayersActivity
} from "../playersActivity";
import {
  calculateWinChance,
  calculateWinPrize,
  getRollContractConstValues,
  getHouseEdge,
  getMaxBetValue,
  getRoundTime,
  listenToMinBetSet,
  listenToMaxBetSet,
  listenToUseDynamicBetChanged,
  listenToBenchmarkParameterSet,
} from "../contractConstants/roll";
import { addRolls, getPreviousRolls } from "../betHistory";
import {
  getUserBonusContractBalance,
  getUserContractBalance,
  getWalletBalance,
  setFirstBet
} from "../account";
import { setRoundTimer } from "../roundTimer";
import {
  betFromRollContract,
  getLastRoundFromRollContract,
  getRollBetsAllowedFromContract,
  getRoundTimestampFromRollContract,
  getTotalBetsFromRollContract,
  listenToRollPausedFromContract,
  listenToRoundFinishedFromRollContract,
  listenToRoundStartedFromRollContract,
  listenForBetPlayedEventFromRollContract,
  listenRollAutoBetStartedEvent,
  listenRollAutoBetStoppedEvent,
  getAutobetInfo
} from "../../services/ethereum/roll";
import { checkIfPlayerHasWonRollBet } from "../../services/utils";
import {
  setRollLastRoundPlayedToLS,
  setRollLastUserSelectedGameTypeToLS,
  setRollLastUserSelectedNumberToLS,
  setRollLastWinPrizeToLS
} from "../../services/localStorage";
import { setNumberOfWithdrawals } from "../assetMovmentlHistory";
import {
  addNewLevelModal,
  checkIfRanOutOfContractBalanceWhileAutoBetting,
  checkIsRunningOutOfContractBalanceWhileAutoBetting,
  getAccTotalBets,
  validatePlaceBet,
} from './games';
import {
  addBadTokenWinNotification,
  areTokensDistributed,
  calculateNumberOfTokensWon,
  getBadTokenWinningProbability,
  getPlayerLevel,
} from "../badToken";
import { getBlockNumber, giveInfiniteApproval, weiToEth } from '../../services/ethereum/main';
import { getWETHApproval } from "../account";
import { startAutoBetFromContract, stopAutoBetFromContract } from '../../services/ethereum/games';
import { rollGameAddress } from '../../services/contract';

/**
 * Handles the state for when the bet is open
 *
 * @return {Function}
 */
export const setRollRoundTime = (timestamp) => async (dispatch, getState) => {
  try {
    await dispatch(getRoundTime());
    if (timestamp) {
      await dispatch(getRollRoundTimeStamp(timestamp, true));
    } else {
      const { rollLastRoundBlock } = getState().gameRoll;
      const { roundTime } = getState().rollConstants;
      await dispatch(getRollRoundTimeStamp(rollLastRoundBlock - roundTime - 100));
    }
    dispatch(setRoundTimer("roll"));
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets showControls game state
 *
 * @param payload
 * @return {Function}
 */
export const setRollShowControls = payload => dispatch => {
  dispatch({ type: SET_ROLL_SHOW_CONTROLS, payload });
};

/**
 * Sets showDice game state
 *
 * @param payload
 * @return {Function}
 */
export const setRollShowDice = payload => dispatch => {
  dispatch({ type: SET_ROLL_SHOW_DICE, payload });
};

/**
 * Gets a total number of placed bets for the current account
 *
 * @return {Function}
 */
export const getAccRollTotalBets = () => async (dispatch, getState) => {
  dispatch({ type: GET_ACC_ROLL_TOTAL_BETS_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getTotalBetsFromRollContract(account);
    dispatch({ type: GET_ACC_ROLL_TOTAL_BETS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_ACC_ROLL_TOTAL_BETS_ERROR, payload: err.message });
  }
};

/**
 *
 * @return {Function} Gives a game state
 */
export const getRollGameState = () => async (dispatch, getState) => {
  dispatch({ type: GET_ROLL_GAME_STATE_REQUEST });


  try {
    await dispatch(getRollRoundState());
    const { firstBet } = getState().games;
    const blockNumber = await getBlockNumber();
    await dispatch(setRollLastRoundBlock(blockNumber));
    await dispatch(setRollRoundTime());
    await dispatch(getRollContractConstValues());
    if (firstBet) await dispatch(getAccRollTotalBets());
    dispatch(setRollWinningPrize());

    const { roundState, betAmount } = getState().gameRoll;
    const { roundTime } = getState().rollConstants;
    if (roundState !== "finished") await dispatch(getOtherRollPlayersActivity(blockNumber - roundTime - 50));
    dispatch(setNumberOfWithdrawals());

    const { minBetSize: minBetValue } = getState().gamesConstants;
    if (betAmount === "0") await dispatch(setRollBetAmount(minBetValue));

    const { lastRoundPlayed, bettingRound } = getState().gameRoll;
    if (lastRoundPlayed === bettingRound && roundState !== "finished") {
      dispatch(setRollShowControls(false));
      if (roundState === "closed") dispatch(setRollShowDice(true));
    }

    const { account } = getState().account;
    const { betAmount: amount, numberOfBets, payload, totalNumberOfBets } = await getAutobetInfo(account)
    if (Number(numberOfBets) > 0) {
      const currentRound = Number(totalNumberOfBets) - Number(numberOfBets);
      dispatch(setAutoBetData(Number(totalNumberOfBets), currentRound));
    }

    await dispatch(getPreviousRolls());
    dispatch({ type: GET_ROLL_GAME_STATE_SUCCESS });
  } catch (e) {
    dispatch({ type: GET_ROLL_GAME_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

/**
 * Gets current round state and number
 *
 * @return {Function}
 */
export const getRollRoundState = () => async ( dispatch, getState ) => {
  dispatch({ type: GET_ROLL_ROUND_STATE_REQUEST });
  try {
    const { account } = getState().account;

    const { state, lastRound } = await getLastRoundFromRollContract(account);
    let round = Number(lastRound);

    if (state === "0") {
      dispatch({
        type: GET_ROLL_ROUND_STATE_SUCCESS,
        payload: { status: "open", round }
      });
    } else if (state === "1") {
      dispatch({
        type: GET_ROLL_ROUND_STATE_SUCCESS,
        payload: { status: "closed", round }
      });
      dispatch(closeRollRound());
    } else if (state === "2") {
      dispatch({
        type: GET_ROLL_ROUND_STATE_SUCCESS,
        payload: { status: "finished", round }
      });
      dispatch(setRollShowControls(true));
    }
  } catch (e) {
    dispatch({ type: GET_ROLL_ROUND_STATE_ERROR, payload: e.message });
    console.log(e);
  }
};

/**
 * Sets a game type and game subtype
 *
 * @param gameTypeID {number}
 * @return {Function}
 */
export const setRollGameType = gameTypeID => dispatch => {
  try {
    dispatch({ type: SET_ROLL_GAME_TYPE, payload: gameTypeID });
    if (gameTypeID === 0) {
      dispatch(setRollBetValue(4));
      dispatch(setRollRangeLimits(4, 94));
    }
    if (gameTypeID === 1) {
      dispatch(setRollBetValue(5));
      dispatch(setRollRangeLimits(5, 95));
    }
    if (gameTypeID === 2) {
      dispatch(setRollBetValue(1));
      dispatch(setRollRangeLimits(1, 98));
    }
    if (gameTypeID === 3 || gameTypeID === 4) {
      dispatch({ type: SET_ROLL_GAME_SUBTYPE, payload: 0 });
    }
    dispatch(setRollWinChance());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a min and max limit for bet value input range
 *
 * @param min
 * @param max
 * @return {Function}
 */
export const setRollRangeLimits = (min, max) => dispatch => {
  dispatch({ type: SET_ROLL_RANGE_LIMITS, payload: { min, max } });
};

/**
 * Sets a selected game sub type
 *
 * @param gameSubTypeID
 * @return {Function}
 */
export const setRollGameSubType = gameSubTypeID => dispatch => {
  try {
    dispatch({ type: SET_ROLL_GAME_SUBTYPE, payload: gameSubTypeID });
    dispatch(setRollWinChance());
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * @param betValue {number}
 * @return {Function}
 */
export const setRollBetValue = betValue => dispatch => {
  try {
    dispatch({ type: SET_ROLL_BET_VALUE, payload: betValue });
    dispatch(setRollWinChance());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a bet amount
 *
 * @param betAmount {string}
 * @return {Function}
 */
export const setRollBetAmount = betAmount => dispatch => {
  try {
    dispatch({
      type: SET_ROLL_BET_AMOUNT,
      payload: betAmount
    });
    dispatch(setRollWinningPrize());
    dispatch(getBadTokenWinningProbability());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Calculates and sets a winning chance
 *
 * @return {Function}
 */
export const setRollWinChance = () => (dispatch, getState) => {
  try {
    const { gameType, gameSubType, betValue } = getState().gameRoll;
    const { benchmarkMaxBetSize, useDynamicMaxBet } = getState().rollConstants;
    const payload = calculateWinChance(gameType, gameSubType, betValue);
    dispatch({ type: SET_ROLL_WIN_CHANCE, payload });
    if (useDynamicMaxBet)
      dispatch(getMaxBetValue(benchmarkMaxBetSize, payload));
    dispatch(setRollWinningPrize());
    dispatch(getBadTokenWinningProbability());
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a winning prize
 *
 * @return {Function}
 */
export const setRollWinningPrize = () => (dispatch, getState) => {
  try {
    const { betAmount, winChance } = getState().gameRoll;
    const { houseEdge } = getState().rollConstants;
    const payload = calculateWinPrize(betAmount, winChance, houseEdge);
    dispatch({ type: SET_ROLL_WIN_PRIZE, payload });
  } catch (e) {
    console.log(e);
  }
};

const getPayload = (_number, _type) => {
  return web3Coder.encodeParameter(
    {
      "ParentStruct": {
        "_number": 'uint256',
        "_type": "uint256"
      }
    },
    { _number, _type}
  );
}

export const setAutoBetData = (autoBettingRounds, autoBettingCurrentRound) => (dispatch) => {
  dispatch({ type: SET_ROLL_AUTO_BET_DATA, payload: { autoBettingRounds, autoBettingCurrentRound } });
};

export const startRollAutoBet = (numberOfRounds) => async (dispatch, getState) => {
  dispatch({ type: START_ROLL_AUTO_BET_REQUEST });
  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      roundState,
      gameType,
      gameSubType,
      betAmount,
      betValue,
      winPrize,
      winChance
    } = getState().gameRoll;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minBetValue } = getState().gamesConstants;
    const { maxBetValue } = getState().rollConstants;

    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBet(
      betAmount,
      minBetValue,
      maxBetValue,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      START_ROLL_AUTO_BET_ERROR,
      dispatch,
      true
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true, isAutoBet: true, numberOfRounds }));
      return;
    }

    dispatch(setFirstBet(false));
    let payload = {};

    if (gameType === 3 || gameType === 4) {
      payload = getPayload(gameSubType, gameType);
    } else {
      payload = getPayload(betValue, gameType);
    }

    const hasSucceeded = await startAutoBetFromContract(
      rollGameAddress, numberOfRounds, betAmount, payload, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: START_ROLL_AUTO_BET_SUCCESS, payload: numberOfRounds });
    } else {
      dispatch({ type: START_ROLL_AUTO_BET_ERROR, payload: "There was an error while placing auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: START_ROLL_AUTO_BET_ERROR, payload: error.message });
  }
};

export const stopRollAutoBet = () => async (dispatch, getState) => {
  dispatch({ type: STOP_ROLL_AUTO_BET_REQUEST });
  try {
    const { account } = getState().account;

    const hasSucceeded = await stopAutoBetFromContract(
      rollGameAddress, account, sendTx, dispatch, getState
    );

    if (hasSucceeded) {
      dispatch({ type: STOP_ROLL_AUTO_BET_SUCCESS });
    } else {
      dispatch({ type: STOP_ROLL_AUTO_BET_ERROR, payload: "There was an error while stopping auto-bet." });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: STOP_ROLL_AUTO_BET_ERROR, payload: error.message });
  }
};

/**
 * Sets a bet that user placed
 *
 * @return {Function}
 */
export const placeRollBet = () => async (dispatch, getState) => {
  dispatch({ type: PLACE_ROLL_BET_REQUEST });

  try {
    const {
      account,
      nickname,
      isFirstBet,
      walletBalance,
      userContractBalance,
      userContractBonusBalance
    } = getState().account;
    const { maxChanceForBonusBet } = getState().gamesConstants;
    const {
      roundState,
      gameType,
      gameSubType,
      betAmount,
      betValue,
      winPrize,
      winChance
    } = getState().gameRoll;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      firstBet
    } = getState().games;
    const { minBetSize: minBetValue } = getState().gamesConstants;
    const { maxBetValue } = getState().rollConstants;

    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(betAmount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    const isValid = validatePlaceBet(
      betAmount,
      minBetValue,
      maxBetValue,
      roundState,
      winChance,
      maxChanceForBonusBet,
      betWithContractBalance,
      betWithContractBonusBalance,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      PLACE_ROLL_BET_ERROR,
      dispatch
    );

    if (!isValid) return;

    if (
      betWithContractBonusBalance === false &&
      betWithContractBalance === false &&
      firstBet &&
      nickname === "" &&
      !isFirstBet
    ) {
      dispatch(setFirstBet(true));
      dispatch(toggleModal("Nickname", { showSkip: true }));
      return;
    }

    dispatch(setFirstBet(false));

    const hasSucceeded = await betFromRollContract(
      betValue,
      betAmount,
      gameSubType,
      gameType,
      account,
      betWithContractBalance,
      betWithContractBonusBalance,
      sendTx,
      dispatch,
      getState
    );

    if (hasSucceeded) {
      setTimeout(() => dispatch(getWalletBalance(account)), 3000);
      dispatch(getUserBonusContractBalance());
      console.log("PLACED ROLL BET");
      dispatch({ type: PLACE_ROLL_BET_SUCCESS });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: PLACE_ROLL_BET_ERROR, payload: e.message });
  }
};

/**
 * Sets round bet state to "closed"
 *
 * @return {Function}
 */
export const closeRollRound = () => dispatch => {
  dispatch({ type: CLOSE_ROLL_ROUND });
  dispatch(setRollShowControls(false));
  dispatch(setRollShowDice(true));
};

/**
 * Sets the last selected betting number
 *
 * @param selectedNumber {number}
 * @return {Function}
 */
export const setRollLastSelectedNumber = selectedNumber => dispatch => {
  dispatch({ type: SET_ROLL_LAST_SELECTED_NUMBER, payload: selectedNumber });
};

/**
 * Sets the last selected betting number
 *
 * @param selectedNumber {number}
 * @param addToLS {boolean}
 * @return {Function}
 */
export const setRollLastUserSelectedNumber = (
  selectedNumber,
  addToLS = true
) => (dispatch, getState) => {
  dispatch({
    type: SET_ROLL_LAST_USER_SELECTED_NUMBER,
    payload: selectedNumber
  });
  if (!addToLS) return;
  const { account } = getState().account;
  setRollLastUserSelectedNumberToLS(account, selectedNumber);
};

export const setRollLastUserSelectedGameType = (
  selectedGameType,
  addToLS = true
) => (dispatch, getState) => {
  dispatch({
    type: SET_ROLL_LAST_USER_SELECTED_GAME_TYPE,
    payload: selectedGameType
  });
  if (!addToLS) return;
  const { account } = getState().account;
  setRollLastUserSelectedGameTypeToLS(account, selectedGameType);
};

/**
 * Sets last bat inputs when user places a bet
 *
 * @param selectedNumber
 * @param selectedGameType
 * @param lastWinPrize
 * @return {Function}
 */
export const setRollLastBetInputs = (
  selectedNumber,
  selectedGameType,
  lastWinPrize
) => dispatch => {
  dispatch(setRollLastUserSelectedNumber(selectedNumber));
  dispatch(setRollLastUserSelectedGameType(selectedGameType));
  dispatch(setRollLastWinPrize(lastWinPrize));
};

/**
 * Sets a last win prize
 *
 * @param lastWinPrize
 * @param addToLS
 * @return {Function}
 */
export const setRollLastWinPrize = (lastWinPrize, addToLS = true) => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_ROLL_LAST_WIN_PRIZE, payload: lastWinPrize });
  if (!addToLS) return;
  const { account } = getState().account;
  setRollLastWinPrizeToLS(account, lastWinPrize);
};

/**
 * Sets a last round number
 *
 * @param lastRoundPlayed
 * @param addToLS
 * @return {Function}
 */
export const setRollLastRoundPlayed = (lastRoundPlayed, addToLS = true) => (
  dispatch,
  getState
) => {
  dispatch({ type: SET_ROLL_LAST_ROUND_PLAYED, payload: lastRoundPlayed });
  if (!addToLS) return;
  const { account } = getState().account;
  setRollLastRoundPlayedToLS(account, lastRoundPlayed);
};

/**
 * Checks if player has won in the last played round
 * opens a modal and shows a result
 *
 * @return {Function}
 */
export const checkRollWinning = () => async (dispatch, getState) => {
  try {
    const {
      lastRoundPlayed,
      bettingRound,
      lastSelectedNumber,
      lastUserSelectedNumber,
      lastUserSelectedGameType,
      lastWinPrize
    } = getState().gameRoll;

    const { hasWon, userSelected } = checkIfPlayerHasWonRollBet(
      lastSelectedNumber,
      lastUserSelectedNumber,
      lastUserSelectedGameType
    );

    const hasPlayed = lastRoundPlayed === bettingRound;
    if (hasPlayed) {
      const { activeGame } = getState().games;
      if (activeGame === "roll") {
        setTimeout(() => {
          const { modalOpen, modalComponent } = getState().modal;

          if (
            modalOpen &&
            (modalComponent !== "WinLose" || modalComponent !== "ModalLoader")
          )
            dispatch(toggleModal());

          dispatch(
            toggleModal('WinLose', {
              hasWon,
              lastSelectedNumber,
              userSelected,
              lastUserSelectedGameType,
              game: 'roll',
              isSide: true,
            })
          );
          const { isAutoBetting } = getState().gameRoll;
          if (isAutoBetting) setTimeout(() => {
            const { modalOpen } = getState().modal;
            if (modalOpen) dispatch(toggleModal());
          }, 2000);
        }, 3000);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets timestamp from the last round
 *
 * @return {Function}
 */
export const getRollRoundTimeStamp = (payload, fromTimestamp) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameRoll;
  console.log(roundState);
  if (roundState === "finished") return;
  dispatch({ type: GET_ROLL_LAST_ROUND_TIMESTAMP_REQUEST });
  try {
    if (fromTimestamp){
      dispatch({ type: GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS, payload });
      return;
    }
    console.log(bettingRound);
    const data = await getRoundTimestampFromRollContract(payload, bettingRound);
    dispatch({ type: GET_ROLL_LAST_ROUND_TIMESTAMP_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_ROLL_LAST_ROUND_TIMESTAMP_ERROR,
      payload: err.message
    });
  }
};

/**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForRollRoundStarted = () => (dispatch, getState) => {
  try {
    const callback = async ({ id, timestamp, blockNumber }) => {
      console.log("ROLL ROUND STARTED CALLBACK");
      dispatch({
        type: GET_ROLL_ROUND_STATE_SUCCESS,
        payload: { status: "open", round: Number(id) }
      });

      const { activeGame } = getState().games;
      if (activeGame !== "roll") return;

      dispatch(setRollLastRoundBlock(blockNumber));
      await dispatch(getHouseEdge());
      await dispatch(setRollRoundTime(timestamp));
    };

    listenToRoundStartedFromRollContract(callback);
  } catch (err) {
    console.log(err);
  }
};

/**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForRollBetPlayed = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (round, player, blockNumber) => {
      const { isAutoBetting, autoBetBalanceNotificationDismissed } = getState().gameRoll;
        if (isAutoBetting) {
          try {
            dispatch({
              type: UPDATE_ROLL_AUTO_BET_REQUEST,
              payload: ""
            });
            const { betAmount, numberOfBets, totalNumberOfBets } = await getAutobetInfo(player);
            if (!autoBetBalanceNotificationDismissed) await dispatch(checkIsRunningOutOfContractBalanceWhileAutoBetting(account, betAmount, totalNumberOfBets))
            if (Number(numberOfBets) === 0) {
              return dispatch({
                type: ROLL_AUTO_BET_STOPPED,
                payload: 0
              });
            }
            const currBetNumber = Number(totalNumberOfBets) - Number(numberOfBets);
            dispatch({
              type: UPDATE_ROLL_AUTO_BET_SUCCESS,
              payload: currBetNumber
            });
          } catch (error) {
            dispatch({
              type: UPDATE_ROLL_AUTO_BET_ERROR,
              payload: error.message
            });
          }
        }

    };

    listenForBetPlayedEventFromRollContract(account, callback);
  } catch (err) {
    console.log(err);
  }
};

export const listenForRollAutoBetStarted = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, noOfBets, blockNumber) => {
          try {

            dispatch({
              type: ROLL_AUTO_BET_STARTED,
              payload: noOfBets.toString()
            });


          } catch (error) {
            console.log(error);
          }
    };

    listenRollAutoBetStartedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};


export const listenForRollAutoBetStopped = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, game, remainingBets, blockNumber) => {
          try {
            const { betAmount } = await getAutobetInfo(account);
            await dispatch(checkIfRanOutOfContractBalanceWhileAutoBetting(account, betAmount));
            dispatch({
              type: ROLL_AUTO_BET_STOPPED,
              payload: remainingBets
            });
            setTimeout(() => {
              dispatch(setRollShowControls(true));
              dispatch(setRollShowDice(false));
            }, 3000);
          } catch (error) {
            console.log(error);
          }
    };

    listenRollAutoBetStoppedEvent(account, callback);
  } catch (err) {
    console.log(err);
  }
};

export const setRollLastRoundBlock = (payload) => (dispatch) =>
  dispatch({ type: SET_ROLL_LAST_ROUND_BLOCK, payload });


export const setRollPaused = () => async (dispatch) => {
  try {
    const roll = await getRollBetsAllowedFromContract();
    dispatch({ type: SET_ROLL_PAUSED, payload: !roll });
  } catch (error) {
    console.log(error);
  }
}

export const listenToRollPaused = () => dispatch => {
  const callback = ({ bool }) => {
    dispatch({ type: SET_ROLL_PAUSED, payload: bool });
    if (!bool) {
      dispatch(getRollInitialData(false));
    }
  };
  listenToRollPausedFromContract(callback);
};

/**
 * Makes a Event listener to check if a new round has finished
 *
 * @return {Function}
 */
export const listenForRollRoundFinished = () => (dispatch, getState) => {
  const callback = async ({round, number}) => {
    console.log("ROLL ROUND FINISHED CALLBACK");
    await dispatch(setRollLastSelectedNumber(number));

    const { lastRoundPlayed } = getState().gameRoll;
    console.log(
      `ROUND FINISHED: ${round}, \nUSER PLAYED ROUND: ${lastRoundPlayed}`
    );

    if (round !== lastRoundPlayed) {
      setTimeout(() => {
        dispatch(setRollShowControls(true));
        dispatch(setRollShowDice(false));
      }, 6000);
    }

    dispatch({
      type: GET_ROLL_ROUND_STATE_SUCCESS,
      payload: { status: "finished", round }
    });

    const { activeGame } = getState().games;
    const { account } = getState().account;
    if (activeGame === "roll" && round === lastRoundPlayed) {
      const areDistributed = await areTokensDistributed(account);
      if (!areDistributed) {
        const tokenAmount = await calculateNumberOfTokensWon(account, "roll");
        dispatch(addBadTokenWinNotification(weiToEth(tokenAmount)));
      }
    }
    const { playerLevel } = getState().badToken;
    if (round === lastRoundPlayed && Number(playerLevel) < 100) await dispatch(addNewLevelModal());
    if (round === lastRoundPlayed) await dispatch(checkRollWinning());

    dispatch(setRollLastRoundPlayed(0));
    setTimeout(() => {
      if (activeGame === "roll") {
        dispatch(addRolls({ round, number }));
        dispatch(resetPlayersActivity());
      }
      dispatch(getUserContractBalance());
      dispatch(getPlayerLevel());
      dispatch(getUserBonusContractBalance());
      dispatch(getWalletBalance(account));
      dispatch(getAccTotalBets());
      dispatch(getRollContractConstValues());
    }, 3000);
  };

  listenToRoundFinishedFromRollContract(callback);
};

export const initRollSpecificListeners = () => dispatch => {
  dispatch(listenForRollRoundStarted());
  dispatch(listenForRollBetPlayed());
  dispatch(listenForRollAutoBetStarted());
  dispatch(listenForRollAutoBetStopped());
  dispatch(listenForRollRoundFinished());
  dispatch(listenToOtherRollPlayersActivity());
  dispatch(listenToRollPaused());
  dispatch(listenToMinBetSet());
  dispatch(listenToMaxBetSet());
  dispatch(listenToUseDynamicBetChanged());
  dispatch(listenToBenchmarkParameterSet());
  dispatch({ type: ROLL_LISTENERS_INITIATED })
};

/**
 * Fetches fresh information that is displayed when the player refreshes
 * or first opens the main page and starts event listeners
 *
 * @return {Function}
 */
export const getRollInitialData = (shouldGetBetsAllowed = true) => async (dispatch, getState) => {
  dispatch({ type: GET_ROLL_INIT_DATA_REQUEST });
  try {
    if (shouldGetBetsAllowed) await dispatch(setRollPaused());
    await dispatch(getRollGameState());
    const { rollListenersInitiated } = getState().gameRoll;
    if (!rollListenersInitiated) dispatch(initRollSpecificListeners());
    dispatch(getBadTokenWinningProbability());
    dispatch({ type: GET_ROLL_INIT_DATA_SUCCESS });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ROLL_INIT_DATA_ERROR, payload: error.message });
  }
};
