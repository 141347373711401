import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { formatAcc, formatNumber } from "../../../../services/utils";
import { toggleModal } from "../../../../actions/modal";
import { getChatHistory } from "../../../../actions/chat";
import { postUserTitle } from "../../../../actions/badToken";
import {
  DP,
  LEVEL_TITLES,
  LEVEL_REWARDS,
  SELECT_CUSTOM_STYLE_SMALL
} from "../../../../config/constants";
import { weiToEth } from "../../../../services/ethereum/main";
import Select from "react-select";
import BigNumber from "bignumber.js";
import PerfectScrollbar from "react-perfect-scrollbar";

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import EditIcon from "../../../Decorative/Icons/EditIcon";
import BetHistoryIcon from "../../../Decorative/Icons/BetHistoryIcon";
import WithdrawHistoryIcon from "../../../Decorative/Icons/WithdrawHistoryIcon";
import WithdrawIcon from "../../../Decorative/Icons/WithdrawIcon";
import BadTokenIcon from "../../../Decorative/Icons/BadTokenIcon";
import "./AccountInfo.scss";

class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.setIsOpen = this.setIsOpen.bind(this);
  }

  setIsOpen = bool => {
    this.setState({ isOpen: bool });
  };

  render() {
    const {
      nickname,
      account,
      walletBalance,
      userContractBalance,
      userContractBonusBalance,
      toggleModal,
      numberOfWithdrawals,
      usesAffCode,
      maxChanceForBonusBet,
      badTokenBalance,
      stakedBadTokenBalance,
      playerLevel,
      titleNumber,
      amountLeftToCompleteLevel,
      amountAcquiredInThisLevel,
      levelPercentCompleted,
      postUserTitle,
      network,
      getChatHistory,
    } = this.props;

    const totalBadTokenBalance = new BigNumber(weiToEth(badTokenBalance))
      .plus(weiToEth(stakedBadTokenBalance))
      .toString();
    const playerCurrLevel = Number(playerLevel);
    const currLevelTitles = [...LEVEL_TITLES].splice(0, playerCurrLevel + 1);
    const selectedOption =
      titleNumber === null ? currLevelTitles[0] : currLevelTitles[titleNumber];
    const onChangeTitle = async data => {
      await postUserTitle(data.value);
      getChatHistory();
    };

    return (
      <PerfectScrollbar>
        <div className="aside__drawer-content aside__drawer-content--account">
          <div className="level-info">
            <p className="title">
              <span>Account Info</span>
              <span>Level {playerCurrLevel}</span>
            </p>
            <div className="account__nickname">
              <Tooltip title={account} theme="roll">
                <span className="account__nickname__title">Player: </span>
                <span className="account__nickname__nick">
                  {nickname === "" && formatAcc(account, 8)}
                  {nickname !== "" && nickname}
                </span>
              </Tooltip>
            </div>

            {playerCurrLevel > 0 && (
              <div className="player__title">
                <span className="player__title__title">Title: </span>
                <Select
                  className="select"
                  classNamePrefix="select--small"
                  styles={SELECT_CUSTOM_STYLE_SMALL}
                  options={currLevelTitles}
                  value={selectedOption}
                  onChange={onChangeTitle}
                  isSearchable={false}
                />
              </div>
            )}

            <div className="progress">
              <span className="progress__title">Level Progress: </span>
              <div className="progress__bar__wrapper">
                <div className="progress__bar">
                  <span
                    className="progress__bar__inner"
                    style={{ width: `${levelPercentCompleted}%` }}
                  />
                  <p>{levelPercentCompleted}%</p>
                </div>
                <span className="progress__amount">
                  {formatNumber(weiToEth(amountAcquiredInThisLevel), DP)} ETH
                </span>
                <span className="progress__amount">
                  {formatNumber(weiToEth(amountLeftToCompleteLevel), DP)} ETH
                </span>
              </div>
            </div>

            {playerCurrLevel > 0 && playerCurrLevel < 100 && (
              <div className="level__reward">
                <span className="level__reward__title">
                  Reward for Level {playerCurrLevel + 1}:{" "}
                </span>
                <span className="level__reward__data">
                  {LEVEL_REWARDS[playerCurrLevel]}
                </span>
              </div>
            )}

            <div className="account__nickname">
              <Tooltip title={account} theme="roll">
                <span className="account__nickname__title">
                  Ethereum Address:{" "}
                </span>
                <span className="account__nickname__nick">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="description"
                    href={`https://${
                      network === 3 ? "testnetv3-explorer." : "explorer."
                    }matic.network/address/${account}`}
                  >
                    {formatAcc(account, 8)}
                  </a>
                </span>
              </Tooltip>
            </div>
          </div>

          <div className="account__balance">
            <span>
              <Tooltip
                title="This is your current balance on the BadBit.Games smart contract. You can withdraw
            these funds to your wallet at any time."
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Contract ETH:
            </span>
            <Tooltip title={weiToEth(userContractBalance)} theme="roll">
              <span className="account__balance__value">
                {formatNumber(weiToEth(userContractBalance), DP)}
                <span>ETH</span>
              </span>
            </Tooltip>
          </div>

          <div className="account__balance">
            <span>
              <Tooltip
                title="This is the current balance in your private wallet."
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Matic ETH:
            </span>
            <Tooltip title={weiToEth(walletBalance)} theme="roll">
              <span className="account__balance__value">
                {formatNumber(weiToEth(walletBalance), DP)}
                <span>ETH</span>
              </span>
            </Tooltip>
          </div>

          <div className="account__balance">
            <span>
              <Tooltip
                title={`This is your bonus balance. Place a bet with win chance of under ${maxChanceForBonusBet}%, win once and you can withdraw your bonus and winnings.`}
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Bonus ETH:
            </span>
            <Tooltip title={weiToEth(userContractBonusBalance)} theme="roll">
              <span className="account__balance__value">
                {formatNumber(weiToEth(userContractBonusBalance), DP)}
                <span>ETH</span>
              </span>
            </Tooltip>
          </div>

          {userContractBalance !== "0" && (
            <button
              className="btn btn--primary btn__withdraw"
              onClick={() => toggleModal("Withdraw")}
            >
              <WithdrawIcon /> Withdraw
            </button>
          )}

          <button
            className="btn btn--light btn__move"
            onClick={() => toggleModal("MoveWETH", { isMedium: true, activeTab: "deposit" })}
          >
            Deposit ETH
          </button>

          <button
            className="btn btn--light btn__move"
            onClick={() => toggleModal("MoveWETH", { isMedium: true, activeTab: "withdraw" })}
          >
            Withdraw ETH
          </button>

          {/*<button*/}
          {/*  className="btn btn--light"*/}
          {/*  onClick={() => toggleModal("MoveBAD", { isMedium: true })}*/}
          {/*>*/}
          {/*  Moving Your BAD Tokens*/}
          {/*</button>*/}

          <hr className="hr-line" />

          <button
            className="btn btn--light"
            onClick={() =>
              toggleModal("PlayersHistory", { isLarge: true, account })
            }
          >
            <BetHistoryIcon /> Bet History
          </button>

          <button
            className="btn btn--light"
            onClick={() => toggleModal("AssetMovementHistory", { isMedium: true })}
          >
            <WithdrawHistoryIcon /> Asset Movement History
          </button>

          <button
            className="btn btn--light"
            onClick={() => toggleModal("Nickname")}
          >
            <EditIcon /> Set Nickname
          </button>

          <hr className="hr-line" />

          <button
            className="btn btn--light"
            onClick={() => toggleModal("AssociateWithAffiliateCode")}
          >
            Associate With Affiliate Code
          </button>

          {usesAffCode !== "" && (
            <button
              className="btn btn--light"
              onClick={() => toggleModal("BreakAffiliation")}
            >
              Break Affiliation
            </button>
          )}

          <hr className="hr-line" />

          <div className="account__balance">
            <span>
              <Tooltip
                title="The number of BAD tokens you own that are currently on the Matic sidechain. You are free to stake them or withdraw to the main Ethereum network."
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Unstaked Token Balance:
            </span>
            <Tooltip title={weiToEth(badTokenBalance)} theme="roll">
              <span className="account__balance__value">
                {formatNumber(weiToEth(badTokenBalance), DP)}
                <span>BAD</span>
              </span>
            </Tooltip>
          </div>

          <div className="account__balance">
            <span>
              <Tooltip
                title="Your balance of BAD tokens that are currently locked at the BadBit.Games smart contract. These tokens are earning revenue rebate at the end of every distribution cycle."
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Staked Token Balance:
            </span>
            <Tooltip title={weiToEth(stakedBadTokenBalance)} theme="roll">
              <span className="account__balance__value">
                {formatNumber(weiToEth(stakedBadTokenBalance), DP)}
                <span>BAD</span>
              </span>
            </Tooltip>
          </div>

          <hr className="hr-line" />

          <div className="account__balance">
            <span>
              <Tooltip
                title="The total number of BAD tokens that you own."
                theme="roll"
              >
                <InfoIcon />
              </Tooltip>{" "}
              Total Token Balance:
            </span>
            <Tooltip title={totalBadTokenBalance} theme="roll">
              <span className="account__balance__value">
                {formatNumber(totalBadTokenBalance, DP)}
                <span>BAD</span>
              </span>
            </Tooltip>
          </div>

          <button
            className="btn btn--light"
            onClick={() => toggleModal("BadToken", { isLarge: true })}
          >
            <BadTokenIcon /> BAD TOKEN MANAGEMENT
          </button>
        </div>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = ({
  account,
  drawer,
  assetMovementHistory,
  gamesConstants,
  badToken
}) => ({
  account: account.account,
  walletBalance: account.walletBalance,
  ethBalance: account.ethBalance,
  nickname: account.nickname,
  userContractBalance: account.userContractBalance,
  userContractBonusBalance: account.userContractBonusBalance,
  drawerRightOpen: drawer.drawerRightOpen,
  numberOfWithdrawals: assetMovementHistory.numberOfWithdrawals,
  maxChanceForBonusBet: gamesConstants.maxChanceForBonusBet,
  badTokenBalance: badToken.badTokenBalance,
  stakedBadTokenBalance: badToken.stakedBadTokenBalance,
  playerLevel: badToken.playerLevel,
  amountLeftToCompleteLevel: badToken.amountLeftToCompleteLevel,
  amountAcquiredInThisLevel: badToken.amountAcquiredInThisLevel,
  levelPercentCompleted: badToken.levelPercentCompleted,
  titleNumber: badToken.titleNumber,
  network: account.network,
  usesAffCode: account.usesAffCode
});

const mapDispatchToProps = {
  toggleModal,
  postUserTitle,
  getChatHistory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfo);
