import {
  GET_PREVIOUS_ROLLS_REQUEST,
  GET_PREVIOUS_ROLLS_SUCCESS,
  GET_PREVIOUS_ROLLS_FAILURE,
  GET_PREVIOUS_SPINS_REQUEST,
  GET_PREVIOUS_SPINS_SUCCESS,
  GET_PREVIOUS_SPINS_FAILURE,
  GET_PREVIOUS_ROULETTE_ROLLS_REQUEST,
  GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS,
  GET_PREVIOUS_ROULETTE_ROLLS_FAILURE,
  ADD_ROLL,
  ADD_SPIN,
  ADD_ROULETTE_ROLLS
} from "../actionTypes/betHistory";
import { getPreviousRollsFromRollContract } from "../services/ethereum/roll";
import { getPreviousSpinsFromRollContract } from "../services/ethereum/spin";
import { getPreviousRollsFromRouletteContract } from "../services/ethereum/roulette";
import { getRouletteBetForRoundFromApi } from "../services/api";
import {
  checkIfPlayerHasWonRouletteBet,
  getPlayersBetDetailsFromRouletteHistory
} from "./contractConstants/roulette";

export const addRolls = item => (dispatch, getState) => {
  try {
    const { history } = getState().betHistory;
    const newRolls = [...history];
    if (newRolls.length >= 9) newRolls.pop();
    newRolls.unshift(item);
    dispatch({ type: ADD_ROLL, payload: newRolls });
  } catch (e) {
    console.log(e);
  }
};

export const addSpins = item => (dispatch, getState) => {
  try {
    const { history, page } = getState().betHistory;
    if (page !== 0) return;
    const newSpins = [...history];
    if (newSpins.length >= 9) newSpins.pop();
    newSpins.unshift(item);
    dispatch({ type: ADD_SPIN, payload: newSpins });
  } catch (e) {
    console.log(e);
  }
};

export const addRouletteRolls = item => async (dispatch, getState) => {
  try {
    const { history, page } = getState().betHistory;
    if (page !== 0) return;
    const newBets = [...history];
    if (newBets.length >= 9) newBets.pop();
    const { account } = getState().account;
    const res = await getRouletteBetForRoundFromApi(account, `${item.round}`);
    const { data } = await res.json();
    item.data = data;
    const { hasWon } = checkIfPlayerHasWonRouletteBet(+item.number, item.data);
    item.won = hasWon;
    item.betDetails = getPlayersBetDetailsFromRouletteHistory(item.data);
    newBets.unshift(item);
    dispatch({ type: ADD_ROULETTE_ROLLS, payload: newBets });
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets data about the users previous rolls
 *
 * @return {Function}
 */
export const getPreviousRolls = (page = 0) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameRoll;

  dispatch({ type: GET_PREVIOUS_ROLLS_REQUEST });
  try {
    let startRound =
      roundState === "finished" ? bettingRound : bettingRound - 1;
    if (page) startRound -= 8 + (page - 1) * 7;

    const history = await getPreviousRollsFromRollContract(
      startRound,
      page === 0 ? 8 : 7
    );

    dispatch({ type: GET_PREVIOUS_ROLLS_SUCCESS, payload: { history, page } });
  } catch (err) {
    dispatch({ type: GET_PREVIOUS_ROLLS_FAILURE, payload: err.message });
  }
};

/**
 * Gets data about the users previous rolls
 *
 * @return {Function}
 */
export const getPreviousSpins = (page = 0) => async (dispatch, getState) => {
  const { bettingRound, roundState } = getState().gameSpin;

  dispatch({ type: GET_PREVIOUS_SPINS_REQUEST });

  try {
    let startRound =
      roundState === "finished" ? bettingRound : bettingRound - 1;
    if (page) startRound -= 8 + (page - 1) * 7;

    const history = await getPreviousSpinsFromRollContract(
      startRound,
      page === 0 ? 8 : 7
    );

    dispatch({ type: GET_PREVIOUS_SPINS_SUCCESS, payload: { history, page } });
  } catch (err) {
    dispatch({ type: GET_PREVIOUS_SPINS_FAILURE, payload: err.message });
  }
};

/**
 * Gets data about the users previous roulette bets
 *
 * @return {Function}
 */
export const getPreviousRouletteRolls = (page = 0) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_PREVIOUS_ROULETTE_ROLLS_REQUEST });
  try {
    const { account } = getState().account;

    let history = await getPreviousRollsFromRouletteContract(
      page === 0 ? 8 : 7,
      account,
      page
    );

    history = await new Promise(async (resolve, reject) => {
      const data = history.map(async item => {
        const res = await getRouletteBetForRoundFromApi(
          account,
          `${item.round}`
        );
        const { data } = await res.json();
        item.data = data;
        const { hasWon } = checkIfPlayerHasWonRouletteBet(+item.number, item.data);
        item.won = hasWon;
        item.betDetails = getPlayersBetDetailsFromRouletteHistory(item.data);
        return item;
      });
      return await Promise.all(data).then(data => resolve(data));
    });

    dispatch({
      type: GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS,
      payload: { history, page }
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_PREVIOUS_ROULETTE_ROLLS_FAILURE,
      payload: err.message
    });
  }
};
