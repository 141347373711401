import React from "react";

export default function WithdrawIcon({ size = 16, color = "#FFFFFF" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.86194V4.40908C1 2.75641 2.33975 1.41666 3.99242 1.41666H12.3712C14.0239 1.41666 15.3636 2.75641 15.3636 4.40908V9.86194"
        stroke={color}
        strokeWidth="1.19697"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.18212 5.60605V14.5833M8.18212 14.5833L5.1897 11.5909M8.18212 14.5833L11.1745 11.5909"
        stroke={color}
        strokeWidth="1.19697"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
