import {
  SET_ACTIVE_GAME,
  SET_BET_WITH_CONTRACT_BALANCE,
  SET_BET_WITH_CONTRACT_BONUS_BALANCE,
  SET_FIRST_BET,
  IS_NEW_LEVEL_REQUEST,
  IS_NEW_LEVEL_SUCCESS,
  IS_NEW_LEVEL_ERROR,
  SET_GAMES_PAUSED,
  SET_INIT_DATA_REQUEST,
  SET_INIT_DATA_SUCCESS,
  SET_INIT_DATA_ERROR,
  GET_AUTO_BET_MAX_BETS_REQUEST,
  GET_AUTO_BET_MAX_BETS_SUCCESS,
  GET_AUTO_BET_MAX_BETS_ERROR,
} from "../../actionTypes/games/games";

const INITIAL_STATE = {
  activeGame: "",
  betWithContractBalance: false,
  betWithContractBonusBalance: false,

  settingInitData: false,
  settingInitDataErr: "",

  firstBet: true,
  isNewLevel: false,
  newLevel: 0,
  gamesPaused: false,

  autoBetMaxBets: 150,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BET_WITH_CONTRACT_BALANCE:
      return {
        ...state,
        betWithContractBalance: payload,
        betWithContractBonusBalance: false
      };

    case SET_BET_WITH_CONTRACT_BONUS_BALANCE:
      return {
        ...state,
        betWithContractBonusBalance: payload,
        betWithContractBalance: false
      };

    case SET_ACTIVE_GAME:
      return {
        ...state,
        activeGame: payload
      };

    case IS_NEW_LEVEL_REQUEST:
      return {
        ...state,
        gettingIsNewLvl: true,
        gettingIsNewLvlErr: ""
      };

    case IS_NEW_LEVEL_SUCCESS:
      return {
        ...state,
        gettingIsNewLvl: false,
        gettingIsNewLvlErr: "",
        newLevel: payload.newLevel,
        isNewLevel: payload.isNewLevel
      };

    case IS_NEW_LEVEL_ERROR:
      return {
        ...state,
        gettingIsNewLvl: false,
        gettingIsNewLvlErr: payload
      };

    case SET_GAMES_PAUSED:
      return {
        ...state,
        gamesPaused: payload
      };

    case SET_FIRST_BET:
      return { ...state, firstBet: payload };

    case SET_INIT_DATA_REQUEST: return {
      ...state,
      settingInitData: true,
      settingInitDataErr: "",
    };

    case SET_INIT_DATA_SUCCESS: return {
      ...state,
      settingInitData: false,
      settingInitDataErr: "",
    };

    case SET_INIT_DATA_ERROR: return {
      ...state,
      settingInitData: false,
      settingInitDataErr: payload,
    };

    case GET_AUTO_BET_MAX_BETS_REQUEST: return {
      ...state,
      gettingAutoBetMaxBets: true,
      gettingAutoBetMaxBetsErr: "",
    };

    case GET_AUTO_BET_MAX_BETS_SUCCESS: return {
      ...state,
      gettingAutoBetMaxBets: false,
      gettingAutoBetMaxBetsErr: "",
      autoBetMaxBets: payload,
    };

    case GET_AUTO_BET_MAX_BETS_ERROR: return {
      ...state,
      gettingAutoBetMaxBets: false,
      gettingAutoBetMaxBetsErr: payload,
    };

    default:
      return state;
  }
};
