import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setDoubleOrNothing } from "../../../../actions/games/doubleOrNothing";
import { formatNumber } from "../../../../services/utils";
import { DP } from "../../../../config/constants";
import { weiToEth } from "../../../../services/ethereum/main";

import Roll from "./Games/Roll";
import Spin from "./Games/Spin";
import Scratch from "./Games/Scratch";
import Roulette from "./Games/Roulette";
import DoN from "./Games/DoN";
import Tokens from "./Tokens";
import Level from "./Level";
import "./WinLose.scss";
import BigNumber from 'bignumber.js';

const WinLose = ({
  toggleModal,
  hasWon,
  userSelected,
  lastSelectedNumber,
  lastSelectedRouletteNumber,
  canPlayDoubleOrNothing,
  setDoubleOrNothing,
  hideRes,
  lastWinPrizeRoll,
  lastWinPrizeSpin,
  lastWinPrizeScratch,
  lastWinPrizeRoulette,
  lastUserSelectedGameType,
  showDonTips,
  maxNumOfDonInRow,
  lastSelectedGameType,
  badTokenWinProbDon,
  extraTokenWinChance,
  game,
  tokensWon,
  winPrize,
  betHistory,
  cards,
  activeGame,
  newLevel,
  isNewLevel,
  isAutoBettingRoll,
  isAutoBettingSpin,
  isAutoBettingRoulette,
  isAutoBettingScratch,
}) => {

  let badTokenWinChanceDon = new BigNumber(badTokenWinProbDon)
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toString();
  if (new BigNumber(badTokenWinChanceDon).lt(0)) badTokenWinChanceDon = "0";

  const badTokenExtraWinChance = new BigNumber(extraTokenWinChance)
    .dividedBy(100)
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toString();
  let badTokenTotalWinChanceDon = new BigNumber(badTokenWinChanceDon)
    .plus(badTokenExtraWinChance)
    .decimalPlaces(2, BigNumber.ROUND_FLOOR)
    .toString();

  if (new BigNumber(badTokenTotalWinChanceDon).isGreaterThan(99)) {
    badTokenTotalWinChanceDon = "99";
  }

  const shareUrl =
    window.location.protocol + "//" + window.location.host + `/${game}`;
  const title = `I just won ${formatNumber(
    weiToEth(
      `${game === "roll" ? lastWinPrizeRoll : ""}${
        game === "spin" ? lastWinPrizeSpin : ""
      }`
    ),
    DP + 3
  )} ETH playing ${game.toUpperCase()}! Join me at BadBit.Games and WIN BIG!`;


  if (game === "roll")
    return (
      <Fragment>
        <Roll
          title={title}
          shareUrl={shareUrl}
          hasWon={hasWon}
          toggleModal={toggleModal}
          userSelected={userSelected}
          lastSelectedNumber={lastSelectedNumber}
          canPlayDoubleOrNothing={canPlayDoubleOrNothing}
          setDoubleOrNothing={setDoubleOrNothing}
          lastWinPrize={lastWinPrizeRoll}
          hideRes={hideRes}
          lastUserSelectedGameType={lastUserSelectedGameType}
          showDonTips={showDonTips}
          maxNumOfDonInRow={maxNumOfDonInRow}
          lastSelectedGameType={lastSelectedGameType}
          badTokenWinChanceDon={badTokenWinChanceDon}
          badTokenExtraWinChance={badTokenExtraWinChance}
          badTokenTotalWinChanceDon={badTokenTotalWinChanceDon}
          isAutoBetting={isAutoBettingRoll}
        />
        <Tokens tokensWon={tokensWon} />
        <Level newLevel={isNewLevel} level={newLevel} />
      </Fragment>
    );

  if (game === "spin")
    return (
      <Fragment>
        <Spin
          title={title}
          shareUrl={shareUrl}
          hasWon={hasWon}
          toggleModal={toggleModal}
          lastSelectedGameType={lastSelectedGameType}
          canPlayDoubleOrNothing={canPlayDoubleOrNothing}
          setDoubleOrNothing={setDoubleOrNothing}
          hideRes={hideRes}
          lastUserSelectedGameType={lastUserSelectedGameType}
          showDonTips={showDonTips}
          maxNumOfDonInRow={maxNumOfDonInRow}
          lastWinPrize={lastWinPrizeSpin}
          badTokenWinChanceDon={badTokenWinChanceDon}
          badTokenExtraWinChance={badTokenExtraWinChance}
          badTokenTotalWinChanceDon={badTokenTotalWinChanceDon}
          isAutoBetting={isAutoBettingSpin}
        />
        <Tokens tokensWon={tokensWon} />
        <Level newLevel={isNewLevel} level={newLevel} />
      </Fragment>
    );

  if (game === "scratch")
    return (
      <Fragment>
        <Scratch
          title={"I just played and won a game of SCRATCH! Join me at BadBit.Games and WIN BIG!"}
          shareUrl={shareUrl}
          hasWon={hasWon}
          toggleModal={toggleModal}
          winPrize={winPrize}
          cards={cards}
          canPlayDoubleOrNothing={canPlayDoubleOrNothing}
          setDoubleOrNothing={setDoubleOrNothing}
          showDonTips={showDonTips}
          maxNumOfDonInRow={maxNumOfDonInRow}
          badTokenWinChanceDon={badTokenWinChanceDon}
          badTokenExtraWinChance={badTokenExtraWinChance}
          badTokenTotalWinChanceDon={badTokenTotalWinChanceDon}
          isAutoBetting={isAutoBettingScratch}
        />
        <Tokens tokensWon={tokensWon} hideInfo={true} />
        <Level newLevel={isNewLevel} level={newLevel} />
      </Fragment>
    );

  if (game === "roulette")
    return (
      <Fragment>
        <Roulette
          title={"I just played and won a game of ROULETTE! Join me at BadBit.Games and WIN BIG!"}
          shareUrl={shareUrl}
          hasWon={hasWon}
          toggleModal={toggleModal}
          winPrize={winPrize}
          betHistory={betHistory}
          canPlayDoubleOrNothing={canPlayDoubleOrNothing}
          setDoubleOrNothing={setDoubleOrNothing}
          showDonTips={showDonTips}
          lastSelectedNumber={lastSelectedRouletteNumber}
          maxNumOfDonInRow={maxNumOfDonInRow}
          badTokenWinChanceDon={badTokenWinChanceDon}
          badTokenExtraWinChance={badTokenExtraWinChance}
          badTokenTotalWinChanceDon={badTokenTotalWinChanceDon}
          isAutoBetting={isAutoBettingRoulette}
        />
        <Tokens tokensWon={tokensWon} hideInfo={true} />
        <Level newLevel={isNewLevel} level={newLevel} />
      </Fragment>
    );

  const gamesLastWinPrizes = {
    "roll": lastWinPrizeRoll,
    "spin": lastWinPrizeSpin,
    "scratch": lastWinPrizeScratch,
    "roulette": lastWinPrizeRoulette,
  }

  if (game === "double or nothing")
    return (
      <Fragment>
        <DoN
          title={title}
          shareUrl={shareUrl}
          hasWon={hasWon}
          toggleModal={toggleModal}
          lastSelectedGameType={lastSelectedGameType}
          canPlayDoubleOrNothing={canPlayDoubleOrNothing}
          setDoubleOrNothing={setDoubleOrNothing}
          hideRes={hideRes}
          lastUserSelectedGameType={lastUserSelectedGameType}
          showDonTips={showDonTips}
          maxNumOfDonInRow={maxNumOfDonInRow}
          lastWinPrize={gamesLastWinPrizes[activeGame]}
          badTokenWinChanceDon={badTokenWinChanceDon}
          badTokenExtraWinChance={badTokenExtraWinChance}
          badTokenTotalWinChanceDon={badTokenTotalWinChanceDon}
        />
        <Tokens tokensWon={tokensWon} hideInfo={true} />
        <Level newLevel={isNewLevel} level={newLevel} />
      </Fragment>
    );
};

WinLose.propTypes = {
  canPlayDoubleOrNothing: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  gameRoll,
  gameSpin,
  gameScratch,
  gameRoulette,
  gameDoubleOrNothing,
  badToken,
  games
}) => ({
  lastWinPrizeRoll: gameRoll.lastWinPrize,
  lastWinPrizeSpin: gameSpin.lastWinPrize,
  lastWinPrizeScratch: gameScratch.winPrize,
  lastWinPrizeRoulette: gameRoulette.lastWinPrize,
  lastSelectedRouletteNumber: gameRoulette.lastSelectedNumber,
  canPlayDoubleOrNothing:
    gameDoubleOrNothing.doubleOrNothingWinCount <
    gameDoubleOrNothing.maxNumOfDonInRow,
  maxNumOfDonInRow: gameDoubleOrNothing.maxNumOfDonInRow,
  tokensWon: badToken.tokensWon,
  extraTokenWinChance: badToken.extraTokenWinChance,
  badTokenWinProbDon: badToken.badTokenWinProbDon,
  newLevel: games.newLevel,
  isNewLevel: games.isNewLevel,
  activeGame: games.activeGame,
  isAutoBettingRoll: gameRoll.isAutoBetting,
  isAutoBettingSpin: gameSpin.isAutoBetting,
  isAutoBettingRoulette: gameRoulette.isAutoBetting,
  isAutoBettingScratch: gameScratch.isAutoBetting,
});

const mapDispatchToProps = {
  setDoubleOrNothing
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WinLose);
