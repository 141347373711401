export const SET_ACTIVE_GAME = "SET_ACTIVE_GAME";

export const SET_FIRST_BET = "SET_FIRST_BET";

export const SET_BET_WITH_CONTRACT_BALANCE = "SET_BET_WITH_CONTRACT_BALANCE";
export const SET_BET_WITH_CONTRACT_BONUS_BALANCE =
  "SET_BET_WITH_CONTRACT_BONUS_BALANCE";

export const IS_NEW_LEVEL_REQUEST = "IS_NEW_LEVEL_REQUEST";
export const IS_NEW_LEVEL_SUCCESS = "IS_NEW_LEVEL_SUCCESS";
export const IS_NEW_LEVEL_ERROR = "IS_NEW_LEVEL_ERROR";

export const SET_GAMES_PAUSED = "SET_GAMES_PAUSED";

export const SET_INIT_DATA_REQUEST = "SET_INIT_DATA_REQUEST";
export const SET_INIT_DATA_SUCCESS = "SET_INIT_DATA_SUCCESS";
export const SET_INIT_DATA_ERROR = "SET_INIT_DATA_ERROR";

export const MOVE_MATIC_ETH_TO_CONTRACT_REQUEST = "MOVE_MATIC_ETH_TO_CONTRACT_REQUEST";
export const MOVE_MATIC_ETH_TO_CONTRACT_SUCCESS = "MOVE_MATIC_ETH_TO_CONTRACT_SUCCESS";
export const MOVE_MATIC_ETH_TO_CONTRACT_ERROR = "MOVE_MATIC_ETH_TO_CONTRACT_ERROR";

export const GET_AUTO_BET_MAX_BETS_REQUEST = "GET_AUTO_BET_MAX_BETS_REQUEST";
export const GET_AUTO_BET_MAX_BETS_SUCCESS = "GET_AUTO_BET_MAX_BETS_SUCCESS";
export const GET_AUTO_BET_MAX_BETS_ERROR = "GET_AUTO_BET_MAX_BETS_ERROR";
