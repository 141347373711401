import {
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR,
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST,
  GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS,
  GET_SPIN_MAX_BET_VAL_ERROR,
  GET_SPIN_MAX_BET_VAL_REQUEST,
  GET_SPIN_MAX_BET_VAL_SUCCESS,
  GET_SPIN_ROUND_TIME_ERROR,
  GET_SPIN_ROUND_TIME_REQUEST,
  GET_SPIN_ROUND_TIME_SUCCESS,
  GET_SPIN_STANDARD_LOT_REQUEST,
  GET_SPIN_STANDARD_LOT_SUCCESS,
  GET_SPIN_STANDARD_LOT_ERROR,
  GET_GBM_REQUEST,
  GET_GBM_SUCCESS,
  GET_GBM_ERROR,
} from "../../actionTypes/contractConstants/spin";
import {
  getBenchmarkMaxBetSizeFromSpinContract, getGBMFromContract,
  getMaxBetFromSpinContract,
  getRoundTimeFromSpinContract,
  getStandardLotForGoldFromContract,
} from '../../services/ethereum/spin';
import BigNumber from "bignumber.js";
import { getMaxBetChanceForBonusBetting, getWinProbability } from "./games";
import { getMaxDonsInRow } from "../games/doubleOrNothing";

export const getStandardLot = () => async (dispatch) => {
  dispatch({ type: GET_SPIN_STANDARD_LOT_REQUEST });
  try {
    await dispatch(getGBM());
    const payload = await getStandardLotForGoldFromContract();
    dispatch({ type: GET_SPIN_STANDARD_LOT_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_SPIN_STANDARD_LOT_ERROR, payload: e.message });
  }
};

export const getGBM = () => async (dispatch) => {
  dispatch({ type: GET_GBM_REQUEST });
  try {
    const payload = await getGBMFromContract();
    dispatch({ type: GET_GBM_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_GBM_ERROR, payload: error.message });
  }
};

/**
 * Gets round time from the contract
 *
 * @return {Function}
 */
export const getSpinRoundTime = () => async ( dispatch, getState ) => {
  dispatch({ type: GET_SPIN_ROUND_TIME_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getRoundTimeFromSpinContract(account);
    dispatch({ type: GET_SPIN_ROUND_TIME_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_SPIN_ROUND_TIME_ERROR,
      payload: err.message
    });
  }
};

/**
 * Gets benchmark max bet size
 *
 * @return {Function}
 */
export const getSpinBenchmarkMaxBetSize = () => async (dispatch, getState) => {
  dispatch({ type: GET_SPIN_BENCHMARK_MAX_BET_SIZE_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getBenchmarkMaxBetSizeFromSpinContract(account);
    dispatch({ type: GET_SPIN_BENCHMARK_MAX_BET_SIZE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_SPIN_BENCHMARK_MAX_BET_SIZE_ERROR,
      payload: e.message
    });
    console.log(e);
  }
};

/**
 * Sets max bet value from contract
 *
 * @return {Function}
 */
export const getSpinMaxBetValue = (gameType = 1) => async ( dispatch, getState ) => {
  dispatch({ type: GET_SPIN_MAX_BET_VAL_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getMaxBetFromSpinContract(gameType, account);
    dispatch({ type: GET_SPIN_MAX_BET_VAL_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_SPIN_MAX_BET_VAL_ERROR, payload: err.message });
  }
};

/**
 * Calculates a winning prize
 *
 * @param betAmount
 * @param winChance
 * @return {string}
 */
export const calculateSpinWinPrize = (betAmount, winChance) => {
  const amount = new BigNumber(betAmount);
  if (winChance === 4760 || winChance === 50) {
    return amount.plus(amount).toString();
  }
  if (winChance === 2380) {
    return amount
      .times(3)
      .plus(amount)
      .toString();
  }
  if (winChance === 1904) {
    return amount
      .times(4)
      .plus(amount)
      .toString();
  }
  if (winChance === 476) {
    return amount
      .times(19)
      .plus(amount)
      .toString();
  }
  return amount.toString();
};

/**
 * Calculates a winning chance
 *
 * @param gameType
 * @return {number|*}
 */
export const calculateSpinWinChance = gameType => {
  if (gameType === 1) {
    return 4760;
  }
  if (gameType === 2) {
    return 2380;
  }
  if (gameType === 3) {
    return 1904;
  }
  if (gameType === 4) {
    return 476;
  }
};

/**
 * Gets constant values from the contract
 *
 * @return {Function}
 */
export const getSpinContractConstValues = gameType => async dispatch => {
  await dispatch(getSpinBenchmarkMaxBetSize());
  await dispatch(getSpinMaxBetValue(gameType));
  await dispatch(getStandardLot());
  await dispatch(getMaxDonsInRow());
  await dispatch(getMaxBetChanceForBonusBetting());
  await dispatch(getWinProbability());
};
