import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { weiToEth } from "../../../../../services/ethereum/main";
import { formatNumber } from "../../../../../services/utils";
import { DP } from "../../../../../config/constants";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import FaceBook from "../../../../Decorative/SocialIcons/FaceBook";
import Twitter from "../../../../Decorative/SocialIcons/Twitter";
import InfoIcon from "../../../../Decorative/Icons/InfoIcon";

const Spin = ({
  title,
  shareUrl,
  hasWon,
  toggleModal,
  lastSelectedGameType,
  canPlayDoubleOrNothing,
  setDoubleOrNothing,
  hideRes,
  lastUserSelectedGameType,
  showDonTips,
  maxNumOfDonInRow,
  lastWinPrize,
  badTokenWinChanceDon,
  badTokenExtraWinChance,
  badTokenTotalWinChanceDon,
  isAutoBetting,
}) => {
  return (
    <div className="modal__content modal__content--winlose">
      <div className="content">
        {hasWon && (
          <Fragment>
            <p>Congratulations!</p>
            <h3 className="win">YOU WIN!</h3>
          </Fragment>
        )}
        {hasWon && (
          <Tooltip title={weiToEth(lastWinPrize)} theme="roll">
            <p className="prize">
              {formatNumber(weiToEth(lastWinPrize), DP + 3)}
            </p>
          </Tooltip>
        )}
        {!hasWon && (
          <Fragment>
            <p>Sorry!</p>
            <h3 className="lost">NO WIN</h3>
          </Fragment>
        )}
        {!hideRes && (
          <p className="result">
            <span className="outcome spin">
              Outcome: {lastSelectedGameType === 1 && <span>White</span>}
              {lastSelectedGameType === 2 && <span>Red</span>}
              {lastSelectedGameType === 3 && <span>Blue</span>}
              {lastSelectedGameType === 4 && <span>Orange</span>}
              {lastSelectedGameType === 5 && <span>Gold</span>}
            </span>
            <span className="input">
              Your bet:{" "}
              {lastUserSelectedGameType === 1 && <span>White</span>}
              {lastUserSelectedGameType === 2 && <span>Red</span>}
              {lastUserSelectedGameType === 3 && <span>Blue</span>}
              {lastUserSelectedGameType === 4 && <span>Orange</span>}
            </span>
          </p>
        )}
        {hasWon && (
          <div className="social">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="some-network__share-button"
            >
              <FaceBook />
            </FacebookShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="some-network__share-button"
            >
              <Twitter />
            </TwitterShareButton>
          </div>
        )}
        {hasWon && showDonTips && canPlayDoubleOrNothing && (
          <p>Would you like to try again?</p>
        )}
        {hasWon && showDonTips && !canPlayDoubleOrNothing && (
          <p>You reached the limit of double or nothing attempts!</p>
        )}
      </div>
      <div className="btn__group">
        {!isAutoBetting && hasWon && canPlayDoubleOrNothing && (
          <button className="btn btn--primary" onClick={setDoubleOrNothing}>
            <Tooltip
              theme="roll"
              title={`Allows you to play another bet with a 50% chance to double the amount you won. Double or nothing can be played for maximum ${maxNumOfDonInRow} times in a row.`}
            >
              <InfoIcon />
            </Tooltip>{" "}
            Double Or Nothing
          </button>
        )}
        <button className="btn btn--dark" onClick={() => toggleModal()}>
          Done
        </button>
      </div>
      {!isAutoBetting && hasWon && canPlayDoubleOrNothing && (
        <div className="bet__data__single bet__data__single--bad-chance">
          <span>BAD Token Win Chance for Double or Nothing:</span>
          <span className="data">
          {badTokenWinChanceDon === "NaN" ? "0" : badTokenWinChanceDon}% + {badTokenExtraWinChance}% ={" "}
            {badTokenTotalWinChanceDon === "NaN" ? "0" : badTokenTotalWinChanceDon}%
            <Tooltip
              html={
                <ul className="tooltip__token-chance__list">
                  <li>
                    <p>BAD Token Win Chance Derived from Bet</p>{" "}
                    <span>{badTokenWinChanceDon}%</span>
                  </li>
                  <li>
                    <p>Additional Win Chance Derived from Player Level</p>{" "}
                    <span>{badTokenExtraWinChance}%</span>
                  </li>
                </ul>
              }
              theme="roll"
            >
            < InfoIcon color={"#465E71"} />
            </Tooltip>
          </span>
        </div>
      )}
    </div>
  );
};

Spin.propTypes = {};

export default Spin;
