const Ethers = require("ethers");
import Biconomy from "@biconomy/mexa";
const Web3 = require("web3");
import { setupWeb3 } from "./web3";

import RollGame from "../config/contracts/RollGame.json";
import SpinGame from "../config/contracts/SpinGame.json";
import ScratchGame from "../config/contracts/ScratchGame.json";
import RouletteGame from "../config/contracts/RouletteGame.json";
import BadBitGame from "../config/contracts/BadBitCasino.json";
import BadBitSettings from "../config/contracts/BadBitSettings.json";
import BadToken from "../config/contracts/BadToken.json";
import BadTokenEthereum from "../config/contracts/ethereum/BadToken.json";
import BadBitDistributor from "../config/contracts/BadBitDistributor.json";
import AutoBet from "../config/contracts/AutoBetSettings.json";
import TokenTransferProxy from "../config/contracts/TokenTransferProxy.json";
import IERC20 from "../config/contracts/IERC20.json";
import POSRootChainManager from "../config/contracts/POSRootChainManager.json";
import RootChainManagerHelper from "../config/contracts/RootChainManagerHelper.json";
import { ethereumNetwork, maticNetwork, provider } from "../config/client.js";
import BadBitAffiliateToken from "../config/contracts/BadBitAffiliateToken.json";
import IMaticToken from "../config/contracts/IMaticToken.json";
import networkConfigs from "../config/network-configs.json";
import EthereumDeposit from "../config/contracts/ethereum/EthereumDeposit.json";
import Deposits from "../config/contracts/Deposits.json";



setupWeb3();

let maticNetworkConfig = networkConfigs.networks[maticNetwork];

const biconomy = new Biconomy(
  new Web3.providers.HttpProvider(provider),
   {
     apiKey: maticNetworkConfig['biconomy_api_key'],
     debug: true
   }
);

export const web3Matic = new Web3(biconomy);
biconomy
  .onEvent(biconomy.READY, () => {
    // Initialize your dapp here like getting user accounts etc
    console.log("Mexa is Ready");
    window._web3Matic = web3Matic;
  })
  .onEvent(biconomy.ERROR, (error, message) => {
    // Handle error while initializing mexa
    console.error(error);
  });

export const ethersProvider = new Ethers.providers.Web3Provider(web3Matic.currentProvider);
export const posERC20Predicate = maticNetworkConfig['posERC20Predicate'];
export const wethContractAddress = maticNetworkConfig['weth'];
export const WethContract =
  () => new web3Matic.eth.Contract(IMaticToken.abi, wethContractAddress);

export const posRootChainManagerAddress = maticNetworkConfig['posRootChainManager'];
export const POSRootChainManagerContract =
  () => new window._web3.eth.Contract(POSRootChainManager.abi, posRootChainManagerAddress);


export const rootChainManagerHelperAddress = maticNetworkConfig['rootChainManagerHelper'];
export const RootChainManagerHelperContract =
  () => new window._web3.eth.Contract(RootChainManagerHelper.abi, rootChainManagerHelperAddress);

export const ethereumDepositContractAddress = EthereumDeposit.networks[ethereumNetwork].address;
export const EthereumDepositContract =
  () => new window._web3.eth.Contract(EthereumDeposit.abi, ethereumDepositContractAddress);

export const maticDepositContractAddress = Deposits.networks[maticNetwork].address;
export const MaticDepositContract =
  () => new web3Matic.eth.Contract(Deposits.abi, maticDepositContractAddress);

export const rollGameAddress = RollGame.networks[maticNetwork].address;
export const RollGameContract =
  () => new web3Matic.eth.Contract(RollGame.abi, rollGameAddress);

export const tokenProxyAddress = TokenTransferProxy.networks[maticNetwork].address;
export const TokenProxyContract =
  () => new web3Matic.eth.Contract(TokenTransferProxy.abi, tokenProxyAddress);

export const spinGameAddress = SpinGame.networks[maticNetwork].address;
export const SpinGameContract =
  () => new web3Matic.eth.Contract(SpinGame.abi, spinGameAddress);

export const scratchGameAddress = ScratchGame.networks[maticNetwork].address;
export const scratchGameTxHash = ScratchGame.networks[maticNetwork].transactionHash;
export const ScratchGameContract =
  () => new web3Matic.eth.Contract(ScratchGame.abi, scratchGameAddress)

export const rouletteGameAddress = RouletteGame.networks[maticNetwork].address;
export const rouletteGameTxHash = RouletteGame.networks[maticNetwork].transactionHash;
export const RouletteGameContract =
  () => new web3Matic.eth.Contract(RouletteGame.abi, rouletteGameAddress)

export const badBitGameAddress = BadBitGame.networks[maticNetwork].address;
export const badBitGameContractDeployTransactionHash = BadBitGame.networks[maticNetwork].transactionHash;
export const BadBitGameContract =
  () => new web3Matic.eth.Contract(BadBitGame.abi, badBitGameAddress);

export const badBitSettingsAddress = BadBitSettings.networks[maticNetwork].address;
export const BadBitSettingsContract =
  () => new web3Matic.eth.Contract(BadBitSettings.abi, badBitSettingsAddress);

export const badTokenGameAddress = BadToken.networks[maticNetwork].address;
export const badTokenTxHash = BadToken.networks[maticNetwork].transactionHash;
export const BadTokenContract =
  () => new web3Matic.eth.Contract(BadToken.abi, badTokenGameAddress);

export const badTokenEthereumAddress = BadTokenEthereum.networks[ethereumNetwork].address;
export const BadTokenEthereumContract =
  () => new window._web3.eth.Contract(BadTokenEthereum.abi, badTokenEthereumAddress);

export const badBitDistributorGameAddress = BadBitDistributor.networks[maticNetwork].address;
export const badBitDistributorTxHash = BadBitDistributor.networks[maticNetwork].transactionHash;
export const BadBitDistributorContract =
  () => new web3Matic.eth.Contract(BadBitDistributor.abi, badBitDistributorGameAddress);

export const autoBetAddress = AutoBet.networks[maticNetwork].address;
export const autoBetTxHash = AutoBet.networks[maticNetwork].transactionHash;
export const AutoBetContract =
  () => new web3Matic.eth.Contract(AutoBet.abi, autoBetAddress);

export const badBitAffiliateTokenAddress = BadBitAffiliateToken.networks[maticNetwork].address;
export const BadBitAffiliateTokenContract =
  () => new web3Matic.eth.Contract(BadBitAffiliateToken.abi, badBitAffiliateTokenAddress);

export const WETHEventsContract = new Ethers.Contract(
  wethContractAddress,
  IERC20.abi,
  ethersProvider
);

export const RollGameEventsContract = new Ethers.Contract(
  rollGameAddress,
  RollGame.abi,
  ethersProvider
);

export const AutoBetEventsContract = new Ethers.Contract(
  autoBetAddress,
  AutoBet.abi,
  ethersProvider
);

export const SpinGameEventsContract = new Ethers.Contract(
  spinGameAddress,
  SpinGame.abi,
  ethersProvider
);

export const ScratchGameEventsContract = new Ethers.Contract(
  scratchGameAddress,
  ScratchGame.abi,
  ethersProvider
);

export const RouletteGameEventsContract = new Ethers.Contract(
  rouletteGameAddress,
  RouletteGame.abi,
  ethersProvider
);

export const BadBitGameEventsContract = new Ethers.Contract(
  badBitGameAddress,
  BadBitGame.abi,
  ethersProvider
);

export const BadBitSettingsEventsContract = new Ethers.Contract(
  badBitSettingsAddress,
  BadBitSettings.abi,
  ethersProvider
);

export const BadBitDistributorEventsContract = new Ethers.Contract(
  badBitDistributorGameAddress,
  BadBitDistributor.abi,
  ethersProvider
);

export const BadTokenEventsContract = new Ethers.Contract(
  badTokenGameAddress,
  BadToken.abi,
  ethersProvider
);
