import React from "react";

export default function MakerDAO() {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="85" height="85" rx="42.5" fill="white"/>
      <path d="M17.4867 60.103V25.9011L38.7364 41.6866V60.103" stroke="#19BB9C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M66.2598 60.1036V25.9017L45.0101 41.6872V60.1036" stroke="#54697A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
