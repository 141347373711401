export const GET_BAD_TOKEN_BALANCE_REQUEST = "GET_BAD_TOKEN_BALANCE_REQUEST";
export const GET_BAD_TOKEN_BALANCE_SUCCESS = "GET_BAD_TOKEN_BALANCE_SUCCESS";
export const GET_BAD_TOKEN_BALANCE_ERROR = "GET_BAD_TOKEN_BALANCE_ERROR";

export const GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST = "GET_ETHEREUM_BAD_TOKEN_BALANCE_REQUEST";
export const GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS = "GET_ETHEREUM_BAD_TOKEN_BALANCE_SUCCESS";
export const GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR = "GET_ETHEREUM_BAD_TOKEN_BALANCE_ERROR";

export const GET_STAKED_BAD_TOKEN_BALANCE_REQUEST =
  "GET_STAKED_BAD_TOKEN_BALANCE_REQUEST";
export const GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS =
  "GET_STAKED_BAD_TOKEN_BALANCE_SUCCESS";
export const GET_STAKED_BAD_TOKEN_BALANCE_ERROR =
  "GET_STAKED_BAD_TOKEN_BALANCE_ERROR";

export const STAKE_TOKENS_REQUEST = "STAKE_TOKENS_REQUEST";
export const STAKE_TOKENS_SUCCESS = "STAKE_TOKENS_SUCCESS";
export const STAKE_TOKENS_ERROR = "STAKE_TOKENS_ERROR";

export const WITHDRAW_TOKENS_REQUEST = "WITHDRAW_TOKENS_REQUEST";
export const WITHDRAW_TOKENS_SUCCESS = "WITHDRAW_TOKENS_SUCCESS";
export const WITHDRAW_TOKENS_ERROR = "WITHDRAW_TOKENS_ERROR";

export const DEPOSIT_BAD_TOKEN_REQUEST = "DEPOSIT_BAD_TOKEN_REQUEST";
export const DEPOSIT_BAD_TOKEN_SUCCESS = "DEPOSIT_BAD_TOKEN_SUCCESS";
export const DEPOSIT_BAD_TOKEN_ERROR = "DEPOSIT_BAD_TOKEN_ERROR";

export const WITHDRAW_BAD_TOKEN_REQUEST = "WITHDRAW_BAD_TOKEN_REQUEST";
export const WITHDRAW_BAD_TOKEN_SUCCESS = "WITHDRAW_BAD_TOKEN_SUCCESS";
export const WITHDRAW_BAD_TOKEN_ERROR = "WITHDRAW_BAD_TOKEN_ERROR";

export const GET_BAD_TOKEN_REVENUE_SHARE_REQUEST =
  "GET_BAD_TOKEN_REVENUE_SHARE_REQUEST";
export const GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS =
  "GET_BAD_TOKEN_REVENUE_SHARE_SUCCESS";
export const GET_BAD_TOKEN_REVENUE_SHARE_ERROR =
  "GET_BAD_TOKEN_REVENUE_SHARE_ERROR";

export const ADD_BAD_TOKEN_WIN_NOTIF = "ADD_BAD_TOKEN_WIN_NOTIF";

export const GET_BAD_TOKEN_WIN_PROB_REQUEST = "GET_BAD_TOKEN_WIN_PROB_REQUEST";
export const GET_BAD_TOKEN_WIN_PROB_SUCCESS = "GET_BAD_TOKEN_WIN_PROB_SUCCESS";
export const GET_BAD_TOKEN_WIN_PROB_ERROR = "GET_BAD_TOKEN_WIN_PROB_ERROR";

export const GET_BAD_TOKEN_WIN_PROB_DON_REQUEST = "GET_BAD_TOKEN_WIN_PROB_DON_REQUEST";
export const GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS = "GET_BAD_TOKEN_WIN_PROB_DON_SUCCESS";
export const GET_BAD_TOKEN_WIN_PROB_DON_ERROR = "GET_BAD_TOKEN_WIN_PROB_DON_ERROR";

export const GET_TOKEN_REVENUE_HISTORY_REQUEST =
  "GET_TOKEN_REVENUE_HISTORY_REQUEST";
export const GET_TOKEN_REVENUE_HISTORY_SUCCESS =
  "GET_TOKEN_REVENUE_HISTORY_SUCCESS";
export const GET_TOKEN_REVENUE_HISTORY_ERROR =
  "GET_TOKEN_REVENUE_HISTORY_ERROR";

export const GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST =
  "GET_EXTRA_TOKEN_WIN_CHANCE_REQUEST";
export const GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS =
  "GET_EXTRA_TOKEN_WIN_CHANCE_SUCCESS";
export const GET_EXTRA_TOKEN_WIN_CHANCE_ERROR =
  "GET_EXTRA_TOKEN_WIN_CHANCE_ERROR";

export const GET_PLAYERS_LEVEL_REQUEST = "GET_PLAYERS_LEVEL_REQUEST";
export const GET_PLAYERS_LEVEL_SUCCESS = "GET_PLAYERS_LEVEL_SUCCESS";
export const GET_PLAYERS_LEVEL_ERROR = "GET_PLAYERS_LEVEL_ERROR";

export const GET_TOKEN_STATS_REQUEST = "GET_TOKEN_STATS_REQUEST";
export const GET_TOKEN_STATS_SUCCESS = "GET_TOKEN_STATS_SUCCESS";
export const GET_TOKEN_STATS_ERROR = "GET_TOKEN_STATS_ERROR";

export const POST_USER_TITLE_REQUEST = "POST_USER_TITLE_REQUEST";
export const POST_USER_TITLE_SUCCESS = "POST_USER_TITLE_SUCCESS";
export const POST_USER_TITLE_ERROR = "POST_USER_TITLE_ERROR";

export const GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST = "GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_REQUEST";
export const GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS = "GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_SUCCESS";
export const GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR = "GET_BLOCKS_LEFT_TO_STAKED_TOKEN_PERIOD_END_ERROR";
