import React from "react";

export default function EditIcon({ size = 16, color = "#FFFFFF" }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22961 6.24403C9.09204 6.24403 8.98056 6.35552 8.98056 6.49308V8.70439C8.98007 9.11688 8.64589 9.45115 8.2334 9.45154H1.24526C0.832767 9.45115 0.49859 9.11688 0.498104 8.70439V2.21435C0.49859 1.80195 0.832767 1.46768 1.24526 1.46719H3.45657C3.59413 1.46719 3.70562 1.3557 3.70562 1.21814C3.70562 1.08068 3.59413 0.96909 3.45657 0.96909H1.24526C0.557837 0.969868 0.000778287 1.52693 0 2.21435V8.70448C0.000778287 9.39191 0.557837 9.94897 1.24526 9.94974H8.2334C8.92082 9.94897 9.47788 9.39191 9.47866 8.70448V6.49308C9.47866 6.35552 9.36717 6.24403 9.22961 6.24403V6.24403Z"
        fill="white"
      />
      <path
        d="M9.37998 0.328267C8.94229 -0.109422 8.23269 -0.109422 7.795 0.328267L3.35176 4.77151C3.32131 4.80196 3.29932 4.8397 3.28785 4.88115L2.70355 6.9906C2.67952 7.07709 2.70394 7.1697 2.76737 7.23323C2.83089 7.29666 2.92351 7.32108 3.01 7.29715L5.11945 6.71275C5.16089 6.70127 5.19864 6.67928 5.22909 6.64883L9.67223 2.2055C10.1092 1.76751 10.1092 1.05849 9.67223 0.620514L9.37998 0.328267ZM3.89442 4.93339L7.53087 1.29685L8.70365 2.46963L5.06711 6.10617L3.89442 4.93339ZM3.66016 5.40348L4.59712 6.34053L3.30108 6.69962L3.66016 5.40348ZM9.32005 1.85332L9.05592 2.11745L7.88305 0.944573L8.14727 0.680442C8.39039 0.437324 8.78459 0.437324 9.02771 0.680442L9.32005 0.972689C9.56278 1.2161 9.56278 1.61001 9.32005 1.85332V1.85332Z"
        fill="white"
      />
    </svg>
  );
}
