import {
  BadBitAffiliateTokenContract
} from "../contract";
import { fromHex, toHex } from "./main";

export const tokenOfOwnerByIndexFromContract = async (address, tokenId = 0, from) => {
  try {
    const contract = await BadBitAffiliateTokenContract();
    return await contract.methods.tokenOfOwnerByIndex(address, tokenId).call({ from });
  } catch (error) {
    console.log(error);
  }
};

export const getTokenAffiliatesFromContract = async (tokenId, from) => {
  try {
    const contract = await BadBitAffiliateTokenContract();
    return await contract.methods.getTokenAffiliates(tokenId).call({ from });
  } catch (error) {
    console.log(error);
  }
};

export const disassociateWithAffiliateFromContract =
  (from, sendTxFunc, dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitAffiliateTokenContract();
      const functionSignature = contract.methods
        .disassociateWithAffilate()
        .encodeABI();

      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Breaking affiliation...",
          successTitle: "Breaking affiliation complete.",
          errorTitle: "Breaking affiliation denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const tokenDataFromContract = async (id, from) => {
  try {
    const contract = await BadBitAffiliateTokenContract();
    const data = await contract.methods.tokenData(Number(id)).call({ from });
    console.log("BALANCE: ", data.balance, data.isBalancePositive);
    return {
      balance: data.balance,
      isBalancePositive: data.isBalancePositive,
      affiliateCode: fromHex(`${data.affiliateCode}`)
    };
  } catch (error) {
    console.log(error);
  }
};

export const associateWithAffiliateFromContract = (
  code,
  from,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(code);
      const contract = await BadBitAffiliateTokenContract();
      const functionSignature = contract.methods
        .associateWithAffilate(toHex(code))
        .encodeABI();

      var txObject = {
        functionSignature,
        contract,
        account: from
      }
      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Associating with Affiliate...",
          successTitle: "You are now associated with the affiliate!",
          errorTitle: "Association failed! Please enter a valid affiliate code. Codes are case-sensitive."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const setAffiliateCodeFromContract =
  (tokenId, code, from, sendTxFunc, dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitAffiliateTokenContract();
      const functionSignature = contract.methods
        .setAffiliateCode(tokenId, toHex(code))
        .encodeABI();
      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Setting affiliate code...",
          successTitle: "You have set your affiliate code!",
          errorTitle: "Setting affiliate code denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
});

export const balanceOfFromContract = async (owner, from) => {
  try {
    const contract = await BadBitAffiliateTokenContract();
    return Number(await contract.methods.balanceOf(owner).call({ from }));
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param fromAdd
 * @param toAdd
 * @param tokenId
 * @param from
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<unknown>}
 */
export const safeTransferFromFromContract = (fromAdd, toAdd, tokenId, from, sendTxFunc, dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitAffiliateTokenContract();
      const functionSignature = contract.methods.safeTransferFrom(fromAdd, toAdd, tokenId).encodeABI();

      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Transferring affiliate token...",
          successTitle: "Transferring affiliate token complete.",
          errorTitle: "Transferring affiliate token denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status)
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
