import {
  GET_LEADERBOARD_STATS_REQUEST,
  GET_LEADERBOARD_STATS_SUCCESS,
  GET_LEADERBOARD_STATS_ERROR,

  GET_LEADERBOARD_DATA_REQUEST,
  GET_LEADERBOARD_DATA_SUCCESS,
  GET_LEADERBOARD_DATA_ERROR,
} from "../actionTypes/leaderboardStats";

const INITIAL_STATE = {
  leaderboardStats: { data: [] },
  gettingLeaderboardStats: false,
  gettingLeaderboardStatsError: "",

  leaderboardsWeeklyPrize: 0,
  leaderboardsBonusPositions: [],
  gettingLeaderboardsData: false,
  gettingLeaderboardsDataError: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADERBOARD_STATS_REQUEST:
      return {
        ...state,
        gettingLeaderboardStats: true,
        gettingLeaderboardStatsError: ""
      };

    case GET_LEADERBOARD_STATS_SUCCESS:
      return {
        ...state,
        leaderboardStats: payload,
        gettingLeaderboardStats: false,
        gettingLeaderboardStatsError: ""
      };

    case GET_LEADERBOARD_STATS_ERROR:
      return {
        ...state,
        gettingLeaderboardStats: false,
        gettingLeaderboardStatsError: payload
      };


    case GET_LEADERBOARD_DATA_REQUEST:
      return {
        ...state,
        gettingLeaderboardsData: true,
        gettingLeaderboardsDataError: ""
      };

    case GET_LEADERBOARD_DATA_SUCCESS:
      return {
        ...state,
        leaderboardsWeeklyPrize: payload.leaderboardsWeeklyPrize,
        leaderboardsBonusPositions: payload.leaderboardsBonusPositions.split(","),
        gettingLeaderboardsData: false,
        gettingLeaderboardsDataError: ""
      };

    case GET_LEADERBOARD_DATA_ERROR:
      return {
        ...state,
        gettingLeaderboardsData: false,
        gettingLeaderboardsDataError: payload
      };

    default:
      return state;
  }
};
