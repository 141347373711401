import React from "react";

export default function BAT() {
  return (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="85" height="85" rx="42.5" fill="white"/>
      <path d="M42.451 17V33.919L52.3946 51.4673L66.8192 60C66.9491 59.7689 66.9305 59.4501 66.7317 59.0997L54.9531 38.3133L43.1745 17.5269C42.975 17.1754 42.7126 17 42.451 17Z" fill="#9E1F63"/>
      <path d="M18.0825 60L32.507 51.4677L42.451 33.919V17C42.1883 17 41.9263 17.1754 41.7275 17.5265L29.9486 38.3133L18.1697 59.0994C17.9716 59.4501 17.953 59.7685 18.0825 60Z" fill="#FF4724"/>
      <path d="M66.902 59.6441L52.4286 51.5686H32.4738L18 59.6441C18.1311 59.8636 18.4122 60 18.8109 60H66.0914C66.4905 60 66.7709 59.8636 66.902 59.6441Z" fill="#662D91"/>
    </svg>
  );
}
