import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { connect } from "react-redux";
import { setActiveGame } from "../../actions/games/games";
import { getTokenStats } from "../../actions/badToken";
import { formatNumber } from "../../services/utils";
import { DP } from "../../config/constants";

import "./BadToken.scss";

class BadToken extends Component {
  async componentWillMount() {
    this.props.getTokenStats();
  }

  componentDidMount() {
    this.props.setActiveGame("");
    document.title = "BAD Token | BadBit.Games";
  }
  render() {
    const {
      nextCycleBlocksLeft,
      currentStage,
      distributedTokensInCurrStage,
      notDistributedTokensInCurrStage,
      distributedTokens,
      distributedTokensPercent,
      revenueForCurrentDistCycle,
      percentOfDistInCurrStage,
      stakedTokens,
      maxPercentForNextStage,
    } = this.props;

    return (
      <main className="main bad-token">
        <div className="container">
          <p className="title">
            Current stage: <span>{formatNumber(currentStage, DP)}</span>
          </p>
          <div className="progress">
            <div className="progress-bar">
              <span style={{ flex: `0 0 ${percentOfDistInCurrStage}%` }} />
              <span />
              <p>{formatNumber(distributedTokensInCurrStage, DP)}</p>
              <p>{formatNumber(notDistributedTokensInCurrStage, DP)}</p>
            </div>
            <div className="details">
              <p className="detail">
                <span className="yellow" /> Number of BAD tokens that have been
                distributed in the current stage
              </p>
              <p className="detail">
                <span className="blue" /> Number of BAD tokens not yet
                distributed in the current stage
              </p>
            </div>
          </div>
          <div className="stat">
            <span className="stat__title">
              {formatNumber(distributedTokens, DP)}
            </span>
            <span className="stat__supply">
              <Tooltip title={`${distributedTokensPercent}%`} theme="roll">
                {formatNumber(distributedTokensPercent, DP)}%{" "}
              </Tooltip>
              of total supply
            </span>
            <span className="stat__info">BAD Tokens Distributed</span>
          </div>
          <div className="stats">
            {nextCycleBlocksLeft > 0 && (
              <div className="stat">
                <span className="stat__title">
                  {formatNumber(nextCycleBlocksLeft, DP)}
                </span>
                <span className="stat__info">
                  Number of blocks to go before next revenue distribution
                </span>
              </div>
            )}
            <div className="stat">
              <Tooltip title={revenueForCurrentDistCycle} theme="roll">
                <span className="stat__title">
                  {formatNumber(revenueForCurrentDistCycle, DP)}{" "}
                  <span>ETH</span>
                </span>
              </Tooltip>
              <span className="stat__info">Revenue Pool</span>
            </div>

            <div className="stat">
              <Tooltip title={stakedTokens} theme="roll">
                <span className="stat__title">
                  {formatNumber(stakedTokens, DP)}
                </span>
              </Tooltip>
              <span className="stat__info">BAD Tokens Staked</span>
            </div>

            <div className="stat">
              <Tooltip title={maxPercentForNextStage} theme="roll">
                <span className="stat__title">
                  {formatNumber(maxPercentForNextStage, DP)}%
                </span>
              </Tooltip>
              <span className="stat__info">Revenue Share</span>
            </div>

          </div>
        </div>
      </main>
    );
  }
}

BadToken.propTypes = {};

const mapStateToProps = ({ badToken }) => ({
  currentStage: badToken.currentStage,
  distributedTokensInCurrStage: badToken.distributedTokensInCurrStage,
  notDistributedTokensInCurrStage: badToken.notDistributedTokensInCurrStage,
  distributedTokens: badToken.distributedTokens,
  distributedTokensPercent: badToken.distributedTokensPercent,
  revenueForCurrentDistCycle: badToken.revenueForCurrentDistCycle,
  percentOfDistInCurrStage: badToken.percentOfDistInCurrStage,
  nextCycleBlocksLeft: badToken.nextCycleBlocksLeft,
  stakedTokens: badToken.stakedTokens,
  maxPercentForNextStage: badToken.maxPercentForNextStage,
});

const mapDispatchToProps = {
  setActiveGame,
  getTokenStats
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadToken);
