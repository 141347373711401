import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import __html from "../../../../config/tos.html";
const template = { __html: __html };

import { acceptTOS } from "../../../../actions/account";

import "./ToS.scss";

class ToS extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { acceptTOS } = this.props;

    return (
      <div className="modal__content modal__content--tos">
        <p className="title">Terms of Service</p>

        <PerfectScrollbar option={{minScrollbarLength: 40}}>
          <div className="description" dangerouslySetInnerHTML={template} />
        </PerfectScrollbar>

        <div className="btn__group">
          <button className="btn btn--primary" onClick={acceptTOS}>
            Accept
          </button>
          <button
            className="btn btn--dark"
            onClick={() => window.location.replace("/")}
          >
            Reject
          </button>
        </div>
        <p className="info">
          Please read and accept our Terms of Service to start winning!
        </p>
      </div>
    );
  }
}

ToS.propTypes = {
  acceptTOS: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  acceptTOS
};

export default connect(
  null,
  mapDispatchToProps
)(ToS);
