import React from "react";

export default function RouletteLogo({ width = 230 }) {
  return (
    <svg
      width={width}
      height={width / 7.35}
      viewBox="0 0 169 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.324 11.876C38.1827 11.876 38.864 11.6147 39.368 11.092C39.872 10.5693 40.124 9.93467 40.124 9.188C40.124 8.42267 39.8813 7.85333 39.396 7.48C38.9293 7.088 38.3227 6.892 37.576 6.892H36.456L35.98 11.876H37.324ZM40.488 3.392C42.4853 3.392 44.0813 3.868 45.276 4.82C46.4893 5.75333 47.096 7.144 47.096 8.992C47.096 9.664 46.956 10.308 46.676 10.924C46.396 11.5213 46.06 12.0067 45.668 12.38C44.8467 13.1267 44.0907 13.6587 43.4 13.976L42.98 14.144L46.004 20.36H41.104L35.672 14.9L35.14 20.36H29.68L28 3.392H40.488Z"
        fill="white"
      />
      <path
        d="M47.9303 11.988C47.9303 9.05733 48.7796 6.82667 50.4783 5.296C52.1956 3.76533 54.641 3 57.8143 3C61.0063 3 63.4516 3.76533 65.1503 5.296C66.8676 6.82667 67.7263 9.05733 67.7263 11.988C67.7263 14.9187 66.821 17.0933 65.0103 18.512C63.2183 19.9307 60.8196 20.64 57.8143 20.64C54.8276 20.64 52.429 19.9307 50.6183 18.512C48.8263 17.0933 47.9303 14.9187 47.9303 11.988ZM60.2783 11.876C60.2783 8.10533 59.457 6.22 57.8143 6.22C56.1903 6.22 55.3783 8.10533 55.3783 11.876C55.3783 15.6467 56.1903 17.532 57.8143 17.532C59.457 17.532 60.2783 15.6467 60.2783 11.876Z"
        fill="white"
      />
      <path
        d="M85.5643 18.512C83.8843 19.9307 81.6163 20.64 78.7603 20.64C75.9043 20.64 73.6269 19.9307 71.9283 18.512C70.2483 17.0933 69.4083 14.8627 69.4083 11.82V3.392H76.8563V13.192C76.8563 15.376 77.1363 16.7013 77.6963 17.168C77.9576 17.4107 78.3123 17.532 78.7603 17.532C79.2083 17.532 79.5629 17.42 79.8243 17.196C80.1043 16.9533 80.3003 16.5893 80.4123 16.104C80.5803 15.3573 80.6643 14.3867 80.6643 13.192V3.392H88.1123V11.82C88.1123 14.8627 87.2629 17.0933 85.5643 18.512Z"
        fill="white"
      />
      <path
        d="M91.4736 20.36L89.7936 3.392H98.6136L97.3536 16.02L102.422 15.74L100.826 20.36H91.4736Z"
        fill="white"
      />
      <path
        d="M112.06 13.836C112.06 14.9747 112.246 15.7493 112.62 16.16C112.993 16.552 113.46 16.748 114.02 16.748C114.897 16.748 115.784 16.5987 116.68 16.3L117.1 16.16L118.304 18.568C118.136 18.7173 117.884 18.9133 117.548 19.156C117.212 19.38 116.456 19.688 115.28 20.08C114.122 20.4533 112.918 20.64 111.668 20.64C110.436 20.64 109.4 20.4627 108.56 20.108C107.72 19.7533 107.076 19.3427 106.628 18.876C106.18 18.3907 105.815 17.756 105.535 16.972C105.255 16.1693 105.069 15.488 104.976 14.928C104.901 14.368 104.836 13.668 104.78 12.828L104.108 3.392H116.708L117.492 6.752L112.732 7.172L112.452 9.888H116.204L116.764 12.52L112.06 13.696V13.836Z"
        fill="white"
      />
      <path
        d="M135.174 3.392L135.734 8.712L131.226 7.816L129.966 20.36H124.506L123.246 7.816L118.71 8.712L119.27 3.392H135.174Z"
        fill="white"
      />
      <path
        d="M152.209 3.392L152.769 8.712L148.261 7.816L147.001 20.36H141.541L140.281 7.816L135.745 8.712L136.305 3.392H152.209Z"
        fill="white"
      />
      <path
        d="M161.852 13.836C161.852 14.9747 162.039 15.7493 162.412 16.16C162.786 16.552 163.252 16.748 163.812 16.748C164.69 16.748 165.576 16.5987 166.472 16.3L166.892 16.16L168.096 18.568C167.928 18.7173 167.676 18.9133 167.34 19.156C167.004 19.38 166.248 19.688 165.072 20.08C163.915 20.4533 162.711 20.64 161.46 20.64C160.228 20.64 159.192 20.4627 158.352 20.108C157.512 19.7533 156.868 19.3427 156.42 18.876C155.972 18.3907 155.608 17.756 155.328 16.972C155.048 16.1693 154.862 15.488 154.768 14.928C154.694 14.368 154.628 13.668 154.572 12.828L153.9 3.392H166.5L167.284 6.752L162.524 7.172L162.244 9.888H165.996L166.556 12.52L161.852 13.696V13.836Z"
        fill="white"
      />
      <circle cx="11.5" cy="11.5" r="10.5" stroke="white" strokeWidth="2" />
      <circle cx="11.5001" cy="11.5001" r="7.32" stroke="white" />
      <circle cx="11.5001" cy="11.5001" r="2.3" fill="white" />
      <path d="M11.5 0.920044V4.14004" stroke="white" />
      <path d="M11.5 18.4V21.62" stroke="white" />
      <path d="M21.85 11.27L18.63 11.27" stroke="white" />
      <path d="M4.37 11.27L1.15 11.27" stroke="white" />
      <path d="M18.8187 18.5887L16.5418 16.3119" stroke="white" />
      <path d="M6.45837 6.22803L4.18149 3.95114" stroke="white" />
      <path d="M4.18127 18.5887L6.45816 16.3119" stroke="white" />
      <path d="M16.542 6.22888L18.8189 3.952" stroke="white" />
      <path d="M2.53674 16.4449L5.32535 14.8349" stroke="white" />
      <path d="M17.675 7.70581L20.4637 6.09581" stroke="white" />
      <path d="M1.50317 13.9491L4.61346 13.1157" stroke="white" />
      <path d="M18.387 9.42554L21.4972 8.59214" stroke="white" />
      <path d="M1.52734 8.50281L4.63007 9.36391" stroke="white" />
      <path d="M18.3699 13.1774L21.4726 14.0385" stroke="white" />
      <path d="M2.5835 6.01599L5.35763 7.6508" stroke="white" />
      <path d="M17.6422 14.8907L20.4163 16.5256" stroke="white" />
      <path d="M6.4054 2.26135L7.99046 5.0642" stroke="white" />
      <path d="M15.0093 17.4773L16.5943 20.2801" stroke="white" />
      <path d="M8.91064 1.24976L9.71627 4.36735" stroke="white" />
      <path d="M13.2833 18.1744L14.089 21.292" stroke="white" />
      <path d="M14.2681 1.29736L13.407 4.40009" stroke="white" />
      <path d="M9.59253 18.1412L8.73143 21.244" stroke="white" />
      <path d="M16.755 2.35388L15.1202 5.12802" stroke="white" />
      <path d="M7.87927 17.4136L6.24447 20.1877" stroke="white" />
      <rect x="11.04" y="6.43994" width="0.92" height="10.12" fill="white" />
      <rect
        x="16.5601"
        y="11.04"
        width="0.92"
        height="10.12"
        transform="rotate(90 16.5601 11.04)"
        fill="white"
      />
    </svg>
  );
}
