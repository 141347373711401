import React from "react";

export default function TxSuccessIcon() {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2 24C11.52 24 12.6 22.92 12.6 21.6H7.8C7.8 22.92 8.88 24 10.2 24ZM18 16.8V10.2C18 6.48 15.48 3.48 12 2.64V1.8C12 0.84 11.16 0 10.2 0C9.24 0 8.4 0.84 8.4 1.8V2.64C4.92 3.48 2.4 6.48 2.4 10.2V16.8L0 19.2V20.4H20.4V19.2L18 16.8Z"
        fill="#42A8DF"
      />
    </svg>
  );
}
