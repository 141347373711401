import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BadBitLogo from "../Decorative/BadBitLogo.png";

import "./NoProvider.scss";

class NoProvider extends Component {
  render() {
    const { accountError, history } = this.props;
    const isWrongNetwork = accountError.includes("Wrong network");
    if (!accountError) {
      history.push("");
    }
    return (
      <div className="no-provider">
        {isWrongNetwork && (
          <p>
            <img className="logo" src={BadBitLogo} alt="BadBit Games" />
            Please make sure that you are logged in and set to the correct
            network.
          </p>
        )}
        {!isWrongNetwork && (
          <p>
            <img className="logo" src={BadBitLogo} alt="BadBit Games" />
            In order to join the game, you will need a browser capable of
            interacting with the Ethereum blockchain. We recommend using the
            MetaMask extension available for all major browsers. If you already
            have MetaMask installed, please make sure that you are logged in and
            set to the correct network (Main Ethereum Network).
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              Download MetaMask
            </a>
          </p>
        )}
      </div>
    );
  }
}

NoProvider.propTypes = {
  accountError: PropTypes.string.isRequired
};

const mapStateToProps = ({ account }) => ({
  accountError: account.accountError
});

export default connect(mapStateToProps)(NoProvider);
