import React from "react";

export default function TxErrorIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
        fill="#E0343C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 28H13V22H38V28Z"
        fill="white"
      />
    </svg>
  );
}
