import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changeModalType, toggleModal } from '../../../../actions/modal';
import { dismissAutBetBalanceNotificationModal } from '../../../../actions/games/games';

import "./AutoBetBalanceNotification.scss";

class AutoBetBalanceNotification extends Component {
  render() {
    const { toggleModal, changeModalType, dismissAutBetBalanceNotificationModal } = this.props;
    return (
      <div className="modal__content modal__content--confirm-auto-bet">
        <p className="title">Warning</p>

        <div className="description">
          Your Contract ETH balance is running low. Please deposit funds from Matic ETH to Contract ETH to avoid interruption to your auto-bet session.
        </div>

        <div className="btn__group">
          <button className="btn btn--primary" onClick={() => {
            changeModalType("MoveMaticEthToContract");
          }}>
            Deposit to Contract ETH
          </button>
          <button
            className="btn btn--dark"
            onClick={() => {
              dismissAutBetBalanceNotificationModal();
              toggleModal();
            }}
          >
            Dismiss and Do Not Show Again
          </button>
        </div>
      </div>
    );
  }
}

AutoBetBalanceNotification.propTypes = {
  changeModalType: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  dismissAutBetBalanceNotificationModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeModalType,
  toggleModal,
  dismissAutBetBalanceNotificationModal,
};

export default connect(
  null,
  mapDispatchToProps
)(AutoBetBalanceNotification);
