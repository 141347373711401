import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import BigNumber from "bignumber.js";
import {ethToWei, weiToEth} from "../../../../services/ethereum/main";
import {
  formatNumber,
  formatTimestampToDate
} from "../../../../services/utils";
import { toggleModal } from "../../../../actions/modal";
import {
  listenForNewBlockAction,
  removeListenForNewBlockAction,
  stakeTokens,
  withdrawTokens
} from "../../../../actions/badToken";
import { DP } from "../../../../config/constants";
import { changePage } from "../../../../actions/pagination";
import { getRevenueDistributedHistory } from "../../../../actions/badToken";

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import Loader from "../../../Loader/Loader";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./BadToken.scss";

class BadToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonStaked: "",
      nonStakedWei: "",
      staked: "",
      stakedWei: "",
      history: []
    };

    this.handleStakedAmountInput = this.handleStakedAmountInput.bind(this);
    this.handleNonStakedAmountInput = this.handleNonStakedAmountInput.bind(
      this
    );
    this.nonStakedMax = this.nonStakedMax.bind(this);
  }

  componentDidMount() {
    const { getRevenueDistributedHistory, listenForNewBlockAction } = this.props;
    getRevenueDistributedHistory();
    listenForNewBlockAction();
  }

  componentWillUnmount() {
    this.props.removeListenForNewBlockAction();
  }

  handleStakedAmountInput = e => {
    const { value } = e.target;
    this.setState({ staked: value, stakedWei: ethToWei(value) });
  };

  handleNonStakedAmountInput = e => {
    const { value } = e.target;
    this.setState({ nonStaked: value, nonStakedWei: ethToWei(value) });
  };

  stakedMax = () => {
    const { stakedBadTokenBalance } = this.props;
    this.setState({
      staked: Number(weiToEth(stakedBadTokenBalance)),
      stakedWei: stakedBadTokenBalance
    });
  };

  nonStakedMax = () => {
    const { badTokenBalance } = this.props;
    this.setState({
      nonStaked: Number(weiToEth(badTokenBalance)),
      nonStakedWei: badTokenBalance
    });
  };

  render() {
    const {
      toggleModal,
      badTokenBalance,
      stakedBadTokenBalance,
      stakeTokens,
      withdrawTokens,
      currentRevenueShare,
      totalRevenueShare,
      blockLeftToStakedTokenPeriodEnd,
      blocksLeftToNextCycle,
      tokenRevenueHistory,
      gettingTokenRevenueHistory,
      gettingTokenRevenueHistoryErr
    } = this.props;

    const canUnstake = new BigNumber(blockLeftToStakedTokenPeriodEnd).gt(0);
    const cycleDidEnd = new BigNumber(blocksLeftToNextCycle).lt(1);

    return (
      <div className="modal__content modal__content--bad-token">
        <p className="title">BAD Token Management</p>

        <div className="stake-controls">
          <div className="stake-controls__header">
            <Tooltip
              title={
                "The number of BAD tokens you own that are currently on the Matic sidechain. You are free to stake them or withdraw to the main Ethereum network."
              }
              theme="roll"
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>
            Unstaked Token Balance: <span>{formatNumber(weiToEth(badTokenBalance), DP)}</span>
          </div>

          <div className="stake-controls__input-wrapper">
            <input
              className="input--stake"
              type="number"
              placeholder="Enter amount"
              step="0.001"
              min="0"
              max={Number(weiToEth(badTokenBalance))}
              value={this.state.nonStaked}
              onChange={this.handleNonStakedAmountInput}
            />
            <button className="btn btn--dark" onClick={this.nonStakedMax}>
              <Tooltip
                theme="roll"
                title={`The maximum amount you can stake is: ${Number(
                  weiToEth(badTokenBalance)
                )} BAD`}
              >
                <InfoIcon color={"#465E71"} />
              </Tooltip>{" "}
              Max
            </button>
            <button
              className="btn btn--primary"
              disabled={
                new BigNumber(badTokenBalance).lte(0) || this.state.nonStaked <= 0
              }
              onClick={() => stakeTokens(this.state.nonStakedWei)}
            >
              Stake
            </button>
          </div>
        </div>

        <div className="stake-controls">
          <div className="stake-controls__header">
            <Tooltip
              title={
                "Your balance of BAD tokens that are currently locked at the BadBit.Games smart contract. These tokens are earning revenue rebate at the end of every distribution cycle."
              }
              theme="roll"
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>
            Staked Token Balance: <span>{formatNumber(weiToEth(stakedBadTokenBalance), DP)}</span>
          </div>

          {canUnstake && (
            <div className="blocks-left">
              <p>
                Blocks left: <span>{blockLeftToStakedTokenPeriodEnd}</span>
              </p>
            </div>
          )}

          <div className="stake-controls__input-wrapper">
            <input
              className="input--stake"
              type="number"
              placeholder="Enter amount"
              step="0.001"
              min="0"
              max={Number(weiToEth(stakedBadTokenBalance))}
              value={this.state.staked}
              onChange={this.handleStakedAmountInput}
            />
            <button className="btn btn--dark" onClick={this.stakedMax}>
              <Tooltip
                theme="roll"
                title={`The maximum amount you can unstake is: ${Number(
                  weiToEth(stakedBadTokenBalance)
                )} BAD`}
              >
                <InfoIcon color={"#465E71"} />
              </Tooltip>{" "}
              Max
            </button>
            <button
              className="btn btn--primary"
              disabled={
                new BigNumber(stakedBadTokenBalance).lte(0) ||
                this.state.staked <= 0 ||
                canUnstake
              }
              onClick={() => withdrawTokens(this.state.stakedWei)}
            >
              Unstake
            </button>
          </div>
        </div>

        <div className="revenue-share">
          <span className="revenue-share__item">
            <Tooltip
              theme="roll"
              title={
                "This is the amount of revenue rebate you will receive in the current revenue distribution cycle, based on current balance of staked BAD tokens (both yours and other players') and the current revenue of the BadBit.Games platform. As such this number will fluctuate until the end of the distribution cycle."
              }
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>{" "}
            Current revenue share:{" "}
            <span>
              <Tooltip theme="roll" title={weiToEth(currentRevenueShare)}>
                {formatNumber(weiToEth(currentRevenueShare), DP)}
              </Tooltip>
            </span>{" "}
            ETH
          </span>
          <span className="revenue-share__item">
            <Tooltip
              theme="roll"
              title={
                "This is the total amount of revenue rebate you have received up to and excluding the current distribution cycle."
              }
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>{" "}
            Total revenue share:&nbsp;
            <Tooltip theme="roll" title={weiToEth(totalRevenueShare)}>
              {formatNumber(weiToEth(totalRevenueShare), DP)} ETH
            </Tooltip>
          </span>
        </div>

        {!cycleDidEnd && (
          <div className="end-cycle">
            <p>
              Blocks left until the end of the cycle:{" "}
              <span>{blocksLeftToNextCycle}</span>
            </p>
            <p>
              (Approximately:
              {formatTimestampToDate(
                Math.round(+new Date() / 1000) + blocksLeftToNextCycle * 1
              )}
              )
            </p>
          </div>
        )}

        <div className="history">
          <p className="title">History</p>
          {!gettingTokenRevenueHistory && tokenRevenueHistory.data.length > 0 && (
            <PerfectScrollbar>
              <table className="stake-history">
                <thead className="stake-history__head">
                  <tr>
                    <th>ID</th>
                    <th>Staked tokens</th>
                    <th>Percentage share</th>
                    <th>ETH share</th>
                    <th>Cycle period</th>
                  </tr>
                </thead>
                <tbody className="stake-history__body">
                  {tokenRevenueHistory.data.map(
                    (
                      {
                        cycle,
                        startTimestamp,
                        endTimestamp,
                        ethShare,
                        stakedTokens,
                        totalStakedInCycle,
                        percentageShare
                      },
                      index
                    ) => (
                      <tr
                        className="stake-history__single"
                        key={`${index} ${cycle}`}
                      >
                        <td>{cycle}</td>
                        <td>
                          <Tooltip title={stakedTokens} theme="roll">
                            {formatNumber(stakedTokens, DP)}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={percentageShare} theme="roll">
                            {formatNumber(percentageShare, DP)}%
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title={ethShare} theme="roll">
                            {formatNumber(ethShare, DP)} ETH
                          </Tooltip>
                        </td>
                        <td>
                          {endTimestamp
                            ? `${startTimestamp} - ${endTimestamp}`
                            : startTimestamp}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </PerfectScrollbar>
          )}
          {!gettingTokenRevenueHistory &&
            gettingTokenRevenueHistoryErr !== "" && (
              <p style={{ textAlign: "center" }}>
                There was an error getting revenue history.
              </p>
            )}
          {gettingTokenRevenueHistory && (
            <Loader text="Loading revenue history..." />
          )}
          {!gettingTokenRevenueHistory &&
            gettingTokenRevenueHistoryErr === "" &&
            tokenRevenueHistory.data.length === 0 && (
              <p style={{ textAlign: "center" }}>
                {" "}
                There is no revenue history.
              </p>
            )}
        </div>

        <button className="btn btn--dark" onClick={() => toggleModal()}>
          Close
        </button>
      </div>
    );
  }
}

BadToken.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  stakeTokens: PropTypes.func.isRequired,
  withdrawTokens: PropTypes.func.isRequired,
  badTokenBalance: PropTypes.string.isRequired,
  stakedBadTokenBalance: PropTypes.string.isRequired,
  blockLeftToStakedTokenPeriodEnd: PropTypes.string.isRequired,
  blocksLeftToNextCycle: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired
};

const mapDispatchToProps = {
  toggleModal,
  stakeTokens,
  withdrawTokens,
  changePage,
  getRevenueDistributedHistory,
  listenForNewBlockAction,
  removeListenForNewBlockAction,
};

const mapStateToProps = ({ badToken, account, pagination }) => ({
  badTokenBalance: badToken.badTokenBalance,
  stakedBadTokenBalance: badToken.stakedBadTokenBalance,
  currentRevenueShare: badToken.currentRevenueShare,
  totalRevenueShare: badToken.totalRevenueShare,
  blockLeftToStakedTokenPeriodEnd: badToken.blockLeftToStakedTokenPeriodEnd,
  blocksLeftToNextCycle: badToken.blocksLeftToNextCycle,
  tokenRevenueHistory: badToken.tokenRevenueHistory,
  gettingTokenRevenueHistory: badToken.gettingTokenRevenueHistory,
  gettingTokenRevenueHistoryErr: badToken.gettingTokenRevenueHistoryErr,
  account: account.account,
  currentPage: pagination.currentPage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadToken);
