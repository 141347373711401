import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import BigNumber from "bignumber.js";
import { withdrawUsersFunds } from "../../../../actions/games/games";

import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import "./Withdraw.scss";
import { ethToWei, weiToEth } from "../../../../services/ethereum/main";

class Withdraw extends Component {
  constructor(props) {
    super(props);
    this.state = { amount: "", amountWei: "" };

    this.handleAmountInput = this.handleAmountInput.bind(this);
    this.withdrawMax = this.withdrawMax.bind(this);
  }

  handleAmountInput = e => {
    const { value } = e.target;
    this.setState({ amount: value, amountWei: ethToWei(value) });
  };

  withdrawMax = () => {
    const { userContractBalance } = this.props;
    this.setState({
      amount: Number(weiToEth(userContractBalance)),
      amountWei: userContractBalance
    });
  };

  render() {
    const { withdrawUsersFunds, toggleModal, userContractBalance } = this.props;

    const isWithdrawDisabled =
      this.state.amountWei === "" ||
      new BigNumber(this.state.amountWei).lte(0) ||
      new BigNumber(userContractBalance).lt(this.state.amountWei);

    return (
      <div className="modal__content modal__content--withdraw">
        <p className="title">Please enter amount to withdraw:</p>
        <div className="input--withdraw__wrapper">
          <input
            className="input--withdraw"
            type="number"
            placeholder="Enter amount"
            step="0.001"
            min="0"
            max={userContractBalance}
            value={this.state.amount}
            onChange={this.handleAmountInput}
          />
          <button className="btn btn--dark" onClick={this.withdrawMax}>
            <Tooltip
              theme="roll"
              title={`The maximum amount you can withdraw is: ${Number(
                weiToEth(userContractBalance)
              )} ETH`}
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>{" "}
            Max
          </button>
        </div>
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => withdrawUsersFunds(this.state.amountWei)}
            disabled={isWithdrawDisabled}
          >
            Withdraw
          </button>
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        </div>
      </div>
    );
  }
}

Withdraw.propTypes = {
  withdrawUsersFunds: PropTypes.func.isRequired,
  userContractBalance: PropTypes.string.isRequired
};

const mapStateToProps = ({ account }) => ({
  userContractBalance: account.userContractBalance
});

const mapDispatchToProps = {
  withdrawUsersFunds
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Withdraw);
