import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setRouletteMaxBetSize } from "../../../../actions/contractConstants/roulette";
import {
  toggleChips,
  setExceededMaxBetSize,
  highlight,
  unhighlight
} from "../../../../actions/games/roulette";

import "./Table.scss";

class Table extends Component {
  render() {
    const {
      overlay = "transparent",
      chipsColor = "#F4BD24",
      toggleChips,
      disableControls,
      tableNumbers,
      highlightColor,
      highlight,
      unhighlight,
      commands,
      combinations
    } = this.props;
    return (
      <svg
        width="787"
        height="341"
        viewBox="0 0 787 341"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table"
      >
        <g className="table__numbers">
          <g>
            <rect
              x="84.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["3"].highlight ? highlightColor : null}
            />
            <rect
              x="111"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 111 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M111.423 38.4613C111.423 37.2801 110.509 36.6895 108.681 36.6895H108.345L109.931 33.836L107.226 33.3324L107.823 31.2622H115.75L113.624 34.9177C113.686 34.9426 113.773 34.9799 113.885 35.0296C113.997 35.0669 114.189 35.1788 114.463 35.3653C114.736 35.5394 114.979 35.7508 115.19 35.9995C115.402 36.2357 115.594 36.5714 115.768 37.0066C115.942 37.4293 116.029 37.8956 116.029 38.4054C116.029 39.6488 115.545 40.687 114.575 41.52C113.605 42.3407 112.436 42.751 111.068 42.751C110.372 42.751 109.688 42.6391 109.017 42.4153C108.358 42.179 107.873 41.9428 107.562 41.7065L107.077 41.3708L107.972 39.5804C108.569 39.9161 109.141 40.0839 109.688 40.0839C110.235 40.0839 110.658 39.9472 110.957 39.6736C111.267 39.4001 111.423 38.996 111.423 38.4613Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="84.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["2"].highlight ? highlightColor : null}
            />
            <rect
              x="111"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 111 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M116.307 97.5636H107V95.7544C107.112 95.6301 107.417 95.313 107.914 94.8033C108.411 94.2935 108.784 93.8956 109.033 93.6096C109.294 93.3112 109.611 92.9382 109.984 92.4906C110.693 91.5954 111.047 90.8369 111.047 90.2152C111.047 89.2827 110.587 88.8164 109.667 88.8164C109.132 88.8164 108.554 88.947 107.933 89.2081L107.41 87.1565C107.933 86.8457 108.592 86.5784 109.387 86.3545C110.195 86.1183 110.898 86.0002 111.495 86.0002C112.104 86.0002 112.657 86.0561 113.155 86.168C113.664 86.2799 114.149 86.4602 114.609 86.7089C115.082 86.9576 115.455 87.3182 115.728 87.7906C116.002 88.2507 116.139 88.7916 116.139 89.4132C116.126 91.241 114.672 93.0252 111.775 94.766L111.886 95.0271L116.307 94.1132V97.5636Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="84.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["1"].highlight ? highlightColor : null}
            />
            <rect
              x="111"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 111 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M109.872 151.302L108.735 142.387L107.149 142.07L107 140H114.498L113.323 151.302H109.872Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="138.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["6"].highlight ? highlightColor : null}
            />
            <rect
              x="165"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 165 58.2132)"
              fill="#42A8DF"
            />
            <path
              d="M167.652 39.7586C167.652 39.4229 167.54 39.1556 167.316 38.9567C167.092 38.7453 166.813 38.6396 166.477 38.6396C166.141 38.6396 165.824 38.7142 165.526 38.8634C165.24 39.0126 165.035 39.224 164.91 39.4975C164.91 39.8208 165.053 40.1192 165.339 40.3928C165.625 40.6539 165.961 40.7844 166.346 40.7844C166.744 40.7844 167.061 40.6974 167.298 40.5233C167.534 40.3492 167.652 40.0944 167.652 39.7586ZM169.61 35.1519C168.802 34.8287 168.019 34.667 167.26 34.667C166.514 34.667 165.899 34.8846 165.414 35.3198C164.929 35.755 164.687 36.2959 164.687 36.9424C164.736 36.8678 164.811 36.7746 164.91 36.6626C165.01 36.5507 165.265 36.4015 165.675 36.215C166.098 36.0285 166.57 35.9353 167.092 35.9353C168.361 35.9353 169.38 36.271 170.151 36.9424C170.934 37.6138 171.326 38.5339 171.326 39.7027C171.326 40.859 170.866 41.8226 169.946 42.5935C169.038 43.3644 167.758 43.7499 166.104 43.7499C164.463 43.7499 163.095 43.2712 162.001 42.3138C160.919 41.3439 160.378 39.9141 160.378 38.0241C160.378 36.1218 160.932 34.6422 162.038 33.5853C163.145 32.5284 164.469 32 166.011 32C167.552 32 168.995 32.3917 170.338 33.175L169.61 35.1519Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="138.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["5"].highlight ? highlightColor : null}
            />
            <rect
              x="165"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 165 112.213)"
              fill="#DD0F30"
            />
            <path
              d="M170.285 92.5921C170.285 93.8479 169.794 94.855 168.811 95.6135C167.841 96.3719 166.685 96.7512 165.342 96.7512C164.671 96.7512 164.006 96.6393 163.347 96.4154C162.688 96.1916 162.203 95.9678 161.892 95.744L161.407 95.4083L162.302 93.5806C162.936 93.8665 163.49 94.0095 163.962 94.0095C164.435 94.0095 164.801 93.879 165.062 93.6179C165.336 93.3443 165.473 92.9837 165.473 92.5361C165.473 92.0761 165.342 91.6906 165.081 91.3798C164.832 91.0689 164.528 90.8327 164.167 90.6711C163.434 90.3105 162.775 90.0991 162.19 90.0369L161.799 89.981L163.16 85.2624H170.229L169.632 87.3326L166.778 88.3397V88.5449C167.835 88.8806 168.681 89.3904 169.315 90.0742C169.576 90.3478 169.8 90.7084 169.986 91.156C170.185 91.6036 170.285 92.0823 170.285 92.5921Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="138.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["4"].highlight ? highlightColor : null}
            />
            <rect
              x="165"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 165 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M161.798 140H168.68L168.101 145.8H169.295L168.959 148.467H167.822L167.542 151.302H164.297L164.017 148.467H159L159.149 145.8L161.798 140ZM163.439 142.686L161.984 145.8H163.737L163.439 142.686Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="192.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["9"].highlight ? highlightColor : null}
            />
            <rect
              x="219"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 219 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M217.75 35.9922C217.75 36.3279 217.862 36.6015 218.086 36.8128C218.31 37.0118 218.589 37.1112 218.925 37.1112C219.261 37.1112 219.572 37.0366 219.858 36.8874C220.156 36.7382 220.367 36.5269 220.492 36.2533C220.492 35.93 220.349 35.6378 220.063 35.3767C219.777 35.1032 219.435 34.9664 219.037 34.9664C218.651 34.9664 218.341 35.0535 218.104 35.2275C217.868 35.4016 217.75 35.6565 217.75 35.9922ZM215.792 40.5989C216.6 40.9222 217.377 41.0838 218.123 41.0838C218.882 41.0838 219.503 40.8662 219.988 40.431C220.473 39.9959 220.715 39.455 220.715 38.8084C220.666 38.883 220.591 38.9763 220.492 39.0882C220.392 39.2001 220.131 39.3493 219.708 39.5358C219.298 39.7223 218.832 39.8156 218.31 39.8156C217.041 39.8156 216.016 39.4799 215.232 38.8084C214.461 38.137 214.076 37.2231 214.076 36.0668C214.076 34.898 214.53 33.9282 215.437 33.1573C216.357 32.3864 217.638 32.001 219.279 32.001C220.933 32.001 222.301 32.4859 223.383 33.4557C224.477 34.4131 225.024 35.843 225.024 37.7454C225.024 39.6353 224.47 41.1087 223.364 42.1656C222.257 43.2224 220.933 43.7509 219.391 43.7509C217.85 43.7509 216.407 43.3592 215.064 42.5759L215.792 40.5989Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="192.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["8"].highlight ? highlightColor : null}
            />
            <rect
              x="219"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 219 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M217.101 91.8751C215.883 90.8431 215.273 89.8609 215.273 88.9283C215.273 87.9958 215.678 87.2746 216.486 86.7649C217.306 86.2551 218.463 86.0002 219.955 86.0002C221.459 86.0002 222.603 86.2302 223.386 86.6903C224.17 87.1379 224.561 87.772 224.561 88.5926C224.561 89.1148 224.412 89.5873 224.114 90.0101C223.815 90.4204 223.331 90.8307 222.659 91.241C224.201 92.2108 224.972 93.2304 224.972 94.2997C224.972 95.369 224.493 96.2145 223.536 96.8362C222.591 97.4454 221.329 97.7501 219.75 97.7501C218.183 97.7501 216.958 97.4952 216.075 96.9854C215.205 96.4632 214.77 95.6798 214.77 94.6354C214.77 93.5785 215.547 92.6584 217.101 91.8751ZM217.493 94.4489C217.493 94.7722 217.617 95.0395 217.866 95.2509C218.115 95.4498 218.413 95.5493 218.761 95.5493C219.109 95.5493 219.383 95.4436 219.582 95.2322C219.793 95.0209 219.899 94.797 219.899 94.5608C219.899 94.3121 219.806 94.0945 219.619 93.908C219.433 93.7091 219.128 93.4604 218.705 93.162C217.897 93.5972 217.493 94.0262 217.493 94.4489ZM221.894 89.0402C221.894 88.4683 221.565 88.1823 220.906 88.1823C220.583 88.1823 220.334 88.2631 220.16 88.4248C219.986 88.5864 219.899 88.8102 219.899 89.0962C219.899 89.3697 220.216 89.7303 220.85 90.1779C221.546 89.8298 221.894 89.4505 221.894 89.0402Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="192.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["7"].highlight ? highlightColor : null}
            />
            <rect
              x="219"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 219 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M224.18 140.262V143.377L220.039 151.565H216.402L219.088 143.34L215.619 143.899L215.433 140.262H224.18Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="246.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["12"].highlight ? highlightColor : null}
            />
            <rect
              x="273"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 273 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M267.485 43.5643L266.348 34.6494L264.762 34.3323L264.613 32.2621H272.111L270.936 43.5643H267.485Z"
              fill="white"
            />
            <path
              d="M282.721 43.5643H273.415V41.7552C273.526 41.6309 273.831 41.3138 274.328 40.8041C274.826 40.2943 275.199 39.8964 275.447 39.6104C275.709 39.312 276.026 38.939 276.399 38.4914C277.107 37.5962 277.462 36.8377 277.462 36.216C277.462 35.2835 277.002 34.8172 276.082 34.8172C275.547 34.8172 274.969 34.9478 274.347 35.2089L273.825 33.1573C274.347 32.8465 275.006 32.5791 275.802 32.3553C276.61 32.1191 277.312 32.001 277.909 32.001C278.519 32.001 279.072 32.0569 279.569 32.1688C280.079 32.2807 280.564 32.461 281.024 32.7097C281.496 32.9584 281.869 33.319 282.143 33.7914C282.417 34.2515 282.553 34.7923 282.553 35.414C282.541 37.2418 281.086 39.026 278.189 40.7668L278.301 41.0279L282.721 40.114V43.5643Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="246.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["11"].highlight ? highlightColor : null}
            />
            <rect
              x="273"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 273 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M268.387 97.5637L267.249 88.6487L265.664 88.3316L265.515 86.2614H273.012L271.837 97.5637H268.387Z"
              fill="white"
            />
            <path
              d="M277.002 97.5637L275.864 88.6487L274.279 88.3316L274.13 86.2614H281.627L280.452 97.5637H277.002Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="246.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["10"].highlight ? highlightColor : null}
            />
            <rect
              x="273"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 273 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M266.575 151.564L265.437 142.649L263.852 142.332L263.703 140.261H271.2L270.025 151.564H266.575Z"
              fill="white"
            />
            <path
              d="M279.106 143.245C278.932 142.673 278.584 142.387 278.062 142.387C277.54 142.387 277.185 142.673 276.999 143.245C276.825 143.817 276.738 144.7 276.738 145.894C276.738 148.194 277.179 149.344 278.062 149.344C278.945 149.344 279.386 148.194 279.386 145.894C279.386 144.7 279.293 143.817 279.106 143.245ZM282.128 150.202C281.009 151.234 279.653 151.75 278.062 151.75C276.47 151.75 275.115 151.234 273.996 150.202C272.877 149.158 272.318 147.734 272.318 145.931C272.318 144.128 272.834 142.692 273.866 141.623C274.898 140.541 276.296 140 278.062 140C279.828 140 281.226 140.541 282.258 141.623C283.29 142.692 283.806 144.128 283.806 145.931C283.806 147.734 283.247 149.158 282.128 150.202Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="300.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["15"].highlight ? highlightColor : null}
            />
            <rect
              x="327"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 327 58.2132)"
              fill="#42A8DF"
            />
            <path
              d="M321.346 42.5635L320.209 33.6485L318.623 33.3314L318.474 31.2612H325.972L324.797 42.5635H321.346Z"
              fill="white"
            />
            <path
              d="M336.153 38.5909C336.153 39.8467 335.662 40.8539 334.68 41.6123C333.71 42.3708 332.554 42.75 331.211 42.75C330.539 42.75 329.874 42.6381 329.215 42.4143C328.556 42.1905 328.071 41.9667 327.76 41.7429L327.276 41.4072L328.171 39.5794C328.805 39.8654 329.358 40.0084 329.831 40.0084C330.303 40.0084 330.67 39.8778 330.931 39.6167C331.205 39.3432 331.341 38.9826 331.341 38.535C331.341 38.0749 331.211 37.6895 330.95 37.3786C330.701 37.0678 330.396 36.8315 330.036 36.6699C329.302 36.3093 328.643 36.098 328.059 36.0358L327.667 35.9798L329.029 31.2612H336.097L335.5 33.3314L332.647 34.3386V34.5437C333.704 34.8794 334.549 35.3892 335.183 36.0731C335.444 36.3466 335.668 36.7072 335.855 37.1548C336.054 37.6024 336.153 38.0811 336.153 38.5909Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="300.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["14"].highlight ? highlightColor : null}
            />
            <rect
              x="327"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 327 112.213)"
              fill="#DD0F30"
            />
            <path
              d="M320.736 97.5647L319.598 88.6497L318.013 88.3326L317.864 86.2624H325.361L324.186 97.5647H320.736Z"
              fill="white"
            />
            <path
              d="M329.276 86.2624H336.158L335.58 92.0627H336.774L336.438 94.7298H335.3L335.021 97.5647H331.775L331.496 94.7298H326.479L326.628 92.0627L329.276 86.2624ZM330.917 88.9481L329.463 92.0627H331.216L330.917 88.9481Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="300.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["13"].highlight ? highlightColor : null}
            />
            <rect
              x="327"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 327 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M321.31 150.564L320.172 141.649L318.587 141.332L318.438 139.261H325.935L324.76 150.564H321.31Z"
              fill="white"
            />
            <path
              d="M331.398 146.461C331.398 145.279 330.484 144.689 328.656 144.689H328.321L329.906 141.835L327.202 141.332L327.799 139.261H335.725L333.599 142.917C333.661 142.942 333.748 142.979 333.86 143.029C333.972 143.066 334.165 143.178 334.438 143.365C334.712 143.539 334.954 143.75 335.166 143.999C335.377 144.235 335.57 144.571 335.744 145.006C335.918 145.429 336.005 145.895 336.005 146.405C336.005 147.648 335.52 148.686 334.55 149.519C333.58 150.34 332.411 150.75 331.044 150.75C330.347 150.75 329.664 150.638 328.992 150.414C328.333 150.178 327.848 149.942 327.537 149.706L327.053 149.37L327.948 147.58C328.545 147.915 329.117 148.083 329.664 148.083C330.211 148.083 330.633 147.946 330.932 147.673C331.243 147.399 331.398 146.995 331.398 146.461Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="354.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["18"].highlight ? highlightColor : null}
            />
            <rect
              x="381"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 381 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M375.184 43.5643L374.047 34.6494L372.461 34.3323L372.312 32.2621H379.81L378.635 43.5643H375.184Z"
              fill="white"
            />
            <path
              d="M383.408 37.8759C382.189 36.8439 381.58 35.8616 381.58 34.9291C381.58 33.9966 381.984 33.2754 382.792 32.7656C383.613 32.2559 384.769 32.001 386.261 32.001C387.766 32.001 388.909 32.231 389.693 32.691C390.476 33.1387 390.868 33.7728 390.868 34.5934C390.868 35.1156 390.719 35.5881 390.42 36.0109C390.122 36.4212 389.637 36.8315 388.965 37.2418C390.507 38.2116 391.278 39.2312 391.278 40.3005C391.278 41.3698 390.799 42.2153 389.842 42.837C388.897 43.4462 387.635 43.7509 386.056 43.7509C384.489 43.7509 383.265 43.496 382.382 42.9862C381.511 42.464 381.076 41.6806 381.076 40.6362C381.076 39.5793 381.853 38.6592 383.408 37.8759ZM383.799 40.4497C383.799 40.773 383.924 41.0403 384.172 41.2517C384.421 41.4506 384.719 41.5501 385.067 41.5501C385.416 41.5501 385.689 41.4444 385.888 41.233C386.099 41.0216 386.205 40.7978 386.205 40.5616C386.205 40.3129 386.112 40.0953 385.925 39.9088C385.739 39.7099 385.434 39.4612 385.011 39.1628C384.203 39.598 383.799 40.0269 383.799 40.4497ZM388.201 35.041C388.201 34.4691 387.871 34.1831 387.212 34.1831C386.889 34.1831 386.64 34.2639 386.466 34.4256C386.292 34.5872 386.205 34.811 386.205 35.097C386.205 35.3705 386.522 35.7311 387.156 36.1787C387.853 35.8306 388.201 35.4513 388.201 35.041Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="354.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["17"].highlight ? highlightColor : null}
            />
            <rect
              x="381"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 381 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M376.295 97.5637L375.158 88.6487L373.572 88.3316L373.423 86.2614H380.921L379.746 97.5637H376.295Z"
              fill="white"
            />
            <path
              d="M390.487 86.2614V89.3761L386.346 97.5637H382.71L385.395 89.3388L381.926 89.8983L381.74 86.2614H390.487Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="354.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["16"].highlight ? highlightColor : null}
            />
            <rect
              x="381"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 381 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M374.756 151.565L373.618 142.65L372.033 142.332L371.884 140.262H379.382L378.207 151.565H374.756Z"
              fill="white"
            />
            <path
              d="M387.959 147.76C387.959 147.424 387.847 147.157 387.623 146.958C387.4 146.746 387.12 146.641 386.784 146.641C386.448 146.641 386.131 146.715 385.833 146.865C385.547 147.014 385.342 147.225 385.218 147.499C385.218 147.822 385.361 148.12 385.647 148.394C385.932 148.655 386.268 148.786 386.654 148.786C387.052 148.786 387.369 148.699 387.605 148.524C387.841 148.35 387.959 148.096 387.959 147.76ZM389.917 143.153C389.109 142.83 388.326 142.668 387.568 142.668C386.821 142.668 386.206 142.886 385.721 143.321C385.236 143.756 384.994 144.297 384.994 144.944C385.043 144.869 385.118 144.776 385.218 144.664C385.317 144.552 385.572 144.403 385.982 144.216C386.405 144.03 386.877 143.936 387.4 143.936C388.668 143.936 389.687 144.272 390.458 144.944C391.242 145.615 391.633 146.535 391.633 147.704C391.633 148.86 391.173 149.824 390.253 150.595C389.346 151.366 388.065 151.751 386.411 151.751C384.77 151.751 383.402 151.272 382.308 150.315C381.226 149.345 380.685 147.915 380.685 146.025C380.685 144.123 381.239 142.643 382.345 141.586C383.452 140.53 384.776 140.001 386.318 140.001C387.86 140.001 389.302 140.393 390.645 141.176L389.917 143.153Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="408.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["21"].highlight ? highlightColor : null}
            />
            <rect
              x="435"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 435 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M436.106 43.5643H426.8V41.7552C426.911 41.6309 427.216 41.3138 427.713 40.8041C428.211 40.2943 428.584 39.8964 428.832 39.6104C429.094 39.312 429.411 38.939 429.784 38.4914C430.492 37.5962 430.847 36.8377 430.847 36.216C430.847 35.2835 430.387 34.8172 429.467 34.8172C428.932 34.8172 428.354 34.9478 427.732 35.2089L427.21 33.1573C427.732 32.8465 428.391 32.5791 429.187 32.3553C429.995 32.1191 430.698 32.001 431.294 32.001C431.904 32.001 432.457 32.0569 432.954 32.1688C433.464 32.2807 433.949 32.461 434.409 32.7097C434.881 32.9584 435.254 33.319 435.528 33.7914C435.802 34.2515 435.938 34.7923 435.938 35.414C435.926 37.2418 434.471 39.026 431.574 40.7668L431.686 41.0279L436.106 40.114V43.5643Z"
              fill="white"
            />
            <path
              d="M439.903 43.5643L438.766 34.6494L437.18 34.3323L437.031 32.2621H444.529L443.354 43.5643H439.903Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="408.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["20"].highlight ? highlightColor : null}
            />
            <rect
              x="435"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 435 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M434.294 97.5636H424.987V95.7544C425.099 95.6301 425.404 95.313 425.901 94.8033C426.399 94.2935 426.772 93.8956 427.02 93.6096C427.282 93.3112 427.599 92.9382 427.972 92.4906C428.68 91.5954 429.035 90.8369 429.035 90.2152C429.035 89.2827 428.575 88.8164 427.655 88.8164C427.12 88.8164 426.542 88.947 425.92 89.2081L425.398 87.1565C425.92 86.8457 426.579 86.5784 427.375 86.3545C428.183 86.1183 428.885 86.0002 429.482 86.0002C430.092 86.0002 430.645 86.0561 431.142 86.168C431.652 86.2799 432.137 86.4602 432.597 86.7089C433.069 86.9576 433.442 87.3182 433.716 87.7906C433.99 88.2507 434.126 88.7916 434.126 89.4132C434.114 91.241 432.659 93.0252 429.762 94.766L429.874 95.0271L434.294 94.1132V97.5636Z"
              fill="white"
            />
            <path
              d="M442.008 89.2454C441.834 88.6734 441.486 88.3875 440.963 88.3875C440.441 88.3875 440.087 88.6734 439.9 89.2454C439.726 89.8173 439.639 90.7001 439.639 91.8938C439.639 94.194 440.081 95.3441 440.963 95.3441C441.846 95.3441 442.288 94.194 442.288 91.8938C442.288 90.7001 442.194 89.8173 442.008 89.2454ZM445.029 96.2021C443.91 97.2341 442.555 97.7501 440.963 97.7501C439.372 97.7501 438.017 97.2341 436.898 96.2021C435.779 95.1576 435.219 93.734 435.219 91.9311C435.219 90.1282 435.735 88.6921 436.767 87.6228C437.799 86.541 439.198 86.0002 440.963 86.0002C442.729 86.0002 444.128 86.541 445.16 87.6228C446.192 88.6921 446.708 90.1282 446.708 91.9311C446.708 93.734 446.148 95.1576 445.029 96.2021Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="408.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["19"].highlight ? highlightColor : null}
            />
            <rect
              x="435"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 435 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M428.757 151.565L427.619 142.65L426.034 142.332L425.884 140.262H433.382L432.207 151.565H428.757Z"
              fill="white"
            />
            <path
              d="M438.174 143.992C438.174 144.328 438.286 144.602 438.509 144.813C438.733 145.012 439.013 145.111 439.349 145.111C439.684 145.111 439.995 145.037 440.281 144.888C440.58 144.738 440.791 144.527 440.915 144.253C440.915 143.93 440.772 143.638 440.486 143.377C440.2 143.103 439.858 142.967 439.46 142.967C439.075 142.967 438.764 143.054 438.528 143.228C438.292 143.402 438.174 143.657 438.174 143.992ZM436.215 148.599C437.023 148.922 437.801 149.084 438.547 149.084C439.305 149.084 439.927 148.866 440.412 148.431C440.897 147.996 441.139 147.455 441.139 146.809C441.089 146.883 441.015 146.976 440.915 147.088C440.816 147.2 440.555 147.349 440.132 147.536C439.722 147.723 439.255 147.816 438.733 147.816C437.465 147.816 436.439 147.48 435.656 146.809C434.885 146.137 434.499 145.223 434.499 144.067C434.499 142.898 434.953 141.928 435.861 141.157C436.781 140.387 438.062 140.001 439.703 140.001C441.357 140.001 442.724 140.486 443.806 141.456C444.9 142.413 445.447 143.843 445.447 145.746C445.447 147.635 444.894 149.109 443.787 150.166C442.681 151.223 441.357 151.751 439.815 151.751C438.273 151.751 436.831 151.359 435.488 150.576L436.215 148.599Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="462.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["24"].highlight ? highlightColor : null}
            />
            <rect
              x="489"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 489 58.2132)"
              fill="#42A8DF"
            />
            <path
              d="M488.895 43.5634H479.588V41.7543C479.7 41.6299 480.005 41.3129 480.502 40.8031C480.999 40.2933 481.372 39.8954 481.621 39.6094C481.882 39.311 482.199 38.938 482.572 38.4904C483.281 37.5952 483.635 36.8367 483.635 36.215C483.635 35.2825 483.175 34.8162 482.255 34.8162C481.721 34.8162 481.142 34.9468 480.521 35.2079L479.998 33.1563C480.521 32.8455 481.18 32.5782 481.975 32.3544C482.784 32.1181 483.486 32 484.083 32C484.692 32 485.245 32.056 485.743 32.1679C486.253 32.2798 486.738 32.46 487.198 32.7087C487.67 32.9574 488.043 33.318 488.317 33.7905C488.59 34.2505 488.727 34.7914 488.727 35.4131C488.714 37.2408 487.26 39.0251 484.363 40.7658L484.475 41.0269L488.895 40.113V43.5634Z"
              fill="white"
            />
            <path
              d="M492.617 32.2611H499.499L498.921 38.0614H500.115L499.779 40.7285H498.641L498.362 43.5634H495.117L494.837 40.7285H489.82L489.969 38.0614L492.617 32.2611ZM494.259 34.9468L492.804 38.0614H494.557L494.259 34.9468Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="462.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["23"].highlight ? highlightColor : null}
            />
            <rect
              x="489"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 489 112.213)"
              fill="#DD0F30"
            />
            <path
              d="M489.468 97.5645H480.161V95.7554C480.273 95.6311 480.578 95.314 481.075 94.8042C481.573 94.2945 481.946 93.8966 482.194 93.6106C482.456 93.3122 482.773 92.9392 483.146 92.4916C483.854 91.5963 484.209 90.8379 484.209 90.2162C484.209 89.2837 483.749 88.8174 482.829 88.8174C482.294 88.8174 481.716 88.948 481.094 89.2091L480.572 87.1575C481.094 86.8467 481.753 86.5793 482.549 86.3555C483.357 86.1193 484.059 86.0012 484.656 86.0012C485.266 86.0012 485.819 86.0571 486.316 86.169C486.826 86.2809 487.311 86.4612 487.771 86.7099C488.243 86.9586 488.616 87.3191 488.89 87.7916C489.164 88.2517 489.3 88.7925 489.3 89.4142C489.288 91.242 487.833 93.0262 484.936 94.7669L485.048 95.028L489.468 94.1142V97.5645Z"
              fill="white"
            />
            <path
              d="M494.739 93.4614C494.739 92.2802 493.825 91.6896 491.997 91.6896H491.661L493.247 88.8361L490.542 88.3325L491.139 86.2623H499.066L496.939 89.9178C497.002 89.9427 497.089 89.98 497.201 90.0297C497.312 90.067 497.505 90.1789 497.779 90.3654C498.052 90.5395 498.295 90.7508 498.506 90.9995C498.717 91.2358 498.91 91.5715 499.084 92.0067C499.258 92.4294 499.345 92.8957 499.345 93.4054C499.345 94.6488 498.86 95.687 497.891 96.5201C496.921 97.3407 495.752 97.751 494.384 97.751C493.688 97.751 493.004 97.6391 492.333 97.4153C491.674 97.1791 491.189 96.9428 490.878 96.7066L490.393 96.3709L491.288 94.5804C491.885 94.9161 492.457 95.084 493.004 95.084C493.551 95.084 493.974 94.9472 494.272 94.6737C494.583 94.4001 494.739 93.996 494.739 93.4614Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="462.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["22"].highlight ? highlightColor : null}
            />
            <rect
              x="489"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 489 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M489.204 151.564H479.898V149.754C480.01 149.63 480.314 149.313 480.812 148.803C481.309 148.293 481.682 147.896 481.931 147.61C482.192 147.311 482.509 146.938 482.882 146.491C483.59 145.595 483.945 144.837 483.945 144.215C483.945 143.283 483.485 142.816 482.565 142.816C482.03 142.816 481.452 142.947 480.83 143.208L480.308 141.157C480.83 140.846 481.489 140.578 482.285 140.355C483.093 140.118 483.796 140 484.392 140C485.002 140 485.555 140.056 486.052 140.168C486.562 140.28 487.047 140.46 487.507 140.709C487.98 140.958 488.353 141.318 488.626 141.791C488.9 142.251 489.036 142.792 489.036 143.413C489.024 145.241 487.569 147.025 484.672 148.766L484.784 149.027L489.204 148.113V151.564Z"
              fill="white"
            />
            <path
              d="M499.622 151.564H490.316V149.754C490.428 149.63 490.732 149.313 491.23 148.803C491.727 148.293 492.1 147.896 492.349 147.61C492.61 147.311 492.927 146.938 493.3 146.491C494.009 145.595 494.363 144.837 494.363 144.215C494.363 143.283 493.903 142.816 492.983 142.816C492.448 142.816 491.87 142.947 491.248 143.208L490.726 141.157C491.248 140.846 491.907 140.578 492.703 140.355C493.511 140.118 494.214 140 494.811 140C495.42 140 495.973 140.056 496.47 140.168C496.98 140.28 497.465 140.46 497.925 140.709C498.398 140.958 498.771 141.318 499.044 141.791C499.318 142.251 499.455 142.792 499.455 143.413C499.442 145.241 497.987 147.025 495.09 148.766L495.202 149.027L499.622 148.113V151.564Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="516.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["27"].highlight ? highlightColor : null}
            />
            <rect
              x="543"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 543 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M543.575 43.5643H534.269V41.7552C534.381 41.6309 534.685 41.3138 535.183 40.8041C535.68 40.2943 536.053 39.8964 536.302 39.6104C536.563 39.312 536.88 38.939 537.253 38.4914C537.962 37.5962 538.316 36.8377 538.316 36.216C538.316 35.2835 537.856 34.8172 536.936 34.8172C536.401 34.8172 535.823 34.9478 535.201 35.2089L534.679 33.1573C535.201 32.8465 535.86 32.5791 536.656 32.3553C537.464 32.1191 538.167 32.001 538.764 32.001C539.373 32.001 539.926 32.0569 540.423 32.1688C540.933 32.2807 541.418 32.461 541.878 32.7097C542.351 32.9584 542.724 33.319 542.997 33.7914C543.271 34.2515 543.408 34.7923 543.408 35.414C543.395 37.2418 541.94 39.026 539.043 40.7668L539.155 41.0279L543.575 40.114V43.5643Z"
              fill="white"
            />
            <path
              d="M552.949 32.2621V35.3767L548.809 43.5643H545.172L547.857 35.3394L544.388 35.899L544.202 32.2621H552.949Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="516.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["26"].highlight ? highlightColor : null}
            />
            <rect
              x="543"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 543 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M542.037 97.5636H532.73V95.7544C532.842 95.6301 533.146 95.313 533.644 94.8033C534.141 94.2935 534.514 93.8956 534.763 93.6096C535.024 93.3112 535.341 92.9382 535.714 92.4906C536.423 91.5954 536.777 90.8369 536.777 90.2152C536.777 89.2827 536.317 88.8164 535.397 88.8164C534.862 88.8164 534.284 88.947 533.662 89.2081L533.14 87.1565C533.662 86.8457 534.321 86.5784 535.117 86.3545C535.925 86.1183 536.628 86.0002 537.225 86.0002C537.834 86.0002 538.387 86.0561 538.885 86.168C539.394 86.2799 539.879 86.4602 540.339 86.7089C540.812 86.9576 541.185 87.3182 541.458 87.7906C541.732 88.2507 541.869 88.7916 541.869 89.4132C541.856 91.241 540.402 93.0252 537.504 94.766L537.616 95.0271L542.037 94.1132V97.5636Z"
              fill="white"
            />
            <path
              d="M550.422 93.7588C550.422 93.4231 550.31 93.1558 550.086 92.9569C549.862 92.7455 549.582 92.6398 549.247 92.6398C548.911 92.6398 548.594 92.7144 548.296 92.8636C548.01 93.0128 547.804 93.2242 547.68 93.4977C547.68 93.821 547.823 94.1194 548.109 94.393C548.395 94.6541 548.731 94.7846 549.116 94.7846C549.514 94.7846 549.831 94.6976 550.067 94.5235C550.304 94.3494 550.422 94.0945 550.422 93.7588ZM552.38 89.1521C551.572 88.8289 550.789 88.6672 550.03 88.6672C549.284 88.6672 548.669 88.8848 548.184 89.32C547.699 89.7552 547.456 90.296 547.456 90.9426C547.506 90.868 547.581 90.7747 547.68 90.6628C547.78 90.5509 548.034 90.4017 548.445 90.2152C548.868 90.0287 549.34 89.9355 549.862 89.9355C551.13 89.9355 552.15 90.2712 552.921 90.9426C553.704 91.614 554.096 92.5341 554.096 93.7029C554.096 94.8592 553.636 95.8228 552.716 96.5937C551.808 97.3646 550.527 97.7501 548.874 97.7501C547.233 97.7501 545.865 97.2714 544.771 96.314C543.689 95.3441 543.148 93.9143 543.148 92.0243C543.148 90.122 543.701 88.6424 544.808 87.5855C545.915 86.5286 547.239 86.0002 548.781 86.0002C550.322 86.0002 551.765 86.3918 553.107 87.1752L552.38 89.1521Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="516.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["25"].highlight ? highlightColor : null}
            />
            <rect
              x="543"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 543 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M543.065 151.565H533.759V149.755C533.871 149.631 534.175 149.314 534.673 148.804C535.17 148.294 535.543 147.897 535.792 147.611C536.053 147.312 536.37 146.939 536.743 146.492C537.451 145.596 537.806 144.838 537.806 144.216C537.806 143.284 537.346 142.817 536.426 142.817C535.891 142.817 535.313 142.948 534.691 143.209L534.169 141.157C534.691 140.847 535.35 140.579 536.146 140.356C536.954 140.119 537.657 140.001 538.253 140.001C538.863 140.001 539.416 140.057 539.913 140.169C540.423 140.281 540.908 140.461 541.368 140.71C541.841 140.959 542.214 141.319 542.487 141.792C542.761 142.252 542.897 142.793 542.897 143.414C542.885 145.242 541.43 147.026 538.533 148.767L538.645 149.028L543.065 148.114V151.565Z"
              fill="white"
            />
            <path
              d="M553.054 147.592C553.054 148.848 552.563 149.855 551.581 150.613C550.611 151.372 549.455 151.751 548.112 151.751C547.441 151.751 546.775 151.639 546.116 151.415C545.457 151.192 544.973 150.968 544.662 150.744L544.177 150.408L545.072 148.58C545.706 148.866 546.259 149.009 546.732 149.009C547.204 149.009 547.571 148.879 547.832 148.618C548.106 148.344 548.243 147.984 548.243 147.536C548.243 147.076 548.112 146.691 547.851 146.38C547.602 146.069 547.298 145.833 546.937 145.671C546.203 145.31 545.544 145.099 544.96 145.037L544.568 144.981L545.93 140.262H552.999L552.402 142.332L549.548 143.34V143.545C550.605 143.88 551.451 144.39 552.085 145.074C552.346 145.348 552.57 145.708 552.756 146.156C552.955 146.603 553.054 147.082 553.054 147.592Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="570.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["30"].highlight ? highlightColor : null}
            />
            <rect
              x="597"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 597 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M591.41 39.4612C591.41 38.28 590.496 37.6894 588.668 37.6894H588.333L589.918 34.8359L587.214 34.3323L587.81 32.2621H595.737L593.611 35.9176C593.673 35.9425 593.76 35.9798 593.872 36.0295C593.984 36.0668 594.177 36.1787 594.45 36.3652C594.724 36.5393 594.966 36.7507 595.177 36.9993C595.389 37.2356 595.582 37.5713 595.756 38.0065C595.93 38.4292 596.017 38.8955 596.017 39.4053C596.017 40.6486 595.532 41.6869 594.562 42.5199C593.592 43.3405 592.423 43.7509 591.056 43.7509C590.359 43.7509 589.676 43.6389 589.004 43.4151C588.345 43.1789 587.86 42.9427 587.549 42.7064L587.064 42.3707L587.96 40.5803C588.557 40.916 589.128 41.0838 589.676 41.0838C590.223 41.0838 590.645 40.947 590.944 40.6735C591.255 40.4 591.41 39.9959 591.41 39.4612Z"
              fill="white"
            />
            <path
              d="M603.743 35.2462C603.569 34.6742 603.221 34.3883 602.699 34.3883C602.177 34.3883 601.822 34.6742 601.636 35.2462C601.462 35.8181 601.375 36.7009 601.375 37.8946C601.375 40.1948 601.816 41.3449 602.699 41.3449C603.582 41.3449 604.023 40.1948 604.023 37.8946C604.023 36.7009 603.93 35.8181 603.743 35.2462ZM606.765 42.2029C605.646 43.2349 604.29 43.7509 602.699 43.7509C601.107 43.7509 599.752 43.2349 598.633 42.2029C597.514 41.1584 596.954 39.7348 596.954 37.9319C596.954 36.129 597.47 34.6929 598.502 33.6236C599.534 32.5418 600.933 32.001 602.699 32.001C604.464 32.001 605.863 32.5418 606.895 33.6236C607.927 34.6929 608.443 36.129 608.443 37.9319C608.443 39.7348 607.884 41.1584 606.765 42.2029Z"
              fill="white"
            />
            <rect
              x="570.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["29"].highlight ? highlightColor : null}
            />
            <rect
              x="597"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 597 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M596.476 97.5636H587.17V95.7544C587.282 95.6301 587.586 95.313 588.084 94.8033C588.581 94.2935 588.954 93.8956 589.203 93.6096C589.464 93.3112 589.781 92.9382 590.154 92.4906C590.862 91.5954 591.217 90.8369 591.217 90.2152C591.217 89.2827 590.757 88.8164 589.837 88.8164C589.302 88.8164 588.724 88.947 588.102 89.2081L587.58 87.1565C588.102 86.8457 588.761 86.5784 589.557 86.3545C590.365 86.1183 591.068 86.0002 591.664 86.0002C592.274 86.0002 592.827 86.0561 593.324 86.168C593.834 86.2799 594.319 86.4602 594.779 86.7089C595.252 86.9576 595.625 87.3182 595.898 87.7906C596.172 88.2507 596.308 88.7916 596.308 89.4132C596.296 91.241 594.841 93.0252 591.944 94.766L592.056 95.0271L596.476 94.1132V97.5636Z"
              fill="white"
            />
            <path
              d="M601.075 89.9914C601.075 90.3271 601.187 90.6007 601.411 90.812C601.635 91.011 601.915 91.1104 602.25 91.1104C602.586 91.1104 602.897 91.0358 603.183 90.8866C603.481 90.7374 603.693 90.5261 603.817 90.2525C603.817 89.9292 603.674 89.637 603.388 89.3759C603.102 89.1024 602.76 88.9656 602.362 88.9656C601.977 88.9656 601.666 89.0527 601.43 89.2267C601.194 89.4008 601.075 89.6557 601.075 89.9914ZM599.117 94.5981C599.925 94.9214 600.702 95.083 601.448 95.083C602.207 95.083 602.829 94.8654 603.314 94.4303C603.798 93.9951 604.041 93.4542 604.041 92.8077C603.991 92.8823 603.917 92.9755 603.817 93.0874C603.718 93.1993 603.457 93.3485 603.034 93.535C602.623 93.7215 602.157 93.8148 601.635 93.8148C600.367 93.8148 599.341 93.4791 598.558 92.8077C597.787 92.1362 597.401 91.2223 597.401 90.066C597.401 88.8972 597.855 87.9274 598.763 87.1565C599.683 86.3856 600.964 86.0002 602.605 86.0002C604.258 86.0002 605.626 86.4851 606.708 87.4549C607.802 88.4123 608.349 89.8422 608.349 91.7446C608.349 93.6345 607.796 95.1079 606.689 96.1648C605.583 97.2216 604.258 97.7501 602.717 97.7501C601.175 97.7501 599.733 97.3584 598.39 96.5751L599.117 94.5981Z"
              fill="white"
            />
            <rect
              x="570.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["28"].highlight ? highlightColor : null}
            />
            <rect
              x="597"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 597 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M596.904 151.564H587.598V149.754C587.71 149.63 588.014 149.313 588.512 148.803C589.009 148.293 589.382 147.896 589.631 147.61C589.892 147.311 590.209 146.938 590.582 146.491C591.291 145.595 591.645 144.837 591.645 144.215C591.645 143.283 591.185 142.816 590.265 142.816C589.73 142.816 589.152 142.947 588.53 143.208L588.008 141.157C588.53 140.846 589.189 140.578 589.985 140.355C590.793 140.118 591.496 140 592.093 140C592.702 140 593.255 140.056 593.752 140.168C594.262 140.28 594.747 140.46 595.207 140.709C595.68 140.958 596.053 141.318 596.326 141.791C596.6 142.251 596.737 142.792 596.737 143.413C596.724 145.241 595.269 147.025 592.372 148.766L592.484 149.027L596.904 148.113V151.564Z"
              fill="white"
            />
            <path
              d="M600.31 145.875C599.091 144.843 598.482 143.861 598.482 142.928C598.482 141.996 598.886 141.275 599.694 140.765C600.515 140.255 601.671 140 603.163 140C604.668 140 605.812 140.23 606.595 140.69C607.378 141.138 607.77 141.772 607.77 142.593C607.77 143.115 607.621 143.587 607.322 144.01C607.024 144.42 606.539 144.831 605.868 145.241C607.41 146.211 608.18 147.23 608.18 148.3C608.18 149.369 607.702 150.214 606.744 150.836C605.799 151.445 604.537 151.75 602.958 151.75C601.392 151.75 600.167 151.495 599.284 150.985C598.414 150.463 597.979 149.68 597.979 148.635C597.979 147.579 598.756 146.658 600.31 145.875ZM600.702 148.449C600.702 148.772 600.826 149.04 601.075 149.251C601.323 149.45 601.622 149.549 601.97 149.549C602.318 149.549 602.591 149.444 602.79 149.232C603.002 149.021 603.107 148.797 603.107 148.561C603.107 148.312 603.014 148.095 602.828 147.908C602.641 147.709 602.337 147.46 601.914 147.162C601.106 147.597 600.702 148.026 600.702 148.449ZM605.103 143.04C605.103 142.468 604.774 142.182 604.115 142.182C603.791 142.182 603.543 142.263 603.369 142.425C603.194 142.586 603.107 142.81 603.107 143.096C603.107 143.37 603.424 143.73 604.059 144.178C604.755 143.83 605.103 143.451 605.103 143.04Z"
              fill="white"
            />
            <rect
              x="624.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["33"].highlight ? highlightColor : null}
            />
            <rect
              x="651"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 651 58.2132)"
              fill="#42A8DF"
            />
            <path
              d="M646.585 38.4604C646.585 37.2792 645.671 36.6886 643.843 36.6886H643.507L645.093 33.835L642.388 33.3314L642.985 31.2612H650.912L648.785 34.9167C648.848 34.9416 648.935 34.9789 649.046 35.0287C649.158 35.066 649.351 35.1779 649.625 35.3644C649.898 35.5384 650.141 35.7498 650.352 35.9985C650.563 36.2347 650.756 36.5704 650.93 37.0056C651.104 37.4284 651.191 37.8946 651.191 38.4044C651.191 39.6478 650.706 40.686 649.737 41.5191C648.767 42.3397 647.598 42.75 646.23 42.75C645.534 42.75 644.85 42.6381 644.179 42.4143C643.52 42.178 643.035 41.9418 642.724 41.7056L642.239 41.3699L643.134 39.5794C643.731 39.9151 644.303 40.083 644.85 40.083C645.397 40.083 645.82 39.9462 646.118 39.6726C646.429 39.3991 646.585 38.995 646.585 38.4604Z"
              fill="white"
            />
            <path
              d="M656.475 38.4604C656.475 37.2792 655.561 36.6886 653.733 36.6886H653.397L654.982 33.835L652.278 33.3314L652.875 31.2612H660.801L658.675 34.9167C658.737 34.9416 658.824 34.9789 658.936 35.0287C659.048 35.066 659.241 35.1779 659.515 35.3644C659.788 35.5384 660.031 35.7498 660.242 35.9985C660.453 36.2347 660.646 36.5704 660.82 37.0056C660.994 37.4284 661.081 37.8946 661.081 38.4044C661.081 39.6478 660.596 40.686 659.626 41.5191C658.657 42.3397 657.488 42.75 656.12 42.75C655.424 42.75 654.74 42.6381 654.069 42.4143C653.41 42.178 652.925 41.9418 652.614 41.7056L652.129 41.3699L653.024 39.5794C653.621 39.9151 654.193 40.083 654.74 40.083C655.287 40.083 655.71 39.9462 656.008 39.6726C656.319 39.3991 656.475 38.995 656.475 38.4604Z"
              fill="white"
            />
            <rect
              x="624.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["32"].highlight ? highlightColor : null}
            />
            <rect
              x="651"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 651 112.213)"
              fill="#DD0F30"
            />
            <path
              d="M646.32 93.4614C646.32 92.2802 645.406 91.6896 643.578 91.6896H643.243L644.828 88.8361L642.124 88.3325L642.721 86.2623H650.647L648.521 89.9178C648.583 89.9427 648.67 89.98 648.782 90.0297C648.894 90.067 649.087 90.1789 649.36 90.3654C649.634 90.5395 649.876 90.7508 650.087 90.9995C650.299 91.2358 650.492 91.5715 650.666 92.0067C650.84 92.4294 650.927 92.8957 650.927 93.4054C650.927 94.6488 650.442 95.687 649.472 96.5201C648.502 97.3407 647.333 97.751 645.966 97.751C645.269 97.751 644.586 97.6391 643.914 97.4153C643.255 97.1791 642.77 96.9428 642.459 96.7066L641.974 96.3709L642.87 94.5804C643.467 94.9161 644.038 95.084 644.586 95.084C645.133 95.084 645.555 94.9472 645.854 94.6737C646.165 94.4001 646.32 93.996 646.32 93.4614Z"
              fill="white"
            />
            <path
              d="M661.358 97.5645H652.051V95.7554C652.163 95.6311 652.467 95.314 652.965 94.8042C653.462 94.2945 653.835 93.8966 654.084 93.6106C654.345 93.3122 654.662 92.9392 655.035 92.4916C655.744 91.5963 656.098 90.8379 656.098 90.2162C656.098 89.2837 655.638 88.8174 654.718 88.8174C654.183 88.8174 653.605 88.948 652.983 89.2091L652.461 87.1575C652.983 86.8467 653.642 86.5793 654.438 86.3555C655.246 86.1193 655.949 86.0012 656.546 86.0012C657.155 86.0012 657.708 86.0571 658.206 86.169C658.715 86.2809 659.2 86.4612 659.66 86.7099C660.133 86.9586 660.506 87.3191 660.779 87.7916C661.053 88.2517 661.19 88.7925 661.19 89.4142C661.177 91.242 659.723 93.0262 656.825 94.7669L656.937 95.028L661.358 94.1142V97.5645Z"
              fill="white"
            />
            <rect
              x="624.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["31"].highlight ? highlightColor : null}
            />
            <rect
              x="651"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 651 166.213)"
              fill="#42A8DF"
            />
            <path
              d="M647.222 146.461C647.222 145.279 646.308 144.689 644.48 144.689H644.145L645.73 141.835L643.026 141.332L643.622 139.261H651.549L649.423 142.917C649.485 142.942 649.572 142.979 649.684 143.029C649.796 143.066 649.988 143.178 650.262 143.365C650.536 143.539 650.778 143.75 650.989 143.999C651.201 144.235 651.394 144.571 651.568 145.006C651.742 145.429 651.829 145.895 651.829 146.405C651.829 147.648 651.344 148.686 650.374 149.519C649.404 150.34 648.235 150.75 646.868 150.75C646.171 150.75 645.487 150.638 644.816 150.414C644.157 150.178 643.672 149.942 643.361 149.706L642.876 149.37L643.772 147.58C644.368 147.915 644.94 148.083 645.487 148.083C646.035 148.083 646.457 147.946 646.756 147.673C647.067 147.399 647.222 146.995 647.222 146.461Z"
              fill="white"
            />
            <path
              d="M655.639 150.564L654.501 141.649L652.916 141.332L652.766 139.261H660.264L659.089 150.564H655.639Z"
              fill="white"
            />
            <rect
              x="678.5"
              y="10.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["36"].highlight ? highlightColor : null}
            />
            <rect
              x="705"
              y="58.2132"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 705 58.2132)"
              fill="#DD0F30"
            />
            <path
              d="M699.592 39.4612C699.592 38.28 698.678 37.6894 696.85 37.6894H696.515L698.1 34.8359L695.396 34.3323L695.992 32.2621H703.919L701.793 35.9176C701.855 35.9425 701.942 35.9798 702.054 36.0295C702.166 36.0668 702.359 36.1787 702.632 36.3652C702.906 36.5393 703.148 36.7507 703.359 36.9993C703.571 37.2356 703.764 37.5713 703.938 38.0065C704.112 38.4292 704.199 38.8955 704.199 39.4053C704.199 40.6486 703.714 41.6869 702.744 42.5199C701.774 43.3405 700.605 43.7509 699.238 43.7509C698.541 43.7509 697.858 43.6389 697.186 43.4151C696.527 43.1789 696.042 42.9427 695.731 42.7064L695.246 42.3707L696.142 40.5803C696.739 40.916 697.31 41.0838 697.858 41.0838C698.405 41.0838 698.827 40.947 699.126 40.6735C699.437 40.4 699.592 39.9959 699.592 39.4612Z"
              fill="white"
            />
            <path
              d="M712.597 39.7596C712.597 39.4239 712.485 39.1566 712.261 38.9576C712.037 38.7463 711.757 38.6406 711.422 38.6406C711.086 38.6406 710.769 38.7152 710.47 38.8644C710.184 39.0136 709.979 39.225 709.855 39.4985C709.855 39.8218 709.998 40.1202 710.284 40.3937C710.57 40.6549 710.906 40.7854 711.291 40.7854C711.689 40.7854 712.006 40.6984 712.242 40.5243C712.478 40.3502 712.597 40.0953 712.597 39.7596ZM714.555 35.1529C713.747 34.8296 712.963 34.668 712.205 34.668C711.459 34.668 710.843 34.8856 710.359 35.3208C709.874 35.756 709.631 36.2968 709.631 36.9434C709.681 36.8688 709.756 36.7755 709.855 36.6636C709.954 36.5517 710.209 36.4025 710.62 36.216C711.042 36.0295 711.515 35.9363 712.037 35.9363C713.305 35.9363 714.325 36.272 715.096 36.9434C715.879 37.6148 716.271 38.5349 716.271 39.7037C716.271 40.86 715.811 41.8236 714.891 42.5945C713.983 43.3654 712.702 43.7509 711.049 43.7509C709.407 43.7509 708.04 43.2722 706.945 42.3148C705.864 41.3449 705.323 39.915 705.323 38.0251C705.323 36.1228 705.876 34.6431 706.983 33.5863C708.089 32.5294 709.414 32.001 710.955 32.001C712.497 32.001 713.939 32.3926 715.282 33.176L714.555 35.1529Z"
              fill="white"
            />
            <rect
              x="678.5"
              y="64.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["35"].highlight ? highlightColor : null}
            />
            <rect
              x="705"
              y="112.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 705 112.213)"
              fill="#42A8DF"
            />
            <path
              d="M700.621 92.4605C700.621 91.2793 699.708 90.6887 697.88 90.6887H697.544L699.129 87.8352L696.425 87.3316L697.022 85.2614H704.948L702.822 88.9169C702.884 88.9418 702.971 88.9791 703.083 89.0288C703.195 89.0661 703.388 89.178 703.662 89.3645C703.935 89.5386 704.178 89.75 704.389 89.9987C704.6 90.2349 704.793 90.5706 704.967 91.0058C705.141 91.4285 705.228 91.8948 705.228 92.4046C705.228 93.648 704.743 94.6862 703.773 95.5192C702.804 96.3399 701.635 96.7502 700.267 96.7502C699.571 96.7502 698.887 96.6383 698.216 96.4145C697.557 96.1782 697.072 95.942 696.761 95.7057L696.276 95.37L697.171 93.5796C697.768 93.9153 698.34 94.0831 698.887 94.0831C699.434 94.0831 699.857 93.9464 700.155 93.6728C700.466 93.3993 700.621 92.9952 700.621 92.4605Z"
              fill="white"
            />
            <path
              d="M715.23 92.5911C715.23 93.8469 714.739 94.854 713.757 95.6125C712.787 96.371 711.63 96.7502 710.288 96.7502C709.616 96.7502 708.951 96.6383 708.292 96.4145C707.633 96.1907 707.148 95.9669 706.837 95.743L706.352 95.4073L707.248 93.5796C707.882 93.8656 708.435 94.0085 708.907 94.0085C709.38 94.0085 709.747 93.878 710.008 93.6169C710.281 93.3433 710.418 92.9828 710.418 92.5351C710.418 92.0751 710.288 91.6897 710.026 91.3788C709.778 91.068 709.473 90.8317 709.113 90.6701C708.379 90.3095 707.72 90.0981 707.136 90.036L706.744 89.98L708.105 85.2614H715.174L714.577 87.3316L711.724 88.3388V88.5439C712.781 88.8796 713.626 89.3894 714.26 90.0733C714.521 90.3468 714.745 90.7074 714.932 91.155C715.131 91.6026 715.23 92.0813 715.23 92.5911Z"
              fill="white"
            />
          </g>
          <g>
            <rect
              x="678.5"
              y="118.5"
              width="54"
              height="54"
              stroke="#465E71"
              fill={tableNumbers["34"].highlight ? highlightColor : null}
            />
            <rect
              x="705"
              y="166.213"
              width="30"
              height="30"
              rx="5"
              transform="rotate(-135 705 166.213)"
              fill="#DD0F30"
            />
            <path
              d="M700.011 146.462C700.011 145.28 699.097 144.69 697.269 144.69H696.934L698.519 141.836L695.815 141.333L696.411 139.262H704.338L702.212 142.918C702.274 142.943 702.361 142.98 702.473 143.03C702.585 143.067 702.777 143.179 703.051 143.366C703.325 143.54 703.567 143.751 703.778 144C703.99 144.236 704.182 144.572 704.357 145.007C704.531 145.43 704.618 145.896 704.618 146.406C704.618 147.649 704.133 148.687 703.163 149.52C702.193 150.341 701.024 150.751 699.657 150.751C698.96 150.751 698.276 150.639 697.605 150.415C696.946 150.179 696.461 149.943 696.15 149.707L695.665 149.371L696.561 147.581C697.157 147.916 697.729 148.084 698.276 148.084C698.824 148.084 699.246 147.947 699.545 147.674C699.856 147.4 700.011 146.996 700.011 146.462Z"
              fill="white"
            />
            <path
              d="M708.353 139.262H715.235L714.657 145.063H715.85L715.515 147.73H714.377L714.097 150.565H710.852L710.572 147.73H705.555L705.704 145.063L708.353 139.262ZM709.994 141.948L708.539 145.063H710.293L709.994 141.948Z"
              fill="white"
            />
          </g>
          <path
            d="M732.5 10.5H772C780.008 10.5 786.5 16.9919 786.5 25V64.5H732.5V10.5Z"
            stroke="#465E71"
          />
          <path
            d="M756.633 44.4542H745V42.1928C745.14 42.0374 745.521 41.6411 746.142 41.0039C746.764 40.3666 747.23 39.8693 747.541 39.5118C747.868 39.1388 748.264 38.6725 748.73 38.113C749.616 36.994 750.059 36.0459 750.059 35.2688C750.059 34.1031 749.484 33.5203 748.334 33.5203C747.665 33.5203 746.943 33.6835 746.166 34.0099L745.513 31.4454C746.166 31.0569 746.989 30.7227 747.984 30.443C748.994 30.1477 749.872 30 750.618 30C751.38 30 752.072 30.0699 752.693 30.2098C753.331 30.3497 753.937 30.5751 754.512 30.8859C755.102 31.1967 755.569 31.6475 755.911 32.2381C756.253 32.8131 756.423 33.4892 756.423 34.2663C756.408 36.551 754.59 38.7813 750.968 40.9572L751.108 41.2836L756.633 40.1413V44.4542Z"
            fill="white"
          />
          <path
            d="M758.023 38.6958V36.4111H763.968V38.6958H758.023Z"
            fill="white"
          />
          <path
            d="M768.733 44.4542L767.311 33.3105L765.33 32.9142L765.143 30.3264L774.515 30.3264L773.046 44.4542H768.733Z"
            fill="white"
          />
          <rect x="732.5" y="64.5" width="54" height="54" stroke="#465E71" />
          <path
            d="M756.633 98.4542H745V96.1928C745.14 96.0374 745.521 95.6411 746.142 95.0039C746.764 94.3666 747.23 93.8693 747.541 93.5118C747.868 93.1388 748.264 92.6725 748.73 92.113C749.616 90.994 750.059 90.0459 750.059 89.2688C750.059 88.1031 749.484 87.5203 748.334 87.5203C747.665 87.5203 746.943 87.6835 746.166 88.0099L745.513 85.4454C746.166 85.0569 746.989 84.7227 747.984 84.443C748.994 84.1477 749.872 84 750.618 84C751.38 84 752.072 84.0699 752.693 84.2098C753.331 84.3497 753.937 84.5751 754.512 84.8859C755.102 85.1967 755.569 85.6475 755.911 86.2381C756.253 86.8131 756.423 87.4892 756.423 88.2663C756.408 90.551 754.59 92.7813 750.968 94.9572L751.108 95.2836L756.633 94.1413V98.4542Z"
            fill="white"
          />
          <path
            d="M758.023 92.6958V90.4111H763.968V92.6958H758.023Z"
            fill="white"
          />
          <path
            d="M768.733 98.4542L767.311 87.3105L765.33 86.9142L765.143 84.3264H774.515L773.046 98.4542H768.733Z"
            fill="white"
          />
          <path
            d="M732.5 118.5H786.5V158C786.5 166.008 780.008 172.5 772 172.5H732.5V118.5Z"
            stroke="#465E71"
          />
          <path
            d="M756.633 152.454H745V150.193C745.14 150.037 745.521 149.641 746.142 149.004C746.764 148.367 747.23 147.869 747.541 147.512C747.868 147.139 748.264 146.673 748.73 146.113C749.616 144.994 750.059 144.046 750.059 143.269C750.059 142.103 749.484 141.52 748.334 141.52C747.665 141.52 746.943 141.683 746.166 142.01L745.513 139.445C746.166 139.057 746.989 138.723 747.984 138.443C748.994 138.148 749.872 138 750.618 138C751.38 138 752.072 138.07 752.693 138.21C753.331 138.35 753.937 138.575 754.512 138.886C755.102 139.197 755.569 139.647 755.911 140.238C756.253 140.813 756.423 141.489 756.423 142.266C756.408 144.551 754.59 146.781 750.968 148.957L751.108 149.284L756.633 148.141V152.454Z"
            fill="white"
          />
          <path
            d="M758.023 146.696V144.411H763.968V146.696H758.023Z"
            fill="white"
          />
          <path
            d="M768.733 152.454L767.311 141.31L765.33 140.914L765.143 138.326H774.515L773.046 152.454H768.733Z"
            fill="white"
          />
          <rect x="300.5" y="256.5" width="108" height="84" stroke="#465E71" />
          <rect
            x="326"
            y="298"
            width="40"
            height="40"
            rx="5"
            transform="rotate(-45 326 298)"
            fill="#DD0F30"
          />
          <rect x="408.5" y="256.5" width="108" height="84" stroke="#465E71" />
          <rect
            x="434"
            y="298"
            width="40"
            height="40"
            rx="5"
            transform="rotate(-45 434 298)"
            fill="#42A8DF"
          />
          <g>
            <path
              fill={tableNumbers["0"].highlight ? highlightColor : null}
              d="M84.5 10.5L84.5 172.5L15 172.5C6.99188 172.5 0.500001 166.008 0.500001 158L0.500007 25C0.500007 16.9919 6.99188 10.5 15 10.5L84.5 10.5Z"
              stroke="#465E71"
            />
            <path
              d="M44.5776 86.4904C44.2295 85.3465 43.5332 84.7746 42.4888 84.7746C41.4443 84.7746 40.7356 85.3465 40.3626 86.4904C40.0145 87.6343 39.8404 89.3999 39.8404 91.7872C39.8404 96.3877 40.7232 98.6879 42.4888 98.6879C44.2544 98.6879 45.1372 96.3877 45.1372 91.7872C45.1372 89.3999 44.9506 87.6343 44.5776 86.4904ZM50.6204 100.404C48.3824 102.468 45.6718 103.5 42.4888 103.5C39.3057 103.5 36.5952 102.468 34.3571 100.404C32.119 98.3149 31 95.4676 31 91.8618C31 88.256 32.032 85.3838 34.096 83.2452C36.16 81.0817 38.9576 80 42.4888 80C46.0199 80 48.8175 81.0817 50.8815 83.2452C52.9455 85.3838 53.9775 88.256 53.9775 91.8618C53.9775 95.4676 52.8585 98.3149 50.6204 100.404Z"
              fill="white"
            />
          </g>
          <path
            d="M84.5 256.5H192.5V340.5H99C90.9919 340.5 84.5 334.008 84.5 326V256.5Z"
            stroke="#465E71"
          />
          <path
            d="M117.308 307.345L115.602 293.973L113.224 293.497L113 290.392L124.246 290.392L122.484 307.345L117.308 307.345Z"
            fill="white"
          />
          <path
            d="M126.202 300.435L126.202 297.693L133.336 297.693L133.336 300.435L126.202 300.435Z"
            fill="white"
          />
          <path
            d="M139.055 307.345L137.349 293.973L134.971 293.497L134.747 290.392L145.993 290.392L144.231 307.345L139.055 307.345Z"
            fill="white"
          />
          <path
            d="M151.39 298.812C149.562 297.264 148.648 295.791 148.648 294.392C148.648 292.993 149.255 291.912 150.467 291.147C151.698 290.382 153.432 290 155.67 290C157.927 290 159.643 290.345 160.818 291.035C161.993 291.707 162.58 292.658 162.58 293.889C162.58 294.672 162.357 295.381 161.909 296.015C161.461 296.63 160.734 297.246 159.727 297.861C162.04 299.316 163.196 300.845 163.196 302.449C163.196 304.053 162.478 305.321 161.042 306.254C159.624 307.168 157.731 307.625 155.363 307.625C153.013 307.625 151.176 307.242 149.851 306.478C148.546 305.694 147.893 304.519 147.893 302.953C147.893 301.368 149.059 299.987 151.39 298.812ZM151.978 302.673C151.978 303.158 152.164 303.559 152.537 303.876C152.91 304.174 153.358 304.324 153.88 304.324C154.402 304.324 154.813 304.165 155.111 303.848C155.428 303.531 155.587 303.195 155.587 302.841C155.587 302.468 155.447 302.142 155.167 301.862C154.887 301.563 154.43 301.19 153.796 300.743C152.584 301.396 151.978 302.039 151.978 302.673ZM158.58 294.56C158.58 293.702 158.086 293.273 157.097 293.273C156.612 293.273 156.239 293.394 155.978 293.637C155.717 293.879 155.587 294.215 155.587 294.644C155.587 295.054 156.062 295.595 157.013 296.267C158.058 295.744 158.58 295.176 158.58 294.56Z"
            fill="white"
          />
          <rect x="192.5" y="256.5" width="108" height="84" stroke="#465E71" />
          <path
            d="M218.945 300.435C218.945 301.573 219.132 302.347 219.505 302.757C219.878 303.149 220.344 303.345 220.903 303.345C221.78 303.345 222.666 303.195 223.561 302.897L223.981 302.757L225.184 305.163C225.016 305.312 224.764 305.508 224.428 305.75C224.093 305.974 223.337 306.282 222.162 306.674C221.006 307.047 219.803 307.233 218.553 307.233C217.323 307.233 216.287 307.056 215.448 306.702C214.609 306.347 213.965 305.937 213.518 305.471C213.07 304.986 212.707 304.352 212.427 303.568C212.147 302.766 211.961 302.086 211.867 301.526C211.793 300.967 211.727 300.267 211.671 299.428L211 290L223.589 290L224.372 293.357L219.617 293.777L219.337 296.49L223.086 296.49L223.645 299.12L218.945 300.295L218.945 300.435Z"
            fill="white"
          />
          <path
            d="M239.102 290L244.138 290C244.138 292.201 243.924 294.29 243.495 296.267C243.084 298.244 242.581 299.857 241.984 301.106C241.387 302.356 240.781 303.447 240.166 304.38C239.569 305.293 239.056 305.946 238.627 306.338L238.011 306.953L230.822 306.953L225.59 290L234.458 290L236.053 301.023C237.359 299.4 238.263 297.097 238.767 294.112C238.991 292.807 239.102 291.436 239.102 290Z"
            fill="white"
          />
          <path
            d="M254.052 300.435C254.052 301.573 254.238 302.347 254.611 302.757C254.984 303.149 255.45 303.345 256.01 303.345C256.887 303.345 257.772 303.195 258.668 302.897L259.087 302.757L260.29 305.163C260.122 305.312 259.871 305.508 259.535 305.75C259.199 305.974 258.444 306.282 257.269 306.674C256.113 307.047 254.91 307.233 253.66 307.233C252.429 307.233 251.394 307.056 250.555 306.702C249.715 306.347 249.072 305.937 248.624 305.471C248.177 304.986 247.813 304.352 247.533 303.568C247.253 302.766 247.067 302.086 246.974 301.526C246.899 300.967 246.834 300.267 246.778 299.428L246.106 290L258.696 290L259.479 293.357L254.723 293.777L254.443 296.49L258.192 296.49L258.752 299.12L254.052 300.295L254.052 300.435Z"
            fill="white"
          />
          <path
            d="M271.048 290L275.999 299.875L275.216 290L281.678 290L280.28 306.953L272.083 306.953L267.383 297.721L266.46 306.953L262.795 306.953L261.396 290L271.048 290Z"
            fill="white"
          />
          <rect x="516.5" y="256.5" width="108" height="84" stroke="#465E71" />
          <path
            d="M541 298.98C541 296.052 541.849 293.823 543.546 292.294C545.262 290.765 547.705 290 550.875 290C554.065 290 556.508 290.765 558.205 292.294C559.921 293.823 560.779 296.052 560.779 298.98C560.779 301.908 559.874 304.081 558.065 305.499C556.275 306.916 553.878 307.625 550.875 307.625C547.891 307.625 545.495 306.916 543.686 305.499C541.895 304.081 541 301.908 541 298.98ZM553.337 298.868C553.337 295.101 552.517 293.217 550.875 293.217C549.253 293.217 548.442 295.101 548.442 298.868C548.442 302.636 549.253 304.519 550.875 304.519C552.517 304.519 553.337 302.636 553.337 298.868Z"
            fill="white"
          />
          <path
            d="M569.314 304.519C570.6 304.519 571.598 303.839 572.307 302.477C573.016 301.116 573.37 299.689 573.37 298.197C573.37 296.686 573.1 295.483 572.559 294.588C572.018 293.693 571.319 293.245 570.461 293.245L570.293 293.245L569.146 304.519L569.314 304.519ZM569.202 307.345L563.718 307.345L562.04 290.392L570.293 290.392C574.452 290.392 577.315 291.529 578.881 293.805C579.702 295.017 580.112 296.453 580.112 298.113C580.112 304.268 576.475 307.345 569.202 307.345Z"
            fill="white"
          />
          <path
            d="M588.656 304.519C589.943 304.519 590.941 303.839 591.65 302.477C592.358 301.116 592.713 299.689 592.713 298.197C592.713 296.686 592.442 295.483 591.901 294.588C591.361 293.693 590.661 293.245 589.803 293.245L589.635 293.245L588.488 304.519L588.656 304.519ZM588.544 307.345L583.061 307.345L581.383 290.392L589.635 290.392C593.795 290.392 596.657 291.529 598.224 293.805C599.045 295.017 599.455 296.453 599.455 298.113C599.455 304.268 595.818 307.345 588.544 307.345Z"
            fill="white"
          />
          <path
            d="M624.5 256.5H732.5V326C732.5 334.008 726.008 340.5 718 340.5H624.5V256.5Z"
            stroke="#465E71"
          />
          <path
            d="M647.308 307.345L645.602 293.973L643.224 293.497L643 290.392L654.246 290.392L652.484 307.345L647.308 307.345Z"
            fill="white"
          />
          <path
            d="M661.434 295.987C661.434 296.49 661.602 296.901 661.937 297.218C662.273 297.516 662.693 297.665 663.196 297.665C663.7 297.665 664.166 297.553 664.595 297.33C665.043 297.106 665.36 296.789 665.546 296.379C665.546 295.894 665.332 295.455 664.903 295.064C664.474 294.653 663.961 294.448 663.364 294.448C662.786 294.448 662.32 294.579 661.965 294.84C661.611 295.101 661.434 295.483 661.434 295.987ZM658.496 302.897C659.709 303.382 660.874 303.624 661.993 303.624C663.131 303.624 664.063 303.298 664.791 302.645C665.518 301.992 665.882 301.181 665.882 300.211C665.807 300.323 665.695 300.463 665.546 300.631C665.397 300.799 665.005 301.023 664.371 301.302C663.756 301.582 663.056 301.722 662.273 301.722C660.371 301.722 658.832 301.218 657.657 300.211C656.501 299.204 655.922 297.833 655.922 296.099C655.922 294.346 656.603 292.891 657.965 291.735C659.345 290.578 661.266 290 663.728 290C666.208 290 668.26 290.727 669.882 292.182C671.524 293.618 672.344 295.763 672.344 298.617C672.344 301.451 671.514 303.662 669.854 305.247C668.195 306.832 666.208 307.625 663.896 307.625C661.583 307.625 659.419 307.037 657.405 305.862L658.496 302.897Z"
            fill="white"
          />
          <path
            d="M674.015 300.435L674.015 297.693L681.149 297.693L681.149 300.435L674.015 300.435Z"
            fill="white"
          />
          <path
            d="M689.078 301.19C689.078 299.419 687.707 298.533 684.966 298.533L684.462 298.533L686.84 294.252L682.783 293.497L683.679 290.392L695.568 290.392L692.379 295.875C692.472 295.912 692.603 295.968 692.771 296.043C692.939 296.099 693.228 296.267 693.638 296.546C694.048 296.807 694.412 297.125 694.729 297.498C695.046 297.852 695.335 298.355 695.596 299.008C695.858 299.642 695.988 300.342 695.988 301.106C695.988 302.971 695.261 304.529 693.806 305.778C692.351 307.009 690.598 307.625 688.546 307.625C687.502 307.625 686.476 307.457 685.469 307.121C684.481 306.767 683.753 306.413 683.287 306.058L682.56 305.555L683.902 302.869C684.798 303.372 685.656 303.624 686.476 303.624C687.297 303.624 687.931 303.419 688.379 303.009C688.845 302.598 689.078 301.992 689.078 301.19Z"
            fill="white"
          />
          <path
            d="M708.585 301.638C708.585 301.134 708.417 300.733 708.081 300.435C707.746 300.118 707.326 299.959 706.822 299.959C706.319 299.959 705.843 300.071 705.396 300.295C704.967 300.519 704.659 300.836 704.472 301.246C704.472 301.731 704.687 302.179 705.116 302.589C705.545 302.981 706.048 303.177 706.627 303.177C707.223 303.177 707.699 303.046 708.053 302.785C708.408 302.524 708.585 302.142 708.585 301.638ZM711.522 294.728C710.31 294.243 709.135 294.001 707.997 294.001C706.878 294.001 705.955 294.327 705.228 294.98C704.5 295.632 704.137 296.444 704.137 297.414C704.211 297.302 704.323 297.162 704.472 296.994C704.622 296.826 705.004 296.602 705.619 296.323C706.254 296.043 706.962 295.903 707.746 295.903C709.648 295.903 711.177 296.406 712.334 297.414C713.509 298.421 714.096 299.801 714.096 301.554C714.096 303.289 713.406 304.734 712.026 305.89C710.664 307.047 708.743 307.625 706.263 307.625C703.801 307.625 701.749 306.907 700.108 305.471C698.486 304.016 697.674 301.871 697.674 299.036C697.674 296.183 698.504 293.963 700.164 292.378C701.824 290.793 703.81 290 706.123 290C708.436 290 710.599 290.588 712.613 291.762L711.522 294.728Z"
            fill="white"
          />
          <rect x="300.5" y="172.5" width="216" height="84" stroke="#465E71" />
          <path
            d="M383.613 226.127L365 226.127L365 222.509C365.224 222.26 365.833 221.626 366.828 220.606C367.822 219.587 368.568 218.791 369.066 218.219C369.588 217.622 370.222 216.876 370.968 215.981C372.386 214.19 373.094 212.673 373.094 211.43C373.094 209.565 372.174 208.632 370.334 208.632C369.265 208.632 368.108 208.894 366.865 209.416L365.821 205.313C366.865 204.691 368.183 204.156 369.775 203.709C371.391 203.236 372.796 203 373.99 203C375.208 203 376.315 203.112 377.309 203.336C378.329 203.56 379.299 203.92 380.219 204.417C381.164 204.915 381.91 205.636 382.457 206.581C383.004 207.501 383.278 208.583 383.278 209.826C383.253 213.482 380.343 217.05 374.549 220.532L374.773 221.054L383.613 219.226L383.613 226.127Z"
            fill="white"
          />
          <path
            d="M392.768 212.941L396.619 220.622L396.01 212.941L401.036 212.941L399.948 226.127L393.573 226.127L389.918 218.946L389.2 226.127L386.349 226.127L385.261 212.941L392.768 212.941Z"
            fill="white"
          />
          <path
            d="M407.79 223.929C408.791 223.929 409.567 223.4 410.118 222.341C410.67 221.282 410.945 220.172 410.945 219.012C410.945 217.837 410.735 216.901 410.314 216.205C409.894 215.508 409.35 215.16 408.682 215.16L408.552 215.16L407.66 223.929L407.79 223.929ZM407.703 226.127L403.438 226.127L402.133 212.941L408.552 212.941C411.787 212.941 414.013 213.826 415.232 215.595C415.87 216.538 416.189 217.655 416.189 218.946C416.189 223.733 413.361 226.127 407.703 226.127Z"
            fill="white"
          />
          <path
            d="M423.124 226.127L420.848 208.297L417.678 207.663L417.379 203.522L432.374 203.522L430.024 226.127L423.124 226.127Z"
            fill="white"
          />
          <path
            d="M453.596 226.127L434.982 226.127L434.982 222.509C435.206 222.26 435.815 221.626 436.81 220.606C437.805 219.587 438.551 218.791 439.048 218.219C439.57 217.622 440.204 216.876 440.95 215.981C442.368 214.19 443.077 212.673 443.077 211.43C443.077 209.565 442.156 208.632 440.316 208.632C439.247 208.632 438.091 208.894 436.847 209.416L435.803 205.313C436.847 204.691 438.165 204.156 439.757 203.709C441.373 203.236 442.778 203 443.972 203C445.19 203 446.297 203.112 447.292 203.336C448.311 203.56 449.281 203.92 450.201 204.417C451.146 204.915 451.892 205.636 452.439 206.581C452.986 207.501 453.26 208.583 453.26 209.826C453.235 213.482 450.325 217.05 444.531 220.532L444.755 221.054L453.596 219.226L453.596 226.127Z"
            fill="white"
          />
          <rect x="84.5" y="172.5" width="216" height="84" stroke="#465E71" />
          <path
            d="M159.744 226.127L157.469 208.297L154.298 207.663L154 203.522L168.995 203.522L166.645 226.127L159.744 226.127Z"
            fill="white"
          />
          <path
            d="M180.254 218.489C180.732 218.779 181.131 219.193 181.45 219.73C181.784 220.266 181.951 220.984 181.951 221.884C181.951 222.769 181.668 223.574 181.102 224.299C180.058 225.663 178.469 226.344 176.337 226.344C175.409 226.344 174.488 226.214 173.574 225.953C172.674 225.677 172.007 225.401 171.572 225.126L170.919 224.734L171.855 222.863C172.899 223.371 173.842 223.624 174.683 223.624C175.525 223.624 175.945 223.356 175.945 222.819C175.945 222.515 175.779 222.232 175.445 221.971C175.126 221.695 174.727 221.434 174.248 221.187C173.784 220.941 173.313 220.658 172.834 220.339C172.37 220.005 171.971 219.548 171.637 218.968C171.318 218.373 171.158 217.684 171.158 216.901C171.158 215.508 171.688 214.449 172.747 213.724C173.82 212.999 175.111 212.636 176.62 212.636C177.519 212.636 178.361 212.745 179.144 212.963C179.942 213.18 180.508 213.398 180.841 213.615L181.363 213.942L180.515 215.791C179.775 215.472 179.028 215.313 178.274 215.313C177.534 215.313 177.164 215.595 177.164 216.161C177.164 216.466 177.324 216.741 177.643 216.988C177.976 217.235 178.375 217.474 178.839 217.706C179.318 217.924 179.79 218.185 180.254 218.489Z"
            fill="white"
          />
          <path
            d="M195.284 212.941L195.719 217.075L192.216 216.379L191.237 226.127L186.994 226.127L186.015 216.379L182.49 217.075L182.925 212.941L195.284 212.941Z"
            fill="white"
          />
          <path
            d="M202.218 226.127L199.943 208.297L196.772 207.663L196.474 203.522L211.469 203.522L209.119 226.127L202.218 226.127Z"
            fill="white"
          />
          <path
            d="M232.69 226.127L214.077 226.127L214.077 222.509C214.301 222.26 214.91 221.626 215.905 220.606C216.899 219.587 217.645 218.791 218.143 218.219C218.665 217.622 219.299 216.876 220.045 215.981C221.462 214.19 222.171 212.673 222.171 211.43C222.171 209.565 221.251 208.632 219.411 208.632C218.342 208.632 217.185 208.894 215.942 209.416L214.897 205.313C215.942 204.691 217.26 204.156 218.851 203.709C220.468 203.236 221.873 203 223.066 203C224.285 203 225.391 203.112 226.386 203.336C227.406 203.56 228.376 203.92 229.296 204.417C230.241 204.915 230.987 205.636 231.534 206.581C232.081 207.501 232.354 208.583 232.354 209.826C232.329 213.482 229.42 217.05 223.626 220.532L223.85 221.054L232.69 219.226L232.69 226.127Z"
            fill="white"
          />
          <rect x="516.5" y="172.5" width="216" height="84" stroke="#465E71" />
          <path
            d="M590.691 217.92C590.691 215.558 588.863 214.377 585.208 214.377L584.536 214.377L587.707 208.67L582.298 207.663L583.492 203.522L599.345 203.522L595.093 210.833C595.217 210.883 595.391 210.958 595.615 211.057C595.839 211.132 596.224 211.355 596.771 211.728C597.318 212.077 597.803 212.499 598.226 212.997C598.649 213.469 599.034 214.141 599.382 215.011C599.73 215.856 599.905 216.789 599.905 217.809C599.905 220.295 598.935 222.372 596.995 224.038C595.055 225.679 592.718 226.5 589.982 226.5C588.59 226.5 587.222 226.276 585.879 225.828C584.561 225.356 583.592 224.883 582.97 224.411L582 223.739L583.79 220.159C584.984 220.83 586.128 221.166 587.222 221.166C588.316 221.166 589.162 220.892 589.759 220.345C590.38 219.798 590.691 218.99 590.691 217.92Z"
            fill="white"
          />
          <path
            d="M608.824 219.533C609.491 219.533 610.021 219.33 610.412 218.924C610.804 218.518 611 218.025 611 217.445C611 216.85 610.811 216.407 610.434 216.117C610.071 215.813 609.6 215.66 609.02 215.66L608.149 215.66L607.779 219.533L608.824 219.533ZM611.283 212.94C612.835 212.94 614.075 213.31 615.004 214.05C615.947 214.775 616.418 215.856 616.418 217.292C616.418 217.814 616.309 218.315 616.092 218.794C615.874 219.258 615.613 219.635 615.308 219.925C614.67 220.505 614.083 220.919 613.546 221.165L613.219 221.296L615.569 226.127L611.761 226.127L607.54 221.884L607.127 226.127L602.883 226.127L601.578 212.94L611.283 212.94Z"
            fill="white"
          />
          <path
            d="M622.833 223.929C623.834 223.929 624.61 223.399 625.161 222.341C625.712 221.282 625.988 220.172 625.988 219.011C625.988 217.836 625.778 216.901 625.357 216.204C624.936 215.508 624.392 215.16 623.725 215.16L623.594 215.16L622.702 223.929L622.833 223.929ZM622.746 226.127L618.481 226.127L617.175 212.94L623.594 212.94C626.829 212.94 629.056 213.825 630.275 215.595C630.913 216.538 631.232 217.655 631.232 218.946C631.232 223.733 628.403 226.127 622.746 226.127Z"
            fill="white"
          />
          <path
            d="M638.167 226.127L635.891 208.297L632.721 207.663L632.422 203.522L647.417 203.522L645.067 226.127L638.167 226.127Z"
            fill="white"
          />
          <path
            d="M668.639 226.127L650.025 226.127L650.025 222.509C650.249 222.26 650.858 221.626 651.853 220.606C652.848 219.587 653.594 218.791 654.091 218.219C654.613 217.622 655.247 216.876 655.993 215.981C657.411 214.19 658.12 212.673 658.12 211.43C658.12 209.565 657.2 208.632 655.359 208.632C654.29 208.632 653.134 208.894 651.89 209.416L650.846 205.313C651.89 204.691 653.208 204.156 654.8 203.709C656.416 203.236 657.821 203 659.015 203C660.233 203 661.34 203.112 662.335 203.336C663.354 203.56 664.324 203.92 665.244 204.417C666.189 204.915 666.935 205.636 667.482 206.581C668.029 207.501 668.303 208.583 668.303 209.826C668.278 213.482 665.369 217.05 659.574 220.532L659.798 221.054L668.639 219.226L668.639 226.127Z"
            fill="white"
          />
        </g>
        <g className="table__chips">
          {tableNumbers["3"].chip && (
            <g
              className={
                tableNumbers["3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="37.8725">
                  {tableNumbers["3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,1,2,3"].chip && (
            <g
              className={
                combinations["0,1,2,3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="11.3725">
                  {combinations["0,1,2,3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,1,2,3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["1,2,3,4,5,6"].chip && (
            <g
              className={
                combinations["1,2,3,4,5,6"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="11.3725">
                  {combinations["1,2,3,4,5,6"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["1,2,3,4,5,6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["4,5,6,7,8,9"].chip && (
            <g
              className={
                combinations["4,5,6,7,8,9"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="11.3725">
                  {combinations["4,5,6,7,8,9"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["4,5,6,7,8,9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["7,8,9,10,11,12"].chip && (
            <g
              className={
                combinations["7,8,9,10,11,12"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="11.3725">
                  {combinations["7,8,9,10,11,12"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["7,8,9,10,11,12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["10,11,12,13,14,15"].chip && (
            <g
              className={
                combinations["10,11,12,13,14,15"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="11.3725">
                  {combinations["10,11,12,13,14,15"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["10,11,12,13,14,15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["13,14,15,16,17,18"].chip && (
            <g
              className={
                combinations["13,14,15,16,17,18"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="11.3725">
                  {combinations["13,14,15,16,17,18"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["13,14,15,16,17,18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["16,17,18,19,20,21"].chip && (
            <g
              className={
                combinations["16,17,18,19,20,21"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="11.3725">
                  {combinations["16,17,18,19,20,21"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["16,17,18,19,20,21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["19,20,21,22,23,24"].chip && (
            <g
              className={
                combinations["19,20,21,22,23,24"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="11.3725">
                  {combinations["19,20,21,22,23,24"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["19,20,21,22,23,24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["22,23,24,25,26,27"].chip && (
            <g
              className={
                combinations["22,23,24,25,26,27"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="11.3725">
                  {combinations["22,23,24,25,26,27"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["22,23,24,25,26,27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["25,26,27,28,29,30"].chip && (
            <g
              className={
                combinations["25,26,27,28,29,30"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="11.3725">
                  {combinations["25,26,27,28,29,30"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["25,26,27,28,29,30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["28,29,30,31,32,33"].chip && (
            <g
              className={
                combinations["28,29,30,31,32,33"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="11.3725">
                  {combinations["28,29,30,31,32,33"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["28,29,30,31,32,33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["31,32,33,34,35,36"].chip && (
            <g
              className={
                combinations["31,32,33,34,35,36"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="11.3725">
                  {combinations["31,32,33,34,35,36"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["31,32,33,34,35,36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["1,2,3"].chip && (
            <g
              className={
                combinations["1,2,3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="11.3725">
                  {combinations["1,2,3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["1,2,3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["4,5,6"].chip && (
            <g
              className={
                combinations["4,5,6"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="11.3725">
                  {combinations["4,5,6"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["4,5,6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["7,8,9"].chip && (
            <g
              className={
                combinations["7,8,9"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="11.3725">
                  {combinations["7,8,9"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["7,8,9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["10,11,12"].chip && (
            <g
              className={
                combinations["10,11,12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="11.3725">
                  {combinations["10,11,12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["10,11,12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["13,14,15"].chip && (
            <g
              className={
                combinations["13,14,15"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="11.3725">
                  {combinations["13,14,15"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["13,14,15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["16,17,18"].chip && (
            <g
              className={
                combinations["16,17,18"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="11.3725">
                  {combinations["16,17,18"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["16,17,18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["19,20,21"].chip && (
            <g
              className={
                combinations["19,20,21"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="11.3725">
                  {combinations["19,20,21"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["19,20,21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["22,23,24"].chip && (
            <g
              className={
                combinations["22,23,24"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="488.536" y="11.3725">
                  {combinations["22,23,24"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["22,23,24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["25,26,27"].chip && (
            <g
              className={
                combinations["25,26,27"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="11.3725">
                  {combinations["25,26,27"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["25,26,27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["28,29,30"].chip && (
            <g
              className={
                combinations["28,29,30"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="11.3725">
                  {combinations["28,29,30"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["28,29,30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["31,32,33"].chip && (
            <g
              className={
                combinations["31,32,33"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="11.3725">
                  {combinations["31,32,33"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["31,32,33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["34,35,36"].chip && (
            <g
              className={
                combinations["34,35,36"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="0.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="11.3725">
                  {combinations["34,35,36"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["34,35,36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,2,3"].chip && (
            <g
              className={
                combinations["0,2,3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="65.3725">
                  {combinations["0,2,3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,2,3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["2,3,5,6"].chip && (
            <g
              className={
                combinations["2,3,5,6"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="65.3725">
                  {combinations["2,3,5,6"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["2,3,5,6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["5,6,8,9"].chip && (
            <g
              className={
                combinations["5,6,8,9"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="65.3725">
                  {combinations["5,6,8,9"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["5,6,8,9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["8,9,11,12"].chip && (
            <g
              className={
                combinations["8,9,11,12"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="65.3725">
                  {combinations["8,9,11,12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["8,9,11,12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["11,12,14,15"].chip && (
            <g
              className={
                combinations["11,12,14,15"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="65.3725">
                  {combinations["11,12,14,15"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["11,12,14,15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["14,15,17,18"].chip && (
            <g
              className={
                combinations["14,15,17,18"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="65.3725">
                  {combinations["14,15,17,18"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["14,15,17,18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["17,18,20,21"].chip && (
            <g
              className={
                combinations["17,18,20,21"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="65.3725">
                  {combinations["17,18,20,21"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["17,18,20,21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["20,21,23,24"].chip && (
            <g
              className={
                combinations["20,21,23,24"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="65.3725">
                  {combinations["20,21,23,24"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["20,21,23,24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["23,24,26,27"].chip && (
            <g
              className={
                combinations["23,24,26,27"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="65.3725">
                  {combinations["23,24,26,27"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["23,24,26,27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["26,27,29,30"].chip && (
            <g
              className={
                combinations["26,27,29,30"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="65.3725">
                  {combinations["26,27,29,30"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["26,27,29,30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["29,30,32,33"].chip && (
            <g
              className={
                combinations["29,30,32,33"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="65.3725">
                  {combinations["29,30,32,33"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["29,30,32,33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["32,33,35,36"].chip && (
            <g
              className={
                combinations["32,33,35,36"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="65.3725">
                  {combinations["32,33,35,36"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["32,33,35,36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["2,3"].chip && (
            <g
              className={
                combinations["2,3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="65.3725">
                  {combinations["2,3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["2,3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["5,6"].chip && (
            <g
              className={
                combinations["5,6"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="65.3725">
                  {combinations["5,6"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["5,6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["8,9"].chip && (
            <g
              className={
                combinations["8,9"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="65.3725">
                  {combinations["8,9"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["8,9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["11,12"].chip && (
            <g
              className={
                combinations["11,12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="65.3725">
                  {combinations["11,12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["11,12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["14,15"].chip && (
            <g
              className={
                combinations["14,15"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="65.3725">
                  {combinations["14,15"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["14,15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["17,18"].chip && (
            <g
              className={
                combinations["17,18"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="65.3725">
                  {combinations["17,18"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["17,18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["20,21"].chip && (
            <g
              className={
                combinations["20,21"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="65.3725">
                  {combinations["20,21"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["20,21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["23,24"].chip && (
            <g
              className={
                combinations["23,24"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="489.536" y="65.3725">
                  {combinations["23,24"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["23,24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["26,27"].chip && (
            <g
              className={
                combinations["26,27"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="65.3725">
                  {combinations["26,27"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["26,27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["29,30"].chip && (
            <g
              className={
                combinations["29,30"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="65.3725">
                  {combinations["29,30"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["29,30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["32,33"].chip && (
            <g
              className={
                combinations["32,33"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="65.3725">
                  {combinations["32,33"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["32,33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["35,36"].chip && (
            <g
              className={
                combinations["35,36"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="54.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="65.3725">
                  {combinations["35,36"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["35,36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,1,2"].chip && (
            <g
              className={
                combinations["0,1,2"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="119.373">
                  {combinations["0,1,2"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,1,2"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["1,2,4,5"].chip && (
            <g
              className={
                combinations["1,2,4,5"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="119.373">
                  {combinations["1,2,4,5"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["1,2,4,5"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["4,5,7,8"].chip && (
            <g
              className={
                combinations["4,5,7,8"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="119.373">
                  {combinations["4,5,7,8"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["4,5,7,8"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["7,8,10,11"].chip && (
            <g
              className={
                combinations["7,8,10,11"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="119.373">
                  {combinations["7,8,10,11"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["7,8,10,11"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["10,11,13,14"].chip && (
            <g
              className={
                combinations["10,11,13,14"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="119.373">
                  {combinations["10,11,13,14"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["10,11,13,14"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["13,14,16,17"].chip && (
            <g
              className={
                combinations["13,14,16,17"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="119.373">
                  {combinations["13,14,16,17"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["13,14,16,17"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["16,17,19,20"].chip && (
            <g
              className={
                combinations["16,17,19,20"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="119.373">
                  {combinations["16,17,19,20"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["16,17,19,20"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["19,20,22,23"].chip && (
            <g
              className={
                combinations["19,20,22,23"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="119.373">
                  {combinations["19,20,22,23"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["19,20,22,23"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["22,23,25,26"].chip && (
            <g
              className={
                combinations["22,23,25,26"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="119.373">
                  {combinations["22,23,25,26"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["22,23,25,26"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["25,26,28,29"].chip && (
            <g
              className={
                combinations["25,26,28,29"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="119.373">
                  {combinations["25,26,28,29"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["25,26,28,29"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["28,29,31,32"].chip && (
            <g
              className={
                combinations["28,29,31,32"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="119.373">
                  {combinations["28,29,31,32"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["28,29,31,32"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["31,32,34,35"].chip && (
            <g
              className={
                combinations["31,32,34,35"].removeChip
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="119.373">
                  {combinations["31,32,34,35"].removeChip &&
                  !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["31,32,34,35"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["1,2"].chip && (
            <g
              className={
                combinations["1,2"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="119.373">
                  {combinations["1,2"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["1,2"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["4,5"].chip && (
            <g
              className={
                combinations["4,5"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="119.373">
                  {combinations["4,5"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["4,5"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["7,8"].chip && (
            <g
              className={
                combinations["7,8"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="119.373">
                  {combinations["7,8"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["7,8"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["10,11"].chip && (
            <g
              className={
                combinations["10,11"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="119.373">
                  {combinations["10,11"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["10,11"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["13,14"].chip && (
            <g
              className={
                combinations["13,14"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="119.373">
                  {combinations["13,14"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["13,14"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["16,17"].chip && (
            <g
              className={
                combinations["16,17"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="119.373">
                  {combinations["16,17"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["16,17"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["19,20"].chip && (
            <g
              className={
                combinations["19,20"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="119.373">
                  {combinations["19,20"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["19,20"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["22,23"].chip && (
            <g
              className={
                combinations["22,23"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="488.536" y="119.373">
                  {combinations["22,23"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["22,23"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["25,26"].chip && (
            <g
              className={
                combinations["25,26"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="119.373">
                  {combinations["25,26"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["25,26"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["28,29"].chip && (
            <g
              className={
                combinations["28,29"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="119.373">
                  {combinations["28,29"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["28,29"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["31,32"].chip && (
            <g
              className={
                combinations["31,32"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="119.373">
                  {combinations["31,32"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["31,32"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["34,35"].chip && (
            <g
              className={
                combinations["34,35"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="108.5"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="119.373">
                  {combinations["34,35"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["34,35"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,1"].chip && (
            <g
              className={
                combinations["0,1"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="145.873">
                  {combinations["0,1"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,1"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["1,4"].chip && (
            <g
              className={
                combinations["1,4"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="145.873">
                  {combinations["1,4"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["1,4"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["4,7"].chip && (
            <g
              className={
                combinations["4,7"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="145.873">
                  {combinations["4,7"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["4,7"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["7,10"].chip && (
            <g
              className={
                combinations["7,10"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="145.873">
                  {combinations["7,10"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["7,10"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["10,13"].chip && (
            <g
              className={
                combinations["10,13"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="145.873">
                  {combinations["10,13"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["10,13"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["13,16"].chip && (
            <g
              className={
                combinations["13,16"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="145.873">
                  {combinations["13,16"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["13,16"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["16,19"].chip && (
            <g
              className={
                combinations["16,19"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="145.873">
                  {combinations["16,19"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["16,19"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["19,22"].chip && (
            <g
              className={
                combinations["19,22"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="145.873">
                  {combinations["19,22"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["19,22"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["22,25"].chip && (
            <g
              className={
                combinations["22,25"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="145.873">
                  {combinations["22,25"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["22,25"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["25,28"].chip && (
            <g
              className={
                combinations["25,28"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="145.873">
                  {combinations["25,28"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["25,28"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["28,31"].chip && (
            <g
              className={
                combinations["28,31"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="145.873">
                  {combinations["28,31"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["28,31"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["31,34"].chip && (
            <g
              className={
                combinations["31,34"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="145.873">
                  {combinations["31,34"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["31,34"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,2"].chip && (
            <g
              className={
                combinations["0,2"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="91.8725">
                  {combinations["0,2"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,2"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["2,5"].chip && (
            <g
              className={
                combinations["2,5"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="91.8725">
                  {combinations["2,5"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["2,5"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["5,8"].chip && (
            <g
              className={
                combinations["5,8"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="91.8725">
                  {combinations["5,8"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["5,8"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["8,11"].chip && (
            <g
              className={
                combinations["8,11"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="91.8725">
                  {combinations["8,11"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["8,11"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["11,14"].chip && (
            <g
              className={
                combinations["11,14"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="91.8725">
                  {combinations["11,14"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["11,14"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["14,17"].chip && (
            <g
              className={
                combinations["14,17"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="91.8725">
                  {combinations["14,17"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["14,17"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["17,20"].chip && (
            <g
              className={
                combinations["17,20"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="91.8725">
                  {combinations["17,20"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["17,20"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["20,23"].chip && (
            <g
              className={
                combinations["20,23"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="91.8725">
                  {combinations["20,23"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["20,23"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["23,26"].chip && (
            <g
              className={
                combinations["23,26"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="91.8725">
                  {combinations["23,26"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["23,26"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["26,29"].chip && (
            <g
              className={
                combinations["26,29"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="91.8725">
                  {combinations["26,29"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["26,29"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["29,32"].chip && (
            <g
              className={
                combinations["29,32"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="91.8725">
                  {combinations["29,32"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["29,32"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["32,35"].chip && (
            <g
              className={
                combinations["32,35"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="91.8725">
                  {combinations["32,35"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["32,35"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["0,3"].chip && (
            <g
              className={
                combinations["0,3"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="74.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="84.0356" y="37.8725">
                  {combinations["0,3"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["0,3"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["3,6"].chip && (
            <g
              className={
                combinations["3,6"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="128.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.036" y="37.8725">
                  {combinations["3,6"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["3,6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["6,9"].chip && (
            <g
              className={
                combinations["6,9"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="182.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.036" y="37.8725">
                  {combinations["6,9"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["6,9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["9,12"].chip && (
            <g
              className={
                combinations["9,12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="236.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.036" y="37.8725">
                  {combinations["9,12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["9,12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["12,15"].chip && (
            <g
              className={
                combinations["12,15"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="290.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="300.036" y="37.8725">
                  {combinations["12,15"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["12,15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["15,18"].chip && (
            <g
              className={
                combinations["15,18"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="344.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.036" y="37.8725">
                  {combinations["15,18"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["15,18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["18,21"].chip && (
            <g
              className={
                combinations["18,21"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="398.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.036" y="37.8725">
                  {combinations["18,21"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["18,21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["21,24"].chip && (
            <g
              className={
                combinations["21,24"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="452.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.036" y="37.8725">
                  {combinations["21,24"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["21,24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["24,27"].chip && (
            <g
              className={
                combinations["24,27"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="506.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="516.036" y="37.8725">
                  {combinations["24,27"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["24,27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["27,30"].chip && (
            <g
              className={
                combinations["27,30"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="560.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.036" y="37.8725">
                  {combinations["27,30"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["27,30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["30,33"].chip && (
            <g
              className={
                combinations["30,33"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="614.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.036" y="37.8725">
                  {combinations["30,33"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["30,33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {combinations["33,36"].chip && (
            <g
              className={
                combinations["33,36"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="668.5"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.036" y="37.8725">
                  {combinations["33,36"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    combinations["33,36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["2"].chip && (
            <g
              className={
                tableNumbers["2"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="91.8725">
                  {tableNumbers["2"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["2"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["0"].chip && (
            <g
              className={
                tableNumbers["0"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="32"
                y="82"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="41.5356" y="92.8725">
                  {tableNumbers["0"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["0"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["1st12"].chip && (
            <g
              className={
                commands["1st12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="183"
                y="205"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="192.536" y="215.873">
                  {commands["1st12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["1st12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["1-18"].chip && (
            <g
              className={
                commands["1-18"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="129"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="138.536" y="299.873">
                  {commands["1-18"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["1-18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["even"].chip && (
            <g
              className={
                commands["even"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="237"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="246.536" y="299.873">
                  {commands["even"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["even"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["red"].chip && (
            <g
              className={
                commands["red"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="345"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="354.536" y="299.873">
                  {commands["red"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["red"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["blue"].chip && (
            <g
              className={
                commands["blue"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="453"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="462.536" y="299.873">
                  {commands["blue"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["blue"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["odd"].chip && (
            <g
              className={
                commands["odd"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="561"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="570.536" y="299.873">
                  {commands["odd"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["odd"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["19-36"].chip && (
            <g
              className={
                commands["19-36"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="669"
                y="289"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="678.536" y="299.873">
                  {commands["19-36"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["19-36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["2nd12"].chip && (
            <g
              className={
                commands["2nd12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="399"
                y="205"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="408.536" y="215.873">
                  {commands["2nd12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["2nd12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["3rd12"].chip && (
            <g
              className={
                commands["3rd12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="615"
                y="205"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="624.536" y="215.873">
                  {commands["3rd12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["3rd12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["1"].chip && (
            <g
              className={
                tableNumbers["1"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="101"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="110.536" y="145.873">
                  {tableNumbers["1"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["1"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["4"].chip && (
            <g
              className={
                tableNumbers["4"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="145.873">
                  {tableNumbers["4"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["4"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["7"].chip && (
            <g
              className={
                tableNumbers["7"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="145.873">
                  {tableNumbers["7"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["7"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["10"].chip && (
            <g
              className={
                tableNumbers["10"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="145.873">
                  {tableNumbers["10"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["10"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["13"].chip && (
            <g
              className={
                tableNumbers["13"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="145.873">
                  {tableNumbers["13"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["13"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["16"].chip && (
            <g
              className={
                tableNumbers["16"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="145.873">
                  {tableNumbers["16"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["16"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["19"].chip && (
            <g
              className={
                tableNumbers["19"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="145.873">
                  {tableNumbers["19"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["19"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["22"].chip && (
            <g
              className={
                tableNumbers["22"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="488.536" y="145.873">
                  {tableNumbers["22"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["22"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["25"].chip && (
            <g
              className={
                tableNumbers["25"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="145.873">
                  {tableNumbers["25"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["25"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["28"].chip && (
            <g
              className={
                tableNumbers["28"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="145.873">
                  {tableNumbers["28"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["28"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["31"].chip && (
            <g
              className={
                tableNumbers["31"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="145.873">
                  {tableNumbers["31"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["31"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["34"].chip && (
            <g
              className={
                tableNumbers["34"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="145.873">
                  {tableNumbers["34"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["34"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["1st2-1"].chip && (
            <g
              className={
                commands["1st2-1"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="749"
                y="135"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="758.536" y="145.873">
                  {commands["1st2-1"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["1st2-1"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["5"].chip && (
            <g
              className={
                tableNumbers["5"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="91.8725">
                  {tableNumbers["5"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["5"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["8"].chip && (
            <g
              className={
                tableNumbers["8"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="91.8725">
                  {tableNumbers["8"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["8"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["11"].chip && (
            <g
              className={
                tableNumbers["11"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="91.8725">
                  {tableNumbers["11"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["11"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["14"].chip && (
            <g
              className={
                tableNumbers["14"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="91.8725">
                  {tableNumbers["14"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["14"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["17"].chip && (
            <g
              className={
                tableNumbers["17"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="91.8725">
                  {tableNumbers["17"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["17"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["20"].chip && (
            <g
              className={
                tableNumbers["20"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="91.8725">
                  {tableNumbers["20"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["20"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["23"].chip && (
            <g
              className={
                tableNumbers["23"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="488.536" y="91.8725">
                  {tableNumbers["23"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["23"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["26"].chip && (
            <g
              className={
                tableNumbers["26"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="91.8725">
                  {tableNumbers["26"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["26"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["29"].chip && (
            <g
              className={
                tableNumbers["29"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="91.8725">
                  {tableNumbers["29"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["29"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["32"].chip && (
            <g
              className={
                tableNumbers["32"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="91.8725">
                  {tableNumbers["32"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["32"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["35"].chip && (
            <g
              className={
                tableNumbers["35"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="91.8725">
                  {tableNumbers["35"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["35"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["2nd2-1"].chip && (
            <g
              className={
                commands["2nd2-1"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="749"
                y="81"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="758.536" y="91.8725">
                  {commands["2nd2-1"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["2nd2-1"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["6"].chip && (
            <g
              className={
                tableNumbers["6"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="155"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="164.536" y="37.8725">
                  {tableNumbers["6"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["6"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["9"].chip && (
            <g
              className={
                tableNumbers["9"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="209"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="218.536" y="37.8725">
                  {tableNumbers["9"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["9"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["12"].chip && (
            <g
              className={
                tableNumbers["12"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="263"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="272.536" y="37.8725">
                  {tableNumbers["12"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["12"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["15"].chip && (
            <g
              className={
                tableNumbers["15"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="317"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="326.536" y="37.8725">
                  {tableNumbers["15"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["15"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["18"].chip && (
            <g
              className={
                tableNumbers["18"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="371"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="380.536" y="37.8725">
                  {tableNumbers["18"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["18"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["21"].chip && (
            <g
              className={
                tableNumbers["21"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="425"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="434.536" y="37.8725">
                  {tableNumbers["21"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["21"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["24"].chip && (
            <g
              className={
                tableNumbers["24"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="479"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="488.536" y="37.8725">
                  {tableNumbers["24"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["24"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["27"].chip && (
            <g
              className={
                tableNumbers["27"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="533"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="542.536" y="37.8725">
                  {tableNumbers["27"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["27"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["30"].chip && (
            <g
              className={
                tableNumbers["30"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="587"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="596.536" y="37.8725">
                  {tableNumbers["30"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["30"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["33"].chip && (
            <g
              className={
                tableNumbers["33"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="641"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="650.536" y="37.8725">
                  {tableNumbers["33"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["33"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {tableNumbers["36"].chip && (
            <g
              className={
                tableNumbers["36"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="695"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="704.536" y="37.8725">
                  {tableNumbers["36"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    tableNumbers["36"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
          {commands["3rd2-1"].chip && (
            <g
              className={
                commands["3rd2-1"].removeChip && !disableControls
                  ? "chip chip--remove"
                  : null
              }
            >
              <rect
                x="749"
                y="27"
                width="20"
                height="20"
                rx="10"
                fill={chipsColor}
              />
              <text
                fill="white"
                style={{ whiteSpace: "pre" }}
                fontFamily="Wendy One"
                fontSize="15"
                letterSpacing="0em"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                <tspan cx="50%" cy="50%" x="758.536" y="37.8725">
                  {commands["3rd2-1"].removeChip && !disableControls ? (
                    <Fragment>&times;</Fragment>
                  ) : (
                    commands["3rd2-1"].chipID
                  )}
                </tspan>
              </text>
            </g>
          )}
        </g>
        <g className="table__overlay">
          <rect
            x="95"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("3", "tableNumbers")}
            onMouseEnter={() => highlight([3], "tableNumbers")}
            onMouseLeave={() => unhighlight([3], "tableNumbers")}
          />
          <rect
            x="101"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("1,2,3", "combinations")}
            onMouseEnter={() => highlight([1, 2, 3], "combinations")}
            onMouseLeave={() => unhighlight([1, 2, 3], "combinations")}
          />
          <rect
            x="128.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("1,2,3,4,5,6", "combinations")}
            onMouseEnter={() => highlight([1, 2, 3, 4, 5, 6], "combinations")}
            onMouseLeave={() => unhighlight([1, 2, 3, 4, 5, 6], "combinations")}
          />
          <rect
            x="128.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("3,6", "combinations")}
            onMouseEnter={() => highlight([3, 6], "combinations")}
            onMouseLeave={() => unhighlight([3, 6], "combinations")}
          />
          <rect
            x="74.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,1,2,3", "combinations")}
            onMouseEnter={() => highlight([0, 1, 2, 3], "combinations")}
            onMouseLeave={() => unhighlight([0, 1, 2, 3], "combinations")}
          />
          <rect
            x="74.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,3", "combinations")}
            onMouseEnter={() => highlight([0, 3], "combinations")}
            onMouseLeave={() => unhighlight([0, 3], "combinations")}
          />
          <rect
            x="101"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("2,3", "combinations")}
            onMouseEnter={() => highlight([2, 3], "combinations")}
            onMouseLeave={() => unhighlight([2, 3], "combinations")}
          />
          <rect
            x="128.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("2,3,5,6", "combinations")}
            onMouseEnter={() => highlight([2, 3, 5, 6], "combinations")}
            onMouseLeave={() => unhighlight([2, 3, 5, 6], "combinations")}
          />
          <rect
            x="74.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,2,3", "combinations")}
            onMouseEnter={() => highlight([0, 2, 3], "combinations")}
            onMouseLeave={() => unhighlight([0, 2, 3], "combinations")}
          />
          <rect
            x="95"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("2", "tableNumbers")}
            onMouseEnter={() => highlight([2], "tableNumbers")}
            onMouseLeave={() => unhighlight([2], "tableNumbers")}
          />
          <rect
            x="128.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("2,5", "combinations")}
            onMouseEnter={() => highlight([2, 5], "combinations")}
            onMouseLeave={() => unhighlight([2, 5], "combinations")}
          />
          <rect
            x="74.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,2", "combinations")}
            onMouseEnter={() => highlight([0, 2], "combinations")}
            onMouseLeave={() => unhighlight([0, 2], "combinations")}
          />
          <rect
            x="101"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("1,2", "combinations")}
            onMouseEnter={() => highlight([1, 2], "combinations")}
            onMouseLeave={() => unhighlight([1, 2], "combinations")}
          />
          <rect
            x="128.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("1,2,4,5", "combinations")}
            onMouseEnter={() => highlight([1, 2, 4, 5], "combinations")}
            onMouseLeave={() => unhighlight([1, 2, 4, 5], "combinations")}
          />
          <rect
            x="74.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,1,2", "combinations")}
            onMouseEnter={() => highlight([0, 1, 2], "combinations")}
            onMouseLeave={() => unhighlight([0, 1, 2], "combinations")}
          />
          <rect
            x="95"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("1", "tableNumbers")}
            onMouseEnter={() => highlight([1], "tableNumbers")}
            onMouseLeave={() => unhighlight([1], "tableNumbers")}
          />
          <rect
            x="94"
            y="183"
            width="198"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("1st12", "commands")}
            onMouseEnter={() => highlight("1st12", "commands")}
            onMouseLeave={() => unhighlight("1st12", "commands")}
          />
          <rect
            x="9"
            y="20"
            width="63"
            height="143"
            fill={overlay}
            onClick={() => toggleChips("0", "tableNumbers")}
            onMouseEnter={() => highlight([0], "tableNumbers")}
            onMouseLeave={() => unhighlight([0], "tableNumbers")}
          />
          <rect
            x="94"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("1-18", "commands")}
            onMouseEnter={() => highlight("1-18", "commands")}
            onMouseLeave={() => unhighlight("1-18", "commands")}
          />
          <rect
            x="202"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("even", "commands")}
            onMouseEnter={() => highlight("even", "commands")}
            onMouseLeave={() => unhighlight("even", "commands")}
          />
          <rect
            x="310"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("red", "commands")}
            onMouseEnter={() => highlight("red", "commands")}
            onMouseLeave={() => unhighlight("red", "commands")}
          />
          <rect
            x="418"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("blue", "commands")}
            onMouseEnter={() => highlight("blue", "commands")}
            onMouseLeave={() => unhighlight("blue", "commands")}
          />
          <rect
            x="526"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("odd", "commands")}
            onMouseEnter={() => highlight("odd", "commands")}
            onMouseLeave={() => unhighlight("odd", "commands")}
          />
          <rect
            x="634"
            y="267"
            width="89"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("19-36", "commands")}
            onMouseEnter={() => highlight("19-36", "commands")}
            onMouseLeave={() => unhighlight("19-36", "commands")}
          />
          <rect
            x="310"
            y="183"
            width="198"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("2nd12", "commands")}
            onMouseEnter={() => highlight("2nd12", "commands")}
            onMouseLeave={() => unhighlight("2nd12", "commands")}
          />
          <rect
            x="526"
            y="183"
            width="198"
            height="63"
            fill={overlay}
            onClick={() => toggleChips("3rd12", "commands")}
            onMouseEnter={() => highlight("3rd12", "commands")}
            onMouseLeave={() => unhighlight("3rd12", "commands")}
          />
          <rect
            x="128.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("1,4", "combinations")}
            onMouseEnter={() => highlight([1, 4], "combinations")}
            onMouseLeave={() => unhighlight([1, 4], "combinations")}
          />
          <rect
            x="74.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("0,1", "combinations")}
            onMouseEnter={() => highlight([0, 1], "combinations")}
            onMouseLeave={() => unhighlight([0, 1], "combinations")}
          />
          <rect
            x="149"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("6", "tableNumbers")}
            onMouseEnter={() => highlight([6], "tableNumbers")}
            onMouseLeave={() => unhighlight([6], "tableNumbers")}
          />
          <rect
            x="155"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("4,5,6", "combinations")}
            onMouseEnter={() => highlight([4, 5, 6], "combinations")}
            onMouseLeave={() => unhighlight([4, 5, 6], "combinations")}
          />
          <rect
            x="182.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("4,5,6,7,8,9", "combinations")}
            onMouseEnter={() => highlight([4, 5, 6, 7, 8, 9], "combinations")}
            onMouseLeave={() => unhighlight([4, 5, 6, 7, 8, 9], "combinations")}
          />
          <rect
            x="182.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("6,9", "combinations")}
            onMouseEnter={() => highlight([6, 9], "combinations")}
            onMouseLeave={() => unhighlight([6, 9], "combinations")}
          />
          <rect
            x="155"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("5,6", "combinations")}
            onMouseEnter={() => highlight([5, 6], "combinations")}
            onMouseLeave={() => unhighlight([5, 6], "combinations")}
          />
          <rect
            x="182.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("5,6,8,9", "combinations")}
            onMouseEnter={() => highlight([5, 6, 8, 9], "combinations")}
            onMouseLeave={() => unhighlight([5, 6, 8, 9], "combinations")}
          />
          <rect
            x="149"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("5", "tableNumbers")}
            onMouseEnter={() => highlight([5], "tableNumbers")}
            onMouseLeave={() => unhighlight([5], "tableNumbers")}
          />
          <rect
            x="182.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("5,8", "combinations")}
            onMouseEnter={() => highlight([5, 8], "combinations")}
            onMouseLeave={() => unhighlight([5, 8], "combinations")}
          />
          <rect
            x="155"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("4,5", "combinations")}
            onMouseEnter={() => highlight([4, 5], "combinations")}
            onMouseLeave={() => unhighlight([4, 5], "combinations")}
          />
          <rect
            x="182.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("4,5,7,8", "combinations")}
            onMouseEnter={() => highlight([4, 5, 7, 8], "combinations")}
            onMouseLeave={() => unhighlight([4, 5, 7, 8], "combinations")}
          />
          <rect
            x="149"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("4", "tableNumbers")}
            onMouseEnter={() => highlight([4], "tableNumbers")}
            onMouseLeave={() => unhighlight([4], "tableNumbers")}
          />
          <rect
            x="182.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("4,7", "combinations")}
            onMouseEnter={() => highlight([4, 7], "combinations")}
            onMouseLeave={() => unhighlight([4, 7], "combinations")}
          />
          <rect
            x="203"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("9", "tableNumbers")}
            onMouseEnter={() => highlight([9], "tableNumbers")}
            onMouseLeave={() => unhighlight([9], "tableNumbers")}
          />
          <rect
            x="209"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("7,8,9", "combinations")}
            onMouseEnter={() => highlight([7, 8, 9], "combinations")}
            onMouseLeave={() => unhighlight([7, 8, 9], "combinations")}
          />
          <rect
            x="236.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("7,8,9,10,11,12", "combinations")}
            onMouseEnter={() =>
              highlight([7, 8, 9, 10, 11, 12], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([7, 8, 9, 10, 11, 12], "combinations")
            }
          />
          <rect
            x="236.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("9,12", "combinations")}
            onMouseEnter={() => highlight([9, 12], "combinations")}
            onMouseLeave={() => unhighlight([9, 12], "combinations")}
          />
          <rect
            x="209"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("8,9", "combinations")}
            onMouseEnter={() => highlight([8, 9], "combinations")}
            onMouseLeave={() => unhighlight([8, 9], "combinations")}
          />
          <rect
            x="236.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("8,9,11,12", "combinations")}
            onMouseEnter={() => highlight([8, 9, 11, 12], "combinations")}
            onMouseLeave={() => unhighlight([8, 9, 11, 12], "combinations")}
          />
          <rect
            x="203"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("8", "tableNumbers")}
            onMouseEnter={() => highlight([8], "tableNumbers")}
            onMouseLeave={() => unhighlight([8], "tableNumbers")}
          />
          <rect
            x="236.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("8,11", "combinations")}
            onMouseEnter={() => highlight([8, 11], "combinations")}
            onMouseLeave={() => unhighlight([8, 11], "combinations")}
          />
          <rect
            x="209"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("7,8", "combinations")}
            onMouseEnter={() => highlight([7, 8], "combinations")}
            onMouseLeave={() => unhighlight([7, 8], "combinations")}
          />
          <rect
            x="236.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("7,8,10,11", "combinations")}
            onMouseEnter={() => highlight([7, 8, 10, 11], "combinations")}
            onMouseLeave={() => unhighlight([7, 8, 10, 11], "combinations")}
          />
          <rect
            x="203"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("7", "tableNumbers")}
            onMouseEnter={() => highlight([7], "tableNumbers")}
            onMouseLeave={() => unhighlight([7], "tableNumbers")}
          />
          <rect
            x="236.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("7,10", "combinations")}
            onMouseEnter={() => highlight([7, 10], "combinations")}
            onMouseLeave={() => unhighlight([7, 10], "combinations")}
          />
          <rect
            x="257"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("12", "tableNumbers")}
            onMouseEnter={() => highlight([12], "tableNumbers")}
            onMouseLeave={() => unhighlight([12], "tableNumbers")}
          />
          <rect
            x="263"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("10,11,12", "combinations")}
            onMouseEnter={() => highlight([10, 11, 12], "combinations")}
            onMouseLeave={() => unhighlight([10, 11, 12], "combinations")}
          />
          <rect
            x="290.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("10,11,12,13,14,15", "combinations")}
            onMouseEnter={() =>
              highlight([10, 11, 12, 13, 14, 15], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([10, 11, 12, 13, 14, 15], "combinations")
            }
          />
          <rect
            x="290.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("12,15", "combinations")}
            onMouseEnter={() => highlight([12, 15], "combinations")}
            onMouseLeave={() => unhighlight([12, 15], "combinations")}
          />
          <rect
            x="263"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("11,12", "combinations")}
            onMouseEnter={() => highlight([11, 12], "combinations")}
            onMouseLeave={() => unhighlight([11, 12], "combinations")}
          />
          <rect
            x="290.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("11,12,14,15", "combinations")}
            onMouseEnter={() => highlight([11, 12, 14, 15], "combinations")}
            onMouseLeave={() => unhighlight([11, 12, 14, 15], "combinations")}
          />
          <rect
            x="257"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("11", "tableNumbers")}
            onMouseEnter={() => highlight([11], "tableNumbers")}
            onMouseLeave={() => unhighlight([11], "tableNumbers")}
          />
          <rect
            x="290.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("11,14", "combinations")}
            onMouseEnter={() => highlight([11, 14], "combinations")}
            onMouseLeave={() => unhighlight([11, 14], "combinations")}
          />
          <rect
            x="263"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("10,11", "combinations")}
            onMouseEnter={() => highlight([10, 11], "combinations")}
            onMouseLeave={() => unhighlight([10, 11], "combinations")}
          />
          <rect
            x="290.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("10,11,13,14", "combinations")}
            onMouseEnter={() => highlight([10, 11, 13, 14], "combinations")}
            onMouseLeave={() => unhighlight([10, 11, 13, 14], "combinations")}
          />
          <rect
            x="257"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("10", "tableNumbers")}
            onMouseEnter={() => highlight([10], "tableNumbers")}
            onMouseLeave={() => unhighlight([10], "tableNumbers")}
          />
          <rect
            x="290.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("10,13", "combinations")}
            onMouseEnter={() => highlight([10, 13], "combinations")}
            onMouseLeave={() => unhighlight([10, 13], "combinations")}
          />
          <rect
            x="311"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("15", "tableNumbers")}
            onMouseEnter={() => highlight([15], "tableNumbers")}
            onMouseLeave={() => unhighlight([15], "tableNumbers")}
          />
          <rect
            x="317"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("13,14,15", "combinations")}
            onMouseEnter={() => highlight([13, 14, 15], "combinations")}
            onMouseLeave={() => unhighlight([13, 14, 15], "combinations")}
          />
          <rect
            x="344.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("13,14,15,16,17,18", "combinations")}
            onMouseEnter={() =>
              highlight([13, 14, 15, 16, 17, 18], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([13, 14, 15, 16, 17, 18], "combinations")
            }
          />
          <rect
            x="344.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("15,18", "combinations")}
            onMouseEnter={() => highlight([15, 18], "combinations")}
            onMouseLeave={() => unhighlight([15, 18], "combinations")}
          />
          <rect
            x="317"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("14,15", "combinations")}
            onMouseEnter={() => highlight([14, 15], "combinations")}
            onMouseLeave={() => unhighlight([14, 15], "combinations")}
          />
          <rect
            x="344.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("14,15,17,18", "combinations")}
            onMouseEnter={() => highlight([14, 15, 17, 18], "combinations")}
            onMouseLeave={() => unhighlight([14, 15, 17, 18], "combinations")}
          />
          <rect
            x="311"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("14", "tableNumbers")}
            onMouseEnter={() => highlight([14], "tableNumbers")}
            onMouseLeave={() => unhighlight([14], "tableNumbers")}
          />
          <rect
            x="344.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("14,17", "combinations")}
            onMouseEnter={() => highlight([14, 17], "combinations")}
            onMouseLeave={() => unhighlight([14, 17], "combinations")}
          />
          <rect
            x="317"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("13,14", "combinations")}
            onMouseEnter={() => highlight([13, 14], "combinations")}
            onMouseLeave={() => unhighlight([13, 14], "combinations")}
          />
          <rect
            x="344.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("13,14,16,17", "combinations")}
            onMouseEnter={() => highlight([13, 14, 16, 17], "combinations")}
            onMouseLeave={() => unhighlight([13, 14, 16, 17], "combinations")}
          />
          <rect
            x="311"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("13", "tableNumbers")}
            onMouseEnter={() => highlight([13], "tableNumbers")}
            onMouseLeave={() => unhighlight([13], "tableNumbers")}
          />
          <rect
            x="344.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("13,16", "combinations")}
            onMouseEnter={() => highlight([13, 16], "combinations")}
            onMouseLeave={() => unhighlight([13, 16], "combinations")}
          />
          <rect
            x="365"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("18", "tableNumbers")}
            onMouseEnter={() => highlight([18], "tableNumbers")}
            onMouseLeave={() => unhighlight([18], "tableNumbers")}
          />
          <rect
            x="371"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("16,17,18", "combinations")}
            onMouseEnter={() => highlight([16, 17, 18], "combinations")}
            onMouseLeave={() => unhighlight([16, 17, 18], "combinations")}
          />
          <rect
            x="398.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("16,17,18,19,20,21", "combinations")}
            onMouseEnter={() =>
              highlight([16, 17, 18, 19, 20, 21], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([16, 17, 18, 19, 20, 21], "combinations")
            }
          />
          <rect
            x="398.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("18,21", "combinations")}
            onMouseEnter={() => highlight([18, 21], "combinations")}
            onMouseLeave={() => unhighlight([18, 21], "combinations")}
          />
          <rect
            x="371"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("17,18", "combinations")}
            onMouseEnter={() => highlight([17, 18], "combinations")}
            onMouseLeave={() => unhighlight([17, 18], "combinations")}
          />
          <rect
            x="398.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("17,18,20,21", "combinations")}
            onMouseEnter={() => highlight([17, 18, 20, 21], "combinations")}
            onMouseLeave={() => unhighlight([17, 18, 20, 21], "combinations")}
          />
          <rect
            x="365"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("17", "tableNumbers")}
            onMouseEnter={() => highlight([17], "tableNumbers")}
            onMouseLeave={() => unhighlight([17], "tableNumbers")}
          />
          <rect
            x="398.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("17,20", "combinations")}
            onMouseEnter={() => highlight([17, 20], "combinations")}
            onMouseLeave={() => unhighlight([17, 20], "combinations")}
          />
          <rect
            x="371"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("16,17", "combinations")}
            onMouseEnter={() => highlight([16, 17], "combinations")}
            onMouseLeave={() => unhighlight([16, 17], "combinations")}
          />
          <rect
            x="398.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("16,17,19,20", "combinations")}
            onMouseEnter={() => highlight([16, 17, 19, 20], "combinations")}
            onMouseLeave={() => unhighlight([16, 17, 19, 20], "combinations")}
          />
          <rect
            x="365"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("16", "tableNumbers")}
            onMouseEnter={() => highlight([16], "tableNumbers")}
            onMouseLeave={() => unhighlight([16], "tableNumbers")}
          />
          <rect
            x="398.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("16,19", "combinations")}
            onMouseEnter={() => highlight([16, 19], "combinations")}
            onMouseLeave={() => unhighlight([16, 19], "combinations")}
          />
          <rect
            x="419"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("21", "tableNumbers")}
            onMouseEnter={() => highlight([21], "tableNumbers")}
            onMouseLeave={() => unhighlight([21], "tableNumbers")}
          />
          <rect
            x="425"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("19,20,21", "combinations")}
            onMouseEnter={() => highlight([19, 20, 21], "combinations")}
            onMouseLeave={() => unhighlight([19, 20, 21], "combinations")}
          />
          <rect
            x="452.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("19,20,21,22,23,24", "combinations")}
            onMouseEnter={() =>
              highlight([19, 20, 21, 22, 23, 24], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([19, 20, 21, 22, 23, 24], "combinations")
            }
          />
          <rect
            x="452.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("21,24", "combinations")}
            onMouseEnter={() => highlight([21, 24], "combinations")}
            onMouseLeave={() => unhighlight([21, 24], "combinations")}
          />
          <rect
            x="425"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("20,21", "combinations")}
            onMouseEnter={() => highlight([20, 21], "combinations")}
            onMouseLeave={() => unhighlight([20, 21], "combinations")}
          />
          <rect
            x="452.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("20,21,23,24", "combinations")}
            onMouseEnter={() => highlight([20, 21, 23, 24], "combinations")}
            onMouseLeave={() => unhighlight([20, 21, 23, 24], "combinations")}
          />
          <rect
            x="419"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("20", "tableNumbers")}
            onMouseEnter={() => highlight([20], "tableNumbers")}
            onMouseLeave={() => unhighlight([20], "tableNumbers")}
          />
          <rect
            x="452.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("20,23", "combinations")}
            onMouseEnter={() => highlight([20, 23], "combinations")}
            onMouseLeave={() => unhighlight([20, 23], "combinations")}
          />
          <rect
            x="425"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("19,20", "combinations")}
            onMouseEnter={() => highlight([19, 20], "combinations")}
            onMouseLeave={() => unhighlight([19, 20], "combinations")}
          />
          <rect
            x="452.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("19,20,22,23", "combinations")}
            onMouseEnter={() => highlight([19, 20, 22, 23], "combinations")}
            onMouseLeave={() => unhighlight([19, 20, 22, 23], "combinations")}
          />
          <rect
            x="419"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("19", "tableNumbers")}
            onMouseEnter={() => highlight([19], "tableNumbers")}
            onMouseLeave={() => unhighlight([19], "tableNumbers")}
          />
          <rect
            x="452.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("19,22", "combinations")}
            onMouseEnter={() => highlight([19, 22], "combinations")}
            onMouseLeave={() => unhighlight([19, 22], "combinations")}
          />
          <rect
            x="473"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("24", "tableNumbers")}
            onMouseEnter={() => highlight([24], "tableNumbers")}
            onMouseLeave={() => unhighlight([24], "tableNumbers")}
          />
          <rect
            x="479"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("22,23,24", "combinations")}
            onMouseEnter={() => highlight([22, 23, 24], "combinations")}
            onMouseLeave={() => unhighlight([22, 23, 24], "combinations")}
          />
          <rect
            x="506.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("22,23,24,25,26,27", "combinations")}
            onMouseEnter={() =>
              highlight([22, 23, 24, 25, 26, 27], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([22, 23, 24, 25, 26, 27], "combinations")
            }
          />
          <rect
            x="506.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("24,27", "combinations")}
            onMouseEnter={() => highlight([24, 27], "combinations")}
            onMouseLeave={() => unhighlight([24, 27], "combinations")}
          />
          <rect
            x="479"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("23,24", "combinations")}
            onMouseEnter={() => highlight([23, 24], "combinations")}
            onMouseLeave={() => unhighlight([23, 24], "combinations")}
          />
          <rect
            x="506.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("23,24,26,27", "combinations")}
            onMouseEnter={() => highlight([23, 24, 26, 27], "combinations")}
            onMouseLeave={() => unhighlight([23, 24, 26, 27], "combinations")}
          />
          <rect
            x="473"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("23", "tableNumbers")}
            onMouseEnter={() => highlight([23], "tableNumbers")}
            onMouseLeave={() => unhighlight([23], "tableNumbers")}
          />
          <rect
            x="506.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("23,26", "combinations")}
            onMouseEnter={() => highlight([23, 26], "combinations")}
            onMouseLeave={() => unhighlight([23, 26], "combinations")}
          />
          <rect
            x="479"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("22,23", "combinations")}
            onMouseEnter={() => highlight([22, 23], "combinations")}
            onMouseLeave={() => unhighlight([22, 23], "combinations")}
          />
          <rect
            x="506.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("22,23,25,26", "combinations")}
            onMouseEnter={() => highlight([22, 23, 25, 26], "combinations")}
            onMouseLeave={() => unhighlight([22, 23, 25, 26], "combinations")}
          />
          <rect
            x="473"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("22", "tableNumbers")}
            onMouseEnter={() => highlight([22], "tableNumbers")}
            onMouseLeave={() => unhighlight([22], "tableNumbers")}
          />
          <rect
            x="506.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("22,25", "combinations")}
            onMouseEnter={() => highlight([22, 25], "combinations")}
            onMouseLeave={() => unhighlight([22, 25], "combinations")}
          />
          <rect
            x="527"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("27", "tableNumbers")}
            onMouseEnter={() => highlight([27], "tableNumbers")}
            onMouseLeave={() => unhighlight([27], "tableNumbers")}
          />
          <rect
            x="533"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("25,26,27", "combinations")}
            onMouseEnter={() => highlight([25, 26, 27], "combinations")}
            onMouseLeave={() => unhighlight([25, 26, 27], "combinations")}
          />
          <rect
            x="560.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("25,26,27,28,29,30", "combinations")}
            onMouseEnter={() =>
              highlight([25, 26, 27, 28, 29, 30], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([25, 26, 27, 28, 29, 30], "combinations")
            }
          />
          <rect
            x="560.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("27,30", "combinations")}
            onMouseEnter={() => highlight([27, 30], "combinations")}
            onMouseLeave={() => unhighlight([27, 30], "combinations")}
          />
          <rect
            x="533"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("26,27", "combinations")}
            onMouseEnter={() => highlight([26, 27], "combinations")}
            onMouseLeave={() => unhighlight([26, 27], "combinations")}
          />
          <rect
            x="560.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("26,27,29,30", "combinations")}
            onMouseEnter={() => highlight([26, 27, 29, 30], "combinations")}
            onMouseLeave={() => unhighlight([26, 27, 29, 30], "combinations")}
          />
          <rect
            x="527"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("26", "tableNumbers")}
            onMouseEnter={() => highlight([26], "tableNumbers")}
            onMouseLeave={() => unhighlight([26], "tableNumbers")}
          />
          <rect
            x="560.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("26,29", "combinations")}
            onMouseEnter={() => highlight([26, 29], "combinations")}
            onMouseLeave={() => unhighlight([26, 29], "combinations")}
          />
          <rect
            x="533"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("25,26", "combinations")}
            onMouseEnter={() => highlight([25, 26], "combinations")}
            onMouseLeave={() => unhighlight([25, 26], "combinations")}
          />
          <rect
            x="560.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("25,26,28,29", "combinations")}
            onMouseEnter={() => highlight([25, 26, 28, 29], "combinations")}
            onMouseLeave={() => unhighlight([25, 26, 28, 29], "combinations")}
          />
          <rect
            x="527"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("25", "tableNumbers")}
            onMouseEnter={() => highlight([25], "tableNumbers")}
            onMouseLeave={() => unhighlight([25], "tableNumbers")}
          />
          <rect
            x="560.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("25,28", "combinations")}
            onMouseEnter={() => highlight([25, 28], "combinations")}
            onMouseLeave={() => unhighlight([25, 28], "combinations")}
          />
          <rect
            x="581"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("30", "tableNumbers")}
            onMouseEnter={() => highlight([30], "tableNumbers")}
            onMouseLeave={() => unhighlight([30], "tableNumbers")}
          />
          <rect
            x="587"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("28,29,30", "combinations")}
            onMouseEnter={() => highlight([28, 29, 30], "combinations")}
            onMouseLeave={() => unhighlight([28, 29, 30], "combinations")}
          />
          <rect
            x="614.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("28,29,30,31,32,33", "combinations")}
            onMouseEnter={() =>
              highlight([28, 29, 30, 31, 32, 33], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([28, 29, 30, 31, 32, 33], "combinations")
            }
          />
          <rect
            x="614.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("30,33", "combinations")}
            onMouseEnter={() => highlight([30, 33], "combinations")}
            onMouseLeave={() => unhighlight([30, 33], "combinations")}
          />
          <rect
            x="587"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("29,30", "combinations")}
            onMouseEnter={() => highlight([29, 30], "combinations")}
            onMouseLeave={() => unhighlight([29, 30], "combinations")}
          />
          <rect
            x="614.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("29,30,32,33", "combinations")}
            onMouseEnter={() => highlight([29, 30, 32, 33], "combinations")}
            onMouseLeave={() => unhighlight([29, 30, 32, 33], "combinations")}
          />
          <rect
            x="581"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("29", "tableNumbers")}
            onMouseEnter={() => highlight([29], "tableNumbers")}
            onMouseLeave={() => unhighlight([29], "tableNumbers")}
          />
          <rect
            x="614.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("29,32", "combinations")}
            onMouseEnter={() => highlight([29, 32], "combinations")}
            onMouseLeave={() => unhighlight([29, 32], "combinations")}
          />
          <rect
            x="587"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("28,29", "combinations")}
            onMouseEnter={() => highlight([28, 29], "combinations")}
            onMouseLeave={() => unhighlight([28, 29], "combinations")}
          />
          <rect
            x="614.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("28,29,31,32", "combinations")}
            onMouseEnter={() => highlight([28, 29, 31, 32], "combinations")}
            onMouseLeave={() => unhighlight([28, 29, 31, 32], "combinations")}
          />
          <rect
            x="581"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("28", "tableNumbers")}
            onMouseEnter={() => highlight([28], "tableNumbers")}
            onMouseLeave={() => unhighlight([28], "tableNumbers")}
          />
          <rect
            x="614.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("28,31", "combinations")}
            onMouseEnter={() => highlight([28, 31], "combinations")}
            onMouseLeave={() => unhighlight([28, 31], "combinations")}
          />
          <rect
            x="635"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("33", "tableNumbers")}
            onMouseEnter={() => highlight([33], "tableNumbers")}
            onMouseLeave={() => unhighlight([33], "tableNumbers")}
          />
          <rect
            x="641"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("31,32,33", "combinations")}
            onMouseEnter={() => highlight([31, 32, 33], "combinations")}
            onMouseLeave={() => unhighlight([31, 32, 33], "combinations")}
          />
          <rect
            x="668.5"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("31,32,33,34,35,36", "combinations")}
            onMouseEnter={() =>
              highlight([31, 32, 33, 34, 35, 36], "combinations")
            }
            onMouseLeave={() =>
              unhighlight([31, 32, 33, 34, 35, 36], "combinations")
            }
          />
          <rect
            x="668.5"
            y="27"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("33,36", "combinations")}
            onMouseEnter={() => highlight([33, 36], "combinations")}
            onMouseLeave={() => unhighlight([33, 36], "combinations")}
          />
          <rect
            x="641"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("32,33", "combinations")}
            onMouseEnter={() => highlight([32, 33], "combinations")}
            onMouseLeave={() => unhighlight([32, 33], "combinations")}
          />
          <rect
            x="668.5"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("32,33,35,36", "combinations")}
            onMouseEnter={() => highlight([32, 33, 35, 36], "combinations")}
            onMouseLeave={() => unhighlight([32, 33, 35, 36], "combinations")}
          />
          <rect
            x="635"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("32", "tableNumbers")}
            onMouseEnter={() => highlight([32], "tableNumbers")}
            onMouseLeave={() => unhighlight([32], "tableNumbers")}
          />
          <rect
            x="668.5"
            y="81"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("32,35", "combinations")}
            onMouseEnter={() => highlight([32, 35], "combinations")}
            onMouseLeave={() => unhighlight([32, 35], "combinations")}
          />
          <rect
            x="641"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("31,32", "combinations")}
            onMouseEnter={() => highlight([31, 32], "combinations")}
            onMouseLeave={() => unhighlight([31, 32], "combinations")}
          />
          <rect
            x="668.5"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("31,32,34,35", "combinations")}
            onMouseEnter={() => highlight([31, 32, 34, 35], "combinations")}
            onMouseLeave={() => unhighlight([31, 32, 34, 35], "combinations")}
          />
          <rect
            x="635"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("31", "tableNumbers")}
            onMouseEnter={() => highlight([31], "tableNumbers")}
            onMouseLeave={() => unhighlight([31], "tableNumbers")}
          />
          <rect
            x="668.5"
            y="135"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("31,34", "combinations")}
            onMouseEnter={() => highlight([31, 34], "combinations")}
            onMouseLeave={() => unhighlight([31, 34], "combinations")}
          />
          <rect
            x="689"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("36", "tableNumbers")}
            onMouseEnter={() => highlight([36], "tableNumbers")}
            onMouseLeave={() => unhighlight([36], "tableNumbers")}
          />
          <rect
            x="695"
            y="0.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("34,35,36", "combinations")}
            onMouseEnter={() => highlight([34, 35, 36], "combinations")}
            onMouseLeave={() => unhighlight([34, 35, 36], "combinations")}
          />
          <rect
            x="695"
            y="54.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("35,36", "combinations")}
            onMouseEnter={() => highlight([35, 36], "combinations")}
            onMouseLeave={() => unhighlight([35, 36], "combinations")}
          />
          <rect
            x="689"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("35", "tableNumbers")}
            onMouseEnter={() => highlight([35], "tableNumbers")}
            onMouseLeave={() => unhighlight([35], "tableNumbers")}
          />
          <rect
            x="695"
            y="108.5"
            width="20"
            height="20"
            rx="10"
            fill={overlay}
            onClick={() => toggleChips("34,35", "combinations")}
            onMouseEnter={() => highlight([34, 35], "combinations")}
            onMouseLeave={() => unhighlight([34, 35], "combinations")}
          />
          <rect
            x="689"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("34", "tableNumbers")}
            onMouseEnter={() => highlight([34], "tableNumbers")}
            onMouseLeave={() => unhighlight([34], "tableNumbers")}
          />
          <rect
            x="743"
            y="21"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("3rd2-1", "commands")}
            onMouseEnter={() => highlight("3rd2-1", "commands")}
            onMouseLeave={() => unhighlight("3rd2-1", "commands")}
          />
          <rect
            x="743"
            y="75"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("2nd2-1", "commands")}
            onMouseEnter={() => highlight("2nd2-1", "commands")}
            onMouseLeave={() => unhighlight("2nd2-1", "commands")}
          />
          <rect
            x="743"
            y="129"
            width="32"
            height="32"
            fill={overlay}
            onClick={() => toggleChips("1st2-1", "commands")}
            onMouseEnter={() => highlight("1st2-1", "commands")}
            onMouseLeave={() => unhighlight("1st2-1", "commands")}
          />
        </g>
      </svg>
    );
  }
}

Table.propTypes = {
  bets: PropTypes.array.isRequired,
  betAmount: PropTypes.string.isRequired,
  maxBetSize: PropTypes.string.isRequired,
  minBetSize: PropTypes.string.isRequired,
  tableNumbers: PropTypes.object.isRequired,
  toggleChips: PropTypes.func.isRequired,
  disableControls: PropTypes.bool.isRequired,
  setExceededMaxBetSize: PropTypes.func.isRequired,
  setRouletteMaxBetSize: PropTypes.func.isRequired,
  highlight: PropTypes.func.isRequired,
  unhighlight: PropTypes.func.isRequired,
  commands: PropTypes.object.isRequired,
  highlightColor: PropTypes.string.isRequired,
  combinations: PropTypes.object.isRequired
};

const mapStateToProps = ({ gameRoulette, rouletteConstants, gamesConstants }) => ({
  bets: gameRoulette.bets,
  tableNumbers: gameRoulette.tableNumbers,
  commands: gameRoulette.commands,
  combinations: gameRoulette.combinations,
  highlightColor: gameRoulette.highlightColor,
  disableControls: gameRoulette.disableControls,
  betAmount: rouletteConstants.betAmount,
  maxBetSize: rouletteConstants.maxBetSize,
  minBetSize: gamesConstants.minBetSize
});

const mapDispatchToProps = {
  setRouletteMaxBetSize,
  setExceededMaxBetSize,
  toggleChips,
  highlight,
  unhighlight
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
