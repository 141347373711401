import {
  SET_ACTIVE_GAME,
  SET_BET_WITH_CONTRACT_BALANCE,
  SET_BET_WITH_CONTRACT_BONUS_BALANCE,
  SET_FIRST_BET,
  IS_NEW_LEVEL_REQUEST,
  IS_NEW_LEVEL_SUCCESS,
  IS_NEW_LEVEL_ERROR,
  SET_GAMES_PAUSED,
  SET_INIT_DATA_REQUEST,
  SET_INIT_DATA_SUCCESS,
  SET_INIT_DATA_ERROR,
  MOVE_MATIC_ETH_TO_CONTRACT_REQUEST,
  MOVE_MATIC_ETH_TO_CONTRACT_SUCCESS,
  MOVE_MATIC_ETH_TO_CONTRACT_ERROR,
  GET_AUTO_BET_MAX_BETS_REQUEST,
  GET_AUTO_BET_MAX_BETS_SUCCESS,
  GET_AUTO_BET_MAX_BETS_ERROR,
} from "../../actionTypes/games/games";
import { ASSOCIATE_WITH_AFF_CODE_SUCCESS } from "../../actionTypes/account";
import { connectToSocket } from "../chat";
import {
  getUserBonusContractBalance,
  getUserContractBalance,
  getUsername,
  getWalletBalance,
} from "../account";
import { weiToEth, giveInfiniteApproval } from "../../services/ethereum/main";
import { getWETHApproval } from "../account";

import { sendTx } from "../txNotifications";
import { formatNumber } from "../../services/utils";
import { DP } from "../../config/constants";
import { listenForDoubleOrNothingFinished } from "./doubleOrNothing";
import {
  getBetsAllowedFromContract,
  getUsersBalanceFromContract,
  listenToGamePausedFromContract,
  moveMaticEthToContractFromContract,
  withdrawUsersFundsFromContract,
} from '../../services/ethereum/games';
import { getAccRollTotalBets, getRollInitialData } from "./roll";
import { getAccSpinTotalBets, getSpinInitialData } from "./spin";
import {getAccRouletteTotalBets, getRouletteInitialData} from "./roulette";
import { toggleModal } from "../modal";
import { notify } from "../notification";
import BigNumber from "bignumber.js";
import {
  getPlayerLevel,
  getUsersBadTokenBalance,
  getUsersEthereumBadTokenBalance,
  getUsersStakedBadTokenBalance,
  initBadTokenListeners
} from "../badToken";
import { initLocalStorage } from "../../services/localStorage";
import {getGBS, getGCL, getGWP, getHouseEdgeGlobal, getMinBetSize} from '../contractConstants/games';
import { getPlayerLevelFromContract } from "../../services/ethereum/badToken";
import {
  getAutoBetMaxBetsFromApi,
  getUserDataFromApi,
  getUserTitleFromApi,
  postUserTitleFromApi,
  postWithdrewEth,
} from '../../services/api';
import { getChanceAndMultiplierDuplicatedFromContract } from "../contractConstants/roulette";
import { getAccScratchTotalBets, getScratchInitialData } from "./scratch";
import { DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION } from '../../actionTypes/games/roll';
import { DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION } from '../../actionTypes/games/spin';
import { DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION } from '../../actionTypes/games/scratch';
import { DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION } from '../../actionTypes/games/roulette';

export const listenToGamePaused = () => (dispatch, getState) => {
  const callback = ({ bool }) => {
    dispatch({ type: SET_GAMES_PAUSED, payload: bool });
    if (!bool) {
      const { activeGame } = getState().games;
      if (activeGame === "roll") dispatch(getRollInitialData());
      if (activeGame === "spin") dispatch(getSpinInitialData());
      if (activeGame === "scratch") dispatch(getScratchInitialData());
      if(activeGame === 'roulette') dispatch(getRouletteInitialData());
    }
  };
  listenToGamePausedFromContract(callback);
};

export const setGamesPaused = () => async (dispatch) => {
  try {
    const games = await getBetsAllowedFromContract();
    dispatch({ type: SET_GAMES_PAUSED, payload: !games });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Sets listeners for all games, checks for a username,
 * and checks for registered cookie/connects to socket
 *
 * @return {Function}
 */
export const setInitData = () => async (dispatch, getState) => {
  dispatch({ type: SET_INIT_DATA_REQUEST })
  try {
    dispatch(listenForDoubleOrNothingFinished());
    dispatch(listenToGamePaused());
    dispatch(initBadTokenListeners());

    await dispatch(getMinBetSize());
    await dispatch(getGWP());
    await dispatch(getGBS());
    await dispatch(getGCL());

    await dispatch(setGamesPaused());

    await dispatch(getUserContractBalance());
    await dispatch(getUserBonusContractBalance());
    await dispatch(getAccTotalBets());
    await dispatch(getHouseEdgeGlobal());
    await dispatch(getPlayerLevel());
    await dispatch(getAutoBetMaxBets());

    initLocalStorage(dispatch, getState);

    await dispatch(getUsername());
    const { account } = getState().account;
    const { isRegistered } = getState().account;
    if (!isRegistered) return;

    const { usesAffiliateCode } = await getUserDataFromApi(account);
    if (usesAffiliateCode) {
      dispatch({ type: ASSOCIATE_WITH_AFF_CODE_SUCCESS, payload: usesAffiliateCode });
    }
    dispatch({ type: SET_INIT_DATA_SUCCESS });
    dispatch(connectToSocket());
  } catch (err) {
    console.log(err);
    dispatch({ type: SET_INIT_DATA_ERROR, payload: err.message })
  }
};

export const checkForNewLevel = (lvl, getState) => {
  try {
    const { playerLevel } = getState().badToken;
    if (Number(playerLevel) === 100) return false;
    return Number(lvl) !== Number(playerLevel);
  } catch (e) {
    console.log(e);
  }
};

export const addNewLevelModal = () => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_NEW_LEVEL_REQUEST });
    const { account } = getState().account;

    const level = await getPlayerLevelFromContract(account);
    const isNewLevel = checkForNewLevel(level, getState);
    if (isNewLevel) {
      const titleNumber = await getUserTitleFromApi(account);
      if (titleNumber === null) await postUserTitleFromApi(level, account);
      dispatch({
        type: IS_NEW_LEVEL_SUCCESS,
        payload: { isNewLevel, newLevel: level }
      });
    } else {
      dispatch({
        type: IS_NEW_LEVEL_SUCCESS,
        payload: { isNewLevel, newLevel: 0 }
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: IS_NEW_LEVEL_ERROR, payload: e.message });
  }
};

/**
 * Sets the name of the active game
 *
 * @param payload
 * @return {Function}
 */
export const setActiveGame = payload => dispatch => {
  dispatch({ type: SET_ACTIVE_GAME, payload });
};

/**
 * Sets if betting with contract balance is selected
 *
 * @param payload
 * @return {Function}
 */
export const setBetWithContractBalance = payload => dispatch => {
  try {
    dispatch({ type: SET_BET_WITH_CONTRACT_BALANCE, payload });
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets if betting with contract bonus balance is selected
 *
 * @param payload
 * @return {Function}
 */
export const setBetWithContractBonusBalance = payload => dispatch => {
  try {
    dispatch({ type: SET_BET_WITH_CONTRACT_BONUS_BALANCE, payload });
  } catch (e) {
    console.log(e);
  }
};

export const moveMaticEthToContract = (amount) => async (dispatch, getState) => {
  dispatch({ type: MOVE_MATIC_ETH_TO_CONTRACT_REQUEST });
  try {
    const state = getState();
    const { account } = state.account;
    const { modalOpen } = state.modal;
    if (modalOpen) dispatch(toggleModal());
    const { wethApproval } = getState().account;

    if(Number(wethApproval) < Number(amount)) {
      await giveInfiniteApproval(account);
      await dispatch(getWETHApproval(account));
    }

    await moveMaticEthToContractFromContract(amount, account, sendTx, dispatch, getState);
    dispatch({ type: MOVE_MATIC_ETH_TO_CONTRACT_SUCCESS });
    setTimeout(() => dispatch(getWalletBalance(account)), 3000);
    dispatch(getUserContractBalance());
  } catch (error) {
    console.log(error);
    dispatch({ type: MOVE_MATIC_ETH_TO_CONTRACT_ERROR, payload: error.message });
  }
}

/**
 *
 * Withdraws user funds from contract balance
 *
 * @param amount
 * @return {Function}
 */
export const withdrawUsersFunds = amount => async (dispatch, getState) => {
  const state = getState();
  const { account } = state.account;
  const { modalOpen } = state.modal;
  if (modalOpen) dispatch(toggleModal());
  await withdrawUsersFundsFromContract(
    sendTx,
    account,
    amount,
    dispatch,
    getState
  );
  setTimeout(() => dispatch(getWalletBalance(account)), 3000);
  dispatch(getUserContractBalance());

  await postWithdrewEth(amount);
};

export const getAccTotalBets = () => async (dispatch, getState) => {
  await dispatch(getAccRollTotalBets());
  await dispatch(getAccSpinTotalBets());
  await dispatch(getAccScratchTotalBets());
  await dispatch(getAccRouletteTotalBets());

  const state = getState();
  const { rollTotalBets } = state.gameRoll;
  const { spinTotalBets } = state.gameSpin;
  const { scratchTotalBets } = state.gameScratch;
  const { rouletteTotalBets } = state.gameRoulette;

  const payload =
    ![rollTotalBets, spinTotalBets, scratchTotalBets, rouletteTotalBets].filter(i => i > 0)
      .length > 0;
  dispatch({ type: SET_FIRST_BET, payload });
};

export const checkIfRanOutOfContractBalanceWhileAutoBetting = (account, betAmount) => async (dispatch) => {
  try {
    const contractBalance = await getUsersBalanceFromContract(account);
    const didRanOutOfContractBalance = new BigNumber(contractBalance).lt(betAmount);
    if (didRanOutOfContractBalance) {
      notify(`Auto-Bet stopped due to insufficient funds on contract balance.`, "error")(dispatch);
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkIsRunningOutOfContractBalanceWhileAutoBetting =
  (account, betAmount, numberOfBets) => async (dispatch, getState) => {
    try {
      const notificationNumber = numberOfBets > 5 ? 5 : numberOfBets - 1;
      if (notificationNumber !== 0) {
        const contractBalance = await getUsersBalanceFromContract(account);
        const amount = new BigNumber(betAmount).times(notificationNumber);
        const shouldNotify = new BigNumber(contractBalance).lt(amount);
        const { modalOpen } = getState().modal;
        if (shouldNotify && !modalOpen) {
          dispatch(toggleModal("AutoBetBalanceNotification"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const dismissAutBetBalanceNotificationModal = () => async (dispatch, getState) => {
  const { activeGame } = getState().games;
  if (activeGame === "roll") dispatch({ type: DISMISS_ROLL_AUTO_BET_BALANCE_NOTIFICATION });
  if (activeGame === "spin") dispatch({ type: DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION });
  if (activeGame === "scratch") dispatch({ type: DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION });
  if (activeGame === "roulette") dispatch({ type: DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION });
};

/**
 *
 * @param betAmount
 * @param minBetValue
 * @param maxBetValue
 * @param roundState
 * @param winChance
 * @param maxChanceForBonusBet
 * @param betWithContractBalance
 * @param betWithContractBonusBalance
 * @param userContractBalance
 * @param userContractBonusBalance
 * @param walletBalance
 * @param errType
 * @param dispatch
 * @param isAutoBet
 */
export const validatePlaceBet = (
  betAmount,
  minBetValue,
  maxBetValue,
  roundState,
  winChance,
  maxChanceForBonusBet,
  betWithContractBalance,
  betWithContractBonusBalance,
  userContractBalance,
  userContractBonusBalance,
  walletBalance,
  errType,
  dispatch,
  isAutoBet = false
) => {
  if (!isAutoBet && roundState === "closed") {
    notify("Betting is closed currently", "error")(dispatch);
    dispatch({ type: errType });
    return false;
  }

  if (
    new BigNumber(minBetValue).gt(betAmount) ||
    new BigNumber(maxBetValue).lt(betAmount)
  ) {
    notify(
      `Invalid bet size, it needs to be between ${formatNumber(
        Number(weiToEth(minBetValue)),
        DP
      )} and ${formatNumber(weiToEth(maxBetValue), DP)} ETH.`,
      "error"
    )(dispatch);
    dispatch({ type: errType });
    return false;
  }

  if (isAutoBet) {
    if (new BigNumber(userContractBalance).lt(betAmount)) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    return true;
  }

  if (betWithContractBalance || betWithContractBonusBalance) {
    if (
      betWithContractBalance &&
      new BigNumber(userContractBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    if (
      betWithContractBonusBalance &&
      new BigNumber(userContractBonusBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on bonus balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }

    const adjustedWinChance = winChance > 100 ? winChance / 100 : winChance;

    if (
      betWithContractBonusBalance &&
      maxChanceForBonusBet <= adjustedWinChance
    ) {
      notify(
        `Bonus balance funds are eligible for bets with a win chance of under ${maxChanceForBonusBet}%`,
        "error"
      )(dispatch);
      dispatch({ type: errType });
      return false;
    }
  } else {
    if (new BigNumber(walletBalance).lt(betAmount)) {
      notify(`Insufficient funds on your Matic ETH balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
  }
  return true;
};

export const validatePlaceBetForRoulette = (
  betsArr,
  betAmount,
  maxChanceForBonusBet,
  betWithContractBalance,
  betWithContractBonusBalance,
  userContractBalance,
  userContractBonusBalance,
  walletBalance,
  errType,
  dispatch,
  isAutoBet = false,
) => {
  if (!betsArr.length) {
    notify(`You haven't placed any bets yet.`, "error")(dispatch);
    dispatch({ type: errType });
    return false;
  }

  if (isAutoBet) {
    if (new BigNumber(userContractBalance).lt(betAmount)) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    return true;
  }

  if (betWithContractBalance || betWithContractBonusBalance) {
    if (
      betWithContractBalance &&
      new BigNumber(userContractBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    if (
      betWithContractBonusBalance &&
      new BigNumber(userContractBonusBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on bonus balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }

    for (let item of betsArr) {
      const { chance } = getChanceAndMultiplierDuplicatedFromContract(item);
      const winChance = chance / 100;
      const adjustedWinChance = winChance > 100 ? winChance / 100 : winChance;

      console.log(winChance, maxChanceForBonusBet);

      if (
        betWithContractBonusBalance &&
        maxChanceForBonusBet <= adjustedWinChance
      ) {
        notify(
          `Bonus balance funds are eligible for bets with a win chance of under ${maxChanceForBonusBet}%`,
          "error"
        )(dispatch);
        dispatch({ type: errType });
        return false;
      }
    }

  } else {
    if (new BigNumber(walletBalance).lt(betAmount)) {
      notify(`Insufficient funds on your Matic ETH balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
  }
  return true;
};

/**
 *
 * @param betAmount
 * @param costPerCard
 * @param minBetValue
 * @param maxBetValue
 * @param roundState
 * @param winChance
 * @param maxChanceForBonusBet
 * @param betWithContractBalance
 * @param betWithContractBonusBalance
 * @param userContractBalance
 * @param userContractBonusBalance
 * @param walletBalance
 * @param errType
 * @param dispatch
 * @param isAutoBet
 */
export const validatePlaceBetForScratch = (
  betAmount,
  costPerCard,
  minBetValue,
  maxBetValue,
  roundState,
  winChance,
  maxChanceForBonusBet,
  betWithContractBalance,
  betWithContractBonusBalance,
  userContractBalance,
  userContractBonusBalance,
  walletBalance,
  errType,
  dispatch,
  isAutoBet
) => {
  if (!isAutoBet && roundState === "closed") {
    notify("Betting is closed currently", "error")(dispatch);
    dispatch({ type: errType });
    return false;
  }

  if (
    new BigNumber(minBetValue).gt(costPerCard) ||
    new BigNumber(maxBetValue).lt(costPerCard)
  ) {
    notify(
      `Invalid cost per card amount, it needs to be between ${formatNumber(
        Number(weiToEth(minBetValue)),
        DP
      )} and ${formatNumber(weiToEth(maxBetValue), DP)} ETH.`,
      "error"
    )(dispatch);
    dispatch({ type: errType });
    return false;
  }

  if (isAutoBet) {
    if (new BigNumber(userContractBalance).lt(betAmount)) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    return true;
  }

  if (betWithContractBalance || betWithContractBonusBalance) {
    if (
      betWithContractBalance &&
      new BigNumber(userContractBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on contract balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
    if (
      betWithContractBonusBalance &&
      new BigNumber(userContractBonusBalance).lt(betAmount)
    ) {
      notify(`Insufficient funds on bonus balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }

    if (betWithContractBonusBalance && maxChanceForBonusBet <= winChance) {
      notify(
        `Bonus balance funds are eligible for bets with a win chance of under ${maxChanceForBonusBet}%`,
        "error"
      )(dispatch);
      dispatch({ type: errType });
      return false;
    }
  } else {
    if (new BigNumber(walletBalance).lt(betAmount)) {
      notify(`Insufficient funds on your Matic ETH balance.`, "error")(dispatch);
      dispatch({ type: errType });
      return false;
    }
  }
  return true;
};

export const getAutoBetMaxBets = () => async (dispatch) => {
  dispatch({ type: GET_AUTO_BET_MAX_BETS_REQUEST })
  try {
    const data = await getAutoBetMaxBetsFromApi();
    if (data.status === 200) {
      dispatch({ type: GET_AUTO_BET_MAX_BETS_SUCCESS, payload: data.maxBets })
    } else {
      dispatch({ type: GET_AUTO_BET_MAX_BETS_ERROR, payload: "There was an error while getting data" })
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_AUTO_BET_MAX_BETS_ERROR, payload: error.message })
  }
};
