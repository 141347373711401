import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { breakAffiliation } from "../../../../actions/account";
import { toggleModal } from "../../../../actions/modal";

import "./BreakAffiliation.scss";

const BreakAffiliation = ({ breakAffiliation, toggleModal, currentAffCode } ) =>
  (<div className="modal__content modal__content--break-affiliation">
    {currentAffCode !== "" && (
      <p className="description">
        Currently associated with affiliate code: <span>{currentAffCode}</span>
      </p>
    )}
    <p className="title">Break your affiliation</p>
    <div className="btn__group">
      <button
        className="btn btn--primary"
        onClick={() => breakAffiliation()}
      >
        Break
      </button>
      <button
        className="btn btn--dark"
        onClick={() => toggleModal()}
      >
        Close
      </button>
    </div>
  </div>);

BreakAffiliation.propTypes = {
  breakAffiliation: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currentAffCode: PropTypes.string.isRequired
};

const mapDispatchToProps = {
  breakAffiliation,
  toggleModal
};

const mapStateToProps = ({ account }) => ({
  currentAffCode: account.usesAffCode
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreakAffiliation);
