import {
  GET_MAX_NUM_DON_IN_ROW_ERROR,
  GET_MAX_NUM_DON_IN_ROW_REQUEST,
  GET_MAX_NUM_DON_IN_ROW_SUCCESS,
  SET_DOUBLE_OR_NOTHING_RES,
  SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT
} from "../../actionTypes/games/doubleOrNothing";
import { setRollLastWinPrize } from "./roll";
import { setSpinLastWinPrize } from "./spin";
import { setRouletteLastWinPrize } from "./roulette";
import {
  getUserBonusContractBalance,
  getUserContractBalance
} from "../account";
import {
  doubleOrNothingFromContract,
  getMaxDonsInRowFromContract,
  listenToDoubleOrNothingFinishedFromContract
} from "../../services/ethereum/doubleOrNothing";
import { changeModalType, toggleModal } from "../modal";
import { calculateWinPrize } from "../contractConstants/roll";
import { notify } from "../notification";
import {
  rollGameAddress,
  spinGameAddress,
  rouletteGameAddress,
  scratchGameAddress,
} from "../../services/contract";
import { addNewLevelModal } from "./games";
import {addBadTokenWinNotification, areTokensDistributed, getUsersEthereumBadTokenBalance} from '../badToken';
import { setScratchWinningPrize } from "./scratch";
import { weiToEth } from '../../services/ethereum/main';

/**
 * Sets max bet chance for bonus betting from contract
 *
 * @return {Function}
 */
export const getMaxDonsInRow = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_NUM_DON_IN_ROW_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getMaxDonsInRowFromContract(account);
    dispatch({ type: GET_MAX_NUM_DON_IN_ROW_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_MAX_NUM_DON_IN_ROW_ERROR, payload: err.message });
  }
};

/**
 * Plays a Double Or Nothing game type
 *
 * @return {Function}
 */
export const setDoubleOrNothing = () => async (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const { activeGame } = getState().games;

    let gameAddress;
    let winPrize;

    if (activeGame === "roll") {
      const { lastWinPrize } = getState().gameRoll;
      gameAddress = rollGameAddress;
      winPrize = lastWinPrize;
    }
    if (activeGame === "spin") {
      const { lastWinPrize } = getState().gameSpin;
      gameAddress = spinGameAddress;
      winPrize = lastWinPrize;
    }
    if (activeGame === "scratch") {
      const { winPrize: scratchPrize } = getState().gameScratch;
      gameAddress = scratchGameAddress;
      winPrize = scratchPrize;
    }
    if (activeGame === "roulette") {
      const { lastWinPrize } = getState().gameRoulette;
      gameAddress = rouletteGameAddress;
      winPrize = lastWinPrize;
    }

    await doubleOrNothingFromContract(
      account,
      changeModalType,
      toggleModal,
      notify,
      dispatch,
      winPrize,
      gameAddress,
      getState,
    );
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets a result for Double Or Nothing game type
 *
 * @param won
 * @return {Function}
 */
export const setDoubleOrNothingResult = won => (dispatch, getState) => {
  const state = getState();
  const { doubleOrNothingWinCount } = state.gameDoubleOrNothing;
  const { houseEdge } = state.gamesConstants;
  const { activeGame } = state.games;

  let winPrize;

  if (activeGame === "roll") {
    const { lastWinPrize } = getState().gameRoll;
    winPrize = lastWinPrize;
  }
  if (activeGame === "spin") {
    const { lastWinPrize } = getState().gameSpin;
    winPrize = lastWinPrize;
  }
  if (activeGame === "scratch") {
    const { winPrize: scratchPrize } = getState().gameScratch;
    winPrize = scratchPrize;
  }
  if (activeGame === "roulette") {
    const { lastWinPrize } = getState().gameRoulette;
    winPrize = lastWinPrize;
  }

  const donPrize = calculateWinPrize(winPrize, 50, houseEdge);

  console.log(donPrize, winPrize, houseEdge);

  if (won) {
    if (activeGame === "roll") dispatch(setRollLastWinPrize(donPrize));
    if (activeGame === "spin") dispatch(setSpinLastWinPrize(donPrize));
    if (activeGame === "scratch") dispatch(setScratchWinningPrize(donPrize));
    if (activeGame === "roulette") dispatch(setRouletteLastWinPrize(donPrize));
    dispatch({
      type: SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT,
      payload: doubleOrNothingWinCount + 1
    });
  } else {
    dispatch({
      type: SET_DOUBLE_OR_NOTHING_ROUND_WIN_COUNT,
      payload: 0
    });
  }

  dispatch({ type: SET_DOUBLE_OR_NOTHING_RES, payload: won });
};

/**
 * Checks if user can play Double Or Nothing game type
 * and changes a modal type
 *
 * @return {Function}
 */
export const canPlayDoubleOrNothingAgain = () => async (dispatch, getState) => {
  const state = getState();
  const { hasWonDoubleOrNothing } = state.gameDoubleOrNothing;
  dispatch(
    changeModalType(
      "WinLose",
      {
        hasWon: hasWonDoubleOrNothing,
        hideRes: true,
        showDonTips: true,
        modalTitle: "Double or Nothing",
        game: "double or nothing",
        isSide: true
      },
      true
    )
  );
};

/**
 * Makes a listener for DoubleOrNothingFinished contract event
 *
 * @return {Function}
 */
export const listenForDoubleOrNothingFinished = () => (dispatch, getState) => {
  const callback = async ({ won, tokensWon }) => {
    console.log("DON FINISHED CALLBACK");

    dispatch(addBadTokenWinNotification("0"));
    const { account } = getState().account;
    const areDistributed = await areTokensDistributed(account);
    if (!areDistributed) {
      dispatch(addBadTokenWinNotification(weiToEth(tokensWon)));
    }
    dispatch(setDoubleOrNothingResult(won));
    const { playerLevel } = getState().badToken;
    if (Number(playerLevel) < 100) await dispatch(addNewLevelModal());
    await dispatch(canPlayDoubleOrNothingAgain());
    await dispatch(getUserContractBalance());
    await dispatch(getUserBonusContractBalance());
    await dispatch(getUsersEthereumBadTokenBalance())
  };

  listenToDoubleOrNothingFinishedFromContract(callback, getState);
};
