import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleModal } from '../../../../actions/modal';

import "./ConfirmAutoBet.scss";

class ConfirmAutoBet extends Component {
  render() {
    const { toggleModal, startAutoBet, numOfRounds } = this.props;
    return (
      <div className="modal__content modal__content--confirm-auto-bet">
        <p className="title">Start Auto-Bet</p>
        <div className="description">
          You are about to play a total of {numOfRounds} rounds automatically by providing authorization to our oracle software to use your Contract ETH on your behalf. By clicking 'Confirm' you have agreed to the following:
          <br /><br />
          1. There could be a response delay between the confirmation of your 'start auto-bet' transaction, and the actual start of auto-bet play, of up to several rounds. Also, during an auto-bet session it is possible for the oracle to skip rounds every now and then due to network latency or other potential issues beyond our control. BadBit.Games will not refund any hypothetical winnings or actual losses that resulted from such occurrences.
          <br /><br />
          2. We cannot guarantee 100% uptime of the oracle software that manages your auto-betting. If this software malfunctions or goes offline during your auto-bet session, auto-betting will stop and BadBit.Games will not refund any hypothetical winnings that could have resulted had auto-betting continued uninterrupted.
          <br /><br />
          3. Once confirmed, your auto-betting play will continue in all circumstances unless you successfully submit a 'Cancel Auto-Bet' transaction to the Matic Network blockchain, or if there is an oracle software error. If you lose your network connection or exit your browser, you can always consult the 'bet history' section to see the outcomes of rounds that were played during your absence.
        </div>

        <div className="btn__group">
          <button className="btn btn--primary" onClick={() => {
            toggleModal();
            startAutoBet(numOfRounds);
          }}>
            Confirm
          </button>
          <button
            className="btn btn--dark"
            onClick={() => toggleModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

ConfirmAutoBet.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  startAutoBet: PropTypes.func.isRequired,
  numOfRounds: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  toggleModal
};

export default connect(
  null,
  mapDispatchToProps
)(ConfirmAutoBet);
