import React from "react";

const EvenOrOdd = () => {
  return (
    <div className="icon">
      <svg
        width="38"
        height="29"
        viewBox="0 0 38 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75 6.74219H1.82031V10.7734H7.54688V12H0.320312V0.625H7.46875V1.85938H1.82031V5.51562H6.75V6.74219Z"
          fill="black"
        />
        <path
          d="M13.1797 10.0078L16.4297 0.625H18.0703L13.8516 12H12.5234L8.3125 0.625H9.94531L13.1797 10.0078Z"
          fill="black"
        />
        <path
          d="M26.0312 6.74219H21.1016V10.7734H26.8281V12H19.6016V0.625H26.75V1.85938H21.1016V5.51562H26.0312V6.74219Z"
          fill="black"
        />
        <path
          d="M37.4375 12H35.9297L30.2031 3.23438V12H28.6953V0.625H30.2031L35.9453 9.42969V0.625H37.4375V12Z"
          fill="black"
        />
        <path
          d="M13.0576 22.8457C13.0576 23.9603 12.8701 24.9342 12.4951 25.7676C12.1201 26.5957 11.5889 27.2285 10.9014 27.666C10.2139 28.1035 9.41178 28.3223 8.49512 28.3223C7.59928 28.3223 6.80501 28.1035 6.1123 27.666C5.4196 27.2233 4.88053 26.5957 4.49512 25.7832C4.11491 24.9655 3.9196 24.0202 3.90918 22.9473V22.127C3.90918 21.0332 4.09928 20.0671 4.47949 19.2285C4.8597 18.39 5.39616 17.7493 6.08887 17.3066C6.78678 16.8587 7.58366 16.6348 8.47949 16.6348C9.39095 16.6348 10.193 16.8561 10.8857 17.2988C11.5837 17.7363 12.1201 18.3743 12.4951 19.2129C12.8701 20.0462 13.0576 21.0176 13.0576 22.127V22.8457ZM11.5654 22.1113C11.5654 20.7624 11.2946 19.7285 10.7529 19.0098C10.2113 18.2858 9.45345 17.9238 8.47949 17.9238C7.53158 17.9238 6.78418 18.2858 6.2373 19.0098C5.69564 19.7285 5.41699 20.7285 5.40137 22.0098V22.8457C5.40137 24.153 5.6748 25.1816 6.22168 25.9316C6.77376 26.6764 7.53158 27.0488 8.49512 27.0488C9.46387 27.0488 10.2139 26.6973 10.7451 25.9941C11.2764 25.2858 11.5498 24.2728 11.5654 22.9551V22.1113Z"
          fill="black"
        />
        <path
          d="M15.3076 28.166V16.791H18.5186C19.5081 16.791 20.3831 17.0098 21.1436 17.4473C21.904 17.8848 22.4899 18.5072 22.9014 19.3145C23.318 20.1217 23.529 21.0488 23.5342 22.0957V22.8223C23.5342 23.8952 23.3258 24.8353 22.9092 25.6426C22.4977 26.4499 21.9066 27.0697 21.1357 27.502C20.3701 27.9342 19.4769 28.1556 18.4561 28.166H15.3076ZM16.8076 18.0254V26.9395H18.3857C19.542 26.9395 20.4404 26.5801 21.0811 25.8613C21.7269 25.1426 22.0498 24.1191 22.0498 22.791V22.127C22.0498 20.8353 21.7451 19.8327 21.1357 19.1191C20.5316 18.4004 19.6722 18.0358 18.5576 18.0254H16.8076Z"
          fill="black"
        />
        <path
          d="M25.8076 28.166V16.791H29.0186C30.0081 16.791 30.8831 17.0098 31.6436 17.4473C32.404 17.8848 32.9899 18.5072 33.4014 19.3145C33.818 20.1217 34.029 21.0488 34.0342 22.0957V22.8223C34.0342 23.8952 33.8258 24.8353 33.4092 25.6426C32.9977 26.4499 32.4066 27.0697 31.6357 27.502C30.8701 27.9342 29.9769 28.1556 28.9561 28.166H25.8076ZM27.3076 18.0254V26.9395H28.8857C30.042 26.9395 30.9404 26.5801 31.5811 25.8613C32.2269 25.1426 32.5498 24.1191 32.5498 22.791V22.127C32.5498 20.8353 32.2451 19.8327 31.6357 19.1191C31.0316 18.4004 30.1722 18.0358 29.0576 18.0254H27.3076Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default EvenOrOdd;
