import React from "react";

export default function BetHistoryIcon({ size = 16, color = "#FFFFFF" }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.42001 0C2.93767 0 0.918138 2.01937 0.91845 4.48891C0.916575 4.52812 0.893919 5.18187 1.08001 5.88594L0.473606 5.5325C0.322981 5.445 0.131106 5.49578 0.042669 5.64609C-0.0445185 5.79609 0.00610645 5.98906 0.156263 6.07656L1.57736 6.905C1.58033 6.90656 1.58329 6.90703 1.58611 6.90844C1.60126 6.91656 1.61673 6.9225 1.63314 6.92812C1.64423 6.93203 1.65501 6.93609 1.66611 6.93875C1.6797 6.94172 1.69314 6.94266 1.70704 6.94391C1.71673 6.94484 1.72642 6.94781 1.73611 6.94781C1.74158 6.94781 1.74673 6.94594 1.75236 6.94562C1.75767 6.94547 1.7622 6.94672 1.76751 6.94625C1.77533 6.94547 1.78236 6.94219 1.79001 6.94094C1.80517 6.93828 1.81954 6.935 1.83408 6.93031C1.84626 6.92625 1.85829 6.92141 1.87001 6.91578C1.88314 6.90953 1.89548 6.90266 1.90767 6.89453C1.91876 6.88734 1.92939 6.87953 1.93939 6.87109C1.95048 6.86172 1.96033 6.85156 1.97017 6.84062C1.97939 6.83047 1.98736 6.81984 1.99501 6.80859C1.99923 6.80234 2.00486 6.79781 2.00861 6.79141C2.01142 6.78656 2.01142 6.78094 2.01376 6.77609C2.01798 6.7675 2.02454 6.76 2.02829 6.75078L2.59361 5.35109C2.65876 5.18984 2.58095 5.00625 2.41986 4.94109C2.25845 4.87641 2.07501 4.95391 2.00986 5.11516L1.71954 5.83391C1.52579 5.18594 1.54783 4.52016 1.54845 4.50187C1.54845 2.36687 3.28548 0.63 5.42017 0.63C7.55501 0.63 9.29189 2.36687 9.29189 4.50187C9.29189 6.63656 7.55501 8.37344 5.42017 8.37344C5.24611 8.37344 5.10517 8.51453 5.10517 8.68844C5.10517 8.86234 5.24595 9.00344 5.42017 9.00344C7.90267 9.00344 9.92204 6.98406 9.92204 4.50187C9.92173 2.01937 7.90236 0 5.42001 0V0Z"
        fill="white"
      />
      <path
        d="M5 2.48203V4.98203L6.5 6.48203"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </svg>
  );
}
