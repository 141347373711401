import React from "react";

const RollUnder = () => {
  return (
    <div className="icon">
      <svg
        width="23"
        height="20"
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.417 19.4089L22.834 0.000116348L7.4176e-05 0.000114352L11.417 19.4089ZM11.417 16.0313L19.8397 1.71266L2.99431 1.71266L11.417 16.0313Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default RollUnder;
