import {
  GET_MAX_BET_CHANCE_BONUS_ERROR,
  GET_MAX_BET_CHANCE_BONUS_REQUEST,
  GET_MAX_BET_CHANCE_BONUS_SUCCESS,
  GET_WIN_PROBABILITY_SUCCESS,
  GET_HOUSE_EDGE_GLOBAL_REQUEST,
  GET_HOUSE_EDGE_GLOBAL_SUCCESS,
  GET_HOUSE_EDGE_GLOBAL_ERROR,
  GET_GWP_REQUEST,
  GET_GWP_SUCCESS,
  GET_GWP_ERROR,
  GET_GBS_REQUEST,
  GET_GBS_SUCCESS,
  GET_GBS_ERROR,
  GET_GCL_REQUEST,
  GET_GCL_SUCCESS,
  GET_GCL_ERROR,
  GET_MIN_BET_SIZE_REQUEST,
  GET_MIN_BET_SIZE_SUCCESS,
  GET_MIN_BET_SIZE_ERROR,
} from "../../actionTypes/contractConstants/games";

const INITIAL_STATE = {
  maxChanceForBonusBet: 0,
  gettingMaxBetChanceForBonusBet: false,
  gettingMaxBetChanceForBonusBetErr: "",

  GBS: "0",
  GWP: "0",
  GCL: "0",

  minBetSize: "0",
  gettingMinBetSize: false,
  gettingMinBetSizeErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MAX_BET_CHANCE_BONUS_REQUEST:
      return {
        ...state,
        gettingMaxBetChanceForBonusBet: true,
        gettingMaxBetChanceForBonusBetErr: ""
      };

    case GET_MAX_BET_CHANCE_BONUS_SUCCESS:
      return {
        ...state,
        maxChanceForBonusBet: payload,
        gettingMaxBetChanceForBonusBet: false,
        gettingMaxBetChanceForBonusBetErr: ""
      };

    case GET_MAX_BET_CHANCE_BONUS_ERROR:
      return {
        ...state,
        gettingMaxBetChanceForBonusBet: false,
        gettingMaxBetChanceForBonusBetErr: payload
      };

    case GET_WIN_PROBABILITY_SUCCESS:
      return {
        ...state,
        minWinProb: payload.minWinProb,
        maxWinProb: payload.maxWinProb
      };

    case GET_HOUSE_EDGE_GLOBAL_REQUEST:
      return {
        ...state,
        gettingHouseEdge: true,
        gettingHouseEdgeError: ""
      };

    case GET_HOUSE_EDGE_GLOBAL_SUCCESS:
      return {
        ...state,
        houseEdge: payload,
        gettingHouseEdge: false,
        gettingHouseEdgeError: ""
      };

    case GET_HOUSE_EDGE_GLOBAL_ERROR:
      return {
        ...state,
        gettingHouseEdge: false,
        gettingHouseEdgeError: payload
      };

    case GET_GWP_REQUEST: return {
      ...state,
      gettingGWP: true,
      gettingGWPError: '',
    };

    case GET_GWP_SUCCESS: return {
      ...state,
      GWP: payload,
      gettingGWP: false,
      gettingGWPError: '',
    };

    case GET_GWP_ERROR: return {
      ...state,
      gettingGWP: false,
      gettingGWPError: payload,
    };

    case GET_GBS_REQUEST: return {
      ...state,
      gettingGBS: true,
      gettingGBSError: '',
    };

    case GET_GBS_SUCCESS: return {
      ...state,
      GBS: payload,
      gettingGBS: false,
      gettingGBSError: '',
    };

    case GET_GBS_ERROR: return {
      ...state,
      gettingGBS: false,
      gettingGBSError: payload,
    };

    case GET_GCL_REQUEST: return {
      ...state,
      gettingGCL: true,
      gettingGCLError: '',
    };

    case GET_GCL_SUCCESS: return {
      ...state,
      GCL: payload,
      gettingGCL: false,
      gettingGCLError: '',
    };

    case GET_GCL_ERROR: return {
      ...state,
      gettingGCL: false,
      gettingGCLError: payload,
    };

    case GET_MIN_BET_SIZE_REQUEST: return {
      ...state,
      gettingMinBetSize: true,
      gettingMinBetSizeErr: "",
    };

    case GET_MIN_BET_SIZE_SUCCESS: return {
      ...state,
      minBetSize: payload,
      gettingMinBetSize: false,
      gettingMinBetSizeErr: "",
    };

    case GET_MIN_BET_SIZE_ERROR: return {
      ...state,
      gettingMinBetSize: false,
      gettingMinBetSizeErr: payload,
    };

    default:
      return state;
  }
};
