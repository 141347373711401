import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveGame } from "../../../actions/games/games";
import {getSpinInitialData, startSpinAutoBet, stopSpinAutoBet} from '../../../actions/games/spin';

import Controls from "./Controls/Controls";
import Details from "../Shared/Details/Details";
import History from "../Shared/History/History";
import Players from "../Shared/Players/Players";
import GamePaused from "../Shared/GamePaused/GamePaused";
import "./SpinGame.scss";
import UserDisabled from "../Shared/UserDisabled/UserDisabled";
import AutoBet from '../Shared/AutoBet/AutoBet';

class SpinGame extends Component {
  componentDidMount() {
    this.props.setActiveGame("spin");
    this.props.getSpinInitialData();
    document.title = "SPIN | BadBit.Games";
  }
  render() {
    const {
      gettingRoundTime,
      gettingRoundState,
      gettingGameState,
      gettingGameStateErr,
      roundState,
      gamesPaused,
      spinPaused,
      userStatus,
      isAutoBetting,
      autoBettingRounds,
      autoBettingCurrentRound,
      startSpinAutoBet,
      stopSpinAutoBet,
      startingAutoBetting,
      stoppingAutoBetting,
    } = this.props;
    return (
      <Fragment>
        <main className={`main ${gamesPaused || spinPaused || userStatus.status === "disabled" ? "center" : ""}`}>
          <div className="container">
            {userStatus.status !== "disabled" &&  !gamesPaused && !spinPaused && (
              <Fragment>
                <Details
                  gettingTime={gettingRoundTime}
                  gettingState={gettingRoundState}
                  state={roundState}
                />
                <History
                  gettingGameState={gettingGameState}
                  gettingGameStateErr={gettingGameStateErr}
                />
                <Controls />
                <AutoBet
                  startingAutoBetting={startingAutoBetting}
                  stoppingAutoBetting={stoppingAutoBetting}
                  startAutoBet={startSpinAutoBet}
                  stopAutoBet={stopSpinAutoBet}
                  isAutoBetting={isAutoBetting}
                  autoBettingRounds={autoBettingRounds}
                  autoBettingCurrentRound={autoBettingCurrentRound}
                />
                <Players
                  gettingState={gettingGameState}
                  gettingStateErr={gettingGameStateErr}
                  state={roundState}
                />
              </Fragment>
            )}
            {(gamesPaused || spinPaused) && <GamePaused />}
            {!(gamesPaused || spinPaused) && userStatus.status === "disabled" && <UserDisabled />}
          </div>
        </main>
      </Fragment>
    );
  }
}

SpinGame.propTypes = {
  setActiveGame: PropTypes.func.isRequired,
  getSpinInitialData: PropTypes.func.isRequired,
  gettingRoundState: PropTypes.bool.isRequired,
  gettingRoundTime: PropTypes.bool.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  gettingGameStateErr: PropTypes.string.isRequired,
  roundState: PropTypes.string.isRequired,
  gamesPaused: PropTypes.bool.isRequired,
  spinPaused: PropTypes.bool.isRequired,
  startSpinAutoBet: PropTypes.func.isRequired,
  stopSpinAutoBet: PropTypes.func.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
  stoppingAutoBetting: PropTypes.bool.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  autoBettingRounds: PropTypes.number.isRequired,
  autoBettingCurrentRound: PropTypes.number.isRequired,
};

const mapStateToProps = ({ gameSpin, spinConstants, games, account }) => ({
  roundState: gameSpin.roundState,
  gettingRoundState: gameSpin.gettingRoundState,
  gettingGameState: gameSpin.gettingGameState,
  gettingGameStateErr: gameSpin.gettingGameStateErr,
  gettingRoundTime: spinConstants.gettingRoundTime,
  spinPaused: gameSpin.spinPaused,
  gamesPaused: games.gamesPaused,
  userStatus: account.userStatus,
  isAutoBetting: gameSpin.isAutoBetting,
  autoBettingRounds: gameSpin.autoBettingRounds,
  autoBettingCurrentRound: gameSpin.autoBettingCurrentRound,
  startingAutoBetting: gameSpin.startingAutoBetting,
  stoppingAutoBetting: gameSpin.stoppingAutoBetting,
});

const mapDispatchToProps = {
  setActiveGame,
  getSpinInitialData,
  startSpinAutoBet,
  stopSpinAutoBet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpinGame);
