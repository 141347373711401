export const GET_SPIN_GAME_STATE_REQUEST = "GET_SPIN_GAME_STATE_REQUEST";
export const GET_SPIN_GAME_STATE_SUCCESS = "GET_SPIN_GAME_STATE_SUCCESS";
export const GET_SPIN_GAME_STATE_ERROR = "GET_SPIN_GAME_STATE_ERROR";

export const GET_SPIN_ROUND_STATE_REQUEST = "GET_SPIN_ROUND_STATE_REQUEST";
export const GET_SPIN_ROUND_STATE_SUCCESS = "GET_SPIN_ROUND_STATE_SUCCESS";
export const GET_SPIN_ROUND_STATE_ERROR = "GET_SPIN_ROUND_STATE_ERROR";

export const SET_SPIN_BET_AMOUNT = "SET_SPIN_BET_AMOUNT";
export const SET_SPIN_GAME_TYPE = "SET_SPIN_GAME_TYPE";
export const SET_SPIN_WIN_CHANCE = "SET_SPIN_WIN_CHANCE";
export const SET_SPIN_WIN_PRIZE = "SET_SPIN_WIN_PRIZE";
export const SET_SPIN_LAST_WIN_PRIZE = "SET_SPIN_LAST_WIN_PRIZE";
export const SET_SPIN_AUTO_BET_DATA = "SET_SPIN_AUTO_BET_DATA";

export const SET_SPIN_LAST_ROUND_PLAYED = "SET_SPIN_LAST_ROUND_PLAYED";

export const SET_SPIN_LAST_SELECTED_GAME_TYPE =
  "SET_SPIN_LAST_SELECTED_GAME_TYPE";
export const SET_SPIN_LAST_USER_SELECTED_GAME_TYPE =
  "SET_SPIN_LAST_USER_SELECTED_GAME_TYPE";

export const SET_SPIN_SHOW_SPINNER = "SET_SPIN_SHOW_SPINNER";
export const SET_SPIN_SHOW_CONTROLS = "SET_SPIN_SHOW_CONTROLS";

export const PLACE_SPIN_BET_REQUEST = "PLACE_SPIN_BET_REQUEST";
export const PLACE_SPIN_BET_ERROR = "PLACE_SPIN_BET_ERROR";
export const PLACE_SPIN_BET_SUCCESS = "PLACE_SPIN_BET_SUCCESS";

export const START_SPIN_AUTO_BET_REQUEST = "START_SPIN_AUTO_BET_REQUEST";
export const START_SPIN_AUTO_BET_SUCCESS = "START_SPIN_AUTO_BET_SUCCESS";
export const START_SPIN_AUTO_BET_ERROR = "START_SPIN_AUTO_BET_ERROR";

export const STOP_SPIN_AUTO_BET_REQUEST = "STOP_SPIN_AUTO_BET_REQUEST";
export const STOP_SPIN_AUTO_BET_SUCCESS = "STOP_SPIN_AUTO_BET_SUCCESS";
export const STOP_SPIN_AUTO_BET_ERROR = "STOP_SPIN_AUTO_BET_ERROR";

export const SPIN_AUTO_BET_STARTED = "SPIN_AUTO_BET_STARTED";
export const SPIN_AUTO_BET_STOPPED = "SPIN_AUTO_BET_STOPPED";

export const UPDATE_SPIN_AUTO_BET_REQUEST = "UPDATE_SPIN_AUTO_BET_REQUEST";
export const UPDATE_SPIN_AUTO_BET_SUCCESS = "UPDATE_SPIN_AUTO_BET_SUCCESS";
export const UPDATE_SPIN_AUTO_BET_ERROR = "UPDATE_SPIN_AUTO_BET_ERROR";

export const GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST =
  "GET_SPIN_LAST_ROUND_TIMESTAMP_REQUEST";
export const GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS =
  "GET_SPIN_LAST_ROUND_TIMESTAMP_SUCCESS";
export const GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR =
  "GET_SPIN_LAST_ROUND_TIMESTAMP_ERROR";

export const CLOSE_SPIN_ROUND = "CLOSE_SPIN_ROUND";

export const GET_ACC_SPIN_TOTAL_BETS_REQUEST =
  "GET_ACC_SPIN_TOTAL_BETS_REQUEST";
export const GET_ACC_SPIN_TOTAL_BETS_SUCCESS =
  "GET_ACC_SPIN_TOTAL_BETS_SUCCESS";
export const GET_ACC_SPIN_TOTAL_BETS_ERROR = "GET_ACC_SPIN_TOTAL_BETS_ERROR";

export const SET_SPIN_PAUSED = "SET_SPIN_PAUSED";

export const SET_SPIN_LAST_ROUND_BLOCK = "SET_SPIN_LAST_ROUND_BLOCK";

export const SPIN_LISTENERS_INITIATED = "SPIN_LISTENERS_INITIATED";

export const GET_SPIN_INIT_DATA_REQUEST = "GET_SPIN_INIT_DATA_REQUEST";
export const GET_SPIN_INIT_DATA_SUCCESS = "GET_SPIN_INIT_DATA_SUCCESS";
export const GET_SPIN_INIT_DATA_ERROR = "GET_SPIN_INIT_DATA_ERROR";

export const DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION = "DISMISS_SPIN_AUTO_BET_BALANCE_NOTIFICATION";
