import {
  GET_PROMOTION_STATS_REQUEST,
  GET_PROMOTION_STATS_SUCCESS,
  GET_PROMOTION_STATS_ERROR,
  GET_PROMOTION_DATA_REQUEST,
  GET_PROMOTION_DATA_SUCCESS,
  GET_PROMOTION_DATA_ERROR,
} from "../actionTypes/promotionStats";
import {
  getPromotionDataFromApi,
  getPromotionStatsFromApi,
} from '../services/api';


export const getPromotionStats = () => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_STATS_REQUEST });
  try {
    await dispatch(getPromotionData());
    const payload = await getPromotionStatsFromApi();
    dispatch({ type: GET_PROMOTION_STATS_SUCCESS, payload: { data: payload.data } });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_PROMOTION_STATS_ERROR, payload: error.message });
  }
};

export const getPromotionData = () => async (dispatch) => {
  dispatch({ type: GET_PROMOTION_DATA_REQUEST });
  try {
    const payload = await getPromotionDataFromApi();
    dispatch({ type: GET_PROMOTION_DATA_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_PROMOTION_DATA_ERROR, payload: error.message });
  }
};
