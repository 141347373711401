import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setScratchNumOfCards } from "../../../../../actions/games/scratch";

import "./NumberOfCards.scss";

class NumberOfCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currNumOfCards: this.props.numberOfCards,
      inputWidth: this.props.numberOfCards.toString().length,
    };
    this.handleNumOfCards = this.handleNumOfCards.bind(this);
    this.handleBlurNumberOfCardsInput = this.handleBlurNumberOfCardsInput.bind(this);
    this.handleChangeNumberOfCardsInput = this.handleChangeNumberOfCardsInput.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.numberOfCards !== prevProps.numberOfCards) {
      this.setState({
        currNumOfCards: this.props.numberOfCards,
        inputWidth: this.props.numberOfCards.toString().length
      });
    }
  }

  handleBlurNumberOfCardsInput = (e) => {
    const { value } = e.target;
    if (Number(value) > this.props.maxNumOfCards) {
      this.setState({
        currNumOfCards: this.props.maxNumOfCards,
        inputWidth: this.props.maxNumOfCards.toString().length
      });
      return this.props.setScratchNumOfCards(this.props.maxNumOfCards);
    }
    if (Number(value) < 1) {
      this.setState({
        currNumOfCards: 1,
        inputWidth: 1
      });
      return this.props.setScratchNumOfCards(1);
    }
    this.props.setScratchNumOfCards(value);
  };

  handleChangeNumberOfCardsInput = (e) => {
    const { value } = e.target;
    this.setState({ currNumOfCards: Number(value), inputWidth: value.length });
  };

  handleNumOfCards = (number) => {
    this.props.setScratchNumOfCards(number);
  };

  render() {
    const {
      numberOfCards,
      maxNumOfCards,
      placingBet,
    } = this.props;
    return (
      <div className="main__section__body main__section__body--num-of-items">
        <header className="header">
          <p className="main__section__heading">Number of Cards</p>
          <span className="max-cards">Max {maxNumOfCards}</span>
        </header>
        <div className="card-controls">
          <button
            className="button"
            onClick={() =>
              this.handleNumOfCards(numberOfCards !== 1 ? numberOfCards - 1 : numberOfCards)
            }
            disabled={placingBet}
          >
            -
          </button>
          <input
            className="number-of-cards"
            type="number" step="1"
            min={1} max={maxNumOfCards}
            style={{ width: `${this.state.inputWidth + 3}ch`}}
            value={this.state.currNumOfCards}
            onBlur={this.handleBlurNumberOfCardsInput}
            onChange={this.handleChangeNumberOfCardsInput}
            disabled={placingBet}
          />
          <button
            className="button"
            onClick={() =>
              this.handleNumOfCards(numberOfCards !== maxNumOfCards ? numberOfCards + 1 : maxNumOfCards)
            }
            disabled={placingBet}
          >
            +
          </button>
          <button
            className="button max"
            onClick={() => this.handleNumOfCards(maxNumOfCards)}
            disabled={placingBet}
          >
            Max
          </button>
        </div>
      </div>
    );
  }
}

NumberOfCards.propTypes = {
  numberOfCards: PropTypes.number.isRequired,
  maxNumOfCards: PropTypes.number.isRequired,
  setScratchNumOfCards: PropTypes.func.isRequired,
  placingBet: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameScratch, scratchConstants }) => ({
  numberOfCards: gameScratch.numberOfCards,
  maxNumOfCards: scratchConstants.maxNumOfCards,
});

const mapDispatchToProps = { setScratchNumOfCards };

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfCards);
