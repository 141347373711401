import {
  SET_ROULETTE_PAUSED,
  TOGGLE_BETS,
  TOGGLE_CHIPS,
  EXCEEDED_MAX_BET_SIZE,
  CHANGE_TABLE_HIGHLIGHT_COLOR,
  SET_TOTAL_BET_AMOUNT,
  SET_ROULETTE_SHOW_CONTROLS,
  PLACE_ROULETTE_BET_REQUEST,
  PLACE_ROULETTE_BET_SUCCESS,
  PLACE_ROULETTE_BET_ERROR,
  GET_ROULETTE_ROUND_STATE_REQUEST,
  GET_ROULETTE_ROUND_STATE_SUCCESS,
  GET_ROULETTE_ROUND_STATE_ERROR,
  GET_ROULETTE_GAME_STATE_REQUEST,
  GET_ROULETTE_GAME_STATE_SUCCESS,
  GET_ROULETTE_GAME_STATE_ERROR,
  GET_ACC_ROULETTE_TOTAL_BETS_REQUEST,
  GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS,
  GET_ACC_ROULETTE_TOTAL_BETS_ERROR,
  SET_ROULETTE_DISABLE_CONTROLS,
  SET_ROULETTE_LAST_WIN_PRIZE,
  SET_ROULETTE_LAST_SELECTED_NUMBER,
  CLEAR_TABLE,
  START_ROULETTE_AUTO_BET_ERROR,
  START_ROULETTE_AUTO_BET_REQUEST,
  START_ROULETTE_AUTO_BET_SUCCESS,
  STOP_ROULETTE_AUTO_BET_ERROR,
  STOP_ROULETTE_AUTO_BET_REQUEST,
  STOP_ROULETTE_AUTO_BET_SUCCESS,
  DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION,
  ROULETTE_LISTENERS_INITIATED,
} from "../../actionTypes/games/roulette";
import rouletteTable from "../../config/rouletteTable.json";


const tableData = JSON.parse(JSON.stringify(rouletteTable));

const INITIAL_STATE = {
  roulettePaused: false,

  gettingGameState: false,
  gettingGameStateErr: "",

  gettingRouletteTotalBets: false,
  gettingRouletteTotalBetsErr: "",
  rouletteTotalBets: 0,

  bets: [],
  totalBetAmount: "0",
  lastWinPrize: "0",
  lastSelectedNumber: 0,

  placingBet: false,
  placingBetError: "",

  gettingRoundState: false,
  gettingRoundStateError: "",
  roundState: "",
  bettingRound: 0,

  showControls: true,
  disableControls: false,

  highlightColor: "#f4bd2421",
  exceededMaxBetSize: false,

  isAutoBetting: false,
  autoBettingRounds: 0,
  autoBettingCurrentRound: 1,
  autoBetBalanceNotificationDismissed: false,

  startingAutoBetting: false,
  startingAutoBettingErr: "",
  stoppingAutoBetting: false,
  stoppingAutoBettingErr: "",
  rouletteListenersInitiated: false,

  ...tableData
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PLACE_ROULETTE_BET_REQUEST:
      return { ...state, placingBet: true, placingBetError: "" };

    case PLACE_ROULETTE_BET_SUCCESS:
      return { ...state, placingBet: false, placingBetError: "" };

    case PLACE_ROULETTE_BET_ERROR:
      return { ...state, placingBet: false, placingBetError: payload || "" };

    case GET_ROULETTE_GAME_STATE_REQUEST:
      return { ...state, gettingGameState: true, gettingGameStateErr: "" };

    case GET_ROULETTE_GAME_STATE_SUCCESS:
      return { ...state, gettingGameState: false, gettingGameStateErr: "" };

    case GET_ACC_ROULETTE_TOTAL_BETS_REQUEST:
      return {
        ...state,
        gettingRouletteTotalBets: true,
        gettingRouletteTotalBetsErr: ""
      };

    case GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS:
      return {
        ...state,
        gettingRouletteTotalBets: false,
        gettingRouletteTotalBetsErr: "",
        rouletteTotalBets: payload
      };

    case GET_ACC_ROULETTE_TOTAL_BETS_ERROR:
      return {
        ...state,
        gettingRouletteTotalBets: false,
        gettingRouletteTotalBetsErr: payload
      };

    case GET_ROULETTE_GAME_STATE_ERROR:
      return {
        ...state,
        gettingGameState: false,
        gettingGameStateErr: payload
      };

    case GET_ROULETTE_ROUND_STATE_REQUEST:
      return { ...state, gettingRoundState: true, gettingRoundStateError: "" };

    case GET_ROULETTE_ROUND_STATE_SUCCESS:
      return {
        ...state,
        gettingRoundState: false,
        gettingRoundStateError: "",
        roundState: payload.status,
        bettingRound: payload.round
      };

    case GET_ROULETTE_ROUND_STATE_ERROR:
      return {
        ...state,
        gettingRoundState: false,
        gettingRoundStateError: payload
      };

    case SET_TOTAL_BET_AMOUNT:
      return { ...state, totalBetAmount: payload };

    case SET_ROULETTE_SHOW_CONTROLS:
      return { ...state, showControls: payload };

    case SET_ROULETTE_DISABLE_CONTROLS:
      return { ...state, disableControls: payload };

    case CHANGE_TABLE_HIGHLIGHT_COLOR:
      return { ...state, highlightColor: payload };

    case TOGGLE_BETS:
      return { ...state, bets: payload };

    case TOGGLE_CHIPS:
      return { ...state, ...payload };

    case EXCEEDED_MAX_BET_SIZE:
      return {
        ...state,
        exceededMaxBetSize: payload
      };

    case SET_ROULETTE_PAUSED:
      return {
        ...state,
        roulettePaused: payload
      };


    case SET_ROULETTE_LAST_WIN_PRIZE: return {
      ...state,
      lastWinPrize: payload
    };

    case SET_ROULETTE_LAST_SELECTED_NUMBER: return {
      ...state,
      lastSelectedNumber: payload
    };

    case CLEAR_TABLE: return {
      ...state,
      ...(JSON.parse(JSON.stringify(rouletteTable)))
    };

    case START_ROULETTE_AUTO_BET_REQUEST: return {
      ...state,
      startingAutoBetting: true,
      startingAutoBettingErr: "",
    };

    case START_ROULETTE_AUTO_BET_SUCCESS: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: +payload,
      startingAutoBetting: false,
      startingAutoBettingErr: "",
    };

    case START_ROULETTE_AUTO_BET_ERROR: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: payload,
    };

    case STOP_ROULETTE_AUTO_BET_REQUEST: return {
      ...state,
      stoppingAutoBetting: true,
      stoppingAutoBettingErr: "",
    };

    case STOP_ROULETTE_AUTO_BET_SUCCESS: return {
      ...state,
      stoppingAutoBetting: false,
      isAutoBetting: false,
      autoBettingRounds: 0,
      autoBettingCurrentRound: 1,
      stoppingAutoBettingErr: "",
    };

    case STOP_ROULETTE_AUTO_BET_ERROR: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: payload,
    };

    case DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION: return {
      ...state,
      autoBetBalanceNotificationDismissed: true,
    };

    case ROULETTE_LISTENERS_INITIATED: return {
      ...state,
      rouletteListenersInitiated: true,
    };

    default:
      return state;
  }
};
