import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { SELECT_CUSTOM_STYLE } from "../../../../config/constants";
import {
  setBetWithContractBalance,
  setBetWithContractBonusBalance
} from "../../../../actions/games/games";

const selectOptions = [
  { value: "contract", label: "Contract ETH" },
  { value: "wallet", label: "Matic ETH" },
  { value: "bonus", label: "Bonus ETH" }
];

class PlaceBet extends Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.state = {
      selectedOption: selectOptions[1]
    };
  }

  componentDidMount() {
    const { betWithContractBalance, betWithContractBonusBalance } = this.props;
    if (betWithContractBalance) {
      this.setState({
        selectedOption: selectOptions[0]
      });
    }
    if (betWithContractBonusBalance) {
      this.setState({
        selectedOption: selectOptions[2]
      });
    }
  }

  handleSelectChange = selectedOption => {
    const {
      setBetWithContractBalance,
      setBetWithContractBonusBalance,
    } = this.props;
    this.setState({ selectedOption });

    if (selectedOption.value === "wallet") {
      setBetWithContractBalance(false);
      setBetWithContractBonusBalance(false);
    }
    if (selectedOption.value === "contract") {
      setBetWithContractBalance(true);
    }
    if (selectedOption.value === "bonus") {
      setBetWithContractBonusBalance(true);
    }
  };

  render() {
    const { selectedOption } = this.state;
    const { gettingState, placeBet, buttonText, isAutoBetting } = this.props;

    let btnText = "Place Bet";
    if (buttonText) btnText = buttonText;
    if (isAutoBetting) btnText = "Auto-Betting in Progress";
    return (
      <div className="bet__place-bet">
        <button
          onClick={placeBet}
          className="btn btn--big btn--primary"
          disabled={gettingState || isAutoBetting}
          style={{ fontSize: isAutoBetting && '1.4rem' }}
        >
          {btnText}
        </button>
        <Select
          className="select"
          classNamePrefix="select"
          options={selectOptions}
          value={selectedOption}
          menuPlacement={"top"}
          disabled={gettingState || isAutoBetting}
          onChange={this.handleSelectChange}
          styles={SELECT_CUSTOM_STYLE}
          isSearchable={false}
        />
      </div>
    );
  }
}

PlaceBet.propTypes = {
  gettingState: PropTypes.bool.isRequired,
  placeBet: PropTypes.func.isRequired,
  betWithContractBalance: PropTypes.bool.isRequired,
  betWithContractBonusBalance: PropTypes.bool.isRequired,
  setBetWithContractBalance: PropTypes.func.isRequired,
  setBetWithContractBonusBalance: PropTypes.func.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
};

const mapStateToProps = ({ games }) => ({
  betWithContractBalance: games.betWithContractBalance,
  betWithContractBonusBalance: games.betWithContractBonusBalance,
});

const mapDispatchToProps = {
  setBetWithContractBalance,
  setBetWithContractBonusBalance
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceBet);
