import { TOGGLE_DRAWER_RIGHT, TOGGLE_DRAWER_LEFT } from "../actionTypes/drawer";

const INITIAL_STATE = {
  drawerRightOpen: false,
  drawerRightComponent: "",

  drawerLeftOpen: false,
  drawerLeftComponent: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_DRAWER_RIGHT:
      return {
        ...state,
        drawerRightOpen: payload.drawerRightOpen,
        drawerRightComponent: payload.drawerRightComponent
      };

    case TOGGLE_DRAWER_LEFT:
      return {
        ...state,
        drawerLeftOpen: payload.drawerLeftOpen,
        drawerLeftComponent: payload.drawerLeftComponent
      };

    default:
      return state;
  }
};
