import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { weiToEth } from "../../../../../services/ethereum/main";
import { formatNumber } from "../../../../../services/utils";
import { DP } from "../../../../../config/constants";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import FaceBook from "../../../../Decorative/SocialIcons/FaceBook";
import Twitter from "../../../../Decorative/SocialIcons/Twitter";
import InfoIcon from "../../../../Decorative/Icons/InfoIcon";
import { isNumberRedOrBlue } from "../../../../../actions/contractConstants/roulette";

const Roulette = ({
  title,
  shareUrl,
  hasWon,
  toggleModal,
  betHistory,
  winPrize,
  canPlayDoubleOrNothing,
  setDoubleOrNothing,
  showDonTips,
  maxNumOfDonInRow,
  lastSelectedNumber,
  badTokenWinChanceDon,
  badTokenExtraWinChance,
  badTokenTotalWinChanceDon,
  isAutoBetting,
}) => {
  return (
    <div className="modal__content modal__content--winlose">
      <div className="content">
        <ul className="roulette-bets">
          <div className="selected-number">
            Outcome:{" "}
            <span className={`${isNumberRedOrBlue(lastSelectedNumber)}`}>
            {lastSelectedNumber}
          </span>
          </div>
          {betHistory.map((item, key) => (
            <li
              key={`${item.betData.details}-${key}`}
              className={`bet ${item.hasWon ? "win" : "lose"}`}
            >
              <p className="result">
                <span>
                  {`${key + 1}# `} <span>{item.hasWon ? "Win" : "No win"}</span>
                </span>
                {item.hasWon && (
                  <span className="result-prize">
                    {formatNumber(weiToEth(item.winPrize), DP)}
                  </span>
                )}
              </p>
              <p className="details">
                <span className={`type ${item.betData.color}`}>
                  {item.betData.bet}
                </span>
                <span className="text">{item.betData.details}</span>
              </p>
            </li>
          ))}
        </ul>
        {hasWon && (
          <Tooltip title={weiToEth(winPrize)} theme="roll">
            <span className="total-win">Total Win:</span>
            <p className="prize">{formatNumber(weiToEth(winPrize), DP + 3)}</p>
          </Tooltip>
        )}
        {hasWon && (
          <div className="social">
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="some-network__share-button"
            >
              <FaceBook />
            </FacebookShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="some-network__share-button"
            >
              <Twitter />
            </TwitterShareButton>
          </div>
        )}
        {hasWon && showDonTips && canPlayDoubleOrNothing && (
          <p>Would you like to try again?</p>
        )}
        {hasWon && showDonTips && !canPlayDoubleOrNothing && (
          <p>You reached the limit of double or nothing attempts!</p>
        )}
      </div>
      <div className="btn__group">
        {!isAutoBetting && hasWon && canPlayDoubleOrNothing && (
          <button className="btn btn--primary" onClick={setDoubleOrNothing}>
            <Tooltip
              theme="roll"
              title={`Allows you to play another bet with a 50% chance to double the amount you won. Double or nothing can be played for maximum ${maxNumOfDonInRow} times in a row.`}
            >
              <InfoIcon />
            </Tooltip>{" "}
            Double Or Nothing
          </button>
        )}
        <button className="btn btn--dark" onClick={() => toggleModal()}>
          Done
        </button>
      </div>
      {!isAutoBetting && hasWon && canPlayDoubleOrNothing && (
        <div className="bet__data__single bet__data__single--bad-chance">
          <span>BAD Token Win Chance for Double or Nothing:</span>
          <span className="data">
          {badTokenWinChanceDon === "NaN" ? "0" : badTokenWinChanceDon}% + {badTokenExtraWinChance}% ={" "}
            {badTokenTotalWinChanceDon === "NaN" ? "0" : badTokenTotalWinChanceDon}%
            <Tooltip
              html={
                <ul className="tooltip__token-chance__list">
                  <li>
                    <p>BAD Token Win Chance Derived from Bet</p>{" "}
                    <span>{badTokenWinChanceDon}%</span>
                  </li>
                  <li>
                    <p>Additional Win Chance Derived from Player Level</p>{" "}
                    <span>{badTokenExtraWinChance}%</span>
                  </li>
                </ul>
              }
              theme="roll"
            >
            < InfoIcon color={"#465E71"} />
            </Tooltip>
          </span>
        </div>
      )}
    </div>
  );
};

Roulette.propTypes = {};

export default Roulette;
