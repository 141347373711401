export const GET_HOUSE_EDGE_REQUEST = "GET_HOUSE_EDGE_REQUEST";
export const GET_HOUSE_EDGE_SUCCESS = "GET_HOUSE_EDGE_SUCCESS";
export const GET_HOUSE_EDGE_ERROR = "GET_HOUSE_EDGE_ERROR";

export const GET_ROUND_TIME_REQUEST = "GET_ROUND_TIME_REQUEST";
export const GET_ROUND_TIME_SUCCESS = "GET_ROUND_TIME_SUCCESS";
export const GET_ROUND_TIME_ERROR = "GET_ROUND_TIME_ERROR";

export const GET_MAX_BET_VAL_REQUEST = "GET_MAX_BET_VAL_REQUEST";
export const GET_MAX_BET_VAL_SUCCESS = "GET_MAX_BET_VAL_SUCCESS";
export const GET_MAX_BET_VAL_ERROR = "GET_MAX_BET_VAL_ERROR";

export const GET_USE_DYNAMIC_MAX_BET_REQUEST =
  "GET_USE_DYNAMIC_MAX_BET_REQUEST";
export const GET_USE_DYNAMIC_MAX_BET_SUCCESS =
  "GET_USE_DYNAMIC_MAX_BET_SUCCESS";
export const GET_USE_DYNAMIC_MAX_BET_ERROR = "GET_USE_DYNAMIC_MAX_BET_ERROR";

export const GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST =
  "GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST";
export const GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS =
  "GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS";
export const GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR =
  "GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR";
