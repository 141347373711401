import { combineReducers } from "redux";

import account from "./account";

import games from "./games/games";
import gamesConstants from "./contractConstants/games";

import rollConstants from "./contractConstants/roll";
import gameRoll from "./games/roll";

import spinConstants from "./contractConstants/spin";
import gameSpin from "./games/spin";

import scratchConstants from "./contractConstants/scratch";
import gameScratch from "./games/scratch";

import rouletteConstants from "./contractConstants/roulette";
import gameRoulette from "./games/roulette";

import gameDoubleOrNothing from "./games/doubleOrNothing";

import badToken from "./badToken";
import roundTimer from "./roundTimer";
import modal from "./modal";
import drawer from "./drawer";
import chat from "./chat";
import pagination from "./pagination";
import betHistory from "./betHistory";
import playersActivity from "./playersActivity";
import playersHistory from "./playersHistory";
import assetMovementHistory from "./assetMovementHistory";
import leaderboardStats from "./leaderboardStats";
import promotionStats from "./promotionStats";
import notification from "./notification";
import txNotifications from "./txNotifications";
import withdrawals from "./withdrawals";

export default combineReducers({
  account,

  gamesConstants,
  games,

  rollConstants,
  gameRoll,

  spinConstants,
  gameSpin,

  scratchConstants,
  gameScratch,

  rouletteConstants,
  gameRoulette,

  gameDoubleOrNothing,

  badToken,
  roundTimer,
  modal,
  drawer,
  chat,
  pagination,
  playersHistory,
  betHistory,
  playersActivity,
  assetMovementHistory,
  leaderboardStats,
  promotionStats,
  notification,
  txNotifications,
  withdrawals
});
