import React, { Fragment } from "react";

const GameTypeInfo = ({ gameType }) => {
  return (
    <p className="controls__info">
      {gameType === 0 && (
        <Fragment>
          <span className="controls__name">Roll Over: </span>In Roll Over mode
          you win if the random number is greater than the number you picked.
        </Fragment>
      )}
      {gameType === 1 && (
        <Fragment>
          <span className="controls__name">Roll Under: </span>In Roll Under mode
          you win if the random number is smaller than the number you picked.
        </Fragment>
      )}
      {gameType === 3 && (
        <Fragment>
          <span className="controls__name">Odd or Even: </span> In Odd or Even
          mode you win by correctly guessing whether the random number will be
          odd or even. Please note that the number 0 (zero) is considered
          neither odd nor even.
        </Fragment>
      )}
      {gameType === 4 && (
        <Fragment>
          <span className="controls__name">Dragon Tiger: </span>In Dragon
          Tiger mode, you win by correctly guessing whether the random number
          will be a Dragon (digit on the left is greater than the digit on the
          right e.g. 83) or a Tiger (digit on the left is smaller than digit on
          the right e.g. 38), or a Tie (both digits are the same e.g. 11).
        </Fragment>
      )}
    </p>
  );
};

export default GameTypeInfo;
