import {
  SpinGameContract,
  SpinGameEventsContract,
  BadBitDistributorContract,
  ethersProvider,
  AutoBetContract,
  spinGameAddress,
  AutoBetEventsContract
} from "../contract";
import { getBlock, hexToNumber, numberToHex, weiToEth } from './main';
import { getUserDataFromApi } from "../api";
import BigNumber from "bignumber.js";

export const getGBMFromContract = async () => {
  const contract = await SpinGameContract();
  return contract.methods.GBM().call();
};

export const getStandardLotForGoldFromContract = async () => {
  const spinGameContract = await SpinGameContract();
  const tokenDistributorContract = await BadBitDistributorContract();

  const standardLotGoldModifier = await spinGameContract.methods.STANDARD_LOT_GOLD_MODIFIER().call();
  const standardLotSize = await tokenDistributorContract.methods.getStandardLot().call();

  return new BigNumber(weiToEth(standardLotSize)).times(standardLotGoldModifier).dividedBy(100).toString();
};

/**
 * Gets max bet value from contract settings
 *
 * @return {Promise<string>}
 */
export const getMaxBetFromSpinContract = async ( gameType, from ) => {
  const contract = await SpinGameContract();
  return await contract.methods.maxBetSize(gameType).call({from});
};

/**
 * Gets duration of the round from the contract settings
 *
 * @return {Promise<number>}
 */
export const getRoundTimeFromSpinContract = async ( from ) => {
  const contract = await SpinGameContract();
  const roundTime = await contract.methods.ROUND_TIME().call( {from} );
  return Number(roundTime);
};

/**
 * Gets benchmark maximum bet size from the contract settings
 *
 * @return {Promise<number>}
 */
export const getBenchmarkMaxBetSizeFromSpinContract = async (from) => {
  const contract = await SpinGameContract();
  const benchmarkMaximumBetSize = await contract.methods
    .BENCHMARK_MAXIMUM_BET_SIZE()
    .call({from});
  return Number(benchmarkMaximumBetSize);
};

/**
 * Gets total number of bets for the given address
 *
 * @param address
 * @return {Promise<number>}
 */
export const getTotalBetsFromSpinContract = async(address) => {
  const contract = await SpinGameContract();
  const totalBets = await contract.methods.getTotalBets(address).call();
  return Number(totalBets);
};

export const getTokensWonParametersFromSpinContract = async (address) => {
  const contract = await SpinGameContract();
  return contract.methods.getTokensWonParameters(address).call();
};

export const getAutobetInfo = async(player)=>{
  const autoBetContract = AutoBetContract();
  let betInfo = await autoBetContract.methods.getBetInfo(player, spinGameAddress).call();
  return betInfo;
}

/**
 * Gets result for a given address
 * and number of a played bet
 *
 * @param address
 * @param id
 * @return {Promise<{number, gameType, amount, selectedColor, won}>}
 */
export const getBetsFromSpinContract = async (address, id, from) => {
  const contract = await SpinGameContract();
  const bets = await contract.methods.getBet(address, id).call({ from });
  return bets;
};

/**
 * Gets last played round from the contract
 *
 * @return {Promise<{state, selectedColor, lastRound}>}
 */
export const getLastRoundFromSpinContract = (from) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await SpinGameContract();
      const lastRound = await contract.methods.lastRound().call({from});
      const { state, selectedNumber } = await contract.methods
        .rounds(lastRound)
        .call({from});
      resolve({ state, selectedNumber, lastRound });
    } catch (e) {
      reject(e);
    }
  });

/**
 * Places a regular bet for different game types
 * Also places a first bet if nickname is included
 *
 * @param betValue
 * @param amount
 * @param gameType
 * @param account
 * @param betWithContractBalance
 * @param betWithContractBonusBalance
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<any>}
 */
export const betFromSpinContract = (
  betValue,
  amount,
  gameType,
  account,
  betWithContractBalance,
  betWithContractBonusBalance,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      let promise;
      let functionSignature;
      const contract = await SpinGameContract();

      if (betWithContractBalance) {
        functionSignature = contract.methods.placeBetWithContractBalance(amount, gameType).encodeABI();
      } else if (betWithContractBonusBalance) {
        functionSignature = contract.methods.placeBonusBet(amount, gameType).encodeABI();
      } else {
        functionSignature = contract.methods.placeBet(amount, gameType).encodeABI();

      }

      var txObject = {
        functionSignature,
        contract,
        account
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Placing a bet...",
          successTitle: "Placed a bet.",
          errorTitle: "Bet denied.",
          notifyOf: "place-bet",
          game: "spin",
          amount,
          gameType,
          betValue
        },
        dispatch,
        getState
      );

      resolve(status);
    } catch (err) {
      reject(err);
    }
  });

export const getParamsForTokenCalculationFromSpinContract = async (chance, from) => {
  const contract = await SpinGameContract();
  const {
    gbs,
    gwp,
    maxB,
    minB
  } = await contract.methods.getParamsForTokenCaluclation(chance).call({from});
  return { gbs, gwp, maxB, minB };
};

/**
 * Gets starting block timestamp for a given round
 *
 * @param lastRound
 * @return {Promise<any>}
 */
export const getRoundTimestampFromSpinContract = (fromBlock, lastRound) =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(`SPIN LAST ROUND: #${lastRound} \n________________`);
      const filter = SpinGameEventsContract.filters.RoundStarted(
        numberToHex(lastRound)
      );
      filter.fromBlock = fromBlock;
      const logs = await ethersProvider.getLogs(filter);
      const blockNumber = logs[0].blockNumber;
      const { timestamp } = await getBlock(blockNumber);
      resolve(timestamp);
    } catch (err) {
      reject(err);
    }
  });

/**
 * Formats players activity retrieved from the contracts event
 *
 * @param data
 * @return {{gameType: (*|number), history: number[], account: (*|number|string), username: *}}
 */
const formatSingleSpinPlayerActivity = async data => {
  const { name } = await getUserDataFromApi(data.user);
  console.log("USER PLAYED: ", name, data.user);
  const playHistory = hexToNumber(data.gameTypes._hex)
    .toString()
    .split("")
    .map(Number);

  const winHistory = hexToNumber(data.history._hex)
    .toString()
    .split("")
    .map(Number);

  let history = [];

  winHistory.forEach(item => {
    history.push({ result: item });
  });

  playHistory.forEach((item, index) => {
    history[index].gameType = item;
  });

  return {
    account: data.user,
    gameType: data.gameType,
    history: history,
    username: name,
    amount: hexToNumber(data.amount._hex),
    round: hexToNumber(data.round._hex)
  };
};

/**
 * Gets players activity for a given round from the contracts event logs
 *
 * @param fromBlock
 * @param round
 * @return {Promise<any>}
 */
export const getOtherPlayersActivityFromSpinContract = (fromBlock, round) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = SpinGameEventsContract;
      const betEvent = contract.interface.events["BetPlayed"];
      const filter = contract.filters.BetPlayed(round, null);
      filter.fromBlock = fromBlock;

      let logs = await ethersProvider.getLogs(filter);
      logs = logs.map(log => betEvent.decode(log.data, log.topics));
      logs = logs.map(log => formatSingleSpinPlayerActivity(log));

      Promise.all(logs).then(logs => resolve(logs));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });


export const getSpinRoundFromContract = async (round) => {
  const contract = await SpinGameContract();
  return contract.methods.rounds(round).call();
};

/**
 * Gets previous selected numbers from the contract using RoundFinished event logs
 *
 * @param startRound
 * @param n
 * @return {Promise<any>}
 */
export const getPreviousSpinsFromRollContract = (startRound, n = 8) =>
  new Promise(async (resolve, reject) => {
    try {
      const rounds = [];

      for (let i = startRound; i > startRound - n; i--) {
        if (i > 0) rounds.push(i);
      }

      const promise = await Promise.all(rounds.map(async round => {
        const { selectedColor: color } = await getSpinRoundFromContract(round);
        return { round, color };
      }));

      resolve(promise);
    } catch (err) {
      reject(err);
    }
  });

/**
 * A listener for BetPlayed event
 *
 * @param callback
 */
export const listenToOtherPlayersActivityFromSpinContract = callback => {
  console.log("SPIN BET PLAYED EVENT OUT");
  SpinGameEventsContract.on(
    "BetPlayed",
    async (round, user, gameType, amount, history, gameTypes) => {
      console.log("SPIN BET PLAYED EVENT IN");
      callback(
        await formatSingleSpinPlayerActivity({
          user,
          gameType,
          gameTypes,
          amount,
          history,
          round
        })
      );
    }
  );
};

export const listenForBetPlayedEventFromSpinContract = (player, callback) => {
  let filter = SpinGameEventsContract.filters.BetPlayed(null, player);

  SpinGameEventsContract.on(filter, async (round, user, { blockNumber }) => {
    await callback(round, user, blockNumber);
  });
}

export const listenSpinAutoBetStartedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStarted(player, spinGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, noOfBets, {blockNumber}) => {
    await callback(user, game, noOfBets, blockNumber);
  })
}

export const listenSpinAutoBetStoppedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStopped(player, spinGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, remainingBets, {blockNumber}) => {
    await callback(user, game, remainingBets, blockNumber);
  })
}

/**
 * A listener for RoundStarted event
 *
 * @param callback
 */
export const listenToRoundStartedFromSpinContract = callback => {
  console.log("SPIN ROUND STARTED EVENT OUT");
  SpinGameEventsContract.on("RoundStarted", async (id, { blockNumber }) => {
    console.log("SPIN ROUND ID: ", hexToNumber(id._hex), "\nBLOCK: ", blockNumber);
    const { timestamp } = await getBlock(blockNumber);
    console.log("SPIN ROUND STARTED EVENT IN: ", timestamp);
    await callback({id: hexToNumber(id._hex), timestamp, blockNumber});
  });
};

/**
 * A listener for RoundFinished event
 *
 * @param callback
 */
export const listenToRoundFinishedFromSpinContract = callback => {
  console.log("SPIN ROUND FINISHED EVENT OUT");
  SpinGameEventsContract.on("RoundFinished", async (round, selectedColor, { blockNumber }) => {
    await callback({
      round: hexToNumber(round._hex),
      color: selectedColor
    });
    console.log("SPIN ROUND FINISHED EVENT IN", blockNumber);
  });
};

export const getSpinBetsAllowedFromContract = async () => {
  const contract = await SpinGameContract();
  return await contract.methods.BETS_ALLOWED().call();
};

export const listenToSpinPausedFromContract = callback => {
  console.log("SPIN PAUSED EVENT OUT");
  SpinGameEventsContract.on("GamePaused", async bool => {
    console.log("SPIN PAUSED EVENT IN");
    callback({ bool });
  });
};
