import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { transferAffiliateToken } from "../../../../actions/account";
import { toggleModal } from "../../../../actions/modal";

import "./TransferAffiliateToken.scss";
import {toChecksumAddress} from "../../../../services/utils";

class TransferAffiliateToken extends Component {
  constructor(props) {
    super(props);
    this.state = { toAddress: "", error: "" };

    this.handleAddressInput = this.handleAddressInput.bind(this);
  }

  handleAddressInput = e => {
    const { value } = e.target;
    try {
      if (value === "") {
        this.setState({ toAddress: value, error: "" });
        return;
      }
      if (value !== "" && toChecksumAddress(value)) {
        this.setState({ toAddress: value, error: "" });
      }
    } catch (error) {
      this.setState({ toAddress: value, error: "Given address is not a valid ETH address." });
    }
  };

  render() {
    const { transferAffiliateToken, toggleModal } = this.props;

    const disableSubmit =
      this.state.toAddress === "" || this.state.error !== "";

    return (
      <div className="modal__content modal__content--transfer-affiliate">
        <p className="title">Transfer your affiliate token:</p>
        <p className="description">You are transferring your affiliate token to another address! This is an irreversible move - you will lose control of your affiliate token and will cease to receive any affiliate earnings, including those accrued but not yet received in the current cycle! Please check the recipient address carefully, and proceed at your own risk.</p>
        <input
          className="input--nickname"
          type="text"
          placeholder="To address"
          onChange={this.handleAddressInput}
        />
        {this.state.error !== "" && <p className="input__error">{this.state.error}</p>}
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => transferAffiliateToken(this.state.toAddress)}
            disabled={disableSubmit}
          >
            Transfer
          </button>
          <button
            className="btn btn--dark"
            onClick={() => toggleModal()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

TransferAffiliateToken.propTypes = {
  transferAffiliateToken: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  transferAffiliateToken,
  toggleModal
};

const mapStateToProps = ({ account }) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferAffiliateToken);
