import React from "react";

export default function BadTokenIcon({ size = 12, color = "#FFFFFF" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM11.2556 6.01243C11.2556 7.83694 10.3237 9.4438 8.90991 10.383L7.99522 8.79868C8.86483 8.17871 9.4319 7.16179 9.4319 6.01243C9.4319 5.641 9.37268 5.28341 9.26317 4.94858L10.8653 4.0236C11.1169 4.63691 11.2556 5.30847 11.2556 6.01243ZM10.4463 3.21258C9.5171 1.74421 7.87862 0.769231 6.01243 0.769231C5.17287 0.769231 4.3794 0.966553 3.67587 1.31735L4.59058 2.90166C5.02355 2.70344 5.50509 2.59295 6.01243 2.59295C7.20359 2.59295 8.2525 3.20201 8.86473 4.1257L10.4463 3.21258ZM2.95199 7.53941C2.72223 7.07981 2.59295 6.56121 2.59295 6.01243C2.59295 4.95868 3.06958 4.01627 3.81902 3.38901L2.8981 1.79394C1.60663 2.74896 0.769231 4.2829 0.769231 6.01243C0.769231 6.89343 0.98652 7.72369 1.37043 8.45253L2.95199 7.53941ZM3.46652 8.29527C4.09256 8.99298 5.00124 9.4319 6.01243 9.4319C6.42552 9.4319 6.8215 9.35865 7.1881 9.22443L8.10908 10.8196C7.46699 11.1001 6.75788 11.2556 6.01243 11.2556C4.32495 11.2556 2.82366 10.4584 1.86462 9.22013L3.46652 8.29527ZM5.12933 3.73147C4.81925 3.45228 4.33306 3.73298 4.41981 4.14111L4.63217 5.14019C4.67097 5.32271 4.58783 5.50945 4.42623 5.60275L3.54167 6.11345C3.18032 6.32207 3.29704 6.87121 3.712 6.91482L4.72781 7.02159C4.91339 7.04109 5.0653 7.17788 5.10409 7.3604L5.31645 8.35948C5.40321 8.76761 5.96153 8.82629 6.13124 8.44511L6.54668 7.51202C6.62258 7.34155 6.79961 7.23934 6.98519 7.25885L8.00099 7.36561C8.41595 7.40923 8.6443 6.89636 8.33422 6.61717L7.57517 5.93372C7.4365 5.80886 7.394 5.60891 7.4699 5.43844L7.88534 4.50535C8.05505 4.12417 7.63785 3.74852 7.2765 3.95714L6.39194 4.46784C6.23034 4.56114 6.02704 4.53978 5.88837 4.41492L5.12933 3.73147Z"
        fill={color}
      />
    </svg>
  );
}
