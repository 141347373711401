import {
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST,
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS,
  GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS,
  GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR,
  ADD_OTHER_ROLL_PLAYER_ACTIVITY,
  ADD_OTHER_SPIN_PLAYER_ACTIVITY,
  RESET_OTHER_PLAYERS_ACTIVITY
} from "../actionTypes/playersActivity";

const INITIAL_STATE = {
  gettingOtherPlayersActivity: false,
  gettingOtherPlayersActivityError: "",
  otherPlayersActivity: []
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST:
      return {
        ...state,
        gettingOtherPlayersActivity: true,
        gettingOtherPlayersActivityError: ""
      };

    case GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS:
      return {
        ...state,
        gettingOtherPlayersActivity: false,
        gettingOtherPlayersActivityError: "",
        otherPlayersActivity: payload
      };

    case GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR:
      return {
        ...state,
        gettingOtherPlayersActivity: false,
        gettingOtherPlayersActivityError: payload
      };

    case GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST:
      return {
        ...state,
        gettingOtherPlayersActivity: true,
        gettingOtherPlayersActivityError: ""
      };

    case GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS:
      return {
        ...state,
        gettingOtherPlayersActivity: false,
        gettingOtherPlayersActivityError: "",
        otherPlayersActivity: payload
      };

    case GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR:
      return {
        ...state,
        gettingOtherPlayersActivity: false,
        gettingOtherPlayersActivityError: payload
      };

    case ADD_OTHER_ROLL_PLAYER_ACTIVITY:
      return {
        ...state,
        otherPlayersActivity: [...state.otherPlayersActivity, payload]
      };

    case ADD_OTHER_SPIN_PLAYER_ACTIVITY:
      return {
        ...state,
        otherPlayersActivity: [...state.otherPlayersActivity, payload]
      };

    case RESET_OTHER_PLAYERS_ACTIVITY:
      return {
        ...state,
        otherPlayersActivity: []
      };

    default:
      return state;
  }
};
