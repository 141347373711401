import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatAcc } from "../../../../../../services/utils";
import PlayersGameTypeSwitcher from "./PlayersGameTypeSwitcher";
import { toggleModal } from "../../../../../../actions/modal";

const RollPlayersActivity = ({ playersActivity, toggleModal }) => {
  return (
    <table className="player-activity">
      <thead className="player-activity__head">
        <tr>
          <th>No</th>
          <th>Player</th>
          <th>Game type</th>
          <th>Win history</th>
        </tr>
      </thead>
      <tbody className="player-activity__body">
        {playersActivity.map(
          ({ account, history, gameType, username, number }, index) => (
            <tr className="player-activity__single" key={account}>
              <td>{index + 1}.</td>

              <td>
                <a
                  className="acc"
                  onClick={() =>
                    toggleModal("PlayersHistory", {
                      isLarge: true,
                      account
                    })
                  }
                >
                  {username && username}
                  {!username && formatAcc(account, 5, 5)}
                </a>
              </td>

              <td className="player-activity__single__type">
                <PlayersGameTypeSwitcher
                  gameType={gameType}
                  number={number}
                  type={1}
                />
              </td>

              {history.length > 0 && history[0] !== 0 && (
                <td>
                  {history
                    .map((n, index) => ({
                      num: n,
                      index
                    }))
                    .map(({ num, index }) => (
                      <span
                        key={`${account} ${index}`}
                        className={`status status--dot status--${
                          num === 2 ? "success" : "danger"
                        }`}
                      />
                    ))}
                </td>
              )}
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

RollPlayersActivity.propTypes = {
  playersActivity: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ playersActivity }) => ({
  playersActivity: playersActivity.otherPlayersActivity
});

const mapDispatchToProps = {
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RollPlayersActivity);
