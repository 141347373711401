import {
    BURNING_TOKEN,
    BURNING_TOKEN_SUCCESS,
    BURNING_TOKEN_FAILURE,
    CLAIMING_TOKEN,
    CLAIMING_TOKEN_SUCCESS,
    CLAIMING_TOKEN_FAILURE,
    ADD_WITHDRAWAL,
    ADD_WITHDRAWAL_SUCCESS,
    ADD_WITHDRAWAL_FAILURE,
    UPDATE_WITHDRAWAL,
    UPDATE_WITHDRAWAL_SUCCESS,
    UPDATE_WITHDRAWAL_FAILURE
  } from "../actionTypes/withdrawals";

  const INITIAL_STATE = {

    burningToken: false,
    burningTokenErr: '',

    claimingToken: false,
    claimingTokenErr: '',

    updatingToken: false,
    updatingTokenErr: '',

    addingWithdrawal: false,
    addingWithdrawalErr: '',
    withdrawals: {}
  };

  export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
      case BURNING_TOKEN:
        return { ...state, burningToken: true };

      case BURNING_TOKEN_SUCCESS:
        return {
          ...state,
          burningToken: false,
          burningTokenErr: ""
        };

      case BURNING_TOKEN_FAILURE:
        return {
          ...state,
          burningToken: false,
          burningTokenErr: payload
        };

      case CLAIMING_TOKEN:
        return { ...state, claimingToken: true };

      case CLAIMING_TOKEN_SUCCESS:
        return {
          ...state,
          claimingToken: false,
          claimingTokenErr: ""
        };

      case CLAIMING_TOKEN_FAILURE:
        return {
          ...state,
          claimingToken: false,
          claimingTokenErr: payload
        };

      case UPDATE_WITHDRAWAL:
        return { ...state, updatingToken: true };

      case UPDATE_WITHDRAWAL_SUCCESS:
        return {
          ...state,
          updatingToken: false,
          updatingTokenErr: "",
          withdrawals: {
            ...state['withdrawals'],
            [payload.transactionHash]: {
              ...state['withdrawals'][payload.transactionHash],
              status: payload.status,
              claimed: payload.claimed
            }
          }
        };

      case UPDATE_WITHDRAWAL_FAILURE:
        return {
          ...state,
          updatingToken: false,
          updatingTokenErr: payload
        };

      case ADD_WITHDRAWAL:
        return { ...state, addingWithdrawal: true };

      case ADD_WITHDRAWAL_SUCCESS:
        return {
          ...state,
          addingWithdrawal: false,
          addingWithdrawalErr: "",
          withdrawals: {
            ...state['withdrawals'],
            [payload.transactionHash]: payload.data
          }

        };

      case ADD_WITHDRAWAL_FAILURE:
        return {
          ...state,
          addingWithdrawal: false,
          addingWithdrawalErr: payload
        };



      default:
        return state;
    }
  };
