import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PerfectScrollbar from "react-perfect-scrollbar";
import BigNumber from "bignumber.js";
import { setActiveGame } from "../../actions/games/games";
import { getAffTokenData, submitAffiliateRequest } from "../../actions/account";
import { toggleModal } from "../../actions/modal";
import { weiToEth } from "../../services/ethereum/main";
import { formatAcc, formatNumber, toChecksumAddress, validateEmail } from "../../services/utils";

import { DP } from "../../config/constants";
import Loader from "../Loader/Loader";
import "./AffiliateToken.scss";
import { createAffiliateAgreementDoc } from "../../config/affiliateAgreement";

class AffiliateToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      physicalAddress: "",
      ethAddress: this.props.ethAddress,
      listOfAffiliateSites: "",
      proposedMarketingMethods: "",
      error: "",
      affLinkCopied: false,
      applyForAffiliateToken: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleApplyToAffiliate = this.handleApplyToAffiliate.bind(this);
  }
  handleCopyClick = () => {
    this.setState({ affLinkCopied: true })
    setTimeout(() => this.setState({ affLinkCopied: false }), 2000);
  };

  handleApplyToAffiliate = () => {
    this.setState({ applyForAffiliateToken: !this.state.applyForAffiliateToken })
  };

  handleInput = (event, type, required) => {
    const { value } = event.target;
    if (!value && required) {
      let field = "";
      if (type === "email") field = "Email";
      if (type === "name") field = "Name";
      if (type === "physicalAddress") field = "Address";
      if (type === "ethAddress") field = "Ethereum address";
      return this.setState({ [type]: value, error: `* ${field} is a required field.` })
    }
    if (type === "email" && !validateEmail(value)) {
      return this.setState({ [type]: value, error: "Enter valid email." })
    }
    return this.setState({ [type]: value, error: "" });
  };

  componentWillMount() {
    const { getAffTokenData } = this.props;
    getAffTokenData();
  }

  componentDidMount() {
    this.props.setActiveGame("");
    document.title = "Affiliates | BadBit.Games";
  }

  render() {
    const {
      network,
      toggleModal,
      affTokenBalance,
      affBalance,
      ownsAffCode,
      usesAffCode,
      tokenCurrentAffiliatesData,
      gettingCurrentTokenAffiliatesData,
      gettingCurrentTokenAffiliatesDataErr,
      tokenPastAffiliatesData,
      gettingPastTokenAffiliatesData,
      gettingPastTokenAffiliatesDataErr,
      gettingAffTokenData,
      sentAffiliateRequest,
      gettingSentAffiliateRequest,
      gettingSentAffiliateRequestErr,
      submitAffiliateRequest,
      submittingAffiliateRequest,
      submittingAffiliateRequestErr,
    } = this.props;

    const pastRevenue =
      tokenPastAffiliatesData.length ?
        tokenPastAffiliatesData
          .map(({ pastCyclesAffiliateRevenue }) =>
            pastCyclesAffiliateRevenue ? pastCyclesAffiliateRevenue : "0")
          .reduce((acc, cur) => new BigNumber(acc).plus(cur)).toString()
        : "0";

    const { email, name, physicalAddress, ethAddress, error, applyForAffiliateToken } = this.state;

    const disableSubmit =
      !email || !name || !physicalAddress || !ethAddress || !validateEmail(email) || error !== "";

    return (
      <main className="main affiliate-page">
        <div className="container">
          {gettingAffTokenData && <Loader size={85} isFullScreen={false} text={"Loading..."} />}
          {!gettingAffTokenData && affTokenBalance === 0 && (
            <Fragment>
              <div className="details" style={{ padding: "4rem 0"}}>
                {!sentAffiliateRequest
                  && !gettingSentAffiliateRequest
                  && gettingSentAffiliateRequestErr === ""
                  &&
                  <Fragment>
                    {!applyForAffiliateToken &&
                      <Fragment>
                        <h3 className="title" style={{ marginBottom: '2rem' }}>
                          BadBit.Games Affiliate Program
                        </h3>
                        <div className="controls">
                          <button
                            className="btn btn--dark"
                            onClick={() => toggleModal("AssociateWithAffiliateCode")}
                          >
                            Associate With Affiliate Code
                          </button>
                          <button
                            className="btn btn--dark"
                            onClick={this.handleApplyToAffiliate}
                          >
                            Apply to become an affiliate
                          </button>
                        </div>
                      </Fragment>
                    }

                    {applyForAffiliateToken &&
                      <Fragment>
                        <button
                          className="btn btn--dark"
                          onClick={this.handleApplyToAffiliate}
                        >
                          Go Back
                        </button>
                        <h3 className="title" style={{ marginBottom: '8rem' }}>
                          BadBit.Games Affiliate Program Application Form
                        </h3>
                        <div className="form affiliate-request">
                          <div className="form__group">
                            <input
                              className="form__input" type="text"
                              placeholder="Full name *"
                              onBlur={(e) => this.handleInput(e, "name", true)}
                            />
                            <input
                              className="form__input" type="text"
                              placeholder="Email *"
                              onBlur={(e) => this.handleInput(e, "email", true)}
                            />
                          </div>
                          <div className="form__group">
                            <input
                              className="form__input" type="text"
                              placeholder="ETH address *"
                              readOnly={true}
                              value={this.state.ethAddress}
                              onBlur={(e) => this.handleInput(e, "ethAddress", true)}
                            />
                            <input
                              className="form__input" type="text"
                              placeholder="Residential address *"
                              onBlur={(e) => this.handleInput(e, "physicalAddress", true)}
                            />
                          </div>
                          <textarea
                            className="form__textarea"
                            placeholder="Your Affiliate websites"
                            onBlur={(e) => this.handleInput(e, "listOfAffiliateSites" )}
                          />
                          <textarea
                            className="form__textarea"
                            placeholder="Proposed marketing methods"
                            onBlur={(e) => this.handleInput(e, "proposedMarketingMethods" )}
                          />
                          <PerfectScrollbar option={{ minScrollbarLength: 40 }}>
                            <div className="description"
                                 dangerouslySetInnerHTML={{ __html:  createAffiliateAgreementDoc(this.state) }} />
                          </PerfectScrollbar>
                          <button
                            className="btn btn--primary"
                            disabled={disableSubmit}
                            onClick={() => submitAffiliateRequest(this.state)}
                          >
                            {submittingAffiliateRequest ? 'Submitting' : 'Submit' }
                          </button>
                          {this.state.error !== "" && <p className="form__error">{error}</p>}
                          {submittingAffiliateRequestErr !== "" && <p className="form__error">{submittingAffiliateRequestErr}</p>}
                        </div>
                      </Fragment>
                    }

                  </Fragment>
                }
                {sentAffiliateRequest
                  && !gettingSentAffiliateRequest
                  && gettingSentAffiliateRequestErr === ""
                  && <h3 className="title">Your request is being processed</h3>
                }
              </div>
            </Fragment>
          )}
          {!gettingAffTokenData && affTokenBalance > 0 && (
            <Fragment>
              <p className="title">
                Affiliate Management
              </p>

              <div className="controls">
                <button
                  className="btn btn--dark"
                  onClick={() => toggleModal("SetAffiliateCode")}
                >
                  Set Your Affiliate Code
                </button>
                <button
                  className="btn btn--dark"
                  onClick={() => toggleModal("TransferAffiliateToken")}
                >
                  Transfer Affiliate Token
                </button>
                {ownsAffCode !== "" &&
                  <CopyToClipboard text={`${window.location.origin}/spin?affCode=${ownsAffCode}`}>
                    <button
                      className="btn btn--dark"
                      onClick={this.handleCopyClick}
                    >
                      {this.state.affLinkCopied ? "Affiliate Link Copied" : "Generate Affiliate Link"}
                    </button>
                  </CopyToClipboard>
                }
                <button
                  className="btn btn--dark"
                  onClick={() => toggleModal("AssociateWithAffiliateCode")}
                >
                  Associate With Affiliate Code
                </button>
                {usesAffCode !== "" && (
                  <button
                    className="btn btn--dark"
                    onClick={() => toggleModal("BreakAffiliation")}
                  >
                    Break Affiliation
                  </button>
                )}
              </div>

              <div className="details">
                {ownsAffCode !== "" &&
                <p> Your affiliate code: <span>{ownsAffCode}</span></p>
                }
                <p>Your affiliate earnings for the current cycle:
                  <span>
                      <Tooltip title={`${affBalance ? weiToEth(affBalance) : "0"} ETH`} theme="roll">
                        {affBalance ? formatNumber(weiToEth(affBalance), DP) : "0"} ETH
                      </Tooltip>
                    </span>
                </p>
              </div>

              {gettingCurrentTokenAffiliatesData && (
                <Fragment>
                  <Loader size={85} isFullScreen={false} text={"Fetching current affiliates data..."} />;
                </Fragment>
              )}
              {!gettingCurrentTokenAffiliatesData &&
              tokenCurrentAffiliatesData.length === 0 &&
              gettingCurrentTokenAffiliatesDataErr === "" && (
                <p className="info">You don't have any affiliate earnings.</p>
              )}
              {!gettingCurrentTokenAffiliatesData &&
              tokenCurrentAffiliatesData.length === 0 &&
              gettingCurrentTokenAffiliatesDataErr !== "" && (
                <p className="info">There was an error while fetching affiliates data.</p>
              )}
              {!gettingCurrentTokenAffiliatesData &&
              tokenCurrentAffiliatesData.length > 0 &&
              gettingCurrentTokenAffiliatesDataErr === "" && (
                <div className="table-wrapper">
                  <p className="subtitle">
                    Current cycle player activities
                  </p>
                  <table className="affiliates-data">
                    <thead className="affiliates-data__head">
                      <tr>
                        <th>Address</th>
                        <th>Total bets</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody className="affiliates-data__body mock">
                      <tr>
                        <td><a>0X0000...000000</a></td>
                        <td>0000</td>
                        <td>0000 ETH</td>
                      </tr>
                    </tbody>
                  </table>
                  <PerfectScrollbar>
                    <table className="affiliates-data real">
                      <thead className="affiliates-data__head mock">
                        <tr>
                          <th>Address</th>
                          <th>Total bets</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody className="affiliates-data__body">
                      {tokenCurrentAffiliatesData.map(
                        (
                          {
                            address,
                            currentCycleAffiliateRevenue,
                            currentCycleTotalBets,
                            error,
                          },
                          index
                        ) => (
                          <tr
                            className="affiliates-data__single"
                            key={`current-${address}-#${index}`}
                          >
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="address"
                                href={`https://${
                                  network === 3 ? "testnetv3-explorer." : "explorer."
                                }matic.network/address/${toChecksumAddress(address)}`}
                              >
                              {formatAcc(toChecksumAddress(address), 6)}
                              </a>
                            </td>
                            {!error && (
                              <Fragment>
                                <td>{currentCycleTotalBets ? currentCycleTotalBets : 0}</td>
                                <td>
                                  <Tooltip
                                    title={`${currentCycleAffiliateRevenue ? weiToEth(currentCycleAffiliateRevenue) : "0" } ETH`}
                                    theme="roll"
                                  >
                                    {currentCycleAffiliateRevenue ? formatNumber(weiToEth(currentCycleAffiliateRevenue), DP) : 0} ETH
                                  </Tooltip>
                                  </td>
                              </Fragment>
                            )}
                            {error && <td>{error}</td>}
                          </tr>
                        )
                      )}
                      <tr className="affiliates-data__single total">
                        <td>Total: </td>
                        <td>
                          {
                            tokenCurrentAffiliatesData
                              .map(({currentCycleTotalBets}) => currentCycleTotalBets)
                              .reduce((acc, cur) => acc + cur)
                          }
                        </td>
                        <td>
                          {
                            formatNumber(weiToEth(tokenCurrentAffiliatesData
                              .map(({currentCycleAffiliateRevenue}) => currentCycleAffiliateRevenue)
                              .reduce((acc, cur) => new BigNumber(acc).plus(cur).toString())), DP)
                          } ETH
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </PerfectScrollbar>
                </div>
              )}

              <div className="details">
                <p>Your affiliate earnings for the past cycles:
                  <span>
                      <Tooltip title={`${weiToEth(
                        new BigNumber(pastRevenue).isNegative() ? "0" : pastRevenue
                      )} ETH`} theme="roll">
                        {formatNumber(weiToEth(
                          new BigNumber(pastRevenue).isNegative() ? "0" : pastRevenue
                        ), DP)} ETH
                      </Tooltip>
                    </span>
                </p>
              </div>

              {gettingPastTokenAffiliatesData && (
                <Fragment>
                  <Loader size={85} isFullScreen={false} text={"Fetching past affiliates data..."} />;
                </Fragment>
              )}
              {!gettingPastTokenAffiliatesData &&
              tokenPastAffiliatesData.length === 0 &&
              gettingPastTokenAffiliatesDataErr === "" && (
                <p className="info">You don't have any past affiliate earnings.</p>
              )}
              {!gettingPastTokenAffiliatesData &&
              tokenPastAffiliatesData.length === 0 &&
              gettingPastTokenAffiliatesDataErr !== "" && (
                <p className="info">There was an error while fetching affiliates data.</p>
              )}
              {!gettingPastTokenAffiliatesData &&
              tokenPastAffiliatesData.length > 0 &&
              gettingPastTokenAffiliatesDataErr === "" && (
                <div className="table-wrapper">
                  <p className="subtitle">
                    Past cycles player activities
                  </p>
                  <table className="affiliates-data">
                    <thead className="affiliates-data__head">
                    <tr>
                      <th>Address</th>
                      <th>Total bets</th>
                      <th>Revenue</th>
                    </tr>
                    </thead>
                    <tbody className="affiliates-data__body mock">
                    <tr>
                      <td><a>0X0000...000000</a></td>
                      <td>0000</td>
                      <td>0000 ETH</td>
                    </tr>
                    </tbody>
                  </table>
                  <PerfectScrollbar>
                    <table className="affiliates-data real">
                      <thead className="affiliates-data__head mock">
                      <tr>
                        <th>Address</th>
                        <th>Total bets</th>
                        <th>Revenue</th>
                      </tr>
                      </thead>
                      <tbody className="affiliates-data__body">
                      {tokenPastAffiliatesData.map(
                        (
                          {
                            address,
                            pastCyclesAffiliateRevenue,
                            pastCyclesTotalBets,
                            error,
                          },
                          index
                        ) => (
                          <tr
                            className="affiliates-data__single"
                            key={`past-${address}-#${index}`}
                          >
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="address"
                                href={`https://${
                                  network === 3 ? "testnetv3-explorer." : "explorer."
                                }matic.network/address/${toChecksumAddress(address)}`}
                              >
                                {formatAcc(toChecksumAddress(address), 6)}
                              </a>
                            </td>
                            {!error && (
                              <Fragment>
                                <td>{pastCyclesTotalBets ? pastCyclesTotalBets : 0}</td>
                                <td>
                                  <Tooltip
                                    title={
                                      `${pastCyclesAffiliateRevenue ? weiToEth(pastCyclesAffiliateRevenue) : "0"} ETH`}
                                    theme="roll"
                                  >
                                    {pastCyclesAffiliateRevenue ? formatNumber(weiToEth(pastCyclesAffiliateRevenue), DP) : "0"} ETH
                                  </Tooltip>
                                </td>
                              </Fragment>
                            )}
                            {error && <td>{error}</td>}
                          </tr>
                        )
                      )}
                      <tr className="affiliates-data__single total">
                        <td>Total: </td>
                        <td>
                          {
                            tokenPastAffiliatesData
                              .map(({pastCyclesTotalBets}) => pastCyclesTotalBets)
                              .reduce((acc, cur) => acc + cur)
                          }
                        </td>
                        <td>
                          {
                            formatNumber(weiToEth(tokenPastAffiliatesData
                              .map(({pastCyclesAffiliateRevenue}) => pastCyclesAffiliateRevenue)
                              .reduce((acc, cur) => new BigNumber(acc).plus(cur).toString())), DP)
                          } ETH
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </PerfectScrollbar>
                </div>
              )}

            </Fragment>
          )}
        </div>
      </main>
    );
  }
}

AffiliateToken.propTypes = {};

const mapStateToProps = ({ account }) => ({
  network: account.network,
  affTokenBalance: account.affTokenBalance,
  ethAddress: account.account,
  affBalance: account.affBalance,
  ownsAffCode: account.ownsAffCode,
  usesAffCode: account.usesAffCode,
  tokenCurrentAffiliatesData: account.tokenCurrentAffiliatesData,
  gettingCurrentTokenAffiliatesData: account.gettingCurrentTokenAffiliatesData,
  gettingCurrentTokenAffiliatesDataErr: account.gettingCurrentTokenAffiliatesDataErr,
  tokenPastAffiliatesData: account.tokenPastAffiliatesData,
  gettingPastTokenAffiliatesData: account.gettingPastTokenAffiliatesData,
  gettingPastTokenAffiliatesDataErr: account.gettingPastTokenAffiliatesDataErr,
  gettingAffTokenData: account.gettingAffTokenData,
  sentAffiliateRequest: account.sentAffiliateRequest,
  gettingSentAffiliateRequest: account.gettingSentAffiliateRequest,
  gettingSentAffiliateRequestErr: account.gettingSentAffiliateRequestErr,
  submittingAffiliateRequest: account.submittingAffiliateRequest,
  submittingAffiliateRequestErr: account.submittingAffiliateRequestErr,
});

const mapDispatchToProps = {
  setActiveGame,
  toggleModal,
  getAffTokenData,
  submitAffiliateRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateToken);
