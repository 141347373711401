import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BigNumber from "bignumber.js";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  toggleChangeBetSizeDialog,
  toggleChips,
  toggleBets
} from "../../../../actions/games/roulette";
import { getRouletteMaxBetSize } from "../../../../actions/contractConstants/roulette";
import { ethToWei, weiToEth } from "../../../../services/ethereum/main";
import { formatNumber } from "../../../../services/utils";
import { DP } from "../../../../config/constants";

import "./BetsList.scss";

class BetsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amounts: [],
      amountsWei: [],
    };
    this.changeBetSize = this.changeBetSize.bind(this);
    this.handleChangeBetSizeInput = this.handleChangeBetSizeInput.bind(this);
    this.setNewBetSize = this.setNewBetSize.bind(this);
    this.handleMinMax = this.handleMinMax.bind(this);
    this.resetBetSize = this.resetBetSize.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.bets.length !== prevProps.bets.length) {
      const newAmounts = [...this.props.bets].map(
        ({ betArr }) => +weiToEth(betArr[0])
      );
      const newAmountsWei = [...this.props.bets].map(({ betArr }) => betArr[0]);

      this.setState({ amounts: newAmounts, amountsWei: newAmountsWei });
    }
  }

  setNewBetSize = (itemIndex, newAmount) => {
    const { bets, toggleBets, toggleChangeBetSizeDialog } = this.props;

    const newBets = [...bets].map((item, index) => {
      if (itemIndex === index) {
        item.betArr[0] = newAmount;
        return item;
      } else {
        return item;
      }
    });

    toggleBets(newBets);
    toggleChangeBetSizeDialog(itemIndex);
  };

  handleMinMax = (itemIndex, type) => {
    let newAmounts = [...this.state.amounts];
    let newAmountsWei = [...this.state.amountsWei];
    const { bets, getRouletteMaxBetSize, minBetSize } = this.props;
    if (type === "min") {
      newAmounts = newAmounts.map((item, index) =>
        itemIndex === index ? +weiToEth(minBetSize) : item
      );
      newAmountsWei = newAmountsWei.map((item, index) =>
        itemIndex === index ? minBetSize : item
      );
    }
    if (type === "max") {
      newAmounts = newAmounts.map((item, index) =>
        itemIndex === index
          ? +weiToEth(getRouletteMaxBetSize(bets[itemIndex].betArr))
          : item
      );
      newAmountsWei = newAmountsWei.map((item, index) =>
        itemIndex === index
          ? getRouletteMaxBetSize(bets[itemIndex].betArr)
          : item
      );
    }
    this.setState({ amounts: newAmounts, amountsWei: newAmountsWei });
  };

  resetBetSize = itemIndex => {
    const newAmounts = [...this.state.amounts];
    const newAmountsWei = [...this.state.amountsWei];

    newAmounts.splice(
      itemIndex,
      1,
      +weiToEth(this.props.bets[itemIndex].betArr[0])
    );
    newAmountsWei.splice(itemIndex, 1, this.props.bets[itemIndex].betArr[0]);

    this.setState({
      amounts: newAmounts,
      amountsWei: newAmountsWei
    });
  };

  handleChangeBetSizeInput = (itemIndex, e) => {
    const { value } = e.target;

    const newAmounts = [...this.state.amounts];
    const newAmountsWei = [...this.state.amountsWei];

    newAmounts.splice(itemIndex, 1, +value);
    newAmountsWei.splice(itemIndex, 1, ethToWei(value));

    this.setState({
      amounts: newAmounts,
      amountsWei: newAmountsWei
    });
  };

  changeBetSize = () => {
    console.log("da");
  };

  render() {
    const {
      bets,
      toggleChips,
      totalBetAmount,
      toggleChangeBetSizeDialog,
      getRouletteMaxBetSize,
      disableControls
    } = this.props;
    return (
      <section className="main__section main__section--bet-list">
        <div className="main__section__header">
          <span className="main__section__heading">Your Bets:</span>
          {!!bets.length && (
            <span className="main__section__heading total">
              Total:{" "}
              <span>{formatNumber(weiToEth(totalBetAmount), DP)} ETH</span>
            </span>
          )}
        </div>

        <div className="main__section__body">
          <PerfectScrollbar>
            {!!bets.length &&
              bets.map((item, index) => (
                <div key={`${item.type}-${index}`} className="single-bet">
                  <span className="single-bet__type">
                    <span
                      className={`single-bet__type__bet single-bet__type__bet--${
                        item.betData.color
                      }`}
                    >
                      {item.betData.bet}
                    </span>
                    <span className="single-bet__type__details">
                      {item.betData.details}
                    </span>
                  </span>
                  <span className="single-bet__detail">
                    Bet number: <span>#{index + 1}</span>
                  </span>
                  <span className="single-bet__detail">
                    Bet size:{" "}
                    <span>
                      {formatNumber(weiToEth(item.betArr[0]), DP)} ETH
                    </span>
                  </span>
                  <span className="single-bet__detail">
                    Payout on win: <span>{item.betData.payout}</span>
                  </span>
                  {!disableControls &&
                    <div className="more">
                    <span onClick={() => toggleChangeBetSizeDialog(index)}>
                      Change bet size
                    </span>
                      <span onClick={() => toggleChips(item.data, item.type)}>
                      Remove
                    </span>
                      {item.showControls && (
                        <div className="change-bet-size">
                          <span>Change bet size</span>
                          <div className="change-bet-size__input__wrapper">
                            <input
                              className="change-bet-size__input"
                              type="number"
                              placeholder="Amount"
                              step="0.001"
                              min={+weiToEth(this.props.minBetSize)}
                              max={+weiToEth(getRouletteMaxBetSize(item.betArr))}
                              value={this.state.amounts[index]}
                              onChange={e =>
                                this.handleChangeBetSizeInput(index, e)
                              }
                            />
                            <button
                              className="btn btn--dark"
                              onClick={() => this.handleMinMax(index, "min")}
                            >
                              Min
                            </button>
                            <button
                              className="btn btn--dark"
                              onClick={() => this.handleMinMax(index, "max")}
                            >
                              Max
                            </button>
                          </div>
                          <div className="btn__group">
                            <button
                              className="btn btn--primary"
                              onClick={() =>
                                this.setNewBetSize(
                                  index,
                                  ethToWei(this.state.amounts[index])
                                )
                              }
                              disabled={
                                new BigNumber(
                                  ethToWei(this.state.amounts[index])
                                ).gt(getRouletteMaxBetSize(item.betArr)) ||
                                new BigNumber(
                                  ethToWei(this.state.amounts[index])
                                ).lt(this.props.minBetSize)
                              }
                            >
                              Set
                            </button>
                            <button
                              className="btn btn--dark"
                              onClick={() => {
                                toggleChangeBetSizeDialog(index);
                                this.resetBetSize(index);
                              }}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
              ))}
            {!bets.length && (
              <p className="no-bets">No bets, please place a bet!</p>
            )}
          </PerfectScrollbar>
        </div>
      </section>
    );
  }
}

BetsList.propTypes = {
  bets: PropTypes.array.isRequired,
  totalBetAmount: PropTypes.string.isRequired,
  disableControls: PropTypes.bool.isRequired,
  toggleChips: PropTypes.func.isRequired,
  toggleChangeBetSizeDialog: PropTypes.func.isRequired
};

const mapStateToProps = ({ gameRoulette, gamesConstants }) => ({
  bets: gameRoulette.bets,
  totalBetAmount: gameRoulette.totalBetAmount,
  disableControls: gameRoulette.disableControls,
  minBetSize: gamesConstants.minBetSize
});

const mapDispatchToProps = {
  toggleChips,
  toggleBets,
  getRouletteMaxBetSize,
  toggleChangeBetSizeDialog
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetsList);
