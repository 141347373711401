import {
  NUM_OF_WITHDRAWALS_REQUEST,
  NUM_OF_WITHDRAWALS_SUCCESS,
  NUM_OF_WITHDRAWALS_ERROR,
  GET_WITHDRAWAL_HISTORY_REQUEST,
  GET_WITHDRAWAL_HISTORY_SUCCESS,
  GET_WITHDRAWAL_HISTORY_ERROR
} from "../actionTypes/assetMovementHistory";

const INITIAL_STATE = {
  numberOfWithdrawals: 0,
  gettingNumberOfWithdrawals: false,
  gettingNumberOfWithdrawalsError: "",

  assetMovementHistory: { data: [] },
  gettingMovementHistory: false,
  gettingMovementHistoryError: ""
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case NUM_OF_WITHDRAWALS_REQUEST:
      return {
        ...state,
        gettingNumberOfWithdrawals: true,
        gettingNumberOfWithdrawalsError: ""
      };

    case NUM_OF_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        numberOfWithdrawals: payload,
        gettingNumberOfWithdrawals: false,
        gettingNumberOfWithdrawalsError: ""
      };

    case NUM_OF_WITHDRAWALS_ERROR:
      return {
        ...state,
        gettingNumberOfWithdrawals: false,
        gettingNumberOfWithdrawalsError: payload
      };

    case GET_WITHDRAWAL_HISTORY_REQUEST:
      return {
        ...state,
        gettingMovementHistory: true,
        gettingMovementHistoryError: ""
      };

    case GET_WITHDRAWAL_HISTORY_SUCCESS:
      return {
        ...state,
        assetMovementHistory: payload,
        gettingMovementHistory: false,
        gettingMovementHistoryError: ""
      };

    case GET_WITHDRAWAL_HISTORY_ERROR:
      return {
        ...state,
        gettingMovementHistory: false,
        gettingMovementHistoryError: payload
      };

    default:
      return state;
  }
};
