export const GET_MAX_BET_CHANCE_BONUS_REQUEST =
  "GET_MAX_BET_CHANCE_BONUS_REQUEST";
export const GET_MAX_BET_CHANCE_BONUS_SUCCESS =
  "GET_MAX_BET_CHANCE_BONUS_SUCCESS";
export const GET_MAX_BET_CHANCE_BONUS_ERROR = "GET_MAX_BET_CHANCE_BONUS_ERROR";

export const GET_WIN_PROBABILITY_SUCCESS = "GET_WIN_PROBABILITY_SUCCESS";

export const GET_HOUSE_EDGE_GLOBAL_REQUEST = "GET_HOUSE_EDGE_GLOBAL_REQUEST";
export const GET_HOUSE_EDGE_GLOBAL_SUCCESS = "GET_HOUSE_EDGE_GLOBAL_SUCCESS";
export const GET_HOUSE_EDGE_GLOBAL_ERROR = "GET_HOUSE_EDGE_GLOBAL_ERROR";

export const GET_GWP_REQUEST = "GET_GWP_REQUEST";
export const GET_GWP_SUCCESS = "GET_GWP_SUCCESS";
export const GET_GWP_ERROR = "GET_GWP_ERROR";

export const GET_GBS_REQUEST = "GET_GBS_REQUEST";
export const GET_GBS_SUCCESS = "GET_GBS_SUCCESS";
export const GET_GBS_ERROR = "GET_GBS_ERROR";

export const GET_GCL_REQUEST = "GET_GCL_REQUEST";
export const GET_GCL_SUCCESS = "GET_GCL_SUCCESS";
export const GET_GCL_ERROR = "GET_GCL_ERROR";

export const GET_MIN_BET_SIZE_REQUEST = "GET_MIN_BET_SIZE_REQUEST";
export const GET_MIN_BET_SIZE_SUCCESS = "GET_MIN_BET_SIZE_SUCCESS";
export const GET_MIN_BET_SIZE_ERROR = "GET_MIN_BET_SIZE_ERROR";
