import {
  TIME_OFFSET,
  START_ROUND_TIMER,
  CLEAR_ROUND_TIMER,
  SET_ROUND_TIMER_REQUEST,
  SET_ROUND_TIMER_SUCCESS,
  SET_ROUND_TIMER_ERROR
} from "../actionTypes/roundTimer";

const INITIAL_STATE = {
  timer: "",
  timeOffset: 0,

  settingRoundTimer: false,
  settingRoundTimerErr: ""
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TIME_OFFSET:
      return {
        ...state,
        timeOffset: payload
      };

    case START_ROUND_TIMER:
      return { ...state, timer: payload };

    case CLEAR_ROUND_TIMER:
      return { ...state, timer: "" };

    case SET_ROUND_TIMER_REQUEST:
      return {
        ...state,
        settingRoundTimer: true,
        settingRoundTimerErr: ""
      };

    case SET_ROUND_TIMER_SUCCESS:
      return {
        ...state,
        settingRoundTimer: false,
        settingRoundTimerErr: ""
      };

    case SET_ROUND_TIMER_ERROR:
      return {
        ...state,
        settingRoundTimer: false,
        settingRoundTimerErr: payload
      };

    default:
      return state;
  }
};
