import React from "react";
import { weiToEth } from "../../../../services/ethereum/main";
import BigNumber from "bignumber.js";

const Tokens = ({ tokensWon, hideInfo = false }) => {
  if (new BigNumber(tokensWon).gt(0)) {
    return (
      <div className="modal__content modal__content--bad-token-win-lose">
        <p className="title">Congratulations!</p>
        <p className="title win">YOU WIN!</p>
        <p className="details">
          BAD tokens won: <span>{tokensWon}</span>
        </p>
        {!hideInfo && (
          <p className="info">
            Your token balance will be updated after you place another bet or
            withdraw contract funds.
          </p>
        )}
      </div>
    );
  } else {
    return null;
  }
};

Tokens.propTypes = {};

export default Tokens;
