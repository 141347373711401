/**
 * User status types defined on the server
 *
 * @type {{starter: string, flagged: string, verified: string, disabled: string, suspended: string}}
 */
export const statusTypes = {
  starter: "starter",
  flagged: "flagged",
  disabled: "disabled",
  verified: "verified",
  suspended: "suspended"
};

export const FROM_BLOCK = 3678215;

/**
 *
 * @type {number} Global decimal place
 */
export const DP = 3;

/**
 *
 * @type {Storage} Local storage global const
 */
export const LS = window.localStorage;

/**
 * String for LS object model version,
 * change if there are breaking changes
 * or if contract was redeployed
 *
 * @type {string}
 */
export const LS_VERSION = "v0.0.13";

/**
 * React select input options for the pagination per page value
 *
 * @type {array}
 */
export const PAGINATION_PER_PAGE_OPTIONS = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 50, label: "50" }
];

/**
 * Custom styling for the react-select library
 */
export const SELECT_CUSTOM_STYLE = {
  option: (provided, state) => ({
    color: state.isSelected ? "#66849b" : "",
    backgroundColor: state.isFocused ? "#1d314d" : "",
    padding: "2rem"
  }),
  menu: () => ({
    bottom: "100%",
    backgroundColor: "transparent",
    marginBottom: "8px",
    marginTop: "8px",
    position: "absolute",
    width: "100%",
    zIndex: 1,
    left: 0
  }),
  menuList: () => ({
    background: "#192a41",
    overflow: "hidden",
    borderRadius: "1rem"
  }),
  control: () => ({
    width: 200
  }),
  singleValue: () => {},
  indicatorsContainer: () => ({
    display: "none"
  }),
  indicatorSeparator: () => ({
    display: "none"
  })
};

export const SELECT_CUSTOM_STYLE_SMALL = {
  option: (provided, state) => ({
    color: state.isSelected ? "#66849b" : "",
    backgroundColor: state.isFocused ? "#1d314d" : "",
    padding: "1rem"
  }),
  menu: () => ({
    top: "100%",
    backgroundColor: "transparent",
    marginBottom: "8px",
    marginTop: "8px",
    position: "absolute",
    width: "100%",
    zIndex: 1,
    left: 0
  }),
  menuList: () => ({
    background: "#192a41",
    overflow: "hidden",
    borderRadius: "1rem"
  }),
  control: () => ({
    width: 200
  }),
  singleValue: () => {},
  indicatorsContainer: () => ({
    display: "none"
  }),
  indicatorSeparator: () => ({
    display: "none"
  })
};

export const LEVEL_TITLES = [
  { value: null, label: "No title" },
  { value: 1, label: "Debutant" },
  { value: 2, label: "Casual Player" },
  { value: 3, label: "Beginner's Luck" },
  { value: 4, label: "Rewards Collector" },
  { value: 5, label: "Validator" },
  { value: 6, label: "Turning Pro" },
  { value: 7, label: "Soft Forker" },
  { value: 8, label: "Maximalist" },
  { value: 9, label: "Fundamentalist" },
  { value: 10, label: "Technical Analyst" },
  { value: 11, label: "Sentimentalist" },
  { value: 12, label: "Probability Theorist" },
  { value: 13, label: "Duelist" },
  { value: 14, label: "Gunslinger" },
  { value: 15, label: "Sharpshooter" },
  { value: 16, label: "Bounty Hunter" },
  { value: 17, label: "Stakeholder" },
  { value: 18, label: "High Roller" },
  { value: 19, label: "Hard Forker" },
  { value: 20, label: "Spearheader" },
  { value: 21, label: "Olympian" },
  { value: 22, label: "Frontiersman" },
  { value: 23, label: "Homesteader" },
  { value: 24, label: "Metropolitan" },
  { value: 25, label: "Byzantine General" },
  { value: 26, label: "Constantinopler" },
  { value: 27, label: "Clean and Serene" },
  { value: 28, label: "Hodler" },
  { value: 29, label: "Day Trader" },
  { value: 30, label: "Swing Trader" },
  { value: 31, label: "Trendsetter" },
  { value: 32, label: "Algorithmic Trader" },
  { value: 33, label: "High Frequency Trader" },
  { value: 34, label: "Cryptographer" },
  { value: 35, label: "Cyberpunk" },
  { value: 36, label: "Difficulty Bomber" },
  { value: 37, label: "Risk Manager" },
  { value: 38, label: "Smart Contractor" },
  { value: 39, label: "Multi-Armed Bandit" },
  { value: 40, label: "Game Theorist" },
  { value: 41, label: "Aerodynamicist" },
  { value: 42, label: "51% Attacker" },
  { value: 43, label: "Wei Dai" },
  { value: 44, label: "Hal Finney" },
  { value: 45, label: "Nick Szabo" },
  { value: 46, label: "Claude Shannon" },
  { value: 47, label: "Alan Turing" },
  { value: 48, label: "Tim Berners-Lee" },
  { value: 49, label: "Vitalik Buterin" },
  { value: 50, label: "Satoshi Nakamoto" },
  { value: 51, label: "The Sound of Inevitability" },
  { value: 52, label: "Lightning Doesn't Strike Thrice" },
  { value: 53, label: "The Revolution Will Not Be Televised" },
  { value: 54, label: "This is Gentlemen" },
  { value: 55, label: "The FOMO is Real" },
  { value: 56, label: "Bosphorus Blues" },
  { value: 57, label: "Cucumber Connoisseur" },
  { value: 58, label: "Hash Warrior" },
  { value: 59, label: "Harebrain Schemer" },
  { value: 60, label: "Lone Ranger" },
  { value: 61, label: "Outlaw" },
  { value: 62, label: "Isaac Newton" },
  { value: 63, label: "Galileo Galilei" },
  { value: 64, label: "Johannes Kepler" },
  { value: 65, label: "Nicolaus Copernicus" },
  { value: 66, label: "Leonhard Euler" },
  { value: 67, label: "Ferdinand von Zeppelin" },
  { value: 68, label: "Robert Hutchings Goddard" },
  { value: 69, label: "Yuri Gagarin" },
  { value: 70, label: "Neil Armstrong" },
  { value: 71, label: "Buzz Aldrin" },
  { value: 72, label: "Dead Cat Bounce" },
  { value: 73, label: "Three White Soldiers" },
  { value: 74, label: "Morning Star" },
  { value: 75, label: "Shaved Bottom" },
  { value: 76, label: "Reverse Bart Simpson" },
  { value: 77, label: "u/ScienceGuy9489" },
  { value: 78, label: "Aristocrat" },
  { value: 79, label: "Expansionist" },
  { value: 80, label: "Oligarch" },
  { value: 81, label: "Plutocrat" },
  { value: 82, label: "Monopolist" },
  { value: 83, label: "Monopsonist" },
  { value: 84, label: "Imperialist" },
  { value: 85, label: "Egregious" },
  { value: 86, label: "Preposterous" },
  { value: 87, label: "Relentless" },
  { value: 88, label: "Unassailable" },
  { value: 89, label: "Invincible" },
  { value: 90, label: "Indispensible" },
  { value: 91, label: "Indefatigable" },
  { value: 92, label: "Indomitable" },
  { value: 93, label: "Irreplaceable" },
  { value: 94, label: "Inexorable" },
  { value: 95, label: "Privileged" },
  { value: 96, label: "Entitled" },
  { value: 97, label: "Unrekt" },
  { value: 98, label: "Unchained" },
  { value: 99, label: "Destined" },
  { value: 100, label: "Flippened" }
];

export const LEVEL_REWARDS = [
  "0.005 ETH",
  "0.01 ETH",
  "0.015 ETH",
  "0.02 ETH",
  "0.025 ETH",
  "0.03 ETH",
  "0.035 ETH",
  "0.04 ETH",
  "0.045 ETH",
  "0.05 ETH",
  "0.055 ETH",
  "0.06 ETH",
  "0.065 ETH",
  "0.07 ETH",
  "0.075 ETH",
  "0.08 ETH",
  "0.085 ETH",
  "0.09 ETH",
  "0.095 ETH",
  "0.1 ETH",
  "0.105 ETH",
  "0.11 ETH",
  "0.115 ETH",
  "0.12 ETH",
  "0.125 ETH",
  "0.13 ETH",
  "0.135 ETH",
  "0.14 ETH",
  "0.145 ETH",
  "0.14 ETH",
  "0.145 ETH",
  "0.15 ETH",
  "0.155 ETH",
  "0.16 ETH",
  "0.165 ETH",
  "0.17 ETH",
  "0.175 ETH",
  "0.18 ETH",
  "0.18 ETH",
  "0.185 ETH",
  "0.19 ETH",
  "0.195 ETH",
  "0.2 ETH",
  "0.21 ETH",
  "0.215 ETH",
  "0.22 ETH",
  "0.225 ETH",
  "0.23 ETH",
  "0.235 ETH",
  "0.24 ETH",
  "0.245 ETH",
  "0.25 ETH",
  "0.26 ETH",
  "0.27 ETH",
  "0.28 ETH",
  "0.29 ETH",
  "0.3 ETH",
  "0.31 ETH",
  "0.32 ETH",
  "0.33 ETH",
  "0.34 ETH",
  "0.35 ETH",
  "0.36 ETH",
  "0.37 ETH",
  "0.38 ETH",
  "0.39 ETH",
  "0.4 ETH",
  "0.41 ETH",
  "0.42 ETH",
  "0.43 ETH",
  "0.44 ETH",
  "0.45 ETH",
  "0.46 ETH",
  "0.47 ETH",
  "0.48 ETH",
  "0.49 ETH",
  "0.5 ETH",
  "0.51 ETH",
  "0.52 ETH",
  "0.53 ETH",
  "0.54 ETH",
  "0.55 ETH",
  "BAD token WC +0.10%",
  "BAD token WC +0.10%",
  "BAD token WC +0.10%",
  "BAD token WC +0.20%",
  "BAD token WC +0.20%",
  "BAD token WC +0.20%",
  "BAD token WC +0.30%",
  "BAD token WC +0.30%",
  "BAD token WC +0.30%",
  "BAD token WC +0.40%",
  "BAD token WC +0.40%",
  "BAD token WC +0.40%",
  "BAD token WC +0.50%",
  "BAD token WC +0.50%",
  "BAD token WC +0.50%",
  "BAD token WC +0.60%",
  "BAD token WC +0.60%",
  "BAD token WC +0.60%",
]
