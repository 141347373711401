import {
  CONNECT_PROVIDER,
  CONNECT_PROVIDER_FAILURE,
  CONNECT_PROVIDER_SUCCESS,
  GET_NICKNAME_ERROR,
  GET_NICKNAME_REQUEST,
  GET_NICKNAME_SUCCESS,
  GET_USER_BONUS_CONTRACT_BALANCE_ERROR,
  GET_USER_BONUS_CONTRACT_BALANCE_REQUEST,
  GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS,
  GET_USER_CONTRACT_BALANCE_ERROR,
  GET_USER_CONTRACT_BALANCE_REQUEST,
  GET_USER_CONTRACT_BALANCE_SUCCESS,
  GET_WALLET_BALANCE_ERROR,
  GET_WALLET_BALANCE_REQUEST,
  GET_WALLET_BALANCE_SUCCESS,
  GET_ETH_BALANCE_ERROR,
  GET_ETH_BALANCE_REQUEST,
  GET_ETH_BALANCE_SUCCESS,
  SET_FIRST_BET_FLAG,
  SET_NICKNAME_ERROR,
  SET_NICKNAME_REQUEST,
  SET_NICKNAME_SUCCESS,
  DEPOSIT_ETH_REQUEST,
  DEPOSIT_ETH_SUCCESS,
  DEPOSIT_ETH_ERROR,
  WITHDRAW_ETH_REQUEST,
  WITHDRAW_ETH_SUCCESS,
  WITHDRAW_ETH_ERROR,
  RESUME_WITHDRAWAL_REQUEST,
  RESUME_WITHDRAWAL_SUCCESS,
  RESUME_WITHDRAWAL_ERROR,
  SEND_VERIFICATION_STEP_ONE_REQUEST,
  SEND_VERIFICATION_STEP_ONE_SUCCESS,
  SEND_VERIFICATION_STEP_ONE_ERROR,
  SEND_VERIFICATION_STEP_TWO_REQUEST,
  SEND_VERIFICATION_STEP_TWO_SUCCESS,
  SEND_VERIFICATION_STEP_TWO_ERROR,
  ASSOCIATE_WITH_AFF_CODE_REQUEST,
  ASSOCIATE_WITH_AFF_CODE_SUCCESS,
  ASSOCIATE_WITH_AFF_CODE_ERROR,
  GET_AFF_TOKEN_BALANCE_REQUEST,
  GET_AFF_TOKEN_BALANCE_SUCCESS,
  GET_AFF_TOKEN_BALANCE_ERROR,
  SET_AFF_CODE_REQUEST,
  SET_AFF_CODE_SUCCESS,
  SET_AFF_CODE_ERROR,
  TRANSFER_AFF_TOKEN_REQUEST,
  TRANSFER_AFF_TOKEN_SUCCESS,
  TRANSFER_AFF_TOKEN_ERROR,
  GET_TOKEN_CURRENT_AFFILIATES_REQUEST,
  GET_TOKEN_CURRENT_AFFILIATES_SUCCESS,
  GET_TOKEN_CURRENT_AFFILIATES_ERROR,
  GET_TOKEN_PAST_AFFILIATES_REQUEST,
  GET_TOKEN_PAST_AFFILIATES_SUCCESS,
  GET_TOKEN_PAST_AFFILIATES_ERROR,
  GET_AFF_TOKEN_DATA_REQ,
  GET_AFF_TOKEN_DATA_SUCCESS,
  BREAK_AFFILIATION_REQUEST,
  BREAK_AFFILIATION_SUCCESS,
  BREAK_AFFILIATION_ERROR,
  SET_IS_REGISTERED,
  SET_REMEMBER_USER,
  ACCEPT_TOS_SUCCESS,
  SET_SENT_AFFILIATE_REQUEST,
  SET_SENT_AFFILIATE_SUCCESS,
  SET_SENT_AFFILIATE_FAILURE,
  SUBMIT_AFFILIATE_REQUEST,
  SUBMIT_AFFILIATE_SUCCESS,
  SUBMIT_AFFILIATE_FAILURE,
  GET_WETH_APPROVAL_REQUEST,
  GET_WETH_APPROVAL_SUCCESS,
  GET_WETH_APPROVAL_ERROR
} from "../actionTypes/account";

const INITIAL_STATE = {
  loggingIn: false,
  connectingProvider: false,

  account: "",
  accountError: "",

  isFirstBet: false,
  isRegistered: false,
  rememberUser: false,
  acceptedTOS: false,
  userStatus: {},

  gettingAffTokenData: false,

  affTokenBalance: 0,
  gettingAffTokenBalance: false,
  gettingAffTokenBalanceErr: "",

  sentAffiliateRequest: false,
  gettingSentAffiliateRequest: false,
  gettingSentAffiliateRequestErr: '',
  submittingAffiliateRequest: false,
  submittingAffiliateRequestErr: '',

  usesAffCode: "",
  associatingWithAffCode: false,
  associatingWithAffCodeErr: "",

  ownsAffCode: "",
  affBalance: "0",
  settingOwnAffCode: false,
  settingOwnAffCodeErr: "",

  tokenCurrentAffiliatesData: [],
  gettingCurrentTokenAffiliatesData: false,
  gettingCurrentTokenAffiliatesDataErr: "",

  tokenPastAffiliatesData: [],
  gettingPastTokenAffiliatesData: false,
  gettingPastTokenAffiliatesDataErr: "",

  walletBalance: "0",
  gettingWalletBalance: false,
  gettingWalletBalanceErr: "",

  ethBalance: "0",
  gettingEthBalance: false,
  gettingEthBalanceErr: "",

  wethApproval: "0",
  gettingWethAPproval: false,
  gettingWethApprovalErr: "",

  resumingWithdrawal: false,
  resumingWithdrawalErr: "",

  depositingEthBalance: false,
  depositingEthBalanceErr: "",

  withdrawingEthBalance: false,
  withdrawingEthBalanceErr: "",

  userContractBalance: "0",
  gettingUserBalance: false,
  gettingUserBalanceErr: "",

  userContractBonusBalance: "0",
  gettingUserBonusBalance: false,
  gettingUserBonusBalanceErr: "",

  sendingVerificationStepOne: false,
  sendingVerificationStepOneErr: "",

  sendingVerificationStepTwo: false,
  sendingVerificationStepTwoErr: "",

  network: 0,

  nickname: "",
  gettingNickname: false,
  gettingNicknameErr: "",
  settingNickname: false,
  settingNicknameErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONNECT_PROVIDER:
      return { ...state, connectingProvider: true };

    case CONNECT_PROVIDER_SUCCESS:
      return {
        ...state,
        connectingProvider: false,
        accountError: "",
        ...payload
      };

    case CONNECT_PROVIDER_FAILURE:
      return {
        ...state,
        connectingProvider: false,
        account: "",
        walletBalance: "0",
        ethBalance: "0",
        accountError: payload
      };

    case ACCEPT_TOS_SUCCESS: return {
      ...state,
      acceptedTOS: true,
    };

    case SET_REMEMBER_USER: return {
      ...state,
      rememberUser: payload,
    };

    case SET_IS_REGISTERED:
      return {
        ...state,
        isRegistered: payload
      };

    case GET_NICKNAME_REQUEST:
      return { ...state, gettingNickname: true, gettingNicknameErr: "" };

    case GET_NICKNAME_SUCCESS:
      return {
        ...state,
        gettingNickname: false,
        gettingNicknameErr: "",
        nickname: payload
      };

    case GET_NICKNAME_ERROR:
      return { ...state, gettingNickname: false, gettingNicknameErr: payload };

    case GET_WALLET_BALANCE_REQUEST:
      return {
        ...state,
        gettingWalletBalance: true,
        gettingWalletBalanceErr: ""
      };

    case GET_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        gettingWalletBalance: false,
        gettingWalletBalanceErr: "",
        walletBalance: payload
      };

    case GET_WALLET_BALANCE_ERROR:
      return {
        ...state,
        gettingWalletBalance: false,
        gettingWalletBalanceErr: payload
      };


    case DEPOSIT_ETH_REQUEST:
      return {
        ...state,
        depositingEthBalance: true,
        depositingEthBalanceErr: ""
      };

    case DEPOSIT_ETH_SUCCESS:
      return {
        ...state,
        depositingEthBalance: false,
        depositingEthBalanceErr: ""
      };

    case DEPOSIT_ETH_ERROR:
      return {
        ...state,
        depositingEthBalance: false,
        depositingEthBalanceErr: payload
      };

    case RESUME_WITHDRAWAL_REQUEST:
    return {
      ...state,
      resumingWithdrawal: true,
      resumingWithdrawalErr: ""
    };

    case RESUME_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        resumingWithdrawal: false,
        resumingWithdrawalErr: ""
      };

    case RESUME_WITHDRAWAL_ERROR:
      return {
        ...state,
        resumingWithdrawal: false,
        resumingWithdrawalErr: payload
      };

    case WITHDRAW_ETH_REQUEST:
      return {
        ...state,
        withdrawingEthBalance: true,
        withdrawingEthBalanceErr: ""
      };

    case WITHDRAW_ETH_SUCCESS:
      return {
        ...state,
        withdrawingEthBalance: false,
        withdrawingEthBalanceErr: ""
      };

    case WITHDRAW_ETH_ERROR:
      return {
        ...state,
        withdrawingEthBalance: false,
        withdrawingEthBalanceErr: payload
      };

    case GET_ETH_BALANCE_REQUEST:
      return {
        ...state,
        gettingEthBalance: true,
        gettingEthBalanceErr: ""
      };

    case GET_ETH_BALANCE_SUCCESS:
      return {
        ...state,
        gettingEthBalance: false,
        gettingEthBalanceErr: "",
        ethBalance: payload
      };

    case GET_ETH_BALANCE_ERROR:
      return {
        ...state,
        gettingEthBalance: false,
        gettingEthBalanceErr: payload
      };
    
    case GET_WETH_APPROVAL_REQUEST:
      return {
        ...state,
        gettingWethAPproval: true,
        gettingWethApprovalErr: "",
      };

    case GET_WETH_APPROVAL_SUCCESS:
      return {
        ...state,
        gettingWethAPproval: false,
        gettingWethApprovalErr: "",
        wethApproval: payload
      };

    case GET_WETH_APPROVAL_ERROR:
      return {
        ...state,
        gettingWethAPproval: false,
        gettingWethApprovalErr: payload,
      };

    case GET_USER_CONTRACT_BALANCE_REQUEST:
      return {
        ...state,
        gettingUserBalance: true,
        gettingUserBalanceErr: ""
      };

    case GET_USER_CONTRACT_BALANCE_SUCCESS:
      return {
        ...state,
        userContractBalance: payload,
        gettingUserBalance: false,
        gettingUserBalanceErr: ""
      };

    case GET_USER_CONTRACT_BALANCE_ERROR:
      return {
        ...state,
        gettingUserBalance: false,
        gettingUserBalanceErr: payload
      };

    case GET_USER_BONUS_CONTRACT_BALANCE_REQUEST:
      return {
        ...state,
        gettingUserBonusBalance: true,
        gettingUserBonusBalanceErr: ""
      };

    case GET_USER_BONUS_CONTRACT_BALANCE_SUCCESS:
      return {
        ...state,
        userContractBonusBalance: payload,
        gettingUserBonusBalance: false,
        gettingUserBonusBalanceErr: ""
      };

    case GET_USER_BONUS_CONTRACT_BALANCE_ERROR:
      return {
        ...state,
        gettingUserBonusBalance: false,
        gettingUserBonusBalanceErr: payload
      };

    case SET_NICKNAME_REQUEST:
      return { ...state, settingNickname: true, settingNicknameErr: "" };

    case SET_NICKNAME_SUCCESS:
      return {
        ...state,
        settingNickname: false,
        settingNicknameErr: "",
        nickname: payload
      };

    case SET_NICKNAME_ERROR:
      return { ...state, settingNickname: false, settingNicknameErr: payload };

    case SET_FIRST_BET_FLAG:
      return { ...state, isFirstBet: payload };

    case SEND_VERIFICATION_STEP_ONE_REQUEST: return {
      ...state,
      sendingVerificationStepOne: true,
      sendingVerificationStepOneErr: "",
    };

    case SEND_VERIFICATION_STEP_ONE_SUCCESS: return {
      ...state,
      sendingVerificationStepOne: false,
      sendingVerificationStepOneErr: "",
    };

    case SEND_VERIFICATION_STEP_ONE_ERROR: return {
      ...state,
      sendingVerificationStepOne: false,
      sendingVerificationStepOneErr: payload,
    };

    case SEND_VERIFICATION_STEP_TWO_REQUEST: return {
      ...state,
      sendingVerificationStepTwo: true,
      sendingVerificationStepTwoErr: "",
    };

    case SEND_VERIFICATION_STEP_TWO_SUCCESS: return {
      ...state,
      sendingVerificationStepTwo: false,
      sendingVerificationStepTwoErr: "",
    };

    case SEND_VERIFICATION_STEP_TWO_ERROR: return {
      ...state,
      sendingVerificationStepTwo: false,
      sendingVerificationStepTwoErr: payload,
    };

    case ASSOCIATE_WITH_AFF_CODE_REQUEST: return {
      ...state,
      associatingWithAffCode: true,
      associatingWithAffCodeErr: "",
    };

    case ASSOCIATE_WITH_AFF_CODE_SUCCESS: return {
      ...state,
      usesAffCode: payload,
      associatingWithAffCode: false,
      associatingWithAffCodeErr: "",
    };

    case ASSOCIATE_WITH_AFF_CODE_ERROR: return {
      ...state,
      associatingWithAffCode: false,
      associatingWithAffCodeErr: payload,
    };

    case BREAK_AFFILIATION_REQUEST: return {
      ...state,
      breakingAffiliation: true,
      breakingAffiliationErr: "",
    };

    case BREAK_AFFILIATION_SUCCESS: return {
      ...state,
      usesAffCode: "",
      breakingAffiliation: false,
      breakingAffiliationErr: "",
    };

    case BREAK_AFFILIATION_ERROR: return {
      ...state,
      breakingAffiliation: false,
      breakingAffiliationErr: payload.error,
    };

    case GET_AFF_TOKEN_BALANCE_REQUEST: return {
      ...state,
      gettingAffTokenBalance: true,
      gettingAffTokenBalanceErr: "",
    };

    case GET_AFF_TOKEN_BALANCE_SUCCESS: return {
      ...state,
      affTokenBalance: payload,
      gettingAffTokenBalance: false,
      gettingAffTokenBalanceErr: "",
    };

    case GET_AFF_TOKEN_BALANCE_ERROR: return {
      ...state,
      gettingAffTokenBalance: false,
      gettingAffTokenBalanceErr: payload,
    };

    case SET_AFF_CODE_REQUEST: return {
      ...state,
      settingOwnAffCode: true,
      settingOwnAffCodeErr: "",
    };

    case SET_AFF_CODE_SUCCESS: return {
      ...state,
      ownsAffCode: payload.ownsAffCode,
      affBalance: payload.affBalance,
      settingOwnAffCode: false,
      settingOwnAffCodeErr: "",
    };

    case SET_AFF_CODE_ERROR: return {
      ...state,
      settingOwnAffCode: false,
      settingOwnAffCodeErr: payload,
    };

    case TRANSFER_AFF_TOKEN_REQUEST: return {
      ...state,
      transferringAffToken: false,
      transferringAffTokenErr: "",
    };

    case TRANSFER_AFF_TOKEN_SUCCESS: return {
      ...state,
      transferringAffToken: true,
      transferringAffTokenErr: "",
      ownsAffCode: "",
      affTokenBalance: 0,
    };

    case TRANSFER_AFF_TOKEN_ERROR: return {
      ...state,
      transferringAffToken: false,
      transferringAffTokenErr: payload,
    };

    case GET_TOKEN_CURRENT_AFFILIATES_REQUEST: return {
      ...state,
      gettingCurrentTokenAffiliatesData: true,
      gettingCurrentTokenAffiliatesDataErr: "",
    };

    case GET_TOKEN_CURRENT_AFFILIATES_SUCCESS: return {
      ...state,
      tokenCurrentAffiliatesData: payload,
      gettingCurrentTokenAffiliatesData: false,
      gettingCurrentTokenAffiliatesDataErr: "",
    };

    case GET_TOKEN_CURRENT_AFFILIATES_ERROR: return {
      ...state,
      gettingCurrentTokenAffiliatesData: false,
      gettingCurrentTokenAffiliatesDataErr: payload,
    };


    case GET_TOKEN_PAST_AFFILIATES_REQUEST: return {
      ...state,
      gettingPastTokenAffiliatesData: true,
      gettingPastTokenAffiliatesDataErr: "",
    };

    case GET_TOKEN_PAST_AFFILIATES_SUCCESS: return {
      ...state,
      tokenPastAffiliatesData: payload,
      gettingPastTokenAffiliatesData: false,
      gettingPastTokenAffiliatesDataErr: "",
    };

    case GET_TOKEN_PAST_AFFILIATES_ERROR: return {
      ...state,
      gettingPastTokenAffiliatesData: false,
      gettingPastTokenAffiliatesDataErr: payload,
    };

    case GET_AFF_TOKEN_DATA_REQ: return {
      ...state,
      gettingAffTokenData: true,
    };

    case GET_AFF_TOKEN_DATA_SUCCESS: return {
      ...state,
      gettingAffTokenData: false,
    };

    case SET_SENT_AFFILIATE_REQUEST: return {
      ...state,
      gettingSentAffiliateRequest: true,
      gettingSentAffiliateRequestErr: '',
    };

    case SET_SENT_AFFILIATE_SUCCESS: return {
      ...state,
      sentAffiliateRequest: payload,
      gettingSentAffiliateRequest: false,
      gettingSentAffiliateRequestErr: '',
    };

    case SET_SENT_AFFILIATE_FAILURE: return {
      ...state,
      gettingSentAffiliateRequest: false,
      gettingSentAffiliateRequestErr: payload,
    };

    case SUBMIT_AFFILIATE_REQUEST: return {
      ...state,
      submittingAffiliateRequest: true,
      submittingAffiliateRequestErr: '',
    };

    case SUBMIT_AFFILIATE_SUCCESS: return {
      ...state,
      sentAffiliateRequest: true,
      submittingAffiliateRequest: false,
      submittingAffiliateRequestErr: '',
    };

    case SUBMIT_AFFILIATE_FAILURE: return {
      ...state,
      submittingAffiliateRequest: false,
      submittingAffiliateRequestErr: payload,
    };


    default:
      return state;
  }
};
