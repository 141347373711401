import React, { Fragment } from "react";
import { connect } from "react-redux";

import "./Drawer.scss";

const DrawerLeft = ({ drawerLeftOpen, drawerLeftComponent }) => {
  if (drawerLeftOpen) {
    const {
      default: DrawerComponent
    } = require(`./DrawerTypes/${drawerLeftComponent}/${drawerLeftComponent}.js`);
    return (
      <Fragment>
        <section className="aside__drawer aside__drawer--left aside__drawer--left--open">
          <DrawerComponent />
        </section>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <section className="aside__drawer aside__drawer--left" />
      </Fragment>
    );
  }
};

const mapStateToProps = ({ drawer, txNotifications, chat }) => ({
  drawerLeftOpen: drawer.drawerLeftOpen,
  drawerLeftComponent: drawer.drawerLeftComponent,
  unreadNotifications: txNotifications.unreadNotifications,
  unreadMessages: chat.unreadMessages,
  adminOnline: chat.adminOnline
});

export default connect(
  mapStateToProps
)(DrawerLeft);
