import {
  badBitGameContractDeployTransactionHash,
  ethersProvider,
  RouletteGameContract,
  RouletteGameEventsContract, rouletteGameTxHash, web3Matic,
} from '../contract';
import { hexToNumber } from "./main";

export const getChanceAndMultiplierFromRouletteContract = async (betArr, from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.getChanceAndMultiplier(betArr).call({ from });
};

export const maxBetSizeFromRouletteContract = async (chance, from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.maxBetSize(chance).call({ from });
};

export const getParamsForTokenCalculationFromRouletteContract = async (chance, from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.getParamsForTokenCaluclation(chance).call({ from });
};

export const placeBetFromRouletteContract = (
  betsArr,
  account,
  betWithContractBalance,
  betWithContractBonusBalance,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      let functionSignature;
      const contract = await RouletteGameContract();

      if (betWithContractBalance) {
        functionSignature = contract.methods
          .placeBetWithContractBalance(betsArr)
          .encodeABI();
      } else if (betWithContractBonusBalance) {
        functionSignature = contract.methods
          .placeBonusBet(betsArr)
          .encodeABI();
      } else {
        functionSignature = contract.methods
          .placeBet(betsArr)
          .encodeABI();
      }

      var txObject = {
        functionSignature,
        contract,
        account
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Placing a bet...",
          successTitle: "Placed a bet.",
          errorTitle: "Bet denied.",
          notifyOf: "place-bet",
          game: "roulette",
          amount
        },
        dispatch,
        getState
      );

      resolve(status);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });

export const getTotalBetsFromRouletteContract = async (address) => {
  const contract = await RouletteGameContract();
  return +(await contract.methods.getTotalBets(address).call());
};

export const getBetInfoFromRouletteContract = async (address, round, from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.getBetInfo(address, round).call({ from });
};

export const lastBetForPlayerFromRouletteContract = async (address, from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.lastBetForPlayer(address).call({ from });
};

export const getMaxNumberOfBetsFromContract = async () => {
  const contract = await RouletteGameContract();
  return +(await contract.methods.MAX_NUMBER_OF_BETS().call());
};

export const getBenchmarkMaxBetSizeFromRouletteContract = async (from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.BENCHMARK_MAXIMUM_BET_SIZE().call({ from });
};

export const getMaxBetSizeFromRouletteContract = async (from) => {
  const contract = await RouletteGameContract();
  const MAX_BET_1 = await contract.methods.MAX_BET_1().call({ from });
  const MAX_BET_2 = await contract.methods.MAX_BET_2().call({ from });
  const MAX_BET_3 = await contract.methods.MAX_BET_3().call({ from });
  const MAX_BET_4 = await contract.methods.MAX_BET_4().call({ from });
  const MAX_BET_5 = await contract.methods.MAX_BET_5().call({ from });
  const MAX_BET_6 = await contract.methods.MAX_BET_6().call({ from });
  const MAX_BET_7 = await contract.methods.MAX_BET_7().call({ from });
  return {
    MAX_BET_1,
    MAX_BET_2,
    MAX_BET_3,
    MAX_BET_4,
    MAX_BET_5,
    MAX_BET_6,
    MAX_BET_7
  };
};

export const getRouletteBetsAllowedFromContract = async (from) => {
  const contract = await RouletteGameContract();
  return await contract.methods.BETS_ALLOWED().call({ from });
};

export const listenToRoulettePausedFromContract = callback => {
  console.log("ROULETTE PAUSED EVENT OUT");
  RouletteGameEventsContract.on("GamePaused", async bool => {
    console.log("ROULETTE PAUSED EVENT IN");
    callback({ bool });
  });
};

export const getPreviousRoundsFromRouletteContract = async (numberOfRolls, page, address) => {
  try {
    const contract = RouletteGameEventsContract;
    const event = contract.interface.events["RoundFinished"];
    const filter = contract.filters.RoundFinished(null, address, null);
    const { blockNumber } = await web3Matic.eth.getTransaction(rouletteGameTxHash);
    filter.fromBlock = blockNumber;

    const startFrom = page === 0 ? page : page * numberOfRolls + 1;

    let rounds = await ethersProvider.getLogs(filter);
    rounds = rounds.map(item => event.decode(item.data, item.topics));
    rounds = rounds.map(({ id }) => hexToNumber(id._hex)).reverse();
    rounds = rounds.splice(startFrom, numberOfRolls);
    return rounds;
  } catch (e) {
    console.log(e);
  }
};

export const getPreviousRollsFromRouletteContract = (
  numberOfRolls = 8,
  address,
  page
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = RouletteGameEventsContract;
      let rounds = await getPreviousRoundsFromRouletteContract(numberOfRolls, page, address);

      const promises = rounds.reverse().map(
        round =>
          new Promise(async (resolve, reject) => {
            try {
              const event = contract.interface.events["RoundFinished"];
              const filter = contract.filters.RoundFinished(round, address);
              const { blockNumber } = await web3Matic.eth.getTransaction(rouletteGameTxHash);
              filter.fromBlock = blockNumber;

              let item = (await ethersProvider.getLogs(filter))[0];
              item = event.decode(item.data, item.topics);

              resolve({
                round: hexToNumber(item.id._hex),
                number: hexToNumber(item.resultNumber._hex)
              });
            } catch (err) {
              reject(err);
            }
          })
      );

      const res = await Promise.all(promises);

      resolve(res.reverse());
    } catch (err) {
      reject(err);
    }
  });

/**
 * A listener for RoundStarted event
 *
 * @param callback
 */
export const listenToRoundStartedFromRouletteContract = callback => {
  console.log("ROULETTE ROUND STARTED EVENT OUT");
  RouletteGameEventsContract.on("RoundStarted", async (id, player) => {
    console.log("ROULETTE ROUND STARTED EVENT IN");
    await callback({
      player,
      round: hexToNumber(id._hex)
    });
  });
};

/**
 * A listener for RoundFinished event
 *
 * @param callback
 */
export const listenToRoundFinishedFromRouletteContract = callback => {
  console.log("ROULETTE ROUND FINISHED EVENT OUT");
  RouletteGameEventsContract.on("RoundFinished", async (id, player, resultHash, resultNumber) => {
    console.log("ROULETTE ROUND FINISHED EVENT IN");
    await callback({
      player,
      round: hexToNumber(id._hex),
      number: hexToNumber(resultNumber._hex)
    });
  });
};
