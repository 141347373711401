import {
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR,
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST,
  GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS,
  GET_HOUSE_EDGE_ERROR,
  GET_HOUSE_EDGE_REQUEST,
  GET_HOUSE_EDGE_SUCCESS,
  GET_MAX_BET_VAL_ERROR,
  GET_MAX_BET_VAL_REQUEST,
  GET_MAX_BET_VAL_SUCCESS,
  GET_ROUND_TIME_ERROR,
  GET_ROUND_TIME_REQUEST,
  GET_ROUND_TIME_SUCCESS,
  GET_USE_DYNAMIC_MAX_BET_ERROR,
  GET_USE_DYNAMIC_MAX_BET_REQUEST,
  GET_USE_DYNAMIC_MAX_BET_SUCCESS
} from "../../actionTypes/contractConstants/roll";
import {
  getBenchmarkMaxBetSizeFromRollContract,
  getHouseEdgeFromRollContract,
  getMaxBetFromRollContract,
  getRoundTimeFromRollContract,
  getUseDynamicMaxBetFromRollContract,
  listenToBenchmarkParameterSetFromRollContract,
  listenToMaxBetSetFromRollContract,
  listenToMinBetSetFromRollContract,
  listenToUseDynamicBetChangedFromRollContract
} from "../../services/ethereum/roll";
import { getMaxDonsInRow } from "../games/doubleOrNothing";
import BigNumber from "bignumber.js";
import {getMaxBetChanceForBonusBetting, getMinBetSize, getWinProbability} from './games';
import { ethToWei } from "../../services/ethereum/main";

/**
 * Gets round time from the contract
 *
 * @return {Function}
 */
export const getRoundTime = () => async ( dispatch, getState ) => {
  dispatch({ type: GET_ROUND_TIME_REQUEST });
  try {
    const { account } = getState().account;

    const payload = await getRoundTimeFromRollContract(account);
    dispatch({ type: GET_ROUND_TIME_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ROUND_TIME_ERROR,
      payload: err.message
    });
  }
};

/**
 * Gets house commission in percents.
 *
 * @return {Function}
 */
export const getHouseEdge = () => async ( dispatch, getState ) => {
  dispatch({ type: GET_HOUSE_EDGE_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getHouseEdgeFromRollContract(account);
    dispatch({ type: GET_HOUSE_EDGE_SUCCESS, payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_HOUSE_EDGE_ERROR, payload: err.message });
  }
};

/**
 * Gets benchmark max bet size
 *
 * @return {Function}
 */
export const getBenchmarkMaxBetSize = (value = null) => async (dispatch, getState) => {
  dispatch({ type: GET_ROLL_BENCHMARK_MAX_BET_SIZE_REQUEST });
  const { account } = getState().account;

  if (value !== null) {
    const payload = Number(value);
    dispatch({ type: GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS, payload });
    return;
  }
  try {
    const payload = await getBenchmarkMaxBetSizeFromRollContract(account);
    dispatch({ type: GET_ROLL_BENCHMARK_MAX_BET_SIZE_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_ROLL_BENCHMARK_MAX_BET_SIZE_ERROR, payload: e.message });
  }
};

export const getUseDynamicMaxBet = (value = null) => async (dispatch, getState) => {
  dispatch({ type: GET_USE_DYNAMIC_MAX_BET_REQUEST });
  const { account } = getState().account;

  if (value !== null) {
    dispatch({ type: GET_USE_DYNAMIC_MAX_BET_SUCCESS, payload: value });
    if (value) dispatch(getBenchmarkMaxBetSize());
    dispatch(getMinBetSize());
    dispatch(getMaxBetValue());
    return;
  }
  try {
    const payload = await getUseDynamicMaxBetFromRollContract(account);
    dispatch({ type: GET_USE_DYNAMIC_MAX_BET_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_USE_DYNAMIC_MAX_BET_ERROR, payload: e.message });
  }
};

/**
 * Sets max bet value from contract
 *
 * @return {Function}
 */
export const getMaxBetValue = (value = null) => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_BET_VAL_REQUEST });
  const { useDynamicMaxBet, benchmarkMaxBetSize } = getState().rollConstants;
  const { winChance } = getState().gameRoll;

  if (value !== null) {
    if (useDynamicMaxBet) {
      const payload = await calculateDynamicMaxBetSize(
        benchmarkMaxBetSize,
        winChance
      );
      dispatch({ type: GET_MAX_BET_VAL_SUCCESS, payload });
      return;
    } else {
      dispatch({ type: GET_MAX_BET_VAL_SUCCESS, payload: value });
      return;
    }
  }

  try {
    const { account } = getState().account;

    if (useDynamicMaxBet) {
      const payload = await calculateDynamicMaxBetSize(
        benchmarkMaxBetSize,
        winChance
      );
      dispatch({ type: GET_MAX_BET_VAL_SUCCESS, payload });
    } else {
      const payload = await getMaxBetFromRollContract(account);
      dispatch({ type: GET_MAX_BET_VAL_SUCCESS, payload });
    }
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_MAX_BET_VAL_ERROR, payload: err.message });
  }
};

/**
 * Calculates max bet sizing
 *
 * @param benchmarkMaxBetSize
 * @param chance
 * @return {string}
 */
export const calculateDynamicMaxBetSize = (benchmarkMaxBetSize, chance) => {
  const benchmark = new BigNumber(ethToWei(benchmarkMaxBetSize));
  if (chance < 13 && chance > 4) {
    return benchmark
      .times(chance * 3 - 5)
      .dividedBy(100)
      .toString();
  }

  if (chance < 46) {
    return benchmark
      .times(chance * 2 + 8)
      .dividedBy(100)
      .toString();
  }
  if (chance < 56) {
    return benchmark.toString();
  }

  return benchmark
    .times((chance - 55) * 5 + 100)
    .dividedBy(100)
    .toString();
};

/**
 * Calculates a winning chance
 *
 * @param gameType
 * @param gameSubType
 * @param betValue
 * @return {number|*}
 */
export const calculateWinChance = (gameType, gameSubType, betValue) => {
  if (gameType === 0) {
    return 99 - betValue;
  }
  if (gameType === 1) {
    return betValue;
  }
  if (gameType === 2) {
    return 1;
  }
  if (gameType === 3) {
    if (gameSubType === 0) return 49;
    if (gameSubType === 1) return 50;
  }
  if (gameType === 4) {
    if (gameSubType === 2) {
      return 10;
    } else {
      return 45;
    }
  }
};

/**
 * Calculates a house commission for a given house edge and winning chance
 *
 * @param houseEdge
 * @param winChance
 * @return {number}
 */
export const calculateHouseCommission = (houseEdge, winChance) => {
  return Math.floor((houseEdge * 100) / (100 - winChance));
};

/**
 * Calculates a winning prize
 *
 * @param betAmount
 * @param winChance
 * @param houseEdge
 * @return {string}
 */
export const calculateWinPrize = (betAmount, winChance, houseEdge) => {
  const amount = new BigNumber(betAmount);
  const houseCommission = calculateHouseCommission(houseEdge, winChance);

  const prizeInWei = amount
    .times(100 - winChance)
    .dividedBy(winChance)
    .integerValue(BigNumber.ROUND_FLOOR)
    .times(100000 - houseCommission)
    .dividedBy(100000)
    .integerValue(BigNumber.ROUND_FLOOR);

  return amount.plus(prizeInWei).toString();
};

/**
 *  * A listener for UseDynamicBetChanged event
 *
 * @return {Function}
 */
export const listenToUseDynamicBetChanged = () => dispatch => {
  const callback = ({ useDynamic }) => {
    dispatch(getUseDynamicMaxBet(useDynamic));
  };
  listenToUseDynamicBetChangedFromRollContract(callback);
};

/**
 * A listener for BenchmarkParameterSet event
 *
 * @return {Function}
 */
export const listenToBenchmarkParameterSet = () => dispatch => {
  const callback = ({ benchmarkMaxBetSize }) => {
    dispatch(getBenchmarkMaxBetSize(benchmarkMaxBetSize));
  };
  listenToBenchmarkParameterSetFromRollContract(callback);
};

/**
 * A listener for MinBetSet event
 *
 * @return {Function}
 */
export const listenToMinBetSet = () => dispatch => {
  const callback = ({ minBetAmount }) => {
    // dispatch(getMinBetValue(minBetAmount));
  };
  listenToMinBetSetFromRollContract(callback);
};

/**
 * A listener for MaxBetSet event
 *
 * @return {Function}
 */
export const listenToMaxBetSet = () => dispatch => {
  const callback = ({ maxBetAmount }) => {
    dispatch(getMaxBetValue(maxBetAmount));
  };
  listenToMaxBetSetFromRollContract(callback);
};

/**
 * Gets constant values from the contract
 *
 * @return {Function}
 */
export const getRollContractConstValues = () => async dispatch => {
  await dispatch(getHouseEdge());
  await dispatch(getUseDynamicMaxBet());
  await dispatch(getBenchmarkMaxBetSize());
  await dispatch(getMaxBetValue());
  await dispatch(getMaxDonsInRow());
  await dispatch(getMaxBetChanceForBonusBetting());
  await dispatch(getWinProbability())
};
