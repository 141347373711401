import {
    BURNING_TOKEN,
    BURNING_TOKEN_SUCCESS,
    BURNING_TOKEN_FAILURE,
    CLAIMING_TOKEN,
    CLAIMING_TOKEN_SUCCESS,
    CLAIMING_TOKEN_FAILURE,
    ADD_WITHDRAWAL,
    ADD_WITHDRAWAL_SUCCESS,
    ADD_WITHDRAWAL_FAILURE,
    UPDATE_WITHDRAWAL,
    UPDATE_WITHDRAWAL_SUCCESS,
    UPDATE_WITHDRAWAL_FAILURE
  } from "../actionTypes/withdrawals";
import { toggleModal } from "./modal";
import { sendTx, sendTxETH } from "./txNotifications";

import {
  listenToWETHBurnEventFromContract,
  getBurnEventsFromWETHContract,
  getBurnEventsFromBadTokenContract,
  getBurnClaimHash,
  checkBurnClaim,
  burnWETH,
  burnBADToken,
  claimWithdrawalHash,
  listenToBadTokenBurnEventFromContract,
  badbitETHWithdrawalService
} from "../services/withdrawal";
import {
  postEtherMoveMaticToEthereum,
  postBadMoveMaticToEthereum,
  getCheckpointStatus
} from "../services/api";
import { web3Matic } from "../services/contract";
import { getEthBalance } from '../services/ethereum/main';
import { GET_ETH_BALANCE_SUCCESS } from '../actionTypes/account';
import { getEthWalletBalance, getWalletBalance } from './account';

  /**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForBurnEventWETH = () => (dispatch, getState) => {
    try {
      const { account } = getState().account;
      const callback = async (user, amount, transactionHash, blockNumber) => {
        try {
          dispatch({ type : ADD_WITHDRAWAL });

          const hash = await getBurnClaimHash(transactionHash);
          const claimed = await checkBurnClaim(hash);
          const { timestamp } = await web3Matic.eth.getBlock(blockNumber);
          let status = claimed ? "CLAIMED" : "BURNED";

          if (!claimed) {
            const res = await getCheckpointStatus(blockNumber);
            if (!res) status = "WAITING";
          }

          const payload = {
            transactionHash,
            data: {
              claimed,
              status,
              timestamp,
              blockNumber,
              token: "WETH",
              amount: amount.toString(10),
            }
          };

          dispatch({type: ADD_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          dispatch({type: ADD_WITHDRAWAL_FAILURE, payload: error.message});
        }
      };

      listenToWETHBurnEventFromContract(account, callback);
    } catch (err) {
      console.log(err);
    }
  };

  export const refreshCheckpointStatus = () => (dispatch, getState) => {
    setInterval(async() => {
      try {
        const { withdrawals } = getState().withdrawals;

        var i = -1;
        for(const withdrawal in withdrawals){
          i = i++;
          if(withdrawal.status === 'WAITING'){
              const res = await getCheckpointStatus(blockNumber);
              if (!res) return;
            const payload = {
              transactionHash: Object.keys(withdrawals)[i],
              status: "BURNED",
              claimed: false,
            };
            dispatch({type: UPDATE_WITHDRAWAL_SUCCESS, payload})
          }
        }
      }
      catch(err){

      }
    }, 60000);

}

  /**
 * Makes a Event listener to check if a new round has started
 *
 * @return {Function}
 */
export const listenForBurnEventBadToken = () => (dispatch, getState) => {
  try {
    const { account } = getState().account;
    const callback = async (user, amount, transactionHash, blockNumber) => {
      try {
        dispatch({ type : ADD_WITHDRAWAL });

        const hash = await getBurnClaimHash(transactionHash);
        const claimed = await checkBurnClaim(hash);
        const { timestamp } = await web3Matic.eth.getBlock(blockNumber);
        let status = claimed ? "CLAIMED": "BURNED";

        if (!claimed) {
          const res = await getCheckpointStatus(blockNumber);
          if (!res) status = "WAITING";
        }

        const payload = {
          transactionHash,
          data: {
            claimed,
            status,
            timestamp,
            blockNumber,
            token: "BAD",
            amount: amount.toString(10),
          }
        };

        dispatch({ type: ADD_WITHDRAWAL_SUCCESS, payload });
      } catch (error) {
        dispatch({type: ADD_WITHDRAWAL_FAILURE, payload: error.message});

      }

    };

    listenToBadTokenBurnEventFromContract(account, callback);
  } catch (err) {
    console.log(err);
  }
};

  export const getBurnEventsFromWETH = () => async (dispatch, getState) => {
    try {
      const { account } = getState().account;
      const logs = await getBurnEventsFromWETHContract(account);
      for (let i = 0; i < logs.length; i++) {
        try {
          dispatch({ type : ADD_WITHDRAWAL });

          const hash = await getBurnClaimHash(logs[i].transactionHash);
          const claimed = await checkBurnClaim(hash);
          const { timestamp } = await web3Matic.eth.getBlock(logs[i].blockNumber);
          let status = claimed ? "CLAIMED" : "BURNED";

          if (!claimed) {
            const res = await getCheckpointStatus(logs[i].blockNumber);
            if (!res) status = "WAITING";
          }

          const payload = {
            transactionHash: logs[i].transactionHash,
            data: {
              claimed,
              status,
              timestamp,
              blockNumber: logs[i].blockNumber,
              token: "WETH",
              amount: logs[i].amount.toString(10),
            }
          };

          dispatch({ type: ADD_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          console.log(error);
          dispatch({ type: ADD_WITHDRAWAL_FAILURE, payload: error.message });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  export const getBurnEventsFromBadToken = () => async (dispatch, getState) => {
    try {
      const { account } = getState().account;
      const logs = await getBurnEventsFromBadTokenContract(account);
      for (let i = 0; i < logs.length; i++) {
        try {
          dispatch({ type : ADD_WITHDRAWAL });

          const hash = await getBurnClaimHash(logs[i].transactionHash);
          const claimed = await checkBurnClaim(hash);
          const { timestamp } = await web3Matic.eth.getBlock(logs[i].blockNumber);
          let status = claimed ? "CLAIMED" : "BURNED";

          if (!claimed) {
            const res = await getCheckpointStatus(logs[i].blockNumber);
            if (!res) status = "WAITING";
          }

          const payload = {
            transactionHash: logs[i].transactionHash,
            data: {
              claimed,
              status,
              timestamp,
              blockNumber: logs[i].blockNumber,
              token: "BAD",
              amount: logs[i].amount.toString(10),
            }
          };

          dispatch({type: ADD_WITHDRAWAL_SUCCESS, payload})
        } catch (error) {
          console.log(error);
          dispatch({ type: ADD_WITHDRAWAL_FAILURE, payload: error.message });
        }
      }
    } catch (error) {
        console.log(error);
    }
  }

/**
 * withdraw given amount of ETH from Matic Chain
 *
 * @param amount
 * @param type
 */
export const withdrawETH = (amount, type) => {
  return async (dispatch, getState) => {
    dispatch({ type: BURNING_TOKEN });
    try {
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());
      const { account, ethBalance } = getState().account;

      const callback = async (transactionHash) => {
        try {
          dispatch({ type : ADD_WITHDRAWAL });

          const payload = {
            transactionHash,
            data: {
              amount,
              status: "BURNING",
              claimed: false,
              token: "WETH",
            }
          };

          dispatch({ type: ADD_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          dispatch({type: ADD_WITHDRAWAL_FAILURE, payload: error.message});
        }
      };
      if (type === 1 || type === '1') {
        await badbitETHWithdrawalService(
          amount,
          account,
          sendTx,
          dispatch,
          getState
        );

        await postEtherMoveMaticToEthereum(amount, 'BadBit.Games', '');

        const withdrawalInterval = setInterval(async () => {
          const newBalance = await getEthBalance(account);
          console.log('withdrawalInterval: ', ethBalance, newBalance);
          if (newBalance !== ethBalance) {
            console.log('withdrawalInterval inside: ', ethBalance, newBalance);
            dispatch({ type: GET_ETH_BALANCE_SUCCESS, payload: newBalance });
            dispatch(getWalletBalance());
            clearInterval(withdrawalInterval);
          }
        }, 10000);
      } else {
        await burnWETH(
          amount,
          account,
          sendTx,
          dispatch,
          getState,
          callback
        );

        dispatch(getEthWalletBalance());
        dispatch(getWalletBalance());
      }

      dispatch({ type: BURNING_TOKEN_SUCCESS });
    } catch (e) {
      console.log(e);
      dispatch({ type: BURNING_TOKEN_FAILURE, payload: e.message });
    }
  };
};

/**
 * withdraw given amount of ETH from Matic Chain
 *
 * @param amount
 */
export const withdrawBAD = amount => {
  return async (dispatch, getState) => {
    dispatch({ type: BURNING_TOKEN });
    try {
      const { modalOpen } = getState().modal;
      if (modalOpen) dispatch(toggleModal());

      const { account } = getState().account;
      const callback = async (transactionHash) => {
        try {
          dispatch({ type : ADD_WITHDRAWAL });

          const payload = {
            transactionHash,
            data: {
              amount,
              status: "BURNING",
              claimed: false,
              token: "BAD",
            }
          };

          dispatch({ type: ADD_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          dispatch({ type: ADD_WITHDRAWAL_FAILURE, payload: error.message });
        }
      };
      await burnBADToken(
        amount,
        account,
        sendTx,
        dispatch,
        getState,
        callback
      );

      dispatch({ type: BURNING_TOKEN_SUCCESS });
    } catch (e) {
      console.log(e);
      dispatch({ type: BURNING_TOKEN_FAILURE, payload: e.message });
    }
  };
};

/**
 * withdraw given amount of ETH from Matic Chain
 *
 * @param transactionHash
 */
export const claimToken = transactionHash => {
  return async (dispatch, getState) => {
    console.log("Claiming token with hash: ", transactionHash);

    dispatch({ type: CLAIMING_TOKEN });
    try {
      const { account } = getState().account;

      const callback = async () => {
        try {
          dispatch({ type : UPDATE_WITHDRAWAL });

          const payload = {
            transactionHash,
            status: "CLAIMING",
            claimed: false,
          };

          dispatch({ type: UPDATE_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          dispatch({ type: UPDATE_WITHDRAWAL_FAILURE, payload: error.message });
        }
      };

      const finalCallback = async () => {
        try {
          dispatch({type : UPDATE_WITHDRAWAL});
          const { withdrawals } = getState().withdrawals;
          const withdrawal = withdrawals[transactionHash];

          const payload = {
            transactionHash,
            status: "CLAIMED",
            claimed: false,
          };

          if (withdrawal.token === "WETH") {
            await postEtherMoveMaticToEthereum(withdrawal.amount, 'Matic', transactionHash);
          } else if (withdrawal.token === "BAD") {
            await postBadMoveMaticToEthereum(withdrawal.amount);
          }

          dispatch({ type: UPDATE_WITHDRAWAL_SUCCESS, payload });
        } catch (error) {
          dispatch({type: UPDATE_WITHDRAWAL_FAILURE, payload: error.message});
        }
      };

      const { withdrawals } = getState().withdrawals;
      const token = withdrawals[transactionHash].token;

      await claimWithdrawalHash(
        transactionHash,
        account,
        sendTxETH,
        dispatch,
        getState,
        callback,
        finalCallback,
        token
      );

      dispatch({ type: CLAIMING_TOKEN_SUCCESS });
    } catch (e) {
      console.log(e);
      dispatch({ type: CLAIMING_TOKEN_FAILURE, payload: e.message });
    }
  };
};
