import {
  SET_SCRATCH_GAME_MODE,
  SET_SCRATCH_NUM_OF_CARDS,
  SET_SCRATCH_LISTENERS_INITIATED,
  SET_SCRATCH_LAST_ROUND_BLOCK,
  SET_SCRATCH_SHOW_CONTROLS,
  SET_SCRATCH_BET_AMOUNT,
  SET_SCRATCH_WIN_CHANCE,
  SET_SCRATCH_PAUSED,
  SET_SCRATCH_WIN_PRIZE,
  SET_CARDS,
  SET_ACTIVE_CARD,
  GET_SCRATCH_GAME_STATE_REQUEST,
  GET_SCRATCH_GAME_STATE_SUCCESS,
  GET_SCRATCH_GAME_STATE_ERROR,
  GET_SCRATCH_ROUND_STATE_REQUEST,
  GET_SCRATCH_ROUND_STATE_SUCCESS,
  GET_SCRATCH_ROUND_STATE_ERROR,
  GET_ACC_SCRATCH_TOTAL_BETS_REQUEST,
  GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS,
  GET_ACC_SCRATCH_TOTAL_BETS_ERROR,
  GET_SCRATCH_INIT_DATA_REQUEST,
  GET_SCRATCH_INIT_DATA_SUCCESS,
  GET_SCRATCH_INIT_DATA_ERROR,
  PLACE_SCRATCH_BET_REQUEST,
  PLACE_SCRATCH_BET_SUCCESS,
  PLACE_SCRATCH_BET_ERROR,
  START_SCRATCH_AUTO_BET_ERROR,
  START_SCRATCH_AUTO_BET_REQUEST,
  START_SCRATCH_AUTO_BET_SUCCESS,
  STOP_SCRATCH_AUTO_BET_ERROR,
  STOP_SCRATCH_AUTO_BET_REQUEST,
  STOP_SCRATCH_AUTO_BET_SUCCESS,
  UPDATE_SCRATCH_AUTO_BET_REQUEST,
  UPDATE_SCRATCH_AUTO_BET_SUCCESS,
  UPDATE_SCRATCH_AUTO_BET_ERROR,
  SCRATCH_AUTO_BET_STARTED,
  SCRATCH_AUTO_BET_STOPPED,
  SET_SCRATCH_AUTO_BET_DATA,
  DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION,
} from "../../actionTypes/games/scratch";

const INITIAL_STATE = {
  gettingScratchInitData: false,
  gettingScratchInitDataErr: "",

  scratchTotalBets: 0,
  gettingGameState: false,
  gettingGameStateErr: "",

  scratchPaused: false,
  showControls: true,

  scratchLastRoundBlock: 0,
  roundState: "",
  bettingRound: 0,

  gettingRoundState: false,
  gettingRoundStateErr: "",

  gameMode: 0,
  numberOfCards: 1,

  costPerCard: "0",
  betAmount: "0",

  placingBet: false,
  placingBetError: "",

  scratchListenersInitiated: false,

  cards: [],
  activeCard: 0,
  winChance: 0,
  winPrize: "0",

  isAutoBetting: false,
  autoBettingRounds: 0,
  autoBettingCurrentRound: 0,
  autoBetBalanceNotificationDismissed: false,

  updatingAutoBetting: false,
  updatingAutoBettingErr: "",

  startingAutoBetting: false,
  startingAutoBettingErr: "",
  stoppingAutoBetting: false,
  stoppingAutoBettingErr: "",
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SCRATCH_GAME_MODE:
      return { ...state, gameMode: Number(payload) };

    case SET_SCRATCH_NUM_OF_CARDS:
      return { ...state, numberOfCards: Number(payload) }

    case SET_SCRATCH_BET_AMOUNT:
      return { ...state, costPerCard: payload.costPerCard, betAmount: payload.betAmount }

    case GET_SCRATCH_GAME_STATE_REQUEST:
      return {
        ...state,
        gettingGameState: true,
        gettingGameStateErr: "",
      };

    case GET_SCRATCH_GAME_STATE_SUCCESS:
      return {
        ...state,
        gettingGameState: false,
        gettingGameStateErr: "",
      };

    case GET_SCRATCH_GAME_STATE_ERROR:
      return {
        ...state,
        gettingGameState: false,
        gettingGameStateErr: payload,
      };

    case GET_SCRATCH_ROUND_STATE_REQUEST:
      return {
        ...state,
        gettingRoundState: true,
        gettingRoundStateErr: "",
      };

    case GET_SCRATCH_ROUND_STATE_SUCCESS:
      return {
        ...state,
        roundState: payload.status,
        bettingRound: Number(payload.roundNumber),
        gettingRoundState: false,
        gettingRoundStateErr: "",
      };

    case GET_SCRATCH_ROUND_STATE_ERROR:
      return {
        ...state,
        gettingRoundState: false,
        gettingRoundStateErr: payload,
      };

    case GET_ACC_SCRATCH_TOTAL_BETS_REQUEST:
      return {
        ...state,
        gettingScratchTotalBets: true,
        gettingScratchTotalBetsErr: "",
      };

    case GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS:
      return {
        ...state,
        scratchTotalBets: Number(payload),
        gettingScratchTotalBets: false,
        gettingScratchTotalBetsErr: "",
      };

    case GET_ACC_SCRATCH_TOTAL_BETS_ERROR:
      return {
        ...state,
        gettingScratchTotalBets: false,
        gettingScratchTotalBetsErr: payload,
      };

    case GET_SCRATCH_INIT_DATA_REQUEST:
      return {
        ...state,
        gettingScratchInitData: true,
        gettingScratchInitDataErr: "",
      };

    case GET_SCRATCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        gettingScratchInitData: false,
        gettingScratchInitDataErr: "",
      };

    case GET_SCRATCH_INIT_DATA_ERROR:
      return {
        ...state,
        gettingScratchInitData: false,
        gettingScratchInitDataErr: payload,
      };

    case PLACE_SCRATCH_BET_REQUEST:
      return {
        ...state,
        placingBet: true,
        placingBetError: "",
      };

    case PLACE_SCRATCH_BET_SUCCESS:
      return {
        ...state,
        placingBet: false,
        placingBetError: "",
      };

    case PLACE_SCRATCH_BET_ERROR:
      return {
        ...state,
        placingBet: false,
        placingBetError: payload,
      };

    case SET_SCRATCH_WIN_CHANCE:
      return { ...state, winChance: payload };

    case SET_SCRATCH_LISTENERS_INITIATED:
      return {
        ...state,
        scratchListenersInitiated: true,
      };

    case SET_SCRATCH_LAST_ROUND_BLOCK:
      return {
        ...state,
        scratchLastRoundBlock: payload,
      };

    case SET_SCRATCH_SHOW_CONTROLS:
      return {
        ...state,
        showControls: payload,
      };

    case SET_CARDS:
      return {
        ...state,
        cards: payload,
      };

    case SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: Number(payload),
      }

    case SET_SCRATCH_PAUSED:
      return {
        ...state,
        scratchPaused: payload,
      };

    case SET_SCRATCH_WIN_PRIZE:
      return {
        ...state,
        winPrize: payload,
      };

    case START_SCRATCH_AUTO_BET_REQUEST: return {
      ...state,
      startingAutoBetting: true,
      startingAutoBettingErr: "",
    };

    case START_SCRATCH_AUTO_BET_SUCCESS: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: "",
    };

    case SCRATCH_AUTO_BET_STARTED: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: +payload
    };

    case START_SCRATCH_AUTO_BET_ERROR: return {
      ...state,
      startingAutoBetting: false,
      startingAutoBettingErr: payload,
    };

    case UPDATE_SCRATCH_AUTO_BET_REQUEST: return {
      ...state,
      updatingAutoBetting: true,
      updatingAutoBettingErr: "",
    };

    case UPDATE_SCRATCH_AUTO_BET_SUCCESS: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: "",
      autoBettingCurrentRound: payload
    };

    case UPDATE_SCRATCH_AUTO_BET_ERROR: return {
      ...state,
      updatingAutoBetting: false,
      updatingAutoBettingErr: payload,
    };

    case STOP_SCRATCH_AUTO_BET_REQUEST: return {
      ...state,
      stoppingAutoBetting: true,
      stoppingAutoBettingErr: "",
    };

    case STOP_SCRATCH_AUTO_BET_SUCCESS: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: "",
    };

    case SCRATCH_AUTO_BET_STOPPED: return {
      ...state,
      isAutoBetting: false,
      autoBettingRounds: 0,
      autoBettingCurrentRound: 0,
      autoBetBalanceNotificationDismissed: false,
    };

    case STOP_SCRATCH_AUTO_BET_ERROR: return {
      ...state,
      stoppingAutoBetting: false,
      stoppingAutoBettingErr: payload,
    };

    case SET_SCRATCH_AUTO_BET_DATA: return {
      ...state,
      isAutoBetting: true,
      autoBettingRounds: payload.autoBettingRounds,
      autoBettingCurrentRound: payload.autoBettingCurrentRound,
    };

    case DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION: return {
      ...state,
      autoBetBalanceNotificationDismissed: true,
    };

    default:
      return state;
  }
};
