import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import {formatAcc, formatNumber} from '../../../../services/utils';
import {DP, SELECT_CUSTOM_STYLE_SMALL} from '../../../../config/constants';
import { toggleModal } from "../../../../actions/modal";
import { weiToEth } from "../../../../services/ethereum/main";
import { claimToken } from '../../../../actions/withdrawal';
import { changePage } from '../../../../actions/pagination';
import { getAssetMovementHistory } from '../../../../actions/assetMovmentlHistory';

import Loader from "../../../Loader/Loader";
import Pagination from "../../../Pagination/Pagination";
import "./AssetMovementHistory.scss";
import Select from 'react-select';

const historyOptions = [
  { value: "withdrawals", label: "Withdrawals" },
  { value: "deposits", label: "Deposits" },
];

const assets = [
  { value: "ETH", label: "ETH" },
  { value: "BAD", label: "BAD" },
];

class AssetMovementHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedHistoryOption: historyOptions[0], selectedAsset: assets[0] }
    this.onHistoryChange = this.onHistoryChange.bind(this);
    this.onAssetChange = this.onAssetChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPage, getAssetMovementHistory } = this.props;

    if (prevProps.currentPage !== currentPage) {
      getAssetMovementHistory(currentPage, 5, this.state.selectedAsset.value);
    }
  }

  onAssetChange = (data) => {
    const { selectedAsset } = this.state;
    if (data.value === selectedAsset.value) return;
    this.setState({ selectedAsset: data });
    const { currentPage, getAssetMovementHistory } = this.props;
    getAssetMovementHistory(currentPage, 5, data.value);
  };

  onHistoryChange = (data) => {
    const { selectedHistoryOption } = this.state;
    if (data.value === selectedHistoryOption.value) return;
    this.setState({ selectedHistoryOption: data });
    const { currentPage, getAssetMovementHistory } = this.props;
    if (data.value === "deposits") getAssetMovementHistory(currentPage, 5);
  };

  render() {
    const {
      gettingMovementHistory,
      gettingMovementHistoryError,
      assetMovementHistory,
      withdrawalsHistory,
      toggleModal,
      claimToken,
      network,
    } = this.props;

    const { selectedHistoryOption, selectedAsset } = this.state;

    const data = Object.values(withdrawalsHistory);

    return (
      <div className="modal__content modal__content--withdrawal-history">
        {gettingMovementHistory && (
          <Loader text="Loading asset movement history..." size={85} />
        )}
        {!gettingMovementHistory &&
          <Fragment>
            <p className="title">Asset Movement History</p>
            <div className="select-options">
              <div className="select-wrap">
                <Select
                  className="select"
                  classNamePrefix="select--small"
                  styles={SELECT_CUSTOM_STYLE_SMALL}
                  options={historyOptions}
                  value={selectedHistoryOption}
                  onChange={this.onHistoryChange}
                  isSearchable={false}
                />
              </div>

              {selectedHistoryOption.value === historyOptions[1].value && (
                <div className="select-wrap">
                  <span>Asset:</span>
                  <Select
                    className="select"
                    classNamePrefix="select--small"
                    styles={SELECT_CUSTOM_STYLE_SMALL}
                    options={assets}
                    value={selectedAsset}
                    onChange={this.onAssetChange}
                    isSearchable={false}
                    menuPlacement="bottom"
                  />
                </div>
              )}
            </div>

          </Fragment>
        }

        {selectedHistoryOption.value === historyOptions[0].value && data.length !== 0 && (
            <Fragment>
              <table className="withdrawal-history">
                <thead className="withdrawal-history__head">
                  <tr>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Token symbol</th>
                    <th>Service</th>
                  </tr>
                </thead>
                <tbody className="withdrawal-history__body">
                  {data.map(
                    ({ status, amount, token, timestamp, service }, index) => (
                      <tr
                        className="withdrawal-history__single"
                        key={`${index}-${amount}-${status}-${token}`}
                      >
                        <td>
                          {status === "BURNED"
                          ? (<button className="btn btn--primary"
                                     onClick={() => claimToken((Object.keys(withdrawalsHistory))[index])}>
                              CLAIM
                            </button>)
                          : status}
                        </td>
                        <td className="amount">
                          <Tooltip title={`${weiToEth(amount)}`} theme="roll">
                            <span>{formatNumber(weiToEth(amount), DP + 3)}</span>
                          </Tooltip>
                        </td>
                        <td>{token}</td>
                        {token === "WETH" && <td>Matic</td>}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Fragment>
          )}
        {selectedHistoryOption.value === historyOptions[0].value && data.length === 0 &&
          gettingMovementHistoryError === "" && (
            <p className="description description--error">
              You have no withdraw history.
            </p>
          )}

        {selectedHistoryOption.value === historyOptions[1].value &&
        !gettingMovementHistory &&
        assetMovementHistory.data.length !== 0 &&
        gettingMovementHistoryError === "" && (
          <Fragment>
            <table className="withdrawal-history">
              <thead className="withdrawal-history__head">
              <tr>
                <th>Date</th>
                <th>Amount</th>
                {selectedAsset.value === "ETH" && <th>Service</th>}
              </tr>
              </thead>
              <tbody className="withdrawal-history__body">
              {assetMovementHistory.data.map(
                ({ amount, date, txHash, service }, index) => (
                  <tr
                    className="withdrawal-history__single"
                    key={`${index}-${amount}`}
                  >
                    <td>{date}</td>

                    <td className="amount">
                      <Tooltip title={amount} theme="roll">
                        <span>{formatNumber(amount, DP + 3)}</span> ETH
                      </Tooltip>
                    </td>

                    {txHash &&
                    <td>
                      <Tooltip title={txHash} theme="roll">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://${
                            network === 3 ? "testnetv3-explorer." : "explorer."
                          }matic.network/tx/${txHash}`}
                        >
                          {formatAcc(txHash, 6, 6)}
                        </a>
                      </Tooltip>
                    </td>
                    }
                    {selectedAsset.value === "ETH" && <td>{service}</td>}
                  </tr>
                )
              )}
              </tbody>
            </table>
            <Pagination
              totalPages={assetMovementHistory.totalPages}
              perPage={assetMovementHistory.perPage}
              currentPage={assetMovementHistory.currentPage}
            />
          </Fragment>
        )}
        {selectedHistoryOption.value === historyOptions[1].value && !gettingMovementHistory && gettingMovementHistoryError !== "" && (
          <p className="description description--error">
            Oops, there was an error getting deposit history data.
          </p>
        )}
        {selectedHistoryOption.value === historyOptions[1].value && !gettingMovementHistory &&
        assetMovementHistory.data.length === 0 &&
        gettingMovementHistoryError === "" && (
          <p className="description description--error">
            You have no deposit history.
          </p>
        )}
        {!gettingMovementHistory && (
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        )}
      </div>
    );
  }
}

AssetMovementHistory.propTypes = {
  getAssetMovementHistory: PropTypes.func.isRequired,
  gettingMovementHistory: PropTypes.bool.isRequired,
  gettingMovementHistoryError: PropTypes.string.isRequired,
  assetMovementHistory: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  claimToken: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  network: PropTypes.number.isRequired,
};

const mapStateToProps = ({ assetMovementHistory, withdrawals, pagination, account }) => ({
  gettingMovementHistory: assetMovementHistory.gettingMovementHistory,
  gettingMovementHistoryError: assetMovementHistory.gettingMovementHistoryError,
  assetMovementHistory: assetMovementHistory.assetMovementHistory,
  withdrawalsHistory: withdrawals.withdrawals,
  currentPage: pagination.currentPage,
  network: account.network,
});

const mapDispatchToProps = {
  claimToken,
  getAssetMovementHistory,
  toggleModal,
  changePage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetMovementHistory);
