import React, {Fragment} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getWinningMultiplier } from "../../../../actions/contractConstants/scratch";

import BetData from "../../Shared/BetData/BetData";
import Ethereum from "../../../Decorative/ScratchCardItems/Ethereum";
import MakerDAO from "../../../Decorative/ScratchCardItems/MakerDAO";
import BAT from "../../../Decorative/ScratchCardItems/BAT";
import ChainLink from "../../../Decorative/ScratchCardItems/ChainLink";
import Matic from "../../../Decorative/ScratchCardItems/Matic";
import Augur from "../../../Decorative/ScratchCardItems/Augur";
import "./WinType.scss";
import Loader from "../../../Loader/Loader";

const WIN_TYPES = [Ethereum, MakerDAO, BAT, ChainLink, Matic, Augur];

const WinType = ({ gameMode, winningMultiplier, settingInitData, gettingScratchInitData, gettingWinningMultiplier }) => {
  const loading = settingInitData || gettingScratchInitData || gettingWinningMultiplier;
  return (
    <section className="main__section main_section--win-type">
      {loading && <Loader size={85} isFullScreen={false} text={"Loading..."}/>}
      {!loading &&
      <Fragment>
        <header className="main__section__header">
          <span className="main__section__heading">Match 3 or More</span>
        </header>
        <div className="win-type">
          {WIN_TYPES.map((Component, index) => (
            <div className="win-type__single" key={index}>
              <Component/>
              <span className="win-type__amount">
              {!winningMultiplier.length
                ? ''
                : `${getWinningMultiplier(index, gameMode, winningMultiplier)}x`
              }
            </span>
            </div>
          ))}
        </div>
        <BetData game="scratch"/>
      </Fragment>
      }
    </section>
  )
}

WinType.propTypes = {
  gameMode: PropTypes.number.isRequired,
  winningMultiplier: PropTypes.array.isRequired,
};

const mapStateToProps = ({ games, gameScratch, scratchConstants }) => ({
  settingInitData: games.settingInitData,
  gameMode: gameScratch.gameMode,
  gettingScratchInitData: gameScratch.gettingScratchInitData,
  winningMultiplier: scratchConstants.winningMultiplier,
  gettingWinningMultiplier: scratchConstants.gettingWinningMultiplier,
});

export default connect(mapStateToProps)(WinType);
