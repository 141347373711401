import React from "react";

const IndividualNumber = () => {
  return (
    <div className="icon">
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2672 32.5384C26.1489 32.5384 32.5384 26.1489 32.5384 18.2672C32.5384 10.3854 26.1489 3.99599 18.2672 3.99599C10.3854 3.99599 3.99599 10.3854 3.99599 18.2672C3.99599 26.1489 10.3854 32.5384 18.2672 32.5384ZM18.2672 34.2509C27.0948 34.2509 34.2509 27.0948 34.2509 18.2672C34.2509 9.43961 27.0948 2.28345 18.2672 2.28345C9.43961 2.28345 2.28345 9.43961 2.28345 18.2672C2.28345 27.0948 9.43961 34.2509 18.2672 34.2509Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2672 26.8299C22.9963 26.8299 26.8299 22.9963 26.8299 18.2672C26.8299 13.5381 22.9963 9.70449 18.2672 9.70449C13.5381 9.70449 9.70449 13.5381 9.70449 18.2672C9.70449 22.9963 13.5381 26.8299 18.2672 26.8299ZM18.2672 28.5425C23.9421 28.5425 28.5425 23.9421 28.5425 18.2672C28.5425 12.5923 23.9421 7.99194 18.2672 7.99194C12.5923 7.99194 7.99194 12.5923 7.99194 18.2672C7.99194 23.9421 12.5923 28.5425 18.2672 28.5425Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2672 21.1212C19.8436 21.1212 21.1215 19.8433 21.1215 18.267C21.1215 16.6906 19.8436 15.4127 18.2672 15.4127C16.6909 15.4127 15.413 16.6906 15.413 18.267C15.413 19.8433 16.6909 21.1212 18.2672 21.1212ZM18.2672 22.8338C20.7894 22.8338 22.834 20.7891 22.834 18.267C22.834 15.7448 20.7894 13.7002 18.2672 13.7002C15.7451 13.7002 13.7004 15.7448 13.7004 18.267C13.7004 20.7891 15.7451 22.8338 18.2672 22.8338Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 17.4109H36.5343V19.1234H0V17.4109Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1233 0L19.1233 36.5343L17.4107 36.5343L17.4107 -7.48577e-08L19.1233 0Z"
            fill="black"
          />
        </g>
      </svg>
    </div>
  );
};

export default IndividualNumber;
