export const SET_SCRATCH_LISTENERS_INITIATED = "SET_SCRATCH_LISTENERS_INITIATED";
export const SET_SCRATCH_GAME_MODE = "SET_SCRATCH_GAME_MODE";
export const SET_SCRATCH_NUM_OF_CARDS = "SET_SCRATCH_NUM_OF_CARDS";
export const SET_SCRATCH_BET_AMOUNT = "SET_SCRATCH_BET_AMOUNT";
export const SET_SCRATCH_LAST_ROUND_BLOCK = "SET_SCRATCH_LAST_ROUND_BLOCK";
export const SET_SCRATCH_SHOW_CONTROLS = "SET_SCRATCH_SHOW_CONTROLS";
export const SET_CARDS = "SET_CARDS";
export const SET_ACTIVE_CARD= "SET_ACTIVE_CARD";
export const SET_SCRATCH_WIN_CHANCE = "SET_SCRATCH_WIN_CHANCE";
export const SET_SCRATCH_PAUSED = "SET_SCRATCH_PAUSED";
export const SET_SCRATCH_WIN_PRIZE = "SET_SCRATCH_WIN_PRIZE";
export const SET_SCRATCH_AUTO_BET_DATA = "SET_SCRATCH_AUTO_BET_DATA";

export const GET_SCRATCH_GAME_STATE_REQUEST = "GET_SCRATCH_GAME_STATE_REQUEST";
export const GET_SCRATCH_GAME_STATE_SUCCESS = "GET_SCRATCH_GAME_STATE_SUCCESS";
export const GET_SCRATCH_GAME_STATE_ERROR = "GET_SCRATCH_GAME_STATE_ERROR";

export const GET_SCRATCH_ROUND_STATE_REQUEST = "GET_SCRATCH_ROUND_STATE_REQUEST";
export const GET_SCRATCH_ROUND_STATE_SUCCESS = "GET_SCRATCH_ROUND_STATE_SUCCESS";
export const GET_SCRATCH_ROUND_STATE_ERROR = "GET_SCRATCH_ROUND_STATE_ERROR";

export const GET_ACC_SCRATCH_TOTAL_BETS_REQUEST = "GET_ACC_SCRATCH_TOTAL_BETS_REQUEST";
export const GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS = "GET_ACC_SCRATCH_TOTAL_BETS_SUCCESS";
export const GET_ACC_SCRATCH_TOTAL_BETS_ERROR = "GET_ACC_SCRATCH_TOTAL_BETS_ERROR";

export const GET_SCRATCH_INIT_DATA_REQUEST = "GET_SCRATCH_INIT_DATA_REQUEST";
export const GET_SCRATCH_INIT_DATA_SUCCESS = "GET_SCRATCH_INIT_DATA_SUCCESS";
export const GET_SCRATCH_INIT_DATA_ERROR = "GET_SCRATCH_INIT_DATA_ERROR";

export const PLACE_SCRATCH_BET_REQUEST = "PLACE_SCRATCH_BET_REQUEST";
export const PLACE_SCRATCH_BET_SUCCESS = "PLACE_SCRATCH_BET_SUCCESS";
export const PLACE_SCRATCH_BET_ERROR = "PLACE_SCRATCH_BET_ERROR";

export const START_SCRATCH_AUTO_BET_REQUEST = "START_SCRATCH_AUTO_BET_REQUEST";
export const START_SCRATCH_AUTO_BET_SUCCESS = "START_SCRATCH_AUTO_BET_SUCCESS";
export const START_SCRATCH_AUTO_BET_ERROR = "START_SCRATCH_AUTO_BET_ERROR";

export const STOP_SCRATCH_AUTO_BET_REQUEST = "STOP_SCRATCH_AUTO_BET_REQUEST";
export const STOP_SCRATCH_AUTO_BET_SUCCESS = "STOP_SCRATCH_AUTO_BET_SUCCESS";
export const STOP_SCRATCH_AUTO_BET_ERROR = "STOP_SCRATCH_AUTO_BET_ERROR";

export const SCRATCH_AUTO_BET_STARTED = "SCRATCH_AUTO_BET_STARTED";
export const SCRATCH_AUTO_BET_STOPPED = "SCRATCH_AUTO_BET_STOPPED";

export const UPDATE_SCRATCH_AUTO_BET_REQUEST = "UPDATE_SCRATCH_AUTO_BET_REQUEST";
export const UPDATE_SCRATCH_AUTO_BET_SUCCESS = "UPDATE_SCRATCH_AUTO_BET_SUCCESS";
export const UPDATE_SCRATCH_AUTO_BET_ERROR = "UPDATE_SCRATCH_AUTO_BET_ERROR";

export const DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION = "DISMISS_SCRATCH_AUTO_BET_BALANCE_NOTIFICATION";
