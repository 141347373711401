import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import RollOver from "../../../Decorative/GameTypeIcons/RollOver/RollOver";
import RollUnder from "../../../Decorative/GameTypeIcons/RollUnder/RollUnder";
import IndividualNumber from "../../../Decorative/GameTypeIcons/IndividualNumber/IndividualNumber";
import EvenOrOdd from "../../../Decorative/GameTypeIcons/EvenOrOdd/EvenOrOdd";
import DragonOrTiger from "../../../Decorative/GameTypeIcons/DragonOrTiger/DragonOrTiger";
import { spinGameTypeToColor } from "../../../../services/utils";
import Wheel from "../../SpinGame/Wheel/Wheel";
import Dice from "../../DiceGame/Dice/Dice";

const BetPlaced = ({
  lastUserSelectedGameType,
  lastUserSelectedNumber,
  lastSelectedNumber,
  lastSelectedGameType,
  showVisuals,
  lastRoundPlayed,
  bettingRound,
  roundState,
  activeGame
}) => (
  <Fragment>
    <div className="selected-bet">
      {lastRoundPlayed === bettingRound && <p className="title">Your bet:</p>}
      {activeGame === "roll" && (
        <div className="game-type">
          {lastRoundPlayed === bettingRound && (
            <Fragment>
              <span className="game-type__icon">
                {lastUserSelectedGameType === 0 && <RollOver />}
                {lastUserSelectedGameType === 1 && <RollUnder />}
                {lastUserSelectedGameType === 2 && <IndividualNumber />}
                {lastUserSelectedGameType === 3 && <EvenOrOdd />}
                {lastUserSelectedGameType === 4 && <DragonOrTiger />}
              </span>
              <div className="game-type__text">
                {lastUserSelectedGameType === 0 && (
                  <Fragment>
                    <span className="num">{lastUserSelectedNumber}</span>
                    <span className="txt">Roll Over</span>
                  </Fragment>
                )}
                {lastUserSelectedGameType === 1 && (
                  <Fragment>
                    <span className="num">{lastUserSelectedNumber}</span>
                    <span className="txt">Roll Under</span>
                  </Fragment>
                )}
                {lastUserSelectedGameType === 3 && (
                  <span className="num">
                    {lastUserSelectedNumber === 0 && "Even"}
                    {lastUserSelectedNumber === 1 && "Odd"}
                  </span>
                )}
                {lastUserSelectedGameType === 4 && (
                  <span className="num">
                    {lastUserSelectedNumber === 0 && "Dragon"}
                    {lastUserSelectedNumber === 1 && "Tiger"}
                    {lastUserSelectedNumber === 2 && "Tie"}
                  </span>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
      {activeGame === "spin" && (
        <div className="game-type">
          {lastRoundPlayed === bettingRound && (
            <span
              className={`game-type__icon ${spinGameTypeToColor(
                lastUserSelectedGameType
              )}`}
            />
          )}
        </div>
      )}
    </div>
    <div>
      <p className="title">
        {activeGame === "roll" &&
          !showVisuals &&
          "Waiting for other players to join..."}
        {activeGame === "roll" &&
          showVisuals &&
          roundState !== "finished" &&
          "Rolling..."}
        {activeGame === "roll" &&
          showVisuals &&
          roundState === "finished" &&
          "Result:"}
        {activeGame === "spin" &&
          !showVisuals &&
          "Waiting for other players to join..."}
        {activeGame === "spin" &&
          showVisuals &&
          roundState !== "finished" &&
          "Spinning..."}
        {activeGame === "spin" &&
          showVisuals &&
          roundState === "finished" &&
          "Result:"}
      </p>
      {activeGame === "roll" &&
        showVisuals &&
        (roundState === "closed" || roundState === "finished") && (
          <Dice
            lastSelectedNumber={lastSelectedNumber}
            roundState={roundState}
          />
        )}
      {activeGame === "spin" &&
        showVisuals &&
        (roundState === "closed" || roundState === "finished") && (
          <Wheel
            spin={true}
            roundState={roundState}
            lastSelectedGameType={lastSelectedGameType}
          />
        )}
    </div>
  </Fragment>
);

BetPlaced.propTypes = {
  activeGame: PropTypes.string.isRequired
};

const mapStateToProps = ({ games }) => ({
  activeGame: games.activeGame
});

export default connect(mapStateToProps)(BetPlaced);
