import React from "react";

export default function SpinLogo({ width = 130 }) {
  return (
    <svg
      width={width}
      height={width / 3.95833333333}
      viewBox="0 0 95 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3499 10.1721C40.9659 10.5455 41.4792 11.0775 41.8899 11.7681C42.3192 12.4588 42.5339 13.3828 42.5339 14.5401C42.5339 15.6788 42.1699 16.7148 41.4419 17.6481C40.0979 19.4028 38.0539 20.2801 35.3099 20.2801C34.1152 20.2801 32.9299 20.1121 31.7539 19.7761C30.5966 19.4215 29.7379 19.0668 29.1779 18.7121L28.3379 18.2081L29.5419 15.8001C30.8859 16.4535 32.0992 16.7801 33.1819 16.7801C34.2646 16.7801 34.8059 16.4348 34.8059 15.7441C34.8059 15.3521 34.5912 14.9881 34.1619 14.6521C33.7512 14.2975 33.2379 13.9615 32.6219 13.6441C32.0246 13.3268 31.4179 12.9628 30.8019 12.5521C30.2046 12.1228 29.6912 11.5348 29.2619 10.7881C28.8512 10.0228 28.6459 9.13614 28.6459 8.12814C28.6459 6.33614 29.3272 4.97347 30.6899 4.04014C32.0712 3.1068 33.7326 2.64014 35.6739 2.64014C36.8312 2.64014 37.9139 2.78014 38.9219 3.06014C39.9486 3.34014 40.6766 3.62014 41.1059 3.90014L41.7779 4.32014L40.6859 6.70014C39.7339 6.28947 38.7726 6.08414 37.8019 6.08414C36.8499 6.08414 36.3739 6.44814 36.3739 7.17614C36.3739 7.56814 36.5792 7.9228 36.9899 8.24014C37.4192 8.55747 37.9326 8.86547 38.5299 9.16414C39.1459 9.44414 39.7526 9.78014 40.3499 10.1721Z"
        fill="white"
      />
      <path
        d="M53.2511 11.5161C54.1098 11.5161 54.7911 11.2548 55.2951 10.7321C55.7991 10.2095 56.0511 9.5748 56.0511 8.82814C56.0511 8.0628 55.8084 7.49347 55.3231 7.12014C54.8564 6.72814 54.2498 6.53214 53.5031 6.53214H52.3831L51.9071 11.5161H53.2511ZM55.0151 15.1001H51.5431L51.0671 20.0001H45.6071L43.9271 3.03214H56.4151C58.3751 3.03214 59.9618 3.53614 61.1751 4.54414C62.4071 5.53347 63.0231 6.96147 63.0231 8.82814C63.0231 9.9668 62.7898 10.9561 62.3231 11.7961C61.8751 12.6361 61.2591 13.2895 60.4751 13.7561C58.9631 14.6521 57.1431 15.1001 55.0151 15.1001Z"
        fill="white"
      />
      <path
        d="M65.5407 20.0001L63.8607 3.03214H72.6807L71.0007 20.0001H65.5407Z"
        fill="white"
      />
      <path
        d="M83.7473 3.03214L88.7033 12.9161L87.9193 3.03214H94.3873L92.9873 20.0001H84.7833L80.0793 10.7601L79.1553 20.0001H75.4873L74.0873 3.03214H83.7473Z"
        fill="white"
      />
      <path
        d="M0.287598 12C0.287598 13.0236 0.421776 14.0428 0.686704 15.0315L12.0005 12L0.287598 12Z"
        fill="white"
      />
      <path
        d="M1.85671 17.8563C2.36851 18.7428 2.99431 19.5583 3.7181 20.2821L12.0005 12L1.85671 17.8563Z"
        fill="white"
      />
      <path
        d="M6.14392 22.1438C7.03038 22.6556 7.98012 23.0489 8.96884 23.3139L12.0005 12L6.14392 22.1438Z"
        fill="white"
      />
      <path
        d="M12.0005 23.7129C13.0241 23.7129 14.0433 23.5787 15.032 23.3138L12.0005 12L12.0005 23.7129Z"
        fill="white"
      />
      <path
        d="M17.8568 22.1438C18.7433 21.632 19.5588 21.0062 20.2826 20.2824L12.0005 12L17.8568 22.1438Z"
        fill="white"
      />
      <path
        d="M22.144 17.8565C22.6558 16.9701 23.0492 16.0203 23.3141 15.0316L12.0005 12L22.144 17.8565Z"
        fill="white"
      />
      <path
        d="M23.7131 12C23.7131 10.9764 23.5789 9.9572 23.314 8.96847L12.0005 12L23.7131 12Z"
        fill="white"
      />
      <path
        d="M22.144 6.14366C21.6322 5.2572 21.0064 4.44164 20.2826 3.71785L12.0005 12L22.144 6.14366Z"
        fill="white"
      />
      <path
        d="M17.8567 1.85622C16.9703 1.34442 16.0205 0.951028 15.0318 0.686101L12.0005 12L17.8567 1.85622Z"
        fill="white"
      />
      <path
        d="M12.0002 0.287109C10.9766 0.287109 9.9574 0.421288 8.96867 0.686216L12.0005 12L12.0002 0.287109Z"
        fill="white"
      />
      <path
        d="M6.14386 1.85622C5.2574 2.36802 4.44184 2.99382 3.71805 3.71762L12.0005 12L6.14386 1.85622Z"
        fill="white"
      />
      <path
        d="M1.85671 6.14343C1.34491 7.0299 0.951517 7.97963 0.68659 8.96835L12.0005 12L1.85671 6.14343Z"
        fill="white"
      />
    </svg>
  );
}
