import {
  GET_PREVIOUS_ROLLS_REQUEST,
  GET_PREVIOUS_ROLLS_SUCCESS,
  GET_PREVIOUS_ROLLS_FAILURE,
  GET_PREVIOUS_SPINS_REQUEST,
  GET_PREVIOUS_SPINS_SUCCESS,
  GET_PREVIOUS_SPINS_FAILURE,
  GET_PREVIOUS_ROULETTE_ROLLS_REQUEST,
  GET_PREVIOUS_ROULETTE_ROLLS_FAILURE,
  GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS,
  ADD_ROLL,
  ADD_SPIN,
  ADD_ROULETTE_ROLLS
} from "../actionTypes/betHistory";

const INITIAL_STATE = {
  gettingHistory: false,
  gettingHistoryError: "",
  history: [],
  page: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PREVIOUS_ROLLS_REQUEST:
      return { ...state, gettingHistory: true, gettingHistoryError: "" };

    case GET_PREVIOUS_ROLLS_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: "",
        history: payload.history,
        page: payload.page,
      };

    case GET_PREVIOUS_ROLLS_FAILURE:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: payload
      };

    case ADD_ROLL:
      return {
        ...state,
        history: payload
      };

    case GET_PREVIOUS_SPINS_REQUEST:
      return { ...state, gettingHistory: true, gettingHistoryError: "" };

    case GET_PREVIOUS_SPINS_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: "",
        history: payload.history,
        page: payload.page,
      };

    case GET_PREVIOUS_SPINS_FAILURE:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: payload
      };

    case ADD_SPIN:
      return {
        ...state,
        history: payload
      };

    case GET_PREVIOUS_ROULETTE_ROLLS_REQUEST:
      return {
        ...state,
        gettingHistory: true,
        gettingHistoryError: ""
      };

    case GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: "",
        history: payload.history,
        page: payload.page
      };

    case GET_PREVIOUS_ROULETTE_ROLLS_FAILURE:
      return {
        ...state,
        gettingHistory: false,
        gettingHistoryError: payload
      };

    case ADD_ROULETTE_ROLLS:
      return {
        ...state,
        history: payload
      };

    default:
      return state;
  }
};
