import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { formatNumber } from "../../../../services/utils";
import { DP } from "../../../../config/constants";
import { ethToWei, weiToEth } from "../../../../services/ethereum/main";
import BigNumber from "bignumber.js";

class BetSize extends Component {
  constructor(props) {
    super(props);
    this.state = { currAmount: "0" };
    this.handleBetAmount = this.handleBetAmount.bind(this);
    this.setBetAmountToLocalState = this.setBetAmountToLocalState.bind(this);
    this.setBetAmountToStore = this.setBetAmountToStore.bind(this);
    this.setBetAmount = this.setBetAmount.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.amount !== prevProps.amount) {
      this.setState({ currAmount: weiToEth(this.props.amount) });
    }
  }

  componentDidMount() {
    this.setState({ currAmount: weiToEth(this.props.amount) });
  }

  setBetAmountToLocalState = (val, isWei = true) => {
    this.setState({ currAmount: isWei ? weiToEth(val) : val });
  };

  setBetAmountToStore = () => {
    this.props.setAmount(ethToWei(this.state.currAmount));
  };

  setBetAmount = (val) => {
    this.setState({ currAmount: weiToEth(val) });
    this.props.setAmount(val);
  };

  handleBetAmount = e => {
    const { value, id } = e.target;
    const {
      betWithContractBalance,
      betWithContractBonusBalance,
      minVal,
      maxVal,
      userContractBalance,
      userContractBonusBalance,
      walletBalance,
      adjustMin = true,
      adjustMax = true
    } = this.props;
    const { currAmount } = this.state;

    console.log(currAmount, value);

    if (id === "mainInput") {
      this.setBetAmountToLocalState(value, false);
      return;
    }
    if (id === "1/2") {
      if (
        currAmount === 0 ||
        (new BigNumber(minVal).gt(ethToWei(new BigNumber(currAmount).dividedBy(2).toString())) && adjustMin)
      ) {
        this.setBetAmount(minVal);
        return;
      }
      const half = ethToWei(new BigNumber(currAmount).dividedBy(2).toString());
      this.setBetAmount(half);
      return;
    }
    if (id === "x2") {
      if (
        currAmount === 0 ||
        (new BigNumber(maxVal).lt(ethToWei(new BigNumber(currAmount).times(2).toString())) && adjustMax)
      )
        return;
      if (betWithContractBalance || betWithContractBonusBalance) {
        if (
          betWithContractBalance &&
          new BigNumber(userContractBalance).lt(ethToWei(new BigNumber(currAmount).times(2).toString()))
        ) {
          return;
        }
        if (
          betWithContractBonusBalance &&
          new BigNumber(userContractBonusBalance).lt(ethToWei(new BigNumber(currAmount).times(2).toString()))
        ) {
          return;
        }
      }
      if (
        !betWithContractBalance &&
        !betWithContractBonusBalance &&
        new BigNumber(walletBalance).lt(ethToWei(new BigNumber(currAmount).times(2).toString()))
      ) {
        return;
      }
      const double = ethToWei(new BigNumber(currAmount).times(2).toString());
      this.setBetAmount(double);
      return;
    }
    if (id === "min") {
      this.setBetAmount(minVal);
      return;
    }
    if (id === "max") {
      if (betWithContractBalance || betWithContractBonusBalance) {
        if (
          betWithContractBalance &&
          new BigNumber(userContractBalance).lt(maxVal)
        ) {
          this.setBetAmount(userContractBalance);
          return;
        }
        if (
          betWithContractBonusBalance &&
          new BigNumber(userContractBonusBalance).lt(maxVal)
        ) {
          this.setBetAmount(userContractBonusBalance);
          return;
        }
      } else {
        if (new BigNumber(walletBalance).lt(maxVal)) {
          this.setBetAmount(walletBalance);
          return;
        }
      }
      this.setBetAmount(maxVal);
    }
  };

  render() {
    const {
      minVal,
      maxVal,
      activeGame,
      betId,
      maxNumOfBets,
      placingBet,
      adjustHalve = true,
      adjustDouble = true,
      adjustMin = true,
      adjustMax = true,
      exceededMax = false,
      exceededMin = false,
    } = this.props;
    const { currAmount } = this.state;

    return (
      <div className="main__section__body main__section__body--bet-size">
        <span className="main__section__heading">
          {activeGame !== "roulette" && activeGame !== "scratch" && "Bet size:"}
          {activeGame === "scratch" && "Cost per Card:"}
          {activeGame === "roulette" && (betId > maxNumOfBets
            ? "Maximum Number of Bets Reached"
            : `Bet Size for Bet ${betId}`)}
        </span>
        <span className="bet-size__bet">
          <div className="input-wrapper">
            <input
              className="bet-size__bet__value"
              type="number"
              id="mainInput"
              value={currAmount}
              min={weiToEth(minVal)}
              max={weiToEth(maxVal)}
              step={weiToEth(minVal)}
              disabled={placingBet}
              onChange={this.handleBetAmount}
              onBlur={this.setBetAmountToStore}
            />
          </div>
          <span className="bet-size__bet__currency">ETH</span>
        </span>
        <div className="bet-size__info">
          <span className={exceededMin ? "exceeded" : null}>
            <span>
              {activeGame === "scratch" ? "Min Card Cost: " : "Min Bet Size: "}
            </span>
            <Tooltip title={weiToEth(minVal)} theme="roll">
              {formatNumber(weiToEth(minVal), DP)} ETH
            </Tooltip>
          </span>
          <span className={exceededMax ? "exceeded" : null}>
            <span>
              {activeGame === "scratch" ? "Max Card Cost: " : "Max Bet Size: "}
            </span>
            <Tooltip title={weiToEth(maxVal)} theme="roll">
              {formatNumber(weiToEth(maxVal), DP)} ETH
            </Tooltip>
          </span>
        </div>
        <div className="bet-size__controls">
          <p>Adjust:</p>
          {adjustHalve && (
            <button
              className="controls__button"
              id="1/2"
              onClick={this.handleBetAmount}
              disabled={placingBet}
            >
              1/2
            </button>
          )}
          {adjustDouble && (
            <button
              className="controls__button"
              id="x2"
              onClick={this.handleBetAmount}
              disabled={placingBet}
            >
              X2
            </button>
          )}
          {adjustMin && (
            <button
              className="controls__button"
              id="min"
              onClick={this.handleBetAmount}
              disabled={placingBet}
            >
              MIN
            </button>
          )}
          {adjustMax && (
            <button
              className="controls__button"
              id="max"
              onClick={this.handleBetAmount}
              disabled={placingBet}
            >
              MAX
            </button>
          )}
        </div>
      </div>
    );
  }
}

BetSize.propTypes = {
  betWithContractBalance: PropTypes.bool.isRequired,
  betWithContractBonusBalance: PropTypes.bool.isRequired,
  userContractBalance: PropTypes.string.isRequired,
  userContractBonusBalance: PropTypes.string.isRequired,
  walletBalance: PropTypes.string.isRequired,
  minVal: PropTypes.string.isRequired,
  maxVal: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  placingBet: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ games, account, rouletteConstants, gamesConstants }) => ({
  activeGame: games.activeGame,
  maxNumOfBets: rouletteConstants.maxNumOfBets,
  minVal: gamesConstants.minBetSize,
  betWithContractBalance: games.betWithContractBalance,
  betWithContractBonusBalance: games.betWithContractBonusBalance,
  userContractBalance: account.userContractBalance,
  userContractBonusBalance: account.userContractBonusBalance,
  walletBalance: account.walletBalance
});

export default connect(mapStateToProps)(BetSize);
