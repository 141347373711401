import React from "react";

export default function Twitter({ clicked }) {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clicked}
    >
      <path
        d="M26.9342 2.55559C25.9566 2.98963 24.9059 3.2817 23.8026 3.41557C24.9262 2.73813 25.7943 1.67127 26.2 0.397537C25.1453 1.02224 23.977 1.47656 22.7357 1.71995C21.7419 0.661208 20.3262 0 18.7563 0C15.7464 0 13.3044 2.44201 13.3044 5.45192C13.3044 5.87786 13.353 6.29567 13.4463 6.69321C8.91525 6.46605 4.89932 4.29177 2.20987 0.993841C1.73931 1.80108 1.47159 2.73813 1.47159 3.74008C1.47159 5.63041 2.43297 7.29763 3.89737 8.27524C3.00088 8.24685 2.16119 8.00346 1.42696 7.59375C1.42696 7.61809 1.42696 7.63837 1.42696 7.66271C1.42696 10.3035 3.30512 12.5062 5.79986 13.0092C5.34148 13.1349 4.86281 13.1998 4.36386 13.1998C4.01095 13.1998 3.6702 13.1674 3.33757 13.1025C4.03123 15.2646 6.04325 16.8466 8.42847 16.8872C6.56654 18.3516 4.21377 19.2197 1.65818 19.2197C1.21603 19.2197 0.786039 19.1953 0.360107 19.1466C2.76966 20.6922 5.6376 21.5927 8.71648 21.5927C18.7441 21.5927 24.2285 13.285 24.2285 6.08474C24.2285 5.8454 24.2244 5.61013 24.2123 5.37485C25.2791 4.61223 26.204 3.65084 26.9342 2.55559Z"
        fill="white"
      />
    </svg>
  );
}
