import React, { Fragment } from "react";

const GamePaused = () => {
  return (
    <Fragment>
      <h2>Game is paused</h2>
      <p className="description">
        The game will continue shortly, please stand by while the admin
        completes necessary maintenance. The game will automatically
        continue once the system is ready. Thank you for your patience.
      </p>
    </Fragment>
  );
};

GamePaused.propTypes = {
};

export default GamePaused;
