import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveGame } from "../../../actions/games/games";
import {getRollInitialData, startRollAutoBet, stopRollAutoBet} from '../../../actions/games/roll';

import Details from "../Shared/Details/Details";
import History from "../Shared/History/History";
import Controls from "./Controls/Controls";
import Players from "../Shared/Players/Players";
import GamePaused from "../Shared/GamePaused/GamePaused";
import UserDisabled from "../Shared/UserDisabled/UserDisabled";
import AutoBet from '../Shared/AutoBet/AutoBet';

class DiceGame extends Component {
  componentDidMount() {
    this.props.setActiveGame("roll");
    this.props.getRollInitialData();
    document.title = "ROLL | BadBit.Games";
  }
  render() {
    const {
      gettingRoundTime,
      gettingRoundState,
      gettingGameState,
      gettingGameStateErr,
      roundState,
      gamesPaused,
      rollPaused,
      userStatus,

      startRollAutoBet,
      stopRollAutoBet,
      isAutoBetting,
      autoBettingRounds,
      autoBettingCurrentRound,
      startingAutoBetting,
      stoppingAutoBetting,
    } = this.props;
    return (
      <main className={`main ${gamesPaused || rollPaused || userStatus.status === "disabled" ? "center" : ""}`}>
        <div className="container">
          {userStatus.status !== "disabled" &&  !gamesPaused && !rollPaused && (
            <Fragment>
              <Details
                gettingTime={gettingRoundTime}
                gettingState={gettingRoundState}
                state={roundState}
              />
              <History
                gettingGameState={gettingGameState}
                gettingGameStateErr={gettingGameStateErr}
              />
              <Controls />
              <AutoBet
                startingAutoBetting={startingAutoBetting}
                stoppingAutoBetting={stoppingAutoBetting}
                startAutoBet={startRollAutoBet}
                stopAutoBet={stopRollAutoBet}
                isAutoBetting={isAutoBetting}
                autoBettingRounds={autoBettingRounds}
                autoBettingCurrentRound={autoBettingCurrentRound}
              />
              <Players
                gettingState={gettingGameState}
                gettingStateErr={gettingGameStateErr}
                state={roundState}
              />
            </Fragment>
          )}
          {(gamesPaused || rollPaused) && <GamePaused />}
          {!(gamesPaused || rollPaused) && userStatus.status === "disabled" && <UserDisabled />}
        </div>
      </main>
    );
  }
}

DiceGame.propTypes = {
  setActiveGame: PropTypes.func.isRequired,
  getRollInitialData: PropTypes.func.isRequired,
  gettingRoundTime: PropTypes.bool.isRequired,
  gettingRoundState: PropTypes.bool.isRequired,
  gettingGameState: PropTypes.bool.isRequired,
  gettingGameStateErr: PropTypes.string.isRequired,
  roundState: PropTypes.string.isRequired,
  gamesPaused: PropTypes.bool.isRequired,
  rollPaused: PropTypes.bool.isRequired
};

const mapStateToProps = ({ rollConstants, gameRoll, games, account }) => ({
  gettingRoundTime: rollConstants.gettingRoundTime,
  gettingRoundState: gameRoll.gettingRoundState,
  gettingGameState: gameRoll.gettingGameState,
  gettingGameStateErr: gameRoll.gettingGameStateErr,
  roundState: gameRoll.roundState,
  rollPaused: gameRoll.rollPaused,
  gamesPaused: games.gamesPaused,
  userStatus: account.userStatus,

  isAutoBetting: gameRoll.isAutoBetting,
  autoBettingRounds: gameRoll.autoBettingRounds,
  autoBettingCurrentRound: gameRoll.autoBettingCurrentRound,
  startingAutoBetting: gameRoll.startingAutoBetting,
  stoppingAutoBetting: gameRoll.stoppingAutoBetting,
});

const mapDispatchToProps = {
  setActiveGame,
  getRollInitialData,

  startRollAutoBet,
  stopRollAutoBet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiceGame);
