export const createAffiliateAgreementDoc = ({
  name,
  physicalAddress,
  ethAddress,
  email,
  listOfAffiliateSites,
  proposedMarketingMethods
}) => `
<h2>BadBit.Games Affiliate Agreement</h2>

<div class="parties">
  <div class="party">
    <h4>Party A</h4>
    <p>
      <strong>Lifeblood Gaming S.R.L.</strong>, a company incorporated in Costa Rica with address Residencial
Lomas de Curridabat, Casa 4E Barrio La Lia, Curridabat, San Jose 11801, Costa Rica and
with company registration number 3-102-777796, with email address 
      <a href="mailto:affiliates@badbit.games">affiliates@badbit.games</a>, henceforth known as <strong>“The Company”</strong>;
    </p>
  </div>

  <div class="party">
    <h4>Party B</h4>
    <p>Name: ${name}</p>
    <p>Address: ${physicalAddress}</p>
    <p>Ethereum Address: ${ethAddress}</p>
    <p>Email Address: ${email}</p>
    ${
      listOfAffiliateSites
        ? `<p>List of Affiliate websites:
    ${listOfAffiliateSites}</p>
    `
        : ""
    }
    ${
      proposedMarketingMethods
        ? `<p>Proposed marketing methods:
    ${proposedMarketingMethods}</p>
    `
        : ""
    }
    <p>Henceforth known as <strong>“you”</strong>, <strong>“your”</strong> and <strong>“The Affiliate”</strong>.</p>
  </div>
</div>

<div class="point">
<strong>(1) Definitions of Terms.</strong> In this Agreement (except where the context otherwise requires)
capitalised words and expressions shall have the meanings set out below:
<br />
<br />
(i) <strong>"Affiliate Website"</strong> means any website (including any device specific versions of
such website) or application owned and/or operated by you or on your behalf and
which you identify in your Application and any other marketing methods including
without limitation emails and SMS, which the Company approves for use in the
BadBit.Games Affiliate Scheme;
<br />
<br />
(ii) <strong>"Applicable Laws"</strong> means all applicable laws, directives, regulations, rules,
mandatory codes of practice and/or conduct, judgments, judicial orders, ordinances
and decrees imposed by law or any competent governmental or regulatory authority or
agency;
<br />
<br />
(iii) <strong>"Application"</strong> is the action of filling in and submitting the BadBit.Games
Affiliate Scheme registration form which can be found at the webpage
<a href="https://badbit.games/affiliates">https://badbit.games/affiliates</a>;
<br />
<br />
(iv) <strong>"Affiliate Earning"</strong>, or <strong>"Earning"</strong>, has the meaning given in clause (8.4);
<br />
<br />
(v) <strong>"Confidential Information"</strong> means all information in whatever form (including
without limitation written, oral, visual and electronic) which has been or may be
disclosed, before on and/or after the date of this Agreement by the Company;
<br />
<br />
(vi) <strong>"Group Company"</strong> means any entity directly or indirectly controlling,
controlled by, or under common control with the Company. For the purpose of this
definition, "control" (including, with correlative meanings, the terms "controlling",
"controlled by" and "under common control with") means the power to manage or
direct the affairs of the entity in question, whether by ownership of voting securities,
by contract or otherwise;
<br />
<br />
(vii) <strong>"Intellectual Property Rights"</strong> means any and all copyright and related rights,
designs, trade marks, trade names, internet domain names, insignia, service marks,
patents, database rights, inventions and discoveries and all other intellectual and
industrial property rights of a similar or corresponding nature in any of the relevant
territories, in each case whether registered or unregistered, and including the right to
apply for and all applications for any of the foregoing together with all statutory,
equitable and common law rights attaching thereto in any applicable territories,
including the right to sue for damages (and retain such damages) and all other legal
and equitable remedies in respect of any infringement or misuse of any of such
intellectual and industrial property or rights of a similar nature;
<br />
<br />
(viii) <strong>"Licensed Materials"</strong> has the meaning given in clause (7.3);
<br />
<br />
(ix) <strong>"Regulator"</strong> means any governmental, regulatory and administrative authorities,
agencies, commissions, boards, bodies and officials or other regulatory body or
agency that has jurisdiction over (or is responsible for or involved in the regulation of)
the Company or Group Companies from time to time;
<br />
<br />
(x) <strong>"Sites"</strong> means the websites (including any device specific versions of such
websites) and applications owned and/or operated by Lifeblood Gaming SRL under
the domain <a href="https://badbit.games/">https://badbit.games</a> and any other website or application notified to the
Affiliate from time to time.</div>
<div class="point">
<strong>(2)</strong> This agreement (the <strong>"Agreement"</strong>) contains the complete terms and conditions between
<strong>the Company and the Affiliate</strong> regarding: (i) your application to participate as an Affiliate in
the Company’s Affiliate marketing scheme (the <strong>"BadBit.Games Affiliate Scheme"</strong>); and
(ii) your participation in the BadBit.Games Affiliate Scheme and provision of the marketing
services in respect of the Sites, should your application be approved by the Company.</div>
<div class="point">
<strong>(3)</strong> By agreeing to this document, Party B agrees that, subject to the review and approval of
the Company, to be willingly bound by all the terms and conditions contained therein and
agree to execute their responsibilities in good faith. The Company is under no obligation
whatsoever to accept any application and shall review each application on a case-by-case
basis.</div>
<div class="point">
<strong>(4)</strong> The Company may refuse to enter into this agreement if the Company believes, based on
the information provided by the Affiliate, that any one or more of the following (4.1 to 4.5) 
are true. The Company may terminate this agreement if the Company believes any one or
more of the following (4.1 to 4.5) are true, subsequent to entering into this agreement with
the Affiliate. You shall have no right to appeal any decision by the Company to reject your
Application.
<br />
<br />
(4.1) The marketing methods and/or Affiliate Websites proposed by the Affiliate are
unsuitable for any reason;
<br />
<br />
(4.2) the Affiliate Websites, or any material circulated by the Affiliate in association
with The BadBit.Games Affiliate Scheme, include any content which the Company
deems to be unlawful, harmful, threatening, defamatory, obscene, or harassing. By
way of example only, this might mean that it contains: (i) sexually explicit,
pornographic or obscene content (whether in text or graphics); (ii) speech or images
that are offensive, profane, hateful, threatening, harmful, defamatory, libelous,
harassing or discriminatory (whether based on race, ethnicity, creed, religion, gender,
sexual orientation, physical disability or otherwise); (iii) graphic violence; (iv)
politically sensitive or controversial issues; or (v) any unlawful behavior or conduct;
<br />
<br />
(4.3) any of your Affiliate Websites, or any material circulated by the Affiliate in
association with The BadBit.Games Affiliate Scheme, are designed to distribute or
promote any spyware, adware, trojans, viruses, worms, spybots, keyloggers,
ransomware or any other form of malware;
<br />
<br />
(4.4) any of your Affiliate Website(s), or any material circulated by the Affiliate in
association with The BadBit.Games Affiliate Scheme, are designed to appeal to
individuals under the age of 18; or
<br />
<br />
(4.5) you may be in breach of, or may be likely to breach, any Applicable Laws or the
anti-bribery or corruption obligations specified in clause 17.</div>
<div class="point">
<strong>(5)</strong>
The Company reserves the right to perform background checks on you and request any
relevant documentation from you in evaluating your application for any reason, including
(but not limited to) verifying your identity, personal history, registration details (such as
company name and address), your financial transactions and financial standing. The
Company is under no obligation to advise you that such investigation is taking place. Such
activities may include the use of specific third party companies, who perform the
investigations as required. The Company may reject your Application on the basis that such
an investigation provides a negative or uncertain conclusion.</div>
<div class="point">
<strong>(6)</strong>
The documents requested by the Company will typically include an identity card,
certificate of incorporation (or any analogous document) and proof of address. If deemed
necessary, the Company may request that copies of these documents are notarised by a Public
Notary. If you fail to provide documents requested by the Company or if the Company
suspects that you have tampered with any documents provided (or attempted to mislead or
misrepresent in any other way), it may reject your Application. We shall be under no
obligation to accept such documents as valid, and under no obligation to provide feedback on
the exact nature of our findings with regards to these documents.</div>
<div class="point">
<strong>(7) Responsibilities of the Affiliate.</strong>
<br />
<br />
(7.1) By entering into this agreement, the Affiliate agrees to faithfully and diligently
promote the products and services of BadBit.Games, including both the ones that are
currently available and any products and services that may become available in the
future.
<br />
<br />
(7.2) The Affiliate agrees to never portray BadBit.Games in a negative way and never
openly or in private with any player or potential players criticise any aspect of the
Company, its operation, its employees and its management.
<br />
<br />
(7.3) If you are accepted on to the BadBit.Games Affiliates Scheme, the Company
will grant you a non-transferable, non-exclusive, non-sublicensable, limited,
revocable licence to use the Company's and its Group Companies and affiliates' logos,
trade names, trade marks, service marks and similar identifying material (collectively,
<strong>"Licensed Materials"</strong>), solely for the purpose of promoting the Sites. You may not
use any Licensed Materials for any purpose whatsoever other than promoting the
Sites, and in any event, you may not use the Licensed Materials without first
submitting a sample of your proposed use to the Company and receiving its prior
written consent to such use. You are not permitted to use the Licensed Materials in
any manner that is disparaging or that otherwise portrays the Company or any third
party negatively.
<br />
<br />
(7.4) The Company and its licensors own all of the Intellectual Property Rights in the
Licensed Materials and Links, and you hereby irrevocably assign to the Company all
present and future Intellectual Property Rights and other rights relating the Licensed
Materials, and will execute any and all such documents as may be necessary to
transfer and/or assign to the Company any such rights.
<br />
<br />
(7.5) The Company may revoke your licence to use the Licensed Materials and Links
at any time by written notice to you, whereupon you must immediately destroy or
deliver up to the Company all such materials as are in your possession. You
acknowledge that, except for the licence which may be granted to you in connection
hereto, you have not acquired and will not acquire any right, interest or title to the the
Licensed Materials by reason of this Agreement or your activities hereunder. The
aforementioned licence shall terminate upon the termination of this Agreement.
<br />
<br />
(7.6) You will be solely responsible for the technical operation of your Affiliate
Website(s) and the accuracy and appropriateness of materials posted on your Affiliate
Website(s). You agree that your Affiliate Website(s) will not, in any way, copy or
resemble the look and feel of the Sites (or any part thereof or any websites of the
Group Companies), nor will you create the impression that any of your Affiliate
Websites are any of the Sites (or any part thereof or the websites of any of the Group
Companies) or are owned and/or operated by the Company or any of the Group
Companies.
<br />
<br />
(7.7) The Affiliate is not permitted to register a domain name that includes,
incorporates or consists of the Company's, the Group Companies’ or its affiliates'
trademarks or any domain name that is confusingly or materially similar to such
trademarks.
<br />
<br />
(7.8) Upon entering into this Agreement and as a continuing obligation, you will
inform the Company of any domain names you own which are in breach of the
foregoing. Once the Company becomes aware that you have registered such a domain
name you will be informed and required to transfer the domain name to the Company
or an entity nominated by the Company, forthwith and free of charge. Your obligation
to transfer domain names registered in breach of this clause extends to domain names
registered prior to the date of execution of this agreement. Until the domain name has
been transferred to the nominated company you will not allow the domain name
registration to lapse.
<br />
<br />
(7.9) The Affiliate agrees to refrain from any promotional practice that specifically
targets individuals residing in the countries of Costa Rica, Cuba, Iran and Venezuela,
and any other countries that may from time to time be specified by the Company by
written or electronic communication.
<br />
<br />
(7.10) Upon termination of this agreement, the Affiliate agrees to send the digital
token described in section (8.1) back to the Company’s Ethereum address. The
Affiliate will be advised of this address.</div>
<div class="point">
<strong>(8) Responsibilities of the Company.</strong>
<br />
<br />
(8.1) The Company will issue a non-fungible token (NFT) on the Matic Network
sidechain according to the ERC-721 digital asset standard. The Company will send
this NFT to the Ethereum address specified by the Affiliate above, and ownership of
the NFT will serve as proof that an address is an affiliate address of the BadBit.Games
Affiliates Scheme.
<br />
<br />
(8.2) The Company will provide an interface on the Sites that allows the Affiliate,
once in possession of the NFT and logged in with the Ethereum address that holds the
NFT, to generate an affiliate code. Any player can, through input of this affiliate code
on the Sites, associate their account with the Affiliate.
<br />
<br />
(8.3) The Company will keep track of the player net loss of every address associated
with an affiliate code. <strong>Player net loss</strong> is calculated as follows:
<br />
<br />
(A) Total amount lost by the player in bets (excluding any bets made with bonus
balance), minus
<br />
<br />
(B) Total net winnings of the player (defined as total amount returned to the player
minus original bets, excluding net winnings in any bets made with bonus balance)
<br />
<br />
(8.4) Affiliate earnings is calculated based on a percentage of player net loss for all
player addresses that are associated with a particular affiliate code. The affiliate
earnings percentage may undergo reasonable fluctuation from time to time, without
advance notice, as the Company sees fit but it will always be the same for all
affiliates.
<br />
<br />
(8.5) Affiliate earnings is sent to all affiliate addresses at the end of each revenue
distribution cycle, the length of which is set to a number of Matic Network blocks
which is approximately equivalent to one week in time. The Company may adjust the 
length of distribution cycles at any time without advance notice. Affiliate earning is
sent as Contract ETH which can be withdrawn as Matic ETH and subsequently
withdrawn to the main Ethereum network at any time by the Affiliate. At the end of
each revenue distribution cycle, the player net loss calculation for every player is reset
to zero, making this a “no negative balance carryover” affiliate program.
<br />
<br />
(8.6) The Company will provide an interface accessible to affiliate addresses only
which displays the betting statistics of all player addresses associated with a particular
affiliate code, or provide alternative reasonable means for affiliates to monitor and
manage the betting activities of their community of players.</div>
<div class="point">
<strong>(9) Term.</strong>
<br />
<br />
(9.1) The term of this Agreement shall commence upon the Company’s acceptance of
your application, and your acceptance of the terms and conditions of this Agreement
as set out in this document, and will continue in force until terminated in accordance
with its terms by either party.
<br />
<br />
(9.2) At any time, either party may immediately terminate this Agreement, with or
without cause, by giving the other party written notice of termination (in writing or by
electronic communication to affiliates@badbit.games).
<br />
<br />
(9.3) In the event that you do not log into your account or you do not perform any
marketing or promotional activities for the Sites for 90 consecutive days, we may
terminate this Agreement without notice to you.
<br />
<br />
(9.4) At termination of this Agreement, you are obliged to send the affiliate token
back to an Ethereum address specified by the Company. Affiliate earnings are paid
every revenue cycle. At termination of this Agreement, you agree to forfeit any
affiliate earnings accrued but not yet paid, which will cease to be your entitled
affiliate earnings, but will instead go to the address to which you have sent the
affiliate token.
<br />
<br />
(9.5) Without derogating from any other provision in this Agreement, the Company
may terminate this Agreement on written notice to you in the event that: (i) you carry
out any action which the Company reasonably believes will expose the Company or
any Group Company to regulatory repercussions in any jurisdiction; (ii) the Company
reasonably believes that you have breached Applicable Laws and/or (iii) the Company
or any Group Company is ordered or required by a Regulator, to terminate its
relationship with you. In any of the above events, you are obliged to send the affiliate
token back to an Ethereum address specified by the Company, and you agree to forfeit
any affiliate earnings accrued but not yet paid at that point.
<br />
<br />
(9.6) Upon the termination of this Agreement for any reason, you will immediately
cease use of, and remove from your Affiliate Website(s), all Licensed Materials and
any other names, marks, symbols, copyrights, logos, designs, or other proprietary
designations or properties owned, developed, licensed or created by the Company
and/or provided by or on behalf of the Company to you pursuant to this Agreement or
in connection with the BadBit.Games Affiliate Scheme. Following the termination of 
this Agreement, the Company shall have no obligation to make any further payments
to you.</div>
<div class="point">
<strong>(10) Modifications.</strong>
<br />
<br />
(10.1) The Company may modify any of the terms and conditions contained in this
Agreement, at any time at its sole discretion. You agree that posting a change of terms
notice or a new agreement to the webpage <a href="https://badbit.games/affiliates">https://badbit.games/affiliates</a> is considered
sufficient provision of notice and such modifications shall be effective as of the date
of posting.
<br />
<br />
(10.2) Modifications may include, but are not limited to, changes to the list of the
Sites, the scope of available affiliate earning rates or schedules and payment
procedures. If any modification is unacceptable to you, your sole recourse is to
terminate this Agreement and your continued participation in the BadBit.Games
Affiliate Scheme following the posting of a change notice or new agreement to the
webpage <a href="https://badbit.games/affiliates">https://badbit.games/affiliates</a> will constitute binding acceptance by you of
the change. Due to the above, you should frequently visit the page
<a href="https://badbit.games/affiliates">https://badbit.games/affiliates</a>  and review the terms and conditions of this Agreement.</div>
<div class="point">
<strong>(11) Limitation of Liability.</strong>
<br />
<br />
(11.1) Nothing in this clause shall exclude or limit either party’s liability for death or
personal injury resulting from such party’s gross negligence or for fraud, fraudulent
misstatement or fraudulent misrepresentation.
<br />
<br />
(11.2) The Company shall not be liable (in contract, tort (including negligence) or for
breach of statutory duty or in any other way) for any:
<br />
<br />
1. (i) actual or expected indirect, special or consequential loss or damage;
<br />
<br />
2. (ii) loss of opportunity or loss of anticipated savings;
<br />
<br />
3. (iii) loss of contracts, business, profits or revenues;
<br />
<br />
4. (iv) loss of goodwill or reputation; or
<br />
<br />
5. (v) loss of data.
<br />
<br />
(11.3) The Company’s aggregate liability in respect of any loss or damage suffered by
you and arising out of or in connection with this Agreement, whether in contract, tort
(including negligence) or for breach of statutory duty or in any other way, shall not
exceed the total affiliate earnings paid or payable to you under this Agreement.
<br />
<br />
(11.4) You acknowledge and agree that the limitations contained in this clause 11 are
reasonable in the circumstances and that you have taken independent legal advice
regarding the same.</div>
<div class="point">
<strong>(12) Relationship of Parties.</strong> You and the Company are independent contractors, and
nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales
representative, or employment relationship between the parties.</div>
<div class="point">
<strong>(13) Disclaimers.</strong>
The Company makes no express or implied warranties or representations
with respect to the BadBit.Games Affiliate Scheme or the Sites (including without limitation warranties of fitness, merchantability, non-infringement, or any implied warranties arising
out of a course of performance, dealing, or trade usage). In addition, the Company makes no
representation that the operation of the BadBit.Games Affiliate Scheme or the Sites will be
uninterrupted or error-free, and will not be liable for the consequences of any interruptions or
errors.</div>
<div class="point">
<strong>(14) Representations and Warranties.</strong> You hereby represent and warrant to the Company
that:
<br />
<br />
(i) you have accepted the terms and conditions of this Agreement, which creates legal,
valid and binding obligations on you, enforceable against you in accordance with their
terms;
<br />
<br />
(ii) all the information provided by you in your Application is true and accurate;
<br />
<br />
(iii) your entering into, and performance of your obligations under, this agreement
will not conflict with or violate the provisions of any agreement to which you are
party or breach Applicable Laws;
<br />
<br />
(iv) you have, and will have throughout the term of this Agreement, all approvals,
permits and licenses (which includes but is not limited to any approvals, permits and
licenses necessary from any applicable Regulator) required to enter this Agreement,
participate in the BadBit.Games Affiliate Scheme or receive payment under this
Agreement;
<br />
<br />
(v) if you are an individual rather than a legal entity, you are an adult of at least 18
years of age;
<br />
<br />
(vi) as at the date of entering into this Agreement you have not registered any domain
names in breach of clause (7.7); and
<br />
<br />
(vii) you have evaluated the laws relating to your activities and obligations hereunder
and you have independently concluded that you can enter this Agreement and fulfill
your obligations hereunder without violating any Applicable Laws.
</div>
<div class="point">
<strong>(15) Confidentiality.</strong>
<br />
<br />
(15.1) The Company may disclose Confidential Information to you as a result of your
participation as an affiliate within the BadBit.Games Affiliate Scheme.
<br />
<br />
(15.2) You may not disclose any Confidential Information to any other person.
Notwithstanding the foregoing, you may disclose Confidential Information to the
extent: (i) required by law; or (ii) the information has come into the public domain
through no fault of your own.
<br />
<br />
(15.3) You shall not make any public announcement with respect to any aspect of this
Agreement or your relationship with the Company without the prior written approval
of the Company.
<br />
<br />
(15.4) Without derogating from clause (15.3), during the term of the Agreement and
thereafter, you shall not directly or indirectly make any disparaging, negative,
uncomplimentary, derogatory or defamatory statements (including without limitation
on any social media accounts) with regards to the Company, the Group Companies,
the Sites or the BadBit.Games Affiliate Scheme.
</div>
<div class="point">
<strong>(16) Indemnification.</strong>
(16.1) You hereby agree to indemnify, defend and hold harmless the Company, its
shareholders, officers, directors, employees, agents, Group Companies, successors
and assigns (the <strong>"Indemnified Parties"</strong>), from and against any and all claims and all
direct, indirect or consequential liabilities (including loss of profits, loss of business,
depletion of goodwill and similar losses), costs, proceedings, damages and expenses
(including legal and other professional fees and expenses) awarded against, or
incurred or paid by, any of the Indemnified Parties , as a result of or in connection
with:
<br />
<br />
(i) any alleged or actual infringement of any third party's Intellectual Property
Rights or other rights arising out of use or supply of the services provided by
you under this Agreement;
<br />
<br />
(ii) any breach by you of clauses (2), (4), (5), (6), (7) or (15);
<br />
<br />
(iii) any claim related to your use of the Licensed Materials or promotion of
the Sites;
<br />
<br />
(iv) any breach by you of any of the representations or warranties made by you
under this Agreement;
<br />
<br />
(v) the development, operation, maintenance, and contents of your Affiliate
Websites or any materials, products or services linked to therein;
<br />
<br />
(vi) a breach by you of any other provisions of this Agreement; and
<br />
<br />
(vii) any claim related to your entitlement to use or the display of the Licensed
Materials on the Affiliate Website.
<br />
<br />
(16.2) The provisions of this clause (16) shall survive termination of this Agreement
howsoever arising.</div>
<div class="point">
<strong>(17) Anti-Bribery and Corruption.</strong>
<br />
<br />
(17.1) The Company is committed, in accordance with its zero-tolerance policy for
bribery and corruption, to ensuring that all of its activities and the activities of all of
its affiliates and business partners comply with all Applicable Laws and accord to the
highest principles of corporate ethics.
<br />
<br />
(17.2) In submitting an Application for enrolment in the BadBit.Games Affiliate
Scheme and in performing your activities under this Agreement, you undertake to
comply with all Applicable Laws relating to anti-bribery and corruption and shall not 
offer, promise, give, authorise, solicit or accept any undue pecuniary or other
advantage related to any prospective Leads, Money Players, Commissions, SubAffiliates or otherwise.
<br />
<br />
(17.3) The Company may immediately terminate this Agreement if it determines, in
its sole discretion, that any of your activities do not fully comply with this clause (17)
and will no longer be liable to pay any affiliate earnings to you.</div>
<div class="point">
<strong>(18) Entire Agreement.</strong> The provisions contained in this Agreement and your Application
constitute the entire agreement between the parties with respect to the subject matter of this
Agreement, and no statement or inducement with respect to such subject matter by any party
which is not contained in this Agreement or the Application shall be valid or binding between
the parties.</div>
<div class="point">
<strong>(19) Independent Investigation.</strong> You acknowledge that you have read this Agreement, have
had an opportunity to consult with your own legal advisors if you so desired, and agree to all
of its terms and conditions. You have independently evaluated the desirability of participating
in the BadBit.Games Affiliate Scheme and are not relying on any representation, guarantee or
statement other than as set forth in this Agreement.</div>
<div class="point">
<strong>(20) General.</strong>
<br />
<br />
(20.1) This Agreement and any matters relating hereto shall be governed by, and
construed in accordance with the laws of Costa Rica. The courts of Costa Rica will
have exclusive jurisdiction over any dispute arising out of or relating to this
Agreement and the transactions contemplated thereby.
<br />
<br />
(20.2) Without derogating from the rights of the Company under this Agreement
and/or by law, the Company may set off any amount which you owe to it pursuant to
this Agreement and/or by law from any sum that you are entitled to receive from the
Company, from whatever source.
<br />
<br />
(20.3) You may not assign this Agreement, by operation of law or otherwise, without
the Company’s express prior written consent. Subject to that restriction, this
Agreement will be binding on, inure to the benefit of, and be enforceable against the
parties and their respective successors and assigns. You may not sub-contract or enter
into any arrangement whereby another person is to perform any or all of your
obligations under this Agreement.
<br />
<br />
(20.4) The Company’s failure to enforce your strict performance of any provision of
this Agreement will not constitute a waiver of its right to subsequently enforce such
provision or any other provision of this Agreement.
<br />
<br />
(20.5) The Company reserves the right to transfer, assign, sublicense or pledge this
Agreement, in whole or in part, without your consent: (i) to any Group Company, or
(ii) to any entity in the event of a merger, sale of assets or other similar corporate
transaction in which the Company may be involved in. The Company will notify you
of any such transfer, assignment, sublicense or pledge by publishing the new version
of this Agreement on <a href="https://badbit.games/affiliates">https://badbit.games/affiliates</a>.
<br />
<br />
(20.6) Any clause, provision, or portion of this Agreement specifically ruled invalid,
void, illegal or otherwise unenforceable by a Costa Rican court, will be amended to
the extent required to render it valid, legal and enforceable, or deleted if no such
amendment is feasible, and such amendment or deletion will not affect the
enforceability of the other provisions hereof.
<br />
<br />
(20.7) Any phrase introduced by the terms "including", "include" or any similar
expression shall be construed as illustrative and shall not limit the sense of the words
preceding those terms.
<br />
<br />
(20.8) In this Agreement, unless the context otherwise requires, words importing the
singular include the plural and vice versa, and words importing the masculine gender
include the feminine and neuter and vice versa.
</div>
<div class="point">
<strong>(21) Language Discrepancies.</strong>
In case of any discrepancy between the meanings of any
translated versions of this Agreement, the meaning of the English language version shall
prevail.
</div>
<strong>THE END</strong>`;
