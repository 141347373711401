export const GET_PREVIOUS_ROLLS_REQUEST = "GET_PREVIOUS_ROLLS_REQUEST";
export const GET_PREVIOUS_ROLLS_SUCCESS = "GET_PREVIOUS_ROLLS_SUCCESS";
export const GET_PREVIOUS_ROLLS_FAILURE = "GET_PREVIOUS_ROLLS_FAILURE";
export const ADD_ROLL = "ADD_ROLL";

export const GET_PREVIOUS_SPINS_REQUEST = "GET_PREVIOUS_SPINS_REQUEST";
export const GET_PREVIOUS_SPINS_SUCCESS = "GET_PREVIOUS_SPINS_SUCCESS";
export const GET_PREVIOUS_SPINS_FAILURE = "GET_PREVIOUS_SPINS_FAILURE";
export const ADD_SPIN = "ADD_SPIN";

export const GET_PREVIOUS_ROULETTE_ROLLS_REQUEST = "GET_PREVIOUS_ROULETTE_ROLLS_REQUEST";
export const GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS = "GET_PREVIOUS_ROULETTE_ROLLS_SUCCESS";
export const GET_PREVIOUS_ROULETTE_ROLLS_FAILURE = "GET_PREVIOUS_ROULETTE_ROLLS_FAILURE";
export const ADD_ROULETTE_ROLLS = "ADD_ROULETTE_ROLLS";
