import {
  CONNECTING_TO_SOCKET_REQUEST,
  CONNECTING_TO_SOCKET_SUCCESS,
  CONNECTING_TO_SOCKET_ERROR,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  GET_CHAT_HISTORY_REQUEST,
  GET_CHAT_HISTORY_SUCCESS,
  GET_CHAT_HISTORY_ERROR,
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_ERROR,
  REMOVE_MESSAGE_SUCCESS,
  INCREMENT_UNREAD,
  CLEAR_UNREAD,
  SET_MESSAGE_VALUE,
  TOGGLE_EMOJIS,
  SET_ADMIN_ONLINE,
  CHECK_IF_IS_ADMIN_REQUEST,
  CHECK_IF_IS_ADMIN_SUCCESS,
  CHECK_IF_IS_ADMIN_ERROR,
  TOGGLE_BAN_USER_REQUEST,
  TOGGLE_BAN_USER_SUCCESS,
  TOGGLE_BAN_USER_ERROR
} from "../actionTypes/chat";

const INITIAL_STATE = {
  unreadMessages: 0,

  adminOnline: false,

  isAdmin: false,
  checkingIfIsAdmin: false,
  checkingIfIsAdminError: "",

  sendMessage: false,
  sendMessageError: "",

  chatConnected: false,
  connectingToSocket: false,
  connectingToSocketError: "",

  chatHistory: [],
  gettingChatHistory: false,
  gettingChatHistoryError: "",

  addingMessage: false,
  addingMessageError: "",

  messageValue: "",

  showEmojis: false
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONNECTING_TO_SOCKET_REQUEST:
      return {
        ...state,
        connectingToSocket: true,
        connectingToSocketError: ""
      };

    case CONNECTING_TO_SOCKET_SUCCESS:
      return {
        ...state,
        chatConnected: true,
        connectingToSocket: false,
        connectingToSocketError: ""
      };

    case CONNECTING_TO_SOCKET_ERROR:
      return {
        ...state,
        connectingToSocket: false,
        connectingToSocketError: payload
      };

    case GET_CHAT_HISTORY_REQUEST:
      return {
        ...state,
        gettingChatHistory: true,
        gettingChatHistoryError: ""
      };

    case GET_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        chatHistory: payload,
        gettingChatHistory: false,
        gettingChatHistoryError: ""
      };

    case GET_CHAT_HISTORY_ERROR:
      return {
        ...state,
        gettingChatHistory: false,
        gettingChatHistoryError: payload
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessage: true,
        sendMessageError: ""
      };

    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessage: false,
        sendMessageError: ""
      };

    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessage: false,
        sendMessageError: payload
      };

    case ADD_MESSAGE_REQUEST:
      return {
        ...state,
        addingMessage: true,
        addingMessageError: ""
      };

    case ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        chatHistory: [...state.chatHistory, payload],
        addingMessage: false,
        addingMessageError: ""
      };

    case ADD_MESSAGE_ERROR:
      return {
        ...state,
        addingMessage: false,
        addingMessageError: payload
      };

    case REMOVE_MESSAGE_SUCCESS:
      return {
        ...state,
        chatHistory: payload
      };

    case TOGGLE_BAN_USER_SUCCESS:
      return {
        ...state,
        chatHistory: payload
      };

    case INCREMENT_UNREAD:
      return {
        ...state,
        unreadMessages: payload
      };

    case CLEAR_UNREAD:
      return {
        ...state,
        unreadMessages: 0
      };

    case SET_MESSAGE_VALUE:
      return { ...state, messageValue: payload };

    case TOGGLE_EMOJIS:
      return { ...state, showEmojis: payload };

    case SET_ADMIN_ONLINE:
      return { ...state, adminOnline: payload };

    case CHECK_IF_IS_ADMIN_REQUEST:
      return {
        ...state,
        checkingIfIsAdmin: true,
        checkingIfIsAdminError: ""
      };

    case CHECK_IF_IS_ADMIN_SUCCESS:
      return {
        ...state,
        isAdmin: payload,
        checkingIfIsAdmin: false,
        checkingIfIsAdminError: ""
      };

    case CHECK_IF_IS_ADMIN_ERROR:
      return {
        ...state,
        checkingIfIsAdmin: false,
        checkingIfIsAdminError: payload
      };

    default:
      return state;
  }
};
