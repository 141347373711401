import {
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST,
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS,
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR,
  GET_MAX_BET_SIZES_REQUEST,
  GET_MAX_BET_SIZES_SUCCESS,
  GET_MAX_BET_SIZES_ERROR,
  GET_MAX_NUM_OF_BETS_REQUEST,
  GET_MAX_NUM_OF_BETS_SUCCESS,
  GET_MAX_NUM_OF_BETS_ERROR,
  SET_ROULETTE_MAX_BET_SIZE,
  SET_ROULETTE_BET_AMOUNT
} from "../../actionTypes/contractConstants/roulette";

const INITIAL_STATE = {
  betAmount: "0",

  maxBetSize: "0",

  gettingBenchmarkMaxBetSize: false,
  gettingBenchmarkMaxBetSizeErr: "",
  benchmarkMaxBetSize: "0",

  gettingMaxNumOfBets: false,
  gettingMaxNumOfBetsErr: "",
  maxNumOfBets: 0,

  gettingMaxBetSizes: false,
  gettingMaxBetSizesErr: "",
  maxBet1: "0",
  maxBet2: "0",
  maxBet3: "0",
  maxBet4: "0",
  maxBet5: "0",
  maxBet6: "0",
  maxBet7: "0"
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ROULETTE_MAX_BET_SIZE:
      return {
        ...state,
        maxBetSize: payload
      };

    case SET_ROULETTE_BET_AMOUNT:
      return { ...state, betAmount: payload };

    case GET_MAX_NUM_OF_BETS_REQUEST:
      return {
        ...state,
        gettingMaxNumOfBets: true,
        gettingMaxNumOfBetsErr: ""
      };

    case GET_MAX_NUM_OF_BETS_SUCCESS:
      return {
        ...state,
        gettingMaxNumOfBets: false,
        gettingMaxNumOfBetsErr: "",
        maxNumOfBets: payload
      };

    case GET_MAX_NUM_OF_BETS_ERROR:
      return {
        ...state,
        gettingMaxNumOfBets: false,
        gettingMaxNumOfBetsErr: payload
      };

    case GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: true,
        gettingBenchmarkMaxBetSizeErr: ""
      };

    case GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeErr: "",
        benchmarkMaxBetSize: payload
      };

    case GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR:
      return {
        ...state,
        gettingBenchmarkMaxBetSize: false,
        gettingBenchmarkMaxBetSizeErr: payload
      };

    case GET_MAX_BET_SIZES_REQUEST:
      return {
        ...state,
        gettingMaxBetSizes: true,
        gettingMaxBetSizesErr: ""
      };

    case GET_MAX_BET_SIZES_SUCCESS:
      return {
        ...state,
        gettingMaxBetSizes: false,
        gettingMaxBetSizesErr: "",
        maxBet1: payload.MAX_BET_1,
        maxBet2: payload.MAX_BET_2,
        maxBet3: payload.MAX_BET_3,
        maxBet4: payload.MAX_BET_4,
        maxBet5: payload.MAX_BET_5,
        maxBet6: payload.MAX_BET_6,
        maxBet7: payload.MAX_BET_7
      };

    case GET_MAX_BET_SIZES_ERROR:
      return {
        ...state,
        gettingMaxBetSizes: false,
        gettingMaxBetSizesErr: payload
      };

    default:
      return state;
  }
};
