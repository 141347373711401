import { LS, LS_VERSION } from "../config/constants";
import {
  setRollLastRoundPlayed,
  setRollLastUserSelectedGameType,
  setRollLastUserSelectedNumber,
  setRollLastWinPrize,
} from "../actions/games/roll";
import {
  setSpinLastRoundPlayed,
  setSpinLastUserSelectedGameType,
  setSpinLastWinPrize,
} from "../actions/games/spin";
import {
  setScratchGameMode,
  setScratchNumOfCards,
  setScratchBetAmount,
} from "../actions/games/scratch";

/**
 * Initializes local storage
 *
 * @param dispatch
 * @param getState
 */
export const initLocalStorage = (dispatch, getState) => {
  const { account } = getState().account;

  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);

  if (lSAccount === null) {
    const newLSObj = JSON.stringify({
      roll: {
        lastRoundPlayed: 0,
        lastUserSelectedNumber: 0,
        lastUserSelectedGameType: 0,
        lastWinPrize: 0
      },
      spin: {
        lastRoundPlayed: 0,
        lastUserSelectedGameType: 0,
        lastWinPrize: 0
      },
      scratch: {
        gameMode: 0,
        numberOfCards: 1,
        costPerCard: "0",
      }
    });
    LS.setItem(`${LS_VERSION}-${account}`, newLSObj);
  } else {
    setRollGameState(lSAccount, dispatch);
    setSpinGameState(lSAccount, dispatch);
    setScratchGameState(lSAccount, dispatch)
  }
};

/**
 * Sets game state from local storage for roll game
 *
 * @param lSAccount
 * @param dispatch
 */
export const setRollGameState = (lSAccount, dispatch) => {
  const {
    lastRoundPlayed,
    lastUserSelectedNumber,
    lastUserSelectedGameType,
    lastWinPrize
  } = JSON.parse(lSAccount).roll;
  dispatch(setRollLastRoundPlayed(lastRoundPlayed, false));
  dispatch(setRollLastUserSelectedNumber(lastUserSelectedNumber, false));
  dispatch(setRollLastUserSelectedGameType(lastUserSelectedGameType, false));
  dispatch(setRollLastWinPrize(lastWinPrize, false));
};

/**
 * Sets game state from local storage for spin game
 *
 * @param lSAccount
 * @param dispatch
 */
export const setSpinGameState = (lSAccount, dispatch) => {
  const {
    lastRoundPlayed,
    lastUserSelectedGameType,
    lastWinPrize
  } = JSON.parse(lSAccount).roll;
  dispatch(setSpinLastRoundPlayed(lastRoundPlayed, false));
  dispatch(setSpinLastUserSelectedGameType(lastUserSelectedGameType, false));
  dispatch(setSpinLastWinPrize(lastWinPrize, false));
};

/**
 * Sets game state from local storage for roll game
 *
 * @param lSAccount
 * @param dispatch
 */
export const setScratchGameState = (lSAccount, dispatch) => {
  const {
    gameMode,
    numberOfCards,
    costPerCard,
  } = JSON.parse(lSAccount).scratch;
  dispatch(setScratchGameMode(gameMode, false));
  dispatch(setScratchNumOfCards(numberOfCards, false));
  dispatch(setScratchBetAmount(costPerCard, false));
};

/**
 * Sets last win prize to local storage
 *
 * @param account
 * @param payload
 */
export const setRollLastWinPrizeToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.roll.lastWinPrize = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last win prize for spin game to local storage
 *
 * @param account
 * @param payload
 */
export const setSpinLastWinPrizeToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.spin.lastWinPrize = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last round played roll game to local storage
 *
 * @param account
 * @param payload
 */
export const setRollLastRoundPlayedToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.roll.lastRoundPlayed = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last round played for spin game to local storage
 *
 * @param account
 * @param payload
 */
export const setSpinLastRoundPlayedToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.spin.lastRoundPlayed = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last round user selected number to local storage
 *
 * @param account
 * @param payload
 */
export const setRollLastUserSelectedNumberToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.roll.lastUserSelectedNumber = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last user selected game type to local storage
 *
 * @param account
 * @param payload
 */
export const setRollLastUserSelectedGameTypeToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.roll.lastUserSelectedGameType = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 * Sets last user selected game type for spin game to local storage
 *
 * @param account
 * @param payload
 */
export const setSpinLastUserSelectedGameTypeToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.spin.lastUserSelectedGameType = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 *
 * @param account
 * @param payload
 */
export const setScratchGameModeToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.scratch.gameMode = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 *
 * @param account
 * @param payload
 */
export const setScratchNumberOfCardsToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.scratch.numberOfCards = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};

/**
 *
 * @param account
 * @param payload
 */
export const setScratchCostPerCardToLS = (account, payload) => {
  const lSAccount = LS.getItem(`${LS_VERSION}-${account}`);
  const parsedLS = JSON.parse(lSAccount);
  parsedLS.scratch.costPerCard = payload;
  const updatedLS = JSON.stringify(parsedLS);
  LS.setItem(`${LS_VERSION}-${account}`, updatedLS);
};
