import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import { formatNumber } from "../../../../services/utils";
import {
  DP,
  PAGINATION_PER_PAGE_OPTIONS,
  SELECT_CUSTOM_STYLE
} from "../../../../config/constants";
import {
  changePlayersHistoryPerPage,
  getPlayersHistory
} from "../../../../actions/playersHistory";
import { toggleModal } from "../../../../actions/modal";

import "../../../Loader/Loader";
import "../../../Games/Shared/Players/Games/Roll/PlayersGameTypeSwitcher";
import "./PlayersHistory.scss";
import Loader from "../../../Loader/Loader";
import Pagination from "../../../Pagination/Pagination";
import PlayersGameTypeSwitcher from "../../../Games/Shared/Players/Games/Roll/PlayersGameTypeSwitcher";
import { changePage } from "../../../../actions/pagination";
import { weiToEth } from "../../../../services/ethereum/main";
import { isNumberRedOrBlue } from "../../../../actions/contractConstants/roulette";

class PlayersHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { game: props.activeGame ? props.activeGame : "roll" };

    this.handleTabs = this.handleTabs.bind(this);
  }

  handleTabs = data => {
    console.log(data);
    const { changePage } = this.props;
    this.setState({ game: data });
    changePage(1);
  };

  componentDidMount() {
    const { game } = this.state;
    const { getPlayersHistory, account, currentPage, perPage } = this.props;
    getPlayersHistory(account, currentPage, perPage.value, game);
  }

  componentDidUpdate(prevProps, prevState) {
    const { game } = this.state;
    const { account, getPlayersHistory, currentPage, perPage } = this.props;
    if (
      prevProps.currentPage !== currentPage ||
      prevProps.perPage.value !== perPage.value ||
      prevState.game !== game
    ) {
      getPlayersHistory(account, currentPage, perPage.value, game);
    }
  }

  componentWillUnmount() {
    const { changePage } = this.props;
    changePage(1);
  }

  render() {
    const {
      gettingPlayersHistory,
      gettingPlayersHistoryError,
      playersHistory,
      account,
      toggleModal,
      currentAcc,
      currentNick,
      nickname,
      perPage,
      changePlayersHistoryPerPage,
      maxNumOfDonInRow
    } = this.props;

    return (
      <div className="modal__content modal__content--players-history">
        {gettingPlayersHistory && (
          <Loader text="Loading player's history..." size={85} />
        )}
        {!gettingPlayersHistory && gettingPlayersHistoryError === "" && (
          <Fragment>
            <p className="title">
              {currentAcc === account ? "Bet History" : "Player History"}
            </p>
            <div className="details">
              <p className="description">
                Name:
                {currentAcc === account && (
                  <span>{currentNick !== "" ? currentNick : account}</span>
                )}
                {currentAcc !== account && (
                  <span>{nickname !== "" ? nickname : account}</span>
                )}
              </p>
              <div className="select-per-page">
                <p>Per Page: </p>
                <Select
                  className="select"
                  classNamePrefix="select"
                  styles={SELECT_CUSTOM_STYLE}
                  options={PAGINATION_PER_PAGE_OPTIONS}
                  value={perPage}
                  onChange={changePlayersHistoryPerPage}
                  isSearchable={false}
                  menuPlacement="bottom"
                />
              </div>
            </div>

            <div className="games">
              <div className="game-tabs">
                <div
                  className={`game-tab ${
                    this.state.game === "roll" ? "active" : ""
                  }`}
                  onClick={() => this.handleTabs("roll")}
                >
                  ROLL
                </div>
                <div
                  className={`game-tab ${
                    this.state.game === "spin" ? "active" : ""
                  }`}
                  onClick={() => this.handleTabs("spin")}
                >
                  SPIN
                </div>
                <div
                  className={`game-tab ${
                    this.state.game === "scratch" ? "active" : ""
                  }`}
                  onClick={() => this.handleTabs("scratch")}
                >
                  SCRATCH
                </div>
                <div
                  className={`game-tab ${
                    this.state.game === "roulette" ? "active" : ""
                  }`}
                  onClick={() => this.handleTabs("roulette")}
                >
                  ROULETTE
                </div>
                {maxNumOfDonInRow > 0 && (
                  <div
                    className={`game-tab ${
                      this.state.game === "doubleOrNothing" ? "active" : ""
                    }`}
                    onClick={() => this.handleTabs("doubleOrNothing")}
                  >
                    DoN
                  </div>
                )}
              </div>
              <div className="game">
                {!gettingPlayersHistory && playersHistory.data.length === 0 && (
                  <p className="description description--error">
                    {currentAcc === account
                      ? "You have no betting history."
                      : "This player has no betting history."}
                  </p>
                )}
                {playersHistory.game === "roll" && (
                  <Fragment>
                    {playersHistory.data.length !== 0 && (
                      <Fragment>
                        <table className="player-history">
                          <thead className="player-history__head">
                            <tr>
                              <th>Round</th>
                              <th>W/L</th>
                              <th>Game Type</th>
                              <th>Chosen Outcome</th>
                              <th>Outcome</th>
                              <th>Bet Size</th>
                              <th>Pay Out</th>
                            </tr>
                          </thead>
                          <tbody className="player-history__body mock">
                            <tr>
                              <td>1.</td>
                              <td>
                                <span className="status status--dot status--success" />
                              </td>
                              <td>Dragon Tiger</td>
                              <td>20</td>
                              <td>20</td>
                              <td>0.001</td>
                              <td>0.001</td>
                            </tr>
                          </tbody>
                        </table>
                        <PerfectScrollbar>
                          <table className="player-history real">
                            <thead className="player-history__head mock">
                              <tr>
                                <th>Round</th>
                                <th>W/L</th>
                                <th>Game Type</th>
                                <th>Chosen Outcome</th>
                                <th>Outcome</th>
                                <th>Bet Size</th>
                                <th>Pay Out</th>
                              </tr>
                            </thead>
                            <tbody className="player-history__body">
                              {playersHistory.data.map(
                                (
                                  {
                                    amount,
                                    gameType,
                                    chosenNumber,
                                    round,
                                    won,
                                    profit,
                                    roundOutcome
                                  },
                                  index
                                ) => (
                                  <tr
                                    className="player-history__single"
                                    key={`${gameType}${index}${amount}`}
                                  >
                                    <td>
                                      {round !== "0" ? `${round}.` : "N/A"}
                                    </td>

                                    <td>
                                      <span
                                        className={`status status--dot status--${
                                          won ? "success" : "danger"
                                        }`}
                                      />
                                    </td>

                                    <td className="player-history__single__type">
                                      <PlayersGameTypeSwitcher
                                        gameType={gameType}
                                        type={0}
                                      />
                                    </td>

                                    <td>{chosenNumber}</td>
                                    <td>{roundOutcome}</td>
                                    <td>
                                      <Tooltip title={amount} theme="roll">
                                        {formatNumber(amount, DP)} ETH
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={weiToEth(profit)}
                                        theme="roll"
                                      >
                                        {formatNumber(
                                          Number(weiToEth(profit)),
                                          DP
                                        )}{" "}
                                        ETH
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </PerfectScrollbar>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {playersHistory.game === "spin" && (
                  <Fragment>
                    {playersHistory.data.length !== 0 && (
                      <Fragment>
                        <table className="player-history">
                          <thead className="player-history__head">
                            <tr>
                              <th>Round</th>
                              <th>W/L</th>
                              <th>Chosen Outcome</th>
                              <th>Outcome</th>
                              <th>Bet Size</th>
                              <th>Pay Out</th>
                            </tr>
                          </thead>
                          <tbody className="player-history__body mock">
                            <tr>
                              <td>1.</td>
                              <td>
                                <span className="status status--dot status--success" />
                              </td>
                              <th>White</th>
                              <th>White</th>
                              <th>0.001</th>
                              <th>0.001</th>
                            </tr>
                          </tbody>
                        </table>
                        <PerfectScrollbar>
                          <table className="player-history real">
                            <thead className="player-history__head mock">
                              <tr>
                                <th>Round</th>
                                <th>W/L</th>
                                <th>Chosen Outcome</th>
                                <th>Outcome</th>
                                <th>Bet Size</th>
                                <th>Pay Out</th>
                              </tr>
                            </thead>
                            <tbody className="player-history__body">
                              {playersHistory.data.map(
                                (
                                  {
                                    amount,
                                    gameType,
                                    chosenColor,
                                    round,
                                    won,
                                    profit,
                                    roundOutcome
                                  },
                                  index
                                ) => (
                                  <tr
                                    className="player-history__single"
                                    key={`${gameType}${index}${amount}`}
                                  >
                                    <td>
                                      {round !== "0" ? `${round}.` : "N/A"}
                                    </td>

                                    <td>
                                      <span
                                        className={`status status--dot status--${
                                          won ? "success" : "danger"
                                        }`}
                                      />
                                    </td>

                                    <td>{`${chosenColor
                                      .substring(0, 1)
                                      .toUpperCase()}${chosenColor.slice(
                                      1
                                    )}`}</td>
                                    <td>{`${roundOutcome
                                      .substring(0, 1)
                                      .toUpperCase()}${roundOutcome.slice(
                                      1
                                    )}`}</td>
                                    <td>
                                      <Tooltip title={amount} theme="roll">
                                        {formatNumber(amount, DP)} ETH
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={weiToEth(profit)}
                                        theme="roll"
                                      >
                                        {formatNumber(
                                          Number(weiToEth(profit)),
                                          DP
                                        )}{" "}
                                        ETH
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </PerfectScrollbar>
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {playersHistory.game === "scratch" && (
                  <Fragment>
                    {playersHistory.data.length !== 0 && (
                      <Fragment>
                        <table className="player-history">
                          <thead className="player-history__head">
                          <tr>
                            <th>Round</th>
                            <th>W/L</th>
                            <th>Cards Bought</th>
                            <th>Cards Won</th>
                            <th>Bet Size</th>
                            <th>Pay Out</th>
                          </tr>
                          </thead>
                          <tbody className="player-history__body mock">
                          <tr>
                            <td>1.</td>
                            <td>
                              <span className="status status--dot status--success" />
                            </td>
                            <th>White</th>
                            <th>White</th>
                            <th>0.001</th>
                            <th>0.001</th>
                          </tr>
                          </tbody>
                        </table>
                        <PerfectScrollbar>
                          <table className="player-history real">
                            <thead className="player-history__head mock">
                            <tr>
                              <th>Round</th>
                              <th>W/L</th>
                              <th>Cards bought</th>
                              <th>Cards won</th>
                              <th>Bet Size</th>
                              <th>Pay Out</th>
                            </tr>
                            </thead>
                            <tbody className="player-history__body">
                            {playersHistory.data.map(
                              (
                                {
                                  amount,
                                  round,
                                  won,
                                  profit,
                                  numberOfCards,
                                  numOfWinCards,
                                },
                                index
                              ) => (
                                <tr
                                  className="player-history__single"
                                  key={`${index}${amount}${round}`}
                                >
                                  <td>
                                    {round !== "0" ? `${round}.` : "N/A"}
                                  </td>

                                  <td>
                                      <span
                                        className={`status status--dot status--${
                                          won ? "success" : "danger"
                                        }`}
                                      />
                                  </td>

                                  <td>
                                    {numberOfCards}
                                  </td>

                                  <td>
                                    {numOfWinCards}
                                  </td>
                                  <td>
                                    <Tooltip
                                      title={weiToEth(amount)}
                                      theme="roll"
                                    >
                                      {formatNumber(weiToEth(amount), DP)} ETH
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <Tooltip
                                      title={weiToEth(profit)}
                                      theme="roll"
                                    >
                                      {formatNumber(
                                        Number(weiToEth(profit)),
                                        DP
                                      )}{" "}
                                      ETH
                                    </Tooltip>
                                  </td>
                                </tr>
                              )
                            )}
                            </tbody>
                          </table>
                        </PerfectScrollbar>
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {playersHistory.game === "roulette" && (
                  <Fragment>
                    {playersHistory.data.length !== 0 && (
                      <Fragment>
                        <table className="player-history">
                          <thead className="player-history__head">
                            <tr>
                              <th>Round</th>
                              <th>W/L</th>
                              <th>Chosen Outcome</th>
                              <th>Outcome</th>
                              <th>Bet Size</th>
                              <th>Pay Out</th>
                            </tr>
                          </thead>
                          <tbody className="player-history__body mock">
                            <tr>
                              <td>1.</td>
                              <td>
                                <span className="status status--dot status--success" />
                              </td>
                              <th>White</th>
                              <th>White</th>
                              <th>0.001</th>
                              <th>0.001</th>
                            </tr>
                          </tbody>
                        </table>
                        <PerfectScrollbar>
                          <table className="player-history real">
                            <thead className="player-history__head mock">
                              <tr>
                                <th>Round</th>
                                <th>W/L</th>
                                <th>Chosen Outcome</th>
                                <th>Outcome</th>
                                <th>Bet Size</th>
                                <th>Pay Out</th>
                              </tr>
                            </thead>
                            <tbody className="player-history__body">
                              {playersHistory.data.map(
                                (
                                  {
                                    amount,
                                    betDetails,
                                    round,
                                    won,
                                    profit,
                                    roundOutcome
                                  },
                                  index
                                ) => (
                                  <tr
                                    className="player-history__single"
                                    key={`${index}${amount}`}
                                  >
                                    <td>
                                      {round !== "0" ? `${round}.` : "N/A"}
                                    </td>

                                    <td>
                                      <span
                                        className={`status status--dot status--${
                                          won ? "success" : "danger"
                                        }`}
                                      />
                                    </td>

                                    <td>
                                      <Tooltip
                                        html={
                                          <div className="bet-details-tooltip">
                                            {betDetails.length === 0 && (
                                              <p className="info">
                                                We have no info for this round.
                                              </p>
                                            )}
                                            {betDetails.length !== 0 &&
                                              betDetails.map((item, index) => (
                                                <p
                                                  key={`${item}-${index}`}
                                                  className="info"
                                                >
                                                  {item}
                                                </p>
                                              ))}
                                          </div>
                                        }
                                        theme="roll"
                                      >
                                        {betDetails.length === 0
                                          ? "No info"
                                          : betDetails.length === 1
                                          ? `${betDetails.length} bet`
                                          : `${betDetails.length} bets`}
                                      </Tooltip>
                                    </td>

                                    <td>
                                      {roundOutcome}
                                      {isNumberRedOrBlue(roundOutcome) ===
                                      "yellow"
                                        ? ""
                                        : ` (${isNumberRedOrBlue(
                                            roundOutcome
                                          )})`}
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={weiToEth(amount)}
                                        theme="roll"
                                      >
                                        {formatNumber(weiToEth(amount), DP)} ETH
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={weiToEth(profit)}
                                        theme="roll"
                                      >
                                        {formatNumber(
                                          Number(weiToEth(profit)),
                                          DP
                                        )}{" "}
                                        ETH
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </PerfectScrollbar>
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {playersHistory.game === "doubleOrNothing" && (
                  <Fragment>
                    {playersHistory.data.length !== 0 && (
                      <Fragment>
                        <table className="player-history">
                          <thead className="player-history__head">
                            <tr>
                              <th>Game</th>
                              <th>W/L</th>
                              <th>Bet Size</th>
                              <th>Pay Out</th>
                            </tr>
                          </thead>
                          <tbody className="player-history__body mock">
                            <tr>
                              <th>Roll</th>
                              <td>
                                <span className="status status--dot status--success" />
                              </td>
                              <th>0.001</th>
                              <th>0.001</th>
                            </tr>
                          </tbody>
                        </table>
                        <PerfectScrollbar>
                          <table className="player-history real">
                            <thead className="player-history__head mock">
                              <tr>
                                <th>Game</th>
                                <th>W/L</th>
                                <th>Bet Size</th>
                                <th>Pay Out</th>
                              </tr>
                            </thead>
                            <tbody className="player-history__body">
                              {playersHistory.data.map(
                                ({ gameName, won, amount, profit }, index) => (
                                  <tr
                                    className="player-history__single"
                                    key={`${gameName}${index}${amount}`}
                                  >
                                    <td>
                                      {`${gameName.substring(
                                        0,
                                        1
                                      )}${gameName.slice(1).toLowerCase()}`}
                                    </td>

                                    <td>
                                      <span
                                        className={`status status--dot status--${
                                          won ? "success" : "danger"
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Tooltip title={amount} theme="roll">
                                        {formatNumber(amount, DP)} ETH
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={weiToEth(profit)}
                                        theme="roll"
                                      >
                                        {formatNumber(
                                          Number(weiToEth(profit)),
                                          DP
                                        )}{" "}
                                        ETH
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </PerfectScrollbar>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                <Pagination
                  totalPages={playersHistory.totalPages}
                  perPage={playersHistory.perPage}
                  currentPage={playersHistory.currentPage}
                />
              </div>
            </div>
          </Fragment>
        )}
        {!gettingPlayersHistory && gettingPlayersHistoryError !== "" && (
          <p className="description description--error">
            Oops, there was an error getting player's history data.
          </p>
        )}
        {!gettingPlayersHistory && (
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        )}
      </div>
    );
  }
}

PlayersHistory.propTypes = {
  gettingPlayersHistory: PropTypes.bool.isRequired,
  gettingPlayersHistoryError: PropTypes.string.isRequired,
  playersHistory: PropTypes.object.isRequired,
  getPlayersHistory: PropTypes.func.isRequired,
  changePlayersHistoryPerPage: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  currentAcc: PropTypes.string.isRequired
};

const mapStateToProps = ({
  playersHistory,
  account,
  pagination,
  gameDoubleOrNothing,
  games,
}) => ({
  activeGame: games.activeGame,
  gettingPlayersHistory: playersHistory.gettingPlayersHistory,
  gettingPlayersHistoryError: playersHistory.gettingPlayersHistoryError,
  playersHistory: playersHistory.playersHistory,
  perPage: playersHistory.perPage,
  currentAcc: account.account,
  currentNick: account.nickname,
  currentPage: pagination.currentPage,
  maxNumOfDonInRow: gameDoubleOrNothing.maxNumOfDonInRow
});

const mapDispatchToProps = {
  getPlayersHistory,
  toggleModal,
  changePage,
  changePlayersHistoryPerPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersHistory);
