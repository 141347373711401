import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RollOver from "../../../../../Decorative/GameTypeIcons/RollOver/RollOver";
import RollUnder from "../../../../../Decorative/GameTypeIcons/RollUnder/RollUnder";
import EvenOrOdd from "../../../../../Decorative/GameTypeIcons/EvenOrOdd/EvenOrOdd";
import DragonOrTiger from "../../../../../Decorative/GameTypeIcons/DragonOrTiger/DragonOrTiger";

const TYPES = [
  { Component: RollOver, text: "Roll Over" },
  { Component: RollUnder, text: "Roll Under" },
  { Component: Fragment, text: "" },
  {
    Component: EvenOrOdd,
    text: "Odd or Even",
    0: { text: "Even" },
    1: { text: "Odd" }
  },
  {
    Component: DragonOrTiger,
    text: "Dragon Tiger",
    0: { text: "Dragon" },
    1: { text: "Tiger" },
    2: { text: "Tie" }
  },
  { text: "Double or Nothing" }
];

const PlayersGameTypeSwitcher = ({ gameType, number, type = 0 }) => {
  if (type === 0) {
    if (gameType > 4) {
      const { text } = TYPES[gameType];
      return <div className="players-game-type-wrapper">{text}</div>;
    } else {
      const { Component, text } = TYPES[gameType];
      return (
        <div className="players-game-type-wrapper">
          <Component />
          {text}
        </div>
      );
    }
  } else if (type === 1) {
    if (gameType > 4) {
      const { text } = TYPES[gameType];
      return <div className="players-game-type-wrapper">{text}</div>;
    } else {
      if (gameType <= 1) {
        const { Component, text } = TYPES[gameType];
        return (
          <div className="players-game-type-wrapper">
            <Component />
            {text}: {number}
          </div>
        );
      }
      if (gameType > 2) {
        const { Component } = TYPES[gameType];
        const { text } = TYPES[gameType][number] || { text: "" };
        return (
          <div className="players-game-type-wrapper">
            <Component />
            {text}
          </div>
        );
      }
      if (gameType === 2) {
        return null;
      }
    }
  }
};

PlayersGameTypeSwitcher.propTypes = {
  gameType: PropTypes.number.isRequired
};

export default PlayersGameTypeSwitcher;
