import {
  ethersProvider,
  ScratchGameContract,
  ScratchGameEventsContract,
  scratchGameTxHash,
  web3Matic,
  AutoBetContract,
  scratchGameAddress,
  AutoBetEventsContract
} from '../contract';
import { hexToNumber, prepareCall } from "./main";
import BigNumber from "bignumber.js";

export const getGBSFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (gameMode) => contract.methods.GBS(gameMode);
  return (gameMode) => prepareCall(forBatch, method, [gameMode]);
};

export const getWinningProbabilityFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (winType, gameMode) => contract.methods.getWinningProbability(winType, gameMode);
  return (winType, gameMode) => prepareCall(forBatch, method, [winType, gameMode]);
};

export const getWinningMultiplierFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (winType, gameMode) => contract.methods.getWinningMultiplier(winType, gameMode);
  return (winType, gameMode) => prepareCall(forBatch, method, [winType, gameMode]);
};

export const getMaxNumOfCardsFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = () => contract.methods.MAX_NUMBER_OF_CARDS();
  return () => prepareCall(forBatch, method);
};

export const getMaxCardCostFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = () => contract.methods.MAX_CARD_COST();
  return () => prepareCall(forBatch, method);
};

export const getBetsAllowedFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = () => contract.methods.BETS_ALLOWED();
  return () => prepareCall(forBatch, method);
};

export const getMinimumBalanceThresholdFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = () => contract.methods.MINIMUM_BALANCE_THRESHOLD();
  return () => prepareCall(forBatch, method);
};

export const lastBetForPlayerFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (address) => contract.methods.lastBetForPlayer(address);
  return (address) => prepareCall(forBatch, method, [address]);
};

export const getTotalBetsFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (address) => contract.methods.getTotalBets(address);
  return (address) => prepareCall(forBatch, method, [address]);
};

export const getBetInfoFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (address, betId) => contract.methods.getBetInfo(address, betId);
  return (address, betId) => prepareCall(forBatch, method, [address, betId]);
};

export const getUserProfitForFinishedBetFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (address, betId) => contract.methods.getUserProfitForFinishedBet(address, betId);
  return (address, betId) => prepareCall(forBatch, method, [address, betId]);
};

export const getPossibleWinningsFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (address, betId) => contract.methods.getPossibleWinnings(address, betId);
  return (address, betId) => prepareCall(forBatch, method, [address, betId]);
};

export const getCardResultFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (randomNumber, costPerCard, gameMode) =>
    contract.methods.getCardResult(randomNumber, costPerCard, gameMode);
  return (randomNumber, costPerCard, gameMode) =>
    prepareCall(forBatch, method, [randomNumber, costPerCard, gameMode]);
};

export const getParamsForTokenCaluclationFromScratchContract = (forBatch) => {
  const contract = ScratchGameContract();
  const method = (chance) =>
    contract.methods.getParamsForTokenCaluclation(chance);
  return(chance) =>
    prepareCall(forBatch, method, [chance]);
};

export const placeBetFromScratchContract = (
  numberOfCards,
  costPerCard,
  gameMode,
  from,
  betWithContractBalance,
  betWithContractBonusBalance,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await ScratchGameContract();
      let functionSignature;

      if (betWithContractBalance) {
        functionSignature = contract.methods
          .placeBetWithContractBalance(numberOfCards, costPerCard, gameMode)
          .encodeABI();
      } else if (betWithContractBonusBalance) {
        functionSignature = contract.methods
          .placeBonusBet(numberOfCards, costPerCard, gameMode)
          .encodeABI();
      } else {
        functionSignature = contract.methods
          .placeBet(numberOfCards, costPerCard, gameMode)
          .encodeABI();
      }

      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Placing a bet...",
          successTitle: "Placed a bet.",
          errorTitle: "Bet denied.",
          notifyOf: "place-bet",
          game: "scratch",
          amount,
          numberOfCards,
          costPerCard,
          gameMode
        },
        dispatch,
        getState
      );

      resolve(status);
    } catch (err) {
      reject(err);
    }
  });

export const getPreviousRoundsFromScratchContract = async (numberOfRolls, page, address) => {
  try {
    const contract = ScratchGameEventsContract;
    const event = contract.interface.events["RoundFinished"];
    const filter = contract.filters.RoundFinished(null, address, null);
    const { blockNumber } = await web3Matic.eth.getTransaction(scratchGameTxHash);
    filter.fromBlock = blockNumber;

    const startFrom = page === 0 ? page : page * numberOfRolls;

    let rounds = await ethersProvider.getLogs(filter);
    rounds = rounds.map(item => event.decode(item.data, item.topics));
    rounds = rounds.map(({ id }) => hexToNumber(id._hex)).reverse();
    rounds = rounds.splice(startFrom, numberOfRolls);
    return rounds;
  } catch (e) {
    console.log(e);
  }
};


export const getPreviousRollsFromScratchContract = (
  numberOfRolls = 8,
  address,
  page,
  rounds,
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = ScratchGameEventsContract;

      const promises = rounds.reverse().map(
        round =>
          new Promise(async (resolve, reject) => {
            try {
              const event = contract.interface.events["RoundFinished"];
              const filter = contract.filters.RoundFinished(round, address);
              const { blockNumber } = await web3Matic.eth.getTransaction(scratchGameTxHash);
              filter.fromBlock = blockNumber;

              let item = (await ethersProvider.getLogs(filter))[0];
              item = event.decode(item.data, item.topics);

              resolve({
                round: hexToNumber(item.id._hex),
                amount: new BigNumber(item.betSize._hex).times(item.cardsBought._hex).toString(),
                numOfWinCards: hexToNumber(item.cardsWon._hex),
                numberOfCards: hexToNumber(item.cardsBought._hex),
                profit: hexToNumber(item.payout._hex).toString(),
                won: hexToNumber(item.cardsWon._hex) > 0,
              });
            } catch (err) {
              reject(err);
            }
          })
      );

      const res = await Promise.all(promises);

      resolve(res.reverse());
    } catch (err) {
      reject(err);
    }
  });

export const getAutobetInfo = async (player) => {
  const autoBetContract = AutoBetContract();
  return await autoBetContract.methods.getBetInfo(player, scratchGameAddress).call();
}

export const listenScratchAutoBetStartedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStarted(player, scratchGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, noOfBets, {blockNumber}) => {
    await callback(user, game, noOfBets, blockNumber);
  })
}

export const listenScratchAutoBetStoppedEvent = ( player, callback) => {
  let filter = AutoBetEventsContract.filters.AutoBetStopped(player, scratchGameAddress);
  AutoBetEventsContract.on(filter, async(user, game, remainingBets, {blockNumber}) => {
    await callback(user, game, remainingBets, blockNumber);
  })
}

export const listenToRoundStartedFromScratchContract = callback => {
  console.log("SCRATCH ROUND STARTED EVENT OUT");
  ScratchGameEventsContract.on("RoundStarted", async (id, player, { blockNumber }) => {
    console.log("SCRATCH ROUND STARTED EVENT IN", blockNumber, +new Date());
    await callback({ player, blockNumber, round: hexToNumber(id._hex) });
  });
};

export const listenToRoundFinishedFromScratchContract = callback => {
  console.log("SCRATCH ROUND FINISHED EVENT OUT");
  ScratchGameEventsContract.on("RoundFinished", async (id, player, result, { blockNumber }) => {
    console.log("SCRATCH ROUND FINISHED EVENT IN", blockNumber, +new Date());
    await callback({ player, resultHash: result, round: hexToNumber(id._hex) });
  });
};

export const listenToGamePausedFromScratchContract = callback => {
  console.log("SCRATCH PAUSED EVENT OUT");
  ScratchGameEventsContract.on("GamePaused", bool => {
    console.log("SCRATCH PAUSED EVENT IN");
    callback({ bool });
  });
};

