import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Loader from "../../../Loader/Loader";
import RollHistory from "./Games/RollHistory";
import SpinHistory from "./Games/SpinHistory";
import "./History.scss";
import RouletteHistory from "./Games/RouletteHistory";

class History extends Component {
  render() {
    const {
      gettingHistory,
      gettingHistoryError,
      gettingGameState,
      gettingGameStateErr,
      history,
      activeGame,
      page,
    } = this.props;
    const loading = gettingHistory || gettingGameState;
    const error = gettingHistoryError || gettingGameStateErr;

    return (
      <section className="main__section main__section--history">
        <header className="main__section__header">
          <span className="main__section__heading">
            {activeGame === "roll" && "Previous rolls: "}
            {activeGame === "spin" && "Previous spins: "}
          </span>
        </header>
        <div className="main__section__body">
          {loading && <Loader />}

          {!loading && error && <p className="error">{error}</p>}

          {!loading && !error && (history.length > 0 || page !== 0) && (
            <ul className={`previous-${activeGame}`}>
              {activeGame === "roll" && <RollHistory history={history} page={page} />}
              {activeGame === "spin" && <SpinHistory history={history} page={page} />}
              {activeGame === "roulette" && <RouletteHistory history={history} page={page} />}
            </ul>
          )}
          {!loading && !error && history.length === 0 && page === 0 && (
            <p>Place a bet to start a first round!</p>
          )}
        </div>
      </section>
    );
  }
}

History.propTypes = {
  history: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  gettingHistory: PropTypes.bool.isRequired,
  gettingHistoryError: PropTypes.string.isRequired,
  activeGame: PropTypes.string.isRequired
};

const mapStateToProps = ({ betHistory, games }) => ({
  history: betHistory.history,
  page: betHistory.page,
  gettingHistory: betHistory.gettingHistory,
  gettingHistoryError: betHistory.gettingHistoryError,
  activeGame: games.activeGame
});

export default connect(mapStateToProps)(History);
