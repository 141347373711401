import {
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST,
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS,
  GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR,
  GET_MAX_BET_SIZES_REQUEST,
  GET_MAX_BET_SIZES_SUCCESS,
  GET_MAX_BET_SIZES_ERROR,
  GET_MAX_NUM_OF_BETS_REQUEST,
  GET_MAX_NUM_OF_BETS_SUCCESS,
  GET_MAX_NUM_OF_BETS_ERROR,
  SET_ROULETTE_MAX_BET_SIZE,
  SET_ROULETTE_BET_AMOUNT
} from "../../actionTypes/contractConstants/roulette";
import {
  getBenchmarkMaxBetSizeFromRouletteContract,
  getMaxBetSizeFromRouletteContract,
  getMaxNumberOfBetsFromContract,
} from "../../services/ethereum/roulette";
import rouletteTable from "../../config/rouletteTable.json";
import { capitalizeFirstLetter } from "../../services/utils";
import BigNumber from "bignumber.js";
import {getMaxBetChanceForBonusBetting, getWinProbability} from "./games";
import { getMaxDonsInRow } from "../games/doubleOrNothing";

export const setRouletteMaxNumOfBets = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_NUM_OF_BETS_REQUEST });
  try {

    const payload = await getMaxNumberOfBetsFromContract();
    dispatch({ type: GET_MAX_NUM_OF_BETS_SUCCESS, payload });
  } catch (e) {
    dispatch({ type: GET_MAX_NUM_OF_BETS_ERROR, payload: e.message });
  }
};

export const getRouletteMaxBetSize = betArr => (dispatch, getState) => {
  const { chance } = getChanceAndMultiplierDuplicatedFromContract(betArr);
  return maxBetSizeDuplicatedFromContract(chance, getState);
};

export const setRouletteMaxBetSize = betArr => async (dispatch, getState) => {
  const { chance } = getChanceAndMultiplierDuplicatedFromContract(betArr);
  const payload = maxBetSizeDuplicatedFromContract(chance, getState);
  dispatch({ type: SET_ROULETTE_MAX_BET_SIZE, payload });
};

export const getBenchmarkMaxBetSize = () => async (dispatch, getState) => {
  dispatch({ type: GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getBenchmarkMaxBetSizeFromRouletteContract(account);
    dispatch({
      type: GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_SUCCESS,
      payload
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_ROULETTE_BENCHMARK_MAXIMUM_BET_SIZE_ERROR,
      payload: e.message
    });
  }
};

export const setRouletteBetAmount = payload => dispatch => {
  dispatch({ type: SET_ROULETTE_BET_AMOUNT, payload });
};

export const getMaxBetSizes = () => async (dispatch, getState) => {
  dispatch({ type: GET_MAX_BET_SIZES_REQUEST });
  try {
    const { account } = getState().account;
    const payload = await getMaxBetSizeFromRouletteContract(account);
    dispatch({ type: GET_MAX_BET_SIZES_SUCCESS, payload });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_MAX_BET_SIZES_ERROR, payload: e.message });
  }
};

export const getChanceAndMultiplierDuplicatedFromContract = betArr => {
  if (betArr[1] === 0) {
    const numberCount = betArr.length - 2;
    if (numberCount === 1) {
      return { chance: 270, multiplier: 36 };
    } else if (numberCount === 2) {
      return { chance: 541, multiplier: 18 };
    } else if (numberCount === 3) {
      return { chance: 811, multiplier: 12 };
    } else if (numberCount === 4) {
      return { chance: 1081, multiplier: 9 };
    } else if (numberCount === 6) {
      return { chance: 1622, multiplier: 6 };
    }
  } else if (betArr[1] === 4 || betArr[1] === 5) {
    return { chance: 3243, multiplier: 3 };
  } else if (betArr[1] === 1 || betArr[1] === 2 || betArr[1] === 3) {
    return { chance: 4865, multiplier: 2 };
  }
};

export const maxBetSizeDuplicatedFromContract = (chance, getState) => {
  const {
    maxBet1,
    maxBet2,
    maxBet3,
    maxBet4,
    maxBet5,
    maxBet6,
    maxBet7,
    benchmarkMaxBetSize
  } = getState().rouletteConstants;
  if (chance === 270) {
    return new BigNumber(maxBet1)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 541) {
    return new BigNumber(maxBet2)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 811) {
    return new BigNumber(maxBet3)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 1081) {
    return new BigNumber(maxBet4)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 1622) {
    return new BigNumber(maxBet5)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 3243) {
    return new BigNumber(maxBet6)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  } else if (chance === 4865) {
    return new BigNumber(maxBet7)
      .times(benchmarkMaxBetSize)
      .dividedBy(100)
      .toString();
  }
};

export const createBetArr = (_data, type, amount = "0") => {
  if (type === "combinations" || type === "tableNumbers") {
    return [amount, 0, ..._data];
  } else if (type === "commands") {
    if (_data === "even") {
      return [amount, 1, 0];
    } else if (_data === "odd") {
      return [amount, 1, 1];
    } else if (_data === "red") {
      return [amount, 2, 0];
    } else if (_data === "blue") {
      return [amount, 2, 1];
    } else if (_data === "1-18") {
      return [amount, 3, 0];
    } else if (_data === "19-36") {
      return [amount, 3, 1];
    } else if (_data === "1st2-1") {
      return [amount, 4, 0];
    } else if (_data === "2nd2-1") {
      return [amount, 4, 1];
    } else if (_data === "3rd2-1") {
      return [amount, 4, 2];
    } else if (_data === "1st12") {
      return [amount, 5, 0];
    } else if (_data === "2nd12") {
      return [amount, 5, 1];
    } else if (_data === "3rd12") {
      return [amount, 5, 2];
    }
  }
};

export const isNumberRedOrBlue = _num => {
  const num = +_num;
  const isBlue = rouletteTable.commands.blue.numbers.includes(num);
  const isRed = rouletteTable.commands.red.numbers.includes(num);
  if (isBlue) return "blue";
  if (isRed) return "red";
  return "yellow";
};

export const betArrToBetListData = (data, type, _betArr) => {
  if (type === "tableNumbers") {
    return {
      bet: data,
      details: "Bet on Straight",
      payout: "35:1",
      winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
      color: data === "0" ? "yellow" : isNumberRedOrBlue(data)
    };
  } else if (type === "commands") {
    if (data === "1st12" || data === "2nd12" || data === "3rd12") {
      return {
        bet: data,
        details: "Bet on Dozen",
        payout: "2:1",
        winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
        color: "yellow"
      };
    }
    if (data === "1st2-1" || data === "2nd2-1" || data === "3rd2-1") {
      let columnOrdinal;
      if (data === "1st2-1") columnOrdinal = "1st";
      if (data === "2nd2-1") columnOrdinal = "2nd";
      if (data === "3rd2-1") columnOrdinal = "3rd";
      return {
        bet: "2-1",
        details: `Bet on ${columnOrdinal} Column`,
        payout: "2:1",
        winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
        color: "yellow"
      };
    }
    if (
      data === "1-18" ||
      data === "19-36" ||
      data === "even" ||
      data === "odd" ||
      data === "red" ||
      data === "blue"
    ) {
      return {
        bet: capitalizeFirstLetter(data),
        details: `Bet on ${capitalizeFirstLetter(data)}`,
        payout: "1:1",
        winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
        color:
          data === "red" || data === "blue"
            ? data === "red"
              ? "red"
              : "blue"
            : "yellow"
      };
    }
  } else if (type === "combinations") {
    const betArr = [..._betArr].splice(2, _betArr.length);
    if (betArr.length === 2) {
      return {
        bet: data,
        details: "Bet on Split",
        payout: "17:1",
        winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
        color: "yellow"
      };
    } else if (betArr.length === 3) {
      if (betArr.includes(0)) {
        return {
          bet: data,
          details: "Bet on a corner shared by 0",
          payout: "11:1",
          winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
          color: "yellow"
        };
      } else {
        return {
          bet: data,
          details: "Bet on Three Line",
          payout: "11:1",
          winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
          color: "yellow"
        };
      }
    } else if (betArr.length === 4) {
      if (betArr.includes(0)) {
        return {
          bet: data,
          details: "Bet on the line of 1, 2 and 3, with 0",
          payout: "8:1",
          winChance: 1 / 8,
          color: "yellow"
        };
      } else {
        return {
          bet: data,
          details: "Bet on Corner",
          payout: "8:1",
          winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
          color: "yellow"
        };
      }
    } else if (betArr.length === 6) {
      return {
        bet: data,
        details: "Bet on Six Line",
        payout: "5:1",
        winChance: (getChanceAndMultiplierDuplicatedFromContract(_betArr)).chance,
        color: "yellow"
      };
    }
  }
};

export const getPlayersBetDetailsFromRouletteHistory = betHistory => {
  const betDataArr = betHistory.map(item => item.betData);
  return [...betDataArr].map(item => getPlayersBetDetailsFromRouletteBet(item));
};

export const getPlayersBetDetailsFromRouletteBet = item => {
  const condition =
    item.bet === "Even" ||
    item.bet === "Odd" ||
    item.bet === "Red" ||
    item.bet === "Blue" ||
    item.bet === "1-18" ||
    item.bet === "19-36" ||
    item.bet === "2-1" ||
    item.bet === "0,1,2,3";
  return `${item.details}${!condition ? ` - ${item.bet}` : ""}`;
};

export const checkIfPlayerHasWonRouletteBet = (selectedNumber, betHistory) => {
  const betsArr = betHistory.map(item => item.betArr);
  const checkBetArrWinningArr = [...betsArr].map(item =>
    checkBetArrWinning(selectedNumber, item)
  );
  const hasWon = checkBetArrWinningArr
    .map(({ hasWon }) => hasWon)
    .includes(true);
  let winPrize = 0;
  if (hasWon)
    winPrize = checkBetArrWinningArr
      .map(({ winPrize }) => winPrize)
      .reduce((total, num) => new BigNumber(total).plus(num).toString());
  return { hasWon, winPrize };
};

export const checkBetArrWinning = (selectedNumber, betArr) => {
  const input = [...betArr].splice(2, betArr.length);
  const amount = betArr[0];
  let winPrize = 0;
  let hasWon;
  if (betArr[1] === 0) {
    hasWon = input.includes(selectedNumber);
    if (hasWon) {
      if (input.length === 1) {
        winPrize = new BigNumber(amount).times(35).toString();
      } else if (input.length === 2) {
        winPrize = new BigNumber(amount).times(17).toString();
      } else if (input.length === 3) {
        winPrize = new BigNumber(amount).times(11).toString();
      } else if (input.length === 4) {
        winPrize = new BigNumber(amount).times(8).toString();
      } else if (input.length === 6) {
        winPrize = new BigNumber(amount).times(5).toString();
      }
    }
  }
  if (betArr[1] === 1) {
    const EvenOdd =
      selectedNumber !== 0 ? (selectedNumber % 2 ? "odd" : "even") : "zero";
    hasWon =
      (EvenOdd === "even" && input[0] === 0) ||
      (EvenOdd === "odd" && input[0] === 1);
    if (hasWon) winPrize = amount;
  } else if (betArr[1] === 2) {
    const numberColor = isNumberRedOrBlue(selectedNumber);
    hasWon =
      (numberColor === "red" && input[0] === 0) ||
      (numberColor === "blue" && input[0] === 1);
    if (hasWon) winPrize = amount;
  } else if (betArr[1] === 3) {
    hasWon =
      (selectedNumber < 19 && input[0] === 0 && selectedNumber !== 0) ||
      (selectedNumber > 18 && input[0] === 1);
    if (hasWon) winPrize = amount;
  } else if (betArr[1] === 4) {
    hasWon =
      (rouletteTable.commands["1st2-1"].numbers.includes(selectedNumber) &&
        input[0] === 0) ||
      (rouletteTable.commands["2nd2-1"].numbers.includes(selectedNumber) &&
        input[0] === 1) ||
      (rouletteTable.commands["3rd2-1"].numbers.includes(selectedNumber) &&
        input[0] === 2);
    if (hasWon) winPrize = new BigNumber(amount).times(2).toString();
  } else if (betArr[1] === 5) {
    hasWon =
      (rouletteTable.commands["1st12"].numbers.includes(selectedNumber) &&
        input[0] === 0) ||
      (rouletteTable.commands["2nd12"].numbers.includes(selectedNumber) &&
        input[0] === 1) ||
      (rouletteTable.commands["3rd12"].numbers.includes(selectedNumber) &&
        input[0] === 2);
    if (hasWon) winPrize = new BigNumber(amount).times(2).toString();
  }
  return { hasWon, winPrize };
};

export const getRouletteContractConstValues = (shouldSetBetAmount = true) => async (
  dispatch,
  getState
) => {
  await dispatch(getBenchmarkMaxBetSize());
  await dispatch(getMaxBetSizes());
  const { minBetSize } = getState().gamesConstants;
  if (shouldSetBetAmount) dispatch(setRouletteBetAmount(minBetSize));
  dispatch(setRouletteMaxNumOfBets());
  await dispatch(getWinProbability());
  await dispatch(getMaxDonsInRow());
  await dispatch(getMaxBetChanceForBonusBetting());
};
