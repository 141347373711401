import React, { Component } from "react";
import PropTypes from "prop-types";
import { spinGameTypeToColor } from "../../../../services/utils";

class Wheel extends Component {
  constructor(props) {
    super(props);
    this.handleAnimationIteration = this.handleAnimationIteration.bind(this);
  }

  handleAnimationIteration = e => {
    const { roundState, lastSelectedGameType } = this.props;
    if (roundState === "finished") {
      e.target.classList.remove("spin");
      const colorName =
        spinGameTypeToColor(lastSelectedGameType)
          .charAt(0)
          .toUpperCase() + spinGameTypeToColor(lastSelectedGameType).slice(1);
      e.target.classList.add(`spinTo${colorName}`);
    }
  };

  render() {
    const {
      spin = false,
      roundState = "closed"
    } = this.props;
    return (
      <svg
        width="410"
        height="410"
        viewBox="0 0 410 410"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: `${roundState !== "open" ? "100%" : ""}`
        }}
      >
        <path
          d="M237.081 225.141L252.535 221L299.64 376.798L284.186 376.798L237.081 225.141Z"
          fill="#FF9D14"
        />
        <path
          d="M168.64 229.141L153.185 225L106.08 380.798L121.535 380.798L168.64 229.141Z"
          fill="#FF9D14"
        />
        <rect x="194.08" y="81" width="19" height="292" fill="#FF9D14" />
        <g
          className={`wheel ${spin ? "spin" : ""}`}
          onAnimationIteration={this.handleAnimationIteration}
        >
          <path
            d="M347.654 182.972C348.73 190.109 349.27 197.317 349.27 204.534L204.54 204.54L347.654 182.972Z"
            fill="#CC2028"
          />
          <path
            d="M342.843 161.887C344.97 168.784 346.578 175.831 347.654 182.968L204.54 204.54L342.843 161.887Z"
            fill="white"
          />
          <path
            d="M334.942 141.753C338.074 148.256 340.714 154.985 342.841 161.882L204.54 204.54L334.942 141.753Z"
            fill="#CC2028"
          />
          <path
            d="M324.131 123.022C328.196 128.986 331.809 135.246 334.941 141.749L204.54 204.54L324.131 123.022Z"
            fill="white"
          />
          <path
            d="M310.647 106.111C315.556 111.403 320.062 117.054 324.127 123.018L204.54 204.54L310.647 106.111Z"
            fill="#42A8DF"
          />
          <path
            d="M294.795 91.3977C300.438 95.8987 305.735 100.815 310.644 106.107L204.54 204.539L294.795 91.3977Z"
            fill="white"
          />
          <path
            d="M276.927 79.2119C283.177 82.8219 289.148 86.8943 294.791 91.3951L204.54 204.54L276.927 79.2119Z"
            fill="#CC2028"
          />
          <path
            d="M257.443 69.8244C264.161 72.4627 270.673 75.5998 276.923 79.2096L204.54 204.54L257.443 69.8244Z"
            fill="white"
          />
          <path
            d="M236.777 63.4441C243.813 65.0518 250.72 67.1835 257.438 69.8216L204.54 204.539L236.777 63.4441Z"
            fill="#42A8DF"
          />
          <path
            d="M215.392 60.2167C222.589 60.7579 229.736 61.8367 236.773 63.4441L204.54 204.54L215.392 60.2167Z"
            fill="white"
          />
          <path
            d="M193.764 60.2103C200.961 59.6729 208.189 59.6747 215.386 60.2156L204.54 204.539L193.764 60.2103Z"
            fill="#F4BD24"
          />
          <path
            d="M172.376 63.4277C179.413 61.8237 186.56 60.7483 193.758 60.2107L204.539 204.539L172.376 63.4277Z"
            fill="white"
          />
          <path
            d="M151.707 69.7974C158.426 67.1626 165.334 65.0343 172.371 63.43L204.539 204.541L151.707 69.7974Z"
            fill="#CC2028"
          />
          <path
            d="M132.219 79.1753C138.471 75.5687 144.984 72.4347 151.703 69.7998L204.54 204.541L132.219 79.1753Z"
            fill="white"
          />
          <path
            d="M114.344 91.3523C119.989 86.8543 125.962 82.7848 132.214 79.1779L204.54 204.541L114.344 91.3523Z"
            fill="#42A8DF"
          />
          <path
            d="M98.4843 106.057C103.396 100.768 108.696 95.8535 114.34 91.3553L204.54 204.541L98.4843 106.057Z"
            fill="white"
          />
          <path
            d="M84.9925 122.961C89.0609 116.999 93.5696 111.35 98.4808 106.06L204.54 204.541L84.9925 122.961Z"
            fill="#F15E25"
          />
          <path
            d="M74.1701 141.686C77.3047 135.185 80.9212 128.927 84.9894 122.965L204.54 204.541L74.1701 141.686Z"
            fill="white"
          />
          <path
            d="M66.2593 161.814C68.39 154.918 71.0336 148.191 74.1679 141.689L204.54 204.54L66.2593 161.814Z"
            fill="#CC2028"
          />
          <path
            d="M61.4369 182.898C62.5162 175.761 64.1279 168.716 66.2583 161.819L204.54 204.54L61.4369 182.898Z"
            fill="white"
          />
          <path
            d="M59.8096 204.464C59.8134 197.247 60.3571 190.039 61.4362 182.903L204.54 204.54L59.8096 204.464Z"
            fill="#42A8DF"
          />
          <path
            d="M61.4257 226.108C60.3501 218.971 59.8099 211.763 59.8097 204.546L204.54 204.541L61.4257 226.108Z"
            fill="white"
          />
          <path
            d="M66.2375 247.193C64.1104 240.296 62.5022 233.249 61.4264 226.112L204.54 204.54L66.2375 247.193Z"
            fill="#CC2028"
          />
          <path
            d="M74.1377 267.326C71.0065 260.823 68.3662 254.094 66.2389 247.197L204.54 204.539L74.1377 267.326Z"
            fill="white"
          />
          <path
            d="M84.9505 286.059C80.8852 280.095 77.2717 273.835 74.1404 267.332L204.541 204.541L84.9505 286.059Z"
            fill="#CC2028"
          />
          <path
            d="M98.4331 302.969C93.5245 297.677 89.0185 292.026 84.9531 286.062L204.541 204.54L98.4331 302.969Z"
            fill="white"
          />
          <path
            d="M114.285 317.682C108.643 313.181 103.345 308.265 98.4363 302.973L204.54 204.541L114.285 317.682Z"
            fill="#42A8DF"
          />
          <path
            d="M132.153 329.868C125.903 326.258 119.932 322.186 114.289 317.685L204.54 204.54L132.153 329.868Z"
            fill="white"
          />
          <path
            d="M151.637 339.256C144.919 336.617 138.408 333.48 132.157 329.87L204.54 204.54L151.637 339.256Z"
            fill="#CC2028"
          />
          <path
            d="M172.304 345.635C165.267 344.027 158.361 341.896 151.643 339.258L204.541 204.54L172.304 345.635Z"
            fill="white"
          />
          <path
            d="M193.689 348.863C186.492 348.322 179.345 347.243 172.309 345.636L204.541 204.54L193.689 348.863Z"
            fill="#F4BD24"
          />
          <path
            d="M215.317 348.869C208.119 349.406 200.891 349.404 193.694 348.863L204.54 204.54L215.317 348.869Z"
            fill="white"
          />
          <path
            d="M236.704 345.651C229.667 347.255 222.52 348.331 215.322 348.868L204.541 204.54L236.704 345.651Z"
            fill="#42A8DF"
          />
          <path
            d="M257.373 339.284C250.654 341.918 243.746 344.047 236.709 345.651L204.541 204.54L257.373 339.284Z"
            fill="white"
          />
          <path
            d="M276.862 329.906C270.61 333.512 264.097 336.646 257.377 339.281L204.54 204.54L276.862 329.906Z"
            fill="#CC2028"
          />
          <path
            d="M294.736 317.73C289.091 322.228 283.118 326.297 276.866 329.904L204.54 204.541L294.736 317.73Z"
            fill="white"
          />
          <path
            d="M310.596 303.024C305.685 308.313 300.385 313.228 294.74 317.726L204.54 204.54L310.596 303.024Z"
            fill="#F15E25"
          />
          <path
            d="M324.088 286.121C320.02 292.082 315.511 297.732 310.6 303.021L204.54 204.54L324.088 286.121Z"
            fill="white"
          />
          <path
            d="M334.91 267.395C331.776 273.896 328.159 280.154 324.091 286.116L204.541 204.54L334.91 267.395Z"
            fill="#42A8DF"
          />
          <path
            d="M342.821 247.265C340.69 254.161 338.046 260.888 334.912 267.39L204.54 204.539L342.821 247.265Z"
            fill="white"
          />
          <path
            d="M347.644 226.182C346.564 233.319 344.953 240.365 342.822 247.261L204.54 204.54L347.644 226.182Z"
            fill="#CC2028"
          />
          <path
            d="M349.27 204.616C349.267 211.833 348.723 219.041 347.644 226.177L204.54 204.54L349.27 204.616Z"
            fill="#42A8DF"
          />
        </g>
        <circle cx="203.975" cy="203.975" r="20.889" fill="white" />
        <path d="M203.58 72L192.08 52L215.08 52L203.58 72Z" fill="#FF9D14" />
      </svg>
    );
  }
}

Wheel.propTypes = {
  spin: PropTypes.bool.isRequired
};

export default Wheel;
