export const GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST =
  "GET_OTHER_ROLL_PLAYERS_ACTIVITY_REQUEST";
export const GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS =
  "GET_OTHER_ROLL_PLAYERS_ACTIVITY_SUCCESS";
export const GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR =
  "GET_OTHER_ROLL_PLAYERS_ACTIVITY_ERROR";
export const ADD_OTHER_ROLL_PLAYER_ACTIVITY = "ADD_OTHER_ROLL_PLAYER_ACTIVITY";

export const GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST =
  "GET_OTHER_SPIN_PLAYERS_ACTIVITY_REQUEST";
export const GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS =
  "GET_OTHER_SPIN_PLAYERS_ACTIVITY_SUCCESS";
export const GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR =
  "GET_OTHER_SPIN_PLAYERS_ACTIVITY_ERROR";
export const ADD_OTHER_SPIN_PLAYER_ACTIVITY = "ADD_OTHER_SPIN_PLAYER_ACTIVITY";

export const RESET_OTHER_PLAYERS_ACTIVITY = "RESET_OTHER_PLAYERS_ACTIVITY";
