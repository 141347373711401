import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  placeScratchBet, setScratchBetAmount, revealCard, setActiveCard, revealAllCards,
} from "../../../../actions/games/scratch";
import { weiToEth } from "../../../../services/ethereum/main";

import ScratchCard from "./ScratchCard/ScratchCard";
import GameMode from "./GameMode/GameMode";
import NumberOfCards from "./NumberOfCards/NumberOfCards";
import BetSize from "../../Shared/BetSize/BetSize";
import PlaceBet from "../../Shared/PlaceBet/PlaceBet";
import WinType from "../WinType/WinType";

import ArrowLeft from "../../../Decorative/Icons/ArrowLeft";
import ArrowRight from "../../../Decorative/Icons/ArrowRight";
import Loader from "../../../Loader/Loader";

import "./Controls.scss";

class Controls extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      gettingScratchInitData,
      settingInitData,
      revealCard,
      revealAllCards,
      showControls,
      placeScratchBet,
      maxCardCost,
      placingBet,
      costPerCard,
      betAmount,
      roundState,
      activeCard,
      numberOfCards,
      cards,
      setActiveCard,
      setScratchBetAmount,
      isAutoBetting,
      startingAutoBetting,
    } = this.props;

    const loading = settingInitData || gettingScratchInitData;
    return (
      <section
        className={`main__section ${showControls ? "main__section--controls" : "main__section--cards"} scratch`}
      >
        {!showControls && roundState === "finished" && (
          <div className="scratch-card__wrapper">
            {cards.map(({cardWin, winType, cardItems, cardRevealed, cardAmount, costPerCard}, index) =>
              <ScratchCard
                key={`${index}-${winType}`}
                visible={activeCard === index}
                activeCard={activeCard}
                numberOfCards={numberOfCards}
                loading={false}
                cardAmount={cardAmount}
                costPerCard={costPerCard}
                cardItems={cardItems}
                cardRevealed={cardRevealed}
                cardWin={cardWin}
                winType={winType}
              />
            )}
            {numberOfCards > 1 &&
            <div className="scratch-card__controls">
              <button onClick={() => setActiveCard(activeCard - 1)}
                      disabled={activeCard === 0}
              >
                <ArrowLeft />
              </button>
              <button onClick={() => setActiveCard(activeCard + 1)}
                      disabled={!cards[activeCard].cardRevealed || activeCard + 1 === numberOfCards}
              >
                <ArrowRight />
              </button>
            </div>
            }
            <div className="scratch-card__details">
              <p>Card {activeCard + 1}/{numberOfCards}</p>
              <div className="reveal-cards">
                <button className="btn btn--primary"
                        disabled={cards[activeCard].cardRevealed}
                        onClick={revealCard}
                >
                  Reveal this card
                </button>
                <button className="btn btn--primary"
                        disabled={isAutoBetting}
                        onClick={revealAllCards}
                >
                  Reveal all cards
                </button>
              </div>
            </div>
          </div>
        )}
        {!showControls && (roundState === "open" || roundState === "closed") &&
          <div className="scratch-card__wrapper">
            <ScratchCard loading={true} visible={true} />
          </div>
        }
        {!showControls && <WinType />}
        {showControls && (
          <Fragment>
            {loading && <Loader size={85} isFullScreen={false} text={"Loading..."} />}
            {!loading &&
              <Fragment>
                <GameMode placingBet={isAutoBetting || startingAutoBetting || placingBet} />
                <NumberOfCards placingBet={isAutoBetting || startingAutoBetting || placingBet} />
                <BetSize
                  maxVal={maxCardCost}
                  placingBet={isAutoBetting || startingAutoBetting || placingBet}
                  amount={costPerCard}
                  setAmount={setScratchBetAmount}
                />
                <hr/>
                <p className="total-cards">
                  <span>Total Bet Size: </span>
                  <span>{weiToEth(betAmount)} ETH</span>
                </p>
                <PlaceBet
                  isAutoBetting={isAutoBetting}
                  placeBet={placeScratchBet}
                  gettingState={loading || startingAutoBetting}
                  buttonText="Buy"
                />
              </Fragment>
            }
          </Fragment>
        )}
      </section>
    );
  }
}

Controls.propTypes = {
  placeScratchBet: PropTypes.func.isRequired,
  roundState: PropTypes.string.isRequired,
  showControls: PropTypes.bool.isRequired,
  gettingScratchInitData: PropTypes.bool.isRequired,
  costPerCard: PropTypes.string.isRequired,
  maxCardCost: PropTypes.string.isRequired,
  betAmount: PropTypes.string.isRequired,
  placingBet: PropTypes.bool.isRequired,
  setScratchBetAmount: PropTypes.func.isRequired,
  activeCard: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  numberOfCards: PropTypes.number.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  settingInitData: PropTypes.bool.isRequired,
  revealAllCards: PropTypes.func.isRequired,
  revealCard: PropTypes.func.isRequired,
  isAutoBetting: PropTypes.bool.isRequired,
  startingAutoBetting: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ games, gameScratch, scratchConstants }) => ({
  settingInitData: games.settingInitData,
  showControls: gameScratch.showControls,
  gettingScratchInitData: gameScratch.gettingScratchInitData,
  betAmount: gameScratch.betAmount,
  costPerCard: gameScratch.costPerCard,
  placingBet: gameScratch.placingBet,
  roundState: gameScratch.roundState,
  cards: gameScratch.cards,
  activeCard: gameScratch.activeCard,
  numberOfCards: gameScratch.numberOfCards,
  maxCardCost: scratchConstants.maxCardCost,
  isAutoBetting: gameScratch.isAutoBetting,
  startingAutoBetting: gameScratch.startingAutoBetting,
});

const mapDispatchToProps = {
  placeScratchBet, setScratchBetAmount, revealCard, setActiveCard, revealAllCards,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
