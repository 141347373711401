import BigNumber from "bignumber.js";
import {
  badBitDistributorGameAddress,
  BadTokenEventsContract,
  BadBitDistributorContract,
  BadBitDistributorEventsContract,
  BadTokenContract,
  BadTokenEthereumContract,
  ethersProvider,
  BadBitGameContract,
  rollGameAddress,
  spinGameAddress,
  rouletteGameAddress,
  POSRootChainManagerContract,
  badTokenEthereumAddress,
  scratchGameTxHash,
  badBitDistributorTxHash,
  badBitGameContractDeployTransactionHash,
  web3Matic,
  posERC20Predicate
} from '../contract';
import {
  hexToNumber,
  getBlockNumber,
  getBlock,
  weiToEth,
  ethToWei
} from "./main";
import { formatTimestampToDate, toChecksumAddress, executeMetaTransaction, getExecuteMetaTransactionSignature } from "../utils";

import Web3 from 'web3';

const web3 = new Web3();

/**
 * Gets total balance of Bad Token for a given address
 *
 * @param {string} account
 * @return {Promise<number>}
 */
export const getUsersBadTokenBalanceFromContract = async (account, from) => {
  const contract = await BadTokenContract();
  return await contract.methods.balanceOf(account).call({
    from
  });
};

/**
 * Gets total balance of Bad Token for a given address on Ethereum
 *
 * @param {string} from
 * @return {Promise<number>}
 */
export const getUsersEthereumBadTokenBalanceFromContract = async from => {
  const contract = await BadTokenEthereumContract();
  return await contract.methods.balanceOf(from).call({
    from
  });
};

export const getPlayerLevelFromContract = async (address) => {
  const contract = await BadBitGameContract();
  return await contract.methods.playerLevel(address).call();
};

/**
 * Gets balance of staked Bad Token for a given address
 *
 * @param account
 * @return {Promise<*>}
 */
export const getUserStakedTokensFromContract = async (account) => {
  const contract = await BadBitDistributorContract();
  return contract.methods.stakedTokens(account).call();
};

export const getUsersOngoingRevenueFromContract = async (account) => {
  const contract = await BadBitDistributorContract();
  return contract.methods.getUsersOngoingRevenue(account).call({ from: account });
};

/**
 * Stakes a given amount of Bad tokens for a given address
 *
 * @param amount
 * @param address
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<void>}
 */
export const stakeTokensFromContract = (
  amount,
  address,
  sendTxFunc,
  dispatch,
  getState,
  from
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitDistributorContract();
      const tokenContract = await BadTokenContract();

      const allowance = await tokenContract.methods
        .allowance(address, badBitDistributorGameAddress)
        .call({ from });

      if (new BigNumber(allowance).lt(new BigNumber(amount))) {
        var functionSignature = tokenContract.methods
        .approve(badBitDistributorGameAddress, -1)
        .encodeABI();

        var signature = await getExecuteMetaTransactionSignature(tokenContract, functionSignature, from);
        await executeMetaTransaction(tokenContract, functionSignature, from, signature.r, signature.s, signature.v);

      }

      functionSignature = contract.methods.stake(amount).encodeABI();

      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Staking BAD Tokens...",
          successTitle: "Staking complete.",
          errorTitle: "Staking denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (e) {
      reject(e);
    }
  });

/**
 * Withdraws a given amount of Bad tokens for a given address
 *
 * @param amount
 * @param address
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 * @return {Promise<void>}
 */
export const withdrawStakedTokensFromContract = (
  amount,
  address,
  sendTxFunc,
  dispatch,
  getState,
  from
) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitDistributorContract();
      const functionSignature = contract.methods.unstake(amount).encodeABI();

      var txObject = {
        functionSignature,
        contract,
        account: from
      }

      const { status } = await sendTxFunc(
        txObject,
        {
          promiseTitle: "Unstaking BAD Tokens...",
          successTitle: "Unstaking complete.",
          errorTitle: "Unstaking denied."
        },
        dispatch,
        getState,
        false
      );

      resolve(status);
    } catch (e) {
      reject(e);
    }
  });

/**
 * A listener for TokensStaked event
 *
 * @param callback
 * @param address
 */
export const listenToTokensStakedFromContract = (callback, address) => {
  console.log("TOKENS STAKED EVENT OUT");
  BadBitDistributorEventsContract.on("TokensStaked", async (user, amount) => {
    if (toChecksumAddress(user) !== toChecksumAddress(address)) return;
    await callback();
    console.log("TOKENS STAKED EVENT IN");
  });
};

export const getDistributedTokensInCycleFromContract = async (cycle, from) => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.distributedTokensInCycle(cycle).call({ from });
};

export const getDistributedTokensFromContract = async from => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.getDistributedTokens().call({ from });
};

export const getTotalSupplyOfTokens = async from => {
  const contract = await BadTokenContract();
  return await contract.methods.totalSupply().call({ from });
};

export const getMaxPercentForStage = async (stage, from) => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.maxPercentForStage(stage).call({ from });
};

export const getStandardLotFromContract = async () => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.getStandardLot().call();
};

export const shouldWinTokensFromContract = async (
  randomHash,
  user,
  betSizes,
  chances,
) => {
  const contract = await BadBitDistributorContract();

  let betSize = new BigNumber(0);

  for (let i = 0; i < betSizes.length; i++) {
    betSize = betSize.plus(betSizes[i])
  }

  betSize = betSize.toString()

  const shouldWin = await contract.methods
    .shouldWinTokens(
      randomHash,
      user,
      betSize,
      chances
    ).call();

  if (shouldWin) {
    return await getStandardLotFromContract();
  } else {
    return "0";
  }
};

export const getCurrentStageFromContract = async from => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.currentStage().call({ from });
};

export const getStakedTokensFromContract = async () => {
  try {
    const casinoContract = await BadBitGameContract();
    const distributorContract = await BadBitDistributorContract();

    const promises =
      (await casinoContract.methods.getUsers().call()).map(user => distributorContract.methods.stakedTokens(user).call());

    return (await Promise.all(promises)).reduce((acc, val) => acc + parseFloat(weiToEth(val)), 0);
  } catch (err) {
    throw new Error(err);
  }
};

export const nextCycleBlockFromContract = async from => {
  const contract = await BadBitDistributorContract();
  const nextCycleBlockNumber = await contract.methods.nextCycleBlock().call({ from });
  const currentBlockNumber = await getBlockNumber();
  return new BigNumber(nextCycleBlockNumber)
    .minus(currentBlockNumber)
    .toString();
};

export const stakedTokensPeriodEndFromContract = async (address, from) => {
  const contract = await BadBitDistributorContract();
  const stakedPeriodEndBlockNumber = await contract.methods
    .stakedTokensPeriodEnd(address)
    .call({ from });
  const currentBlockNumber = await getBlockNumber();
  return new BigNumber(stakedPeriodEndBlockNumber)
    .minus(currentBlockNumber)
    .toString();
};

export const formatRevenueDistributed = async ({
  cycle,
  ethShare,
  stakedTokens,
  totalStakedInCycle,
  blockNumber,
  from
}) => {
  const { timestamp: startTimestamp } = await getBlock(blockNumber);
  const lastCycle = await currentCycleFromContract(from);
  const isLastCycle = Number(lastCycle) === hexToNumber(cycle._hex);
  const nextCycleData = await getCycleEndedFromContract(
    hexToNumber(cycle._hex) + 1
  );

  if (nextCycleData[0]) {
    const { timestamp: endTimestamp } = await getBlock(
      nextCycleData[0].blockNumber
    );
    return {
      isLastCycle,
      startTimestamp: formatTimestampToDate(startTimestamp),
      endTimestamp: formatTimestampToDate(endTimestamp),
      cycle: hexToNumber(cycle._hex),
      ethShare: weiToEth(hexToNumber(ethShare._hex)),
      stakedTokens: weiToEth(stakedTokens.toString()),
      totalStakedInCycle: hexToNumber(totalStakedInCycle._hex),
      percentageShare: new BigNumber(hexToNumber(stakedTokens._hex))
        .dividedBy(new BigNumber(hexToNumber(totalStakedInCycle._hex)))
        .times(100)
        .toString()
    };
  }
  return {
    isLastCycle,
    startTimestamp: formatTimestampToDate(startTimestamp),
    cycle: hexToNumber(cycle._hex),
    ethShare: weiToEth(hexToNumber(ethShare._hex)),
    stakedTokens: weiToEth(stakedTokens.toString()),
    totalStakedInCycle: hexToNumber(totalStakedInCycle._hex),
    percentageShare: new BigNumber(hexToNumber(stakedTokens._hex))
      .dividedBy(new BigNumber(hexToNumber(totalStakedInCycle._hex)))
      .times(100)
      .toString()
  };
};

export const getTotalRevenueShareFromContract = async address => {
  const revenueLogs = await getRevenueDistributedFromContract(address, null);
  if (revenueLogs.length === 0) return "0";
  const arr = [];
  const reducer = (acc, val) =>
    new BigNumber(acc)
      .plus(val)
      .toString();
  revenueLogs.forEach(({ ethShare }) => {
    arr.push(ethToWei(ethShare));
  });
  return arr.reduce(reducer);
};

export const getRevenueDistributedFromContract = (address, cycle, from) =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitDistributorEventsContract;
      const revenueDistributedEvent =
        contract.interface.events["RevenueDistributed"];
      const filter = contract.filters.RevenueDistributed(address, cycle);
      const { blockNumber } = await web3Matic.eth.getTransaction(badBitDistributorTxHash);
      filter.fromBlock = blockNumber;

      let logs = await ethersProvider.getLogs(filter);

      logs = logs.map(log => ({
        ...revenueDistributedEvent.decode(log.data, log.topics),
        blockNumber: log.blockNumber
      }));

      logs = logs.map(item => formatRevenueDistributed(item, from));

      Promise.all(logs).then(logs => resolve(logs));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const formatCycleEnded = ({ cycle, blockNumber }) => ({
  blockNumber,
  cycle: hexToNumber(cycle._hex)
});

export const getCycleEndedFromContract = cycle =>
  new Promise(async (resolve, reject) => {
    try {
      const contract = await BadBitDistributorEventsContract;
      const cycleEndedEvent = contract.interface.events["CycleEnded"];
      const filter = contract.filters.CycleEnded(cycle);
      const { blockNumber } = await web3Matic.eth.getTransaction(badBitGameContractDeployTransactionHash);
      filter.fromBlock = blockNumber;

      let logs = await ethersProvider.getLogs(filter);
      logs = logs.map(log => ({
        ...cycleEndedEvent.decode(log.data, log.topics),
        blockNumber: log.blockNumber
      }));

      logs = logs.map(item => formatCycleEnded(item));

      Promise.all(logs).then(logs => resolve(logs));
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const currentCycleFromContract = async from => {
  const contract = await BadBitDistributorContract();
  return contract.methods.currentCycle().call({ from });
};

/**
 * A listener for TokensWithdrawn event
 *
 * @param callback
 * @param address
 */
export const listenToTokensUnstakedFromContract = (callback, address) => {
  console.log("TOKENS UNSTAKED EVENT OUT");
  BadBitDistributorEventsContract.on("TokensUnstaked", async (user, amount) => {
    if (toChecksumAddress(user) !== toChecksumAddress(address)) return;
    await callback();
    console.log("TOKENS UNSTAKED EVENT IN");
  });
};

/**
 * A listener for TokensWon event
 *
 * @param callback
 * @param address
 */
export const listenToTokensWonFromContract = async (callback, address) => {
  console.log("TOKENS WON EVENT OUT");
  BadBitDistributorEventsContract.on("TokensWon", async (user, amount) => {
    if (user !== address) return;
    await callback({
      amount: hexToNumber(amount._hex).toString()
    });
    console.log("TOKENS WON EVENT IN");
  });
};

export const listenToTransferBadToken = (callback) => {
  console.log("TRANSFER BAD OUT");
  BadTokenEventsContract.on("Transfer", async (from, to, value) => {
    console.log("TRANSFER BAD IN");
    callback({ to, value })
  });
};


export const getRevenueFromContract = async from => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.getOngoingRevenue().call({ from });
};

export const revenueUntilCycleFromContract = async (cycle, from) => {
  const contract = await BadBitDistributorContract();
  return await contract.methods.revenueUntilCycle(cycle).call({ from });
};

export const getExtraTokenWinChanceForPlayerFromContract = async (
  address,
  from
) => {
  const contract = await BadBitGameContract();
  return await contract.methods
    .getExtraTokenWinChanceForPlayer(address)
    .call({ from });
};

/**
 * Approve ethereum gateway to take token
 *
 */
export const approveEthGatewayToTakeTokens = async (
  amount,
  from,
  sendTxFunc,
  dispatch,
  getState
  ) => {
  const ethereumTokenContract = await BadTokenEthereumContract();
  const approvalPromise = ethereumTokenContract.methods.approve(posERC20Predicate, amount).send({from});
  await sendTxFunc(
    approvalPromise,
    {
      promiseTitle: "Approving BAD Token Transactions...",
      successTitle: "Approving BAD Token Transactions success...",
      errorTitle: "Approving BAD Token Transactions denied..."
    },
    dispatch,
    getState,
  );
};

/**
 * Deposit ETH to Matic Network
 *
 * @param from
 * @param amount
 * @param sendTxFunc
 * @param dispatch
 * @param getState
 */
export const depositTokenToEthGateway = async (
  from,
  amount,
  sendTxFunc,
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    const rootChainManagerContract = POSRootChainManagerContract();
    try {
      var data = web3.eth.abi.encodeParameter('uint256', amount);
      const promise = rootChainManagerContract.methods.depositFor(from, badTokenEthereumAddress, data).send({from})

      const { status } = await sendTxFunc(
        promise,
        {
          promiseTitle: "Depositing BAD Tokens to Matic...",
          successTitle: "Please wait 12 Ethereum blocks + 256 Matic blocks (about 8 to 9 minutes) for your deposit to show up on Matic Network.",
          errorTitle: "Depositing BAD Tokens to Matic denied."
        },
        dispatch,
        getState,
      );

      resolve(status);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const transferTokenToTokenHolder = async (amount, to, from) => {
  var badToken = await BadTokenContract();

  await badToken.methods
    .transfer(window._web3.utils.toChecksumAddress(to), amount)
    .send({ from });
};

export const calculateBadTokenWinningProbabilityFromContract =
  async (_BS, _MIN_B, _GBS, _GCL, _GWP, _K, CHANCES) => {
    console.log(_BS, _MIN_B, _GBS, _GCL, _GWP, _K, CHANCES);
    const BS = new BigNumber(_BS);
    const GBS = new BigNumber(_GBS);
    const MIN_B = new BigNumber(_MIN_B);
    const GCL = new BigNumber(_GCL);
    const GWP = new BigNumber(_GWP);

    const caseOne = BS.dividedBy(2).plus(BS.minus(MIN_B).times(GBS));
    const condition = caseOne.lt(GCL);

    let P;

    if (condition) {
      P = caseOne;
    } else {
      let SUM = new BigNumber(0);

      for (let i = 0; i < _K; i++) {
        const winChance = new BigNumber(CHANCES[i]);
        SUM = SUM.plus(new BigNumber(0.5).minus(winChance));
      }

      P = GCL.plus(SUM.dividedBy(_K).times(GWP))
    }

    if (P.lt(0)) P = new BigNumber(0);

    return P.times(100).toString();
  };
