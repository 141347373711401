export const SET_ROULETTE_PAUSED = "SET_ROULETTE_PAUSED";

export const TOGGLE_BETS = "TOGGLE_BETS";
export const TOGGLE_CHIPS = "TOGGLE_CHIPS";

export const CHANGE_TABLE_HIGHLIGHT_COLOR = "CHANGE_TABLE_HIGHLIGHT_COLOR";

export const GET_ROULETTE_GAME_STATE_REQUEST = "GET_ROULETTE_GAME_STATE_REQUEST";
export const GET_ROULETTE_GAME_STATE_SUCCESS = "GET_ROULETTE_GAME_STATE_SUCCESS";
export const GET_ROULETTE_GAME_STATE_ERROR = "GET_ROULETTE_GAME_STATE_ERROR";

export const PLACE_ROULETTE_BET_REQUEST = "PLACE_ROULETTE_BET_REQUEST";
export const PLACE_ROULETTE_BET_SUCCESS = "PLACE_ROULETTE_BET_SUCCESS";
export const PLACE_ROULETTE_BET_ERROR = "PLACE_ROULETTE_BET_ERROR";

export const START_ROULETTE_AUTO_BET_REQUEST = "START_ROULETTE_AUTO_BET_REQUEST";
export const START_ROULETTE_AUTO_BET_SUCCESS = "START_ROULETTE_AUTO_BET_SUCCESS";
export const START_ROULETTE_AUTO_BET_ERROR = "START_ROULETTE_AUTO_BET_ERROR";

export const STOP_ROULETTE_AUTO_BET_REQUEST = "STOP_ROULETTE_AUTO_BET_REQUEST";
export const STOP_ROULETTE_AUTO_BET_SUCCESS = "STOP_ROULETTE_AUTO_BET_SUCCESS";
export const STOP_ROULETTE_AUTO_BET_ERROR = "STOP_ROULETTE_AUTO_BET_ERROR";

export const GET_ROULETTE_ROUND_STATE_REQUEST = "GET_ROULETTE_ROUND_STATE_REQUEST";
export const GET_ROULETTE_ROUND_STATE_SUCCESS = "GET_ROULETTE_ROUND_STATE_SUCCESS";
export const GET_ROULETTE_ROUND_STATE_ERROR = "GET_ROULETTE_ROUND_STATE_ERROR";

export const GET_ACC_ROULETTE_TOTAL_BETS_REQUEST = "GET_ACC_ROULETTE_TOTAL_BETS_REQUEST";
export const GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS = "GET_ACC_ROULETTE_TOTAL_BETS_SUCCESS";
export const GET_ACC_ROULETTE_TOTAL_BETS_ERROR = "GET_ACC_ROULETTE_TOTAL_BETS_ERROR";

export const SET_TOTAL_BET_AMOUNT = "SET_TOTAL_BET_AMOUNT";
export const SET_ROULETTE_LAST_WIN_PRIZE = "SET_ROULETTE_LAST_WIN_PRIZE";
export const SET_ROULETTE_SHOW_CONTROLS = "SET_ROULETTE_SHOW_CONTROLS";
export const SET_ROULETTE_DISABLE_CONTROLS = "SET_ROULETTE_DISABLE_CONTROLS";
export const SET_ROULETTE_LAST_SELECTED_NUMBER = "SET_ROULETTE_LAST_SELECTED_NUMBER";

export const CLEAR_TABLE = "CLEAR_TABLE";

export const EXCEEDED_MAX_BET_SIZE = "EXCEEDED_MAX_BET_SIZE";

export const DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION = "DISMISS_ROULETTE_AUTO_BET_BALANCE_NOTIFICATION";

export const ROULETTE_LISTENERS_INITIATED = "ROULETTE_LISTENERS_INITIATED";
