import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { associateWithAffCode } from "../../../../actions/account";
import { toggleModal } from "../../../../actions/modal";

import "./AssociateWithAffiliateCode.scss";

const codeMaxLength = 5;

class AssociateWithAffiliateCode extends Component {
  constructor(props) {
    super(props);
    this.state = { affCode: "", error: "" };
    this.affCodeInput = createRef();
    this.handleCodeInput = this.handleCodeInput.bind(this);
  }

  componentDidMount() {
    const { passedCode, currentAffCode } = this.props;
    if (currentAffCode === passedCode) return;
    if (passedCode) {
      this.setState({ affCode: passedCode });
      this.affCodeInput.current.value = passedCode;
    }
  }

  handleCodeInput = e => {
    const { value } = e.target;

    const alphanumericRegex = /[^a-z0-9]/gi;
    if (alphanumericRegex.test(value)) {
      this.setState({ affCode: value, error: "Only alphanumeric values allowed." });
      return;
    }
    if (value === "") {
      this.setState({ affCode: value, error: "" });
      return;
    }
    if (value !== "" && value.length !== codeMaxLength) {
      this.setState({ error: `Code must be ${codeMaxLength} characters long` });
      return;
    }
    this.setState({ affCode: value, error: "" });
  };

  render() {
    const { associateWithAffCode, toggleModal, currentAffCode } = this.props;

    const disableSubmit =
      this.state.affCode === "" || this.state.affCode.length !== codeMaxLength;

    return (
      <div className="modal__content modal__content--affiliate">
        {currentAffCode !== "" && (
          <p className="description">
            Currently associated with affiliate code: <span>{currentAffCode}</span>
          </p>
        )}
        <p className="title">Associate with affiliate code:</p>
        <input
          ref={this.affCodeInput}
          className="input--nickname"
          type="text"
          placeholder="Your code here"
          onChange={this.handleCodeInput}
        />
        {this.state.error !== "" && <p className="input__error">{this.state.error}</p>}
        <div className="btn__group">
          <button
            className="btn btn--primary"
            onClick={() => associateWithAffCode(this.state.affCode)}
            disabled={disableSubmit}
          >
            Associate
          </button>
          <button
            className="btn btn--dark"
            onClick={() => toggleModal()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

AssociateWithAffiliateCode.propTypes = {
  associateWithAffCode: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currentAffCode: PropTypes.string.isRequired
};

const mapDispatchToProps = {
  associateWithAffCode,
  toggleModal
};

const mapStateToProps = ({ account }) => ({
  currentAffCode: account.usesAffCode
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateWithAffiliateCode);
