import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";

import Loader from "../../../Loader/Loader";
import InfoIcon from "../../../Decorative/Icons/InfoIcon";
import "./Details.scss";

const Details = ({ settingRoundTimer, gettingTime, gettingState, state, timer }) => {
  return (
    <section className="main__section main__section--details">
      {gettingState && <Loader />}
      {!gettingState && (
        <Fragment>
          <header className="main__section__header">
            {state === "open" && (
              <Fragment>
                <span className="main__section__heading">Round status: </span>
                <span className="status status--success">Betting Open</span>
              </Fragment>
            )}
            {state === "closed" && (
              <Fragment>
                <span className="main__section__heading">Round status: </span>
                <span className="status status--danger">No More Bets</span>
              </Fragment>
            )}
            {state === "finished" && (
              <Fragment>
                <span className="main__section__heading">Round status: </span>
                <span className="status">No Bets</span>
              </Fragment>
            )}
          </header>
          <div className="main__section__body">
            {state === "open" && gettingTime && settingRoundTimer && timer === "" && <Loader />}
            {state === "open" && !gettingTime && !settingRoundTimer && timer !== "" && (
              <Fragment>
                <span className="timer-title">Betting ends in: </span>
                <span className="timer">{timer}</span>
              </Fragment>
            )}
            {state === "closed" && null}
            {state === "finished" && (
              <span>
                <Tooltip
                  theme="roll"
                  title="There are no active bets right now. You can start a round by placing a bet."
                >
                  <InfoIcon />
                </Tooltip>
                No open bets. Please place a bet!
              </span>
            )}
          </div>
        </Fragment>
      )}
    </section>
  );
};

Details.propTypes = {
  gettingState: PropTypes.bool.isRequired,
  gettingTime: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  timer: PropTypes.string.isRequired,
  settingRoundTimer: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ roundTimer }) => ({
  settingRoundTimer: roundTimer.settingRoundTimer,
  timer: roundTimer.timer
});

export default connect(mapStateToProps)(Details);
