import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import BigNumber from 'bignumber.js';
import { DP } from '../../../../config/constants';
import { formatAcc, formatNumber } from '../../../../services/utils';
import { toggleModal } from "../../../../actions/modal";
import { getLeaderboardStats } from "../../../../actions/leaderboardStats";
import { getPromotionStats } from "../../../../actions/promotionStats";

import Loader from "../../../Loader/Loader";
import "./LeaderboardStats.scss";

const StatsTable =
  ({
     account, activeTab, stats, gettingStats, gettingStatsErr, prize, promotionIsRunning,
     timer, bonusPositions,
  }) => (
  <Fragment>
    {gettingStats && (
      <Loader text="Loading stats..." size={85} />
    )}
    {!gettingStats && (
      <Fragment>
      {activeTab === 'flash' && promotionIsRunning && (
        <Fragment>
          <p className="subtitle">
            <span>${prize}</span> Flash Race
          </p>
          <p className="subtitle">
            Flash Race ends in: <span>{timer}</span>
          </p>
        </Fragment>
      )}
      {activeTab === 'flash' && !promotionIsRunning && (
        <p className="subtitle">
          No Flash Race Right Now
        </p>
      )}
      {activeTab === 'weekly' && (
        <p className="subtitle">
          Prize Money this Week: <span>${prize}</span>
        </p>
      )}
      <p className="description">
        Bets with Bonus ETH do NOT count towards any races!
        <br /><br />
        Finish in bonus positions (indicated by *) to win extra rewards!
      </p>
      </Fragment>
    )}
    {!gettingStats &&
    stats.data.length !== 0 &&
    gettingStatsErr === "" && (
      <Fragment>

        <table className="leaderboard-stats">
          <thead className="leaderboard-stats__head">
          <tr>
            <th>Position</th>
            <th>Nickname</th>
            <th>Address</th>
            <th>Wagered</th>
            <th>Share</th>
            <th>Prize</th>
          </tr>
          </thead>
          <tbody className="leaderboard-stats__body">
          {stats.data.map(
            ({ amount, address, nickname }, index) => (
              <tr
                className={`leaderboard-stats__single
                          ${account.toUpperCase() === address.toUpperCase() ? " current-player" : "" }`
                }
                key={`${index}-${amount}-${address}`}
              >
                <td>{index + 1}{bonusPositions.includes(`${index + 1}`) ? ' *' : ''}</td>
                <td>
                  <Tooltip title={nickname} theme="roll">
                    {nickname.slice(0, 9)}{nickname.length > 9 ? "..." : ""}
                  </Tooltip>
                </td>
                <td>
                  <Tooltip title={address} theme="roll">
                    {formatAcc(address, 5, 3)}
                  </Tooltip>
                </td>
                <td>
                  <Tooltip title={`${amount} ETH`} theme="roll">
                    <span>{formatNumber(amount, DP)}</span> ETH
                  </Tooltip>
                </td>
                <td>
                  {SHARE[index]}%
                </td>
                <td>
                  ${new BigNumber(prize).times(SHARE[index]).dividedBy(100).toString()}
                </td>
              </tr>
            )
          )}
          </tbody>
        </table>
      </Fragment>
    )}
    {!gettingStats && gettingStatsErr !== "" && (
      <p className="description description--error">
        Oops, there was an error getting stats.
      </p>
    )}
    {!gettingStatsErr &&
    stats.data.length === 0 &&
    gettingStatsErr === "" && (
      <p className="description description--error">
        There are no stats currently.
      </p>
    )}
  </Fragment>
)

const SHARE = [ 31, 16, 11, 8, 7, 6, 5, 4, 3, 2.5, 1.5, 1, 0.8, 0.7, 0.6, 0.5, 0.4, 0.4, 0.3, 0.3 ];

class LeaderboardStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'weekly',
      timer: '00:00:00:00',
      timerInitiated: false,
    };
    this.changeTab = this.changeTab.bind(this);
    this.countdownTimer = this.countdownTimer.bind(this);
  }

  intervalID = 0;

  changeTab = async (tabName) => {
    const { activeTab, timerInitiated } = this.state;
    if (activeTab === tabName) return;
    this.setState({ activeTab: tabName });
    const { getLeaderboardStats, getPromotionStats } = this.props;
    if (tabName === 'weekly') {
      getLeaderboardStats();
    }
    if (tabName === 'flash') {
      await getPromotionStats();
      const { promotionRunsUntil } = this.props;
      const untilTimestamp = +new Date(promotionRunsUntil);
      !timerInitiated && this.countdownTimer(untilTimestamp);
    }
  }

  countdownTimer = (untilTimestamp) => {
    this.setState({ timerInitiated: true });
    this.intervalID = setInterval(() => {
      const dateUTC = (new Date()).toUTCString();
      const now = +new Date(dateUTC);
      const distance = untilTimestamp - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      days = days < 10 ? `0${days}` : days;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      console.log(untilTimestamp, now, distance, days, hours, minutes);

      if (distance < 0) {
        clearInterval(this.intervalID);
        this.setState({ timer: `00:00:00:00` });
        return;
      }

      this.setState({
        timer: `${days}:${hours}:${minutes}:${seconds}`
      });
    }, 1000);
  }

  componentDidMount() {
    const { getLeaderboardStats } = this.props;
    getLeaderboardStats();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const {
      toggleModal,
      account,
      gettingLeaderboardStats,
      gettingLeaderboardStatsError,
      leaderboardStats,
      leaderboardsWeeklyPrize,
      promotionStats,
      gettingPromotionStats,
      gettingPromotionStatsError,
      promotionFlashRacePrize,
      promotionRunsUntil,
      promotionIsRunning,
      promotionBonusPositions,
      leaderboardsBonusPositions,
    } = this.props;
    const { activeTab, timer } = this.state;

    return (
      <div className="modal__content modal__content--leaderboard-stats">
        <div className="tabs">
          <button
            onClick={async () => await this.changeTab('weekly')}
            className={`btn ${activeTab === 'weekly' ? 'btn--light' : 'btn--dark'}`}>Weekly Wager Race</button>
          <button
            onClick={async () => await this.changeTab('flash')}
            className={`btn ${activeTab === 'flash' ? 'btn--light' : 'btn--dark'}`}>Flash Race</button>
        </div>

        {activeTab === 'weekly' && (
          <StatsTable
            activeTab={activeTab}
            stats={leaderboardStats}
            gettingStats={gettingLeaderboardStats}
            gettingStatsErr={gettingLeaderboardStatsError}
            prize={leaderboardsWeeklyPrize}
            account={account}
            bonusPositions={leaderboardsBonusPositions}
          />
        )}

        {activeTab === 'flash' && (
          <StatsTable
            activeTab={activeTab}
            stats={promotionStats}
            gettingStats={gettingPromotionStats}
            gettingStatsErr={gettingPromotionStatsError}
            prize={promotionFlashRacePrize}
            account={account}
            promotionRunsUntil={promotionRunsUntil}
            promotionIsRunning={promotionIsRunning}
            timer={timer}
            bonusPositions={promotionBonusPositions}
          />
        )}

        {!gettingLeaderboardStats && (
          <button className="btn btn--dark" onClick={() => toggleModal()}>
            Close
          </button>
        )}
      </div>
    );
  }
}

LeaderboardStats.propTypes = {
  account: PropTypes.string.isRequired,
  leaderboardStats: PropTypes.object.isRequired,
  gettingLeaderboardStats: PropTypes.bool.isRequired,
  gettingLeaderboardStatsError: PropTypes.string.isRequired,
  getLeaderboardStats: PropTypes.func.isRequired,
  getPromotionStats: PropTypes.func.isRequired,
  leaderboardsWeeklyPrize: PropTypes.number.isRequired,
  gettingLeaderboardsData: PropTypes.bool.isRequired,
  gettingLeaderboardsDataError: PropTypes.string.isRequired,
  promotionStats: PropTypes.object.isRequired,
  gettingPromotionStats: PropTypes.bool.isRequired,
  gettingPromotionStatsError: PropTypes.string.isRequired,
  promotionFlashRacePrize: PropTypes.number.isRequired,
  promotionRunsUntil: PropTypes.string.isRequired,
  promotionIsRunning: PropTypes.bool.isRequired,
  gettingPromotionStatsData: PropTypes.bool.isRequired,
  gettingPromotionStatsDataError: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  promotionBonusPositions: PropTypes.array.isRequired,
  leaderboardsBonusPositions: PropTypes.array.isRequired,
};

const mapStateToProps = ({ leaderboardStats, promotionStats, account }) => ({
  account: account.account,
  leaderboardStats: leaderboardStats.leaderboardStats,
  leaderboardsBonusPositions: leaderboardStats.leaderboardsBonusPositions,
  gettingLeaderboardStats: leaderboardStats.gettingLeaderboardStats,
  gettingLeaderboardStatsError: leaderboardStats.gettingLeaderboardStatsError,
  leaderboardsWeeklyPrize: leaderboardStats.leaderboardsWeeklyPrize,
  gettingLeaderboardsData: leaderboardStats.gettingLeaderboardsData,
  gettingLeaderboardsDataError: leaderboardStats.gettingLeaderboardsDataError,

  promotionBonusPositions: promotionStats.promotionBonusPositions,
  promotionStats: promotionStats.promotionStats,
  gettingPromotionStats: promotionStats.gettingPromotionStats,
  gettingPromotionStatsError: promotionStats.gettingPromotionStatsError,
  promotionFlashRacePrize: promotionStats.promotionFlashRacePrize,
  promotionRunsUntil: promotionStats.promotionRunsUntil,
  promotionIsRunning: promotionStats.promotionIsRunning,
  gettingPromotionStatsData: promotionStats.gettingPromotionStatsData,
  gettingPromotionStatsDataError: promotionStats.gettingPromotionStatsDataError,
});

const mapDispatchToProps = {
  getLeaderboardStats,
  getPromotionStats,
  toggleModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardStats);
