import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { formatNumber } from "../../../../services/utils";
import { DP } from "../../../../config/constants";
import { weiToEth } from "../../../../services/ethereum/main";

import "./BetData.scss";
import BigNumber from "bignumber.js";
import InfoIcon from "../../../Decorative/Icons/InfoIcon";

class BetData extends Component {
  render() {
    const {
      game,
      multiplier,
      prize,
      chance,
      gameType,
      badTokenWinProb,
      extraTokenWinChance
    } = this.props;
    let badTokenWinChance = new BigNumber(badTokenWinProb)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString();
    if (new BigNumber(badTokenWinChance).lt(0)) badTokenWinChance = "0";

    const badTokenExtraWinChance = new BigNumber(extraTokenWinChance)
      .dividedBy(100)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString();
    let badTokenTotalWinChance = new BigNumber(badTokenWinChance)
      .plus(badTokenExtraWinChance)
      .decimalPlaces(2, BigNumber.ROUND_FLOOR)
      .toString();

    if (new BigNumber(badTokenTotalWinChance).isGreaterThan(99)) {
      badTokenTotalWinChance = "99";
    }

    return (
      <div className="bet__data">
        {game === "roll" && (
          <div className="bet__data__single">
            <span>Multiplier:</span>
            <span>
              <Tooltip
                title={multiplier === "NaN" ? "0" : multiplier}
                theme="roll"
              >
                x{multiplier === "NaN" ? "0" : formatNumber(multiplier, DP)}
              </Tooltip>
            </span>
          </div>
        )}

        {game === "spin" && (
          <div className="bet__data__single">
            <span>
              {gameType === 1 && (
                <Fragment>
                  <div className="spin-type white" /> White
                </Fragment>
              )}
              {gameType === 2 && (
                <Fragment>
                  <div className="spin-type red" /> Red
                </Fragment>
              )}
              {gameType === 3 && (
                <Fragment>
                  <div className="spin-type blue" /> Blue
                </Fragment>
              )}
              {gameType === 4 && (
                <Fragment>
                  <div className="spin-type orange" /> Orange
                </Fragment>
              )}
            </span>
            <span>
              {gameType === 1 && "Pays 1 to 1"}
              {gameType === 2 && "Pays 3 to 1"}
              {gameType === 3 && "Pays 4 to 1"}
              {gameType === 4 && "Pays 19 to 1"}
            </span>
          </div>
        )}

        {!(game === "roulette" || game === "scratch") && (
          <div className="bet__data__single">
            <span>Payout on Win:</span>
            <span>
              <Tooltip title={weiToEth(prize)} theme="roll">
                {formatNumber(weiToEth(prize), DP)} ETH
              </Tooltip>
            </span>
          </div>
        )}

        {game === "roll" && (
          <div className="bet__data__single">
            <span>Win Chance:</span>
            <span>{chance}%</span>
          </div>
        )}

        <div className="bet__data__single bet__data__single--bad-chance">
          <span>BAD Token Win Chance{game === "scratch" ? " Per Card" : ""}:</span>
          <span className="data">
            {badTokenWinChance === "NaN" ? "0" : badTokenWinChance}% + {badTokenExtraWinChance}% ={" "}
            {badTokenTotalWinChance === "NaN" ? "0" : badTokenTotalWinChance}%
            <Tooltip
              html={
                <ul className="tooltip__token-chance__list">
                  <li>
                    <p>BAD Token Win Chance Derived from Bet</p>{" "}
                    <span>{badTokenWinChance}%</span>
                  </li>
                  <li>
                    <p>Additional Win Chance Derived from Player Level</p>{" "}
                    <span>{badTokenExtraWinChance}%</span>
                  </li>
                </ul>
              }
              theme="roll"
            >
              <InfoIcon color={"#465E71"} />
            </Tooltip>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ badToken }) => ({
  badTokenWinProb: badToken.badTokenWinProb,
  extraTokenWinChance: badToken.extraTokenWinChance
});

export default connect(mapStateToProps)(BetData);
