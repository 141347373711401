import React from "react";

import Loader from "../../../Loader/Loader";
import "./ModalLoader.scss";

const ModalLoader = ({ modalText, modalTitle = "" }) => {
  return (
    <div className="modal__content modal__content--loader">
      {modalTitle !== "" && <p className="title">{modalTitle}</p>}
      <Loader text={modalText} size={85} />
    </div>
  );
};

export default ModalLoader;
