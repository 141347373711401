import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BigNumber from "bignumber.js";

import ScratchItem from "../ScratchItem/ScratchItem";
import "./ScratchCard.scss";
import Loader from "../../../../Loader/Loader";

const ScratchCard = ({ loading, cardItems, visible, cardRevealed, winType, cardAmount, costPerCard, cardWin }) => {
  const multiplier = cardWin && new BigNumber(cardAmount).dividedBy(costPerCard).toString();
  return (
    <div className={`main__section__body main__section__body--scratch-card ${visible ? 'visible' : ''}`}>
      <header className="header">
        <p className="main__section__heading">SCRATCH</p>
      </header>
      <p className={`scratch-card__result ${cardWin ? 'won' : 'lost'}`}>
        {cardRevealed ? cardWin ? `${multiplier}x Win!` : 'No Win' :
          <Fragment>&nbsp;</Fragment>}
      </p>
      {loading && (
        <div className="scratch-card__loader">
          <Loader size={85} isFullScreen={false} text={"Obtaining cards..."}/>
        </div>)}
      <div className="scratch-card__items">
        {loading &&
        [...new Array(9)].map((item, index) => <ScratchItem key={index} iconType={0}/>)
        }
        {!loading &&
        cardItems.map((item, index) =>
          <ScratchItem
            key={`${item}-${index}`}
            iconType={item}
            cardRevealed={cardRevealed}
            winType={winType}
            cardWin={cardWin}
          />)
        }
      </div>
    </div>
  );
}

ScratchCard.propTypes = {
  cardItems: PropTypes.array,
  cardWin: PropTypes.bool,
  winType: PropTypes.number,
  visible: PropTypes.bool,
  cardRevealed: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default ScratchCard;
