import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleModal } from "../../../../actions/modal";
import { deleteMessage } from "../../../../actions/chat";

import "./DeleteMessage.scss";

class DeleteMessage extends Component {
  render() {
    const { _id, toggleModal, deleteMessage, dialog, success } = this.props;
    return (
      <div className="modal__content modal__content--delete-message">
        {dialog && (
          <Fragment>
            <p className="title">Are you sure?</p>
            <div className="btn__group">
              <button
                className="btn btn--primary"
                onClick={() => deleteMessage(_id)}
              >
                Yes
              </button>
              <button
                className="btn btn--dark"
                onClick={() => toggleModal()}
              >
                No
              </button>
            </div>
          </Fragment>
        )}
        {!dialog && success && (
          <Fragment>
            <p className="title">Successfully deleted a message</p>
            <div className="btn__group">
              <button className="btn btn--dark" onClick={() => toggleModal()}>
                Close
              </button>
            </div>
          </Fragment>
        )}
        {!dialog && !success && (
          <Fragment>
            <p className="title">Oops, error!</p>
            <p className="description">
              There was an error while trying to delete a message!
            </p>
            <div className="btn__group">
              <button
                className="btn btn--primary"
                onClick={() => deleteMessage(_id)}
              >
                Try again
              </button>
              <button className="btn btn--dark" onClick={() => toggleModal()}>
                Close
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

DeleteMessage.propTypes = {
  deleteMessage: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  toggleModal,
  deleteMessage
};

export default connect(
  null,
  mapDispatchToProps
)(DeleteMessage);
