import React from "react";

export default function BiconomyLogo({ width = 150 }) {
  return (
    <svg width={width} height={width / 4.7909215956} viewBox="0 0 6966 1454" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6371.31 528.578H6548.11L6602.45 666.448L6677.43 861.71L6754.42 666.448L6804.24 528.578H6965.27L6908.4 666.448L6706.62 1149.04L6643.55 1286.92H6472.63V1149.04H6563.7L6602.95 1049.91L6439.91 666.445L6371.31 528.578Z" fill="white"/>
      <path d="M5339.38 528.575H5577.9V573.86C5599.75 556.532 5624.21 542.773 5650.36 533.094C5676.9 523.089 5705.03 517.978 5733.39 518.009C5772.97 518.009 5806.94 524.966 5835.3 538.88C5863.64 552.813 5885.87 574.034 5901.98 602.542C5913.25 589.946 5925.81 578.563 5939.45 568.581C5953.43 558.315 5968.35 549.385 5983.99 541.908C5999.86 534.327 6016.47 528.424 6033.56 524.294C6050.95 520.09 6068.77 517.98 6086.66 518.009C6150.39 518.009 6199.36 535.621 6233.59 570.846C6267.81 606.071 6284.92 657.565 6284.92 725.328V912.02V1049.91H6120.86V750.994C6120.86 721.471 6113.23 698.323 6097.97 681.55C6082.71 664.777 6061.49 656.39 6034.32 656.388C6015.79 656.327 5997.53 660.911 5981.22 669.722C5964.62 678.618 5948.09 693.967 5931.66 715.77C5931.98 719.465 5932.16 723.241 5932.16 727.099V912.02V1049.91H5769.63V749.988C5769.63 735.895 5767.45 723.15 5763.08 711.752C5759.07 700.901 5752.91 690.97 5744.97 682.552C5737.2 674.468 5727.87 668.051 5717.54 663.691C5706.45 659.049 5694.58 656.569 5682.57 656.383C5665.79 656.057 5648.43 659.752 5630.48 667.468C5612.53 675.184 5595.34 689.437 5578.91 710.228V912.02V1049.91H5415.86V912.02V666.445H5339.38L5339.38 528.575Z" fill="white"/>
      <path d="M4729.48 787.226C4729.48 749.326 4736.36 713.765 4750.11 680.544C4763.39 648.066 4782.87 618.493 4807.48 593.486C4831.97 568.665 4861.16 549.039 4895.05 534.608C4928.93 520.2 4966.17 512.988 5006.77 512.973C5047.35 512.973 5084.67 520.184 5118.73 534.608C5152.77 549.041 5182.04 568.667 5206.53 593.486C5231.12 618.46 5250.53 648.046 5263.64 680.544C5277.24 713.762 5284.03 749.322 5284.03 787.226C5284.03 825.129 5277.4 860.775 5264.15 894.163C5251.37 926.808 5232.22 956.576 5207.79 981.725C5183.46 1006.71 5154.27 1026.42 5120.23 1040.85C5086.17 1055.27 5048.35 1062.48 5006.77 1062.48C4964.81 1062.48 4926.82 1055.27 4892.77 1040.85C4858.72 1026.43 4829.62 1006.8 4805.47 981.969C4781.16 956.887 4762.08 927.209 4749.36 894.669C4736.11 861.286 4729.48 825.472 4729.48 787.226ZM4879.94 787.226C4879.94 807.026 4883.3 825.226 4890.01 841.826C4896.31 857.791 4905.62 872.396 4917.44 884.846C4928.85 896.824 4942.54 906.402 4957.7 913.02C4973.17 919.784 4989.88 923.218 5006.77 923.097C5023.78 923.246 5040.65 919.905 5056.33 913.28C5071.4 906.871 5084.94 897.352 5096.08 885.345C5107.58 872.845 5116.55 858.236 5122.5 842.325C5128.87 825.718 5132.05 807.351 5132.06 787.225C5132.06 766.435 5128.87 747.65 5122.5 730.871C5116.12 714.096 5107.31 699.837 5096.08 688.095C5084.89 676.394 5071.34 667.214 5056.33 661.167C5040.58 654.881 5023.71 651.886 5006.77 652.367C4989.98 651.912 4973.28 654.907 4957.7 661.167C4942.57 667.281 4928.87 676.447 4917.44 688.095C4905.87 699.841 4896.73 714.099 4890.01 730.871C4883.3 747.641 4879.95 766.426 4879.95 787.226H4879.94Z" fill="white"/>
      <path d="M4462.82 749.988C4462.82 720.806 4455.11 697.911 4439.69 681.303C4424.25 664.696 4403.78 656.39 4378.3 656.385C4360.84 656.385 4343.23 660.246 4325.46 667.968C4307.67 675.684 4290.39 690.11 4273.62 711.248V912.02V1049.91H4110.57V912.02V666.446H4035.1V528.576H4272.62V574.364C4294.73 557.081 4319.45 543.41 4345.84 533.858C4373.17 523.902 4402.05 518.875 4431.13 519.012C4493.85 519.012 4542.16 536.54 4576.05 571.595C4609.93 606.655 4626.87 657.9 4626.88 725.329V912.02V1049.91H4462.82V749.988Z" fill="white"/>
      <path d="M3425.2 787.226C3425.2 749.326 3432.08 713.765 3445.83 680.544C3459.1 648.066 3478.59 618.493 3503.2 593.486C3527.69 568.665 3556.88 549.039 3590.77 534.608C3624.64 520.2 3661.88 512.988 3702.49 512.973C3743.07 512.973 3780.39 520.184 3814.45 534.608C3848.49 549.041 3877.76 568.667 3902.25 593.486C3926.84 618.46 3946.25 648.045 3959.37 680.541C3972.96 713.759 3979.76 749.319 3979.75 787.223C3979.75 825.126 3973.12 860.772 3959.87 894.16C3947.09 926.805 3927.94 956.573 3903.51 981.722C3879.18 1006.7 3849.99 1026.41 3815.95 1040.84C3781.91 1055.27 3744.09 1062.49 3702.49 1062.48C3660.54 1062.48 3622.54 1055.27 3588.5 1040.85C3554.44 1026.43 3525.34 1006.8 3501.2 981.969C3476.88 956.887 3457.8 927.209 3445.09 894.669C3431.83 861.286 3425.2 825.472 3425.2 787.226ZM3575.67 787.226C3575.67 807.026 3579.02 825.226 3585.73 841.826C3592.03 857.791 3601.34 872.396 3613.16 884.846C3624.57 896.823 3638.26 906.401 3653.42 913.02C3668.89 919.784 3685.61 923.217 3702.49 923.097C3719.51 923.246 3736.37 919.905 3752.05 913.28C3767.12 906.871 3780.66 897.352 3791.8 885.345C3803.3 872.845 3812.28 858.236 3818.22 842.325C3824.59 825.718 3827.78 807.351 3827.79 787.225C3827.79 766.435 3824.6 747.65 3818.22 730.871C3811.85 714.091 3803.04 699.833 3791.8 688.095C3780.62 676.394 3767.06 667.214 3752.05 661.167C3736.3 654.881 3719.44 651.886 3702.49 652.367C3685.7 651.912 3669 654.907 3653.42 661.167C3638.29 667.282 3624.59 676.448 3613.16 688.095C3601.59 699.841 3592.45 714.099 3585.73 730.871C3579.02 747.641 3575.67 766.426 3575.67 787.226Z" fill="white"/>
      <path d="M3247.57 708.722C3233.14 690.604 3217.03 676.934 3199.25 667.712C3181.48 658.49 3160.51 654.548 3136.35 655.884C3116.9 654.551 3099.2 657.235 3083.27 663.935C3067.72 670.384 3053.67 679.976 3042 692.109C3030.24 704.505 3021.09 719.141 3015.1 735.146C3008.71 751.753 3005.52 770.456 3005.52 791.256C3005.42 809.194 3008.84 826.976 3015.6 843.594C3021.9 859.408 3031.21 873.848 3043.01 886.11C3054.73 898.119 3068.77 907.621 3084.28 914.04C3100.86 920.899 3118.45 924.977 3136.36 926.112C3151.11 925.009 3165.62 921.7 3179.39 916.295C3191.84 911.38 3203.46 904.595 3213.86 896.172C3223.72 888.172 3232.44 878.86 3239.78 868.496C3246.32 859.421 3251.97 849.729 3256.63 839.558L3395.02 877.804C3389.3 897.943 3379.49 918.914 3365.58 940.718C3351.64 962.526 3333.53 982.402 3311.23 1000.34C3288.91 1018.29 3262.4 1033.14 3231.72 1044.87C3201.02 1056.61 3166.04 1062.48 3126.8 1062.48C3086.87 1062.48 3050.14 1055.18 3016.6 1040.59C2983.04 1026.01 2954.19 1006.13 2930.04 980.96C2905.88 955.799 2887.01 926.278 2873.43 892.396C2859.85 858.515 2853.05 822.118 2853.05 783.204C2853.05 744.622 2860.01 708.809 2873.94 675.764C2887.36 643.456 2906.93 614.061 2931.55 589.21C2956.04 564.548 2985.14 545.341 3018.85 531.589C3052.57 517.838 3089.22 511.632 3128.81 512.972C3156.49 511.579 3184.21 514.811 3210.84 522.535C3237.56 530.36 3262.98 542.057 3286.31 557.254H3386.46V708.722H3247.57Z" fill="white"/>
      <path d="M2508.46 528.576H2742.45V912.02V1049.91H2586.95V912.02V666.448H2508.46V528.576ZM2578.92 360.501C2578.83 348.506 2581.14 336.614 2585.71 325.522C2590.05 314.892 2596.46 305.228 2604.57 297.088C2612.74 288.967 2622.39 282.478 2633 277.968C2644.22 273.212 2656.3 270.813 2668.48 270.919C2680.26 270.863 2691.91 273.264 2702.7 277.968C2713.21 282.524 2722.77 289.01 2730.88 297.088C2739.01 305.262 2745.5 314.913 2750 325.522C2754.65 336.596 2757.05 348.486 2757.05 360.496C2757.05 372.507 2754.65 384.397 2750 395.47C2740.97 416.835 2724.02 433.876 2702.7 443.025C2691.91 447.734 2680.26 450.136 2668.48 450.074C2656.3 450.186 2644.22 447.787 2633 443.025C2622.39 438.525 2612.74 432.034 2604.57 423.904C2596.46 415.766 2590.05 406.102 2585.71 395.47C2581.14 384.382 2578.83 372.493 2578.92 360.501V360.501Z" fill="white"/>
      <path d="M1819.94 898.382H1897.94V411.772H1819.94V272.386L2191.81 273.386C2226.36 273.386 2257.98 279.508 2286.67 291.753C2315.36 303.997 2340.1 320.604 2360.9 341.572C2381.76 362.63 2398.26 387.596 2409.45 415.041C2421.09 443.374 2426.99 473.734 2426.81 504.364C2426.81 541.261 2420.36 571.788 2407.44 595.946C2394.63 619.986 2375.6 640.146 2352.34 654.32C2377.5 670.422 2397.38 690.298 2411.97 713.946C2426.57 737.594 2433.86 766.36 2433.86 800.246C2433.86 834.797 2427.91 866.497 2416 895.346C2404.09 924.194 2387.82 949.107 2367.19 970.085C2346.36 991.179 2321.45 1007.79 2293.97 1018.9C2265.79 1030.47 2235.43 1036.26 2202.89 1036.26H1819.94V898.382ZM2065.01 411.772V582.865H2184.77C2194.12 582.876 2203.31 580.448 2211.44 575.821C2219.91 571.041 2227.36 564.627 2233.33 556.95C2239.63 548.885 2244.64 539.888 2248.17 530.282C2251.87 520.383 2253.74 509.899 2253.71 499.334C2253.67 488.693 2251.8 478.139 2248.17 468.134C2244.65 458.057 2239.65 448.559 2233.33 439.954C2227.4 431.831 2219.97 424.915 2211.44 419.576C2203.47 414.497 2194.22 411.792 2184.77 411.776L2065.01 411.772ZM2065.01 720.744V898.382H2191.81C2201.47 898.4 2210.95 895.788 2219.24 890.827C2227.86 885.733 2235.39 878.979 2241.39 870.956C2247.84 862.252 2252.85 852.568 2256.23 842.273C2259.93 831.332 2261.8 819.856 2261.76 808.307C2261.88 796.586 2260.01 784.932 2256.23 773.837C2252.83 763.81 2247.72 754.446 2241.13 746.161C2235.01 738.489 2227.39 732.151 2218.73 727.539C2210.46 723.081 2201.21 720.747 2191.81 720.745L2065.01 720.744Z" fill="white"/>
      <path d="M1157.94 0.918945H296.943C133.467 0.918945 0.942871 133.443 0.942871 296.919V1157.92C0.942871 1321.4 133.467 1453.92 296.943 1453.92H1157.94C1321.42 1453.92 1453.94 1321.4 1453.94 1157.92V296.919C1453.94 133.443 1321.42 0.918945 1157.94 0.918945Z" fill="black"/>
      <path d="M1157.94 25.9189H296.943C147.274 25.9189 25.9429 147.25 25.9429 296.919V1157.92C25.9429 1307.59 147.274 1428.92 296.943 1428.92H1157.94C1307.61 1428.92 1428.94 1307.59 1428.94 1157.92V296.919C1428.94 147.25 1307.61 25.9189 1157.94 25.9189Z" stroke="white" strokeWidth="50"/>
      <path d="M1157.94 13.4189H296.943C140.37 13.4189 13.4429 140.346 13.4429 296.919V1157.92C13.4429 1314.49 140.37 1441.42 296.943 1441.42H1157.94C1314.52 1441.42 1441.44 1314.49 1441.44 1157.92V296.919C1441.44 140.346 1314.52 13.4189 1157.94 13.4189Z" stroke="#BEBEBE" strokeWidth="25"/>
      <path d="M458.767 973.935H529.08V402.157H458.767V316.708L791.775 317.685C821.723 317.685 849.88 323.381 876.248 334.774C902.615 345.842 925.564 361.142 945.095 380.673C964.626 399.879 979.926 422.665 990.994 449.032C1002.39 475.399 1008.08 503.72 1008.08 533.993C1008.08 565.569 999.294 594.377 981.716 620.419C964.138 646.461 939.073 667.294 906.521 682.919C922.472 691.382 936.957 701.311 949.978 712.704C962.999 723.772 974.067 735.979 983.181 749.325C992.296 762.672 999.294 776.832 1004.18 791.806C1009.39 806.78 1011.99 822.079 1011.99 837.704C1011.99 868.629 1006.29 897.437 994.9 924.13C983.832 950.823 968.533 974.097 949.001 993.954C929.47 1013.81 906.521 1029.44 880.154 1040.83C854.112 1052.22 826.117 1057.92 796.169 1057.92H458.767V973.935ZM624.783 728.817V973.935H797.146C812.771 973.935 827.257 970.028 840.603 962.216C853.949 954.403 865.668 944.312 875.759 931.942C885.85 919.247 893.663 904.924 899.197 888.974C905.056 873.023 907.986 856.747 907.986 840.146C907.986 825.823 905.056 811.988 899.197 798.642C893.337 785.295 885.362 773.414 875.271 762.997C865.505 752.58 853.787 744.28 840.115 738.095C826.768 731.91 812.445 728.817 797.146 728.817H624.783ZM624.783 402.157V643.856H792.751C808.376 643.856 822.862 640.764 836.208 634.579C849.88 628.394 861.599 620.093 871.365 609.677C881.456 599.26 889.431 587.216 895.291 573.544C901.15 559.872 904.08 545.712 904.08 531.063C904.08 515.113 901.15 499.488 895.291 484.188C889.431 468.563 881.456 454.729 871.365 442.685C861.274 430.64 849.392 420.875 835.72 413.388C822.374 405.901 808.051 402.157 792.751 402.157H624.783Z" fill="white"/>
    </svg>
  );
}
