import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setScratchGameMode } from "../../../../../actions/games/scratch";

import "./GameMode.scss";

const modeInfo = [
  "With 54% chance of getting up to 10x your money, this is a solid choice for the burgeoning player. No one will call you a wimp if you choose this game mode.",
  "45% of all cards return up to 20x your wager - there is enough spark to get a nice rush going. A fine, balanced game mode.",
  "F*ck it - you only live once, they say. Just 40% of cards offer something, but the chance of hunting the whopping 100x multiplier makes it a no-brainer. Strictly professionals only."
]


class GameMode extends Component {
  constructor(props) {
    super(props);
    this.handleGameModeInput = this.handleGameModeInput.bind(this);
  }

  handleGameModeInput = (e) => {
    const { dataset } = e.target;
    this.props.setScratchGameMode(dataset.value);
  };

  render() {
    const {
      gameMode,
      placingBet,
    } = this.props;
    return (
      <div className="main__section__body main__section__body--game-mode">
        <header className="header">
          <p className="main__section__heading">Game Mode</p>
        </header>
        <div className="mode-controls">
          <div className="mode-input">
            <input
              type="radio"
              id="steady"
              name="scratch-mode"
              data-value="0"
              checked={gameMode === 0}
              onChange={this.handleGameModeInput}
              disabled={placingBet}
            />
            <label htmlFor="steady">Steady</label>
          </div>
          <div className="mode-input">
            <input
              type="radio"
              id="risky"
              name="scratch-mode"
              data-value="1"
              checked={gameMode === 1}
              onChange={this.handleGameModeInput}
              disabled={placingBet}
            />
            <label htmlFor="risky">Risky</label>
          </div>
          <div className="mode-input">
            <input
              type="radio"
              id="extreme"
              name="scratch-mode"
              data-value="2"
              checked={gameMode === 2}
              onChange={this.handleGameModeInput}
              disabled={placingBet}
            />
            <label htmlFor="extreme">Extreme</label>
          </div>
        </div>
        <p className="mode-info">{modeInfo[gameMode]}</p>
      </div>
    );
  }
}

GameMode.propTypes = {
  gameMode: PropTypes.number.isRequired,
  setScratchGameMode: PropTypes.func.isRequired,
  placingBet: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ gameScratch, scratchConstants, gamesConstants }) => ({
  gameMode: gameScratch.gameMode,
  numberOfCards: gameScratch.numberOfCards,
  costPerCard: gameScratch.costPerCard,
  betAmount: gameScratch.betAmount,
  maxNumOfCards: scratchConstants.maxNumOfCards,
  minCardCost: gamesConstants.minBetSize,
  maxCardCost: scratchConstants.maxCardCost,
});

const mapDispatchToProps = {
  setScratchGameMode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameMode);
