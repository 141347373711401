import React, { Fragment } from "react";

const UserDisabled = () => {
  return (
    <Fragment>
      <h2>Your account is currently disabled</h2>
      <p className="description">
        Please contact operator to help you verify your account.
      </p>
    </Fragment>
  );
};

UserDisabled.propTypes = {
};

export default UserDisabled;
